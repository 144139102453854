import React from 'react';

interface WrapperProps {
  children: JSX.Element;
}

const Wrapper = ({ children }: WrapperProps) => (
  <div className='w-full relative tablet:p-4'>{children}</div>
);

export default Wrapper;
