import React, { FC } from 'react';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface DataItem {
  [key: string]: string | number;
}

export interface ChartData {
  chartType: 'Bar' | 'Line' | 'Pie' | 'Area' | 'Radar';
  xDataKey: string;
  yDataKey: string;
  data: DataItem[];
}

interface ChartProps {
  config: ChartData;
}

const isValidDate = (d: Date | string): d is Date =>
  !Number.isNaN(Date.parse(String(d)));

export const Charts: FC<ChartProps> = ({ config }) => {
  const parsedData = config.data.map((item: DataItem) => {
    const newItem: DataItem = { ...item };
    for (const key in newItem) {
      if (!Number.isNaN(Number(newItem[key]))) {
        newItem[key] = Number(newItem[key]);
      } else if (
        key === config.xDataKey &&
        isValidDate(new Date(String(newItem[key])))
      ) {
        const date = new Date(String(newItem[key]));
        newItem[key] = `${
          date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()}`;
      } else {
        newItem[key] = String(newItem[key]);
      }
    }
    return newItem;
  });

  if (parsedData.length < 2)
    return (
      <p className='text-xs pl-6 pb-6 pt-2 text-gray-600'>
        No visualisation was generated for the dataset
      </p>
    );

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const renderChart = () => {
    switch (config.chartType) {
      case 'Bar':
        return (
          <BarChart
            width={750}
            height={300}
            data={parsedData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey={config.xDataKey} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={config.yDataKey} fill='#8884d8' />
          </BarChart>
        );
      case 'Line':
        return (
          <LineChart
            width={750}
            height={300}
            data={parsedData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey={config.xDataKey} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type='monotone'
              dataKey={config.yDataKey}
              stroke='#8884d8'
              activeDot={{ r: 8 }}
            />
          </LineChart>
        );
      case 'Pie':
        return (
          <div className='relative -top-5 flex justify-center'>
            <PieChart width={400} height={450}>
              <Pie
                data={parsedData}
                dataKey={config.yDataKey}
                nameKey={config.xDataKey}
                cx='50%'
                cy='50%'
                outerRadius={100}
                fill='#8884d8'
              >
                {parsedData.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </div>
        );
      case 'Area':
        return (
          <AreaChart
            width={750}
            height={400}
            data={parsedData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey={config.xDataKey} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Area
              type='monotone'
              dataKey={config.yDataKey}
              stroke='#8884d8'
              fill='#8884d8'
            />
          </AreaChart>
        );
      case 'Radar':
        return (
          <RadarChart
            cx={300}
            cy={250}
            outerRadius={150}
            width={600}
            height={500}
            data={parsedData}
          >
            <PolarGrid />
            <PolarAngleAxis dataKey={config.xDataKey} />
            <PolarRadiusAxis
              angle={30}
              domain={[
                0,
                Math.max(
                  ...parsedData
                    .map((item) => item[config.yDataKey])
                    .filter((item): item is number => typeof item === 'number'),
                ),
              ]}
            />
            <Radar
              name='Mike'
              dataKey={config.yDataKey}
              stroke='#8884d8'
              fill='#8884d8'
              fillOpacity={0.6}
            />
            <Legend />
            <Tooltip />
          </RadarChart>
        );
      default:
        return <p>Unsupported chart type</p>;
    }
  };

  return renderChart();
};
