import { APP, AppAction } from './actions';

interface AppState {
  init: boolean;
  isVerifyEmailLinkLoading: boolean;
}

export const INITIAL_STATE: AppState = {
  init: false,
  isVerifyEmailLinkLoading: false,
};

const appReducer = (state = INITIAL_STATE, action: AppAction): AppState => {
  switch (action.type) {
    case APP.INIT:
      return {
        ...state,
        init: true,
      };
    default:
      return state;
  }
};

export { appReducer };
