import { Sender } from '@betterleap/client';
import { Button, IconButton, Text } from '@betterleap/ui';
import { useConnectEmail } from 'hooks/useConnectEmail';
import { Banner, BannerProps, useBanner } from '../Banner/Banner';

export enum emailAccountStates {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

interface EmailAccountBannerProps extends BannerProps {
  email?: string;
  provider?: Sender.provider;
  emailAccountState?: emailAccountStates;
}

export const EmailAccountBanner = ({
  provider,
  email,
  emailAccountState,
}: EmailAccountBannerProps) => {
  const { closeBanner } = useBanner();
  const handleConnectEmail = useConnectEmail();

  switch (emailAccountState) {
    case emailAccountStates.CONNECTED:
      return (
        <Banner
          variant='success'
          css={{
            position: 'relative',
          }}
        >
          <Text inherit noBreak>
            Email connected 🎉. You can now create a Sequence!
          </Text>
          <IconButton
            label='close banner'
            onClick={() => closeBanner()}
            name='x'
            css={{
              position: 'absolute',
              right: 16,
            }}
          />
        </Banner>
      );
    case emailAccountStates.DISCONNECTED:
      return (
        <Banner
          variant='danger'
          css={{
            position: 'relative',
          }}
        >
          <Text inherit>
            Your email account <strong>{email}</strong> is no longer connected.
            To resume sending email sequences,
          </Text>
          <Button
            css={{
              borderBottom: '1px solid $danger-800',
              mx: 8,
              hover: {
                borderBottom: '2px solid $danger-800',
              },
            }}
            variant='headless'
            onClick={() => handleConnectEmail(provider)}
          >
            click to reconnect.
          </Button>
        </Banner>
      );
    default:
      return null;
  }
};
