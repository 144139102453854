/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AlertIcon,
  Box,
  Button,
  Flex,
  Form,
  BoundInput as Input,
  Modal,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';
import React from 'react';
import { useForm } from 'react-hook-form';

export interface SaveSearchModalProps {
  name: string;
  prompt?: string;
}

const SaveSearchModal: PromiseModal<
  SaveSearchModalProps,
  SaveSearchModalProps
> = ({
  onSubmit,
  onDismiss,
  name,
  prompt = 'Add a name to save this search',
}) => {
  const { control, handleSubmit } = useForm<SaveSearchModalProps>({
    defaultValues: { name },
  });

  return (
    <Modal>
      <Flex vertical css={{ width: '100%', alignItems: 'center' }}>
        <Box css={{ py: 8 }}>
          <AlertIcon variant='purple' name='bookmark' size='lg' />
        </Box>
        <ModalTitle css={{ paddingBottom: 24 }}>Save Search</ModalTitle>
        <Text
          css={{
            color: '$neutral-blue-500',
            fontSize: '$sm',
            mb: 16,
          }}
        >
          {prompt}
        </Text>
        <Form
          control={control as any}
          css={{ width: '100%' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            css={{ width: '100%' }}
            placeholder='Search Name'
            type='text'
            name='name'
            id='search-name'
            required
          />
        </Form>
        <Flex justify='center' css={{ px: 56, py: 8, gap: 8, width: '100%' }}>
          <Button variant='gray' onClick={onDismiss}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)}>Save</Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default SaveSearchModal;
