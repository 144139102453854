import { LoadingArea } from '@betterleap/shared';
import { EnrichmentEducation } from '@betterleap/client';
import { Box, BoxProps, Flex, Text, TextSkeleton } from '@betterleap/ui';
import { format } from 'date-fns';
import { SectionHeader } from './SectionHeader';

export interface EducationSectionProps extends BoxProps {
  education: EnrichmentEducation[];
  isLoading?: boolean;
}

export interface EducationEntryProps extends BoxProps {
  entry: EnrichmentEducation;
}

const EducationLoader = () => {
  return (
    <>
      <TextSkeleton size='md' css={{ mb: 10, width: 350 }} />
      <TextSkeleton size='md' css={{ mb: 10, width: 400 }} />
    </>
  );
};

export const EducationEntry = ({ entry, ...rest }: EducationEntryProps) => {
  const schoolAndYear = [
    entry.schoolName,
    entry.endDate ? format(new Date(entry.endDate), 'yyyy') : '',
  ]
    .filter((value) => !!value)
    .join(' • ');

  return (
    <Box {...rest}>
      <Flex css={{ mb: 4 }}>
        <Text css={{ fontWeight: '$medium' }}>
          {entry.degree}{' '}
          <Text as='span' inline css={{ color: '$text-secondary' }}>
            {schoolAndYear}
          </Text>
        </Text>
      </Flex>
    </Box>
  );
};

export const EducationSection = ({
  education,
  isLoading,
}: EducationSectionProps) => {
  return (
    <Box css={{ p: 24 }}>
      <SectionHeader title='Education' icon='library' css={{ mb: 16 }} />
      <Box css={{ pl: 40 }} className='highlight'>
        <LoadingArea
          isLoading={!education.length && isLoading}
          loader={<EducationLoader />}
        >
          {education.map((edu) => (
            <EducationEntry css={{ mb: 8 }} key={edu.degree} entry={edu} />
          ))}
        </LoadingArea>
      </Box>
    </Box>
  );
};
