import { Contact } from '@betterleap/client';
import { AlertIcon, Button, Flex, Text } from '@betterleap/ui';
import { NewProjectButton } from './NewProjectButton.template';

interface ListProjectsEmptyStateProps {
  onCreateProject: (mode?: Contact.contactEmailEnrichmentMode) => Promise<void>;
  areThereMultipleProjectTypes: boolean;
}

export const ListProjectsEmptyState = ({
  onCreateProject,
  areThereMultipleProjectTypes,
}: ListProjectsEmptyStateProps): JSX.Element => (
  <>
    <Text
      as='h3'
      css={{
        color: '#1F1F1F',
        fontSize: '$3xl',
        fontFamily: '$gilroy',
        fontWeight: '$normal',
        textAlign: 'center',
        marginBottom: '$56',
      }}
    >
      Add your first Project
    </Text>
    <Flex css={{ gap: '80px', width: '100%', flexWrap: 'wrap' }} centered>
      <Flex
        vertical
        css={{
          alignItems: 'center',
          maxWidth: '250px',
          width: '100%',
        }}
      >
        <AlertIcon
          variant='info'
          name='collection'
          css={{ marginBottom: '$32' }}
        />
        <Text
          css={{
            color: '#9CA3AF',
            fontSize: '$xl',
            fontFamily: '$gilroy',
            textAlign: 'center',
            marginBottom: '$8',
          }}
        >
          Organize your candidates
        </Text>
        <Text
          css={{
            textAlign: 'center',
            color: '#151515',
            fontSize: '$sm',
          }}
        >
          Create a project to source candidates into.
        </Text>
      </Flex>
      <Flex
        vertical
        css={{
          alignItems: 'center',
          maxWidth: '250px',
          width: '100%',
        }}
      >
        <AlertIcon variant='info' name='mail' css={{ marginBottom: '$32' }} />
        <Text
          css={{
            color: '#9CA3AF',
            fontSize: '$xl',
            fontFamily: '$gilroy',
            textAlign: 'center',
            marginBottom: '$8',
          }}
        >
          Create sequences
        </Text>
        <Text
          css={{
            textAlign: 'center',
            color: '#151515',
            fontSize: '$sm',
          }}
        >
          Send a sequence to all candidates in a project.
        </Text>
      </Flex>
      <Flex
        vertical
        css={{
          alignItems: 'center',
          maxWidth: '250px',
          width: '100%',
        }}
      >
        <AlertIcon
          variant='info'
          name='trending-up'
          css={{ marginBottom: '$32' }}
        />
        <Text
          css={{
            color: '#9CA3AF',
            fontSize: '$xl',
            fontFamily: '$gilroy',
            textAlign: 'center',
            marginBottom: '$8',
          }}
        >
          Track your metrics
        </Text>
        <Text
          css={{
            textAlign: 'center',
            color: '#151515',
            fontSize: '$sm',
          }}
        >
          Monitor email opens, responses, and more.
        </Text>
      </Flex>
    </Flex>
    <Flex centered css={{ marginTop: '$56', mb: 136 }}>
      {areThereMultipleProjectTypes ? (
        <NewProjectButton handleCreateProject={onCreateProject} />
      ) : (
        <Button onClick={() => onCreateProject()}>New Project</Button>
      )}
    </Flex>
  </>
);
