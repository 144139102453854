import React from 'react';
import {
  IconButton,
  IconButtonProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@betterleap/ui';

export interface TipProps extends Omit<IconButtonProps, 'content'> {
  content: React.ReactNode;
}

export const Tip = ({ content, ...rest }: Omit<TipProps, 'label'>) => (
  <Popover>
    <PopoverTrigger>
      <IconButton
        {...rest}
        label='more information'
        name='question-mark-circle'
        size='sm'
      />
    </PopoverTrigger>
    <PopoverContent
      css={{ backgroundColor: '$navy', maxWidth: 270, color: '$text-inverse' }}
    >
      {content}
    </PopoverContent>
  </Popover>
);
