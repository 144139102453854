import { Spinner } from '@betterleap/shared';
import dragDropIcon from '@betterleap/shared/dist/assets/images/drag-drop-icon.svg';
import uploadedIcon from '@betterleap/shared/dist/assets/images/uploaded-icon.svg';
import { Box, BoxProps, Flex, IconButton, Text } from '@betterleap/ui';
import React, { useState } from 'react';
import Dropzone, { DropzoneInputProps, DropzoneProps } from 'react-dropzone';

export interface UploadAreaProps extends DropzoneProps {
  id?: string;
  css?: BoxProps['css'];
  onChange?: (files: File[]) => void;
  loading?: boolean;
  inputProps?: DropzoneInputProps;
  showIcon?: boolean;
  description?: string;
  file?: File;
  onRemove?: () => void;
}

interface FileBarProps extends BoxProps {
  onRemove?: () => void;
  onDownload?: () => void;
}

export const FileBar = ({
  onRemove,
  onDownload,
  children,
  ...rest
}: FileBarProps) => (
  <Box
    className='
      flex
      items-center
      justify-between
      mt-2
      h-11
      py-2
      px-4
      rounded-md
      border-solid
      border-gray-300
      text-gray-500
      border
    '
    {...rest}
  >
    <div className='flex'>
      <img
        className='mr-3 flex self-center w-3.5 h-4'
        src={uploadedIcon}
        alt='drag drop icon'
      />
      {children}
    </div>
    <Flex>
      {onDownload && (
        <p
          className='cursor-pointer text-sm leading-5 font-medium text-blue-500 mr-4'
          onClick={() => onDownload?.()}
          role='presentation'
        >
          Download
        </p>
      )}
      {onRemove && (
        <IconButton
          label='remove file'
          name='trash'
          color='$red-700'
          onClick={() => {
            onRemove?.();
          }}
        />
      )}
    </Flex>
  </Box>
);

export const UploadArea = ({
  id,
  onChange,
  loading,
  showIcon = true,
  children,
  inputProps,
  description,
  css,
  ...rest
}: UploadAreaProps) => {
  const [dropError, setDropError] = useState<string>();
  const [dragHover, setDragHover] = useState(false);

  return (
    <Dropzone
      onDragEnter={() => {
        setDragHover(true);
      }}
      onDragLeave={() => {
        setDragHover(false);
      }}
      onDropRejected={(rejections) => {
        const rejection = rejections?.[0]?.errors?.[0]?.code;

        switch (rejection) {
          case 'file-too-large':
            setDropError('File is too large.');
            break;
        }
      }}
      onDrop={(acceptedFiles) => {
        setDropError(undefined);
        onChange?.(acceptedFiles);
      }}
      {...rest}
    >
      {({ getRootProps, getInputProps }) => (
        <Box
          as='section'
          data-icon={showIcon}
          data-drag-hover={dragHover}
          css={{
            cursor: 'pointer',
            border: '2px dashed $gray-300',
            borderRadius: '0.375rem',
            p: 8,
            height: 96,
            '&[data-icon=true]': {
              height: 144,
              p: 24,
            },
            dragHover: {
              borderColor: '$blue-400',
            },
            hover: {
              borderColor: '$blue-400',
            },
            ...css,
          }}
          data-cy='Resume-upload-area'
        >
          <div
            className='flex flex-col w-full h-full justify-center items-center outline-none'
            {...(getRootProps() as Record<string, unknown>)}
          >
            <input {...getInputProps({ id, ...inputProps })} />
            {showIcon &&
              (loading ? (
                <Spinner variant='blue' />
              ) : (
                <img className='mb-3' src={dragDropIcon} alt='drag drop icon' />
              ))}

            {!showIcon && loading ? (
              <Spinner variant='blue' />
            ) : (
              <>
                <div className='mb-1 flex'>
                  <p
                    className='text-sm leading-5 font-medium text-blue-500 mr-1'
                    data-cy='Upload-file'
                  >
                    Upload a file
                  </p>
                  <p className='text-sm leading-5 font-medium text-gray-600'>
                    or drag and drop
                  </p>
                </div>
                <p className='text-xs leading-4 text-center font-normal text-gray-400'>
                  {description}
                </p>
                {!!dropError && (
                  <Text
                    size='xs'
                    css={{
                      color: '$danger-base',
                      mt: 2,
                      fontWeight: '$medium',
                    }}
                  >
                    {dropError}
                  </Text>
                )}
                {children}
              </>
            )}
          </div>
        </Box>
      )}
    </Dropzone>
  );
};
