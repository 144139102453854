import {
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  showToast,
  Spinner,
} from '@betterleap/ui';
import { useCallback } from 'react';
import { apiClient } from 'lib/apiClient';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FeatureFlagKey, useFeatureFlag } from 'hooks/useFeatureFlag';
import { SourceCandidatesLayout } from './SourceCandidates.layout';
import { SourceCandidatesSearchParams } from './SourceCandidates.types';
import { filterState, sourcingPageState } from './SourceCandidates.template';

type NaturalLanguageSearchProps = {
  handleFilterParamsChanged: (params: SourceCandidatesSearchParams) => void;
};

export const nlsLoadingState = atom(false);
export const nlsInputState = atom('');
export const nlsBooleanQueryState = atom('');

export interface UseGenerateSourcingParamsProps {
  onSuccess: (
    data: SourceCandidatesSearchParams & { booleanQuery: string },
  ) => void;
}

export const useGenerateSourcingParams = ({
  onSuccess,
}: UseGenerateSourcingParamsProps) => {
  const setFilters = useSetAtom(filterState);
  const setPageNumber = useSetAtom(sourcingPageState);
  const setNlsLoading = useSetAtom(nlsLoadingState);
  const setNlsBooleanQuery = useSetAtom(nlsBooleanQueryState);

  const generateParams = useCallback(async (query: string) => {
    setNlsLoading(true);
    setNlsBooleanQuery('');

    try {
      const { data } = await apiClient.personSearch.generateParams({
        query,
      });

      setNlsLoading(false);

      if (data.booleanQuery) {
        setNlsBooleanQuery(data.booleanQuery);
      }

      setFilters({
        ...data,
      });

      setPageNumber(1);

      onSuccess({
        ...data,
        page: 1,
        pageSize: 30,
      });
    } catch (e: unknown) {
      let message =
        'Something went wrong while generating the query. Please try again';

      const error = e as { status?: number };

      if (error.status === 404) message = 'Linkedin Profile not found.';

      showToast({
        variant: 'danger',
        title: 'Error generating query',
        description: message,
      });
      setNlsLoading(false);
    }
  }, []);

  return generateParams;
};

export const NaturalLanguageSearch = ({
  handleFilterParamsChanged,
}: NaturalLanguageSearchProps): JSX.Element => {
  const [nlsInput, setNlsInput] = useAtom(nlsInputState);
  const nlsLoading = useAtomValue(nlsLoadingState);
  const showHealthcarePrompts = useFeatureFlag(
    FeatureFlagKey.HEALTHCARE_NLS_PROMPTS,
  );
  const [nlsBooleanQuery, setNlsBooleanQuery] = useAtom(nlsBooleanQueryState);

  const generateParams = useGenerateSourcingParams({
    onSuccess: (data) => {
      handleFilterParamsChanged({
        ...data,
        skills: data.skillsShould,
      });
    },
  });

  const handleNlsSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    generateParams(nlsInput);
  };

  const searchIcon = (
    <Icon
      name='search'
      color='$gray-300'
      css={{ minWidth: 20, minHeight: 20 }}
    />
  );

  const handleClear = () => {
    setNlsInput('');
    setNlsBooleanQuery('');
  };

  const placeholder = showHealthcarePrompts
    ? 'California based nurses with critical care experience'
    : 'e.g. "San Francisco based software engineer with Python experience"';

  return (
    <SourceCandidatesLayout.SearchBar
      css={{
        padding: '16px',
        backgroundColor: '$background-component',
        borderBottom: '1px solid $neutral-blue-300',
        borderLeft: '1px solid $neutral-blue-300',
      }}
    >
      <form
        onSubmit={handleNlsSubmit}
        style={{
          display: 'flex',
          gap: 10,
          marginLeft: 'auto',
          height: '100%',
        }}
      >
        <Input
          placeholder={placeholder}
          aria-label='search by name'
          type='text'
          value={nlsInput}
          onChange={(value) => setNlsInput(value)}
          style={{ flex: 1, height: '100%' }}
          leftIcon={searchIcon}
          rightIcon={
            <Flex css={{ gap: 4 }}>
              {nlsBooleanQuery && (
                <Button
                  variant='secondary'
                  size='xs'
                  onClick={() => {
                    navigator.clipboard.writeText(nlsBooleanQuery);
                    showToast({
                      variant: 'success',
                      title: 'Boolean Query Copied',
                      description:
                        'The generated boolean query has been copied to your clipboard.',
                    });
                  }}
                >
                  Copy Boolean
                </Button>
              )}
              {nlsInput && (
                <IconButton
                  name='x'
                  type='button'
                  label='clear query'
                  size='xs'
                  color='$danger-base'
                  onClick={handleClear}
                />
              )}
            </Flex>
          }
        />
        <Button
          disabled={!nlsInput}
          type='submit'
          variant='purple'
          style={{ width: 100, height: '100%', alignItems: 'center' }}
        >
          {nlsLoading ? <Spinner variant='white' /> : 'Search'}
        </Button>
      </form>
    </SourceCandidatesLayout.SearchBar>
  );
};
