import { Types } from '@betterleap/shared';
import _ from 'lodash';
import { API, ApiAction } from './actions';
import initApi from './helpers/initApi';

export const INITIAL_STATE: ApiState = initApi();

const apiReducer = (state = INITIAL_STATE, action: ApiAction): ApiState => {
  if (/^FETCH_START_/.test(action.type)) {
    const subReducer = _.camelCase(
      action.type.replace(API.FETCH_START, ''),
    ) as Types.Endpoint;

    return {
      ...state,
      [subReducer]: {
        ...state[subReducer],
        fetchParams: action.payload || {},
        loading: true,
        error: null,
      },
    };
  }

  if (/^FETCH_SUCCESS_/.test(action.type)) {
    const subReducer = _.camelCase(
      action.type.replace(API.FETCH_SUCCESS, ''),
    ) as Types.Endpoint;

    return {
      ...state,
      [subReducer]: {
        ...state[subReducer],
        loading: false,
        error: null,
        data: action.payload as Types.ApiResponse,
        meta: action.meta as Types.ApiMetaResponse,
        firedCount: (state[subReducer].firedCount ?? 0) + 1,
      },
    };
  }

  if (/^FETCH_FAILURE_/.test(action.type)) {
    const subReducer = _.camelCase(
      action.type.replace(API.FETCH_FAILURE, ''),
    ) as Types.Endpoint;
    return {
      ...state,
      [subReducer]: {
        ...state[subReducer],
        loading: false,
        error: (action.payload as Types.ApiError) || null,
        data: null,
      },
    };
  }
  if (/^REMOVE_/.test(action.type)) {
    const subReducer = _.camelCase(
      action.type.replace(API.REMOVE, ''),
    ) as Types.Endpoint;
    if (action.type === 'REMOVE_ALL')
      return { ...initApi(), featureConfig: state.featureConfig };
    return {
      ...state,
      [subReducer]: {
        ...state[subReducer],
        data: undefined,
        loading: false,
        error: null,
        firedCount: 0,
      },
    };
  }
  if (/^UPDATE_/.test(action.type)) {
    const subReducer = _.camelCase(
      action.type.replace(API.UPDATE, ''),
    ) as Types.Endpoint;

    return {
      ...state,
      [subReducer]: {
        ...state[subReducer],
        loading: false,
        error: null,
        data: {
          ...(state[subReducer].data as Record<string, unknown>),
          ...action.payload,
        } as Types.ApiResponse,
        meta: action.meta as Types.ApiMetaResponse,
      },
    };
  }

  return state;
};

export { apiReducer };
