import {
  Badge,
  BadgeProps,
  Flex,
  Icon,
  InputProps,
  List,
  ListBox,
  ListInput,
  ListState,
  Menu,
  MenuContent,
  MenuTrigger,
  Option,
  Text,
  useControlledState,
  useResizeObserver,
} from '@betterleap/ui';

import { useCallback, useMemo, useRef, useState } from 'react';

const ModeBadge = ({ css, ...props }: BadgeProps) => (
  <Badge
    variant='gray'
    size='sm'
    shape='rectangle'
    css={{ color: '$text', ...css }}
    {...props}
  />
);

export const SearchOption = ({
  name,
  description,
}: {
  name: string;
  description: string;
}) => {
  return (
    <Flex css={{ gap: 8 }}>
      <ModeBadge>{name}:</ModeBadge>
      <Text size='xs' css={{ color: '$text-secondary' }}>
        {description}
      </Text>
    </Flex>
  );
};

export type ContactProjectSearchMode = 'name' | 'profile';
interface ContactProjectSearchOption {
  id: string;
  description: string;
}

export interface NlsSearchProjectInputProps extends InputProps {
  onModeChange: (mode?: ContactProjectSearchMode | null) => void;
  mode?: ContactProjectSearchMode | null;
  defaultMode?: ContactProjectSearchMode | null;
  clearable?: boolean;
  onClearClick?: () => void;
}

export const NlsSearchProjectInput = ({
  onModeChange,
  mode,
  defaultMode,
  clearable,
  onClearClick,
  ...props
}: NlsSearchProjectInputProps) => {
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [menuWidth, setMenuWidth] = useState<number>();
  const [openMenu, setOpenMenu] = useState(false);
  const [modeValue, setModeValue] = useControlledState<
    ContactProjectSearchMode | null | undefined
  >(mode, defaultMode, onModeChange);

  const [listState, setListState] =
    useState<ListState<ContactProjectSearchOption>>();

  const handleResize = useCallback((entry: ResizeObserverEntry) => {
    setMenuWidth(entry?.borderBoxSize?.[0]?.inlineSize);
  }, []);

  useResizeObserver(menuContainerRef, handleResize);

  const options = useMemo(
    () => [
      {
        id: 'name',
        desciption: 'Search by name',
      },
      {
        id: 'profile',
        desciption: `Search using natural language`,
      },
    ],
    [],
  );

  const focusOnInput = () => {
    listState?.selectionManager?.setFocusedKey('');
    listState?.selectionManager?.setFocused(false);

    // this focus can get eaten by the menu opening without timeout
    setTimeout(() => {
      inputRef.current?.focus();
    });
  };

  const handleModeChange = (newMode?: ContactProjectSearchMode) => {
    setModeValue(newMode);
    focusOnInput();
  };

  const placeholder =
    modeValue === 'profile' ? options[1]?.desciption : options[0]?.desciption;

  return (
    <List
      shouldFilter={false}
      value={[]}
      options={options}
      onChange={(keys) => {
        const newMode = keys[0] as ContactProjectSearchMode | undefined;
        handleModeChange(newMode);
      }}
      onSetListState={(state: ListState<ContactProjectSearchOption>) => {
        setListState(state);
      }}
    >
      <Menu open={openMenu} modal={false}>
        <div
          ref={menuContainerRef}
          style={{
            width: '100%',
          }}
        >
          <ListInput
            ref={inputRef}
            aria-label='Natural Language Search'
            type='text'
            leftIcon={
              <Flex
                css={{ gap: 8 }}
                onClick={() => {
                  focusOnInput();
                }}
              >
                <Icon name='search' color='$gray-300' size={18} />
                {!!mode && <ModeBadge>{mode}:</ModeBadge>}
              </Flex>
            }
            rightIcon={
              clearable ? (
                <Icon
                  name='x'
                  color='$gray-300'
                  size={20}
                  onClick={() => {
                    onClearClick?.();
                  }}
                />
              ) : undefined
            }
            onFocus={() => setOpenMenu(true)}
            onKeyDown={(event) => {
              if (event.key === 'Escape') {
                setOpenMenu(false);
                inputRef.current?.blur();
              }

              if (event.key === 'Backspace') {
                const val = (event.target as HTMLInputElement).value;
                if (val === '') {
                  setModeValue(null);
                }
              }
            }}
            placeholder={placeholder}
            {...props}
          />
          <MenuTrigger aria-hidden>
            <div />
          </MenuTrigger>
        </div>
        <MenuContent
          css={{ width: menuWidth, p: 8 }}
          align='center'
          onInteractOutside={(e) => {
            // close menu unless interaction was within this component
            const isFromInput = (e.target as HTMLElement)?.closest(
              'input[aria-label="Natural Language Search"], [role="dialog"]',
            );

            if (isFromInput) {
              e.preventDefault();
            } else {
              setOpenMenu(false);
            }
          }}
        >
          <ListBox css={{ width: '100%' }} autoFocus={false}>
            {(option) => (
              <Option css={{ focus: { backgroundColor: '$neutral-blue-200' } }}>
                <SearchOption
                  name={option.id}
                  description={option.desciption}
                />
              </Option>
            )}
          </ListBox>
        </MenuContent>
      </Menu>
    </List>
  );
};
