import {
  signupWithGoogle,
  signupWithMicrosoft,
  Types,
} from '@betterleap/shared';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';
import useAnalytics from 'hooks/analytics';
import { useSearchParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import config from 'lib/config';
import googleIcon from '../../../../assets/images/google_icon.svg';
import microsoftIcon from '../../../../assets/images/microsoft.svg';
import logoPurple from '../../../../assets/images/betterleap_logo_purple.svg';

export interface SignupModalProps {
  csvFilename?: string;
  onAuthenticate: (
    result: firebase.auth.UserCredential,
    csvFilename?: string,
  ) => void;
}

export const SignupModal: PromiseModal<SignupModalProps> = ({
  onSubmit,
  csvFilename,
  onAuthenticate,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { track } = useAnalytics();

  const setRedirectParams = () => {
    if (csvFilename) {
      searchParams.set('csvFilename', csvFilename);
      setSearchParams(searchParams);
    }
  };

  const handleGoogleClick = () => {
    setRedirectParams();
    track(Types.ANALYTICS_CLIENT_EVENT.SIGNUP_STARTED, {
      authProvider: 'google.com',
    });
    signupWithGoogle(
      onSubmit,
      config.environment === 'development',
      ({ credential }) => onAuthenticate(credential, csvFilename),
    );
  };

  const handleMicrosoftClick = () => {
    setRedirectParams();
    track(Types.ANALYTICS_CLIENT_EVENT.SIGNUP_STARTED, {
      authProvider: 'microsoft.com',
    });
    signupWithMicrosoft(
      onSubmit,
      config.environment === 'development',
      ({ credential }) => onAuthenticate(credential, csvFilename),
    );
  };

  return (
    <Modal>
      <Flex css={{ alignItems: 'center' }} vertical>
        <Box css={{ py: 16 }}>
          <Box as='img' src={logoPurple} alt='logo' css={{ width: 54 }} />
        </Box>
        <ModalTitle>Welcome to Betterleap</ModalTitle>
        <Text
          size='sm'
          css={{ color: '$text-lightest', textAlign: 'center', mb: 24 }}
        >
          Your all-in-one recruiting platform to source, engage and convert
          candidates.
        </Text>
        <Flex vertical css={{ gap: 16, alignItems: 'center' }}>
          <Button
            variant='ghost'
            onClick={handleGoogleClick}
            css={{
              borderRadius: 16,
              padding: '24px 24px',
              maxWidth: 265,
              width: '100%',
            }}
          >
            <Box
              as='img'
              css={{ maxWidth: 22, mr: 12 }}
              src={googleIcon}
              alt='google_icon'
            />
            Sign up with Google
          </Button>
          <Button
            variant='ghost'
            onClick={handleMicrosoftClick}
            css={{
              borderRadius: 16,
              padding: '24px 24px',
              maxWidth: 265,
              width: '100%',
            }}
          >
            <Box
              as='img'
              css={{ maxWidth: 22, mr: 12 }}
              src={microsoftIcon}
              alt='microsoft_icon'
            />
            Sign up with Microsoft
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
