import { Icon, Input } from '@betterleap/ui';
import { debounce } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

interface SearchInputProps {
  query: string;
  defaultCollapsed?: boolean;
  pageParamNames?: string[];
  searchParamName?: string;
  onFocus?: () => void;
}

export const SearchInput = ({
  query,
  defaultCollapsed,
  pageParamNames = ['page'],
  searchParamName = 'search',
  onFocus,
}: SearchInputProps) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const updateQuery = (value: string) => {
    const newParams: URLSearchParamsInit = {
      ...Object.fromEntries(searchParams.entries()),
    };
    if (value) {
      newParams[searchParamName] = value;
    } else {
      delete newParams[searchParamName];
    }
    for (const pageParamName of pageParamNames) {
      if (searchParams.get(pageParamName)) {
        newParams[pageParamName] = '1';
      }
    }
    setSearchParams(newParams);
  };
  const onChange = useCallback(debounce(updateQuery, 30), [searchParams]);
  const clearQuery = () => {
    setTimeout(() => {
      if (searchRef.current) {
        searchRef.current.value = '';
        onChange('');
      }
    }, 10);
  };
  const searchIcon = (
    <Icon name='search' color='$neutral-blue-700' css={{ minWidth: 24 }} />
  );
  const clearIcon = <Icon name='x' color='$danger-base' onClick={clearQuery} />;
  const isExpanded = !defaultCollapsed || searchFocus || !!query;
  return (
    <Input
      ref={searchRef}
      aria-expanded={isExpanded}
      css={{
        height: 40,
        width: 40,
        borderRadius: '$xl',
        overflowX: 'hidden',
        transition: 'width .4s',
        borderColor: '$neutral-blue-200',
        cursor: 'pointer',
        backgroundColor: '$neutral-blue-200',
        '&[aria-expanded=false]': {
          hover: {
            backgroundColor: '$neutral-blue-400',
            borderColor: '$neutral-blue-400',
          },
        },
        '&[aria-expanded=true]': {
          width: 240,
          backgroundColor: '#FFFFFF',
        },
      }}
      onFocus={() => {
        onFocus?.();
        setSearchFocus(true);
      }}
      onBlur={() => {
        setSearchFocus(false);
      }}
      placeholder='Search'
      defaultValue={query}
      leftIcon={searchIcon}
      rightIcon={query ? clearIcon : undefined}
      onChange={onChange}
    />
  );
};
