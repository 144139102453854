import { Flex, Text } from '@betterleap/ui';
import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { highlightsAtom } from '../useHighlightWords';
import { SourcingResultSection } from './SourcingResult.elements';

interface SectionProps {
  skills: string[];
  center?: boolean;
}
interface EntryProps {
  skill: string;
  isFirst: boolean;
}

export const SourcingResultSkills = ({
  skills,
  center = false,
}: SectionProps) => {
  const [highlights] = useAtom(highlightsAtom);

  const matchedSkills = useMemo(() => {
    return (
      highlights?.skills?.reduce((acc, skill) => {
        acc[skill.toLowerCase()] = true;
        return acc;
      }, {} as { [key: string]: boolean }) || {}
    );
  }, [highlights?.skills]);

  const sortedSkills = useMemo(
    () =>
      skills.sort((a, b) => {
        const aMatched = !!matchedSkills[a.toLowerCase()];
        const bMatched = !!matchedSkills[b.toLowerCase()];
        if (aMatched && !bMatched) return -1;
        if (!aMatched && bMatched) return 1;
        return 0;
      }),
    [skills, matchedSkills],
  );

  return (
    <SourcingResultSection
      title='Skills'
      icon='lightning-bolt'
      elements={sortedSkills}
      collapsedDisplayMax={5}
      collapsingType='tooltip'
      direction='row'
      center={center}
      constructElement={(skill, index) => (
        <SkillEntry skill={skill} isFirst={index === 0} />
      )}
    />
  );
};

const SkillEntry = ({ skill, isFirst }: EntryProps) => (
  <Flex key={skill} css={{ gap: 4 }}>
    {!isFirst && (
      <Text css={{ fontSize: '$sm', color: '$neutral-blue-700' }}>•</Text>
    )}
    <Text
      css={{
        fontSize: '$sm',
        color: '$neutral-blue-700',
      }}
    >
      {skill}
    </Text>
  </Flex>
);
