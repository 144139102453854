import { BoundInput as Input, Label } from '@betterleap/ui';

export const OnboardingTools = () => (
  <Input
    id='tools'
    name='tools'
    placeholder='LinkedIn Recruiter, HireEZ, etc.'
    required
    label={
      <Label css={{ mt: 8, mb: 24, fontSize: '$lg' }}>
        What tools are you currently using?
      </Label>
    }
    size='xl'
    css={{ maxWidth: 600 }}
  />
);
