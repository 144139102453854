import { extensionUrl } from 'constants/externalUrls';
import { InteractiveTable, Types } from '@betterleap/shared';
import { ProjectSuggestedContactDto } from '@betterleap/client';
import {
  Box,
  Button,
  Flex,
  showToast,
  Spinner,
  Text,
  Tooltip,
} from '@betterleap/ui';
import { Row, UseRowSelectRowProps } from 'react-table';
import { useMutation } from 'react-query';
import { useMemo, useState } from 'react';
import { apiClient } from 'lib/apiClient';
import { AddRemoveColumn } from 'components/elements/CandidatesTable/AddRemoveColumn';
import { useNavigate } from 'react-router-dom';
import { logToSegment } from 'functions/segmentLog';
import { SourceCopilotPaywall } from '../Sourcing/SourceCopilotPaywall';
import { SourcingSearchResult } from '../Sourcing/SearchResults/SourcingSearchResult';
import { ProjectActionBar, ProjectActionButton } from './ProjectActions';
import { IProjectEmptyProps, ProjectEmptyState } from './ProjectEmptyState';
import { useUpdateSuggestedContact } from './useUpdateSuggestedContact';

export interface SourcingCopilotTabProps {
  isSourcingEnabled?: boolean;
  isAutoSourcing: AUTOSOURCING_STATUS;
  suggestedContacts?: ProjectSuggestedContactDto[];
  isFetching?: boolean;
  projectId: string;
  onChange?: () => void;
  onGenerateMore: () => void;
  handleAddCandidatesClick: () => void;
}

export enum AUTOSOURCING_STATUS {
  ACTIVE = 'active',
  PENDING = 'pending',
  INACTIVE = 'inactive',
}

type SourcingTabRow = UseRowSelectRowProps<ProjectSuggestedContactDto> &
  Row<ProjectSuggestedContactDto>;

export const SourcingCopilotTab = ({
  isSourcingEnabled,
  suggestedContacts,
  isFetching,
  projectId,
  onChange,
  handleAddCandidatesClick,
  onGenerateMore,
  isAutoSourcing,
}: SourcingCopilotTabProps) => {
  const navigate = useNavigate();
  const [selectedContacts, setSelectedContacts] = useState<SourcingTabRow[]>(
    [],
  );

  const { mutate: generateMore, isLoading: isLoadingGenerateMore } =
    useMutation(
      () =>
        apiClient.projectSuggestedContact.generateMore({
          requestBody: {
            projectId,
          },
        }),
      {
        onSuccess: () => {
          onChange?.();
          onGenerateMore();

          showToast({
            variant: 'success',
            title: 'Success!',
            description: 'Generating more candidates.',
          });
        },

        onError: () => {
          showToast({
            variant: 'danger',
            title: 'Something went wrong!',
            description: 'Failed to process action. Please try again.',
          });
        },
      },
    );

  const {
    addSuggestedContacts,
    removeSuggestedContacts,
    isLoading: isSuggestedContactUpdatesLoading,
  } = useUpdateSuggestedContact({
    onSuccess: onChange,
  });

  const fetchMoreSuggestedContactsHandler = () => {
    generateMore();
    logToSegment(Types.SEGMENT_EVENTS.GENERATE_MORE_CONTACTS_CLICKED, {
      buttonName: 'Request candidates',
      buttonLocation: 'Sourcing copilot tab',
      projectId,
    });
  };

  const emptyStateData = useMemo(
    (): {
      [key in AUTOSOURCING_STATUS]: IProjectEmptyProps;
    } => ({
      [AUTOSOURCING_STATUS.ACTIVE]: {
        title: 'Keep the momentum going!',
        subtitle:
          "You've actioned all your candidate suggestions. Request more candidates.",
        primaryButton: {
          label: 'Request More',
          onClick: fetchMoreSuggestedContactsHandler,
          loading: isLoadingGenerateMore,
        },
      },
      [AUTOSOURCING_STATUS.PENDING]: {
        title: 'Generating your suggestions!',
        subtitle:
          "We're analyzing your project to find relevant candidates for your search. This could take a few minutes.",
      },
      [AUTOSOURCING_STATUS.INACTIVE]: {
        title: "You've almost unlocked Copilot Sourcing!",
        subtitle:
          'Add at least 15 candidates to this project to begin receiving AI-powered candidate recommendations.',
        primaryButton: {
          label: 'Start Sourcing in Betterleap',
          onClick: () => navigate('/sourcing'),
        },
        secondaryButton: {
          label: 'Use the Chrome Extension',
          onClick: () => window.open(extensionUrl),
        },
        bottomButton: {
          label: 'Upload a CSV',
          icon: 'upload',
          onClick: handleAddCandidatesClick,
        },
      },
    }),
    [isLoadingGenerateMore],
  );

  const contactTableColumns = useMemo(
    () => [
      SourcingSearchResult({ center: true }),
      AddRemoveColumn({
        onAdd: (contactId) => {
          addSuggestedContacts([contactId]);
        },
        onRemove: (contactId) => {
          removeSuggestedContacts([contactId]);
        },
      }),
    ],
    [],
  );

  const contacts = useMemo(
    () =>
      (suggestedContacts ?? []).map((suggestedContact) => ({
        ...suggestedContact.rawData,
        id: suggestedContact.id,
      })),
    [suggestedContacts],
  );

  const handleAddSelectedContacts = async () => {
    const idList = selectedContacts.map((contact) => contact.original.id);
    await addSuggestedContacts(idList);

    selectedContacts.forEach((row) => {
      row.toggleRowSelected(false);
    });

    setSelectedContacts([]);
  };

  const handleRemoveSelectedContacts = async () => {
    const idList = selectedContacts.map((contact) => contact.original.id);
    await removeSuggestedContacts(idList);

    selectedContacts.forEach((row) => {
      row.toggleRowSelected(false);
    });

    setSelectedContacts([]);
  };

  const hasSelection = !!selectedContacts.length;
  const selectedCount = selectedContacts.length;

  if (!isSourcingEnabled) {
    return <SourceCopilotPaywall />;
  }

  return (
    <>
      <Box css={{ flex: 1, pb: 50 }}>
        <InteractiveTable
          css={{
            pt: 0,
            '& [role="rowgroup"] :first-child > span': {
              alignSelf: 'flex-start',
            },
            '& [role="row"] :last-child > div': {
              alignSelf: 'flex-start',
            },
          }}
          pageSize={50}
          tableData={contacts}
          columns={contactTableColumns}
          onRowSelect={({ selectedFlatRows }) => {
            setSelectedContacts(
              selectedFlatRows as unknown as SourcingTabRow[],
            );
          }}
          selectedRows={
            selectedContacts as unknown as Row<Record<string, unknown>>[]
          }
          disablePagination
        />
        {contacts.length === 0 && (
          <ProjectEmptyState {...emptyStateData[isAutoSourcing]} />
        )}
      </Box>
      <ProjectActionBar css={{ px: 28 }}>
        <Flex css={{ gap: 8 }}>
          <Tooltip content='Pass on selected'>
            <ProjectActionButton
              onClick={handleRemoveSelectedContacts}
              disabled={!hasSelection}
              css={{
                p: 12,
                fill: '$red-700',
                border: '1px solid $red-700',
                backgroundColor: '$background-component',
              }}
              name='minus-circle'
              label='Pass on candidates'
            />
          </Tooltip>
        </Flex>
        <Flex css={{ gap: 12 }}>
          {(isSuggestedContactUpdatesLoading || isFetching) && <Spinner />}
          <Text
            css={{
              fontSize: '$sm',
              color: '$neutral-blue-700',
              textAlign: 'right',
            }}
          >
            {hasSelection
              ? `${selectedCount} ${
                  selectedContacts.length === 1 ? 'candidate' : 'candidates'
                } selected`
              : `Select a candidate to take action`}
          </Text>
          {hasSelection && (
            <Button onClick={handleAddSelectedContacts}>Add to project</Button>
          )}
        </Flex>
      </ProjectActionBar>
    </>
  );
};
