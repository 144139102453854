import Papa from 'papaparse';

export const parseCsv = (file: File) =>
  new Promise<Record<string, string>[]>((resolve) => {
    Papa.parse(file, {
      skipEmptyLines: 'greedy',
      header: true,
      complete: ({ data }) => {
        resolve(data as Record<string, string>[]);
      },
    });
  });
