import {
  getLoginRedirectResult,
  showToast,
  Spinner,
  Types,
} from '@betterleap/shared';
import { User } from '@betterleap/client';
import { Col, Flex, Row, Text } from '@betterleap/ui';
import _, { get } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCookie, setCookie } from 'lib/cookies';
import LandingPageSignUp from '../../modules/SignUp/LandingPageSignUp/LandingPageSignUp';
import routeNames from '../../../constants/routeNames';
import getReferrer from '../../../helper/sourceReferrer';
import useFetch from '../../../hooks/fetch';
import TOKEN from '../../../lib/token';
import { selectMe } from '../../../store/api/selectors';
import { authActions } from '../../../store/auth/actions';
import VerifyEmailModal from '../../modules/Modal/VerifyEmailModal/VerifyEmailModal';
import UserSignInWithEmailLink from '../../modules/SignUp/UserSignIn/UserSignInWithEmailLink';
import landingGradientFull from '../../../assets/images/landing-gradient-full.png';
import { apiClient } from '../../../lib/apiClient';
import styles from './SignIn.module.scss';
import { PromotionColumn } from './PromotionColumn';
import { useSignup } from './useSignup';

interface SigninTemplateProp {
  verifyEmail?: boolean;
  isSignup?: boolean;
}

const SigninTemplate = ({
  verifyEmail,
  isSignup,
}: SigninTemplateProp): JSX.Element => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const location = useLocation();
  const me = useSelector(selectMe);
  const navigate = useNavigate();
  const storedEmailForSignup = window.localStorage.getItem('signupEmail');

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const invitationId = searchParams.get('invitationId');
  const invitationType = searchParams.get('invitationType');
  const type = searchParams.get('type');
  const customToken = searchParams.get('custom-token') || '';
  const source = searchParams.get('source') || getCookie('source');
  if (source) {
    setCookie('source', source);
  }
  const referrer = source ? getReferrer(source) : document.referrer;

  const showEmailVerifyLoading: boolean =
    useSelector((state) =>
      _.get(state, 'auth.isVerifyEmailLinkLoading', false),
    ) && !!storedEmailForSignup;

  const [invitation, getInvitation] =
    useFetch<Types.Invitations>('get_invitation');
  const [signupWithEmail, doFetchSignupWithEmail] = useFetch('email_signup');

  const [confirmationText, setConfirmationText] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirectedFromGoogleAuth, setRedirected] = useState(false);

  const firebaseRedirectKey = Object.keys(window.sessionStorage).filter((it) =>
    it.startsWith('firebase:pendingRedirect'),
  )[0];
  if (firebaseRedirectKey && !isRedirectedFromGoogleAuth) {
    setRedirected(true);
  }

  const showLandingPage =
    type &&
    [
      Types.INVITATION_TYPE.PROJECT_SHARE,
      Types.INVITATION_TYPE.LINKED_ORG,
    ].includes(invitationType as Types.INVITATION_TYPE);

  const token = TOKEN.get();

  const { signup, signupData } = useSignup();

  const shouldShowLoading =
    showEmailVerifyLoading ||
    signupData.loading ||
    isLoading ||
    isRedirectedFromGoogleAuth;

  const shouldRedirectToHome =
    token && me.data && me.data?.role !== User.role.NONE;

  const shouldRedirectToOnboarding =
    token && !!me.data && me.data?.role === User.role.NONE;

  const acceptInvitation = useMutation(
    (id: string) => apiClient.invitation.acceptInvitation({ id }),
    {},
  );

  const handleSignup = useCallback(signup, [
    type,
    invitationId,
    searchParams,
    referrer,
  ]);

  const onEmailSubmit = async (email: string) => {
    doFetchSignupWithEmail({
      email,
      invitationId,
      referrer,
      redirectUrl: get(location, 'state.redirectUrl.pathname'),
    });
    localStorage.setItem('signupEmail', email);
  };

  useEffect(() => {
    if (signupWithEmail.firedCount) {
      setConfirmationText(
        'We emailed you a magic link for a password-free sign in. Check your inbox to continue.',
      );
    }
  }, [signupWithEmail.firedCount]);

  useEffect(() => {
    if (signupWithEmail.error) {
      setConfirmationText('Something went wrong with sending email!');
    }
  }, [signupWithEmail.error]);

  useEffect(() => {
    if (invitationId) {
      getInvitation({ id: invitationId });
    }
  }, [invitationId]);

  useEffect(() => {
    if (invitation.data) {
      if (shouldRedirectToHome && !invitation.data.acceptedAt) {
        acceptInvitation.mutate(invitation.data.id);
      }
    }
  }, [invitation, shouldRedirectToHome]);

  useEffect(() => {
    getLoginRedirectResult(setIsLoading, handleSignup);
  }, [setIsLoading, handleSignup]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isAlreadyToasted = !!(signupData.error?.data as any)?.statusCode;

  useEffect(() => {
    if (!signupData.error || showLandingPage) {
      return;
    }
    if (signupData.error?.name === 'AuthInvitationEmailNotMatches') {
      showToast('Please sign up with invitation mail!', {}, 'error');
    } else if (signupData.error?.name && !isAlreadyToasted) {
      showToast('Something went wrong. Please try again later.', {}, 'error');
    }
    setIsLoading(false);
    setRedirected(false);
    navigate(routeNames.signup());
  }, [signupData.error]);

  useEffect(() => {
    if (
      verifyEmail &&
      !showEmailVerifyLoading &&
      customToken &&
      storedEmailForSignup
    ) {
      dispatch(
        authActions.confirmEmailLink(
          {
            token: customToken,
            email: storedEmailForSignup || '',
          },
          { navigate, location },
        ),
      );
    }
  }, [
    verifyEmail,
    showEmailVerifyLoading,
    customToken,
    storedEmailForSignup,
    dispatch,
  ]);

  useEffect(() => {
    if (shouldRedirectToOnboarding) {
      navigate(routeNames.onboarding());
    }
  }, [shouldRedirectToOnboarding]);

  useEffect(() => {
    if (shouldRedirectToHome) {
      navigate(routeNames.home(), {
        state: { redirectUrl: location },
      });
    }
  }, [shouldRedirectToHome]);

  if (shouldShowLoading && !showLandingPage)
    return (
      <Flex
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Spinner variant='blue' />
      </Flex>
    );

  return showLandingPage ? (
    <LandingPageSignUp
      setIsLoading={setIsLoading}
      invitedBy={searchParams.get('invitedBy')}
      invitee={searchParams.get('invitee')}
      error={!!signupData.error}
      loading={shouldShowLoading}
    />
  ) : (
    <Row>
      <Col
        span={{
          '@initial': 12,
          '@lg': 6,
        }}
        css={{
          p: 56,
          display: 'flex',
          backgroundImage: `url(${landingGradientFull})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Flex vertical css={{ padding: 20, flex: 1, alignItems: 'center' }}>
          {me.loading && (
            <Flex
              justify='center'
              css={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              }}
            >
              <Spinner variant='blue' />
            </Flex>
          )}
          {!me.loading && (
            <>
              {invitation.data && (
                <div>
                  <Text
                    css={{
                      color: 'white',
                      fontSize: '1.125rem',
                      fontWeight: 500,
                      textAlign: 'center',
                    }}
                  >
                    {`${
                      invitation?.data?.invitedBy?.firstName ||
                      invitation?.data?.invitedBy?.lastName
                        ? `${invitation.data?.invitedBy?.firstName} ${invitation.data?.invitedBy?.lastName}`
                        : 'Your colleague'
                    } is inviting you!`}
                  </Text>
                </div>
              )}

              <UserSignInWithEmailLink
                onSignIn={(data) =>
                  onEmailSubmit((data.email as string).toLowerCase().trim())
                }
                type={type as 'company' | 'recruiter' | null}
                loading={signupWithEmail.loading}
                confirmationText={confirmationText}
                setIsLoading={setIsLoading}
                isSignup={isSignup}
              />

              <VerifyEmailModal
                isOpen={!!verifyEmail && !storedEmailForSignup}
                modalClassname={`${styles.verifymodal} shadow-xl`}
                token={customToken}
              />
            </>
          )}
        </Flex>
      </Col>
      <PromotionColumn />
    </Row>
  );
};

export default SigninTemplate;
