import { InteractiveTable } from '@betterleap/shared';
import { ExtendedContactProject } from '@betterleap/client';
import { Flex, Text } from '@betterleap/ui';
import { OpensColumn } from 'components/elements/CandidatesTable/OpensColumn';
import { ProjectNameColumn } from 'components/elements/CandidatesTable/ProjectNameColumn';
import { SequenceColumn } from 'components/elements/CandidatesTable/SequenceColumn';
import { StatusColumn } from 'components/elements/CandidatesTable/StatusColumn';

export interface ContactProfileProjectsProps {
  contactProjects: ExtendedContactProject[];
}

const ContactProfileProjects = (props: ContactProfileProjectsProps) => {
  const { contactProjects } = props;

  const contactTableColumns = [
    ProjectNameColumn(),
    SequenceColumn(),
    StatusColumn(),
    OpensColumn(),
  ];

  return (
    <Flex vertical css={{ gap: 20, height: '100%', width: '100%' }}>
      <Text as='h3' css={{ fontWeight: '$medium', fontSize: '$lg' }}>
        {contactProjects.length ? 'Projects' : 'Contact is not in any projects'}
      </Text>
      {contactProjects.length > 0 && (
        <InteractiveTable
          tableData={contactProjects}
          columns={contactTableColumns}
        />
      )}
    </Flex>
  );
};

export default ContactProfileProjects;
