import '@launchnotes/embed/dist/esm/launchnotes-embed';

export const LaunchNotesWidget = ({ userId }) => (
  <launchnotes-embed
    token={process.env.REACT_APP_LAUNCH_NOTES_TOKEN}
    project={process.env.REACT_APP_LAUNCH_NOTES_PROJECT_ID}
    toggle-selector='#whats-new-link'
    primary-color='#5A9AFC'
    heading-color='white'
    widget-placement='bottom-end'
    widget-offset-distance='16'
    show-unread
    anonymous-user-id={userId}
    unread-offset-distance='-34'
    unread-offset-skidding='16'
    unread-placement='right-start'
    unread-background-color='#F97316'
    unread-text-color='#ffffff'
  />
);
