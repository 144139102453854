import { makeNamePossessive } from '@betterleap/shared';
import { Sender, Sequence } from '@betterleap/client';
import { Alert, AlertHeading, AlertingIcon } from '@betterleap/ui';

interface Props {
  sequence: Sequence;
  senderName?: string;
}

export const PausedSequenceBanner = ({ sequence, senderName }: Props) => {
  const message = pausedMessage({ sequence, senderName });
  return !message ? null : (
    <Alert variant='warning' css={{ mb: '$20', display: 'flex' }}>
      <AlertingIcon />
      <AlertHeading>{message}</AlertHeading>
    </Alert>
  );
};

function pausedMessage({ sequence, senderName }: Props) {
  if (!sequence.sender) {
    return 'This sequence is stopped because the sending email has been removed from your organization.';
  }

  if (!sequence.enabled) {
    return 'This sequence is currently paused and is not sending any emails';
  }
  if (sequence.sender?.status === Sender.status.PAUSED_TEMPORARILY) {
    const s = senderName ? makeNamePossessive(senderName) : `the sender's`;
    return `This sequence is temporarily paused due to ${s} sending limits`;
  }
  return null;
}
