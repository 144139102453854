import React from 'react';
import EmailFinderTemplate from '../components/templates/EmailFinder/EmailFinder.template';

const EmailFinder = () => (
  <React.StrictMode>
    <EmailFinderTemplate />
  </React.StrictMode>
);

export default EmailFinder;
