import { AlertIconProps, IconProps } from '@betterleap/ui';

export interface Prompt {
  icon: IconProps['name'];
  variant: AlertIconProps['variant'];
  description: string;
  title: string;
  prompt: string;
  route?: string;
}

export const prompts: Prompt[] = [
  {
    icon: 'paper-airplane',
    variant: 'info',
    title: 'Outreach email',
    description:
      'Write an outreach email to a potential candidate with your company pitch.',
    prompt: `
    <p>As a recruiter, write an outreach email to a potential candidate.</p>
    <br />
    <p>I'm hiring <strong>[role title]</strong> for <strong>[company name]</strong>.</p>
    <br />
    <p>Why this candidate should join:</p>
    <br />
    <ul>
      <li><strong>[benefit 1]</strong></li>
      <li><strong>[benefit 2]</strong></li>
      <li><strong>[benefit 3]</strong></li>
    </ul>
`,
  },
  {
    icon: 'pencil',
    variant: 'warning',
    title: 'Job description',
    description: 'Write amazing job descriptions in seconds.',
    prompt: `
    <p>As a recruiter, write a comprehensive job description.</p>
    <br />
    <p>Company name: <strong>[company name]</strong></p>
    <br />
    <p>Job title: <strong>[job title]</strong></p>
    <br />
    <p>Qualifications:</p>
    <br />
    <ul>
      <li><strong>[skill 1]</strong></li>
      <li><strong>[skill 2]</strong></li>
      <li><strong>[skill 3]</strong></li>
    </ul>
  `,
  },
  {
    icon: 'paper-airplane',
    variant: 'info',
    title: 'Sales email',
    description:
      'Create an email to a potential client offering them your recruiting services.',
    prompt: `
    <p>As an AE at a recruiting firm, write an engaging sales email offering recruiting services to a potential client.</p>
    <br />
    <p>Roles we hire for:</p>
    <ul>
      <li><strong>[role 1]</strong></li>
      <li><strong>[role 2]</strong></li>
      <li><strong>[role 3]</strong></li>
    </ul>
    <br />
    <p>Why this client should consider us:</p>
    <ul>
      <li><strong>[value prop 1]</strong></li>
      <li><strong>[value prop 2]</strong></li>
      <li><strong>[value prop 3]</strong></li>
    </ul>  
  `,
  },
  {
    icon: 'paper-airplane',
    variant: 'info',
    title: 'Offer extended email',
    description:
      'Write an email to a candidate congratulating them on a job offer.',
    prompt: `
    <p>As a recruiter, write an offer extended email for a candidate.</p>
    <br />
    <p>Company name: <strong>[company name]</strong></p>
    <br />
    <p>Job title: <strong>[job title]</strong></p>
    <br />
    <p>Next steps: <strong>[describe the next steps]</strong></p>
  `,
  },
  {
    icon: 'paper-airplane',
    variant: 'info',
    title: 'Rejection email',
    description:
      'Write an email to a candidate telling them about their candidacy being rejected.',
    prompt: `
    <p>As a recruiter, write a candidate rejection email.</p>
    <br />
    <p>Company name: <strong>[company name]</strong></p>
    <br />
    <p>Job title: <strong>[job title]</strong></p>
    <br />  
    <p>Rejection reason: <strong>[rejection reason]</strong></p>
  `,
  },
  {
    icon: 'color-swatch',
    variant: 'success',
    title: 'Interview questions',
    description:
      'Write questions for your upcoming interviews with candidates.',
    prompt: `
    <p>As a recruiter, write a list of interview questions.</p>
    <br />
    <p>Job title: <strong>[job title]</strong></p>
  `,
  },
  {
    icon: 'menu-alt-1',
    variant: 'danger',
    title: 'Job training',
    description:
      'Learn the key concepts and vital skills required to excel as a recruiter.',
    prompt: `
    <p>You’re going through a recruiter job training.</p>
    <br />
    <p>Teach me how to: <strong>[for example, how to source on LinkedIn]</strong></p>    
  `,
  },
  {
    icon: 'search',
    variant: 'purple',
    title: 'Sourcing',
    description: 'Generate a list of companies to source candidates from.',
    prompt: `
    <p>You’re a recruiter sourcing for open roles.</p>
    <br />
    <p>Generate a bullet list of the top <strong>[industry names]</strong> companies that are based in <strong>[location]</strong
  `,
  },
  // Clicking on this card will route you to the analytics page rather than updating the prompt
  {
    icon: 'chart-pie',
    variant: 'orange',
    title: 'Analytics',
    description:
      'Uncover valuable insights to streamline your recruiting process.',
    prompt: '',
    route: '/copilot/analytics',
  },
];
