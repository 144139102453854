import { LoadingArea } from '@betterleap/shared';
import {
  AlertIcon,
  AlertIconProps,
  Box,
  Button,
  Flex,
  Modal,
  ModalProps,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';
import React from 'react';

export interface ConfirmModalProps extends ModalProps {
  title: string;
  description: React.ReactNode;
  icon: {
    variant: AlertIconProps['variant'];
    name: AlertIconProps['name'];
  };
  confirmationText: string;
  dismissButtonText?: string;
  notices?: React.ReactNode;
  variant?: 'primary' | 'danger';
  isLoading?: boolean;
}

export const ConfirmModal: PromiseModal<ConfirmModalProps> = ({
  onSubmit,
  onDismiss,
  title,
  description,
  icon,
  notices,
  dismissButtonText = 'Cancel',
  variant = 'primary',
  confirmationText,
  isLoading,
  ...rest
}) => (
  <Modal {...rest}>
    <LoadingArea isLoading={isLoading}>
      <Flex css={{ alignItems: 'center' }} vertical>
        <Box css={{ py: 16 }}>
          <AlertIcon size='lg' variant={icon.variant} name={icon.name} />
        </Box>
        <ModalTitle>{title}</ModalTitle>
        <Text size='sm' css={{ color: '$text-lightest', textAlign: 'center' }}>
          {description}
        </Text>
        {notices && notices}
        <Flex justify='center' css={{ gap: 8, pt: 24, width: '100%' }}>
          <Button
            variant='secondary'
            onClick={onDismiss}
            dataCy='Dismiss button'
          >
            {dismissButtonText}
          </Button>
          <Button
            variant={variant}
            onClick={onSubmit}
            dataCy='Confirmation button'
          >
            {confirmationText}
          </Button>
        </Flex>
      </Flex>
    </LoadingArea>
  </Modal>
);
