const getReferrer = (source: string) => {
  switch (source) {
    case 'westcap':
      return 'https://betterleap.com/westcap/';
    default:
      return source;
  }
};

export default getReferrer;
