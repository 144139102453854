import {
  Input,
  InputProps,
  Select,
  SelectItem,
  SelectProps,
} from '@betterleap/ui';
import { format, isValid, parse } from 'date-fns';
import trim from 'lodash/trim';
import { useMemo } from 'react';

export const AmPmSelect = ({ css, ref, ...rest }: SelectProps) => (
  <Select
    ref={ref}
    css={{
      backgroundColor: '$background-muted',
      paddingLeft: 16,
      width: 80,
      height: '100%',
      marginRight: -8,
      fontSize: '$sm',
      color: '$text-light',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      border: 'none',
      borderLeft: '1px solid $border-light',
      focus: {
        boxShadow: 'none',
        backgroundColor: '$background-dark',
      },
      ...css,
    }}
    {...rest}
  >
    <SelectItem value='AM'>AM</SelectItem>
    <SelectItem value='PM'>PM</SelectItem>
  </Select>
);

export interface TimeInputProps extends Omit<InputProps, 'onChange' | 'value'> {
  onChange?: (time: string) => void;
  value?: string;
}

export const TimeInput = ({
  css,
  value = '',
  onChange,
  ...props
}: TimeInputProps) => {
  const [parsedTime, parsedAmPm] = useMemo(() => {
    // check if time is 24 hour format and parse if it is
    const parsedDate = /\d\d:\d\d:\d\d/.test(value)
      ? parse(value, 'HH:mm:ss', new Date())
      : parse(value, 'hh:mm a', new Date());

    if (!isValid(parsedDate)) {
      if (value.includes('PM')) return ['', 'PM'];
      return ['', 'AM'];
    }

    return [format(parsedDate, 'hh:mm'), format(parsedDate, 'a')];
  }, [value]);

  const handleChange = (newTime: string) => {
    onChange?.(`${trim(newTime)} ${parsedAmPm}`);
  };

  const handleAmPmChange = (newAmPm: string) => {
    onChange?.(`${parsedTime} ${newAmPm}`);
  };

  return (
    <Input
      aria-label='Enter Time'
      defaultValue={parsedTime}
      onChange={handleChange}
      css={{ width: 160, ...css }}
      placeholder='10:30'
      rightIcon={
        <AmPmSelect defaultValue={parsedAmPm} onChange={handleAmPmChange} />
      }
      {...props}
    />
  );
};
