import routeNames from 'constants/routeNames';
import { Flex, Text } from '@betterleap/ui';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const SequenceColumn = () => ({
  Header: 'SEQUENCE',
  accessor: (contact: {
    id: string;
    sequenceName?: string;
    sequenceId?: string;
    lastContactedAt?: Date;
  }) => (
    <>
      <Flex css={{ gap: 12 }}>
        {!!contact.sequenceName && !!contact.sequenceId && (
          <Link
            id={contact.id}
            to={routeNames.sequenceDetail({
              id: contact.sequenceId,
            })}
          >
            <Text
              css={{
                fontSize: '$sm',
                lineHeight: '$2xl',
                color: '$blue-500',
                hover: {
                  textDecoration: 'underline',
                },
              }}
            >
              {contact.sequenceName}
            </Text>
          </Link>
        )}
      </Flex>
      {!!contact.lastContactedAt && (
        <Text css={{ color: '$text-lightest', fontSize: '$xs' }}>
          Last contacted on {moment(contact.lastContactedAt).format('ll')}
        </Text>
      )}
    </>
  ),
  slot: 'custom',
});
