import { Avatar } from '@betterleap/shared';
import { PastRecruiterClientDto } from '@betterleap/client';
import { Box, Flex } from '@betterleap/ui';
import { EditIcon } from '../RecruiterProfile.elements';

interface RecruiterProfileClientProps {
  client: PastRecruiterClientDto;
  onEditClick?: (client: PastRecruiterClientDto) => void;
}

export const RecruiterProfileClient = ({
  client,
  onEditClick,
}: RecruiterProfileClientProps) => (
  <Box key={client.id} css={{ position: 'relative' }}>
    {!!onEditClick && <EditIcon onClick={() => onEditClick(client)} />}
    <Flex
      css={{
        width: '100%',
        p: 12,
        border: '1px solid $neutral-blue-300',
        borderRadius: 16,
        mediaLg: {
          width: 'unset',
        },
      }}
    >
      <Flex
        css={{
          height: 40,
          width: 40,
          borderRadius: 12,
          border: '1px solid $neutral-blue-400',
          justifyContent: 'center',
        }}
      >
        <Avatar
          image={client.logo}
          name={client.companyName}
          color='#3A83F7'
          size={24}
        />
      </Flex>
      <Box css={{ ml: 12 }}>{client.companyName}</Box>
    </Flex>
  </Box>
);
