import { Button, ButtonProps, Icon } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { forwardRef, useState } from 'react';
import { useQuery } from 'react-query';
import { MoveToMenu, MoveToMenuProps } from '../Projects/ProjectActions';
import { useCreateProject } from '../Projects/useProjectServices';

export interface AddToProjectButtonProps
  extends Omit<MoveToMenuProps, 'onChange' | 'children' | 'ref'> {
  disabled?: boolean;
  onChange?: (ids: string[]) => void;
  size?: ButtonProps['size'];
  modal?: boolean;
}

export const AddToProjectButton = forwardRef<
  HTMLButtonElement,
  AddToProjectButtonProps
>(({ disabled, size, onChange, modal, ...rest }, ref) => {
  const [projectSearchFilter, setProjectSearchFilter] = useState<string>('');

  const {
    data: projectsResponse,
    isLoading: projectsLoading,
    refetch,
  } = useQuery(['get_projects', { projectSearchFilter }], () =>
    apiClient.project.findByUserRecentlyOrdered({
      search: projectSearchFilter,
    }),
  );

  const createProject = useCreateProject({});

  const projectsList = projectsResponse?.data ?? [];

  return (
    <MoveToMenu
      title='Add to Project'
      options={projectsList}
      loading={projectsLoading}
      placeholder='Search or create a project'
      onChange={(ids) => {
        refetch();
        onChange?.(ids as string[]);
      }}
      onFilterChange={setProjectSearchFilter}
      onCreateProject={async (name) => {
        const result = await createProject.mutateAsync({
          name,
          source: 'Sourcing tab',
        });

        onChange?.([result.data.id]);
      }}
      modal={modal}
      {...rest}
    >
      <Button ref={ref} size={size} disabled={disabled}>
        Add to Project
        <Icon
          name='chevron-down'
          size={16}
          css={{ ml: 8 }}
          color='$neutral-blue-100'
        />
      </Button>
    </MoveToMenu>
  );
});
