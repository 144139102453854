import React from 'react';
import SigninTemplate from '../components/templates/SignIn/SignIn.template';

interface SignInProps {
  verifyEmail?: boolean;
}

const SignIn = ({ verifyEmail }: SignInProps) => (
  <React.StrictMode>
    <SigninTemplate verifyEmail={verifyEmail} />
  </React.StrictMode>
);

export default SignIn;
