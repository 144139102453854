import { Flex, FlexProps } from '@betterleap/ui';

export const StickyButtonBar = (props: FlexProps) => (
  <Flex
    justify='end'
    css={{
      gap: 8,
      position: 'sticky',
      right: 24,
      background: '$background-component',
      bottom: 8,
    }}
    {...props}
  />
);
