import { ContactDrawer } from '../modules/Drawer/ContactDrawer/ContactDrawer';

function withContactDrawer<P extends JSX.IntrinsicAttributes>(
  WrappedComponent: React.ComponentType<P>,
) {
  return (props: P) => (
    <>
      <ContactDrawer />
      <WrappedComponent {...props} />
    </>
  );
}

export default withContactDrawer;
