import {
  ActionsDropdown,
  ActionsDropdownProps,
  InteractiveTable,
  ProjectTypeIcon,
  Types,
} from '@betterleap/shared';
import {
  Contact,
  GetProjectShareDto,
  Organization,
  ProjectWithSharedDataDto,
  ShareDto,
  ShareProjectDto,
} from '@betterleap/client';
import {
  AlertIconProps,
  Badge,
  Box,
  Button,
  ClippedList,
  Flex,
  showToast,
  Text,
  Tooltip,
  useModal,
} from '@betterleap/ui';
import qs from 'qs';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useAnalytics from 'hooks/analytics';
import routeNames from '../../../constants/routeNames';
import { apiClient } from '../../../lib/apiClient';
import { selectOrganization } from '../../../store/api/selectors';
import CreateProjectModal, {
  CreateProjectModalProps,
} from '../../modules/Modal/CreateProjectModal/CreateProjectModal';
import PaywallPopupModal from '../../modules/Modal/PaywallPopupModal/PaywallPopupModal';
import SequenceConfirmationModal from '../../modules/Modal/SequenceConfirmationModal/SequenceConfirmationModal';
import { ListProjectsEmptyState } from './ListProjectsEmptyState';
import { NewProjectButton } from './NewProjectButton.template';
import {
  useCreateProject,
  useGetProjects,
  useGetSharedProjects,
} from './useProjectServices';
import { ProjectsKeys, ProjectsTabs } from './ProjectsTabs';
import { ProjectTagList } from './ProjectTagList';
import { SuggestedContactsLink } from './SuggestedContactsLink';

interface LocationStateData {
  showCreateProjectModal: boolean;
}

const PAGE_SIZE = 25;

function constructCreator(value: ProjectWithSharedDataDto) {
  const first = value.creatorFirstName || '';
  const last = value.creatorLastName || '';
  return `${first} ${last}`.trim();
}

const sharedCellProps = {
  getCellAttrs: (value: ProjectWithSharedDataDto) => {
    return {
      'data-has-suggested-contacts': value.suggestedContactCount > 0,
    };
  },
  css: {
    '&[data-has-suggested-contacts="true"]': {
      pb: 44,
    },
  },
};

const projectsColumns = (onArchiveClick?: (projectId: string) => void) => ({
  columns: [
    {
      id: 'name',
      Header: 'PROJECT NAME',
      accessor: (value: ProjectWithSharedDataDto) => (
        <>
          <Text
            css={{
              color: '$neutral-blue-1100',
              fontSize: '$sm',
            }}
          >
            {value.name}
            {value.contactEmailEnrichmentMode ===
              Contact.contactEmailEnrichmentMode.PROFESSIONAL && (
              <Flex
                css={{
                  pb: 1,
                  ml: 8,
                  width: 20,
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                <ProjectTypeIcon mode={value.contactEmailEnrichmentMode} />
              </Flex>
            )}
          </Text>
          <Text css={{ color: '$text-lightest', fontSize: '$xs' }}>
            {constructCreator(value)}
          </Text>
          {value.suggestedContactCount > 0 && (
            <SuggestedContactsLink
              css={{ mt: 8 }}
              projectId={value.id}
              suggestedContactsCount={value.suggestedContactCount}
            />
          )}
        </>
      ),
      slot: 'custom',
      width: 250,
      sortable: true,
    },
    {
      ...sharedCellProps,
      Header: 'PROJECT TAGS',
      accessor: (value: ProjectWithSharedDataDto) =>
        value.tags?.length ? (
          <ProjectTagList max={1} tags={value.tags} />
        ) : (
          <Text
            css={{
              fontSize: '$sm',
              color: '$neutral-blue-700',
            }}
          >
            --
          </Text>
        ),
      slot: 'custom',
      width: 170,
    },
    {
      ...sharedCellProps,
      Header: 'IN PROJECT',
      accessor: 'contactsTotalCount',
      width: 120,
      sortable: true,
    },
    {
      ...sharedCellProps,
      id: 'contactsMessagedCount',
      Header: 'CONTACTED',
      accessor: 'contactsMessagedCount',
      width: 130,
      sortable: true,
    },
    {
      ...sharedCellProps,
      id: 'contactsOpenedCount',
      Header: 'OPENED',
      accessor: 'contactsOpenedCount',
      width: 120,
      sortable: true,
    },
    {
      ...sharedCellProps,
      id: 'contactsRepliedCount',
      Header: 'REPLIED',
      accessor: 'contactsRepliedCount',
      width: 120,
      sortable: true,
    },
    {
      ...sharedCellProps,
      id: 'contactsInterestedCount',
      Header: 'INTERESTED',
      accessor: 'contactsInterestedCount',
      width: 140,
      sortable: true,
    },
    {
      Header: 'SHARED WITH',
      ...sharedCellProps,
      css: {
        overflowX: 'hidden',
        '& > div': {
          width: '100%',
        },
        '&[data-has-suggested-contacts="true"]': {
          pb: 44,
        },
      },
      accessor: (value: ProjectWithSharedDataDto) => (
        <ClippedList<ShareDto & { id: string }>
          items={value.sharedWith.map((share) => ({
            id: share.email,
            ...share,
          }))}
          tooltipProps={{
            variant: 'light',
            side: 'bottom',
          }}
        >
          {(share, isOverflow) =>
            isOverflow ? (
              <Badge
                shape='rectangle'
                css={{
                  display: 'block',
                  '& + &': {
                    mt: 6,
                  },
                }}
                variant={share.accepted ? 'success' : 'outline'}
              >
                {share.name}
              </Badge>
            ) : (
              <Tooltip content={share.accepted ? 'Accepted' : 'Pending'}>
                <Badge
                  shape='rectangle'
                  css={{ mr: 8 }}
                  variant={share.accepted ? 'success' : 'outline'}
                >
                  {share.name}
                </Badge>
              </Tooltip>
            )
          }
        </ClippedList>
      ),
      slot: 'custom',
      width: 120,
    },
    {
      ...sharedCellProps,
      Header: '',
      id: 'menu',
      menu: true,
      width: 42,
      accessor: (project: ProjectWithSharedDataDto) => {
        const options: ActionsDropdownProps['options'] = [
          {
            label: 'Archive',
            color: '#FF8A8A',
            onClick: () => onArchiveClick?.(project.id),
          },
        ];
        return <ActionsDropdown value={project.sequenceId} options={options} />;
      },
    },
  ],
});

const sharedProjectsColumns = {
  columns: [
    {
      Header: 'PROJECT NAME',
      accessor: (value: GetProjectShareDto) => (
        <Text
          css={{
            color: '$neutral-blue-1100',
            fontSize: '$sm',
          }}
        >
          {value.projectName}
        </Text>
      ),
      width: 250,
    },
    {
      Header: 'IN PROJECT',
      accessor: 'contactsTotalCount',
      width: 170,
    },
    {
      Header: 'CONTACTED',
      accessor: 'contactsMessagedCount',
      width: 120,
    },
    {
      Header: 'OPENED',
      accessor: 'contactsOpenedCount',
      width: 120,
    },
    {
      Header: 'REPLIED',
      accessor: 'contactsRepliedCount',
      width: 130,
    },
    {
      Header: 'INTERESTED',
      accessor: 'contactsInterestedCount',
      width: 120,
    },
    {
      Header: 'SHARED BY',
      accessor: (value: GetProjectShareDto) => (
        <Text css={{ color: '$neutral-blue-1100', fontSize: '$sm' }}>
          {value.sharedBy}
        </Text>
      ),
      width: 140,
    },
    {
      Header: 'ACCESS',
      accessor: () => (
        <Badge shape='rectangle' variant='warning'>
          View Only
        </Badge>
      ),
      slot: 'custom',
      width: 162,
    },
  ],
};

const ListProjects = (): JSX.Element => {
  const organization = useSelector(selectOrganization);
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = useAnalytics();

  const { search } = useLocation();
  const queryParams = qs.parse(search.slice(1));

  const openCreateProjectModal = useModal<
    CreateProjectModalProps,
    CreateProjectModalProps
  >(CreateProjectModal);

  const openPaywallPopupModal = useModal<unknown>(PaywallPopupModal);

  useEffect(() => {
    if ((location.state as LocationStateData)?.showCreateProjectModal) {
      handleCreateProject(Contact.contactEmailEnrichmentMode.PERSONAL);
    }
  }, [location]);

  const createProject = useCreateProject({
    onSuccess: () => {
      showToast({
        title: 'Success!',
        description: 'Project created.',
      });
      refetch();
    },
  });

  const handleCreateProject = async (
    optionalMode?: Contact.contactEmailEnrichmentMode,
  ) => {
    if (organization?.data?.tier === Organization.tier.PAYWALL) {
      await openPaywallPopupModal();
    } else {
      const mode = optionalMode || Contact.contactEmailEnrichmentMode.PERSONAL;
      const result = await openCreateProjectModal({ projectName: '', mode });

      if (result) {
        // create project
        createProject.mutate({
          name: result.projectName,
          contactEmailEnrichmentMode: result.mode,
          source: 'Projects',
        });
      }
    }
  };

  const isPaywall = organization?.data?.tier === Organization.tier.PAYWALL;
  const sharedParams = { q: queryParams, pageSize: PAGE_SIZE };
  const basicParams = { ...sharedParams, enabled: !isPaywall };
  const areThereMultipleProjectTypes =
    organization?.data?.features?.enabledSalesProjects;

  const keys = ProjectsKeys;
  const all = useGetProjects({ key: keys.all, ...basicParams });
  const my = useGetProjects({ key: keys.my, ...basicParams, onlyMine: true });
  const shared = useGetSharedProjects({ key: keys.shared, ...sharedParams });

  const refetch = () => {
    my.refetch();
    all.refetch();
  };

  const archiveProject = useMutation(
    (projectId: string) => apiClient.project.delete({ id: projectId }),
    {
      onSuccess: () => {
        showToast({
          title: 'Success!',
          description: 'Your project is now archived.',
        });
        refetch();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to archive project. Please try again.',
        });
      },
    },
  );

  const openConfirmationModal = useModal<{
    title: string;
    notes: { id: number; name: string }[];
    description: string;
    icon: { variant: AlertIconProps['variant']; name: AlertIconProps['name'] };
    confirmButtonText: string;
    confirmButtonVariant?: string;
  }>(SequenceConfirmationModal);

  const goToProjectPage = (projectId: string, openInNewTab: boolean) => {
    if (openInNewTab) {
      window.open(`projects/${projectId}`, '_blank');
    } else {
      navigate(routeNames.project({ id: projectId }), {
        state: { from: location },
      });
    }
  };

  const onRowClick = async (commandPressed: boolean, data: unknown) => {
    goToProjectPage((data as ProjectWithSharedDataDto).id, commandPressed);
  };

  const onSharedRowClick = async (commandPressed: boolean, data: unknown) => {
    goToProjectPage((data as ShareProjectDto).projectId, commandPressed);
  };

  const onArchiveClick = async (projectId: string) => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Archive',
      buttonLocation: 'Projects',
    });
    const result = await openConfirmationModal({
      title: 'Are you sure you would like to archive this project?',
      notes: [
        {
          id: 1,
          name: 'If there is a sequence in this project, archiving will pause the sequence. Candidates will not continue to receive emails.',
        },
        {
          id: 2,
          name: 'Archiving will hide this project from your list view.',
        },
      ],
      description: '',
      icon: { variant: 'danger', name: 'emoji-sad' },
      confirmButtonText: 'Archive project',
      confirmButtonVariant: 'danger',
    });

    if (result) {
      archiveProject.mutate(projectId);
    }
  };

  const columns = projectsColumns(onArchiveClick);
  const showProjectTabs =
    !!queryParams.search ||
    !!queryParams.tags ||
    !!all.data?.data?.length ||
    all.isLoading ||
    my.isLoading;
  return (
    <Box
      css={{
        p: 32,
        height: 'unset',
        mediaLg: {
          minHeight: all.data?.data?.length ? '100%' : 'calc(100% + 70px)',
        },
        position: 'relative',
        overflow: 'auto',
      }}
    >
      <Flex css={{ justifyContent: 'space-between', mb: 8 }}>
        <Text as='h1' variant='heading-1' css={{ fontWeight: '$medium' }}>
          Projects
        </Text>
        <Flex css={{ gap: 8 }}>
          {areThereMultipleProjectTypes ? (
            <NewProjectButton handleCreateProject={handleCreateProject} />
          ) : (
            <Button onClick={() => handleCreateProject()}>New Project</Button>
          )}
        </Flex>
      </Flex>

      <>
        {showProjectTabs && (
          <ProjectsTabs
            allProjects={all.data}
            myProjects={my.data}
            isLoading={all.isLoading || my.isLoading}
            pageSize={PAGE_SIZE}
            onRowClick={onRowClick}
            columns={columns.columns}
          />
        )}
        {!!shared.data?.data.length && (
          <>
            <Text
              css={{
                color: '$neutral-blue-900',
                fontWeight: '$medium',
                fontSize: '$lg',
                mt: !all.data?.data?.length ? 48 : 0,
              }}
            >
              Betterleap Connect
            </Text>
            <InteractiveTable
              tableData={shared.data.data || [{}]}
              onRowClick={onSharedRowClick}
              columns={sharedProjectsColumns.columns}
              count={shared.data.meta?.count ?? 0}
              loading={shared.isLoading}
              paginationKey='sharedProject'
              pageSize={PAGE_SIZE}
            />
          </>
        )}
        {!queryParams.search &&
          !queryParams.tags &&
          !all.isLoading &&
          !all.data?.data?.length && (
            <Box
              css={{
                marginTop: shared.data?.data.length ? '$48' : '$128',
              }}
            >
              <ListProjectsEmptyState
                onCreateProject={handleCreateProject}
                areThereMultipleProjectTypes={!!areThereMultipleProjectTypes}
              />
            </Box>
          )}
      </>
    </Box>
  );
};

export default ListProjects;
