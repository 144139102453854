import {
  AlertIcon,
  AlertIconProps,
  Box,
  Button,
  Flex,
  Modal,
  ModalProps,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';
import React from 'react';

export interface AlertModalProps {
  title: string;
  description: React.ReactNode;
  icon: {
    variant: AlertIconProps['variant'];
    name: AlertIconProps['name'];
  };
  confirmationText: string;
  notices?: React.ReactNode;
  size?: ModalProps['size'];
}

export const AlertModal: PromiseModal<AlertModalProps> = ({
  onSubmit,
  title,
  description,
  icon,
  notices,
  confirmationText,
  size,
}) => (
  <Modal size={size}>
    <Flex css={{ alignItems: 'center' }} vertical>
      <Box css={{ py: 16 }}>
        <AlertIcon variant={icon.variant} name={icon.name} />
      </Box>
      <ModalTitle>{title}</ModalTitle>
      <Text size='sm' css={{ color: '$text-lightest', textAlign: 'center' }}>
        {description}
      </Text>
      {notices && notices}
      <Flex justify='center' css={{ gap: 8, pt: 24, width: '100%' }}>
        <Button onClick={onSubmit} dataCy='Confirmation button'>
          {confirmationText}
        </Button>
      </Flex>
    </Flex>
  </Modal>
);
