import { ContactPreview } from '@betterleap/client';
import { Flex, Icon } from '@betterleap/ui';

interface ContactProfileLinksProps {
  contact: ContactPreview;
  onLinkClicked?: () => void;
}

type SocialIcon = 'linked-in' | 'twitter' | 'github' | 'facebook';

export const ContactProfileLinks = ({
  contact,
  onLinkClicked,
}: ContactProfileLinksProps) => {
  const socials: Record<SocialIcon, string | undefined> = {
    'linked-in': contact.linkedinUrl,
    twitter: contact.twitterUrl,
    github: contact.githubUrl,
    facebook: contact.facebookUrl,
  };
  const links = Object.entries(socials).filter(([, v]) => !!v);
  return (
    <Flex css={{ gap: 8 }}>
      {links.map(([icon, url]) => (
        <a href={url} target='_blank' rel='noreferrer' key={icon}>
          <Icon
            name={icon as SocialIcon}
            color='$neutral-blue-500'
            size={16}
            // fill set by style can only be overridden by !important
            css={{ hover: { fill: '$neutral-blue-700 !important' } }}
            onClick={(e) => {
              e.stopPropagation();
              onLinkClicked && onLinkClicked();
            }}
          />
        </a>
      ))}
    </Flex>
  );
};
