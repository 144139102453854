import { Text, TextProps } from '@betterleap/ui';

export const LandingLink = ({ css, ...props }: TextProps) => (
  <Text
    as='a'
    rel='no-referrer'
    target='_blank'
    css={{
      display: 'block',
      hover: {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
      ...css,
    }}
    {...props}
  />
);
