import { Box, Text } from '@betterleap/ui';
import { differenceInCalendarDays } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { Organization } from '@betterleap/client';
import { selectOrganization } from 'store/api/selectors';
import { Banner } from '../Banner/Banner';

export const FreeTrialBanner = () => {
  const organization = useSelector(selectOrganization);

  if (!organization.data) {
    return null;
  }

  const daysOfTrialLeft = Math.abs(
    Math.min(
      differenceInCalendarDays(
        new Date(),
        new Date(organization.data?.trialStartedAt),
      ) - 7,
      0,
    ),
  );
  const days = daysOfTrialLeft === 1 ? 'day' : 'days';
  const on = daysOfTrialLeft > 0 ? `in ${daysOfTrialLeft} ${days}` : 'today';

  return (
    <Banner variant='purple'>
      <Text inherit>
        {organization.data.tier === Organization.tier.FREE_TRIAL ? (
          <>Your free trial ends {on}.</>
        ) : (
          <Box>Your 7-day trial expired.</Box>
        )}
      </Text>
    </Banner>
  );
};
