import { EnrichmentJobExperience } from '@betterleap/client';
import { Flex, Text } from '@betterleap/ui';
import { formatDateRange } from 'functions/formatDateRange';
import { SourcingResultSection } from './SourcingResult.elements';

interface SectionProps {
  experience: EnrichmentJobExperience[];
  markAsViewed: () => void;
}
interface EntryProps {
  entry: EnrichmentJobExperience;
}

export const SourcingResultExperience = ({
  experience,
  markAsViewed,
}: SectionProps) => (
  <SourcingResultSection
    title='Experience'
    icon='briefcase'
    elements={experience}
    onExpanded={markAsViewed}
    constructElement={(e) => <ExperienceEntry entry={e} />}
  />
);

const ExperienceEntry = ({ entry }: EntryProps) => {
  const dateRange = formatDateRange(entry);

  return (
    <>
      <Flex key={JSON.stringify(entry)} css={{ gap: 12 }}>
        {!!entry.title && <Text css={{ fontSize: '$sm' }}>{entry.title}</Text>}
        {!!entry.companyName && (
          <Text css={{ fontSize: '$sm', color: '$neutral-blue-700' }}>
            {entry.companyName}
          </Text>
        )}
        {!!dateRange && (
          <>
            <Text css={{ color: '$neutral-blue-700' }}>•</Text>
            <Text css={{ fontSize: '$sm', color: '$neutral-blue-700' }}>
              {dateRange}
            </Text>
          </>
        )}
      </Flex>
    </>
  );
};
