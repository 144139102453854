import { ClearbitLogo } from '@betterleap/shared';
import { EnrichmentEducation } from '@betterleap/client';
import { Flex, Text } from '@betterleap/ui';
import { formatDateRange } from 'functions/formatDateRange';

export interface ContactProfileEducationItemProps {
  educationData: EnrichmentEducation;
}

const ContactProfileEducationItem = (
  props: ContactProfileEducationItemProps,
) => {
  const { educationData } = props;

  const dateRange = formatDateRange(educationData);

  const description = [educationData.degree, educationData.major]
    .filter((i) => !!i)
    .join(', ');

  return (
    <Flex
      align='start'
      css={{
        gap: 20,
        p: '1.5rem',
        height: 114,
        width: '100%',
        border: '1px solid $neutral-blue-300',
        borderRadius: '$4xl',
        backgroundColor: '$background-component',
      }}
    >
      <ClearbitLogo
        domain={educationData.schoolDomain}
        name={educationData.schoolName}
      />
      <Flex vertical>
        <Text
          css={{
            fontWeight: '$medium',
            fontSize: '$xs',
            color: '$text-secondary',
          }}
        >
          {dateRange}
        </Text>
        <Text css={{ fontWeight: '$medium', fontSize: '$base' }}>
          {educationData.schoolName}
        </Text>
        <Text
          css={{
            fontSize: '$xs',
            color: '$text-secondary',
          }}
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ContactProfileEducationItem;
