import { Sequence } from '@betterleap/client';
import { AlertIconProps, showToast, useModal } from '@betterleap/ui';
import { useMutation } from 'react-query';
import SequenceConfirmationModal from 'components/modules/Modal/SequenceConfirmationModal/SequenceConfirmationModal';
import { apiClient } from 'lib/apiClient';

interface Props {
  sequence?: Sequence;
  onSuccess: () => void;
}

export const usePauseSequence = ({ sequence, onSuccess }: Props) => {
  const sequenceId = sequence?.id as string;

  const openConfirmationModal = useModal<{
    title: string;
    notes: { id: number; name: string }[];
    description: string;
    icon: { variant: AlertIconProps['variant']; name: AlertIconProps['name'] };
    confirmButtonText: string;
    confirmButtonVariant?: string;
  }>(SequenceConfirmationModal);

  const pauseSequence = useMutation(
    (data: { sequenceId: string; enabled?: boolean }) =>
      apiClient.sequence.update({
        sequenceId: data.sequenceId,
        requestBody: {
          enabled: data.enabled,
        },
      }),
    {
      onSuccess: () => {
        showToast({
          title: 'Success!',
          description: 'Your sequence is now paused',
        });
        onSuccess();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to pause sequence. Please try again.',
        });
      },
    },
  );

  const onPauseClick = async () => {
    const result = await openConfirmationModal({
      title: 'You are pausing this sequence',
      notes: [],
      description:
        'Are you sure you would like to pause this sequence? All candidates in progress will stop receiving emails.',
      icon: { variant: 'warning', name: 'exclamation-circle' },
      confirmButtonText: 'Pause sequence',
    });

    if (result) {
      pauseSequence.mutate({ sequenceId, enabled: false });
    }
  };

  const resumeSequence = useMutation(
    (data: { sequenceId: string }) =>
      apiClient.sequence.update({
        sequenceId: data.sequenceId,
        requestBody: { enabled: true },
      }),
    {
      onSuccess: () => {
        showToast({
          title: 'Success!',
          description: 'Your sequence has resumed.',
        });
        onSuccess();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to resume sequence. Please try again.',
        });
      },
    },
  );

  const onResumeClick = async () => {
    const result = await openConfirmationModal({
      title: 'You are resuming this sequence',
      notes: [],
      description:
        'Are you sure you would like to resume this sequence? All candidates in progress will begin receiving emails.',
      icon: { variant: 'warning', name: 'exclamation-circle' },
      confirmButtonText: 'Resume sequence',
    });

    if (result) {
      resumeSequence.mutate({ sequenceId });
    }
  };

  const onToggleSequence = sequence?.enabled ? onPauseClick : onResumeClick;

  return { onToggleSequence };
};
