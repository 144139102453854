import routeNames from 'constants/routeNames';
import {
  AlertIcon,
  BackButton,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
} from '@betterleap/ui';
import { useNavigate } from 'react-router-dom';

interface ChildProps {
  children: JSX.Element | (JSX.Element | false)[];
}

interface ProfilePageProps extends ChildProps {
  isViewOnly: boolean;
}

export const RecruiterProfilePage = (props: ProfilePageProps) => {
  const { isViewOnly, children } = props;
  const navigate = useNavigate();
  return (
    <Flex
      css={{
        flexDirection: 'column',
        maxWidth: 1200,
        margin: 'auto',
        padding: 16,
        mediaLg: {
          padding: 32,
        },
      }}
    >
      {isViewOnly && (
        <Flex css={{ alignSelf: 'flex-start', mb: -8 }}>
          <BackButton
            route='Projects'
            onClick={() => navigate(routeNames.projects())}
          />
        </Flex>
      )}
      {children}
    </Flex>
  );
};

export const RecruiterProfileCard = ({ children }: ChildProps) => (
  <Flex
    css={{
      flexDirection: 'column',
      gap: 24,
      backgroundColor: 'white',
      p: 20,
      m: 16,
      border: '1px solid #D9D9D9',
      borderRadius: 24,
      width: '100%',
      mediaLg: {
        p: 32,
      },
    }}
  >
    {children}
  </Flex>
);

export const CardSection = ({ children }: ChildProps) => (
  <Flex css={{ flexDirection: 'column', gap: 16, width: '100%' }}>
    {children}
  </Flex>
);

export const SectionTitleArea = ({ children }: ChildProps) => (
  <Flex
    css={{
      width: '100%',
      flexDirection: 'column',
      gap: 16,
      mediaLg: {
        flexDirection: 'row',
        pb: 8,
      },
    }}
  >
    {children}
  </Flex>
);

interface SectionTitleProps {
  title: string;
  icon: 'sun' | 'sparkles' | 'star';
}

export const SectionTitle = ({ title, icon }: SectionTitleProps) => (
  <Flex css={{ width: '100%' }}>
    <AlertIcon variant='gray' name={icon} css={{ borderRadius: 10 }} />
    <Box css={{ ml: 12, fontWeight: 500 }}>{title}</Box>
  </Flex>
);

interface AddButtonProps {
  text: string;
  onClick: () => void;
}

export const AddButton = ({ text, onClick }: AddButtonProps) => (
  <Button
    variant='secondary'
    onClick={onClick}
    css={{
      width: '100%',
      mediaLg: {
        width: 'unset',
      },
    }}
  >
    <Icon name='plus' color='$primary-base' size={16} />
    <Box css={{ ml: 8 }}>{text}</Box>
  </Button>
);

export const AddFirstButton = ({ text, onClick }: AddButtonProps) => (
  <Button
    variant='headless'
    onClick={onClick}
    css={{
      width: '100%',
      p: 16,
      border: '1px dashed $neutral-blue-400',
      borderRadius: 16,
      cursor: 'pointer',
      hover: {
        backgroundColor: '$neutral-blue-50',
      },
      mediaLg: {
        width: 'unset',
        alignSelf: 'flex-start',
      },
    }}
  >
    <IconButton
      variant='secondary'
      label={text}
      name='plus'
      css={{ borderRadius: 10 }}
    />
    <Text css={{ mx: 16, color: '$blue-600', fontWeight: 400 }}>{text}</Text>
  </Button>
);

export const EditIcon = ({ onClick }: { onClick: () => void }) => (
  <IconButton
    variant='darkGray'
    label='Edit'
    name='pencil'
    size='xs'
    onClick={onClick}
    css={{
      position: 'absolute',
      right: -6,
      top: -6,
      borderRadius: 8,
    }}
  />
);
