import { BoxProps, Flex, FlexProps, Text } from '@betterleap/ui';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Settings {
  export const Section = function Section({ children }: FlexProps) {
    return <Flex vertical>{children}</Flex>;
  };

  Section.Row = function SectionRow({ children, css }: FlexProps) {
    return (
      <Flex
        css={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          ...css,
          mediaMd: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
            ...css?.mediaMd,
          },
        }}
      >
        {children}
      </Flex>
    );
  };

  Section.Title = function SectionTitle({ css, children }: BoxProps) {
    return (
      <Text
        as='h2'
        css={{
          fontWeight: '$semibold',
          fontSize: '$lg',
          lineHeight: '$3xl',
          color: '$neutral-blue-900',
          ...css,
        }}
      >
        {children}
      </Text>
    );
  };
}
