import { ContactActivityType, ProjectContactStatus } from '@betterleap/client';
import { useParams, useSearchParams } from 'react-router-dom';

export interface ProjectContactsFilterParams {
  statuses?: ProjectContactStatus[];
  activityTypes?: ContactActivityType[];
  projectId: string;
}

export const useProjectContactsFilterParams = () => {
  const [searchParams] = useSearchParams();
  const { id: projectId } = useParams();
  const cohorts = searchParams.getAll('cohort') as ProjectContactStatus[];
  const openedEmail = searchParams.get('opened') || undefined;
  const statuses = searchParams.getAll('status') as ProjectContactStatus[];
  const activityTypes = searchParams.getAll(
    'activity',
  ) as ContactActivityType[];

  const contactProjectQuery = {
    projectId,
    activityTypes,
    statuses: {
      and: [] as ProjectContactStatus[][],
    },
  };

  if (openedEmail) {
    contactProjectQuery.statuses.and.push([
      openedEmail === 'true'
        ? ProjectContactStatus.OPENED_EMAIL
        : ProjectContactStatus.NOT_OPENED_EMAIL,
    ]);
  }

  if (cohorts.length) {
    contactProjectQuery.statuses.and.push(cohorts);
  }

  if (statuses.length) {
    contactProjectQuery.statuses.and.push(statuses);
  }

  return {
    contactProjectQuery,
    statuses,
    cohorts,
    activityTypes,
    openedEmail,
    projectId: projectId as string,
  };
};
