import { Spinner } from '@betterleap/shared';
import { Flex, showToast } from '@betterleap/ui';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import routeNames from '../../../constants/routeNames';
import { selectMe } from '../../../store/api/selectors';
import { apiClient } from '../../../lib/apiClient';
import ContactProfileSummary from './ContactProfileSummary.template';
import ContactProfileDetails from './ContactProfileDetails.template';
import { useProjectStatusUpdater } from './useProjectStatusUpdater';

const ContactProfileTemplate = (): JSX.Element => {
  const me = useSelector(selectMe);
  const { id } = useParams<{ id: string }>();

  const {
    data: getContactResponse,
    isLoading: isGetContactLoading,
    refetch,
  } = useQuery(['get_contact', id], () =>
    apiClient.contact.getContact({ id: id as string }),
  );

  useProjectStatusUpdater({ onContactProjectUpdated: refetch });

  if (isGetContactLoading) {
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }

  const contactData = getContactResponse?.data;

  if (!contactData?.contact) {
    return <Navigate to={routeNames.projects()} />;
  }

  return (
    <Flex
      vertical
      id='contact-profile-outer-container'
      css={{
        position: 'relative',
        p: 16,
        height: '100%',
        display: 'grid',
        gridGap: '1rem',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '30% 1fr',
        gridTemplateAreas: `'contentsidebar contentmain'`,
        mediaLg: {
          p: 0,
        },
      }}
    >
      <Flex
        vertical
        css={{
          gridArea: 'contentsidebar',
          position: 'relative',
          height: '100%',
          padding: '16px 24px',
          borderRadius: '$4xl',
          border: '1px solid $neutral-blue-200',
          backgroundColor: '$background-component',
        }}
      >
        <ContactProfileSummary
          contact={contactData.contact}
          contactAvatarUrl={contactData.contactAvatarUrl}
          onContactUpdated={() => {
            refetch();
            showToast({
              variant: 'success',
              title: 'Success!',
              description: 'Contact updated.',
            });
          }}
          onUpdateError={(message) => {
            showToast({
              variant: 'danger',
              title: 'Contact Update Failed',
              description: message,
            });
          }}
        />
      </Flex>
      <Flex
        id='contact-details-column'
        css={{
          position: 'relative',
          height: '100%',
          gridArea: 'contentmain',
        }}
      >
        <ContactProfileDetails
          myUserId={me.data?.id as string}
          contact={contactData.contact}
        />
      </Flex>
    </Flex>
  );
};

export default ContactProfileTemplate;
