import {
  ExtendedContactProject,
  Organization,
  Project,
  ProjectWithStatsDto,
  User,
} from '@betterleap/client';
import {
  getProjectSubject,
  ProjectSubjectOptions,
  useMe,
} from '@betterleap/shared';
import { useMemo } from 'react';
import { Row } from 'react-table';
import { useCohortFilter } from './Hooks/useCohortFilter';

export type TableSelection = Row<Record<string, unknown>>[] | 'all';

export function hasSelection(selectedRows: TableSelection) {
  return selectedRows === 'all' || selectedRows.length > 0;
}

export function countSelectedContacts(rows: TableSelection, count: number) {
  return rows === 'all' ? count : rows.length;
}

export function selectContactIds(selectedRows: TableSelection) {
  if (selectedRows === 'all') {
    return undefined;
  }
  return mapToContact(selectedRows).map((c) => c.id);
}

export function selectContacts(
  selectedContacts: TableSelection,
  firstPageContacts: ExtendedContactProject[],
) {
  if (selectedContacts === 'all') {
    return firstPageContacts;
  }
  return mapToContact(selectedContacts);
}

function mapToContact(selectedContacts: Row<Record<string, unknown>>[]) {
  return selectedContacts.map((c) => c.original) as ExtendedContactProject[];
}

export function resolveSubject(
  project: ProjectWithStatsDto,
  options: ProjectSubjectOptions = {},
) {
  const projectType = project.contactEmailEnrichmentMode;
  const salesProject = Project.contactEmailEnrichmentMode.PROFESSIONAL;
  return getProjectSubject(projectType === salesProject)(options);
}

export function isSalesProject(project: ProjectWithStatsDto) {
  const projectType = project.contactEmailEnrichmentMode;
  const salesProject = Project.contactEmailEnrichmentMode.PROFESSIONAL;
  return projectType === salesProject;
}

export function isFreeTrial(user?: User) {
  return user?.organization?.tier === Organization['tier'].FREE_TRIAL;
}

export const isViewOnly = (project: ProjectWithStatsDto) => {
  return project.organizationId !== useMe().user?.organizationId;
};

export const isEnriching = (contacts?: ExtendedContactProject[]) => {
  return !!contacts?.filter(isAwaitingEnrichment)?.length;
};

function isAwaitingEnrichment(contact: ExtendedContactProject) {
  const hasEmails = contact.emails.length > 0;
  const isSourced = contact.status === 'Sourced';
  return !hasEmails && isSourced && !hasWaitedForEnrichment(contact);
}

function hasWaitedForEnrichment(contact: ExtendedContactProject) {
  const threeMinutesAgo = new Date(new Date().getTime() - 3 * 60 * 1000);
  return new Date(contact.createdAt) < threeMinutesAgo;
}

export const useMemoizedContacts = (contacts?: ExtendedContactProject[]) => {
  const { cohort } = useCohortFilter();
  return useMemo(() => contacts ?? [], [contacts, cohort]);
};
