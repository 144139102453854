import { useMe } from '@betterleap/shared';
import { Box, Col, Flex, FlexProps, Row, Text } from '@betterleap/ui';
import { WaveLoader } from 'components/elements/WaveLoader/WaveLoader';
import { useSetAtom } from 'jotai';
import { FeatureFlagKey, useFeatureFlag } from 'hooks/useFeatureFlag';
import addCandidatesImage from '../../../assets/images/add-candidates.svg';
import {
  nlsInputState,
  useGenerateSourcingParams,
  UseGenerateSourcingParamsProps,
} from './NaturalLanguageSearch';
import { SearchMode } from './SourceCandidates.template';

interface SourceCandidatesEmptyStateProps extends FlexProps {
  isLoading?: boolean;
  noResultsFound: boolean;
  text: string;
  searchMode: SearchMode;
}

const standardPrompts = [
  'Software engineers located within a 30min drive of the Google HQ',
  'Product Managers in the PST timezone',
  'Nurses with 10+ years of experience who speak Spanish',
  'Product Designers who worked at FAANG companies',
  'Experienced software engineers with fintech experience',
  'Backend engineers located on the East Coast',
  'Product executives who worked at Fortune 500 companies',
  'UX designers who work at companies similar to OpenAI',
  'Frontend engineers in California but not the Bay Area',
];

const healthCarePrompts = [
  'Nurses within a 30min drive of Sutter Health in San Francisco',
  'Experienced Registered Respiratory Therapists',
  'Physical Therapists located on the East Coast',
  'Nurses with 10+ years of experience who speak Spanish',
  'Patient access specialists with clinical professional licensure',
  'Candidates with ACLS certification',
  'RNs in California but not the Bay Area',
  'Pediatric Nurses with NICU experience',
  'Candidates certified in Internal Medicine',
];

const NLSPrompt = ({ onSuccess }: UseGenerateSourcingParamsProps) => {
  const setNlsInput = useSetAtom(nlsInputState);
  const showHealthcarePrompts = useFeatureFlag(
    FeatureFlagKey.HEALTHCARE_NLS_PROMPTS,
  );

  const generateParams = useGenerateSourcingParams({
    onSuccess,
  });

  const handlePromptClick = (prompt: string) => {
    setNlsInput(prompt);
    generateParams(prompt);
  };

  const prompts = showHealthcarePrompts ? healthCarePrompts : standardPrompts;

  return (
    <Flex
      align='center'
      justify='center'
      css={{ px: 24, flexDirection: 'column', pb: 100 }}
    >
      <div>
        <Text size='xl' align='center' css={{ fontWeight: '$medium', mb: 12 }}>
          Find candidates using natural language
        </Text>
        <Text
          size='sm'
          align='center'
          css={{ color: '$text-secondary', mb: 36 }}
        >
          Click on one of the search prompts below to start sourcing
        </Text>
        <Row css={{ gap: 24 }}>
          {prompts.map((prompt) => (
            <Col
              key={prompt}
              role='button'
              span={{
                '@initial': 6,
                '@lg': 4,
              }}
              css={{
                p: 12,
                background: '$neutral-blue-100',
                border: '1px solid $neutral-blue-200',
                borderRadius: '$2xl',
                maxWidth: 255,
                focus: {
                  boxShadow: '$focus',
                },
              }}
              onClick={() => {
                handlePromptClick(prompt);
              }}
            >
              <Text size='sm' css={{ color: '$text-secondary' }}>
                {prompt}
              </Text>
            </Col>
          ))}
        </Row>
      </div>
      <WaveLoader css={{ position: 'absolute', bottom: 0, left: 0 }} />
    </Flex>
  );
};

const BasePrompt = ({
  noResultsFound,
  text,
  searchMode,
}: SourceCandidatesEmptyStateProps) => {
  return (
    <Flex css={{ flexDirection: 'column', alignItems: 'center' }}>
      <Flex justify='center'>
        <Box as='img' src={addCandidatesImage} width={290} />
      </Flex>
      <Text
        size='xl'
        css={{
          fontWeight: '$medium',
          lineHeight: '2rem',
          textAlign: 'center',
        }}
      >
        {!noResultsFound ? text : 'No results found'}
      </Text>
      {noResultsFound && (
        <Text
          size='sm'
          css={{
            color: '$text-secondary',
            textAlign: 'center',
            mt: 8,
          }}
        >
          Try broadening your search by clearing some filters.
        </Text>
      )}
      {!noResultsFound && searchMode === SearchMode.MY_TALENT_POOL && (
        <Text
          size='sm'
          css={{
            maxWidth: '66%',
            color: '$text-secondary',
            textAlign: 'center',
            mt: 8,
            '@lg': {
              maxWidth: '50%',
            },
          }}
        >
          Your Talent Pool includes all candidates you sourced on Betterleap and
          candidates in your ATS.
        </Text>
      )}
    </Flex>
  );
};

export const SourceCandidatesEmptyState = ({
  css,
  noResultsFound,
  searchMode,
  text,
  onSuccess,
  ...rest
}: SourceCandidatesEmptyStateProps & UseGenerateSourcingParamsProps) => {
  const me = useMe();

  const featureEnabledNaturalLanguageSourcing =
    me?.user?.organization?.features?.enabledNaturalLanguageSourcing;
  const showNlsEmptyState =
    featureEnabledNaturalLanguageSourcing && !noResultsFound;

  return (
    <Flex
      css={{
        width: '100%',
        height: 500,
        py: 85,
        alignItems: 'flex-start',
        justifyContent: 'center',
        ...css,
      }}
      {...rest}
    >
      {showNlsEmptyState ? (
        <NLSPrompt onSuccess={onSuccess} />
      ) : (
        <BasePrompt
          searchMode={searchMode}
          text={text}
          noResultsFound={noResultsFound}
        />
      )}
    </Flex>
  );
};
