import { User } from '@betterleap/client';
import React from 'react';
import { useSelector } from 'react-redux';
import withSidebar from '../components/HOC/withSidebar';
import CompanyProfileTemplate from '../components/templates/CompanyProfile/CompanyProfile.template';
import RecruiterProfileTemplate from '../components/templates/RecruiterProfile/RecruiterProfile.template';
import { selectMe } from '../store/api/selectors';

const Profile = () => {
  const me = useSelector(selectMe);
  const isRecruiter = me.data?.role === User.role.RECRUITER;
  if (isRecruiter) return <RecruiterProfileTemplate />;
  return <CompanyProfileTemplate />;
};
export default withSidebar(Profile, true);
