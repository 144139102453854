import { useMe } from '@betterleap/shared';
import { Text } from '@betterleap/ui';

export const WelcomeTextHeader = () => {
  const me = useMe();
  return (
    <Text
      as='h1'
      css={{
        fontWeight: '$medium',
        fontSize: '$lg',
        mb: 50,
        mediaMd: { fontSize: '$3xl' },
      }}
    >
      Hi{me.user?.firstName ? `, ${me.user?.firstName}` : ''}!
      <Text
        as='h3'
        css={{
          fontSize: '$base',
          fontWeight: '$normal',
          color: '#6B7280',
          mt: 8,
        }}
      >
        Welcome to your Betterleap dashboard
      </Text>
    </Text>
  );
};
