import { Flex } from '@betterleap/ui';
import { useAtom } from 'jotai';
import { forwardRef, ReactNode, useEffect, useMemo } from 'react';
import { InteractiveTable } from '@betterleap/shared';
import { ContactPreview } from '@betterleap/client';
import { Row, TableInstance, UseRowSelectInstanceProps } from 'react-table';
import { useContactView } from 'hooks/useContactView';
import { resetViewingSession } from 'hooks/contactViewingSession.functions';
import { SourceCandidatesPaywall } from './SourceCandidatesPaywall';
import { SearchMode } from './SourceCandidates.template';
import { SourcingSearchResult } from './SearchResults/SourcingSearchResult';
import { highlightsAtom, useHighlightSearchTerms } from './useHighlightWords';

interface SourceCandidatesResultsAreaProps {
  featureEnabledSourcing: boolean | undefined;
  isLoading?: boolean;
  searchResults: Partial<ContactPreview>[] | undefined;
  selectedRows: Row<Record<string, unknown>>[];
  searchMode: SearchMode;
  onRowsSelected: (selectedRows: Row<Record<string, unknown>>[]) => void;
  emptyState: ReactNode;
  onTableLoad?: (
    table: Partial<
      TableInstance<Record<string, unknown>> &
        UseRowSelectInstanceProps<Record<string, unknown>>
    >,
  ) => void;
}

export const SourceCandidatesResultsArea = forwardRef<
  HTMLElement,
  SourceCandidatesResultsAreaProps
>((params, ref) => {
  const {
    featureEnabledSourcing,
    searchResults,
    selectedRows,
    isLoading,
    onRowsSelected,
    searchMode,
    onTableLoad,
    emptyState,
  } = params;

  const { viewedContacts, onViewContact } = useContactView();

  useEffect(() => {
    return () => {
      resetViewingSession();
    };
  }, []);

  const [highlights] = useAtom(highlightsAtom);
  const highlightTerms = useHighlightSearchTerms();
  useEffect(highlightTerms, [highlights]);

  const contactTableColumns = useMemo(
    () => [
      SourcingSearchResult({
        onViewed: onViewContact,
        viewedContacts,
        enableFeedback: searchMode === SearchMode.WORLD_TALENT_POOL,
      }),
    ],
    [searchMode, highlights, viewedContacts],
  );

  if (!featureEnabledSourcing) {
    return <SourceCandidatesPaywall />;
  }

  return (
    <Flex
      ref={ref}
      css={{
        flexDirection: 'column',
        height: '100%',
        pb: 40,
        overflow: 'scroll',
      }}
    >
      <Flex css={{ flexDirection: 'column', width: '100%' }}>
        {searchResults && searchResults?.length > 0 && (
          <InteractiveTable
            className='bold-highlight highlight'
            css={{
              pt: 0,
              height: '100%',
              width: '100%',
              overflowX: 'hidden',
              '& > div': { overflowX: 'hidden', height: '100%' },
              '& .interactive-table': {
                boxShadow: '$none',
              },
              '& [role="rowgroup"]': {
                height: 'calc(100% - 51px)',
                overflowX: 'auto',
              },
              '& [role="rowgroup"] :first-child > span': {
                alignSelf: 'flex-start',
              },
              '& .header_row': {
                display: 'none',
              },
            }}
            pageSize={10}
            tableData={searchResults || []}
            columns={contactTableColumns}
            count={searchResults?.length ?? 0}
            onRowSelect={({ selectedFlatRows }) => {
              onRowsSelected(selectedFlatRows);
              onViewContact(
                selectedFlatRows.map((row) => row.original.id as string),
              );
            }}
            onLoad={(table) => onTableLoad?.(table)}
            selectedRows={selectedRows}
            disablePagination
            autoResetSelectedRows
          />
        )}
        {searchResults?.length === 0 && !isLoading && emptyState}
      </Flex>
    </Flex>
  );
});
