import { Flex, FlexProps, Icon, Text } from '@betterleap/ui';

export const ContactDncWarning = ({
  css,
  children = <>This contact is on your Do Not Contact list</>,
}: FlexProps) => (
  <Flex
    css={{ background: '$red-100', p: 16, mb: 20, borderRadius: 16, ...css }}
  >
    <Flex css={{ p: 6, backgroundColor: '$red-200', borderRadius: 8 }}>
      <Icon name='x-circle' size={16} css={{ fill: '$red-800' }} />
    </Flex>
    <Text size='xs' css={{ color: '$red-700', ml: 16 }}>
      {children}
    </Text>
  </Flex>
);
