import { Box, Flex, Icon } from '@betterleap/ui';

interface OnboardingChecklistItemProps {
  checked?: boolean;
  label: string | JSX.Element;
  onClick?: () => void;
}
export const OnboardingChecklistItem = ({
  checked,
  label,
  onClick,
}: OnboardingChecklistItemProps) => (
  <Flex>
    <Box
      onClick={() => !checked && onClick?.()}
      css={{
        flexDirection: 'row',
        display: 'flex',
        border: '1px solid #E5E7EB',
        borderRadius: '$lg',
        p: '12px',
        width: '272px',
        backgroundColor: checked ? '$neutral-blue-50' : '#fff',
        mb: '8px',
        cursor: checked ? 'not-allowed' : 'pointer',
      }}
    >
      <Box
        css={{
          backgroundColor: checked ? '#10B981' : '#fff',
          width: '20px',
          height: '20px',
          borderRadius: '$lg',
          p: '3px',
          border: checked ? undefined : '1px solid $blue-600',
        }}
      >
        {checked && (
          <Icon color='$text-inverse' size={14} strokeWidth={4} name='check' />
        )}
      </Box>
      <Box
        css={{
          ml: '12px',
          color: checked ? '$neutral-blue-500' : '$blue-600',
          fontSize: '$sm',
        }}
      >
        {label}
      </Box>
    </Box>
  </Flex>
);
