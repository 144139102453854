import { PromiseModalProps } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { AcknowledgmentText } from './AcknowledgmentText';
import { AcceptTermsModal } from './AcceptTermsModal';

export const TermsOfServiceModal = ({
  ...rest
}: PromiseModalProps<unknown>) => {
  return (
    <AcceptTermsModal
      documentTitle='Terms of Service'
      documentPath='/terms-of-service.html'
      acknowledgmentText={
        <AcknowledgmentText
          unlinkedText='I acknowledge and agree with these'
          linkedText='Terms of Service'
          linkUrl='https://betterleap.com/terms/'
        />
      }
      acceptButtonText='Accept Terms'
      acceptTerms={() => apiClient.user.acceptTermsOfService()}
      {...rest}
    />
  );
};
