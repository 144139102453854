import { CancelablePromise } from '@betterleap/client';
import {
  Alert,
  AlertHeading,
  AlertingIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  PromiseModal,
  PromiseModalProps,
  showToast,
  Text,
} from '@betterleap/ui';
import { useLogout } from 'hooks/useLogout';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import privacyLogo from '../../../../assets/images/privacy-logo.svg';
import { DocumentMountPoint } from './DocumentMountPoint';

declare interface ModalProps extends PromiseModalProps<undefined> {
  documentTitle: string;
  documentPath: string;
  acknowledgmentText: JSX.Element;
  acceptButtonText: string;
  acceptTerms: () => CancelablePromise<unknown>;
}

export const AcceptTermsModal: PromiseModal<ModalProps> = ({
  documentTitle,
  documentPath,
  acknowledgmentText,
  acceptButtonText,
  acceptTerms,
  onSubmit,
  onDismiss,
}: ModalProps) => {
  const [isScrolledToBottom, setBottom] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const { logout } = useLogout();

  const updateOrganization = useMutation(() => acceptTerms(), {
    onSuccess: () => {
      onDismiss();
      onSubmit(undefined);
    },
    onError: () => {
      showToast({
        variant: 'danger',
        title: 'Something went wrong!',
        description: 'Sorry, an error occurred. Please try again.',
      });
    },
  });

  return (
    <>
      <Modal size='xl' isDismissable={false}>
        <Flex vertical css={{ alignItems: 'center', justifyContent: 'center' }}>
          <Alert css={{ width: '100%', display: 'flex' }}>
            <AlertingIcon css={{ mt: 1 }} />
            <AlertHeading>
              <Text
                css={{
                  color: '$blue-700',
                  fontWeight: '$medium',
                  fontSize: '$sm',
                }}
                inherit
                inline
              >
                Scroll to the bottom of the {documentTitle} to continue
              </Text>
            </AlertHeading>
          </Alert>
          <Box as='img' src={privacyLogo} alt='logo' css={{ mt: 30 }} />
          <Text
            as='h2'
            css={{
              fontWeight: '$medium',
              fontSize: '$lg',
              pt: 18,
            }}
          >{`We've updated our ${documentTitle}`}</Text>
          <Text
            css={{
              fontSize: '$sm',
              color: '$neutral-blue-500',
              textAlign: 'center',
              mt: 8,
            }}
          >
            Please review and accept the terms to continue using Betterleap.
          </Text>
        </Flex>
        <Box
          css={{
            p: 12,
            background: '$neutral-blue-50',
            borderRadius: '$base',
            my: 24,
          }}
        >
          <DocumentMountPoint
            documentPath={documentPath}
            onScrollToBottom={setBottom}
          />
        </Box>

        <Flex
          align='start'
          css={{
            mb: 24,
          }}
        >
          <Checkbox
            checked={isCheckboxChecked}
            onChange={(val: boolean) => {
              setIsCheckboxChecked(val);
            }}
          />
          {acknowledgmentText}
        </Flex>
        <Flex
          justify='center'
          css={{ width: '100%', mb: 12, mediaMd: { mb: 0 } }}
        >
          <Button
            css={{
              maxWidth: 304,
              width: '100%',
            }}
            onClick={() => {
              updateOrganization.mutate();
            }}
            disabled={!isCheckboxChecked || !isScrolledToBottom}
          >
            {acceptButtonText}
          </Button>
        </Flex>

        <Flex
          css={{
            py: '$12',
            justifyContent: 'center',
            fontWeight: '$normal',
            fontSize: '$sm',
            lineHeight: '$xl',
            color: '$text',
            flexDirection: 'row',
          }}
          as='div'
        >
          {`or `}
          <Text
            onClick={() => {
              onDismiss();
              logout();
            }}
            css={{
              px: '$4',
              fontWeight: '$semibold',
              fontSize: '$sm',
              lineHeight: '$xl',
              color: '$blue-800',
              cursor: 'pointer',
            }}
          >
            Sign Out
          </Text>
          {` and review at later date`}
        </Flex>
      </Modal>
    </>
  );
};
