import { Tooltip } from '@betterleap/ui';
import {
  ExtendedContactProject,
  ProjectWithStatsDto,
} from '@betterleap/client';
import { Row } from 'react-table';
import { ProjectActionButton } from '../../ProjectActions';
import { resolveSubject } from '../../ProjectDetails.functions';
import { usePauseOrResumeSequence } from '../Hooks/usePauseOrResumeSequence';

interface Props {
  project: ProjectWithStatsDto;
  selectedContacts: Row<Record<string, unknown>>[] | 'all';
  onSequencesResumed: () => void;
}

const paused = ExtendedContactProject.contactSequenceDeactivatedReason.PAUSED;

export const ResumeSequenceButton = ({
  project,
  selectedContacts,
  onSequencesResumed,
}: Props) => {
  const { pauseOrResumeSequence } = usePauseOrResumeSequence({
    project,
    selectedContacts,
    onPauseOrResumeSequences: onSequencesResumed,
  });
  function canResumeSequences() {
    return (
      selectedContacts === 'all' ||
      selectedContacts.some((c) => {
        const contact = c.original as ExtendedContactProject;
        return contact.contactSequenceDeactivatedReason === paused;
      })
    );
  }
  return (
    <Tooltip content='Resume sequence'>
      <ProjectActionButton
        onClick={() => pauseOrResumeSequence(false)}
        disabled={!project.sequenceId || !canResumeSequences()}
        name='play'
        label={`Resume sequence for ${resolveSubject(project)}.`}
      />
    </Tooltip>
  );
};
