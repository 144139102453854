import { User } from '@betterleap/client';
import { Settings } from './Settings.elements';
import { ContactReplyEmailSetting } from './Other/ContactReplyEmailSetting';
import { DomainExclusionListSetting } from './Other/DomainExclusionListSettings';

export const OtherSettings = ({ user }: { user: User }) => (
  <Settings.Section>
    <Settings.Section.Row css={{ mt: '2rem', mb: '2rem' }}>
      <Settings.Section.Title css={{ mt: '3rem' }}>
        Other
      </Settings.Section.Title>
    </Settings.Section.Row>
    <DomainExclusionListSetting
      controller='doNotContactDomain'
      filename='donotcontact.csv'
      settingTitle='Do Not Contact List'
      settingSubtitle='Upload a list of restricted domains that your team members will be unable to reach out to.'
      successToastTitle='Your Do Not Contact List has been added!'
      successToastDescription='All future sequences will bypass uploaded contacts.'
      removalToastTitle='Your Do Not Contact List has been removed!'
      analyticsButtonName='Upload DNC List'
    />
    <DomainExclusionListSetting
      controller='restrictedDomain'
      filename='restricted_domains.csv'
      settingTitle='Restricted Domains List'
      settingSubtitle='Upload a list of domains that will be excluded from email processing by Betterleap.'
      successToastTitle='Your Restricted Domains List has been added!'
      successToastDescription='Emails from these domains will not be processed by Betterleap.'
      removalToastTitle='Your Restricted Domains List has been removed!'
      analyticsButtonName='Upload Restricted Domains List'
    />
    <ContactReplyEmailSetting user={user} />
  </Settings.Section>
);
