/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContactEmail, Project } from '@betterleap/client';
import { ContactEmailManager } from '@betterleap/shared';
import {
  AlertIcon,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PromiseModal,
  showToast,
  Tab,
  TabContent,
  Tabs,
  TabsList,
} from '@betterleap/ui';
import { EditContactModalProps } from './EditContactModal';

const EditContactEmailsModal: PromiseModal<EditContactModalProps> = ({
  onSubmit,
  contact,
  onContactUpdated = () => {},
  enrichmentMode = Project.contactEmailEnrichmentMode.PERSONAL,
}) => {
  const defaultTab =
    enrichmentMode === Project.contactEmailEnrichmentMode.PERSONAL
      ? ContactEmail.type.PERSONAL
      : ContactEmail.type.WORK;

  return (
    <Modal size='medium'>
      <ModalHeader>
        <Flex justify='center' css={{ py: 8 }}>
          <AlertIcon variant='info' name='identification' size='lg' />
        </Flex>
        <ModalTitle css={{ paddingBottom: 32 }} dataCy='Edit contact modal'>
          Edit Contact Emails
        </ModalTitle>
      </ModalHeader>
      <ModalBody css={{ p: 4 }}>
        <Tabs tabStyle='underline' defaultValue={defaultTab}>
          <TabsList css={{ mb: 8 }}>
            <Tab value={ContactEmail.type.PERSONAL}>Personal</Tab>
            <Tab value={ContactEmail.type.WORK}>Business</Tab>
          </TabsList>
          <TabContent value={ContactEmail.type.PERSONAL}>
            <ContactEmailManager
              contactId={contact?.id as string}
              onContactUpdated={onContactUpdated}
              emailType={ContactEmail.type.PERSONAL}
              pollingInterval={1000}
              contactPrimaryEmail={contact?.primaryEmail}
              contactLinkedinUrl={contact?.linkedinUrl}
              onEmailCopied={() => {
                showToast({
                  title: 'Email copied!',
                  description: 'Email copied to your clipboard.',
                });
              }}
            />
          </TabContent>
          <TabContent value={ContactEmail.type.WORK}>
            <ContactEmailManager
              contactId={contact?.id as string}
              onContactUpdated={onContactUpdated}
              emailType={ContactEmail.type.WORK}
              pollingInterval={1000}
              contactPrimaryEmail={contact?.primaryBusinessEmail}
              contactLinkedinUrl={contact?.linkedinUrl}
              onEmailCopied={() => {
                showToast({
                  title: 'Email copied!',
                  description: 'Email copied to your clipboard.',
                });
              }}
            />
          </TabContent>
        </Tabs>
      </ModalBody>
      <ModalFooter css={{ justifyContent: 'center', display: 'flex', pt: 32 }}>
        <Button
          dataCy='Save button'
          css={{ width: 100 }}
          onClick={() => onSubmit()}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditContactEmailsModal;
