const testEmails = [
  'youssefaly43@gmail.com',
  'youssefaly.spius@gmail.com',
  'agrocentar.gv@gmail.com',
  'yatimsapp@gmail.com',
  'info.bihguide@gmail.com',
  'jenny_fng@yahoo.com',
  'jennyyy.fang@gmail.com',
  'devicdelila31@gmail.com',
  'devicdelila32@gmail.com',
  'devicdelila33@gmail.com',
  'devicdelila34@gmail.com',
  'devicdelila35@gmail.com',
  'devicdelila36@gmail.com',
  'ddevic1@etf.unsa.ba',
  'andytoizer@gmail.com',
  'toizerandy@gmail.com',
  'andy.e.toizer@gmail.com',
  'mpalmerlee@gmail.com',
  'matt@encodelogic.com',
  'matt@betterleap.com',
  'bengrant008@gmail.com',
  'bengrant002@gmail.com',
  'bengrant003@gmail.com',
  'alex@betterleap.com',
  'hainesaxiv@gmail.com',
  'alex@rifflelabs.com',
  'dvaklokana033@gmail.com',
  'annryabova@gmail.com',
  'benowenssf@gmail.com',
  'melano.anna@gmail.com',
  'yunsergey9@gmail.com',
  'jesskareninoff@gmail.com',
  'hamiltonalexander795@gmail.com',
  'scarlettjohannssen@gmail.com',
  'BradfordStanleyson@gmail.com',
  'anna@stipple.vc',
  'anna@betterleap.com',
  'jaredtommy8@gmail.com',
];

export default testEmails;
