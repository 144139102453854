/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Button,
  Flex,
  Icon,
  Spinner,
  useControlledState,
} from '@betterleap/ui';
import DOMPurify from 'dompurify';
import { FileEntity, SendTestEmailDto } from '@betterleap/client';
import { BaseStepEditor, BaseStepEditorProps } from './BaseStepEditor';
import { StepEditorValue } from './StepEditorTypes';
import { ScheduleTimeManager } from './components/ScheduleTimeManager';
import { StepAttachmentItem } from './components/StepAttachmentItem';
import { useStepAttachments } from './useStepAttachments';

export interface EmailStepEditorProps
  extends Omit<BaseStepEditorProps, 'modules'> {
  sender?: string;
  onSendTestEmail?: (args: SendTestEmailDto) => void;
  onAttachmentUpload?: (file: FileEntity) => void;
  signature?: string;
  useDefaultSignature?: boolean;
}

const Signature = ({ signature }: { signature: string }) => {
  const clean = DOMPurify.sanitize(signature);
  return <iframe title='signature' srcDoc={clean} className='m-4' />;
};

export const EmailStepEditor = ({
  value,
  defaultValue,
  sender,
  stepNumber,
  onChange,
  onRemove,
  error,
  readOnly,
  removable = true,
  canEditDelay = true,
  onBlur,
  onSendTestEmail,
  onFileUpload,
  onAttachmentUpload,
  signature,
  useDefaultSignature,
  quillRef,
  ...props
}: EmailStepEditorProps) => {
  const [stateValue, setStateValue] = useControlledState(
    value,
    defaultValue,
    onChange,
  );

  const handleOnChange = (newValue: StepEditorValue) => {
    setStateValue({
      ...newValue,
    });
  };

  const { onInsertAttachmentClick, handleRemoveAttachment, isUploading } =
    useStepAttachments({ stateValue, setStateValue, onAttachmentUpload });

  return (
    <BaseStepEditor
      value={stateValue}
      stepNumber={stepNumber}
      quillRef={quillRef}
      onBlur={onBlur}
      removable={removable}
      onRemove={onRemove}
      onFileUpload={onFileUpload}
      canEditSubject
      canUploadImage
      canResizeImage
      readOnly={readOnly}
      error={error}
      headerComponent={
        <ScheduleTimeManager
          onChange={handleOnChange}
          onBlur={onBlur}
          readOnly={readOnly}
          canEditDelay={canEditDelay}
          canEditSendStrategy={true}
          value={stateValue}
          error={error}
          stepNumber={stepNumber}
        />
      }
      onChange={handleOnChange}
      {...props}
    >
      <Flex
        css={{ overflow: 'auto' }}
        wrap
        justify={signature && useDefaultSignature ? 'between' : 'end'}
      >
        {signature && useDefaultSignature && (
          <Signature signature={signature} />
        )}
        {!readOnly && (
          <Flex
            justify='end'
            css={{ gap: 8, px: 16, py: 8, flex: 1, textAlign: 'right' }}
          >
            <Button
              variant='gray'
              size='sm'
              css={{ gap: 8, minWidth: 145 }}
              onClick={onInsertAttachmentClick}
            >
              {isUploading ? (
                <Spinner css={{ height: 20, width: 20 }} />
              ) : (
                <>
                  <Icon name='attachment' size={18} />
                  Add Attachment
                </>
              )}
            </Button>
            {onSendTestEmail && (
              <Button
                variant='ghost'
                size='sm'
                onClick={() =>
                  onSendTestEmail?.({
                    subjectTemplate: stateValue?.subject || '',
                    bodyTemplate: stateValue?.body || '',
                    sender,
                    useDefaultSignature,
                    files: stateValue?.attachments?.map(
                      (a) => a.file as FileEntity,
                    ),
                  })
                }
              >
                Send me a test
              </Button>
            )}
          </Flex>
        )}
      </Flex>
      <Flex css={{ px: 16, pt: 8, pb: 16, gap: 8 }}>
        {stateValue?.attachments?.map((attachment) => (
          <StepAttachmentItem
            key={attachment.file?.id as string}
            file={attachment.file as FileEntity}
            readOnly={readOnly}
            onRemove={() => {
              handleRemoveAttachment(attachment);
            }}
          />
        ))}
      </Flex>
    </BaseStepEditor>
  );
};
