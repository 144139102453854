/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export function useBlocker(blocker: any, when = true) {
  const { navigator } = useContext(NavigationContext);
  useEffect(() => {
    if (!when) return;
    const unblock = (navigator as any).block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    // eslint-disable-next-line consistent-return
    return unblock;
  }, [navigator, blocker, when]);
}
export default function usePrompt(message: any, when = true) {
  const blocker = useCallback(
    (tx: { retry: () => void }) => {
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );
  useBlocker(blocker, when);
}
