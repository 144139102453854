import { PersonSearchRequest, SavedSearch } from '@betterleap/client';
import {
  Button,
  Flex,
  IconButton,
  showToast,
  Spinner,
  useModal,
} from '@betterleap/ui';
import {
  ConfirmModal,
  ConfirmModalProps,
} from 'components/modules/Modal/ConfirmModal/ConfirmModal';
import SaveSearchModal, {
  SaveSearchModalProps,
} from 'components/modules/Modal/SaveSearchModal/SaveSearchModal';
import { apiClient } from 'lib/apiClient';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { filterState } from '../Sourcing/SourceCandidates.template';

export const SavedSearchActionCell = ({ search }: { search: SavedSearch }) => {
  const setFilters = useSetAtom(filterState);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const openConfirmationModal = useModal<ConfirmModalProps>(ConfirmModal);
  const openSaveSearchModal = useModal<
    SaveSearchModalProps,
    SaveSearchModalProps
  >(SaveSearchModal);

  const updateSavedSearch = useMutation(
    (data: { name: string }) =>
      apiClient.savedSearch.update({
        savedSearchId: search.id,
        requestBody: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get_saved_searches']);
        showToast({
          variant: 'success',
          title: 'Success!',
          description: 'Successfully updated saved search.',
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to update saved search. Please try again.',
        });
      },
    },
  );

  const deleteSavedSearch = useMutation(
    () =>
      apiClient.savedSearch.delete({
        savedSearchId: search.id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get_saved_searches']);
        showToast({
          variant: 'success',
          title: 'Success!',
          description: 'Successfully deleted saved search.',
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to delete saved search. Please try again.',
        });
      },
    },
  );

  const handleRemove = async () => {
    const result = await openConfirmationModal({
      title: 'Delete Saved Search',
      icon: {
        variant: 'danger',
        name: 'trash',
      },
      description: 'Are you sure you want to remove this saved search?',
      confirmationText: 'Remove',
      variant: 'danger',
    });

    if (result) {
      deleteSavedSearch.mutate();
    }
  };

  const handleApplySearchParams = () => {
    const savedSearchParams = search.parameters;
    const newUrlParams = new URLSearchParams();

    for (const key in savedSearchParams) {
      newUrlParams.set(
        key,
        savedSearchParams[key as keyof PersonSearchRequest] as string,
      );
    }

    setFilters(savedSearchParams);

    navigate({
      pathname: '/sourcing',
      search: newUrlParams.toString(),
    });
  };

  const handleRename = async () => {
    const newSavedSearch = await openSaveSearchModal({
      name: search.name,
      prompt: '',
    });

    if (newSavedSearch) {
      updateSavedSearch.mutate(newSavedSearch);
    }
  };

  return (
    <Flex justify='end' css={{ gap: 32, pr: 8 }}>
      <Flex css={{ gap: 4 }}>
        <Button
          variant='secondary'
          size='xs'
          onClick={handleApplySearchParams}
          css={{ minWidth: 66 }}
        >
          Search
        </Button>
        <Button
          variant='gray'
          size='xs'
          onClick={handleRename}
          css={{ minWidth: 72 }}
        >
          {updateSavedSearch.isLoading ? (
            <Spinner css={{ height: 14, width: 14 }} />
          ) : (
            'Rename'
          )}
        </Button>
      </Flex>
      <IconButton
        label='delete search'
        name='trash'
        variant='danger'
        size='xs'
        onClick={handleRemove}
      />
    </Flex>
  );
};
