import { ModalProps } from '@betterleap/shared';
import * as Modal from '../../components/modules/Modal';
import { ActionsUnion, createAction, IActionWithPayload } from '../action';

enum MODAL {
  OPEN = 'OPEN_MODAL',
  CLOSE = 'CLOSE_MODAL',
}

const modalActions = {
  open: <T extends ModalProps>(
    modalKey: keyof typeof Modal.modalRegistry,
    modalProps: T,
  ): IActionWithPayload<
    string,
    { modalKey: keyof typeof Modal.modalRegistry; props: T },
    undefined,
    null
  > => createAction(MODAL.OPEN, { modalKey, props: modalProps }),
  close: (): IActionWithPayload<
    string,
    Record<string, null>,
    undefined,
    null
  > => createAction(MODAL.CLOSE, {}),
};

export type ModalAction = ActionsUnion<typeof modalActions>;
export { MODAL, modalActions };
