import testEmails from '@betterleap/shared/src/constants/testEmails';

export const markEventAsTest = (
  userEmail: string | undefined,
  isTestUser: boolean,
): boolean => {
  // Check Email
  if (userEmail) {
    if (
      testEmails.indexOf(userEmail) > -1 ||
      /test|yopmail|betterleap\.com|i.betterleap.io/.test(userEmail)
    ) {
      return true;
    }
  }
  return isTestUser;
};

export const shouldEnableAnalytics = (isMasquerade?: boolean): boolean =>
  !isMasquerade;
