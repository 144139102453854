import { useMemo } from 'react';
import { useAtomValue } from 'jotai/react';
import { filterState } from '../SourceCandidates.template';
import { VariableInclusionFilter } from './VariableInclusionFilter';

export const SkillsFilter = () => {
  const filters = useAtomValue(filterState);

  const values = useMemo(() => {
    return filters.skills ?? [];
  }, [filters]);

  return (
    <VariableInclusionFilter
      values={values}
      field='skills'
      title='Skills'
      placeholder='Enter Skills'
    />
  );
};
