import { formatDistanceTerse } from '../../../../helper/formatDistanceTerse';

export function sendsAtMessage(date: string | undefined, isManualTask = false) {
  const verb = isManualTask ? 'due' : 'sends';

  if (!date) {
    return '';
  } else if (date === 'never') {
    return 'not scheduled';
  } else if (sendsSoon(date)) {
    return `${verb} soon`;
  } else if (isMoreThanOneYear(date)) {
    return `${verb} in 1y+`;
  }
  return `${verb} in ${formatDistanceTerse(new Date(date), new Date())}`;
}

function sendsSoon(date: string) {
  const scheduledAt = new Date(date).getTime();
  const now = new Date().getTime();
  const soon = now + 1000 * 60 * 60 * 2;
  return scheduledAt <= soon;
}

function isMoreThanOneYear(date: string) {
  const scheduledAt = new Date(date).getTime();
  const now = new Date().getTime();
  const oneYear = now + 1000 * 60 * 60 * 24 * 365;
  return scheduledAt >= oneYear;
}
