import { apiClient } from '../../../lib/apiClient';
import { ChartCard } from './ChartCard';

export const OutreachChartCard = () => {
  return (
    <ChartCard
      title='Emails Delivered'
      icon='paper-airplane'
      fetchMetrics={(r) => apiClient.sentEmailLog.getSentEmailsByTimePeriod(r)}
      fetchCount={() => apiClient.sentEmailLog.countAllSentEmails()}
    />
  );
};
