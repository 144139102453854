import { put, takeLatest } from 'redux-saga/effects';
import { initFirebase, signInWithCustomToken, Types } from '@betterleap/shared';
import { Location, NavigateFunction } from 'react-router-dom';
import { TakeableChannel } from 'redux-saga';
import TOKEN from '../../lib/token';
import { apiActions } from '../api/actions';
import { APP } from './actions';

export function* onAppInit({
  helpers,
}: {
  helpers: { navigate: NavigateFunction; location: Location };
}) {
  yield initFirebase();
  yield put(apiActions.fetch('feature_config', undefined, {}, helpers));
  const { search } = window.location;

  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('custom-admin-token');

  if (token) {
    const user: Types.FirebaseAuthCredentials = yield signInWithCustomToken(
      token,
    );
    if (user.customToken) {
      TOKEN.setSessionStorage(user.customToken);
      yield put(apiActions.fetch('signup', { ...user }, {}, helpers));
    }
  } else if (TOKEN.get()) {
    yield put(apiActions.fetch('me', undefined, {}, helpers));
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAppInit(): Generator<any> {
  yield takeLatest(APP.INIT as unknown as TakeableChannel<unknown>, onAppInit);
}

export default [watchAppInit];
