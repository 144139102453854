import { ExtendedContactProject } from '@betterleap/client';
import { Box, CheckboxProps, Label } from '@betterleap/ui';
import React from 'react';
import { ActionMenuCheck } from './InMailStatusSelector';

export const InterestedStatusSelector = ({
  contact,
  onChange,
}: {
  contact: ExtendedContactProject;
  onChange: CheckboxProps['onChange'];
}) => {
  const [value, setValue] = React.useState<string | undefined>(
    typeof contact.interested === 'boolean'
      ? contact.interested.toString()
      : '',
  );

  const handleChange = async (checked: boolean, newValue?: string) => {
    setValue(checked ? newValue : '');
    onChange?.(checked, newValue);
  };

  return (
    <Box
      css={{ p: 12, width: 255, borderBottom: '1px solid $neutral-blue-300' }}
      onClick={(e) => e.stopPropagation()}
    >
      <Label
        css={{ fontSize: '$xs', color: '$text-secondary', pb: 8 }}
        htmlFor='interested-select'
      >
        INTERESTED
      </Label>
      <ActionMenuCheck
        onChange={handleChange}
        value='true'
        checked={value === 'true'}
      >
        Yes
      </ActionMenuCheck>
      <ActionMenuCheck
        onChange={handleChange}
        value='false'
        checked={value === 'false'}
      >
        No
      </ActionMenuCheck>
    </Box>
  );
};
