import {
  HandleCsvUploadResultDto,
  ProjectWithStatsDto,
} from '@betterleap/client';
import { Types, useMe } from '@betterleap/shared';
import { showToast, Text, useModal } from '@betterleap/ui';
import { AxiosResponse } from 'axios';
import AddCandidatesModal from 'components/modules/Modal/AddCandidatesModal/AddCandidatesModal';
import useAnalytics from 'hooks/analytics';
import api from 'lib/api';
import { useMutation } from 'react-query';
import {
  AlertModal,
  AlertModalProps,
} from 'components/modules/Modal/AlertModal/AlertModal';
import { useGetTasks } from 'hooks/useGetTasks';
import { resolveSubject } from '../ProjectDetails.functions';

type UploadCsvResponse = AxiosResponse<{
  data: HandleCsvUploadResultDto;
}>;

export const useAddContacts = ({
  project,
  onContactsAdded,
}: {
  project?: ProjectWithStatsDto;
  onContactsAdded: () => void;
}) => {
  const me = useMe();
  const { track } = useAnalytics();
  const getTasksQuery = useGetTasks(me.user?.id);

  const submitCandidates = useMutation(
    (data: { contacts: string; projectId?: string; sequenceId?: string }) =>
      api.fetch<UploadCsvResponse>('create_many_contacts', data),
    {
      onSuccess: () => {
        getTasksQuery.refetch();
        onContactsAdded();
      },
    },
  );

  const handleSubmit = (newContacts: Partial<Types.Contact>[]) =>
    submitCandidates.mutateAsync({
      contacts: JSON.stringify(newContacts),
      projectId: project?.id,
    });

  const openAddCandidatesModal = useModal<
    {
      title: string;
      handleSubmit: typeof handleSubmit;
    },
    UploadCsvResponse
  >(AddCandidatesModal);

  const openAlertModal = useModal<AlertModalProps>(AlertModal);

  const addContacts = async () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Add Candidates',
      buttonLocation: 'Project Details',
    });
    try {
      const result = await openAddCandidatesModal({
        title: `Add ${resolveSubject(project!, { cap: true })}`,
        handleSubmit: (newContacts) => handleSubmit(newContacts),
      });

      const candidateResponse = result?.data?.data;

      if (!candidateResponse) return;

      const singular = candidateResponse.numContacts === 1;

      await openAlertModal({
        title: `Successfully uploaded your CSV!`,
        description: (
          <>
            We are processing your{' '}
            <Text css={{ fontWeight: '$bold' }} inherit inline>
              {candidateResponse.numContacts}{' '}
              {resolveSubject(project!, { singular })}.
            </Text>{' '}
            They will appear in this project over the next few minutes.
          </>
        ),
        icon: {
          name: 'mail-open',
          variant: 'info',
        },
        confirmationText: 'Done',
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err?.response?.data?.error?.name === 'FileTooLargeError') {
        showToast({
          variant: 'danger',
          title: 'Contact Import Failed',
          description: 'Your upload was too large. Please use smaller file.',
        });
      } else {
        showToast({
          variant: 'danger',
          title: 'Contact Import Failed',
          description: 'Upload failed please try again.',
        });
      }
    }
  };

  return { addContacts };
};
