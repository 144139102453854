import { CreateFeedbackDto } from '@betterleap/client';
import {
  AlertIcon,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalClose,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
  PromiseModal,
  showToast,
  Spinner,
  Text,
  TextArea,
} from '@betterleap/ui';
import { useMutation } from 'react-query';
import { apiClient } from 'lib/apiClient';
import { useState } from 'react';
import { usePersonSearchQueryParams } from 'components/templates/Sourcing/useSourcingSearchParams';

export interface SubmitFeedbackModalProps extends ModalProps {
  personId: string;
  personName: string;
}

export const SubmitFeedbackModal: PromiseModal<SubmitFeedbackModalProps> = ({
  onSubmit,
  onDismiss,
  personId,
  personName,
}) => {
  const [feedback, setFeedback] = useState('');
  const sourcingSearchParams = usePersonSearchQueryParams();

  const submitFeedback = useMutation(
    (args: { feedback: string; data: Record<string, unknown> }) =>
      apiClient.feedback.create({
        requestBody: {
          feedback: args.feedback,
          type: CreateFeedbackDto.type.SOURCING_RESULT,
          data: {
            search: args.data,
            personId,
            personName,
          },
        },
      }),
    {
      onSuccess: () => {
        onSubmit();
        showToast({
          variant: 'success',
          title: 'Success!',
          description: 'Your feedback has been submitted.',
        });
      },
      onError: () => {
        onSubmit();
        showToast({
          variant: 'danger',
          title: 'Oops, Something went wrong!',
          description: 'Failed to submit feedback. Please try again.',
        });
      },
    },
  );

  const submit = async () => {
    submitFeedback.mutate({
      feedback,
      data: sourcingSearchParams as Record<string, unknown>,
    });
  };

  return (
    <Modal size='medium'>
      <ModalHeader>
        <Flex justify='center'>
          <AlertIcon
            size='lg'
            css={{ mb: 8 }}
            variant='purple'
            name='pencil-alt'
          />
        </Flex>
        <ModalTitle>Submit Feedback</ModalTitle>
        <Text
          size='sm'
          css={{
            color: '$neutral-blue-600',
            textAlign: 'center',
            mb: 24,
            px: 48,
          }}
        >
          What makes this candidate not the right fit for this search?
        </Text>
      </ModalHeader>
      <ModalClose />
      <ModalBody css={{ p: 4 }}>
        <TextArea
          id='feedback'
          aria-label='sourcing result feedback'
          name='feedback'
          autoFocus
          placeholder='Add feedback here'
          value={feedback}
          onChange={setFeedback}
          css={{ mb: 14 }}
        />
      </ModalBody>
      <ModalFooter>
        <Flex justify='center' css={{ gap: 12, pt: 2, width: '100%' }}>
          <Button onClick={onDismiss} variant='secondary' css={{ width: 120 }}>
            Cancel
          </Button>
          <Button
            onClick={submit}
            css={{
              width: 120,
              '& svg': {
                marginRight: 0,
              },
            }}
          >
            {submitFeedback.isLoading ? <Spinner variant='white' /> : 'Submit'}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
