import { InteractiveTable, Spinner } from '@betterleap/shared';
import { SavedSearch } from '@betterleap/client';
import { BackButton, Box, Flex, Text } from '@betterleap/ui';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import routeNames from '../../../constants/routeNames';
import { apiClient } from '../../../lib/apiClient';
import { SavedSearchActionCell } from './SavedSearchActionCell';

const savedSearcheColumns = [
  {
    Header: 'SEARCH NAME',
    accessor: (d: SavedSearch) => <Text>{d.name}</Text>,
    slot: 'custom',
    width: 200,
  },
  {
    Header: 'CREATED',
    id: 'created',
    accessor: (d: SavedSearch) => (
      <Text size='sm' css={{ color: '$text-tertiary' }}>
        {format(new Date(d.createdAt), 'MM/dd/yyyy')}
      </Text>
    ),
    slot: 'custom',
    width: 80,
  },
  {
    Header: '',
    id: 'actions',
    accessor: (d: SavedSearch) => <SavedSearchActionCell search={d} />,
    css: {
      justifyContent: 'flex-end',
    },
    slot: 'custom',
    width: 80,
  },
];

const SavedSearchesTemplate = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const page = parseInt(searchParams.get('page') ?? '1');

  const { data: savedSearchesResponse, isLoading } = useQuery(
    ['get_saved_searches', { page }],
    () =>
      apiClient.savedSearch.get({
        pageSize: 30,
        page,
      }),
  );

  if (isLoading) {
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }

  const savedSearches = savedSearchesResponse?.data ?? [];

  return (
    <Box
      css={{
        p: 16,
        pb: 70,
        mediaLg: {
          p: 32,
          pb: 70,
        },
      }}
    >
      <BackButton
        route={'sourcing'}
        onClick={() => navigate(routeNames.sourcing())}
      />
      <Text as='h1' size='3xl' css={{ fontWeight: '$medium' }}>
        Saved Searches
      </Text>
      <InteractiveTable
        count={savedSearchesResponse?.meta?.count || 0}
        pageSize={30}
        columns={savedSearcheColumns}
        tableData={savedSearches}
      />
    </Box>
  );
};

export default SavedSearchesTemplate;
