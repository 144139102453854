import { TOKEN } from '@betterleap/shared';
import { useDispatch } from 'react-redux';
import { apiActions } from 'store/api/actions';

export const useLogout = () => {
  const dispatch = useDispatch();
  const logout = () => {
    TOKEN.remove();
    dispatch(apiActions.remove());
  };
  return { logout };
};
