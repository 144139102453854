import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Radio,
  skeletonBgFlash,
  Text,
  Tooltip,
  TooltipProvider,
} from '@betterleap/ui';
import lowerCase from 'lodash/lowerCase';
import { useCopyToClipboard } from 'usehooks-ts';
import { ContactEmailDto } from '@betterleap/client';

export interface ContactEmailItemProps {
  allowRemoval: boolean;
  contactEmail: ContactEmailDto;
  handleRemoveEmail: (contactEmail: ContactEmailDto) => Promise<void>;
  onEmailCopied?: () => void;
}

const EmailValidityIndicator = ({ email }: { email: ContactEmailDto }) => {
  let content;

  switch (email.validity) {
    case ContactEmailDto.validity.VALID:
      content = 'Verified';
      break;
    case ContactEmailDto.validity.INVALID:
      content = 'Invalid';
      break;
    case ContactEmailDto.validity.INCONCLUSIVE:
      content = 'Validity Unknown';
      break;
    default:
      content = 'Verifying';
  }

  return (
    <TooltipProvider>
      <Tooltip
        content={
          <>
            <Flex justify='between' css={{ mb: 8, minWidth: 150 }}>
              <Text
                size='xs'
                css={{ color: '$text-inverse', fontWeight: '$bold' }}
              >
                {content}
              </Text>
              <a
                href='https://intercom.help/betterleap/en/articles/9579766-email-verification-status-codes'
                target='_blank'
                rel='noreferrer'
              >
                <Icon name='question-mark-circle' color='$white' size={12} />
              </a>
            </Flex>
            <Text size='xs' css={{ mb: 4, color: '$text-inverse' }}>
              Status: {lowerCase(email.rawStatus)}
            </Text>
            {email.rawSubStatus && (
              <Text size='xs' css={{ color: '$text-inverse' }}>
                Addl. Info: {lowerCase(email.rawSubStatus)}
              </Text>
            )}
          </>
        }
        css={{ zIndex: '$combo-menu' }} // else hidden behind the modal
      >
        <Box
          css={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            [`&[data-validity="${ContactEmailDto.validity.VALID}"]`]: {
              backgroundColor: '$green-600',
            },
            [`&[data-validity="${ContactEmailDto.validity.INVALID}"]`]: {
              backgroundColor: '$red-600',
            },
            [`&[data-validity="${ContactEmailDto.validity.INCONCLUSIVE}"]`]: {
              backgroundColor: '$yellow-600',
            },
            [`&[data-validity="validating"]`]: {
              animation: `0.8s linear infinite alternate ${skeletonBgFlash}`,
            },
          }}
          data-validity={email.validity ?? 'validating'}
        />
      </Tooltip>
    </TooltipProvider>
  );
};

const ContactEmailItem = ({
  allowRemoval,
  contactEmail,
  handleRemoveEmail,
  onEmailCopied,
}: ContactEmailItemProps) => {
  const [, copy] = useCopyToClipboard();

  return (
    <Flex
      css={{
        px: 8,
        py: 4,
        justifyContent: 'space-between',
        borderBottom: '1px solid $neutral-blue-300',
        gap: '8px',
      }}
    >
      <Flex css={{ justifyContent: 'center' }}>
        <Radio css={{ p: 0 }} value={contactEmail.email} />
      </Flex>
      <Flex css={{ width: '100%', overflow: 'hidden', gap: 8 }}>
        <Button
          variant='link'
          css={{
            color: '$neutral-blue-700',
            fontWeight: 'normal',
            borderBottom: 'none',
            hover: {
              borderBottom: 'none',
              textDecoration: 'underline',
            },
          }}
          onClick={() => {
            copy(contactEmail.email);
            onEmailCopied?.();
          }}
        >
          {contactEmail.email}
        </Button>
        <EmailValidityIndicator email={contactEmail} />
      </Flex>
      <IconButton
        label='remove'
        name='minus'
        size='xs'
        color='$red-600'
        aria-hidden={!allowRemoval}
        css={{
          backgroundColor: '$red-100',
          ...(!allowRemoval && { hidden: { visibility: 'hidden' } }),
        }}
        onClick={(e) => {
          e.preventDefault();
          handleRemoveEmail(contactEmail);
        }}
      />
    </Flex>
  );
};

export default ContactEmailItem;
