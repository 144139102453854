import { Types } from '@betterleap/shared';
import { CreateGroupDto, GroupDto } from '@betterleap/client';
import { Button, showToast, useModal } from '@betterleap/ui';
import EnterGroupNameModal, {
  EnterGroupNameProps,
  EnterGroupNameResult,
} from 'components/modules/Modal/EnterGroupNameModal/EnterGroupNameModal';
import useAnalytics from 'hooks/analytics';
import { apiClient } from 'lib/apiClient';
import { useMutation } from 'react-query';

interface UpdateGroupRequest {
  groupId: string;
  requestBody: CreateGroupDto;
}

interface RenameGroupButtonProps {
  group: GroupDto;
  onGroupRenamed: () => void;
}

export const RenameGroupButton = ({
  group,
  onGroupRenamed,
}: RenameGroupButtonProps) => {
  const { track } = useAnalytics();

  const openRenameGroupModal = useModal<
    EnterGroupNameProps,
    EnterGroupNameResult
  >(EnterGroupNameModal);

  const updateGroup = useMutation(
    ({ groupId, requestBody }: UpdateGroupRequest) =>
      apiClient.group.updateGroup({ id: groupId, requestBody }),
    {
      onSuccess: () => {
        onGroupRenamed();
      },
    },
  );

  const handleUpdateGroup = async (
    groupId: string,
    data?: EnterGroupNameResult,
  ) => {
    if (!data) {
      return;
    }
    try {
      await updateGroup.mutateAsync({ groupId, requestBody: data });
      showToast({
        title: 'Group name successfully updated!',
      });
    } catch (e) {
      showToast({
        variant: 'danger',
        title: 'Something went wrong!',
        description: 'Failed to update group name. Please try again.',
      });
    }
  };

  const onRenameGroupClick = async () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Rename group',
      buttonLocation: 'Team',
    });
    const result = await openRenameGroupModal({
      title: 'Rename Group',
      value: group.name,
      confirmButtonText: 'Save',
    });
    handleUpdateGroup(group.id, result);
  };

  return (
    <Button variant='secondary' size='sm' onClick={onRenameGroupClick}>
      Edit Name
    </Button>
  );
};
