import { LoadingArea } from '@betterleap/shared';
import {
  Box,
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Text,
  TextSkeleton,
} from '@betterleap/ui';
import { CollapisbleHeader } from './ContactProjects';

interface SummarySectionProps {
  summary: string;
  isLoading: boolean;
  fetchSummaryForUser: () => void;
}

const SummaryLoader = () => {
  return (
    <>
      {new Array(2).fill(undefined).map(() => (
        <Box css={{ mb: 20 }}>
          <TextSkeleton size='md' css={{ width: '100%' }} />
        </Box>
      ))}
    </>
  );
};

export const SummarySection = ({
  summary,
  isLoading,
  fetchSummaryForUser,
}: SummarySectionProps) => {
  const showGenerateSummaryButton = !summary && !isLoading;

  return (
    <Collapsible defaultOpen={true}>
      <CollapsibleTrigger asChild>
        <CollapisbleHeader
          title={`${
            showGenerateSummaryButton ? 'Generate ' : ''
          }AI Profile Summary`}
          icon='sparkles'
        />
      </CollapsibleTrigger>
      <CollapsibleContent>
        <Box css={{ pl: 24, pr: 24, pb: 24 }}>
          <Box className='highlight' css={{ pl: 5 }}>
            <LoadingArea isLoading={isLoading} loader={<SummaryLoader />}>
              <Text css={{ color: '$neutral-blue-700' }}>
                {summary ||
                  "This will generate a quick overview highlighting candidate's key qualifications."}
              </Text>

              {showGenerateSummaryButton && (
                <Button
                  onClick={fetchSummaryForUser}
                  css={{ marginTop: '16px' }}
                >
                  Generate Summary
                </Button>
              )}
            </LoadingArea>
          </Box>
        </Box>
      </CollapsibleContent>
    </Collapsible>
  );
};
