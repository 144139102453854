import React, { FC, RefObject, useRef } from 'react';
import { Delta, Sources } from 'quill';
import {
  AlertIcon,
  Box,
  Button,
  Icon,
  Label,
  showToast,
  Text,
  useModal,
} from '@betterleap/ui';
import { useCopyToClipboard } from 'usehooks-ts';
import ReactQuill from 'react-quill';
import { Markdown } from '../../elements/Markdown/Markdown';
import { CopilotEditor } from './CopilotEditor';
import { AssistantPage, PanelLayout } from './Layouts.template';
import { StickyButtonBar } from './StickyButtonBar';
import { SuggestionButton } from './SuggestionButton';
import WorkflowModal from './WorkflowModal';
import { ChartData, Charts } from './Charts';
import { SamplePrompt } from './GeneralCopilotTab';

interface UnprivilegedEditor {
  getLength(): number;
  getText(index?: number, length?: number): string;
  getContents(index?: number, length?: number): Delta;
}

interface CopilotProps {
  editorTitle: string;
  editorPlaceholder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorCSS?: any;
  onEditorChange: (
    content: string,
    delta: Delta,
    source: Sources,
    editor: UnprivilegedEditor,
  ) => void;
  submitText: string;
  result: string;
  isLoading: boolean;
  loadingComponent?: React.ReactNode;
  showRewriteButton?: boolean;
  feedbackButtons?: React.ReactNode;
  samplePrompts: Record<string, SamplePrompt>;
  textPrompt: string;
  setTextPrompt: (text: string) => void;
  handleClear: () => void;
  onSubmit: () => void;
  isLoadingVisualization?: boolean;
  chartData?: ChartData | null;
}

export const Copilot: FC<CopilotProps> = ({
  editorTitle,
  editorPlaceholder,
  editorCSS,
  onEditorChange,
  submitText,
  result,
  isLoading,
  loadingComponent = <Text css={{ color: '$text-tertiary' }}>Thinking...</Text>,
  showRewriteButton,
  feedbackButtons,
  samplePrompts,
  textPrompt,
  setTextPrompt,
  handleClear,
  onSubmit,
  isLoadingVisualization,
  chartData,
}) => {
  const editorRef = useRef<ReactQuill>();

  const [, copy] = useCopyToClipboard();
  const openWorkflowModal = useModal<undefined, { prompt: string }>(
    WorkflowModal,
  );

  const handleOpenWorkflowCenter = async () => {
    const res = await openWorkflowModal();
    if (res?.prompt) {
      setTextPrompt(res.prompt);
    }
  };

  return (
    <AssistantPage css={editorCSS}>
      <AssistantPage.Editor>
        <PanelLayout>
          <PanelLayout.Label>
            <Label
              htmlFor='copilot-editor'
              css={{ color: '$text-tertiary', fontSize: '$xs', cursor: 'text' }}
            >
              {editorTitle}
            </Label>
          </PanelLayout.Label>

          <PanelLayout.Main>
            <CopilotEditor
              ref={editorRef as RefObject<ReactQuill>}
              value={textPrompt}
              id='copilot-editor'
              placeholder={editorPlaceholder}
              onChange={onEditorChange}
            />
          </PanelLayout.Main>

          <PanelLayout.Footer>
            <StickyButtonBar>
              <Button size='xs' variant='gray' onClick={handleClear}>
                Clear
              </Button>
              <Button
                size='xs'
                variant='secondary'
                css={{ gap: 8 }}
                onClick={onSubmit}
                disabled={!textPrompt}
              >
                {submitText}{' '}
                <Icon name='arrow-narrow-right' inherit size={12} />
              </Button>
            </StickyButtonBar>
          </PanelLayout.Footer>
        </PanelLayout>
      </AssistantPage.Editor>

      <AssistantPage.Result>
        <PanelLayout>
          <PanelLayout.Label>
            <Text size='xs' css={{ color: '$text-tertiary', mb: 8 }}>
              Result
            </Text>
          </PanelLayout.Label>
          <PanelLayout.Main>
            {!result ? (
              isLoading ? (
                loadingComponent
              ) : (
                <Text as='span' css={{ color: '$text-tertiary' }}>
                  Your result will appear here
                </Text>
              )
            ) : (
              <Markdown markdown={result} />
            )}

            {isLoadingVisualization ? (
              <Text css={{ color: '$text-tertiary', mt: 18 }}>
                Generating Visualization...
              </Text>
            ) : (
              chartData && (
                <PanelLayout.Vis
                  css={{
                    marginTop: 30,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {chartData && <Charts config={chartData} />}
                </PanelLayout.Vis>
              )
            )}
          </PanelLayout.Main>

          <PanelLayout.Footer>
            <StickyButtonBar>
              {feedbackButtons}
              <Button
                size='xs'
                variant='gray'
                css={{ gap: 8 }}
                disabled={!result}
                onClick={() => {
                  copy(result);
                  showToast({
                    title: 'Copied!',
                    description:
                      'Copilot response was copied to your clipboard.',
                  });
                }}
              >
                <Icon name='document-duplicate' inherit size={12} /> Copy
              </Button>
              {showRewriteButton && (
                <Button
                  size='xs'
                  variant='gray'
                  css={{ gap: 8 }}
                  onClick={() => onSubmit()}
                  disabled={!result || !textPrompt}
                >
                  <Icon name='refresh' inherit size={12} /> Rewrite
                </Button>
              )}
            </StickyButtonBar>
          </PanelLayout.Footer>
        </PanelLayout>
      </AssistantPage.Result>

      <AssistantPage.Sidebar>
        <Button
          css={{ gap: 8 }}
          full
          variant='secondary'
          onClick={handleOpenWorkflowCenter}
        >
          <Icon css={{ fill: 'inherit' }} size={16} name='template' /> Workflows
          Center
        </Button>
        <Text size='xs' css={{ my: 24 }}>
          Try workflows created by our recruiter community
        </Text>
        {Object.keys(samplePrompts).map((category) => (
          <Box key={category} css={{ pb: 32 }}>
            <Text
              size='sm'
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                mb: 8,
              }}
            >
              <AlertIcon
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                variant={samplePrompts[category]!.variant}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                name={samplePrompts[category]!.icon}
                size='sm'
                shape='rounded-square'
              />{' '}
              {category}
            </Text>
            {samplePrompts[category]?.prompts.map((prompt: string) => (
              <SuggestionButton
                key={prompt}
                onClick={() => setTextPrompt(prompt)}
              >
                {prompt}
              </SuggestionButton>
            ))}
          </Box>
        ))}
      </AssistantPage.Sidebar>
    </AssistantPage>
  );
};
