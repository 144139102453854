import { LoadingArea } from '@betterleap/shared';
import { EnrichmentJobExperience } from '@betterleap/client';
import { Box, BoxProps, Flex, Text, TextSkeleton } from '@betterleap/ui';
import { startCase } from 'lodash';
import {
  formatDateRange,
  formatTenure,
} from '../../../../functions/formatDateRange';
import { SectionHeader } from './SectionHeader';

export interface ExperienceSectionProps extends BoxProps {
  experience: EnrichmentJobExperience[];
  isLoading?: boolean;
}

export interface ExperienceEntryProps extends BoxProps {
  entry: EnrichmentJobExperience;
}

// remove bullets from summary.
export const formatSummary = (summary?: string) => {
  if (!summary) return '';

  return (
    summary
      // eslint-disable-next-line no-useless-escape
      .split(/\s*[\.|:|;]?\s*[\*|•|●]\s*/)
      .filter((str) => !!str)
      .join('. ')
  );
};

export const formatLocation = (location?: string) => {
  if (!location) return '';

  return startCase(location.split(',')[0] ?? '');
};

const ExperienceLoader = () => {
  return (
    <>
      {new Array(2).fill(undefined).map(() => (
        <Box css={{ mb: 40 }}>
          <TextSkeleton size='md' css={{ my: 2, width: 350 }} />
          <TextSkeleton size='sm' css={{ my: 4, width: 200 }} />
          <TextSkeleton size='sm' css={{ my: 2, height: 56, width: '100%' }} />
        </Box>
      ))}
    </>
  );
};

export const ExperienceEntry = ({ entry, ...rest }: ExperienceEntryProps) => {
  const dateRange = formatDateRange(entry);
  const tenure = formatTenure(entry);

  const dateAndLocation = [tenure, dateRange, formatLocation(entry.location)]
    .filter((value) => !!value)
    .join(' • ');

  return (
    <Box {...rest} className='highlight'>
      <Flex css={{ mb: 4 }}>
        <Text css={{ fontWeight: '$medium' }}>
          {entry.title}{' '}
          <Text
            as='span'
            inline
            css={{ fontWeight: '$medium', color: '$text-tertiary' }}
          >
            {entry.companyName}
          </Text>
        </Text>
      </Flex>
      <Flex css={{ gap: 6, mb: 4 }}>
        <Text size='sm' css={{ color: '$text-secondary' }}>
          {dateAndLocation}
        </Text>
      </Flex>
      <Text size='sm' css={{ lineHeight: '160%' }}>
        {formatSummary(entry.summary)}
      </Text>
    </Box>
  );
};

export const ExperienceSection = ({
  experience,
  isLoading,
}: ExperienceSectionProps) => {
  return (
    <Box css={{ p: 24 }}>
      <SectionHeader title='Experience' icon='briefcase' css={{ mb: 16 }} />
      <Box className='highlight' css={{ pl: 40 }}>
        <LoadingArea
          isLoading={!experience.length && isLoading}
          loader={<ExperienceLoader />}
        >
          {experience.map((job, index) => (
            <ExperienceEntry
              css={{ mb: 40 }}
              key={`${job.title}-${index}`}
              entry={job}
            />
          ))}
        </LoadingArea>
      </Box>
    </Box>
  );
};
