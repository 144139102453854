import { capitalize, escapeRegExp } from 'lodash';
import { FilterItem, InclusionType } from './SourceCandidates.types';

export const exclusionPrefix = '!~';
export const mustIncludePrefix = '!+';

export function isExclusion(term: string) {
  return term.startsWith(exclusionPrefix);
}

export function hideExclusionPrefix(term: string) {
  return term.replace(exclusionPrefix, '');
}

export function isMustInclusion(term: string) {
  return !isExclusion(term) && term.startsWith(mustIncludePrefix);
}

export function isShouldInclusion(term: string) {
  return !isExclusion(term) && !isMustInclusion(term);
}

export function hidePrefix(term: string) {
  return term.replace(
    new RegExp(`^(${exclusionPrefix}|${escapeRegExp(mustIncludePrefix)})`),
    '',
  );
}

export function getInclusions(terms: string[]) {
  return terms
    .filter((term) => !isExclusion(term))
    .map((term) => hidePrefix(term));
}

export function applyTerm(item: FilterItem) {
  if (item.excluded) {
    return `${exclusionPrefix}${item.name}`;
  }

  switch (item.type) {
    case InclusionType.CANT_HAVE:
      return `${exclusionPrefix}${item.name}`;
    case InclusionType.MUST_HAVE:
      return `${mustIncludePrefix}${item.name}`;
    default:
      return item.name;
  }
}

export function parseTerm(term: string) {
  let type = InclusionType.CAN_HAVE;

  if (isExclusion(term)) {
    type = InclusionType.CANT_HAVE;
  }

  if (isMustInclusion(term)) {
    type = InclusionType.MUST_HAVE;
  }

  return {
    name: hidePrefix(term),
    excluded: isExclusion(term),
    type,
  };
}

// NOTE: lodash startcase strips punctuation
//  https://github.com/lodash/lodash/issues/3383#issuecomment-430586750
export const betterStartCase = (name: string) => {
  // do not capitalize if already includes capitals
  return /[A-Z]/.test(name) ? name : name.replace(/\w+/g, capitalize);
};

export const filterItemsEqual = (a: FilterItem, b: FilterItem) =>
  a.name === b.name && a.field === b.field;
