import { getFullName } from '@betterleap/shared';
import { Sequence } from '@betterleap/client';
import {
  Box,
  BoxProps,
  Button,
  Combobox,
  Flex,
  Icon,
  Label,
  Option,
  Text,
} from '@betterleap/ui';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { apiClient } from '../../../lib/apiClient';

interface CloneSequenceProps extends BoxProps {
  onReset: () => void;
  onApply: (sequence: Sequence) => void;
}

export const CloneSequence = ({ onReset, onApply }: CloneSequenceProps) => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [sequenceToClone, setSequenceToClone] = useState<Sequence>();
  const [sequenceCloneApplied, setSequenceCloneApplied] = useState(false);

  const { data: sequences, isLoading: isSequencesLoading } = useQuery(
    ['search_sequences', { name: searchTerm }],
    () =>
      apiClient.sequence.findAllForUser({
        name: searchTerm,
        pageSize: 10,
        orderProperty: 'createdAt',
        orderDirection: 'DESC',
        relations: ['sender', 'user', 'steps'],
      }),
  );

  const handleApplyCloneSequence = () => {
    if (sequenceToClone) {
      setSequenceCloneApplied(true);
      onApply(sequenceToClone);
    }
  };

  const handleResetSequence = () => {
    setSequenceCloneApplied(false);

    onReset();
  };

  return (
    <Box
      css={{
        mb: 32,
        backgroundColor: 'white',
        boxShadow: '$base',
        borderRadius: '$lg',
        padding: 20,
      }}
    >
      <Label
        css={{ mb: 8, display: 'inline-block' }}
        htmlFor='search-sequences'
      >
        Copy content from:
      </Label>
      <Flex
        css={{
          gap: 8,
          '@lg': {
            maxWidth: '55%',
          },
        }}
      >
        <Combobox
          id='search-sequences'
          placeholder='Select or search a sequence'
          options={sequences?.data ?? []}
          loading={isSequencesLoading}
          disabled={sequenceCloneApplied}
          renderSelection={(item) => item?.value?.name}
          onChange={(keys, selected) => {
            setSequenceToClone(selected?.[0]);
          }}
          onFilterChange={(filter) => setSearchTerm(filter)}
        >
          {(sequence) => (
            <Option>
              <div>
                <Text truncate>{sequence.name}</Text>
                <Text size='xs' css={{ color: '$text-tertiary' }} truncate>
                  {getFullName({
                    firstName: sequence.user.firstName,
                    lastName: sequence.user.lastName,
                  })}
                </Text>
              </div>
            </Option>
          )}
        </Combobox>
        {sequenceCloneApplied ? (
          <Button variant='secondary' onClick={handleResetSequence}>
            Reset{' '}
            <Icon css={{ fill: 'inherit', ml: 8 }} size={16} name='refresh' />
          </Button>
        ) : (
          <Button
            disabled={!sequenceToClone}
            variant='secondary'
            onClick={handleApplyCloneSequence}
          >
            Apply{' '}
            <Icon
              css={{ fill: 'inherit', ml: 8 }}
              size={16}
              name='arrow-narrow-right'
            />
          </Button>
        )}
      </Flex>
    </Box>
  );
};
