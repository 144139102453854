import { Drawer, DrawerContent, Flex, Spinner } from '@betterleap/ui';
import { Project } from '@betterleap/client';
import { atomWithHash } from 'jotai-location';
import { atom, useAtom, useAtomValue } from 'jotai';
import { ContactPreviewSearchResult } from 'components/templates/Sourcing/SourceCandidates.template';
import { ContactProfile } from './ContactProfile';

export const resultIdAtom = atomWithHash<string>('resultId', '');

export interface DrawerContext {
  contactEmailEnrichmentMode: Project.contactEmailEnrichmentMode;
}

const findByIdOrContactId =
  (findId: string) => (result: Partial<ContactPreviewSearchResult>) =>
    result.contactId === findId || result.id === findId;

export const drawerCollectionState = atom<
  Partial<ContactPreviewSearchResult>[]
>([]);

export const sourcedContactIdState = atom<string | undefined>(undefined);

export const sharedProjectIdState = atom<string | undefined>(undefined);

export const activeContactSelector = atom((get) => {
  const searchResults = get(drawerCollectionState);
  const resultId = get(resultIdAtom);
  return searchResults.find(findByIdOrContactId(resultId));
});

export const nextContactIdSelector = atom((get) => {
  const searchResults = get(drawerCollectionState);
  const resultId = get(resultIdAtom);
  const currentIndex = searchResults.findIndex(findByIdOrContactId(resultId));

  if (currentIndex < 0) return undefined;

  const nextResult = searchResults[currentIndex + 1];
  return nextResult?.contactId ?? nextResult?.id;
});

export const lastContactIdSelector = atom((get) => {
  const searchResults = get(drawerCollectionState);
  const resultId = get(resultIdAtom);
  const currentIndex = searchResults.findIndex(findByIdOrContactId(resultId));

  if (currentIndex < 0) return undefined;

  const lastResult = searchResults[currentIndex - 1];
  return lastResult?.contactId ?? lastResult?.id;
});

export const ContactDrawer = () => {
  const [resultId, setResultId] = useAtom(resultIdAtom);
  const result = useAtomValue(activeContactSelector);
  const isDrawerOpen = !!resultId;

  const handleOnOpenChange = (open: boolean) => {
    if (!open) {
      setResultId('');
    }
  };

  return (
    <Drawer open={isDrawerOpen} onOpenChange={handleOnOpenChange} modal={false}>
      <DrawerContent>
        {result ? (
          <ContactProfile contact={result} />
        ) : (
          <Flex
            centered
            css={{
              minHeight: 750,
            }}
          >
            <Spinner variant='blue' />
          </Flex>
        )}
      </DrawerContent>
    </Drawer>
  );
};
