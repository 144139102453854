/* eslint-disable @typescript-eslint/no-explicit-any */
import { Contact } from '@betterleap/client';
import {
  AlertIcon,
  Box,
  Button,
  Flex,
  Form,
  BoundInput as Input,
  Modal,
  ModalTitle,
  PromiseModal,
} from '@betterleap/ui';
import React from 'react';
import { useForm } from 'react-hook-form';

export interface CreateProjectModalProps {
  mode: Contact.contactEmailEnrichmentMode;
  projectName: string;
}

const CreateProjectModal: PromiseModal<
  CreateProjectModalProps,
  CreateProjectModalProps
> = ({ onSubmit, mode }) => {
  const { control, handleSubmit } = useForm<CreateProjectModalProps>({
    defaultValues: { mode },
  });

  return (
    <Modal>
      <Flex vertical css={{ width: '100%', alignItems: 'center' }}>
        <Box css={{ py: 16 }}>
          <AlertIcon variant='purple' name='collection' size='lg' />
        </Box>
        <ModalTitle css={{ paddingBottom: 24 }}>
          Create a new project
        </ModalTitle>
        <Form
          control={control as any}
          css={{ width: '100%' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            css={{ width: '100%' }}
            placeholder='Enter Project Name'
            type='text'
            name='projectName'
            id='project-name'
            required
          />
        </Form>
        <Flex justify='center' css={{ px: 56, py: 8, width: '100%' }}>
          <Button full onClick={handleSubmit(onSubmit)}>
            Create Project
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default CreateProjectModal;
