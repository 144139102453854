import {
  Select,
  SelectItem,
  SelectProps,
  showToast,
  useModal,
  usePrevious,
} from '@betterleap/ui';
import { subject } from '@betterleap/authz';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useMe } from '@betterleap/shared';
import { GroupDto } from '@betterleap/client';
import {
  ConfirmModal,
  ConfirmModalProps,
} from 'components/modules/Modal/ConfirmModal/ConfirmModal';
import { apiClient } from 'lib/apiClient';
import { InviteMember } from './OrganizationInviteTemplate';

interface GroupSelectProps extends SelectProps {
  member: InviteMember;
  groups?: GroupDto[];
  onGroupChanged: () => void;
}

export const GroupSelect = ({
  css,
  member,
  groups = [],
  onGroupChanged,
  ...rest
}: GroupSelectProps) => {
  const me = useMe();

  const openConfirmChangeGroupModal = useModal<ConfirmModalProps>(ConfirmModal);

  const [group, setGroup] = useState(member.groupId);

  useEffect(() => {
    setGroup(member.groupId);
  }, [member]);

  const previousGroup = usePrevious(group);
  const canUpdateGroup = me.ability.can(
    'update',
    subject('User', member),
    'groupId',
  );

  const updateGroup = useMutation(
    (body: Parameters<typeof apiClient.user.update>[0]) =>
      apiClient.user.update(body),
    {
      onSuccess: () => {
        showToast({
          variant: 'success',
          title: 'Success!',
          description: "This user's group has been changed.",
        });
        onGroupChanged();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Oops, Something went wrong',
          description: 'Please try again',
        });

        if (previousGroup) {
          setGroup(previousGroup);
        }
      },
    },
  );

  const handleGroupChange = async (groupId: string) => {
    const fromGroup = groups.find((g) => g.id === group);
    const selectedGroup = groups.find((g) => g.id === groupId);

    if (!fromGroup || !selectedGroup) {
      return;
    }

    const confirm = await openConfirmChangeGroupModal({
      title: 'Reassign Group',
      description: (
        <>
          Are you sure you want to move {member.name} from {fromGroup.name} to{' '}
          {selectedGroup.name}?
        </>
      ),
      icon: {
        name: 'exclamation-circle',
        variant: 'warning',
      },
      confirmationText: 'Confirm',
      dismissButtonText: 'Cancel',
    });

    if (confirm) {
      setGroup(groupId);

      updateGroup.mutate({
        id: member.id as string,
        requestBody: {
          groupId,
        },
      });
    }
  };

  if (groups.length <= 1) {
    return null;
  }

  return (
    <Select
      onChange={handleGroupChange}
      value={group}
      disabled={!canUpdateGroup || !!member.invitationId}
      css={{
        p: 0,
        border: 'none',
        backgroundColor: 'transparent',
        '& svg': {
          fill: '$blue-600',
        },
        disabled: {
          color: '$gray-500',
          backgroundColor: 'transparent',
          cursor: 'auto',
          '& svg': {
            display: 'none',
          },
        },
        color: '$blue-600',
        ...css,
      }}
      {...rest}
    >
      {groups.map((g) => (
        <SelectItem key={g.id} value={g.id}>
          {g.name}
        </SelectItem>
      ))}
    </Select>
  );
};
