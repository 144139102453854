import React from 'react';
import Separator from '../Separator/Separator';

const SeparatorWithText = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => (
  <div className={`flex w-full items-center mb-6 mt-6 ${className}`}>
    <Separator />
    <p className='not-italic font-normal text-sm text-center text-white w-full text-center ml-2 mr-2'>
      {text}
    </p>
    <Separator />
  </div>
);

export default SeparatorWithText;
