import { Box, Flex, Icon, Text } from '@betterleap/ui';
import notionSequence from '../../../assets/images/email-finder/notion-sequence.svg';
import shapes from '../../../assets/images/email-finder/shapes.svg';
import Section from './Section';

const ReachMoreSection = (): JSX.Element => {
  return (
    <Section
      css={{
        pt: 0,
        pb: 0,
        mediaLg: {
          pt: 0,
        },
      }}
    >
      <Box
        css={{
          p: 24,
          backgroundColor: '$purple-400',
          borderRadius: 24,
          mediaLg: {
            p: 80,
            backgroundImage: `url(${shapes})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 0 bottom 0',
          },
        }}
      >
        <Flex
          css={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            mb: 40,
            gap: 8,
            mediaLg: { flexDirection: 'row', mb: 80 },
          }}
        >
          <Icon
            name='arrow-right'
            color='$background'
            css={{
              transform: 'rotate(45deg)',
              mt: 6,
              height: 32,
              width: 32,
              mediaLg: {
                mt: 8,
                height: 40,
                width: 40,
              },
            }}
          />
          <Text
            css={{
              lineHeight: '130%',
              fontSize: 32,
              mediaLg: { fontSize: 48 },
            }}
          >
            Reach more candidates with Betterleap
          </Text>
        </Flex>
        <Flex
          css={{
            flexDirection: 'column',
            gap: 40,
            mediaLg: { flexDirection: 'row' },
          }}
        >
          <Flex justify='center' css={{ flex: 1, width: '100%' }}>
            <Box
              css={{
                objectFit: 'fit',
                width: '95%',
                minHeight: 160,
                mediaLg: {
                  width: '100%',
                },
              }}
              as='img'
              src={notionSequence}
            />
          </Flex>
          <Flex css={{ gap: 10, alignItems: 'flex-start', flex: 1 }}>
            <div>
              <Icon
                name='arrow-right'
                color='$neutral-blue-1000'
                css={{
                  transform: 'rotate(45deg)',
                  mt: 6,
                  height: 32,
                  width: 32,
                  mediaLg: {
                    height: 40,
                    width: 40,
                  },
                }}
              />
            </div>
            <Text
              css={{
                lineHeight: '170%',
                fontSize: 18,
                mediaLg: { maxWidth: 434 },
              }}
            >
              We drive higher conversion rates for recruiters using AI-powered
              sourcing automation and unrivaled email coverage. Say goodbye to
              awful email deliverability rates and hello to a world of seamless
              connections.
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Section>
  );
};

export default ReachMoreSection;
