import { Navigation, Spinner, TOKEN, Types, useMe } from '@betterleap/shared';
import { Organization } from '@betterleap/client';
import { Badge, Box, Flex, Icon, IconProps } from '@betterleap/ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Banners } from 'components/elements/Banners';
import { useBanner } from 'components/elements/Banner/Banner';
import { LaunchNotesWidget } from 'components/elements/LaunchNotesWidget/LaunchNotesWidget';
import { logToSegment } from 'functions/segmentLog';
import { useLogout } from 'hooks/useLogout';
import { FeatureFlagKey, useFeatureFlag } from 'hooks/useFeatureFlag';
import {
  unreadMessagesCount,
  useChatAppContext,
} from '@betterleap/chat-shared';
import routeNames from '../../constants/routeNames';
import { selectOrganization } from '../../store/api/selectors';
import { isPaywallTier } from '../../helper/paywall';

const iconProps: Omit<IconProps, 'name'> = {
  size: 20,
};

const __DEV__ = process.env.NODE_ENV === 'development';

const withSidebar =
  <T extends object>(
    WrappedComponent: React.ComponentType<T>,
    noSpacing?: boolean,
  ) =>
  (props: T) => {
    const me = useMe();
    const { state } = useChatAppContext();
    const unreadMessages = unreadMessagesCount(state);
    const navigate = useNavigate();
    const location = useLocation();

    const isSignInAsAnotherUser = TOKEN.isSessionStorage();

    const organization = useSelector(selectOrganization);
    const { banner } = useBanner();
    const hasCandidateMessaging = useFeatureFlag(
      FeatureFlagKey.CANDIDATE_MESSAGING,
    );

    const isPaywallAccount =
      organization?.data?.tier && isPaywallTier(organization?.data?.tier);

    const logNavToSegment = (buttonName: string) => {
      logToSegment(Types.SEGMENT_EVENTS.NAV_CLICKED, {
        button_name: buttonName,
      });
    };

    const { logout } = useLogout();

    const navbarItems = [
      {
        title: 'Home',
        route: '/dashboard',
        icon: <Icon name='home' {...iconProps} />,
        onClick: () => {
          logNavToSegment('Home');
        },
      },
      {
        title: 'Sourcing',
        route: '/sourcing',
        icon: <Icon name='search' {...iconProps} />,
        onClick: () => {
          logNavToSegment('Sourcing');
        },
      },
      {
        title: 'Portfolio',
        route: '/portfolio',
        icon: <Icon name='book-open' {...iconProps} />,
        onClick: () => {
          logNavToSegment('Portfolio');
        },
        hidden: organization.data?.orgType !== Organization.orgType.VC,
      },
      {
        title: 'Projects',
        route: '/projects',
        icon: <Icon name='collection' {...iconProps} />,
        onClick: () => {
          logNavToSegment('Projects');
        },
      },
      {
        title: 'Chat',
        route: '/chat',
        icon: <Icon name='chat' {...iconProps} />,
        indicator: unreadMessages ? (
          <Badge variant='dark-purple' size='xs' shape='oval'>
            {unreadMessages}
          </Badge>
        ) : null,
        onClick: () => {
          logNavToSegment('Chat');
        },
        hidden: !__DEV__ && hasCandidateMessaging !== true,
        beta: true,
      },
      {
        title: 'AI Assistant',
        route: '/copilot',
        icon: <Icon name='sparkles' {...iconProps} />,
        onClick: () => {
          logNavToSegment('AI Assistant');
        },
      },
    ];

    const optionRoutes = [
      {
        id: 'whats-new-link',
        hidden: isPaywallAccount,
        icon: <Icon name='speakerphone' {...iconProps} />,
      },
      {
        icon: <Icon name='cog' {...iconProps} />,
        children: [
          {
            title: 'Team',
            route: '/team',
            icon: <Icon name='user-group' {...iconProps} />,
            onClick: () => {
              logNavToSegment('Team');
            },
          },
          {
            title: 'Profile',
            route: routeNames.profile(),
            icon: <Icon name='user' {...iconProps} />,
            onClick: () => {
              logNavToSegment('Your Profile');
            },
            hidden:
              isPaywallAccount ||
              organization.data?.orgType === Organization.orgType.VC,
          },
          {
            title: 'Billing',
            icon: <Icon name='currency-dollar' {...iconProps} />,
            onClick: () => {
              window.open(
                `${process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL}?prefilled_email=${me.user?.email}`,
                '_blank',
              );
              logNavToSegment('Billing');
            },
            hidden:
              !me.ability.can('manage', 'Billing') ||
              organization.data?.tier !== Organization.tier.PAID,
          },
          {
            title: 'FAQs',
            icon: <Icon name='faq' {...iconProps} />,
            onClick: () => {
              window.open('https://intercom.help/betterleap/en', '_blank');
              logNavToSegment('FAQs');
            },
          },
          {
            title: 'Settings',
            route: routeNames.settings(),
            icon: <Icon name='adjustments' {...iconProps} />,
            onClick: () => {
              logNavToSegment('Settings');
            },
            hidden: isPaywallAccount,
          },
          {
            title: 'Sign out',
            icon: <Icon name='logout' {...iconProps} />,
            onClick: () => {
              logout();
            },
          },
        ],
      },
    ];

    if (!me.user)
      return (
        <Flex justify='center' align='center' css={{ height: 384 }}>
          <Spinner variant='blue' />
        </Flex>
      );

    return (
      <>
        <Box
          css={{
            position: 'relative',
            width: '100%',
            minHeight: 'calc(100vh - 86px)',
            background: '#F9FAFB',
            overflow: 'hidden',
          }}
        >
          <Navigation
            navbarRoutes={navbarItems}
            optionRoutes={optionRoutes}
            handleNavigate={navigate}
            currentLocation={location}
          />

          <Banners />
          <Box
            style={{
              padding: noSpacing ? 0 : 32,
              // eslint-disable-next-line no-nested-ternary
              paddingTop: banner ? 52 : noSpacing ? 0 : 32,
              paddingBottom: isSignInAsAnotherUser ? 52 : noSpacing ? 0 : 32,
            }}
            css={{
              height: 'calc(100vh - 86px)',
              overflow: 'auto',
            }}
          >
            <LaunchNotesWidget userId={me.user?.id} />
            <WrappedComponent {...props} />
          </Box>
          {isSignInAsAnotherUser && (
            <div className='absolute bottom-0 left-0 w-full bg-red-100'>
              <p className='text-center p-3 font-medium text-base leading-6 text-red-700'>
                ⚠️⚠️⚠️️You are signed in as {me.user?.firstName}{' '}
                {me?.user?.lastName} Please exercise caution! ⚠️⚠️⚠️
              </p>
            </div>
          )}
        </Box>
      </>
    );
  };

export default withSidebar;
