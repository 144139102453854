import React from 'react';
import styles from './Modal.module.scss';

interface ModalImageProps {
  icon: string;
  className?: string;
}

const ModalImage = ({ icon, className }: ModalImageProps) => (
  <div className='w-full flex justify-center'>
    <div
      className={`mb-5 flex items-center justify-center w-12 h-12 ${styles.img_wrapper}`}
    >
      <img src={icon} alt='modal_icon' className={className} />
    </div>
  </div>
);

export default ModalImage;
