import { InteractiveTable } from '@betterleap/shared';
import { Sender } from '@betterleap/client';
import { Box, Button, Flex, showToast, Text, useModal } from '@betterleap/ui';
import { useMutation, useQuery } from 'react-query';
import { apiClient } from 'lib/apiClient';
import { useConnectEmail } from 'hooks/useConnectEmail';
import {
  ConfirmRemoveSenderModal,
  ConfirmRemoveSenderModalProps,
} from 'components/modules/Modal/ConfirmRemoveSenderModal/ConfirmRemoveSenderModal';
import api from '../../../lib/api';
import { senderTableColumns } from './SenderTableColumns';

const EmailSenderSettings = () => {
  const handleConnectEmail = useConnectEmail();
  const openConfirmRemoveEmailModal = useModal<ConfirmRemoveSenderModalProps>(
    ConfirmRemoveSenderModal,
  );

  const { data: sendersResponse, refetch: refetchSenders } = useQuery(
    ['get_senders'],
    () => api.fetch<{ data: Sender[] }>('get_senders'),
  );

  const removeSender = useMutation(
    (data: { senderId: string }) =>
      apiClient.sender.deleteSender({ senderId: data.senderId }),
    {
      onSuccess: () => {
        refetchSenders();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to remove email. Please try again.',
        });
      },
    },
  );

  const senders = sendersResponse?.data?.data ?? [];

  return (
    <Flex vertical>
      <Flex
        css={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          mediaMd: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          },
          width: '100%',
        }}
      >
        <Box>
          <Text
            css={{
              fontSize: '$sm',
              lineHeight: '$lg',
              color: '$neutral-blue-900',
              fontWeight: '$medium',
              mb: 8,
            }}
          >
            Connect Email Account
          </Text>
          <Text
            css={{
              fontSize: '$sm',
              lineHeight: '$lg',
              fontWeight: '$normal',
              color: '$neutral-blue-700',
            }}
          >
            This allows Betterleap to send email sequences on your behalf
          </Text>
        </Box>
        <Button
          onClick={() => handleConnectEmail()}
          css={{
            color: '$primary-base',
            mt: 8,
            mediaMd: { mt: 'unset' },
          }}
          variant='headless'
        >
          Connect {senders.length ? 'Another' : ''} Email
        </Button>
      </Flex>
      {senders.length > 0 && (
        <InteractiveTable
          css={{
            '.header_row': {
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            },
            '.header_row > [role="row"]': {
              height: 32,
            },
            '[role="rowgroup"] [role="row"]': {
              height: 56,
            },
            '[role="rowgroup"] [role="row"]:last-child': {
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            },
          }}
          tableData={senders}
          columns={senderTableColumns({
            onReconnect: (sender: Sender) => {
              handleConnectEmail(sender.provider);
            },
            onRemove: async (sender: Sender) => {
              const confirm = await openConfirmRemoveEmailModal({ sender });

              if (confirm) {
                removeSender.mutate({
                  senderId: sender.id,
                });
              }
            },
          })}
        />
      )}
    </Flex>
  );
};

export default EmailSenderSettings;
