import { Tooltip } from '@betterleap/ui';
import { Row } from 'react-table';
import { ProjectWithStatsDto } from '@betterleap/client';
import { ProjectActionButton } from '../../ProjectActions';
import {
  countSelectedContacts,
  resolveSubject,
  selectContactIds,
} from '../../ProjectDetails.functions';
import { useRemoveContacts } from '../../Hooks/useRemoveContacts';

interface Props {
  project: ProjectWithStatsDto;
  totalContactCount: number;
  selectedContacts: Row<Record<string, unknown>>[] | 'all';
  onContactsRemoved: () => void;
}

export const RemoveFromProjectButton = ({
  project,
  totalContactCount,
  selectedContacts,
  onContactsRemoved,
}: Props) => {
  const count = countSelectedContacts(selectedContacts, totalContactCount);
  const contactIds = selectContactIds(selectedContacts);
  const { removeFromProject } = useRemoveContacts({
    project,
    selectedContactCount: count,
    selectedContactIds: contactIds,
    onContactsRemoved,
  });
  return (
    <Tooltip content='Remove from project'>
      <ProjectActionButton
        onClick={() => removeFromProject()}
        disabled={selectedContacts !== 'all' && selectedContacts.length === 0}
        css={{
          p: 12,
          fill: '$red-700',
          border: '1px solid $red-700',
          backgroundColor: '$background-component',
        }}
        name='minus-circle'
        label={`Remove ${resolveSubject(project)} from project.`}
      />
    </Tooltip>
  );
};
