/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AlertIcon,
  Box,
  Button,
  Flex,
  Modal,
  ModalTitle,
  PromiseModal,
} from '@betterleap/ui';

export interface DeleteTeamMemberModalProps {
  name: string;
}

const DeleteTeamMemberModal: PromiseModal<
  DeleteTeamMemberModalProps,
  DeleteTeamMemberModalProps
> = ({ name, onSubmit, onDismiss }) => (
  <Modal>
    <Flex vertical css={{ width: '100%', alignItems: 'center' }}>
      <Box css={{ py: 16 }}>
        <AlertIcon variant='danger' name='trash' size='xl' />
      </Box>
      <ModalTitle css={{ paddingBottom: 24 }}>Deactivate {name}</ModalTitle>
      <Flex justify='center' css={{ gap: 12 }}>
        <Button
          variant='gray'
          onClick={onDismiss}
          css={{ width: 146 }}
          dataCy='Cancel'
        >
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={() => onSubmit()}
          css={{ width: 146 }}
          dataCy='Deactivate'
        >
          Deactivate
        </Button>
      </Flex>
    </Flex>
  </Modal>
);

export default DeleteTeamMemberModal;
