import { Button, showToast, Spinner, Tooltip } from '@betterleap/ui';
import { ProjectWithStatsDto } from '@betterleap/client';
import { Row } from 'react-table';
import { useMutation } from 'react-query';
import { apiClient } from 'lib/apiClient';
import { ProjectActionButton } from '../../ProjectActions';
import {
  hasSelection,
  isSalesProject,
  selectContactIds,
} from '../../ProjectDetails.functions';
import { useProjectContactsFilterParams } from '../../useProjectContactsFilterParams';

interface Props {
  project: ProjectWithStatsDto;
  selectedContacts: Row<Record<string, unknown>>[] | 'all';
  onSyncStarted: () => void;
}

export const SyncAtsContactButton = ({
  project,
  selectedContacts,
  onSyncStarted,
}: Props) => {
  const { contactProjectQuery } = useProjectContactsFilterParams();

  const syncWithAts = useMutation(
    ({ contactIds }: { contactIds?: string[] }) =>
      apiClient.project.syncWithAts({
        requestBody: {
          contactIds,
          ...contactProjectQuery,
          projectId: project.id,
        },
      }),
    {
      onSuccess: () => {
        showToast({
          variant: 'success',
          title: 'Success!',
          description: `Successfully started ATS auto-sync for candidates.`,
        });
        onSyncStarted();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: `Failed to start ATS auto-syncing for candidates. Please try again.`,
        });
      },
    },
  );

  return isSalesProject(project) ? null : (
    <Tooltip content='Auto-sync contact with ATS'>
      {syncWithAts.isLoading ? (
        <LoadingButton />
      ) : (
        <ProjectActionButton
          onClick={() => {
            syncWithAts.mutate({
              contactIds: selectContactIds(selectedContacts),
            });
          }}
          disabled={!hasSelection(selectedContacts) || !canSync(project)}
          name='sync-complete'
          label='Auto-sync candidates with ATS.'
        />
      )}
    </Tooltip>
  );
};

function canSync(project: ProjectWithStatsDto) {
  return project.roleId && !project.projectRoleAutoSync;
}

const LoadingButton = () => (
  <Button
    disabled
    css={{
      position: 'relative',
      display: 'inline-flex',
      height: 32,
      width: 32,
      borderRadius: '$lg',
      borderStyle: 'none',
      disabled: {
        backgroundColor: '$neutral-blue-200',
      },
    }}
  >
    <Spinner css={{ position: 'absolute', width: 16, height: 16 }} />
  </Button>
);
