import {
  AlertIcon,
  Box,
  Button,
  Flex,
  Label,
  ModalTitle,
} from '@betterleap/ui';
import { FieldValues, FormState } from 'react-hook-form';

interface ChildProps {
  children: JSX.Element | (JSX.Element | false)[];
}

interface ModalHeaderProps {
  title: string;
  icon: 'sparkles' | 'star';
}

export const ModalHeader = ({ title, icon }: ModalHeaderProps) => (
  <Flex css={{ flexDirection: 'column' }}>
    <Box css={{ py: 8 }}>
      <AlertIcon
        variant='info'
        name={icon}
        size='lg'
        css={{ borderRadius: 12 }}
      />
    </Box>
    <ModalTitle>{title}</ModalTitle>
  </Flex>
);

export const ModalField = ({ children }: ChildProps) => (
  <Flex
    css={{
      width: '100%',
      alignSelf: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 12,
    }}
  >
    {children}
  </Flex>
);

interface FieldTitleProps {
  title: string;
  icon: 'document-text' | 'menu-alt-2';
  htmlFor: string;
}

export const FieldTitle = ({ title, icon, htmlFor }: FieldTitleProps) => (
  <Label htmlFor={htmlFor}>
    <Flex>
      <AlertIcon variant='success' name={icon} css={{ borderRadius: 8 }} />
      <Box css={{ ml: 12, fontSize: '$sm' }}>{title}</Box>
    </Flex>
  </Label>
);

interface ModalButtonsProps<T extends FieldValues> {
  formState: FormState<T>;
  submitText: string;
  onDelete?: () => void;
  onCancel?: () => void;
}

export const ModalButtons = <T extends FieldValues>(
  props: ModalButtonsProps<T>,
) => (
  <Flex css={{ mt: 24, gap: 10, '& button': { minWidth: 146 } }}>
    {!!props.onDelete && (
      <Button variant='danger' onClick={props.onDelete}>
        Delete
      </Button>
    )}
    {!!props.onCancel && (
      <Button variant='secondary' onClick={props.onCancel}>
        Cancel
      </Button>
    )}
    <Button type='submit' disabled={!props.formState.isValid}>
      {props.submitText}
    </Button>
  </Flex>
);
