import { Box, Flex, Icon, Text } from '@betterleap/ui';
import { CsvUploader } from './CsvUploader';
import Section from './Section';

const CtaCardSection = (): JSX.Element => {
  return (
    <Section
      css={{
        pt: 0,
        mediaLg: {
          pt: 0,
        },
      }}
    >
      <Flex
        css={{
          backgroundColor: '$purple-200',
          p: 24,
          borderRadius: 24,
          gap: 20,
          width: '100%',
          flexDirection: 'column',
          mediaLg: {
            flexDirection: 'row',
            gap: 100,
            borderRadius: 40,
            p: 80,
          },
        }}
      >
        <Flex
          css={{
            gap: 8,
            alignItems: 'flex-start',
            flexDirection: 'column',
            flex: 1,
            mediaLg: { flexDirection: 'row' },
          }}
        >
          <div>
            <Icon
              name='arrow-right'
              color='$purple-500'
              css={{
                transform: 'rotate(45deg)',
                mt: 6,
                height: 32,
                width: 32,
                mediaLg: {
                  mt: 8,
                  height: 40,
                  width: 40,
                },
              }}
            />
          </div>
          <div>
            <Text
              css={{
                lineHeight: '130%',
                mb: 40,
                fontSize: 32,
                mediaLg: { fontSize: 48, mb: 16, whiteSpace: 'nowrap' },
              }}
            >
              Upload your .CSV
              <br /> to get started
            </Text>
            <Text
              css={{
                lineHeight: '170%',
                fontSize: 18,
                mediaLg: { fontSize: 20, lineHeight: '28px' },
              }}
            >
              Upload LinkedIn links of your candidates and retrieve email
              addresses using our free tool.
            </Text>
          </div>
        </Flex>
        <Box css={{ flex: 1, width: '100%', p: 22 }}>
          <CsvUploader
            css={{
              boxShadow: '0px 0px 0px 22px #C4B5FD80',
              borderColor: '$white',
              height: 176,
            }}
            uploadBarCss={{
              mt: 32,
              boxShadow: '0px 0px 0px 22px #C4B5FD80',
            }}
          />
        </Box>
      </Flex>
    </Section>
  );
};

export default CtaCardSection;
