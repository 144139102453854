import { Spinner } from '@betterleap/shared';
import React from 'react';
import { Flex } from '@betterleap/ui';
import { useSelector } from 'react-redux';
import RecruiterToolPaywall from 'components/templates/RecruiterToolPaywall/RecruiterToolPaywall';
import { selectOrganization } from '../../store/api/selectors';
import { isPaywallTier } from '../../helper/paywall';

const withPaywall =
  (WrappedComponent: React.ComponentType) =>
  (props: Record<string, unknown>) => {
    const organization = useSelector(selectOrganization);

    if (!organization?.data) {
      return (
        <Flex css={{ justifyContent: 'center', flexGrow: 1 }}>
          <Spinner variant='blue' />
        </Flex>
      );
    }
    return isPaywallTier(organization.data.tier) ? (
      <RecruiterToolPaywall tier={organization.data.tier} />
    ) : (
      <WrappedComponent {...props} />
    );
  };

export default withPaywall;
