import { Badge, Flex, Tab, TabContent, Tabs, TabsList } from '@betterleap/ui';
import { ContactDncWarning, LoadingArea } from '@betterleap/shared';
import { Contact, ExtendedContactProject } from '@betterleap/client';
import { useQuery } from 'react-query';
import { apiClient } from 'lib/apiClient';
import ContactProfileNotes from './ContactProfileNotes.template';
import ContactProfileExperience from './ContactProfileExperience.template';
import ContactProfileEducation from './ContactProfileEducation.template';
import ContactProfileProjects from './ContactProfileProjects.template';
import { ContactActivityTemplate } from './ContactActivity.template';

export interface ContactProfileDetailsProps {
  myUserId: string;
  contact: Contact;
}

const ContactProfileDetails = (props: ContactProfileDetailsProps) => {
  const { myUserId, contact } = props;
  const contactId = contact.id;

  const { data: contactExperience, isLoading: isExperienceLoading } = useQuery(
    ['get_contact_experience', contactId],
    () => apiClient.contactEnrichment.getContactExperience({ contactId }),
  );

  const experience = contactExperience?.data ?? [];

  const { data: contactEducation, isLoading: isEducationLoading } = useQuery(
    ['get_contact_education', contactId],
    () => apiClient.contactEnrichment.getContactEducation({ contactId }),
  );

  const education = contactEducation?.data ?? [];

  const { data: contactProjectResponse, isLoading: isContactProjectsLoading } =
    useQuery(['get_contact_projects', contactId], () =>
      apiClient.contactSearch.searchProject({ requestBody: { contactId } }),
    );

  const contactProjects = contactProjectResponse?.data ?? [];
  const dncStatus = ExtendedContactProject.status.DO_NOT_CONTACT;
  const isOnDncList = contactProjects.some((cp) => cp.status === dncStatus);

  const {
    data: contactProjectNotes,
    isLoading: isNotesLoading,
    refetch: refetchContactNotes,
  } = useQuery(['get_contact_notes', contactId], () =>
    apiClient.contactNote.getContactNotes({ contactId }),
  );

  const notes = contactProjectNotes?.data ?? [];

  if (
    isExperienceLoading ||
    isEducationLoading ||
    isContactProjectsLoading ||
    isNotesLoading
  ) {
    return <LoadingArea />;
  }

  const defaultTabValue =
    experience.length > 0
      ? 'Experience'
      : education.length > 0
      ? 'Education'
      : 'Notes';
  return (
    <Flex
      id='contact-profile-details'
      css={{
        alignItems: 'flex-start',
        height: '100%',
        width: '100%',
      }}
    >
      <Tabs
        defaultValue={defaultTabValue}
        css={{ width: '100%', height: '100%' }}
      >
        <TabsList css={{ mb: 32 }}>
          {experience.length > 0 && <Tab value='Experience'>Experience</Tab>}
          {education.length > 0 && <Tab value='Education'>Education</Tab>}
          {contactProjects.length > 0 && (
            <Tab value='Projects'>
              Projects
              <Badge shape='oval' variant='info'>
                {contactProjects.length}
              </Badge>
            </Tab>
          )}
          <Tab value='Notes'>
            Notes
            <Badge shape='oval' variant='success'>
              {notes.length}
            </Badge>
          </Tab>
          <Tab value='Activity'>Activity</Tab>
        </TabsList>

        {isOnDncList && <ContactDncWarning />}
        {experience.length > 0 && (
          <TabContent id='experience-tab-content' value='Experience'>
            <ContactProfileExperience jobExperienceHistory={experience} />
          </TabContent>
        )}
        {education.length > 0 && (
          <TabContent id='education-tab-content' value='Education'>
            <ContactProfileEducation educationHistory={education} />
          </TabContent>
        )}
        {contactProjects.length > 0 && (
          <TabContent id='projects-tab-content' value='Projects'>
            <ContactProfileProjects contactProjects={contactProjects} />
          </TabContent>
        )}
        <TabContent id='notes-tab-content' value='Notes'>
          <ContactProfileNotes
            myUserId={myUserId}
            contactId={contactId}
            notes={notes}
            isNotesLoading={isNotesLoading}
            refetchContactNotes={refetchContactNotes}
          />
        </TabContent>
        <TabContent id='contact-activity-tab' value='Activity'>
          <ContactActivityTemplate contact={contact} />
        </TabContent>
      </Tabs>
    </Flex>
  );
};

export default ContactProfileDetails;
