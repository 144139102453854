import { Badge } from '@betterleap/ui';

interface ContactStatusProps {
  status?:
    | 'Sourced'
    | 'Scheduled'
    | 'Messaged'
    | 'Opened'
    | 'Bounced'
    | 'Replied'
    | 'No Reply'
    | 'Do Not Contact'
    | 'Paused';
}

export const StatusColumn = () => {
  const ContactStatus = ({ status }: ContactStatusProps) => {
    switch (status) {
      case 'Sourced':
        return (
          <Badge size='sm' variant='orange'>
            Sourced
          </Badge>
        );
      case 'Scheduled':
        return (
          <Badge size='sm' variant='teal'>
            Scheduled
          </Badge>
        );
      case 'Bounced':
        return (
          <Badge size='sm' variant='danger'>
            Bounced
          </Badge>
        );
      case 'Messaged':
        return (
          <Badge
            size='sm'
            css={{
              backgroundColor: '$violet-100',
              color: '$violet-800',
            }}
          >
            Messaged
          </Badge>
        );
      case 'No Reply':
        return (
          <Badge size='sm' variant='disabled'>
            No Reply
          </Badge>
        );
      case 'Opened':
        return (
          <Badge size='sm' variant='warning'>
            Opened
          </Badge>
        );
      case 'Replied':
        return (
          <Badge size='sm' variant='success'>
            Replied
          </Badge>
        );
      case 'Do Not Contact':
        return (
          <Badge size='sm' variant='danger'>
            Do Not Contact
          </Badge>
        );
      case 'Paused':
        return (
          <Badge size='sm' variant='disabled'>
            Paused
          </Badge>
        );
      default:
        return (
          <Badge size='sm' variant='info'>
            Sourced
          </Badge>
        );
    }
  };
  return {
    Header: 'STATUS',
    width: 90,
    headerCss: {
      maxWidth: 150,
    },
    css: {
      maxWidth: 150,
    },
    accessor: (contact: { status: string }) => (
      <ContactStatus status={contact.status as ContactStatusProps['status']} />
    ),
    slot: 'custom',
  };
};
