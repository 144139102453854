import routeNames from 'constants/routeNames';
import {
  Alert,
  AlertAction,
  AlertDescription,
  AlertHeading,
  AlertingIcon,
  AlertProps,
  useModal,
} from '@betterleap/ui';
import { Types } from '@betterleap/shared';
import { useNavigate } from 'react-router-dom';
import useAnalytics from 'hooks/analytics';
import {
  StartSourcingModal,
  StartSourcingModalProps,
} from 'components/modules/Modal/StartSourcingModal/StartSourcingModal';

export interface SourcingBannerProps extends AlertProps {
  onUploadCSV: () => void;
  enrichedCount: number;
}

export const SourcingBanner = ({
  enrichedCount = 0,
  onUploadCSV,
  ...rest
}: SourcingBannerProps) => {
  const navigate = useNavigate();
  const openStartSourcingModal =
    useModal<StartSourcingModalProps>(StartSourcingModal);

  const { track } = useAnalytics();

  const handleStartSourcingClick = () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Start Sourcing',
      buttonLocation: 'Sourcing Copilot banner on the Project Details page',
    });
    openStartSourcingModal({
      onUploadCSV,
      onSourceWithBetterleap: () => {
        navigate(routeNames.sourcing());
      },
    });
  };

  return (
    <Alert
      variant='purple'
      css={{ border: '1px solid $purple-300', borderRadius: '$2xl' }}
      {...rest}
    >
      <AlertingIcon name='sparkles' size='lg' />
      <AlertHeading size='base'>Recommended Matches</AlertHeading>
      <AlertDescription>
        Source {10 - enrichedCount} more candidate
        {enrichedCount !== 9 ? 's' : ''}, and we will start recommending
        candidates daily.
      </AlertDescription>
      <AlertAction onClick={handleStartSourcingClick}>
        Start Sourcing
      </AlertAction>
    </Alert>
  );
};
