import React, { useRef } from 'react';
import {
  DeepPartial,
  Path,
  PathValue,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { Sequence } from '../schemas/Sequence';

export const useWatchSubject = <T extends DeepPartial<Sequence>>({
  watch,
  setValue,
  trigger,
}: {
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  trigger: UseFormTrigger<T>;
}) => {
  const previousValues = useRef<DeepPartial<Sequence>>({ steps: [] });

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // watch for step changes
      if (name?.startsWith('steps.') && type === 'change') {
        // get changed step index
        const indexString = name.split('.')[1];

        if (!indexString) return;
        // change step index to int
        const changedIndex = parseInt(indexString, 10);

        // get the previous value of the changed step
        const oldSubject =
          previousValues.current.steps?.[changedIndex]?.subject ?? '';

        // get the steps after the changed steps
        const followingSteps = value.steps?.slice(changedIndex + 1) ?? [];

        for (let index = 0; index < followingSteps.length; index++) {
          const step = followingSteps[index];

          // update remaining subjects if they are in the same thread as the changed subject
          if (step?.subject === oldSubject) {
            setValue(
              `steps.${index + changedIndex + 1}.subject` as Path<T>,
              value.steps?.[changedIndex]?.subject as PathValue<T, Path<T>>,
            );
            trigger(`steps.${index + changedIndex + 1}.subject` as Path<T>);
          } else {
            break;
          }
        }
      }

      // track previous sequence value
      previousValues.current = {
        ...value,
        steps: [...(value.steps ?? [])],
      };
    });

    return () => subscription.unsubscribe();
  }, [watch]);
};

export default useWatchSubject;
