import { CheckStartSequenceResult } from '@betterleap/client';
import {
  Alert,
  AlertHeading,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';
import React, { useState } from 'react';

export interface SendSequenceConfirmModalProps {
  responseData: CheckStartSequenceResult;
}

export interface SendSequenceConfirmModalOnSubmitData {
  skipContactsAlreadyActiveInSequence: boolean;
}

const SendSequenceConfirmModal: PromiseModal<
  SendSequenceConfirmModalProps,
  SendSequenceConfirmModalOnSubmitData
> = ({ onDismiss, onSubmit, responseData }) => {
  const [
    skipContactsAlreadyActiveInSequence,
    setSkipContactsAlreadyActiveInSequence,
  ] = useState(true);
  return (
    <Modal>
      <Flex vertical css={{ width: '100%', alignItems: 'center' }}>
        <Box css={{ py: 16 }}>
          <AlertIcon variant='danger' name='exclamation' size='lg' />
        </Box>
        <ModalTitle css={{ paddingBottom: 24 }}>
          Active Sequences Detected
        </ModalTitle>
        <Alert variant='danger'>
          <AlertHeading>
            <Text css={{ fontWeight: '$bold' }} inherit inline>
              {responseData.contactsWithActiveSequencesCount} contact
              {responseData.contactsWithActiveSequencesCount > 1 ? 's' : ''}
            </Text>{' '}
            from this list{' '}
            {responseData.contactsWithActiveSequencesCount > 1 ? 'are' : 'is'}{' '}
            already enrolled in another active sequence. Do you still want to
            add them to this sequence?
          </AlertHeading>
          <Box
            css={{
              overflowY: 'auto',
              height: '144px',
              width: '211px',
              gridArea: 'action',
              fontSize: '$sm',
            }}
          >
            {responseData.contactNamesWithActiveSequences.map((n) => (
              <li>{n}</li>
            ))}
          </Box>
        </Alert>
        <Flex
          css={{
            gap: 8,
            pt: 12,
            pl: 12,
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <Checkbox
            checked={!skipContactsAlreadyActiveInSequence}
            onChange={(val: boolean) => {
              setSkipContactsAlreadyActiveInSequence(!val);
            }}
          >
            Include all contacts
          </Checkbox>
        </Flex>
        <Flex
          css={{
            gap: 8,
            pt: 24,
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <Button variant='ghost' onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit({ skipContactsAlreadyActiveInSequence })}
          >
            Send
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default SendSequenceConfirmModal;
