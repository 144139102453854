import { getFullName, InteractiveTable, LoadingArea } from '@betterleap/shared';
import { TeamStatsDto } from '@betterleap/client';
import { Combobox, Flex, Option, Text } from '@betterleap/ui';
import {
  eachMonthOfInterval,
  endOfMonth,
  format,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { apiClient } from 'lib/apiClient';

const teamStatsColumns = [
  {
    Header: 'NAME',
    accessor: (d: TeamStatsDto) => (
      <>
        <Text size='sm' css={{ fontWeight: '$medium' }}>
          {getFullName({
            firstName: d.firstName,
            lastName: d.lastName,
          }) || '--'}
        </Text>
        <Text size='xs' css={{ color: '$text-lightest' }}>
          {d.email}
        </Text>
      </>
    ),
    slot: 'custom',
    width: 200,
  },
  {
    Header: 'VIEWED',
    accessor: (d: TeamStatsDto) => (
      <Text size='sm' css={{ color: '$text-tertiary' }}>
        {d.profilesViewedCount || '--'}
      </Text>
    ),
    slot: 'custom',
    width: 80,
  },
  {
    Header: 'SOURCED',
    accessor: (d: TeamStatsDto) => (
      <Text size='sm' css={{ color: '$text-tertiary' }}>
        {d.contactsSourcedCount || '--'}
      </Text>
    ),
    slot: 'custom',
    width: 80,
  },
  {
    Header: 'CONTACTED',
    accessor: (d: TeamStatsDto) => (
      <Text size='sm' css={{ color: '$text-tertiary' }}>
        {d.contactsMessagedCount || '--'}
      </Text>
    ),
    slot: 'custom',
    width: 80,
  },
  {
    Header: 'OPENED',
    accessor: (d: TeamStatsDto) => (
      <Text size='sm' css={{ color: '$text-tertiary' }}>
        {d.contactsOpenedEmailCount || '--'}
      </Text>
    ),
    slot: 'custom',
    width: 80,
  },
  {
    Header: 'REPLIED',
    accessor: (d: TeamStatsDto) => (
      <Text size='sm' css={{ color: '$text-tertiary' }}>
        {d.contactsRepliedCount || '--'}
      </Text>
    ),
    slot: 'custom',
    width: 80,
  },
  {
    Header: 'INTERESTED',
    accessor: (d: TeamStatsDto) => (
      <Text size='sm' css={{ color: '$text-tertiary' }}>
        {d.contactsInterestedCount || '--'}
      </Text>
    ),
    slot: 'custom',
    width: 80,
  },
];

export const TeamStats = () => {
  const [searchParams] = useSearchParams();

  const monthOptions = useMemo(() => {
    const startOfCurrentMonth = startOfMonth(new Date());
    return eachMonthOfInterval({
      start: subMonths(startOfCurrentMonth, 11),
      end: startOfMonth(new Date()),
    })
      .map((monthStart) => ({
        id: format(monthStart, 'MMMM yyyy'),
        label: format(monthStart, 'MMMM yyyy'),
        startDate: monthStart,
        endDate: endOfMonth(monthStart),
      }))
      .reverse();
  }, []);

  const [timeInterval, setTimeInterval] = useState({
    startDate: monthOptions[0]?.startDate,
    endDate: monthOptions[0]?.endDate,
  });

  const { data: teamStats, isLoading } = useQuery(
    [
      'teamStats',
      {
        page: searchParams?.get('statspage') ?? 1,
        ...timeInterval,
      },
    ],
    () =>
      apiClient.organization.teamStats({
        startTime: timeInterval.startDate?.toISOString() as string,
        endTime: timeInterval.endDate?.toISOString() as string,
        page: parseInt(searchParams?.get('statspage') ?? '1', 10),
        pageSize: 5,
      }),
  );

  return (
    <>
      <Flex css={{ mt: 40 }} justify='between'>
        <Text
          as='h2'
          css={{
            fontWeight: '$medium',
            fontSize: '$lg',
          }}
        >
          Team Stats
        </Text>
        <Combobox
          aria-label='select month'
          css={{ maxWidth: 200 }}
          typeahead={false}
          clearable={false}
          options={monthOptions}
          defaultValue={monthOptions[0]?.id}
          onChange={(value) => {
            const newMonth = monthOptions.find(
              (month) => month.id === value[0],
            );

            if (newMonth) {
              setTimeInterval({
                startDate: newMonth.startDate,
                endDate: newMonth.endDate,
              });
            }
          }}
        >
          {(option) => <Option>{option.label}</Option>}
        </Combobox>
      </Flex>
      <LoadingArea css={{ minHeight: 200 }} isLoading={isLoading}>
        <InteractiveTable
          count={teamStats?.meta?.count || 0}
          pageSize={5}
          paginationKey='stats'
          columns={teamStatsColumns}
          tableData={teamStats?.data ?? []}
        />
      </LoadingArea>
    </>
  );
};
