import { Button, Divider, Icon, Text } from '@betterleap/ui';

interface Props {
  projectName: string;
  onClick: () => void;
}

export const CreateProjectAction = ({ projectName, onClick }: Props) => (
  <>
    <Divider css={{ backgroundColor: '$neutral-blue-300' }} />
    <Button
      variant='headless'
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        p: '12px 20px',
        width: '100%',
        minHeight: 52,
        color: '$blue-600',
        '& > svg *': { fill: '$blue-600' },
      }}
      onClick={onClick}
    >
      <Text css={{ color: 'inherit' }}>
        <Text as='span' css={{ color: 'inherit' }}>
          Create:{' '}
        </Text>
        <Text as='em' css={{ color: 'inherit' }}>
          {projectName}
        </Text>
      </Text>
      <Icon name='arrow-right' size={20} />
    </Button>
  </>
);
