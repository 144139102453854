import { LoadingArea } from '@betterleap/shared';
import { EnrichmentCertification } from '@betterleap/client';
import { Box, BoxProps, Flex, Text, TextSkeleton } from '@betterleap/ui';
import { format } from 'date-fns';
import { useState } from 'react';
import { ShowMoreButton } from 'components/elements/ShowMoreButton/ShowMoreButton';
import { useHighlightSearchTerms } from 'components/templates/Sourcing/useHighlightWords';
import { SectionHeader } from './SectionHeader';

export interface CertificationsSectionProps extends BoxProps {
  certifications: EnrichmentCertification[];
  isLoading?: boolean;
}

export interface CertificationsEntryProps extends BoxProps {
  entry: EnrichmentCertification;
}

const CertificationsLoader = () => {
  return (
    <>
      <TextSkeleton size='md' css={{ mb: 10, width: 350 }} />
      <TextSkeleton size='md' css={{ mb: 10, width: 400 }} />
    </>
  );
};

export const CertificationsEntry = ({
  entry,
  ...rest
}: CertificationsEntryProps) => {
  const orgAndYear = [
    entry.organization,
    entry.endDate ? format(new Date(entry.endDate), 'yyyy') : '',
  ]
    .filter((value) => !!value)
    .join(' • ');

  return (
    <Box {...rest}>
      <Flex css={{ mb: 4 }}>
        <Text css={{ fontWeight: '$medium' }}>
          {entry.name}{' '}
          <Text as='span' inline css={{ color: '$text-secondary' }}>
            {orgAndYear}
          </Text>
        </Text>
      </Flex>
    </Box>
  );
};

export const CertificationsSection = ({
  certifications,
  isLoading,
}: CertificationsSectionProps) => {
  const [showAll, setShowAll] = useState(false);
  const highlightTerms = useHighlightSearchTerms();

  const visibleCertifications = showAll
    ? certifications
    : certifications.slice(0, 5);

  return (
    <Box css={{ p: 24 }}>
      <SectionHeader
        title='Certifications'
        icon='academic-cap'
        css={{ mb: 16 }}
      />
      <Box css={{ pl: 40 }} className='highlight'>
        <LoadingArea
          isLoading={!certifications.length && isLoading}
          loader={<CertificationsLoader />}
        >
          {visibleCertifications.map((cert) => (
            <CertificationsEntry css={{ mb: 8 }} key={cert.name} entry={cert} />
          ))}
          {certifications.length > 5 && (
            <ShowMoreButton
              showLessText='Show Less'
              showMoreText={`Show all (${certifications.length})`}
              onToggle={(show) => {
                setShowAll(show);
                highlightTerms();
              }}
            />
          )}
        </LoadingArea>
      </Box>
    </Box>
  );
};
