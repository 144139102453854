import { RecruiterTestimonalsDto } from '@betterleap/client';
import {
  Flex,
  Form,
  BoundInput as Input,
  Modal,
  PromiseModal,
  BoundTextArea as TextArea,
} from '@betterleap/ui';
import { useForm } from 'react-hook-form';
import {
  FieldTitle,
  ModalButtons,
  ModalField,
  ModalHeader,
} from 'components/modules/Modal/Modal.elements';

export interface AddTestimonialResult {
  companyName: string;
  quote: string;
  delete?: boolean;
}

export interface AddTestimonialModalProps {
  testimonial?: RecruiterTestimonalsDto;
}

export const AddTestimonialModal: PromiseModal<AddTestimonialModalProps> = ({
  testimonial,
  onDismiss,
  onSubmit,
}) => {
  const { control, formState, handleSubmit } = useForm<{
    companyName: string;
    quote: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      companyName: testimonial?.companyName ?? '',
      quote: testimonial?.quotes ?? '',
    },
  });
  const onDelete = () => {
    onSubmit({ delete: true });
  };
  return (
    <Modal css={{ mediaLg: { p: 24 } }}>
      <Form control={control} onSubmit={handleSubmit(onSubmit)}>
        <Flex css={{ alignItems: 'center', gap: 12 }} vertical>
          <ModalHeader title='Add a testimonial' icon='star' />
          <Flex css={{ flexDirection: 'column', width: '100%', gap: 16 }}>
            <ModalField>
              <FieldTitle
                title='Company name'
                icon='document-text'
                htmlFor='companyName'
              />
              <Input
                id='companyName'
                name='companyName'
                placeholder='Company name'
                required
                autoComplete='off'
                css={{ width: '100%' }}
              />
            </ModalField>
            <ModalField>
              <FieldTitle title='Quote' icon='menu-alt-2' htmlFor='quote' />
              <TextArea
                id='quote'
                name='quote'
                placeholder='Try to keep quotes only a sentence or two long.'
                required
                css={{
                  width: '100%',
                  border: '1px solid $neutral-blue-300',
                  borderRadius: 16,
                  p: 16,
                }}
              />
            </ModalField>
          </Flex>
          <ModalButtons
            formState={formState}
            submitText={!testimonial ? 'Submit' : 'Done'}
            onCancel={!testimonial ? onDismiss : undefined}
            onDelete={testimonial ? onDelete : undefined}
          />
        </Flex>
      </Form>
    </Modal>
  );
};
