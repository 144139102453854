import { ContactActivityType, ProjectContactStatus } from '@betterleap/client';
import { showToast, Text, useModal } from '@betterleap/ui';
import {
  ConfirmModal,
  ConfirmModalProps,
} from 'components/modules/Modal/ConfirmModal/ConfirmModal';
import { apiClient } from 'lib/apiClient';
import { useMutation } from 'react-query';
import { CandidateImportNotices } from '../CandidateImportNotices';
import SendSequenceConfirmModal, {
  SendSequenceConfirmModalOnSubmitData,
  SendSequenceConfirmModalProps,
} from '../SendSequenceConfirmModal';

interface UseSendSequenceProps {
  projectId: string;
  contactIds?: string[];
  activityTypes?: ContactActivityType[];
  statuses?: {
    and: ProjectContactStatus[][];
  };
  showSuccessModal?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const useSendSequence = ({
  projectId,
  contactIds,
  activityTypes,
  statuses,
  showSuccessModal = true,
  onSuccess,
  onCancel,
}: UseSendSequenceProps) => {
  const openSendSequenceConfirmModal = useModal<
    SendSequenceConfirmModalProps,
    SendSequenceConfirmModalOnSubmitData
  >(SendSequenceConfirmModal);
  const openAlertModal = useModal<ConfirmModalProps>(ConfirmModal);

  const checkStartSequence = useMutation(
    () =>
      apiClient.project.checkStartSequenceRequest({
        requestBody: {
          projectId,
          contactIds,
          activityTypes,
          statuses,
        },
      }),
    {
      onSuccess: async (response) => {
        let skipContactsAlreadyActiveInSequence = true;
        if (response.data.contactsWithActiveSequencesCount > 0) {
          const confirmation = await openSendSequenceConfirmModal({
            responseData: response.data,
          });

          if (!confirmation) {
            onCancel?.();
            return;
          }
          skipContactsAlreadyActiveInSequence =
            confirmation.skipContactsAlreadyActiveInSequence;
        }

        startSequence.mutate({
          skipContactsAlreadyActiveInSequence,
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Starting Sequences Failed',
          description: 'Something went wrong. Please try again.',
        });
      },
    },
  );

  const startSequence = useMutation(
    (request: { skipContactsAlreadyActiveInSequence: boolean }) =>
      apiClient.project.startSequence({
        requestBody: {
          projectId,
          contactIds,
          activityTypes,
          statuses,
          skipContactsAlreadyActiveInSequence:
            request.skipContactsAlreadyActiveInSequence,
        },
      }),
    {
      onSuccess: async (response) => {
        if (showSuccessModal) {
          await openAlertModal({
            title: `Your contacts have been added!`,
            description: (
              <>
                <Text css={{ fontWeight: '$bold' }} inherit inline>
                  {response.data.contactsAddedToSequence} contacts
                </Text>{' '}
                will start receiving this sequence.
              </>
            ),
            icon: {
              name: 'mail-open',
              variant: 'info',
            },
            notices: CandidateImportNotices(response.data, true),
            confirmationText: 'Done',
          });
        }

        onSuccess?.();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Starting Sequences Failed',
          description: 'Something went wrong. Please try again.',
        });
      },
    },
  );

  return {
    sendSequence: () => {
      checkStartSequence.mutate();
    },
    isLoading: checkStartSequence.isLoading || startSequence.isLoading,
  };
};
