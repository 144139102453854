import {
  ActionsDropdown,
  ActionsDropdownProps,
  InteractiveTable,
  LoadingArea,
} from '@betterleap/shared';
import {
  InvitationsMetadataDto,
  LinkedOrganization,
  Organization,
} from '@betterleap/client';
import {
  AlertIcon,
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  showToast,
  Tab,
  Tabs,
  TabsList,
  Text,
  useModal,
} from '@betterleap/ui';
import moment from 'moment';
import qs from 'qs';
import get from 'lodash/get';
import { useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import {
  createSearchParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { EmptyState } from 'components/elements/EmptyState/EmptyState';
import { InvitationRequest } from 'components/modules/Modal/InviteExternalModal/InviteExternalModal';
import { apiClient } from '../../../lib/apiClient';
import { selectOrganization } from '../../../store/api/selectors';
import PaywallPopupModal from '../../modules/Modal/PaywallPopupModal/PaywallPopupModal';
import addCandidatesImage from '../../../assets/images/add-candidates.svg';
import { useInvitePortfolioCompany } from './useInvitePortfolioCompany';

const portfolioColumns = (
  onResendClick?: (value: LinkedOrganization) => void,
  onRemoveClick?: (value: LinkedOrganization) => void,
) => ({
  columns: [
    {
      Header: 'COMPANY',
      accessor: 'company',
      css: {
        '& p': {
          color: '$neutral-blue-1000',
        },
      },
    },
    {
      Header: 'NAME',
      accessor: 'name',
      css: {
        '& p': {
          color: '$neutral-blue-1000',
        },
      },
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
      css: {
        '& p': {
          color: '$neutral-blue-600',
        },
      },
    },
    {
      Header: 'INVITED ON',
      accessor: (value: LinkedOrganization) => (
        <Text css={{ fontSize: '$sm', color: '$neutral-blue-600' }}>
          {moment(value.invitedOn).format('MM/DD/YYYY')}
        </Text>
      ),
      slot: 'custom',
    },
    {
      Header: 'STATUS',
      accessor: (value: LinkedOrganization) => (
        <Badge
          size='sm'
          variant={value.status === 'joined' ? 'success' : 'pink'}
          shape='oval'
        >
          {value.status === 'joined' ? 'Joined' : 'Invited'}
        </Badge>
      ),
      slot: 'custom',
      width: 120,
    },
    {
      Header: '',
      id: 'menu',
      menu: true,
      width: 50,
      accessor: (value: LinkedOrganization) => {
        const options: ActionsDropdownProps<LinkedOrganization>['options'] =
          value.status === 'pending'
            ? [
                {
                  label: 'Resend',
                  onClick: (val) => onResendClick?.(val),
                },
                {
                  label: 'Remove',
                  color: '#FF8A8A',
                  onClick: (val) => onRemoveClick?.(val),
                },
              ]
            : [
                {
                  label: 'Remove',
                  color: '#FF8A8A',
                  onClick: (val) => onRemoveClick?.(val),
                },
              ];

        return (
          <ActionsDropdown<LinkedOrganization>
            value={value}
            options={options}
          />
        );
      },
    },
  ],
});

const Portfolio = (): JSX.Element => {
  const inviteCountRef = useRef<{
    joined: number;
    total: number;
    pending: number;
  }>({
    total: 0,
    pending: 0,
    joined: 0,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const organization = useSelector(selectOrganization);

  const { search } = useLocation();
  const queryParams = qs.parse(search.slice(1));

  const status =
    searchParams.get('status') === 'All'
      ? undefined
      : (searchParams.get('status') as LinkedOrganization['status']);

  const {
    data: linkedOrgResponse,
    isLoading,
    refetch,
  } = useQuery(['get_linked_orgs', queryParams], () =>
    apiClient.invitation.getLinkedOrganizations({
      ...queryParams,
      status,
      page: parseInt(get(queryParams, 'page', '1').toString(), 10),
      pageSize: parseInt(get(queryParams, 'pageSize', '10').toString(), 10),
    }),
  );

  const linkedOrgs = linkedOrgResponse?.data?.invitations;
  const totalInvites =
    (linkedOrgResponse?.data?.count?.joined ?? 0) +
    (linkedOrgResponse?.data?.count?.pending ?? 0);

  if (!isLoading) {
    inviteCountRef.current = {
      total: totalInvites,
      joined: linkedOrgResponse?.data?.count?.joined ?? 0,
      pending: linkedOrgResponse?.data?.count?.pending ?? 0,
    };
  }

  const hasInvites = inviteCountRef.current.total > 0;

  const handleTabChange = (value: string) => {
    setSearchParams(
      createSearchParams({
        ...Object.fromEntries(searchParams),
        status: value,
        page: '1',
      }),
    );
  };

  const openPaywallPopupModal = useModal<unknown>(PaywallPopupModal);

  const handleInviteClick = useInvitePortfolioCompany({
    onSuccess: () => refetch(),
  });

  const onInviteClick = async () => {
    const hasPermission =
      organization.data?.tier === Organization.tier.PAID ||
      organization.data?.tier === Organization.tier.FREE_TRIAL;
    if (!hasPermission) {
      await openPaywallPopupModal();
    } else {
      await handleInviteClick();
    }
  };

  const inviteUser = useMutation(
    (data: InvitationRequest) =>
      apiClient.invitation.inviteUser({
        requestBody: {
          inviteEmail: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          metadata: {
            type: InvitationsMetadataDto.type.LINKED_ORG,
          },
        },
      }),
    {
      onSuccess: async () => {
        showToast({
          title: 'Invite Resent!',
          description:
            'Your portfolio company should receive an invite shortly.',
        });
      },
      onError: async () => {
        showToast({
          title: 'Oops!',
          description: 'Your invite failed to resend. Please try again.',
        });
      },
    },
  );

  const revokeInvite = useMutation(
    (id: string) =>
      apiClient.invitation.deleteLinkedOrganization({
        id,
      }),
    {
      onSuccess: async () => {
        refetch();
        showToast({
          title: 'Success!',
          description: 'This invite has been revoked.',
        });
      },
      onError: async () => {
        showToast({
          title: 'Oops!',
          description: 'Your invite failed to be deleted. Please try again.',
        });
      },
    },
  );

  const onResendClick = (value: LinkedOrganization) => {
    inviteUser.mutate({
      email: value.email,
      firstName: value.name.split('')[0] as string,
      lastName: value.name.split('')[1] as string,
    });
  };

  const onRemoveClick = (value: LinkedOrganization) => {
    revokeInvite.mutate(value.id);
  };

  return (
    <Box
      css={{
        p: 32,
        height: 'unset',
        mediaLg: {
          minHeight: linkedOrgs?.length ? '100vh' : 'calc(100vh + 70px)',
        },
        position: 'relative',
        overflow: 'auto',
      }}
    >
      <Flex
        style={{ marginBottom: hasInvites ? '48px' : '128px' }}
        css={{ justifyContent: 'space-between' }}
      >
        <Text as='h1' variant='heading-1' css={{ fontWeight: '$medium' }}>
          Portfolio Companies
        </Text>
        {hasInvites && (
          <Button variant='secondary' onClick={onInviteClick}>
            <Icon name='plus' size={16} css={{ mr: 8 }} color='$primary-base' />
            Invite
          </Button>
        )}
      </Flex>

      {hasInvites && (
        <Tabs
          defaultValue={searchParams.get('status') ?? 'All'}
          onValueChange={handleTabChange}
        >
          <TabsList>
            <Tab value='All'>
              All
              <Badge
                shape='oval'
                css={{
                  backgroundColor: '$neutral-blue-100',
                  color: '$neutral-blue-500',
                }}
              >
                {inviteCountRef.current.total}
              </Badge>
            </Tab>
            <Tab value='joined'>
              Joined
              <Badge shape='oval' variant='success'>
                {inviteCountRef.current.joined}
              </Badge>
            </Tab>
            <Tab value='pending'>
              Invited
              <Badge
                shape='oval'
                css={{ backgroundColor: '$pink-300', color: '$pink-800' }}
              >
                {inviteCountRef.current.pending}
              </Badge>
            </Tab>
          </TabsList>
        </Tabs>
      )}
      <LoadingArea isLoading={isLoading}>
        {hasInvites ? (
          <EmptyState
            css={{ py: 30 }}
            isEmpty={!!status && inviteCountRef.current[status] < 1}
            fallback={
              <>
                <Box
                  as='img'
                  src={addCandidatesImage}
                  css={{ maxWidth: 250 }}
                />
                <Text
                  css={{
                    fontWeight: '$medium',
                    color: '$neutral-blue-1000',
                    fontSize: '$xl',
                    mb: 24,
                  }}
                >
                  No Portfolio Companies Joined Yet
                </Text>
              </>
            }
          >
            <InteractiveTable
              tableData={linkedOrgs ?? []}
              columns={portfolioColumns(onResendClick, onRemoveClick).columns}
              count={linkedOrgResponse?.meta?.count ?? 0}
              loading={isLoading}
            />
          </EmptyState>
        ) : (
          <>
            <Text
              as='h3'
              css={{
                color: '#1F1F1F',
                fontSize: '$3xl',
                fontWeight: '$medium',
                textAlign: 'center',
                marginBottom: '$56',
              }}
            >
              Invite your first portfolio company
            </Text>
            <Flex
              css={{ gap: '80px', width: '100%', flexWrap: 'wrap' }}
              centered
            >
              <Flex
                vertical
                css={{
                  alignItems: 'center',
                  maxWidth: '250px',
                  width: '100%',
                }}
              >
                <AlertIcon
                  size='xl'
                  variant='info'
                  name='plus'
                  css={{ marginBottom: '$32' }}
                />
                <Text
                  css={{
                    color: '$neutral-blue-1100',
                    fontWeight: '$medium',
                    fontSize: '$lg',
                    textAlign: 'center',
                    marginBottom: '$8',
                  }}
                >
                  Invite portfolio companies
                </Text>
                <Text
                  css={{
                    textAlign: 'center',
                    color: '$neutral-blue-700',
                    fontSize: '$sm',
                  }}
                >
                  Invite your portfolio companies and quickly identify their
                  hiring needs and areas for improvement.
                </Text>
              </Flex>
              <Flex
                vertical
                css={{
                  alignItems: 'center',
                  maxWidth: '250px',
                  width: '100%',
                }}
              >
                <AlertIcon
                  size='xl'
                  variant='info'
                  name='user-add'
                  css={{ marginBottom: '$32' }}
                />
                <Text
                  css={{
                    color: '$neutral-blue-1100',
                    fontWeight: '$medium',
                    fontSize: '$lg',
                    textAlign: 'center',
                    marginBottom: '$8',
                  }}
                >
                  Build your talent pool
                </Text>
                <Text
                  css={{
                    textAlign: 'center',
                    color: '$neutral-blue-700',
                    fontSize: '$sm',
                  }}
                >
                  Grow your VC firm’s talent pool and connect candidates with
                  portfolio companies.
                </Text>
              </Flex>
              <Flex
                vertical
                css={{
                  alignItems: 'center',
                  maxWidth: '250px',
                  width: '100%',
                }}
              >
                <AlertIcon
                  size='xl'
                  variant='info'
                  name='presentation-chart-line'
                  css={{ marginBottom: '$32' }}
                />
                <Text
                  css={{
                    color: '$neutral-blue-1100',
                    fontWeight: '$medium',
                    fontSize: '$lg',
                    textAlign: 'center',
                    marginBottom: '$8',
                  }}
                >
                  Measure success
                </Text>
                <Text
                  css={{
                    textAlign: 'center',
                    color: '$neutral-blue-700',
                    fontSize: '$sm',
                  }}
                >
                  Easily track how many candidates you submitted and how many
                  were hired by portfolio companies.
                </Text>
              </Flex>
            </Flex>
            <Flex centered css={{ marginTop: '$56', mb: 136 }}>
              <Button size='lg' onClick={onInviteClick}>
                Invite Portfolio Company
              </Button>
            </Flex>
          </>
        )}
      </LoadingArea>
    </Box>
  );
};

export default Portfolio;
