import {
  Box,
  CircleSkeleton,
  Collapsible,
  CollapsibleContent,
  CollapsibleProps,
  CollapsibleTrigger,
  Divider,
  Flex,
  SquareSkeleton,
  Text,
  TextSkeleton,
} from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { useQuery } from 'react-query';
import { groupBy } from 'lodash';
import { Suspense, useMemo } from 'react';
import { format, startOfDay } from 'date-fns';
import { ContactActivity } from '@betterleap/shared';
import { useNavigate } from 'react-router-dom';
import { CollapisbleHeader } from './ContactProjects';

export const ActivityListLoader = () => {
  return (
    <Box css={{ pt: 16 }}>
      {new Array(2).fill(undefined).map(() => (
        <Flex css={{ px: 24, py: 4, columnGap: 10 }}>
          <CircleSkeleton css={{ width: 15, height: 15 }} />
          <TextSkeleton size='xs' css={{ width: 30, mr: 16 }} />
          <SquareSkeleton size='xs' />
          <TextSkeleton size='xs' css={{ width: 150 }} />
        </Flex>
      ))}
    </Box>
  );
};

export const ActivityList = ({ contactId }: { contactId?: string }) => {
  const navigate = useNavigate();

  const { data: activityResult } = useQuery(
    ['contact_activity', contactId],
    () =>
      apiClient.contactActivity.get({
        contactId,
      }),
    {
      enabled: !!contactId,
      suspense: true,
    },
  );

  const today = format(startOfDay(new Date()), 'MMM d, yyyy');

  const activitiesByDay = useMemo(
    () =>
      groupBy(activityResult?.data ?? [], (activity) =>
        format(startOfDay(new Date(activity.createdAt)), 'MMM d, yyyy'),
      ),
    [activityResult],
  );

  if ((activityResult && !activityResult.data.length) || !contactId) {
    return (
      <Flex justify='center' css={{ py: 70 }}>
        <Text size='sm' css={{ color: '$text-tertiary' }}>
          No contact activity yet.
        </Text>
      </Flex>
    );
  }

  return (
    <Box css={{ p: 24, pt: 40 }}>
      {Object.keys(activitiesByDay).map((day) => {
        const isToday = today === day;
        return (
          <>
            {activitiesByDay[day]?.map((activity, index) => (
              <ContactActivity
                css={{
                  borderLeft: '1px solid $neutral-blue-300',
                  position: 'relative',
                  '&[data-last-of-day=true]': {
                    before: {
                      content: day,
                      display: 'block',
                      position: 'absolute',
                      color: '$neutral-blue-700',
                      backgroundColor: '$neutral-blue-200',
                      fontSize: '$xs',
                      borderRadius: '$base',
                      px: 8,
                      py: 2,
                      left: -4,
                      top: -40,
                    },
                    '&[data-today=true]': {
                      before: {
                        content: 'Today',
                        display: 'block',
                        position: 'absolute',
                        backgroundColor: '$blue-100',
                        color: '$primary-base',
                        fontWeight: '$medium',
                        fontSize: '$xs',
                        borderRadius: '$base',
                        px: 8,
                        py: 2,
                        left: -4,
                        top: -40,
                      },
                    },
                  },
                }}
                key={activity.id}
                isToday={isToday}
                compact
                lastOfDay={index === 0}
                firstOfDay={index === activitiesByDay[day]!.length - 1}
                onLinkClick={(path) => {
                  navigate(`/${path}`);
                }}
                activity={activity}
              />
            ))}
          </>
        );
      })}
    </Box>
  );
};

export interface ContactActivitySectionProps extends CollapsibleProps {
  contactId?: string;
}

export const ContactActivitySection = ({
  contactId,
}: ContactActivitySectionProps) => {
  return (
    <Collapsible defaultOpen={true}>
      <CollapsibleTrigger asChild>
        <CollapisbleHeader title='Activity' icon='presentation-chart-bar' />
      </CollapsibleTrigger>
      <CollapsibleContent css={{ height: 225, overflow: 'auto' }}>
        <Suspense fallback={<ActivityListLoader />}>
          <ActivityList contactId={contactId} />
        </Suspense>
      </CollapsibleContent>
      <Divider />
    </Collapsible>
  );
};
