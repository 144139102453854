import {
  differenceInMonths,
  differenceInYears,
  format,
  parseISO,
} from 'date-fns';

type DateRange = { startDate?: string; endDate?: string };

export function formatDateRange({ startDate, endDate }: DateRange) {
  // NOTE: since by default client side dates will always render in local time we want to show the date based on UTC TZ
  const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const start = startDate ? formatDate(startDate, tzOffset) : '';
  const end = endDate ? formatDate(endDate, tzOffset) : 'Present';
  return startDate ? `${start} - ${end}` : '';
}

function formatDate(date: string, tzOffset: number) {
  return format(new Date(date).getTime() + tzOffset, 'MMM yyyy');
}

export function sortDateDescending(d1: Date, d2: Date) {
  return new Date(d2).getTime() - new Date(d1).getTime();
}

export function getTenure({ startDate, endDate }: DateRange) {
  if (!startDate) {
    return null;
  }

  const start = parseISO(startDate);
  const end = endDate ? parseISO(endDate) : new Date();

  const years = differenceInYears(end, start);
  const months = differenceInMonths(end, start) % 12;

  return {
    years,
    months,
  };
}

export function formatTenure({ startDate, endDate }: DateRange) {
  const tenure = getTenure({ startDate, endDate });

  if (!tenure) return null;

  if (tenure.years < 1) {
    return 'Less than a year';
  }

  let formatted = `${tenure.years} yrs`;

  if (tenure.months > 0) {
    formatted += ` ${tenure.months} mos`;
  }

  return formatted;
}
