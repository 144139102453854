import {
  Box,
  Flex,
  Icon,
  IconButton,
  Modal,
  PromiseModal,
  showToast,
  Text,
} from '@betterleap/ui';
import { useMutation, useQuery } from 'react-query';
import { NoteManager, NoteManagerStyle } from '@betterleap/shared';
import { apiClient } from 'lib/apiClient';

interface CreateNoteModalProps {
  contactProjectId: string;
  userId: string;
}

const CreateNoteModal: PromiseModal<CreateNoteModalProps> = ({
  onDismiss,
  contactProjectId,
  userId,
}) => {
  const {
    data: contactProjectNotes,
    isLoading: isContactProjectNotesLoading,
    refetch: refetchContactProjectNotes,
  } = useQuery([contactProjectId], () =>
    apiClient.contactProjectNote.getContactProjectNotes({
      contactProjectId: contactProjectId as string,
    }),
  );

  const notes = contactProjectNotes?.data ?? [];
  const hasNotes = notes.length > 0;

  const createContactProjectNote = useMutation(
    (data: { note: string; contactProjectId: string }) =>
      apiClient.contactProjectNote.create({ requestBody: data }),
    {
      onSuccess: () => {
        refetchContactProjectNotes();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to add comment. Please try again.',
        });
      },
    },
  );
  const handleAddNote = async (note: string) => {
    await createContactProjectNote.mutate({
      note,
      contactProjectId,
    });
  };
  const handleClose = () => {
    onDismiss();
  };

  return (
    <Modal
      size='large'
      css={{
        padding: 24,
      }}
    >
      <IconButton
        size='sm'
        label='close'
        name='x'
        style={{
          position: 'absolute',
          right: '8px',
          top: '8px',
          background: 'none',
        }}
        onClick={handleClose}
      />
      <Flex vertical css={{ alignItems: 'center' }}>
        <Box
          css={{
            backgroundColor: '#F5F3FF',
            padding: 8,
            borderRadius: 10,
            mb: 16,
          }}
        >
          <Icon name='document-add' color='$violet-600' size={18} />
        </Box>
        <Text as='h3' css={{ fontWeight: '$medium', fontSize: '$lg', mb: 8 }}>
          Comments
        </Text>
        <Text
          as='p'
          css={{
            color: '$neutral-blue-500',
            fontSize: '$sm',
            mb: 24,
          }}
        >
          When sharing this project, users will be able to view this comment
        </Text>
        <Box
          css={{
            height: hasNotes ? '50vh' : '20vh',
            width: '606px',
          }}
        >
          <NoteManager
            myUserId={userId}
            style={NoteManagerStyle.CONTACT_PROJECT}
            noteTerm='comment'
            showNotes={hasNotes}
            isNotesLoading={isContactProjectNotesLoading}
            notes={notes}
            handleAddNote={handleAddNote}
          />
        </Box>
      </Flex>
    </Modal>
  );
};
export default CreateNoteModal;
