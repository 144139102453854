import { SequenceSchedule } from '@betterleap/client';
import {
  BoundCheckboxGroup,
  BoundCheckboxGroupProps,
  Box,
  BoxProps,
  Checkbox,
  CheckboxGroup,
  CheckboxGroupProps,
  CheckboxProps,
  Flex,
  Label,
} from '@betterleap/ui';

const DayOfTheWeekCheckbox = ({ css, ...props }: CheckboxProps) => (
  <Checkbox
    css={{
      checked: {
        backgroundColor: '$blue-50',
        border: '1px solid $blue-100',
        color: '$blue-600',
      },
      disabled: {
        cursor: 'not-allowed',
      },
      backgroundColor: '$neutral-blue-100',
      border: '1px solid $neutral-blue-200',
      borderRadius: '$lg',
      px: 8,
      py: 6,
      fontWeight: '$normal',
      ...css,
    }}
    {...props}
  />
);

export interface ReadOnlyScheduleSelectorProps extends BoxProps {
  label?: string;
  sequenceSchedule: SequenceSchedule;
}

export const ReadOnlyScheduleSelector = ({
  label,
  css,
  ...rest
}: CheckboxGroupProps) => (
  <Box css={css}>
    <Label css={{ mb: 8 }}>{label}</Label>
    <Flex css={{ gap: 16 }} wrap>
      <CheckboxGroup disabled {...rest}>
        <Flex css={{ gap: 16 }} wrap>
          <DayOfTheWeekCheckbox value='monday'>Monday</DayOfTheWeekCheckbox>
          <DayOfTheWeekCheckbox value='tuesday'>Tuesday</DayOfTheWeekCheckbox>
          <DayOfTheWeekCheckbox value='wednesday'>
            Wednesday
          </DayOfTheWeekCheckbox>
          <DayOfTheWeekCheckbox value='thursday'>Thursday</DayOfTheWeekCheckbox>
          <DayOfTheWeekCheckbox value='friday'>Friday</DayOfTheWeekCheckbox>
          <DayOfTheWeekCheckbox value='saturday'>Saturday</DayOfTheWeekCheckbox>
          <DayOfTheWeekCheckbox value='sunday'>Sunday</DayOfTheWeekCheckbox>
        </Flex>
      </CheckboxGroup>
    </Flex>
  </Box>
);

export const SequenceScheduleSelector = ({
  css,
  label,
  ...rest
}: BoundCheckboxGroupProps) => (
  <Box css={css}>
    <Label css={{ mb: 8 }}>{label}</Label>
    <BoundCheckboxGroup {...rest}>
      <Flex css={{ gap: 16 }} wrap>
        <DayOfTheWeekCheckbox value='monday'>Monday</DayOfTheWeekCheckbox>
        <DayOfTheWeekCheckbox value='tuesday'>Tuesday</DayOfTheWeekCheckbox>
        <DayOfTheWeekCheckbox value='wednesday'>Wednesday</DayOfTheWeekCheckbox>
        <DayOfTheWeekCheckbox value='thursday'>Thursday</DayOfTheWeekCheckbox>
        <DayOfTheWeekCheckbox value='friday'>Friday</DayOfTheWeekCheckbox>
        <DayOfTheWeekCheckbox value='saturday'>Saturday</DayOfTheWeekCheckbox>
        <DayOfTheWeekCheckbox value='sunday'>Sunday</DayOfTheWeekCheckbox>
      </Flex>
    </BoundCheckboxGroup>
  </Box>
);
