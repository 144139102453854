/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Types } from '@betterleap/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiEndpoint } from '../constants/endpoints';
import api from '../lib/api';
import { apiActions } from '../store/api/actions';
import { selectApiState } from '../store/api/selectors';

const useFetch: Types.UseFetch = <T>(endpoint: Types.Endpoint) => {
  const dispatch = useDispatch();
  const apiState = useSelector(selectApiState);
  const navigate = useNavigate();
  const location = useLocation();
  const doFetch = (
    data: Types.EndpointPayload = {},
    meta: Types.IApiMeta = {},
  ) => dispatch(apiActions.fetch(endpoint, data, meta, { navigate, location }));
  const response = apiState[
    _.camelCase(endpoint)
  ] as unknown as Types.ApiReducer<T>;
  const doRemove = () => dispatch(apiActions.remove(endpoint));

  return [response, doFetch, doRemove];
};

export const useStorelessFetch = <T>(
  endpoint: ApiEndpoint,
): [
  {
    loading: boolean;
    data: T | null;
    error: Types.ApiError | null;
    loaded: boolean;
  },
  (data?: Types.EndpointPayload | undefined) => void,
] => {
  const [fetch, setFetch] = useState({
    loading: false,
    data: null,
    error: null,
    loaded: false,
  });

  const doFetch = (data?: Types.EndpointPayload) => {
    setFetch({ ...fetch, loading: true });
    api
      .fetch(endpoint, data)
      .then((res) => {
        setFetch({
          ...fetch,
          loading: false,
          loaded: true,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data: res.data.data,
        });
      })
      .catch((e) => {
        setFetch({
          ...fetch,
          loading: false,
          loaded: false,
          error: e.response,
        });
      });
  };

  return [fetch, doFetch];
};

export default useFetch;
