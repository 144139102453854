import React from 'react';

interface ModalDescriptionProps {
  description: string | JSX.Element;
  className?: string;
}

const ModalDescription = ({
  description,
  className,
}: ModalDescriptionProps) => (
  <p
    className={`text-sm leading-5 font-normal text-gray-500 whitespace-pre-line text-center ${className}`}
  >
    {description}
  </p>
);

export default ModalDescription;
