import { getFullName, Types } from '@betterleap/shared';
import { ContactPreview } from '@betterleap/client';
import {
  Badge,
  Box,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useModal,
} from '@betterleap/ui';
import { useRef } from 'react';
import { useSetAtom } from 'jotai';
import { resultIdAtom } from 'components/modules/Drawer/ContactDrawer/ContactDrawer';
import useAnalytics from 'hooks/analytics';
import {
  SubmitFeedbackModal,
  SubmitFeedbackModalProps,
} from 'components/modules/Modal/SourcingFeedbackModal/SourcingFeedbackModal';

interface SourcingResultNameProps {
  contact: ContactPreview & { contactId?: string };
  isViewed: boolean;
  enableFeedback?: boolean;
}

export const SourcingResultName = ({
  contact,
  isViewed,
  enableFeedback,
}: SourcingResultNameProps) => {
  const { track } = useAnalytics();
  const setResultId = useSetAtom(resultIdAtom);
  const resultElement = useRef<HTMLDivElement>(null);
  const openFeedbackModal =
    useModal<SubmitFeedbackModalProps>(SubmitFeedbackModal);

  const handleSubmitFeedback = async () => {
    openFeedbackModal({
      personId: contact.id,
      personName: getFullName(contact),
    });
  };

  return (
    <Flex
      ref={resultElement}
      css={{
        gap: '10px',
      }}
    >
      <Text
        onClick={() => {
          track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
            buttonName: 'Open Candidate Drawer',
            buttonLocation: `Sourcing Page`,
          });
          setResultId(contact.contactId ?? contact.id);
        }}
        role='button'
        css={{
          fontSize: '$lg',
          fontWeight: '$medium',
          color: '$blue-600',
          hover: {
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        }}
      >
        {getFullName(contact, '--')}
      </Text>
      {isViewed && (
        <Badge size='sm'>
          <Icon
            css={{
              mr: '5px',
            }}
            name='eye'
            size={12}
            color='$blue-600'
          />
          Viewed
        </Badge>
      )}
      {enableFeedback && (
        <Box css={{ position: 'absolute', right: 56 }}>
          <Tooltip content='Not a good fit?'>
            <IconButton
              onClick={handleSubmitFeedback}
              color='$text-tertiary'
              size='sm'
              label='leave feedback on why this result is not a good fit'
              name='thumb-down'
            />
          </Tooltip>
        </Box>
      )}
    </Flex>
  );
};
