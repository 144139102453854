import { TOKEN } from '@betterleap/shared';
import { UpdateUserDevicesDto, User } from '@betterleap/client';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import Rupt from 'rupt';
import { apiClient } from '../lib/apiClient';

export const useRupt = () => {
  const updateUserDevices = useMutation(
    (data: { requestBody: UpdateUserDevicesDto }) =>
      apiClient.user.updateMyDevices(data),
  );

  const initRupt = useCallback(async (user: User) => {
    const isSignInAsAnotherUser = TOKEN.isSessionStorage();
    if (!process.env.REACT_APP_RUPT_CLIENT_ID || isSignInAsAnotherUser) return;

    const response = await Rupt.attach({
      client_id: process.env.REACT_APP_RUPT_CLIENT_ID,
      account: user.id,
      email: user?.email,
      redirect_urls: {
        logout_url: 'https://betterleap.com/logout', //currently not set to be used in dashboard
        new_account_url: 'https://betterleap.com/acc', //currently not set to be used in dashboard
      },
    });
    if (response?.device_id && user) {
      const isExistingDevice = !!user.ruptDevices.find(
        (device) => device === response.device_id,
      );

      if (!isExistingDevice) {
        updateUserDevices.mutate({
          requestBody: {
            ruptDevices: [...user.ruptDevices, response.device_id],
          },
        });
      }
    }
  }, []);

  const checkDevices = (user?: User) => {
    if (user) {
      initRupt(user);
    }
  };

  return { checkDevices };
};
