import React, { createContext, ReactNode, useEffect, useState } from 'react';

interface SidebarContextArgs {
  setCollapsed: () => void;
  collapsed?: boolean;
}
export const SidebarContext = createContext<SidebarContextArgs>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCollapsed: () => {},
  collapsed: true,
});

interface SidebarProps {
  children: ReactNode;
}
export const SidebarProvider = ({ children }: SidebarProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const setCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (window.innerWidth < 1024) setIsCollapsed(true);
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        setCollapsed,
        collapsed: isCollapsed,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
