import { Types } from '@betterleap/shared';
import { Organization, User } from '@betterleap/client';
import { createSelector } from 'reselect';
import { AppState } from '..';

export const selectApiState = (state: AppState) => state.api;

export const selectMe = (state: AppState) =>
  state.api.me as Types.ApiReducer<User>;

export const selectCompany = (state: AppState) =>
  state.api.company as Types.ApiReducer<Types.Company>;

export const selectOrganization = (state: AppState) =>
  state.api.organization as Types.ApiReducer<Organization>;

export const selectFile = (state: AppState) =>
  state.api.fileUpload as Types.ApiReducer<File>;

export const selectFeatureConfig = (state: AppState) =>
  (state.api.featureConfig.data as Types.FeatureConfig[]) || [];

export const selectFeature = (feature: Types.Feature) =>
  createSelector(selectFeatureConfig, (config: Types.FeatureConfig[]) =>
    config.find((el) => el.feature === feature),
  );

export const selectPublicToken = (state: AppState) => state.api.publicToken;

export const selectSyncStatus = (state: AppState) => state.api.getSyncStatus;

export const selectRecruiter = (state: AppState) =>
  state.api.recruiter as Types.ApiReducer<Types.Recruiter>;

export const selectTestUser = createSelector(
  selectOrganization,
  (organization) => {
    if (organization.data) return !!organization?.data?.test;
    return false;
  },
);
