import { showToast } from '@betterleap/ui';
import { useMutation, useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { Contact, ContactEmailDto } from '@betterleap/client';
import { getApiClient } from '../../../lib';
import ContactEmailManagerTemplate from './ContactEmailManager.template';

export interface ContactEmailManagerProps {
  contactId: string;
  emailType?: ContactEmailDto.type;
  onContactUpdated: (contact?: Contact) => void;
  onEmailCopied?: () => void;
  contactPrimaryEmail: string | undefined;
  contactLinkedinUrl: string | undefined;
  pollingInterval?: number;
}

const ContactEmailManager = (props: ContactEmailManagerProps) => {
  const {
    contactId,
    contactPrimaryEmail,
    onContactUpdated,
    emailType,
    onEmailCopied,
    contactLinkedinUrl,
    pollingInterval,
  } = props;
  const [contactDefaultEmail, setContactDefaultEmail] = useState<
    string | undefined
  >(contactPrimaryEmail);
  const apiClient = getApiClient();

  const {
    data: contactEmailsResponse,
    isLoading,
    refetch: refetchContactEmails,
  } = useQuery(
    ['get_contact_emails', contactId, emailType],
    () =>
      apiClient.contactEmail.getContactEmails({
        contactId: contactId as string,
        type: emailType,
      }),
    {
      onSuccess: (result) => {
        const defaultEmail = result?.data?.find(
          (ce) => ce.email === contactDefaultEmail,
        );

        if (!defaultEmail) {
          setContactDefaultEmail(undefined);
        }
      },
      refetchInterval: (data) => {
        const hasVerifyingEmail = data?.data?.some((ce) => !ce.validity);

        if (!data?.data.length || hasVerifyingEmail) {
          return pollingInterval ?? false;
        }
        return false;
      },
    },
  );

  useEffect(() => {
    setContactDefaultEmail(contactPrimaryEmail);
  }, [contactPrimaryEmail]);

  const contactEmails = contactEmailsResponse?.data ?? [];
  const createContactEmail = useMutation(
    (data: { email: string; contactId: string }) =>
      apiClient.contactEmail.create({
        requestBody: {
          ...data,
          type:
            emailType === ContactEmailDto.type.WORK
              ? ContactEmailDto.type.WORK
              : ContactEmailDto.type.PERSONAL,
        },
      }),
    {
      onSuccess: () => {
        refetchContactEmails();
        onContactUpdated();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description:
            'Failed to add email. This may be caused by a duplicate email.',
        });
      },
    },
  );

  const handleAddEmail = async (email: string) => {
    await createContactEmail.mutateAsync({
      email,
      contactId,
    });

    if (!contactEmails.length) {
      // if we are creating the fist email, set it as the default
      await handleSetDefaultEmail(email);
    }
  };

  const removeContactEmail = useMutation(
    (data: { id: string }) => apiClient.contactEmail.deleteContactEmail(data),
    {
      onSuccess: () => {
        refetchContactEmails();
        onContactUpdated();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description:
            'Failed to remove email. This may be caused by a duplicate email.',
        });
      },
    },
  );

  const handleRemoveEmail = async (contactEmail: ContactEmailDto) => {
    await removeContactEmail.mutateAsync({ id: contactEmail.id });

    if (contactEmail.email === contactDefaultEmail) {
      const otherContactEmails = contactEmails.filter(
        (ce) => ce.id !== contactEmail.id,
      );

      const newPrimaryEmail = otherContactEmails[0];

      await updateContactPrimaryEmail.mutate({
        contactId,
        ...(contactEmail?.type === ContactEmailDto.type.PERSONAL
          ? { primaryEmail: newPrimaryEmail?.email ?? null }
          : { primaryBusinessEmail: newPrimaryEmail?.email ?? null }),
      });
    }
  };

  const updateContactPrimaryEmail = useMutation(
    (data: {
      contactId: string;
      primaryEmail?: string | null;
      primaryBusinessEmail?: string | null;
    }) =>
      apiClient.contact.updateContact({
        contactId: data.contactId,
        requestBody: {
          primaryEmail: data.primaryEmail,
          primaryBusinessEmail: data.primaryBusinessEmail,
          linkedinUrl: contactLinkedinUrl,
        },
      }),
    {
      onSuccess: (result) => {
        onContactUpdated(result.data);
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to update default email. Please try again.',
        });
      },
    },
  );

  const handleSetDefaultEmail = async (primaryEmail: string) => {
    setContactDefaultEmail(primaryEmail);
    await updateContactPrimaryEmail.mutateAsync({
      ...(emailType === ContactEmailDto.type.PERSONAL
        ? { primaryEmail }
        : { primaryBusinessEmail: primaryEmail }),
      contactId,
    });
  };

  return (
    <ContactEmailManagerTemplate
      contactPrimaryEmail={contactDefaultEmail}
      contactLinkedinUrl={contactLinkedinUrl}
      isLoading={
        isLoading ||
        createContactEmail.isLoading ||
        removeContactEmail.isLoading
      }
      contactEmails={contactEmails}
      handleAddEmail={handleAddEmail}
      handleRemoveEmail={handleRemoveEmail}
      handleSetDefaultEmail={handleSetDefaultEmail}
      onEmailCopied={onEmailCopied}
    />
  );
};

export default ContactEmailManager;
