import { Col, Row } from '@betterleap/ui';
import { useRef } from 'react';
import { BaseStepEditorProps } from '../BaseStepEditor';
import { StepEditorValue } from '../StepEditorTypes';
import { DelaySelector } from './DelaySelector';
import {
  DelayStrategySelector,
  DelayStrategySelectorProps,
} from './DelayStrategySelector';
import { FirstStepScheduleTimeManager } from './FirstStepScheduleTimeManager';

export interface ScheduleTimeManagerProps {
  stepNumber: number;
  value: StepEditorValue | undefined;
  readOnly?: boolean;
  onBlur: BaseStepEditorProps['onBlur'];
  onChange: (value: StepEditorValue) => void;
  prefix?: string;
  canEditDelay?: boolean;
  canEditSendStrategy?: boolean;
  canEditSendAfterTime?: boolean;
  defaultSendStrategy?: 'send-at' | 'send-immediately';
  error?: BaseStepEditorProps['error'];
}

export const ScheduleTimeManager = ({
  stepNumber,
  value,
  readOnly,
  onBlur,
  onChange,
  prefix,
  canEditSendAfterTime,
  canEditDelay,
  canEditSendStrategy,
  defaultSendStrategy,
  error,
}: ScheduleTimeManagerProps) => {
  const isFirstStep = stepNumber <= 1;
  const lastSendAtTimeRef = useRef('8:30 AM');

  const onDelayChange = (newValue: StepEditorValue) => {
    onChange({
      ...value,
      ...newValue,
    });
  };

  const handleSendStrategyChange: DelayStrategySelectorProps['onSendStrategyChange'] =
    (newValue) => {
      onChange({
        ...value,
        sendAfterTime:
          newValue === 'send-at' ? lastSendAtTimeRef.current : undefined,
      });
    };

  if (isFirstStep) {
    return (
      <FirstStepScheduleTimeManager
        stepNumber={stepNumber}
        value={value}
        readOnly={readOnly}
        onBlur={onBlur}
        onChange={onChange}
        canEditDelay={canEditDelay}
        canEditSendStrategy={canEditSendStrategy}
        error={error}
      />
    );
  }

  return (
    <Row>
      <Col
        span={{
          '@md': 12,
          '@lg': 6,
        }}
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: 16,
        }}
      >
        <DelayStrategySelector
          defaultSendStrategy={
            value?.sendAfterTime
              ? 'send-at'
              : defaultSendStrategy ?? 'send-immediately'
          }
          onSendStrategyChange={handleSendStrategyChange}
          canEditSendStrategy={canEditSendStrategy}
          stepNumber={stepNumber}
          readOnly={readOnly}
          canEditDelay={canEditDelay}
          sendAt={value?.sendAfterTime}
          delay={value?.waitTimeCalendarDays}
          businessDays={value?.waitBusinessDaysOnly}
          onBlur={onBlur}
        >
          <DelaySelector
            onBlur={onBlur}
            value={value}
            prefix={prefix}
            canEditSendAfterTime={canEditSendAfterTime}
            onChange={onDelayChange}
            stepNumber={stepNumber}
            error={error}
          />
        </DelayStrategySelector>
      </Col>
    </Row>
  );
};
