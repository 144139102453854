import { Flex, Text, Tooltip } from '@betterleap/ui';
import { LicensedState } from '@betterleap/client';
import {
  SourcingResultSectionContainer,
  SourcingResultSectionContents,
  SourcingResultSectionIcon,
} from './SourcingResult.elements';

interface SectionProps {
  licensedStates: LicensedState[];
}
interface EntryProps {
  licensedState: LicensedState;
  isFirst: boolean;
}
interface StatusConfiguration {
  color: string;
  tooltip: string;
}

// prettier-ignore
const statusMap: Record<LicensedState.status, StatusConfiguration> = {
  [LicensedState.status.ACTIVE]: {
    color: 'green',
    tooltip: 'License verified by the state licensing authority, indicating active status.',
  },
  [LicensedState.status.INACTIVE]: {
    color: 'red',
    tooltip: 'License verified as inactive by the state licensing authority.',
  },
  [LicensedState.status.NOT_VERIFIED]: {
    color: 'orange',
    tooltip: 'Likely licensed, but not confirmed with the state licensing authority.',
  },
};

export const SourcingResultLicensedStates = ({
  licensedStates,
}: SectionProps) => {
  return (
    <SourcingResultSectionContainer>
      <SourcingResultSectionIcon title='Licensed In' icon='shield-check' />
      <Text css={{ fontSize: '$sm', fontWeight: 500 }}>Licensed In:</Text>
      <SourcingResultSectionContents
        elements={licensedStates}
        collapsedDisplayMax={5}
        collapsingType='tooltip'
        displayRemainingElements={displayRemainingElements}
        direction='row'
        constructElement={(licensedState, index) => (
          <LicensedStateEntry
            licensedState={licensedState}
            isFirst={index === 0}
          />
        )}
      />
    </SourcingResultSectionContainer>
  );
};

function displayRemainingElements(states: LicensedState[]) {
  return states.map(mapRemainingElement).join(', ');
}

function mapRemainingElement(state: LicensedState) {
  return `${state.name} (${state.status})`;
}

const LicensedStateEntry = ({ licensedState, isFirst }: EntryProps) => (
  <Flex key={licensedState.name} css={{ gap: 4 }}>
    {!isFirst && (
      <Text css={{ fontSize: '$sm', color: '$neutral-blue-700' }}>•</Text>
    )}
    <Text css={{ fontSize: '$sm', color: '$neutral-blue-700' }}>
      {licensedState.name}
    </Text>
    <Tooltip
      css={{ maxWidth: '280px', lineHeight: '20px' }}
      content={statusMap[licensedState.status].tooltip}
    >
      <Flex
        css={{
          gap: 12,
          backgroundColor: `$${statusMap[licensedState.status].color}-200`,
          paddingLeft: '$4',
          paddingRight: '$4',
          mx: '$4',
          borderRadius: '$base',
        }}
      >
        <Text
          css={{
            fontSize: '$xs',
            color: `$${statusMap[licensedState.status].color}-700`,
          }}
        >
          {licensedState.status}
        </Text>
      </Flex>
    </Tooltip>
  </Flex>
);
