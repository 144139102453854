import { Step } from '@betterleap/client';
import {
  AlertIcon,
  AlertIconProps,
  Box,
  BoxProps,
  Button,
  Flex,
  IconProps,
  Text,
} from '@betterleap/ui';

interface SupportedStepType {
  icon: IconProps['name'];
  type: Step['type'];
  label: string;
  variant: AlertIconProps['variant'];
}

const supportedStepTypes: SupportedStepType[] = [
  {
    icon: 'mail',
    type: Step.type.EMAIL,
    label: 'Email',
    variant: 'info',
  },
  {
    icon: 'in-mail',
    type: Step.type.INMAIL,
    label: 'InMail',
    variant: 'purple',
  },
  {
    icon: 'chat',
    type: Step.type.SMS,
    label: 'Text',
    variant: 'warning',
  },
  {
    icon: 'phone',
    type: Step.type.PHONE_CALL,
    label: 'Call',
    variant: 'success',
  },
  {
    icon: 'x-logo',
    type: Step.type.X_DIRECT_MESSAGE,
    label: 'X DM',
    variant: 'neutral',
  },
];

export interface AddStepButtonsProps extends BoxProps {
  onAddStep: (type: Step.type) => void;
  label?: string;
}

export const AddStepButtons = ({
  css,
  onAddStep,
  label,
  ...props
}: AddStepButtonsProps) => {
  return (
    <Box
      css={{
        pb: 32,
        ...css,
      }}
      {...props}
    >
      {!!label && (
        <Text
          size='sm'
          css={{ fontWeight: '$medium', textAlign: 'center', mb: 24 }}
        >
          {label}
        </Text>
      )}
      <Flex
        justify='center'
        wrap
        css={{
          gap: 24,
        }}
      >
        {supportedStepTypes.map((stepType) => (
          <Button
            variant='headless'
            css={{
              width: 136,
              height: 100,
              display: 'flex',
              backgroundColor: '$background-component',
              flexDirection: 'vertical',
              justifyContent: 'center',
              borderRadius: '$3xl',
              border: '1px solid $neutral-blue-300',
            }}
            onClick={() => onAddStep(stepType.type)}
          >
            <Box>
              <AlertIcon
                css={{ mb: 8 }}
                name={stepType.icon}
                variant={stepType.variant}
                shape='rounded-square'
                size='lg'
              />
              <Text size='sm'>{stepType.label}</Text>
            </Box>
          </Button>
        ))}
      </Flex>
    </Box>
  );
};
