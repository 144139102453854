import { SearchMode } from './SourceCandidates.template';

export enum InclusionType {
  MUST_HAVE = 'must have',
  CAN_HAVE = 'can have',
  CANT_HAVE = 'cant have',
}

export interface SourceCandidatesSearchParams {
  excludeViewed?: boolean;
  page?: number;
  pageSize?: number;
  localities?: string[];
  locations?: string[];
  regions?: string[];
  countries?: string[];
  skillsShould?: string[];
  skills?: string[];
  jobTitlesCurrent?: string[];
  jobTitlesPast?: string[];
  jobTitlesCurrentOrPast?: string[];
  industries?: string[];
  yearsOfExperienceMin?: number;
  yearsOfExperienceMax?: number;
  educationSchools?: string[];
  educationMajors?: string[];
  educationDegrees?: string[];
  companyNamesCurrent?: string[];
  companyNamesPast?: string[];
  companyNamesCurrentOrPast?: string[];
  currentCompanySizes?: string[];
  currentOrPastCompanySizes?: string[];
  gender?: string;
  name?: string;
  searchMode?: SearchMode;
  jobTitlesBoolean?: string;
  skillsBoolean?: string;
  educationSchoolsBoolean?: string;
  educationMajorsBoolean?: string;
  companyNamesBoolean?: string;
  industriesBoolean?: string;
  summaryKeywords?: string[];
  languageNames?: string[];
  summaryKeywordsBoolean?: string;
  hasPersonalEmail?: boolean;
  zipCodes?: string[];
  certifications?: string[];
  licensedStates?: string[];
  boolean?: string;
}

export interface FilterItem {
  field: string;
  name: string;
  excluded?: boolean;
  type?: InclusionType;
}
