import { Box, Button, Flex, Text } from '@betterleap/ui';
import React from 'react';
import chromeLogo from '../../../assets/images/chrome_logo.svg';

const GetTheChromeExtension = () => (
  <Flex
    css={{
      p: 32,
      flexDirection: 'column',
      background: '$neutral-blue-100',
      borderRadius: '$2xl',
      width: '100%',
      mt: 32,
      alignItems: 'flex-start',
      mediaLg: {
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        maxWidth: '100%',
        top: 0,
        mt: 'unset',
        mb: '1rem',
      },
    }}
  >
    <Flex>
      <img src={chromeLogo} alt='chrome_logo' />
      <Box css={{ ml: 24 }}>
        <Text
          css={{
            fontWeight: '$semibold',
            fontSize: '$lg',
            lineHeight: '$2xl',
            color: '$neutral-blue-900',
          }}
        >
          Get the Chrome Extension
        </Text>
        <Text
          css={{
            maxWidth: 443,
            fontSize: '$sm',
            lineHeight: '$2xl',
            color: '$navy',
          }}
        >
          Quickly add candidates to projects and sequences in 1 click with this
          extension, and track them all here in your CRM.
        </Text>
      </Box>
    </Flex>
    <Button
      onClick={() =>
        window.open(
          'https://chrome.google.com/webstore/detail/betterleap-sourcing/bimmbgomanhpkfodmiomjgfakleojpia',
        )
      }
      variant='ghost'
      css={{
        color: '$blue-600',
        borderColor: '$blue-600',
        background: '$neutral-blue-100',
        borderRadius: '$2xl',
        px: 32,
        py: 24,
        alignSelf: 'center',
        mt: 24,
        mediaLg: { mt: 'unset' },
      }}
    >
      Download Extension
    </Button>
  </Flex>
);

export default GetTheChromeExtension;
