/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/namespace
import * as Modal from '../../components/modules/Modal';
import { MODAL, ModalAction } from './actions';

export interface ModalState {
  openedModalKey: null | keyof typeof Modal.modalRegistry;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
}

export const INITIAL_STATE: ModalState = {
  openedModalKey: null,
  props: {},
};

const modalReducer = (
  state = INITIAL_STATE,
  action: ModalAction,
): ModalState => {
  switch (action.type) {
    case MODAL.OPEN:
      return {
        openedModalKey: action.payload.modalKey,
        props: action.payload.props,
      };
    case MODAL.CLOSE:
      return {
        openedModalKey: null,
        props: {},
      };
    default:
      return state;
  }
};

export { modalReducer };
