import { Sender } from '@betterleap/client';
import { PromiseModal, Text } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import React from 'react';
import { useQuery } from 'react-query';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';

export interface ConfirmRemoveSenderModalProps {
  sender: Sender;
}

export const ConfirmRemoveSenderModal: PromiseModal<
  ConfirmRemoveSenderModalProps
> = ({ onSubmit, onDismiss, onReject, sender }) => {
  const { data: response, isLoading } = useQuery(
    ['getActiveSequenceCount', sender.id],
    () => apiClient.sequence.getActiveSequenceCount({ senderId: sender.id }),
  );
  const count = response?.data ?? 0;

  const description = (
    <>
      <Text as='span' inherit>
        Are you sure you want to remove the email{' '}
        <strong>{sender.email}</strong>?{' '}
      </Text>
      {count > 0 && (
        <Text as='span' inherit>
          This email is currently being used in{' '}
          <strong>{count} active sequences</strong>. These sequences{' '}
          <strong>will be paused</strong>. Do you want to continue?
        </Text>
      )}
    </>
  );

  return (
    <ConfirmModal
      size='medium'
      title='Remove Email'
      icon={{ variant: 'danger', name: 'trash' }}
      description={description}
      confirmationText='Remove'
      variant='danger'
      isLoading={isLoading}
      onSubmit={onSubmit}
      onDismiss={onDismiss}
      onReject={onReject}
    />
  );
};
