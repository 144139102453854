import { CandidateImportResult } from '@betterleap/client';
import {
  Alert,
  AlertDescription,
  AlertHeading,
  AlertingIcon,
  Text,
} from '@betterleap/ui';

export const CandidateImportNotices = (
  candidateResponse: CandidateImportResult,
  hasSequence: boolean,
) => (
  <>
    {hasSequence && candidateResponse.contactsIgnored > 0 && (
      <Alert css={{ mt: 16 }}>
        <AlertingIcon css={{ mt: 1 }} />
        <AlertHeading>
          <Text css={{ fontWeight: '$bold' }} inherit inline>
            {candidateResponse.contactsIgnored} contact
            {candidateResponse.contactsIgnored > 1 ? 's' : ''}
          </Text>{' '}
          from this list{' '}
          {candidateResponse.contactsIgnored > 1 ? 'were' : 'was'} not added to
          this sequence.
        </AlertHeading>
      </Alert>
    )}

    {candidateResponse.duplicateContacts.length > 0 && (
      <Alert css={{ mt: 16 }}>
        <AlertingIcon css={{ mt: 1 }} />
        <AlertHeading>
          <Text css={{ fontWeight: '$bold' }} inherit inline>
            {candidateResponse.duplicateContacts.length} contact
            {candidateResponse.duplicateContacts.length > 1 ? 's' : ''}
          </Text>{' '}
          unable to be added because another contact exists with the same email
          or linkedin profile.
        </AlertHeading>
      </Alert>
    )}

    {hasSequence && candidateResponse.contactsAddedToSequence > 400 && (
      <Alert css={{ mt: 16 }}>
        <AlertingIcon css={{ mt: 1 }} />
        <AlertHeading>You’ve sourced a ton of contacts. Awesome!</AlertHeading>
        <AlertDescription>
          Since your list is large, we’re going to send 400 emails a day until
          your entire list has been contacted.
        </AlertDescription>
      </Alert>
    )}
  </>
);
