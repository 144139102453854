import camelCase from 'lodash/camelCase';
import { parseCsv } from 'functions/parseCsv';

const requiredFieldSets = [['linkedinUrl', 'primaryEmail']]; // we require LI Url or Email

// maps a csv column to one of our accepted values
const fieldMap: Record<string, string> = {
  firstname: 'firstName',
  first_name: 'firstName',

  lastname: 'lastName',
  last_name: 'lastName',

  primaryemail: 'primaryEmail',
  emailaddress: 'primaryEmail',
  email: 'primaryEmail',
  email1: 'primaryEmail',

  linkedinurl: 'linkedinUrl',
  profileurl: 'linkedinUrl',
  linkedin: 'linkedinUrl',
  linkedinlink: 'linkedinUrl',
  prospectlinkedinurl: 'linkedinUrl',
  linkedinprofileurl: 'linkedinUrl',
  publiclinkedinurl: 'linkedinUrl',
  contactliprofileurl: 'linkedinUrl',
  linkedincontactprofileurl: 'linkedinUrl',
};

class CsvParser {
  static async parse(file: File) {
    const results = await parseCsv(file);

    // get fields of first row
    const fields = results[0] ? Object.keys(results[0]) : [];

    // check all required fields are present
    const includedRequiredFields = new Set();
    for (let i = 0; i < fields.length; i++) {
      const field = standardizeColumnName(fields[i]);
      const mappedField = fieldMap[field];

      if (mappedField) {
        requiredFieldSets.forEach((fieldSet) => {
          if (fieldSet.includes(mappedField)) {
            includedRequiredFields.add(mappedField);
          }
        });
      }
    }

    const includedRequiredFieldsNum = Array.from(includedRequiredFields).length;

    if (includedRequiredFieldsNum < requiredFieldSets.length) {
      throw new Error('FieldsNotCorrect');
    }

    const data = [];
    for (let i = 0; i < results.length; i++) {
      const rawRow = results[i];

      if (!rawRow) continue;

      let row: Record<string, string | Record<string, string>> = {
        csvUploadData: {},
      };
      const keys = Object.keys(rawRow);

      for (let j = 0; j < keys.length; j++) {
        const key = keys[j] as string;
        if (!rawRow[key]) {
          continue;
        }
        const value = rawRow[key] as string;
        const mappedKey = fieldMap[standardizeColumnName(key)];
        if (mappedKey) {
          row = { ...row, [mappedKey]: value };
        }
        row = {
          ...row,
          csvUploadData: {
            ...(row.csvUploadData as Record<string, string>),
            [key]: value,
          },
        };
      }

      data.push(row);
    }

    return data;
  }
}

function standardizeColumnName(key: string | undefined) {
  // camel removes spaces and some other characters
  return camelCase(key).toLowerCase();
}

export default CsvParser;
