import { Select, SelectItem, SelectProps } from '@betterleap/ui';

export const BusinessDaySelect = ({ css, ref, ...rest }: SelectProps) => (
  <Select
    ref={ref}
    css={{
      backgroundColor: '$background-muted',
      paddingLeft: 16,
      width: 'fit-content',
      whiteSpace: 'nowrap',
      height: '100%',
      marginRight: -8,
      fontSize: '$sm',
      color: '$text-light',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      border: 'none',
      borderLeft: '1px solid $border-light',
      focus: {
        boxShadow: 'none',
        backgroundColor: '$background-dark',
      },
      ...css,
    }}
    {...rest}
  >
    <SelectItem value='false'>Calendar Days</SelectItem>
    <SelectItem value='true'>Business Days</SelectItem>
  </Select>
);
