import { Button, Icon, IconProps, Text } from '@betterleap/ui';
import { useState } from 'react';

interface ShowMoreButtonProps {
  onToggle?: (showMore: boolean) => void;
  showLessText: string;
  showMoreText: string;
}

export const ShowMoreButton = ({
  onToggle,
  showLessText = 'Show less',
  showMoreText = `Show all`,
}: ShowMoreButtonProps) => {
  const [isExpanded, setExpanded] = useState(false);

  const toggleParams: { text: string; icon: IconProps['name'] } = {
    text: isExpanded ? showLessText : showMoreText,
    icon: isExpanded ? 'chevron-up' : 'chevron-down',
  };

  return (
    <Button
      variant='headless'
      onClick={() => {
        setExpanded(!isExpanded);
        onToggle?.(!isExpanded);
      }}
      css={{ mt: 4 }}
    >
      <Text css={{ fontSize: '$sm', color: '$blue-600' }}>
        {toggleParams.text}
      </Text>
      <Icon
        name={toggleParams.icon}
        size={16}
        css={{ fill: '$blue-600', ml: 4 }}
      />
    </Button>
  );
};
