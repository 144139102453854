import { Flex, FlexProps } from '@betterleap/ui';
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useRef,
  useState,
} from 'react';

export interface BannerProps extends FlexProps {
  variant?: 'success' | 'neutral' | 'danger' | 'info' | 'purple';
}

const variantStyles = {
  success: {
    backgroundColor: '$success-100',
    color: '$success-800',
  },
  neutral: {
    backgroundColor: '$background-highlight',
  },
  danger: {
    backgroundColor: '$danger-100',
    color: '$danger-800',
  },
  info: {
    backgroundColor: '$blue-100',
    color: '$blue-600',
  },
  purple: {
    backgroundColor: '$purple-100',
    color: '$purple-600',
  },
};

export const BANNER_MOUNT_ID = 'betterleap-global-banner';

interface BannerContextArgs {
  openBanner: (bannerId: string, bannerElement: ReactElement) => void;
  closeBanner: () => void;
  banner?: ReactElement;
  isVisible?: boolean;
}

const BannerContext = createContext<BannerContextArgs>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openBanner: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeBanner: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  isVisible: false,
});

export const useBanner = () => useContext(BannerContext);

export interface BannerProviderProps {
  children: ReactNode;
}
export const BannerProvider = ({ children }: BannerProviderProps) => {
  const [banner, setBanner] = useState<ReactElement>();
  const bannerComponents = useRef<Record<string, ReactElement>>({});
  const currentBannerRef = useRef<Set<string>>(new Set());
  const dismissedBannerRef = useRef<Set<string>>(new Set());

  const openBanner = (bannerId: string, bannerComponent: ReactElement) => {
    // don't reopen a banner that has been dismissed or has already been.
    if (
      dismissedBannerRef.current?.has(bannerId) ||
      currentBannerRef.current.has(bannerId)
    ) {
      return;
    }

    currentBannerRef.current?.add(bannerId);

    bannerComponents.current[bannerId] = bannerComponent;

    setBanner(bannerComponents.current[bannerId]);
  };

  const closeBanner = () => {
    const lastBanner = Array.from(currentBannerRef.current).pop();

    if (lastBanner) {
      dismissedBannerRef.current.add(lastBanner);
      currentBannerRef.current.delete(lastBanner);
    }

    const newLastBanner = Array.from(currentBannerRef.current).pop();

    if (newLastBanner) {
      setBanner(bannerComponents.current[newLastBanner]);
    } else {
      setBanner(undefined);
    }
  };

  return (
    <BannerContext.Provider
      value={{
        openBanner,
        closeBanner,
        banner,
        isVisible: !!banner,
      }}
    >
      <div id={BANNER_MOUNT_ID} />
      {children}
    </BannerContext.Provider>
  );
};

export const Banner = ({ css, variant = 'neutral', ...rest }: BannerProps) => {
  const styles = variantStyles[variant];

  return (
    <Flex
      justify='center'
      wrap
      css={{
        minHeight: 50,
        py: 12,
        px: 16,
        fontWeight: '$medium',
        boxShadow: '5px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        zIndex: '$10',
        ...css,
        ...styles,
      }}
      {...rest}
    />
  );
};
