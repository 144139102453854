import { Contact } from '@betterleap/client';
import { Divider } from '@betterleap/ui';
import { ContactPreviewSearchResult } from 'components/templates/Sourcing/SourceCandidates.template';
import { useHighlightSearchTerms } from 'components/templates/Sourcing/useHighlightWords';
import { useContactView } from 'hooks/useContactView';
import { apiClient } from 'lib/apiClient';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { ContactActivitySection } from './ContactActivity';
import { sharedProjectIdState, sourcedContactIdState } from './ContactDrawer';
import { ContactDrawerLayout } from './ContactDrawer.layout';
import { ContactDrawerHeader } from './ContactDrawerHeader';
import { ContactNotes } from './ContactNotes';
import { ContactProjects } from './ContactProjects';
import { ContactDetailsSection } from './ContactDetailsSection';
import { EducationSection } from './EducationSection';
import { ExperienceSection } from './ExperienceSection';
import { SkillsSection } from './SkillsSection';
import { SummarySection } from './SummarySection';
import { useContactSummary } from './useContactSummary';
import { CertificationsSection } from './CertificationsSection';

interface ContactProfileProps {
  contact?: Partial<ContactPreviewSearchResult>;
}

const contactPreviewToContact = (
  contact?: Partial<ContactPreviewSearchResult>,
) =>
  ({
    id: contact?.contactId,
    firstName: contact?.firstName,
    lastName: contact?.lastName,
    currentCompany: contact?.currentCompany,
    currentTitle: contact?.currentTitle,
    locations: [contact?.locationName],
    linkedinUrl: contact?.linkedinUrl,
    facebookUrl: contact?.facebookUrl,
    twitterUrl: contact?.twitterUrl,
    githubUrl: contact?.githubUrl,
  } as Partial<Contact>);

export const ContactProfile = ({ contact }: ContactProfileProps) => {
  const { id: projectId } = useParams<{ id: string }>();
  const contactId = contact?.contactId;
  const sourcedContactId = useAtomValue(sourcedContactIdState);
  const sharedProjectId = useAtomValue(sharedProjectIdState);
  const highlightTerms = useHighlightSearchTerms();
  const { viewedContacts, onViewContact } = useContactView();
  const isSharedProject = !!sharedProjectId;

  const { data: getContactResponse, isLoading: isContactLoading } = useQuery(
    ['get_contact', contactId],
    () => apiClient.contact.getContact({ id: contactId as string }),
    {
      enabled: !!contactId,
      refetchInterval: (response) => {
        const data = response?.data?.contact;
        if (sourcedContactId !== data?.id) {
          return false;
        }

        return !data?.primaryEmail && !data?.primaryBusinessEmail
          ? 1000
          : false;
      },
      onSuccess: () => {
        highlightTerms();
      },
    },
  );

  const { data: projectResponse } = useQuery(
    ['get_project', projectId],
    () => apiClient.project.findOneByUser({ projectId: projectId as string }),
    {
      enabled: !!projectId,
    },
  );

  const { data: contactExperience, isLoading: isExperienceLoading } = useQuery(
    ['get_contact_experience', contactId],
    () =>
      apiClient.contactEnrichment.getContactExperience({
        contactId: contactId as string,
      }),
    {
      enabled: !!contactId,
    },
  );

  const { data: contactEducation, isLoading: isEductationLoading } = useQuery(
    ['get_contact_education', contactId],
    () =>
      apiClient.contactEnrichment.getContactEducation({
        contactId: contactId as string,
      }),
    {
      enabled: !!contactId,
    },
  );

  const { data: contactSkills, isLoading: isSkillsLoading } = useQuery(
    ['get_contact_skills', contactId],
    () =>
      apiClient.contactEnrichment.getContactSkills({
        contactId: contactId as string,
      }),
    {
      enabled: !!contactId,
    },
  );

  const { data: contactCertifications, isLoading: isCertificationsLoading } =
    useQuery(
      ['get_contact_certifications', contactId],
      () =>
        apiClient.contactEnrichment.getContactCertifications({
          contactId: contactId as string,
        }),
      {
        enabled: !!contactId,
      },
    );

  const experience = contact?.experience ?? contactExperience?.data ?? [];
  const education = contact?.education ?? contactEducation?.data ?? [];
  const skills = contact?.skills ?? contactSkills?.data ?? [];
  const certifications =
    contact?.certifications ?? contactCertifications?.data ?? [];
  const project = projectResponse?.data;
  const contactObject =
    getContactResponse?.data?.contact ?? contactPreviewToContact(contact);

  const {
    loadingSummary,
    summaryError,
    contactSummaries,
    fetchSummaryForUser,
  } = useContactSummary(contact?.id ? contact : contactObject);

  useEffect(() => {
    if (contact?.id && !viewedContacts?.includes(contact.id)) {
      onViewContact(contact.id);
    }

    highlightTerms();
  }, [contact?.id]);

  return (
    <ContactDrawerLayout>
      <ContactDrawerLayout.Header
        css={{ px: 24, py: 16, borderBottom: '1px solid $neutral-blue-300' }}
      >
        <ContactDrawerHeader />
      </ContactDrawerLayout.Header>
      <ContactDrawerLayout.Title
        css={{ p: 24, borderBottom: '1px solid $neutral-blue-300' }}
      >
        <ContactDetailsSection
          contact={contactObject}
          project={project}
          isContactLoading={isContactLoading}
        />
      </ContactDrawerLayout.Title>
      <ContactDrawerLayout.Main
        css={{ borderRight: '1px solid $neutral-blue-300', pb: 70 }}
      >
        <ExperienceSection
          key={`experience-${contact?.contactId ?? contact?.id}`}
          experience={experience}
          isLoading={isExperienceLoading}
        />
        <Divider />
        {education.length > 0 && (
          <EducationSection
            key={`education-${contact?.contactId ?? contact?.id}`}
            css={{ mb: 32 }}
            education={education}
            isLoading={isEductationLoading}
          />
        )}
        {certifications.length > 0 && (
          <CertificationsSection
            key={`certifications-${contact?.contactId ?? contact?.id}`}
            css={{ mb: 32 }}
            certifications={certifications}
            isLoading={isCertificationsLoading}
          />
        )}
        {skills.length > 0 && (
          <SkillsSection skills={skills} isLoading={isSkillsLoading} />
        )}
      </ContactDrawerLayout.Main>
      <ContactDrawerLayout.Aside>
        {!summaryError && (
          <>
            <SummarySection
              isLoading={loadingSummary}
              summary={contactSummaries.get(contact?.id ?? contactId)}
              fetchSummaryForUser={fetchSummaryForUser}
            />
            <Divider />
          </>
        )}
        <ContactProjects contactId={contactId} />
        {!isSharedProject && <ContactActivitySection contactId={contactId} />}
        <ContactNotes contactId={contactId} />
      </ContactDrawerLayout.Aside>
    </ContactDrawerLayout>
  );
};
