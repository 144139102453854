import { InteractiveTable } from '@betterleap/shared';
import { SequenceStat } from '@betterleap/client';
import { Flex, Text } from '@betterleap/ui';
import { useQuery } from 'react-query';
import { Link, useSearchParams } from 'react-router-dom';
import { SearchInput } from 'components/elements/SearchInput/SearchInput';
import { apiClient } from 'lib/apiClient';

export const OutreachStats = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get('outreachsearch') ?? '';
  const page = parseInt(searchParams?.get('outreachpage') ?? '1', 10);
  const pageSize = parseInt(searchParams?.get('outreachpageSize') ?? '5', 10);
  const { data: sequences, isLoading } = useQuery(
    ['outreach', { search, page, pageSize }],
    () => apiClient.sequence.calculateStats({ search, page, pageSize }),
  );
  const columns = {
    columns: [
      {
        Header: 'SEQUENCE',
        accessor: (s: SequenceStat) => (
          <>
            <Link to={`/sequence/${s.id}`}>
              <Text size='sm' css={{ fontWeight: '$medium' }} variant='link'>
                {s.name || '--'}
              </Text>
            </Link>
            <Text size='xs' css={{ color: '$text-lightest' }}>
              {s.creator}
            </Text>
          </>
        ),
        width: 360,
      },
      {
        Header: 'CONTACTED',
        accessor: (s: SequenceStat) => s.contactedCount ?? '--',
        width: 80,
      },
      {
        Header: 'OPENED',
        accessor: (s: SequenceStat) => s.openedCount ?? '--',
        width: 80,
      },
      {
        Header: 'REPLIED',
        accessor: (s: SequenceStat) => s.repliedCount ?? '--',
        width: 80,
      },
      {
        Header: 'INTERESTED',
        accessor: (s: SequenceStat) => s.interestedCount ?? '--',
        width: 80,
      },
    ],
  };
  if (!(sequences?.data || []).length) {
    return null;
  }
  return (
    <>
      <Flex css={{ mt: 40, justifyContent: 'space-between' }}>
        <Text
          as='h2'
          css={{
            fontWeight: '$medium',
            fontSize: '$lg',
          }}
        >
          Outreach Stats
        </Text>
        <SearchInput
          query={searchParams.get('outreachsearch') ?? ''}
          pageParamNames={['outreachpage']}
          searchParamName='outreachsearch'
        />
      </Flex>
      <InteractiveTable
        columns={columns.columns}
        tableData={sequences?.data || [{}]} // no data = empty row
        loading={isLoading}
        count={sequences?.meta?.count || 0}
        pageSize={pageSize}
        paginationKey='outreach'
      />
    </>
  );
};
