import { getProjectSubject, Types } from '@betterleap/shared';
import {
  ContactActivityType,
  MoveContactsRequest,
  Project,
  ProjectContactStatus,
  ProjectWithStatsDto,
} from '@betterleap/client';
import { showToast, useModal } from '@betterleap/ui';
import { useMutation, useQuery } from 'react-query';
import { logToSegment } from 'functions/segmentLog';
import { useState } from 'react';
import { Row } from 'react-table';
import {
  MoveCandidatesModal,
  MoveCandidatesModalProps,
} from 'components/modules/Modal/MoveCandidatesModal/MoveCandidatesModal';
import { apiClient } from 'lib/apiClient';
import {
  countSelectedContacts,
  selectContactIds,
} from '../../ProjectDetails.functions';

export const useMoveContacts = ({
  currentProject,
  selectedContacts,
  totalCount,
  statuses,
  activityTypes,
  onSuccess,
}: {
  currentProject: ProjectWithStatsDto | undefined;
  selectedContacts: Row<Record<string, unknown>>[] | 'all';
  totalCount: number;
  activityTypes?: ContactActivityType[];
  statuses?: ProjectContactStatus[] | { and: ProjectContactStatus[][] };
  onSuccess: () => void;
}) => {
  const confirm = useModal<MoveCandidatesModalProps>(MoveCandidatesModal);
  const subject = getProjectSubject(
    currentProject?.contactEmailEnrichmentMode ===
      ProjectWithStatsDto.contactEmailEnrichmentMode.PROFESSIONAL,
  );
  const selectedCount = countSelectedContacts(selectedContacts, totalCount);
  const moveContacts = useMutation((request: MoveContactsRequest) =>
    apiClient.project.moveContacts({ requestBody: request }),
  );

  function constructPrompt(project: Project, removeFromSource: boolean) {
    let prompt = 'Are you sure you want to ';
    prompt += removeFromSource ? 'move' : 'copy';
    prompt += ` ${selectedCount} ${subject({ singular: selectedCount === 1 })}`;
    prompt += ` to ${project.name}?`;
    return prompt;
  }

  function constructNote(removeFromSource: boolean) {
    if (!removeFromSource) {
      return undefined;
    }
    return (
      <span>
        If selected {subject()} are currently receiving a sequence, the sequence{' '}
        <strong>will automatically stop</strong> for these {subject()}.
      </span>
    );
  }

  const handleMoveContactsClick = async ({
    selectedProject,
    removeFromSource,
  }: {
    selectedProject: Project | undefined;
    removeFromSource: boolean;
  }) => {
    if (!selectedProject || !currentProject) {
      return null;
    }
    const confirmed = await confirm({
      prompt: constructPrompt(selectedProject, removeFromSource),
      note: constructNote(removeFromSource),
    });
    if (!confirmed) {
      return null;
    }
    const properties = {
      action_taken: Types.BULK_ACTION.MOVE,
      num_candidates_selected: selectedCount,
    };
    logToSegment(Types.SEGMENT_EVENTS.BULK_ACTION_ON_CANDIDATES, properties);
    moveContacts.mutate(
      {
        sourceProjectId: currentProject.id,
        destinationProjectId: selectedProject.id,
        removeFromSource,
        startSequence: false,
        statuses,
        activityTypes,
        contactIds: selectContactIds(selectedContacts),
      },
      {
        onSuccess: () => {
          const added = removeFromSource ? 'moved' : 'copied';
          showToast({
            variant: 'success',
            title: 'Success!',
            description: `${selectedCount} ${subject()} ${added} to ${
              selectedProject.name
            }.`,
          });
          onSuccess();
        },
        onError: () => {
          showToast({
            variant: 'danger',
            title: 'Error',
            description:
              'Something went wrong. Please contact support@betterleap.com for assistance',
          });
        },
      },
    );
    return moveContacts;
  };

  const [projectSearchFilter, setProjectSearchFilter] = useState<string>('');
  const { data: projectsResponse, isLoading: projectsLoading } = useQuery(
    ['get_projects', { projectSearchFilter }],
    () => apiClient.project.findAllByUser({ search: projectSearchFilter }),
  );
  const projects = projectsResponse?.data || [];
  const options = projects.filter((p) => p.id !== currentProject?.id) ?? [];

  const onMoveContacts = async (
    ids: string[] = [],
    removeFromSource: boolean,
  ) => {
    const selectedProject = projects.find((p) => p.id === ids[0]);
    await handleMoveContactsClick({ selectedProject, removeFromSource });
  };

  return { onMoveContacts, setProjectSearchFilter, projectsLoading, options };
};
