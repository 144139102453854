import { Spinner } from '@betterleap/shared';
import { Flex } from '@betterleap/ui';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { apiClient } from 'lib/apiClient';
import RecruiterToolPaywall from 'components/templates/RecruiterToolPaywall/RecruiterToolPaywall';
import withContactDrawer from 'components/HOC/withContactDrawer';
import { selectOrganization } from 'store/api/selectors';
import withSidebar from '../components/HOC/withSidebar';
import Project from '../components/templates/Projects/Project.template';
import { isPaywallTier } from '../helper/paywall';

const OrganizationProject = () => {
  const { id } = useParams<{ id: string }>();
  const organization = useSelector(selectOrganization);

  if (!organization?.data) {
    return (
      <Flex css={{ justifyContent: 'center', flexGrow: 1 }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }

  const { data: sharedProjects } = useQuery(['project-share'], () =>
    apiClient.projectShare.getAllSharedProjectsWithOrganization({ search: '' }),
  );

  const showPaywall =
    isPaywallTier(organization.data.tier) &&
    !sharedProjects?.data?.map((p) => p.projectId).includes(id as string);

  return showPaywall ? (
    <RecruiterToolPaywall tier={organization.data.tier} />
  ) : (
    <Project />
  );
};

export default withContactDrawer(withSidebar(OrganizationProject, true));
