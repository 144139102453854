import { ConnectIntegrationDto, Organization } from '@betterleap/client';
import { showToast } from '@betterleap/ui';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { apiClient } from '../lib/apiClient';
import useFetch from './fetch';

type IntegrationCategory = ConnectIntegrationDto.integrationCategory;

export interface ConnectIntegrationProps {
  location: string;
  onSuccess?: (token: string) => void;
}

interface ConnectIntegrationResponse {
  open: (category?: IntegrationCategory) => void;
  isLoading: boolean;
}

interface IntegrationDefinition {
  accountToken: string | undefined;
  toast: {
    title: string;
    description: string;
  };
}

const useConnectIntegration = ({
  onSuccess,
}: ConnectIntegrationProps): ConnectIntegrationResponse => {
  const [isLoading, setIsLoading] = useState(false);
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [organization, refetch] = useFetch<Organization>('organization');

  const integrationCategory = getIntegrationCategory();
  const integration = mapIntegration(
    integrationCategory,
    organization?.data as Organization,
  );

  useQuery(
    ['link_token', integrationCategory],
    () => apiClient.organization.createLinkToken({ integrationCategory }),
    {
      enabled: !integration?.accountToken && isLoading,
      onSuccess: (response) => {
        setIsLoading(false);
        setLinkToken(response.data.linkToken);
      },
    },
  );

  const connectIntegration = useMutation(
    (publicToken: string) =>
      apiClient.organization.connectIntegration({
        requestBody: {
          publicToken,
          integrationCategory,
        },
      }),
    {
      onSuccess: () => {
        refetch();
        showToast({
          variant: 'success',
          title: integration?.toast.title,
          description: integration?.toast.description,
        });
      },
    },
  );

  const onMergeLinkSuccess = useCallback((token: string) => {
    connectIntegration.mutate(token);
    onSuccess?.(token);
  }, []);

  const { open: openMergeLink, isReady: isMergeLinkReady } = useMergeLink({
    linkToken: linkToken || undefined,
    onSuccess: onMergeLinkSuccess,
  });

  useEffect(() => {
    if (linkToken && !isLoading && isMergeLinkReady) {
      openMergeLink();
    }
  }, [linkToken]);

  const open = (category = ConnectIntegrationDto.integrationCategory.ATS) => {
    // state is always re-initialized on window re-focus :shrug:
    setIntegrationCategory(category);
    setLinkToken(null);
    setIsLoading(true);
  };

  return { open, isLoading };
};

function setIntegrationCategory(category: IntegrationCategory) {
  sessionStorage.setItem('integrationCategory', category);
}

function getIntegrationCategory() {
  return sessionStorage.getItem('integrationCategory') as IntegrationCategory;
}

function mapIntegration(
  integrationCategory: ConnectIntegrationDto.integrationCategory,
  organization: Organization,
): IntegrationDefinition | undefined {
  switch (integrationCategory) {
    case ConnectIntegrationDto.integrationCategory.ATS:
      return atsDefinition(organization);
    case ConnectIntegrationDto.integrationCategory.CRM:
      return crmDefinition(organization);
    default:
      return undefined;
  }
}

function atsDefinition(organization: Organization): IntegrationDefinition {
  return {
    accountToken: organization.accountToken,
    toast: {
      title: 'ATS Successfully Connected',
      description: 'We will let you know when the ATS sync is complete.',
    },
  };
}

function crmDefinition(organization: Organization): IntegrationDefinition {
  return {
    accountToken: organization.crmAccountToken,
    toast: {
      title: 'CRM Successfully Connected',
      description: 'You can now sync leads in your sales project to your CRM.',
    },
  };
}

export default useConnectIntegration;
