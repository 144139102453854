import { forwardRef } from 'react';
import merge from 'lodash/merge';
import { Flex, FlexProps, Tooltip, TooltipProps } from '@betterleap/ui';

export interface OverflowListProps<T> extends Omit<FlexProps, 'children'> {
  items: T[];
  max?: number;
  children: (arg: T, isOverflow: boolean) => JSX.Element | null;
  indicatorContent?: (items: T[]) => JSX.Element;
  tooltipProps?: Partial<Omit<TooltipProps, 'ref'>>;
}

export const OverflowIndicator = forwardRef<HTMLDivElement, FlexProps>(
  ({ css, ...rest }, ref) => (
    <Flex
      ref={ref}
      css={merge(
        {
          whiteSpace: 'nowrap',
          borderRadius: '$md',
          px: 6,
          py: 4,
          color: '$neutral-blue-700',
          fontSize: '$sm',
          hover: {
            cursor: 'pointer',
            backgroundColor: '$neutral-blue-200',
          },
        },
        css,
      )}
      {...rest}
    />
  ),
);

OverflowIndicator.displayName = 'OverflowIndicator';

export function OverflowList<T>({
  items,
  max,
  indicatorContent,
  tooltipProps,
  children,
  ...rest
}: OverflowListProps<T>) {
  let renderIndicator = (overflowItems: T[]) => (
    <OverflowIndicator>+ {overflowItems.length}</OverflowIndicator>
  );

  renderIndicator = indicatorContent ?? renderIndicator;

  const overflowItems = items.slice(max);

  return (
    <Flex {...rest}>
      {items.slice(0, max).map((item) => children(item, false))}
      {!!overflowItems.length && (
        <Tooltip
          css={{ maxWidth: 250 }}
          variant='light'
          {...tooltipProps}
          content={
            <Flex css={{ rowGap: 8 }} wrap>
              {overflowItems.map((overflowItem) =>
                children(overflowItem, true),
              )}
            </Flex>
          }
        >
          {renderIndicator(overflowItems)}
        </Tooltip>
      )}
    </Flex>
  );
}
