import { Flex } from '@betterleap/ui';
import {
  ApiResponse,
  ExtendedContactProject,
  ProjectWithStatsDto,
} from '@betterleap/client';
import { Row } from 'react-table';
import { ProjectActionBar } from '../ProjectActions';
import { SendSequenceButton } from './Buttons/SendSequenceButton';
import { SendFirstStepButton } from './Buttons/SendFirstStepButton';
import { ExportAsCsvButton } from './Buttons/ExportAsCsvButton';
import { RemoveFromProjectButton } from './Buttons/RemoveFromProjectButton';
import { SyncAtsContactButton } from './Buttons/SyncAtsContactButton';
import { ResumeSequenceButton } from './Buttons/ResumeSequenceButton';
import { PauseSequenceButton } from './Buttons/PauseSequenceButton';
import { CopyToProjectButton } from './Buttons/CopyToProjectButton';
import { MoveToProjectButton } from './Buttons/MoveToProjectButton';
import { ActionSelectionText } from './ActionSelectionText';
import { RestartSequenceButton } from './Buttons/RestartSequenceButton';

interface ProjectActionsBarProps {
  project: ProjectWithStatsDto;
  selectedContacts: Row<Record<string, unknown>>[] | 'all';
  projectContacts: ApiResponse<ExtendedContactProject[]> | undefined;
  onActionCompleted: () => void;
}

export const ProjectActionsBar = ({
  project,
  selectedContacts,
  projectContacts,
  onActionCompleted,
}: ProjectActionsBarProps) => {
  return (
    <ProjectActionBar css={{ gap: 8 }}>
      <Flex css={{ gap: 8 }}>
        <MoveToProjectButton
          project={project}
          totalContactCount={projectContacts?.meta?.count ?? 0}
          selectedContacts={selectedContacts}
          onContactsMoved={onActionCompleted}
        />
        <CopyToProjectButton
          project={project}
          totalContactCount={projectContacts?.meta?.count ?? 0}
          selectedContacts={selectedContacts}
          onContactsCopied={onActionCompleted}
        />
        <PauseSequenceButton
          project={project}
          selectedContacts={selectedContacts}
          onSequencesPaused={onActionCompleted}
        />
        <ResumeSequenceButton
          project={project}
          selectedContacts={selectedContacts}
          onSequencesResumed={onActionCompleted}
        />
        <ExportAsCsvButton
          projectId={project.id}
          selectedContacts={selectedContacts}
        />
        <SyncAtsContactButton
          project={project}
          selectedContacts={selectedContacts}
          onSyncStarted={onActionCompleted}
        />
        <RestartSequenceButton
          projectId={project.id}
          sequenceId={project.sequenceId}
          projectContacts={projectContacts}
          selectedContacts={selectedContacts}
        />
        <SendFirstStepButton
          projectId={project.id}
          sequenceId={project.sequenceId}
          selected={selectedContacts}
        />
        <RemoveFromProjectButton
          project={project}
          totalContactCount={projectContacts?.meta?.count ?? 0}
          selectedContacts={selectedContacts}
          onContactsRemoved={onActionCompleted}
        />
      </Flex>
      <Flex css={{ gap: 12 }}>
        <ActionSelectionText
          project={project}
          totalCount={projectContacts?.meta?.count ?? 0}
          selectedContacts={selectedContacts}
        />
        {!!project.sequenceId && (
          <SendSequenceButton
            project={project}
            selectedContacts={selectedContacts}
            onSuccess={onActionCompleted}
          />
        )}
      </Flex>
    </ProjectActionBar>
  );
};
