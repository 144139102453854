import { Types } from '@betterleap/shared';
import { PromiseModal } from '@betterleap/ui';
import CsvParser from 'lib/csv';
import CsvUploadModal from '../CsvUploadModal/CsvUploadModal';

interface AddCandidatesModalProps {
  title: string;
  handleSubmit: (contacts: Partial<Types.Contact>[]) => void;
}

const AddCandidatesModal: PromiseModal<AddCandidatesModalProps> = ({
  title,
  onDismiss,
  onSubmit,
  onReject,
  handleSubmit,
}) => (
  <CsvUploadModal
    title={title}
    columnHintBefore='Your CSV must contain 4 columns labelled as below, in no particular order:'
    columns={['First Name', 'Last Name', 'Email Address', 'Profile URL']}
    requiredColumns={['Profile URL']}
    columnHintAfter='You may have additional columns.'
    parseFile={CsvParser.parse}
    handleSubmit={handleSubmit}
    onSubmit={onSubmit}
    onDismiss={onDismiss}
    onReject={onReject}
  />
);
export default AddCandidatesModal;
