import React from 'react';
import { Flex, FlexProps } from '@betterleap/ui';

export interface EmptyStateProps extends FlexProps {
  isEmpty: boolean;
  fallback: React.ReactNode;
}

export const EmptyState = ({
  isEmpty,
  fallback,
  css,
  children,
  ...rest
}: EmptyStateProps) => (
  <>
    {isEmpty ? (
      <Flex
        vertical
        centered
        css={{
          minHeight: 300,
          ...css,
        }}
        {...rest}
      >
        {fallback}
      </Flex>
    ) : (
      children
    )}
  </>
);
