import { PromiseModal } from '@betterleap/ui';
import { parseCsv } from 'functions/parseCsv';
import CsvUploadModal from '../CsvUploadModal/CsvUploadModal';

interface DoNotContactModalProps {
  title: string;
  handleSubmit: (domains: string[]) => void;
}

export const DoNotContactModal: PromiseModal<DoNotContactModalProps> = ({
  title,
  onDismiss,
  onSubmit,
  onReject,
  handleSubmit,
}) => {
  const parseFile = async (file: File) => {
    const results = await parseCsv(file);
    const domains = results.map((r) => r['Company Domain']).filter((d) => d);
    if (results.length > 0 && domains.length === 0) {
      throw new Error('FieldsNotCorrect');
    }
    return domains as string[];
  };
  return (
    <CsvUploadModal
      title={title}
      columnHintBefore='Your CSV must contain 1 column labelled as below.'
      columns={['Company Domain']}
      requiredColumns={['Company Domain']}
      columnHintAfter='List companies as domains (company.com). You may have additional columns.'
      parseFile={parseFile}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      onDismiss={onDismiss}
      onReject={onReject}
    />
  );
};
