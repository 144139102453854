import {
  BulkRemoveFromProjectSequenceDto,
  ExtendedContactProject,
  ProjectWithStatsDto,
} from '@betterleap/client';
import { Types } from '@betterleap/shared';
import { AlertIconProps, showToast, useModal } from '@betterleap/ui';
import SequenceConfirmationModal from 'components/modules/Modal/SequenceConfirmationModal/SequenceConfirmationModal';
import { logToSegment } from 'functions/segmentLog';
import { apiClient } from 'lib/apiClient';
import { useMutation } from 'react-query';
import { resolveSubject } from '../ProjectDetails.functions';
import { useProjectContactsFilterParams } from '../useProjectContactsFilterParams';

interface RemoveContactProps {
  project: ProjectWithStatsDto;
  selectedContactIds?: string[] | undefined;
  selectedContactCount?: number;
  onContactsRemoved: () => void;
}

export const useRemoveContacts = ({
  project,
  selectedContactIds,
  selectedContactCount,
  onContactsRemoved,
}: RemoveContactProps) => {
  const { contactProjectQuery } = useProjectContactsFilterParams();

  const openRemoveConfirmationModal = useModal<{
    title: string;
    notes: { id: number; name: string }[];
    description: string;
    icon: { variant: AlertIconProps['variant']; name: AlertIconProps['name'] };
    confirmButtonText: string;
    confirmButtonVariant?: string;
  }>(SequenceConfirmationModal);

  const Contact = resolveSubject(project, { cap: true, singular: true });
  const contacts = resolveSubject(project);

  const removeContact = useMutation(
    (data: Partial<ExtendedContactProject>) =>
      apiClient.contact.removeFromProjectSequence({
        requestBody: {
          contactId: data.id as string,
          projectId: project.id,
          sequenceId: project.sequenceId,
        },
      }),
    {
      onSuccess: () => {
        onContactsRemoved();
        if (project.sequenceId) {
          showToast({
            variant: 'success',
            title: 'Success!',
            description: `${Contact} removed from project and will no longer receive a sequence.`,
          });
        } else {
          showToast({
            variant: 'success',
            title: 'Success!',
            description: `${Contact} removed.`,
          });
        }
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: `${Contact} removal failed`,
          description: 'Something went wrong. Please try again.',
        });
      },
    },
  );

  const bulkRemoveContacts = useMutation(
    (data: BulkRemoveFromProjectSequenceDto) =>
      apiClient.contact.bulkRemoveFromProjectSequence({ requestBody: data }),
    {
      onSuccess: () => {
        showToast({
          title: 'Success!',
          description: `Selected ${contacts} are removed.`,
        });
        onContactsRemoved();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: `Failed to remove ${contacts}. Please try again.`,
        });
      },
    },
  );

  const removeFromProject = async (contactProject?: ExtendedContactProject) => {
    const result = await openRemoveConfirmationModal({
      title: `Are you sure you want to remove ${contacts} from this project?`,
      notes: [
        {
          id: 1,
          name: `If selected ${contacts} are currently receiving a sequence, the sequence will automatically stop for these ${contacts}.`,
        },
      ],
      description: '',
      icon: { variant: 'danger', name: 'exclamation-circle' },
      confirmButtonText: 'Remove',
      confirmButtonVariant: 'danger',
    });
    if (!result) {
      return;
    }
    if (contactProject) {
      removeContact.mutate(contactProject);
    } else {
      logToSegment(Types.SEGMENT_EVENTS.BULK_ACTION_ON_CANDIDATES, {
        action_taken: Types.BULK_ACTION.REMOVE,
        num_candidates_selected: selectedContactCount,
      });
      bulkRemoveContacts.mutate({
        ...contactProjectQuery,
        projectId: project.id,
        sequenceId: project.sequenceId,
        contactIds: selectedContactIds,
      });
    }
  };

  return { removeFromProject };
};
