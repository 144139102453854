import { UpdateContactProjectDto } from '@betterleap/client';
import { apiClient } from 'lib/apiClient';
import { useMutation } from 'react-query';

export const useSetInterested = ({
  onStatusUpdated,
}: {
  onStatusUpdated: () => void;
}) => {
  const updateContactProject = useMutation(
    ({
      id: contactProjectId,
      ...requestBody
    }: { id: string } & UpdateContactProjectDto) =>
      apiClient.contactProject.update({
        contactProjectId,
        requestBody,
      }),
    {
      onSuccess: async () => {
        onStatusUpdated();
      },
    },
  );

  const setInterested = (
    contactProjectId: string,
    interested: boolean | null,
  ) => {
    updateContactProject.mutate({
      id: contactProjectId,
      interested,
    });
  };

  return { setInterested };
};
