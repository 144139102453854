import { Box, Button, Flex, Text, TextProps } from '@betterleap/ui';
import React from 'react';
import {
  signupWithGoogle,
  signupWithMicrosoft,
  Spinner,
} from '@betterleap/shared';
import config from 'lib/config';
import { useSignup } from 'components/templates/SignIn/useSignup';
import landingGradientFull from '../../../../assets/images/landing-gradient-full.png';
import sharedProjectImage from '../../../../assets/images/shared-project-image.svg';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import microsoftIcon from '../../../../assets/images/microsoft.svg';
import googleIcon from '../../../../assets/images/google-icon.png';
import logo from '../../../../assets/images/shared-project-logo.svg';

interface LandingPageSignUp {
  invitedBy: string | null;
  invitee: string | null;
  setIsLoading: (loading: boolean) => void;
  error?: boolean;
  loading?: boolean;
}

const HeroText = ({ css, ...props }: TextProps) => (
  <Text
    size='4xl'
    css={{
      color: 'White',
      lineHeight: '3rem',
      fontWeight: '$medium',
      textAlign: 'center',
      ...css,
    }}
    {...props}
  />
);

const LandingPageSignUp = ({
  setIsLoading,
  error,
  invitedBy,
  invitee,
  loading,
}: LandingPageSignUp) => {
  const { width } = useWindowDimensions();

  const handleGoogleClick = () => {
    signupWithGoogle(
      setIsLoading,
      config.environment === 'development',
      ({ mappedUser }) => signup(mappedUser),
    );
  };

  const handleMicrosoftClick = () => {
    signupWithMicrosoft(
      setIsLoading,
      config.environment === 'development',
      ({ mappedUser }) => signup(mappedUser),
    );
  };

  const { signup } = useSignup();

  return (
    <Flex css={{ overflow: 'hidden' }}>
      <Flex
        vertical
        css={{
          flexGrow: '0 50%',
          p: 56,
          backgroundImage: `url(${landingGradientFull})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <Text
          size='sm'
          css={{
            color: 'White',
            mb: 24,
            mediaSm: { display: 'inline-flex' },
          }}
        >
          You’ve been invited by
          <Text
            size='sm'
            css={{
              color: 'White',
              fontWeight: '$bold',
              ml: 4,
              textAlign: 'center',
              mt: 8,
              mediaSm: {
                mt: 'unset',
              },
            }}
          >
            {invitedBy || 'your recruiter'}
          </Text>
        </Text>
        <Flex css={{ flexDirection: 'column' }}>
          <img src={logo} alt='betterleap_logo' />
          <HeroText css={{ mt: 48 }}>Hi{invitee && ` ${invitee}`},</HeroText>
          <HeroText noBreak>welcome to Betterleap!</HeroText>
          <Text
            css={{ color: '$neutral-blue-500', mt: 12, textAlign: 'center' }}
          >
            Your all-in-one recruiting collaboration platform
          </Text>
          <Flex
            vertical
            align='center'
            css={{
              p: 24,
              borderRadius: '$xl',
              mt: 24,
            }}
          >
            <Text
              css={{
                textAlign: 'center',
                mb: 24,
                color: '$neutral-blue-500',
                display: error ? 'unset' : 'none',
              }}
            >
              We weren’t able to authenticate your email
              <br /> address. Please use the email address that your
              <br /> invite was sent to and try again.
            </Text>
            {loading && !error ? (
              <Spinner variant='white' />
            ) : (
              <Flex vertical css={{ gap: 24 }}>
                <Button
                  variant='ghost'
                  onClick={handleGoogleClick}
                  css={{
                    borderRadius: 16,
                    padding: '24px 24px',
                    maxWidth: 265,
                    width: '100%',
                    minWidth: 265,
                  }}
                >
                  <Box
                    as='img'
                    css={{ maxWidth: 22, mr: 8 }}
                    src={googleIcon}
                    alt='icon'
                  />
                  Continue with Google
                </Button>
                <Button
                  variant='ghost'
                  onClick={handleMicrosoftClick}
                  css={{
                    borderRadius: 16,
                    padding: '24px 24px',
                    maxWidth: 265,
                    width: '100%',
                    minWidth: 265,
                  }}
                >
                  <Box
                    as='img'
                    css={{ maxWidth: 22, mr: 8 }}
                    src={microsoftIcon}
                    alt='icon'
                  />
                  Continue with Microsoft
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Text
          css={{
            fontSize: '$sm',
            color: '$neutral-blue-600',
            textAlign: 'center',
          }}
        >
          By clicking &quot;Continue with Google&quot;, you&apos;re
          <br /> agreeing to our{' '}
          <a
            target='_blank'
            href='https://betterleap.com/terms-of-service-for-employers-and-recruiters/'
            style={{ textDecoration: 'underline' }}
            rel='noreferrer'
          >
            Terms
          </a>{' '}
          and{' '}
          <a
            target='_blank'
            href='https://betterleap.com/privacy/'
            style={{ textDecoration: 'underline' }}
            rel='noreferrer'
          >
            Privacy Policy
          </a>
        </Text>
      </Flex>
      {width > 1200 && (
        <Flex
          css={{
            flexGrow: '0 50%',
            width: '50%',
            paddingRight: 32,
          }}
        >
          <Box
            css={{
              width: 800,
              right: 0,
              top: '150px',
              transition: 'width ease 0.5s',
              transform: 'translateX(-50px)',
              scale: 1.1,
            }}
          >
            <img src={sharedProjectImage} alt='shared_project' />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default LandingPageSignUp;
