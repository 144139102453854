import {
  Select,
  SelectItem,
  SelectProps,
  showToast,
  useModal,
  usePrevious,
} from '@betterleap/ui';
import { AUTH_ROLE, subject } from '@betterleap/authz';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useMe } from '@betterleap/shared';
import { InvitationsMetadataDto } from '@betterleap/client';
import {
  ConfirmModal,
  ConfirmModalProps,
} from 'components/modules/Modal/ConfirmModal/ConfirmModal';
import { apiClient } from 'lib/apiClient';
import { InviteMember } from './OrganizationInviteTemplate';

export const RoleSelect = ({
  css,
  member,
  ...rest
}: SelectProps & { member: InviteMember }) => {
  const me = useMe();

  const openConfirmRoleChangeModal = useModal<ConfirmModalProps>(ConfirmModal);

  const roles = [AUTH_ROLE.Admin, AUTH_ROLE.User];
  const [role, setRole] = useState(
    roles.find((r) => member.roles?.includes(r)) ?? AUTH_ROLE.User,
  );

  useEffect(() => {
    setRole(roles.find((r) => member.roles?.includes(r)) ?? AUTH_ROLE.User);
  }, [member]);

  const previousRole = usePrevious(role);
  const isOrgCreator = member.id === me.user?.organization?.createdByUserId;
  const isMe = member.id === me.user?.id;
  const canUpdateRole = me.ability.can(
    'update',
    subject('User', member),
    'roles',
  );

  const updateRole = useMutation(
    (body: Parameters<typeof apiClient.user.update>[0]) =>
      apiClient.user.update(body),
    {
      onSuccess: () => {
        showToast({
          variant: 'success',
          title: 'Success!',
          description: "This user's role has been updated.",
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Oops, Something went wrong',
          description: 'Please try again',
        });

        if (previousRole) {
          setRole(previousRole);
        }
      },
    },
  );
  const updateInvite = useMutation(
    (body: Parameters<typeof apiClient.invitation.inviteUser>[0]) =>
      apiClient.invitation.inviteUser(body),
    {
      onSuccess: () => {
        showToast({
          variant: 'success',
          title: 'Success!',
          description: "This user's role has been updated.",
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Oops, Something went wrong',
          description: 'Please try again',
        });

        if (previousRole) {
          setRole(previousRole);
        }
      },
    },
  );

  const handleRoleChange = async (newRole: string) => {
    const confirm = await openConfirmRoleChangeModal({
      title: 'Change Role',
      description: (
        <>
          Are you sure you want to assign the role{' '}
          <strong>
            {newRole === AUTH_ROLE.User ? 'Member' : capitalize(newRole)}
          </strong>{' '}
          to <strong>{member.name}</strong>?
        </>
      ),
      icon: {
        name: 'exclamation-circle',
        variant: 'warning',
      },
      confirmationText: 'Confirm',
      dismissButtonText: 'Cancel',
    });

    if (confirm) {
      setRole(newRole as AUTH_ROLE);

      const { id, invitationId, name } = member;
      const [firstName, lastName] = (name ?? '').split(' ');
      if (invitationId) {
        updateInvite.mutate({
          requestBody: {
            inviteEmail: member.email as string,
            firstName: firstName as string,
            lastName: lastName as string,
            shouldSendEmail: false,
            metadata: {
              type:
                (member.invitationType as InvitationsMetadataDto.type) ??
                InvitationsMetadataDto.type.TEAMMATE_INVITE,
              userRoles: [newRole],
            },
          },
        });
      } else if (id) {
        updateRole.mutate({
          id: member.id as string,
          requestBody: {
            roles: [newRole],
          },
        });
      }
    }
  };

  return (
    <Select
      onChange={handleRoleChange}
      value={role}
      disabled={isOrgCreator || !canUpdateRole || isMe}
      css={{
        p: 0,
        border: 'none',
        backgroundColor: 'transparent',
        '& svg': {
          fill: '$blue-600',
        },
        disabled: {
          color: '$gray-500',
          backgroundColor: 'transparent',
          cursor: 'auto',
          '& svg': {
            display: 'none',
          },
        },
        color: '$blue-600',
        ...css,
      }}
      {...rest}
    >
      <SelectItem value={AUTH_ROLE.User}>Member</SelectItem>
      <SelectItem value={AUTH_ROLE.Admin}>
        {capitalize(AUTH_ROLE.Admin)}
      </SelectItem>
    </Select>
  );
};
