import { AUTH_ROLE } from '@betterleap/authz';
import { excludedEmails, LoadingArea, useMe } from '@betterleap/shared';
import {
  ApiError,
  CreateInvitationsDto,
  InvitationsMetadataDto,
  Organization,
} from '@betterleap/client';
import {
  Alert,
  AlertAction,
  AlertHeading,
  AlertIcon,
  AlertProps,
  BoundCombobox,
  Box,
  Button,
  Flex,
  Form,
  Icon,
  IconButton,
  InlineText,
  BoundInput as Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Option,
  PromiseModal,
  Spinner,
  Text,
  TextSkeleton,
} from '@betterleap/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreditCardIcon } from 'components/elements/CreditCardIcon/CreditCardIcon';
import { format } from 'date-fns';
import { apiClient } from 'lib/apiClient';
import { capitalize } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import pluralize from 'pluralize';
import { requiredString } from 'schemas/helpers';
import { z } from 'zod';

export const MembersSchema = z.object({
  members: z.array(
    z.object({
      firstName: requiredString('First Name'),
      lastName: requiredString('Last Name'),
      email:
        process.env.REACT_APP_ENV === 'production'
          ? z
              .string()
              .email('Must be valid email address')
              .regex(
                new RegExp(`^((?!${excludedEmails.join('|')}).)*$`),
                'Must be a company email.',
              )
          : z.string().email('Must be valid email address'),
      roles: z.array(z.string()),
    }),
  ),
});

enum PAYMENT_ERRORS {
  CARD_DECLINED = 'CardDeclined',
  OTHER = 'Other',
}

const resolver = zodResolver(MembersSchema);
export type MemberFormType = z.infer<typeof MembersSchema>;

const MemberInfoInput = ({
  name,
  onRemove,
}: {
  name: string;
  onRemove?: () => void;
}) => {
  return (
    <Flex
      css={{
        px: 20,
        pt: 20,
        gap: 8,
        position: 'relative',
        backgroundColor: '$neutral-blue-100',
        borderRadius: '$3xl',
        '& + &': { mt: 12 },
      }}
    >
      <Input
        css={{ maxWidth: 170 }}
        id={`${name}.firstName`}
        name={`${name}.firstName`}
        placeholder='First name'
      />
      <Input
        css={{ maxWidth: 170 }}
        id={`${name}.lastName`}
        name={`${name}.lastName`}
        placeholder='Last name'
      />
      <Input
        css={{ flex: 1 }}
        id={`${name}.email`}
        name={`${name}.email`}
        placeholder='Email'
      />
      <BoundCombobox
        css={{ maxWidth: 150 }}
        id={`${name}.roles`}
        name={`${name}.roles`}
        typeahead={false}
        clearable={false}
        options={[
          { id: AUTH_ROLE.Admin, value: AUTH_ROLE.Admin },
          { id: AUTH_ROLE.User, value: 'Member' },
        ]}
      >
        {(option: { id: string; value: string }) => (
          <Option>
            <Text size='sm' truncate>
              {capitalize(option.value)}
            </Text>
          </Option>
        )}
      </BoundCombobox>
      {onRemove && (
        <IconButton
          name='x'
          variant='danger'
          label='remove invite'
          size='xs'
          css={{
            position: 'absolute',
            right: -4,
            top: -8,
            boxShadow: '$whiteOutline',
          }}
          onClick={onRemove}
        />
      )}
    </Flex>
  );
};

interface PaymentMethodAlertProps extends AlertProps {
  last4: string;
  brand: string;
  error?: PAYMENT_ERRORS;
}

const PaymentMethodAlert = ({
  brand,
  last4,
  error,
  css,
  ...rest
}: PaymentMethodAlertProps) => {
  const me = useMe();

  const renderText = () => {
    switch (error) {
      case PAYMENT_ERRORS.CARD_DECLINED:
        return 'Payment failed, please check your payment settings.';
      case PAYMENT_ERRORS.OTHER:
        return 'Something went wrong, please try again.';
      default:
        return (
          <Text inherit flex css={{ gap: 4 }}>
            You will be charged to <CreditCardIcon brand={brand} /> **** {last4}
          </Text>
        );
    }
  };

  const handleManagePaymentClick = () => {
    window.open(
      `${process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL}?prefilled_email=${me.user?.email}`,
      '_blank',
    );
  };

  return (
    <Alert
      variant={error ? 'danger' : 'info'}
      css={{
        gridTemplateRows: 'auto 0',
        ...css,
      }}
      {...rest}
    >
      <AlertHeading>{renderText()}</AlertHeading>
      <AlertAction onClick={handleManagePaymentClick}>
        Manage Payment Method{' '}
        <Icon css={{ ml: 8 }} size={16} inherit name='arrow-narrow-right' />
      </AlertAction>
    </Alert>
  );
};

const SubscriptionUpdates = ({ inviteCount }: { inviteCount: number }) => {
  const prorationDate = useMemo(() => new Date(), []);

  const { data: countSeatsResponse, isLoading: isCountSeatsLoading } = useQuery(
    ['get_organization_seats'],
    () => apiClient.organization.countOrganizationSeats(),
  );

  const { data: upcomingInvoiceResponse, isLoading: isUpcomingInvoiceLoading } =
    useQuery(['get_upcoming_invoice', inviteCount], () =>
      apiClient.payment.getUpcomingInvoice({
        quantity: inviteCount,
        prorationDate: prorationDate.toISOString(),
      }),
    );

  if (isUpcomingInvoiceLoading || isCountSeatsLoading) {
    return (
      <Flex vertical css={{ gap: 6 }}>
        <TextSkeleton size='xs' css={{ width: 220 }} />
        <TextSkeleton size='xs' css={{ width: 225 }} />
        <TextSkeleton size='xs' css={{ width: 315 }} />
      </Flex>
    );
  }

  const currentSeats = countSeatsResponse?.data;
  const upcomingInvoice = upcomingInvoiceResponse?.data;

  if (!currentSeats || !upcomingInvoice) {
    return (
      <Text size='xs'>
        Unable to calculate change to subscription price.
        <br />
        Please reach out to support@betterleap.com for pricing information.
      </Text>
    );
  }

  const newSeatCount = currentSeats.seatCount + inviteCount;
  const invoiceDate = format(
    new Date(upcomingInvoice.nextInvoiceDate),
    'dd MMM yyyy',
  );
  const prorationCost = (
    upcomingInvoice.chargeImmediatelyAmount / 100
  ).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const nextInvoiceCost = (
    upcomingInvoice.nextInvoiceAmount / 100
  ).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

  return (
    <Text size='xs'>
      Your plan will immediately be updated to{' '}
      <InlineText css={{ fontWeight: '$medium' }}>
        {newSeatCount} seats
      </InlineText>
      . <br />
      You will charged{' '}
      <InlineText css={{ fontWeight: '$medium' }}>
        {prorationCost} now
      </InlineText>{' '}
      for{' '}
      <InlineText css={{ fontWeight: '$medium' }}>
        {upcomingInvoice.chargeImmediatelyQuantity} additional{' '}
        {pluralize('seat', upcomingInvoice.chargeImmediatelyQuantity ?? 0)}
      </InlineText>
      . <br />
      Your next subscription payment of{' '}
      <InlineText css={{ fontWeight: '$medium' }}>
        {nextInvoiceCost}
      </InlineText>{' '}
      will be{' '}
      <InlineText css={{ fontWeight: '$medium' }}>{invoiceDate}</InlineText>.
    </Text>
  );
};

const InviteMembersModal: PromiseModal = ({ onDismiss, onSubmit }) => {
  const [error, setError] = useState<PAYMENT_ERRORS>();
  const me = useMe();

  const isPaid = me.user?.organization?.tier === Organization.tier.PAID;

  const { control, handleSubmit } = useForm<MemberFormType>({
    defaultValues: {
      members: [
        {
          firstName: '',
          lastName: '',
          email: '',
          roles: [AUTH_ROLE.User],
        },
      ],
    },
    resolver,
  });

  const {
    data: defaultPaymentMethodResponse,
    isLoading: isDefaultPaymentMethodLoading,
  } = useQuery(['get_default_payment_method'], () =>
    apiClient.payment.getDefaultPaymentMethod(),
  );

  const inviteMembers = useMutation(
    (data: CreateInvitationsDto[]) =>
      apiClient.invitation.inviteUsers({
        requestBody: {
          invites: data,
        },
      }),
    {
      onSuccess: (result) => {
        onSubmit(result.data);
      },
      onError: (err: ApiError) => {
        const errorName = err.body?.error?.data?.name;

        if (errorName === 'CardDeclined') {
          setError(PAYMENT_ERRORS.CARD_DECLINED);
        } else {
          setError(PAYMENT_ERRORS.OTHER);
        }
      },
    },
  );

  const handleInvite = async (data: MemberFormType) => {
    setError(undefined);
    inviteMembers.mutate(
      data.members.map((invite) => ({
        ...invite,
        metadata: {
          type: InvitationsMetadataDto.type.TEAMMATE_INVITE,
          userRoles: invite.roles,
        },
        inviteEmail: invite.email?.toLowerCase().trim(),
      })),
    );
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'members',
  });

  const defaultPaymentMethod = defaultPaymentMethodResponse?.data;

  const handleClickAddMember = () => {
    append({
      email: '',
      firstName: '',
      lastName: '',
      roles: [AUTH_ROLE.User],
    });
  };

  const handleClickRemoveMember = (index: number) => {
    remove(index);
  };

  return (
    <Modal css={{ p: 0, maxWidth: 850 }}>
      <LoadingArea isLoading={isDefaultPaymentMethodLoading}>
        <ModalHeader
          css={{
            px: 32,
            pt: 32,
            pb: 24,
            borderBottom: '1px solid $neutral-blue-300',
          }}
        >
          <Flex align='start' css={{ width: '100%', gap: 24 }}>
            <AlertIcon
              name='user-add'
              variant='purple'
              shape='rounded-square'
            />
            <Box>
              <Text
                as='h1'
                css={{
                  fontWeight: '$medium',
                  fontSize: '$lg',
                  lineHeight: '28px',
                }}
              >
                Invite your team members
              </Text>
              <Text size='xs' css={{ color: '$gray-500' }}>
                Your subscription will be adjusted based on the number of team
                members in your company.
              </Text>
            </Box>
          </Flex>
          {defaultPaymentMethod && (
            <PaymentMethodAlert
              css={{ mt: 24 }}
              last4={defaultPaymentMethod.last4}
              brand={defaultPaymentMethod.brand}
              error={error}
            />
          )}
        </ModalHeader>
        <ModalBody css={{ p: 32 }}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <Form control={control as any} onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => (
              <MemberInfoInput
                key={item.id}
                name={`members.${index}`}
                onRemove={
                  index > 0 ? () => handleClickRemoveMember(index) : undefined
                }
              />
            ))}
          </Form>
          <Button
            variant='headless'
            css={{ fontSize: '$xs', color: '$text-secondary', mt: 8 }}
            onClick={handleClickAddMember}
          >
            <Icon
              name='plus'
              size={14}
              color='$text-secondary'
              css={{ mr: 4 }}
            />
            Add Team Member
          </Button>
        </ModalBody>
        <ModalFooter
          css={{ p: 32, justifyContent: 'space-between', display: 'flex' }}
        >
          <div>
            {isPaid && <SubscriptionUpdates inviteCount={fields.length} />}
          </div>
          <Flex css={{ gap: 8 }}>
            <Button
              variant='gray'
              onClick={onDismiss}
              css={{ width: 146 }}
              dataCy='Cancel'
            >
              Cancel
            </Button>
            <Button
              css={{ width: 146 }}
              onClick={handleSubmit(handleInvite)}
              dataCy='Invite'
            >
              {inviteMembers.isLoading ? <Spinner /> : 'Invite'}
            </Button>
          </Flex>
        </ModalFooter>
      </LoadingArea>
    </Modal>
  );
};
export default InviteMembersModal;
