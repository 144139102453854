import { Box, Flex } from '@betterleap/ui';
import candidateProfileImage from '../../../assets/images/sourcing-search-candidate-profile.svg';
import filtersImage from '../../../assets/images/sourcing-search-filters.svg';
import integrationsImage from '../../../assets/images/sourcing-search-integrations.svg';
import InfoCard from '../../elements/InfoCard/InfoCard';
import Paywall from '../../elements/Paywall/Paywall';

export const SourceCandidatesPaywall = () => (
  <Flex align='center' vertical css={{ p: 24, width: '100%', height: '100%' }}>
    <Paywall
      title='Betterleap Sourcing: An effortless way to find candidates'
      description='Identify and engage top talent using our database of 800M+ professionals.'
      location='Sourcing'
      css={{ background: '$neutral-blue-200' }}
    />

    <Flex
      css={{
        gap: 74,
        mt: 80,
        flexWrap: 'wrap',
        justifyContent: 'center',
        p: 16,
      }}
    >
      <InfoCard
        title='800M+ candidate profiles'
        description='Discover the perfect match for your search using our powerful filters.'
        css={{ width: 255 }}
        image={
          <Box
            as='img'
            src={candidateProfileImage}
            alt='sourcing_profiles'
            css={{ width: 255 }}
          />
        }
      />
      <InfoCard
        title='Diversity filters'
        description='Find a diverse range of hard-to-find professionals.'
        css={{ width: 255 }}
        image={
          <Box
            as='img'
            src={filtersImage}
            alt='sourcing_filters'
            css={{ width: 255 }}
          />
        }
      />
      <InfoCard
        title='Seamless integrations'
        description='Effortless integration with the Betterleap recruiting stack.'
        css={{ width: 255 }}
        image={
          <Box
            as='img'
            src={integrationsImage}
            alt='sourcing_integrations'
            css={{ width: 255 }}
          />
        }
      />
    </Flex>
  </Flex>
);
