import { ContactNote, ContactProjectNote } from '@betterleap/client';

export function noteAuthorFirstName(item: ContactProjectNote | ContactNote) {
  let firstName = item.user?.firstName;
  if (!firstName && 'authorFirstName' in item) {
    firstName = item.authorFirstName;
  }
  return firstName;
}

export function noteAuthorLastName(item: ContactProjectNote | ContactNote) {
  let lastName = item.user?.lastName;
  if (!lastName && 'authorLastName' in item) {
    lastName = item.authorLastName;
  }
  return lastName;
}
