import { ApiResponse, ExtendedContactProject } from '@betterleap/client';
import { getFullName, Types } from '@betterleap/shared';
import {
  Box,
  Col,
  IconButtonProps,
  Link,
  Row,
  showToast,
  Text,
  Tooltip,
  useModal,
} from '@betterleap/ui';
import {
  AlertModal,
  AlertModalProps,
} from 'components/modules/Modal/AlertModal/AlertModal';
import {
  ConfirmModal,
  ConfirmModalProps,
} from 'components/modules/Modal/ConfirmModal/ConfirmModal';
import { resolveContactPrimaryEmail } from 'helper/resolveContactPrimaryEmail';
import useAnalytics from 'hooks/analytics';
import { apiClient } from 'lib/apiClient';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Row as TableRow } from 'react-table';
import { ProjectActionButton } from '../../ProjectActions';
import {
  selectContacts,
  useMemoizedContacts,
} from '../../ProjectDetails.functions';

export interface RestartSequenceButtonProps
  extends Omit<IconButtonProps, 'label'> {
  selectedContacts: TableRow<Record<string, unknown>>[] | 'all';
  projectContacts: ApiResponse<ExtendedContactProject[]> | undefined;
  projectId: string;
  sequenceId: string;
}

const EmailsNeedUpdatingList = ({
  contacts,
}: {
  contacts: ExtendedContactProject[];
}) => {
  const { pathname } = useLocation();

  return (
    <Box
      as='ul'
      css={{
        listStyle: 'none',
        px: 0,
        borderTop: '1px solid $border-light',
        borderBottom: '1px solid $border-light',
        width: '100%',
        my: 24,
      }}
    >
      {contacts.map((contact) => {
        const currentEmail = resolveContactPrimaryEmail(
          contact,
          contact.contactEmailEnrichmentMode,
        );
        return (
          <Row
            as='li'
            key={contact.id}
            css={{
              '& + &': {
                borderTop: '1px solid $border-light',
              },
              py: 8,
            }}
          >
            <Col span={4}>
              <Link
                css={{ fontSize: '$xs', fontWeight: '$medium' }}
                target='_blank'
                href={`${pathname}#resultId="${contact.id}"`}
              >
                {getFullName(contact)}
              </Link>
            </Col>
            <Col span={4}>
              <Text size='xs' css={{ color: '$text-secondary' }}>
                {currentEmail ?? '--'}
              </Text>
            </Col>
            <Col span={4}>
              <Text
                size='xs'
                align='right'
                css={{ color: '$danger-base', fontStyle: 'italic' }}
              >
                {!currentEmail ? 'No Email' : 'Bounced'}
              </Text>
            </Col>
          </Row>
        );
      })}
    </Box>
  );
};

export const RestartSequenceButton = ({
  css,
  selectedContacts,
  projectContacts,
  projectId,
  sequenceId,
  ...props
}: RestartSequenceButtonProps) => {
  const openAlertModal = useModal<AlertModalProps>(AlertModal);
  const openConfirmModal = useModal<ConfirmModalProps>(ConfirmModal);
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  const contacts = useMemoizedContacts(projectContacts?.data);
  const selected = selectContacts(selectedContacts, contacts);

  const bounced = selected.filter(
    (c) => c.status === ExtendedContactProject.status.BOUNCED,
  );

  const restartSequence = useMutation(
    () =>
      apiClient.project.restartSequence({
        requestBody: {
          contactIds: bounced.map((c) => c.id),
          projectId,
          sequenceId,
        },
      }),
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['get_project_with_stats', projectId]);

        queryClient.invalidateQueries(['get_project_contacts', projectId]);

        showToast({
          title: 'Sequence Restarted',
          description: `Successfully restarted the sequence for ${
            result.data?.restartedCount ?? 0
          } contacts.`,
          variant: 'success',
        });
      },
      onError: () => {
        showToast({
          title: 'Sequence Restart Failed',
          description: `Something went wrong. Please try again.`,
          variant: 'danger',
        });
      },
    },
  );

  const handleRestartSequence = async () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Restart Sequence',
      buttonLocation: `Project Details`,
    });

    const emailsThatNeedToBeChange: ExtendedContactProject[] = [];

    bounced.forEach((c) => {
      const currentContactEmail = resolveContactPrimaryEmail(
        c,
        c.contactEmailEnrichmentMode,
      );
      if (!currentContactEmail || c.sentToEmail === currentContactEmail) {
        emailsThatNeedToBeChange.push(c);
      }
    });

    if (emailsThatNeedToBeChange.length) {
      await openAlertModal({
        icon: {
          name: 'exclamation-circle',
          variant: 'danger',
        },
        title: 'Emails Need Updating',
        description: `Before restarting this sequence, you need to update the email addresses for the following contacts.`,
        notices: <EmailsNeedUpdatingList contacts={emailsThatNeedToBeChange} />,
        confirmationText: 'Okay',
        size: 'medium',
      });

      return;
    }

    const confirm = await openConfirmModal({
      icon: {
        name: 'exclamation-circle',
        variant: 'warning',
      },
      title: 'Restart Sequence',
      description: `Are you sure you want to restart this sequence for ${
        bounced.length
      } ${bounced.length > 1 ? 'contacts' : 'contact'}?`,
      confirmationText: 'Restart',
      size: 'medium',
    });

    if (confirm) {
      restartSequence.mutate();
    }
  };

  return (
    <Tooltip content='Restart bounced sequence'>
      <ProjectActionButton
        onClick={handleRestartSequence}
        disabled={!bounced.length}
        css={{ pr: 14, ...css }}
        label={`Restart bounced sequence`}
        name='reset'
        {...props}
      />
    </Tooltip>
  );
};
