import { Box, BoxProps } from '@betterleap/ui';

export const Overlay = ({ css, ...rest }: BoxProps) => (
  <Box
    css={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#000000',
      zIndex: '$overlay',
      opacity: 0.15,
      ...css,
    }}
    {...rest}
  />
);
