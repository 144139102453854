import { SignInButton } from '@betterleap/shared';
import { Sender } from '@betterleap/client';
import {
  AlertIcon,
  Box,
  Flex,
  Modal,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';

export interface ConnectEmailModalProps {
  provider?: Sender.provider;
}

export const ConnectEmailModal: PromiseModal<ConnectEmailModalProps> = ({
  provider,
  onSubmit,
}) => {
  const providers: Sender.provider[] = [
    Sender.provider.GOOGLE,
    Sender.provider.MICROSOFT,
  ].filter((prov) => (provider ? prov === provider : true));

  return (
    <Modal>
      <Flex css={{ alignItems: 'center' }} vertical>
        <Box css={{ py: 16 }}>
          <AlertIcon variant='warning' name='exclamation-circle' />
        </Box>
        <ModalTitle>Connect your email account</ModalTitle>
        <Text size='sm' css={{ color: '$text-lightest', textAlign: 'center' }}>
          In order to send your email sequences, we need you to connect your
          email account to share permissions to allow us to send emails on your
          behalf.
        </Text>
        <Flex
          justify='center'
          css={{ flexDirection: 'column', gap: 8, pt: 24, width: '100%' }}
        >
          {providers.map((prov) => (
            <SignInButton
              key={prov}
              provider={prov}
              onClick={() => onSubmit(prov)}
            />
          ))}
        </Flex>
      </Flex>
    </Modal>
  );
};
