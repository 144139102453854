import {
  Box,
  Combobox,
  Option,
  Select,
  SelectItem,
  Text,
} from '@betterleap/ui';
import { debounce, escape, uniqBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { apiClient } from 'lib/apiClient';
import { FilterItem } from './SourceCandidates.types';
import {
  betterStartCase,
  filterItemsEqual,
} from './SourceCandidates.functions';
import {
  SourceCandidatesMultiSelectFilterProps,
  SourcingFilterBadges,
  SourcingFilterTitle,
} from './SourceCandidates.components';

export interface FriendlyFilterItem extends FilterItem {
  text: string;
  id: string;
}

export const useAutocompleteOptions = ({
  field,
  selected,
}: {
  field: string;
  selected: FilterItem[];
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debounceSearch = useCallback(debounce(setSearchTerm, 200), []);

  const { data: filterAutoCompleteResponse, isLoading } = useQuery(
    ['discover_candidates_autocomplete', { name: searchTerm, field }],
    () =>
      apiClient.contact.discoverContactsFieldAutocomplete({
        field: field,
        text: searchTerm,
      }),
    { enabled: searchTerm?.length > 0 },
  );

  const options: FriendlyFilterItem[] = useMemo(() => {
    const opts = (filterAutoCompleteResponse?.data ?? [])
      .filter((v) => !selected.some((f) => filterItemsEqual(v, f)))
      .map((option, index) => ({
        ...option,
        text: betterStartCase(option.name),
        id: `${escape(option.name)}-${index}`,
      }));

    // pdl can return duplicate options. need to filter them out.
    return uniqBy(opts, 'name');
  }, [selected, filterAutoCompleteResponse]);

  return {
    options,
    isLoading,
    setSearchTerm: debounceSearch,
  };
};

export const SourceCandidatesMultiSelectFilter = (
  params: SourceCandidatesMultiSelectFilterProps,
) => {
  const {
    title,
    field,
    currentFilter,
    onFilterUpdated,
    selectValues,
    placeholder,
    css,
  } = params;
  const placeholderText = placeholder || `Enter ${title}`;

  const myFilter = currentFilter || [];

  const { options, isLoading, setSearchTerm } = useAutocompleteOptions({
    field,
    selected: myFilter,
  });

  return (
    <Box css={{ width: '100%', ...css }}>
      {!!title && <SourcingFilterTitle title={title} />}
      <SourcingFilterBadges
        items={myFilter}
        canExclude={params.canExclude}
        onFilterUpdated={onFilterUpdated}
      />
      {!selectValues ? (
        <Combobox
          aria-labelledby={`filter-${title}`}
          id={`filter-${field}`}
          placeholder={placeholderText}
          options={options}
          loading={isLoading}
          value={[]}
          clearable={false}
          onChange={(keys, selected) => {
            const newFilter = myFilter
              .filter((v) => !selected.some((s) => filterItemsEqual(v, s)))
              .concat(selected);

            onFilterUpdated(newFilter);
            setSearchTerm('');
          }}
          onFilterChange={setSearchTerm}
          css={{ '& .combobox-toggle-open-button': { visibility: 'hidden' } }}
        >
          {(filterItem: FriendlyFilterItem) => (
            <Option key={filterItem.id} css={{ p: 8, px: 16 }}>
              <div>
                <Text size='sm'>{filterItem.text}</Text>
              </div>
            </Option>
          )}
        </Combobox>
      ) : (
        <Select
          size='sm'
          aria-labelledby={`filter-${title}`}
          value=''
          placeholder={placeholderText}
          css={{ color: '$text-placeholder', height: 40 }}
          onChange={(value) => {
            const newFilter = myFilter.filter((s) => s.name !== value);
            newFilter.push({ name: value, field });
            onFilterUpdated(newFilter);
          }}
        >
          {selectValues
            .filter((v) => !myFilter.some((f) => filterItemsEqual(v, f)))
            .map((v) => (
              <SelectItem size='sm' key={v.name} value={v.name}>
                {v.name}
              </SelectItem>
            ))}
        </Select>
      )}
    </Box>
  );
};
