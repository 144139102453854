import React from 'react';
import { User } from '@betterleap/client';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import routeNames from '../../../constants/routeNames';
import TOKEN from '../../../lib/token';
import { selectMe } from '../../../store/api/selectors';

type OrganizationRouteProps = {
  element: JSX.Element;
};

const OrganizationRoute = ({ element }: OrganizationRouteProps) => {
  const me = useSelector(selectMe);
  const location = useLocation();
  const isOrganizationUser =
    !!me.data &&
    [User.role.COMPANY_ADMIN, User.role.RECRUITER].includes(me.data?.role) &&
    !!me.data.organizationId;

  const routeComponent = () => {
    if (!TOKEN.get() || !!me.error || (me.data && !isOrganizationUser)) {
      return <Navigate to={routeNames.home()} state={location.state} />;
    }
    if (isOrganizationUser) return element;
    return <></>;
  };

  return routeComponent();
};

export default OrganizationRoute;
