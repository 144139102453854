import { Badge, Flex, FlexProps, Icon, Text } from '@betterleap/ui';
import { Link } from 'react-router-dom';

export interface SuggestedContactsLinkProps extends FlexProps {
  projectId: string;
  suggestedContactsCount: number;
}

export const SuggestedContactsLink = ({
  suggestedContactsCount,
  css,
  projectId,
  ...rest
}: SuggestedContactsLinkProps) => {
  return (
    <Link
      onClick={(e) => e.stopPropagation()}
      to={`/projects/${projectId}?tab=recommended-matches`}
    >
      <Flex css={{ gap: 4, ...css }} {...rest}>
        <Badge shape='rectangle' size='sm'>
          {suggestedContactsCount}
        </Badge>
        <Text variant='link' css={{ fontSize: '$xs' }}>
          recommended matches
        </Text>
        <Icon name='arrow-narrow-right' color='$blue-600' size={14} />
      </Flex>
    </Link>
  );
};
