import { Spinner, useMe } from '@betterleap/shared';
import {
  UpdateOrganizationSettingsDto,
  UpdateUserEmailSettingsDto,
} from '@betterleap/client';
import {
  Box,
  BoundCheckbox as Checkbox,
  Flex,
  FlexProps,
  Form,
  BoundInput as Input,
  showToast,
  Text,
  TextProps,
} from '@betterleap/ui';
import merge from 'lodash/merge';
import { FeatureFlagKey, useFeatureFlag } from 'hooks/useFeatureFlag';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import Line from 'components/elements/Line/Line';
import { apiClient } from '../../../lib/apiClient';
import SignatureSetting from './SignatureSetting';

const MAX_MIN_SECONDS_BETWEEN_EMAILS = 60 * 60;

interface EmailFallbackSetting {
  enabled: boolean;
  field: 'usePersonalEmailFallback' | 'useBusinessEmailFallback';
  projectType: 'sales' | 'recruiting';
  fallbackEmailType: 'personal' | 'business';
  primaryEmailType: 'business' | 'personal';
}

export const SettingRow = ({ css, ...props }: FlexProps) => {
  return (
    <Flex
      css={merge(
        {
          flexDirection: 'column',
          alignItems: 'flex-start',
          mediaMd: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          },
          width: '100%',
          mt: '2rem',
        },
        css,
      )}
      {...props}
    />
  );
};

export const SettingRowTitle = ({ css, ...props }: TextProps) => {
  return (
    <Text
      css={{
        fontSize: '$sm',
        lineHeight: '$lg',
        color: '$neutral-blue-900',
        fontWeight: '$medium',
        mb: 8,
        ...css,
      }}
      {...props}
    />
  );
};

export const SettingRowDescription = ({ css, ...props }: TextProps) => {
  return (
    <Text
      css={{
        fontSize: '$sm',
        lineHeight: '$lg',
        fontWeight: '$normal',
        color: '$neutral-blue-700',
        ...css,
      }}
      {...props}
    />
  );
};

const EmailSendingSettings = () => {
  const hasCandidateMessaging = useFeatureFlag(
    FeatureFlagKey.CANDIDATE_MESSAGING,
  );
  const { handleSubmit, control, reset, formState, trigger } = useForm<{
    emailOptionIncludeUnsubscribeLink: boolean;
    emailOptionMaxEmailsPerDay: number;
    emailOptionMinSecondsBetweenEmails: number;
    usePersonalEmailFallback: boolean;
    useBusinessEmailFallback: boolean;
    enabledEmailFooterChatLink: boolean;
  }>({
    // mode: 'onBlur',
    defaultValues: {
      emailOptionIncludeUnsubscribeLink: false,
      emailOptionMaxEmailsPerDay: 400,
      emailOptionMinSecondsBetweenEmails: 60,
      usePersonalEmailFallback: false,
      useBusinessEmailFallback: false,
      enabledEmailFooterChatLink: false,
    },
  });

  const me = useMe({
    onSuccess: () => {
      if (!formState.isDirty) {
        resetForm();
      }
    },
  });

  const resetForm = () => {
    reset({
      emailOptionIncludeUnsubscribeLink:
        me.user?.emailOptionIncludeUnsubscribeLink,
      usePersonalEmailFallback:
        me.user?.organization?.usePersonalEmailFallback ?? false,
      useBusinessEmailFallback:
        me.user?.organization?.useBusinessEmailFallback ?? false,
      emailOptionMinSecondsBetweenEmails:
        me.user?.emailOptionMinSecondsBetweenEmails,
      emailOptionMaxEmailsPerDay: me.user?.emailOptionMaxEmailsPerDay,
      enabledEmailFooterChatLink:
        me.user?.organization?.settings?.enabledEmailFooterChatLink ?? false,
    });
  };

  const handleOnSuccess = () => {
    me.refetch();
  };

  const handleOnError = () => {
    if (me.user) {
      resetForm();
    }

    showToast({
      variant: 'danger',
      title: 'Oops, Something went wrong',
      description: 'Please try again',
    });
  };

  const updateUser = useMutation(
    (data: { requestBody: UpdateUserEmailSettingsDto }) =>
      apiClient.user.updateMe(data),
    {
      onSuccess: () => {
        handleOnSuccess();
      },
      onError: () => {
        handleOnError();
      },
    },
  );

  const updateUserSettings = useMutation(
    (data: UpdateOrganizationSettingsDto) =>
      apiClient.organization.updateSettings({ requestBody: data }),
    {
      onSuccess: () => {
        handleOnSuccess();
      },
      onError: () => {
        handleOnError();
      },
    },
  );

  if (me.isLoading) {
    return (
      <Flex justify='center' align='center' css={{ height: '4rem' }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }

  const user = me?.user;
  if (!user) {
    return <></>;
  }

  const saveSettings = (
    data: UpdateUserEmailSettingsDto & UpdateOrganizationSettingsDto,
  ) => {
    const {
      usePersonalEmailFallback,
      useBusinessEmailFallback,
      enabledEmailFooterChatLink,
      ...restData
    } = data;

    updateUser.mutate({
      requestBody: restData,
    });

    updateUserSettings.mutate({
      usePersonalEmailFallback,
      useBusinessEmailFallback,
      enabledEmailFooterChatLink,
    });
  };

  const emailFallbackSettings: EmailFallbackSetting[] = [
    {
      enabled: !!me.user?.organization?.features?.enabledSalesProjects,
      field: 'usePersonalEmailFallback',
      projectType: 'sales',
      fallbackEmailType: 'personal',
      primaryEmailType: 'business',
    },
    {
      enabled: true,
      field: 'useBusinessEmailFallback',
      projectType: 'recruiting',
      fallbackEmailType: 'business',
      primaryEmailType: 'personal',
    },
  ];

  return (
    <Flex vertical>
      <Form control={control} onSubmit={handleSubmit(saveSettings)}>
        <SettingRow>
          <Text
            as='h2'
            css={{
              fontWeight: '$semibold',
              fontSize: '$lg',
              lineHeight: '$3xl',
              color: '$neutral-blue-900',
              marginTop: '3rem',
            }}
          >
            Email Sending
          </Text>
          <Flex
            css={{
              flexDirection: 'column',
              alignItems: 'flex-end',
              mediaMd: {
                justifyContent: 'end',
                flexDirection: 'row',
                alignItems: 'center',
              },
            }}
          >
            {updateUser.isLoading || updateUserSettings.isLoading ? (
              <Flex justify='center' align='center' css={{ height: '3rem' }}>
                <Spinner variant='blue' />
              </Flex>
            ) : (
              <></>
            )}
          </Flex>
        </SettingRow>
        <SettingRow>
          <Box>
            <SettingRowTitle>Unsubscribe link</SettingRowTitle>
            <SettingRowDescription>
              This adds and unsubscribe link at the bottom of your messages
            </SettingRowDescription>
          </Box>
          <Flex css={{ mediaSm: { pt: 10 } }}>
            <Checkbox
              id='emailOptionIncludeUnsubscribeLink'
              name='emailOptionIncludeUnsubscribeLink'
              onChange={() => {
                handleSubmit(saveSettings)();
              }}
            >
              Add unsubscribe link
            </Checkbox>
          </Flex>
        </SettingRow>
        <Line />
        {hasCandidateMessaging && (
          <>
            <SettingRow>
              <Box>
                <SettingRowTitle>Chat Link</SettingRowTitle>
                <SettingRowDescription>
                  Includes a link to your organization's chat workspace in
                  outbound emails.
                </SettingRowDescription>
              </Box>
              <Flex css={{ mediaSm: { pt: 10 } }}>
                <Checkbox
                  id='enabledEmailFooterChatLink'
                  name='enabledEmailFooterChatLink'
                  onChange={() => {
                    handleSubmit(saveSettings)();
                  }}
                >
                  Add chat link
                </Checkbox>
              </Flex>
            </SettingRow>
            <Line />
          </>
        )}
        <SettingRow>
          <Box>
            <SettingRowTitle>
              Maximum number of emails to send per day
            </SettingRowTitle>
            <SettingRowDescription>
              This sets the daily number of emails to be sent for your email
              account
            </SettingRowDescription>
          </Box>
          <Flex css={{ mediaSm: { pt: 10 } }}>
            <Input
              id='emailOptionMaxEmailsPerDay'
              name='emailOptionMaxEmailsPerDay'
              aria-label='Maximum emails to send per day'
              type='number'
              rules={{
                min: { value: 0, message: 'Must be at least 0' },
                max: { value: 1000, message: `Cannot be more than 1000` },
                required: true,
              }}
              onKeyUp={() => trigger('emailOptionMaxEmailsPerDay')}
              onBlur={handleSubmit(saveSettings)}
              css={{ width: 160 }}
              placeholder='Enter number'
            />
          </Flex>
        </SettingRow>
        <Line />
        <SettingRow>
          <Box>
            <SettingRowTitle>
              Minimum seconds to wait between emails
            </SettingRowTitle>
            <SettingRowDescription>
              This sets the delay between emails to be sent in seconds
            </SettingRowDescription>
          </Box>
          <Flex css={{ mediaSm: { pt: 10 } }}>
            <Input
              id='emailOptionMinSecondsBetweenEmails'
              name='emailOptionMinSecondsBetweenEmails'
              aria-label='Minimum seconds between emails'
              type='number'
              rules={{
                min: { value: 30, message: 'Must be at least 30' },
                max: {
                  value: MAX_MIN_SECONDS_BETWEEN_EMAILS,
                  message: `Cannot be more than ${MAX_MIN_SECONDS_BETWEEN_EMAILS}`,
                },
                required: 'This field is required',
              }}
              onKeyUp={() => trigger('emailOptionMinSecondsBetweenEmails')}
              onBlur={handleSubmit(saveSettings)}
              css={{ width: 160 }}
              placeholder='Enter seconds'
            />
          </Flex>
        </SettingRow>
        {emailFallbackSettings.map((setting) => {
          if (!setting.enabled) {
            return null;
          }
          return (
            <>
              <Line />
              <SettingRow key={setting.field}>
                <Box>
                  <SettingRowTitle>
                    Fallback to available {setting.fallbackEmailType} email
                    addresses
                  </SettingRowTitle>
                  <SettingRowDescription>
                    In {setting.projectType} projects, this will send to a
                    contact's {setting.fallbackEmailType} email address when a{' '}
                    {setting.primaryEmailType} email is not available.
                  </SettingRowDescription>
                </Box>
                <Flex css={{ mediaSm: { pt: 10 } }}>
                  <Checkbox
                    id={setting.field}
                    name={setting.field}
                    onChange={() => {
                      handleSubmit(saveSettings)();
                    }}
                  >
                    Send to available {setting.fallbackEmailType} emails
                  </Checkbox>
                </Flex>
              </SettingRow>
            </>
          );
        })}
      </Form>
      <SignatureSetting />
    </Flex>
  );
};

export default EmailSendingSettings;
