import {
  ExtendedContactProject,
  ProjectWithStatsDto,
  User,
} from '@betterleap/client';
import { TableColumn, Types, useMe } from '@betterleap/shared';
import { logToSegment } from 'functions/segmentLog';
import { BoxProps } from '@betterleap/ui';
import { isSalesProject } from '../ProjectDetails.functions';
import { useRemoveContacts } from '../Hooks/useRemoveContacts';
import { InMailStatusSelector } from './InMailStatusSelector';
import { InterestedStatusSelector } from './InterestedStatusSelector';
import { useSetInMailStatus } from './ActionsMenu/useSetInMailStatus';
import { useSetInterested } from './ActionsMenu/useSetInterested';
import { useSubmitCandidate } from './ActionsMenu/useSubmitCandidate';
import { useUpdateContact } from './ActionsMenu/useUpdateContact';

type ActionTableColumn = TableColumn & {
  id: string;
  width: number;
  headerCss: BoxProps['css'];
  css: BoxProps['css'];
  onclick: () => void;
  options: {
    label: string;
    renderItem?: (contact: ExtendedContactProject) => JSX.Element;
    onClick?: (contact: ExtendedContactProject) => void;
    color?: string;
  }[];
};

export const useContactActionMenu = (
  props: ContactActionMenuProps,
): ActionTableColumn => {
  return {
    Header: '',
    id: 'action-menu',
    slot: 'actions',
    width: 50,
    headerCss: {
      maxWidth: 84,
    },
    css: {
      display: 'flex',
      maxWidth: 84,
      justifyContent: 'flex-end',
      '& button': {
        fill: '$neutral-blue-600',
      },
    },
    onclick: () => {
      logToSegment(Types.SEGMENT_EVENTS.KEBAB_MENU_CLICKED);
    },
    accessor: (contact: ExtendedContactProject) => contact,
    options: useConstructActions(props),
  };
};

const useConstructActions = (p: ContactActionMenuProps) => {
  const me = useMe();
  const options = [
    { action: useSetInMailedAction(p), enabled: true },
    { action: useSetInterestedAction(p), enabled: true },
    { action: useEditContactAction(p), enabled: true },
    { action: useSubmitAction(p), enabled: isRecruiting(p.project, me.user) },
    { action: useRemoveContactAction(p), enabled: true },
  ];
  return options.filter(({ enabled }) => enabled).map(({ action }) => action);
};

function isRecruiting(project: ProjectWithStatsDto, user?: User) {
  return user?.role === User.role.RECRUITER && !isSalesProject(project);
}

const useSetInMailedAction = (props: ContactActionMenuProps) => {
  const { setInMailStatus } = useSetInMailStatus({
    onStatusUpdated: props.onInMailStatusUpdated,
  });
  return {
    label: 'INMAIL STATUS',
    renderItem: (contact: ExtendedContactProject) => (
      <InMailStatusSelector
        contact={contact}
        onChange={(checked: boolean, value?: string) => {
          setInMailStatus(contact, checked, value);
        }}
      />
    ),
  };
};

const useSetInterestedAction = (props: ContactActionMenuProps) => {
  const { setInterested } = useSetInterested({
    onStatusUpdated: props.onInterestedStatusUpdated,
  });
  return {
    label: 'INTERESTED',
    renderItem: (contact: ExtendedContactProject) => (
      <InterestedStatusSelector
        contact={contact}
        onChange={(checked, value) => {
          const interested = !checked ? null : value === 'true';
          setInterested(contact.contactProjectId, interested);
        }}
      />
    ),
  };
};

const useEditContactAction = (props: ContactActionMenuProps) => {
  const { updateContact } = useUpdateContact({
    enrichmentMode: props.project.contactEmailEnrichmentMode,
    onContactUpdated: props.onContactEdited,
  });
  return {
    label: 'Edit',
    onClick: updateContact,
  };
};

const useSubmitAction = (props: ContactActionMenuProps) => {
  const { submitCandidate } = useSubmitCandidate({
    project: props.project,
    onCandidateSubmitted: props.onCandidateSubmitted,
  });
  return {
    label: 'Submit Candidate',
    onClick: submitCandidate,
  };
};

const useRemoveContactAction = (props: ContactActionMenuProps) => {
  const { removeFromProject } = useRemoveContacts({
    project: props.project,
    onContactsRemoved: props.onContactRemoved,
  });
  return {
    label: 'Remove',
    color: '#ef4444',
    onClick: removeFromProject,
  };
};

interface ContactActionMenuProps {
  project: ProjectWithStatsDto;
  onInMailStatusUpdated: () => void;
  onInterestedStatusUpdated: () => void;
  onContactEdited: () => void;
  onCandidateSubmitted: () => void;
  onContactRemoved: () => void;
}
