import { Flex, Text } from '@betterleap/ui';
import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { highlightsAtom } from '../useHighlightWords';
import { SourcingResultSection } from './SourcingResult.elements';

interface SectionProps {
  languages: string[];
  center?: boolean;
}
interface EntryProps {
  language: string;
  isFirst: boolean;
}

export const SourcingResultLanguages = ({
  languages,
  center = false,
}: SectionProps) => {
  const [highlights] = useAtom(highlightsAtom);

  const matchedLanguages = useMemo(() => {
    return (
      highlights?.languageNames?.reduce((acc, language) => {
        acc[language.toLowerCase()] = true;
        return acc;
      }, {} as { [key: string]: boolean }) || {}
    );
  }, [highlights?.languageNames]);

  const sortedLanguages = useMemo(
    () =>
      languages.sort((a, b) => {
        const aMatched = !!matchedLanguages[a.toLowerCase()];
        const bMatched = !!matchedLanguages[b.toLowerCase()];
        if (aMatched && !bMatched) return -1;
        if (!aMatched && bMatched) return 1;
        return 0;
      }),
    [languages, matchedLanguages],
  );

  return (
    <SourcingResultSection
      title='Languages'
      icon='translate'
      elements={sortedLanguages}
      collapsedDisplayMax={5}
      collapsingType='tooltip'
      direction='row'
      center={center}
      constructElement={(language, index) => (
        <LanguageEntry language={language} isFirst={index === 0} />
      )}
    />
  );
};

const LanguageEntry = ({ language, isFirst }: EntryProps) => (
  <Flex key={language} css={{ gap: 4 }}>
    {!isFirst && (
      <Text css={{ fontSize: '$sm', color: '$neutral-blue-700' }}>•</Text>
    )}
    <Text
      css={{
        fontSize: '$sm',
        color: '$neutral-blue-700',
      }}
    >
      {language}
    </Text>
  </Flex>
);
