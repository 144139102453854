import {
  AlertIcon,
  Button,
  Flex,
  Modal,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';

export const CandidateSubmitSuccessModal: PromiseModal = ({
  onSubmit,
  onDismiss,
}) => (
  <Modal>
    <Flex justify='center'>
      <AlertIcon
        size='lg'
        css={{ mb: 8 }}
        variant='success'
        name='check-circle'
      />
    </Flex>
    <ModalTitle>Candidate Submitted!</ModalTitle>
    <Text
      size='sm'
      css={{ color: '$neutral-blue-600', textAlign: 'center', mb: 12, px: 48 }}
    >
      We sent an email notification to your client.
    </Text>
    <Flex justify='center' css={{ gap: 12, pt: 24, width: '100%' }}>
      <Button onClick={onDismiss} variant='secondary' css={{ width: 120 }}>
        Done
      </Button>
      <Button onClick={onSubmit}>View Candidate</Button>
    </Flex>
  </Modal>
);
