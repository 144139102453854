export const getFullName = (
  parts?: { firstName?: string; lastName?: string },
  defaultName?: string,
): string => {
  const fullName = [parts?.firstName, parts?.lastName]
    .filter((x) => !!x)
    .join(' ');
  // eslint-disable-next-line no-unneeded-ternary
  return fullName ? fullName : defaultName || '';
};

export const makeNamePossessive = (name: string) =>
  name + (name.endsWith('s') ? "'" : "'s");

export const splitName = (name: string) => {
  const parts = name.trim().split(' ');
  const firstName = parts.shift();
  const lastName = parts.join(' ');

  return {
    firstName: firstName,
    lastName: lastName || undefined,
  };
};
