import { useFlags } from 'launchdarkly-react-client-sdk';
import camelCase from 'lodash/camelCase';

export enum FeatureFlagKey {
  HEALTHCARE_NLS_PROMPTS = 'healthcare-nls-prompts',
  HEALTHCARE_SOURCING_FILTERS = 'healthcare-sourcing-filters',
  CANDIDATE_MESSAGING = 'candidate-messaging',
}

export const useFeatureFlag = (flagName: FeatureFlagKey) => {
  const flags = useFlags();
  return flags[camelCase(flagName)];
};
