import { ClearbitLogo } from '@betterleap/shared';
import { EnrichmentJobExperience } from '@betterleap/client';
import { Flex, Text } from '@betterleap/ui';
import { formatDateRange } from 'functions/formatDateRange';

export interface ContactProfileExperienceItemProps {
  experienceData: EnrichmentJobExperience;
}

const ContactProfileExperienceItem = (
  props: ContactProfileExperienceItemProps,
) => {
  const { experienceData } = props;

  const dateRange = formatDateRange(experienceData);

  return (
    <Flex
      align='start'
      css={{
        gap: 20,
        p: '1.5rem',
        height: 114,
        width: '100%',
        border: '1px solid $neutral-blue-300',
        borderRadius: '$4xl',
        backgroundColor: '$background-component',
      }}
    >
      <ClearbitLogo
        domain={experienceData.companyDomain}
        name={experienceData.companyName}
      />
      <Flex vertical>
        <Text
          css={{
            fontWeight: '$medium',
            fontSize: '$xs',
            color: '$text-secondary',
          }}
        >
          {dateRange}
        </Text>
        <Text css={{ fontWeight: '$medium', fontSize: '$base' }}>
          {experienceData.title}
        </Text>
        <Text
          css={{
            fontSize: '$xs',
            color: '$text-secondary',
          }}
        >
          {experienceData.companyName}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ContactProfileExperienceItem;
