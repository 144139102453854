import { ApiClient, OpenAPIConfig } from '@betterleap/client';
import { BetterleapHttpRequest } from './betterleap-http-request';

let apiClient: ApiClient;

export const initializeApiClient = (config: Partial<OpenAPIConfig>) => {
  apiClient = new ApiClient(config, BetterleapHttpRequest);
};

export const getApiClient = () => {
  if (!apiClient) {
    throw new Error(
      'ApiClient not initialized, consumer must first call initializeApiClient()',
    );
  }
  return apiClient;
};
