import {
  Box,
  Form,
  Icon,
  IconButton,
  BoundInput as Input,
  InputContainer,
  showToast,
  Tooltip,
} from '@betterleap/ui';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { SourceContactByLinkedInUrlDto } from '@betterleap/client';
import { linkedInRegex } from '@betterleap/shared';
import { useMutation, useQueryClient } from 'react-query';
import { apiClient } from 'lib/apiClient';

export const AddCandidateByLinkedInSchema = z.object({
  linkedinUrl: z
    .string()
    .regex(linkedInRegex, 'Must be a valid LinkedIn profile'),
});

type AddCandidateByLinkedInForm = z.infer<typeof AddCandidateByLinkedInSchema>;

export const AddCandidateByLinkedIn = () => {
  const queryClient = useQueryClient();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const { id: projectId } = useParams<{ id: string }>();

  const { control, reset, handleSubmit } = useForm<AddCandidateByLinkedInForm>({
    defaultValues: {
      linkedinUrl: '',
    },
    resolver: zodResolver(AddCandidateByLinkedInSchema),
  });

  const sourceContact = useMutation(
    (data: { linkedinUrl: string }) =>
      apiClient.contact.sourceContact({
        requestBody: {
          linkedinUrl: data.linkedinUrl,
          source: SourceContactByLinkedInUrlDto.source.ADD_BY_LINKED_IN_BUTTON,
          projectId,
        },
      }),
    {
      onSuccess: () => {
        reset();
        setHasFocus(false);

        queryClient.invalidateQueries(['get_project_with_stats', projectId]);
        queryClient.invalidateQueries(['get_project_contacts', projectId]);
        showToast({
          variant: 'success',
          title: 'Contact added',
          description:
            'Please allow a few moments while we collect contact information.',
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to add contact. Please try again.',
        });
      },
    },
  );

  const onSubmit = (data: AddCandidateByLinkedInForm) => {
    const match = data.linkedinUrl.match(linkedInRegex) as RegExpMatchArray;

    sourceContact.mutate({
      linkedinUrl: match[0] as string,
    });
  };

  return (
    <Form control={control} onSubmit={handleSubmit(onSubmit)}>
      <Input
        id='linkedinUrl'
        name='linkedinUrl'
        ref={searchRef}
        aria-expanded={hasFocus}
        css={{
          position: 'relative',
          [`& > ${InputContainer}`]: {
            cursor: 'pointer',
            borderRadius: '$xl',
            height: 40,
            width: 40,
            overflowX: 'hidden',
            transition: 'width .4s',
            borderColor: '$neutral-blue-200',
            backgroundColor: '$neutral-blue-200',
          },
          [`& > ${InputContainer}[aria-expanded=false]`]: {
            hover: {
              backgroundColor: '$neutral-blue-400',
              borderColor: '$neutral-blue-400',
            },
          },
          [`& > ${InputContainer}[aria-expanded=true]`]: {
            width: 350,
            backgroundColor: '#FFFFFF',
          },
          '& .betterleap-ui-input-error': {
            position: 'absolute',
            bottom: -22,
          },
          '[aria-expanded=false] + .betterleap-ui-input-error': {
            display: 'none',
          },
        }}
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={(e) => {
          if (e.relatedTarget === submitButtonRef.current) {
            return;
          }

          setHasFocus(false);
        }}
        placeholder='LinkedIn profile url'
        leftIcon={
          <Tooltip content='Add Contact by LinkedIn URL' sideOffset={12}>
            <Box css={{ minWidth: 24 }}>
              <Icon name='linked-in' color='$neutral-blue-700' />
            </Box>
          </Tooltip>
        }
        rightIcon={
          <IconButton
            ref={submitButtonRef}
            label='submit candidate'
            type='submit'
            name='arrow-right'
            size='xs'
            color='$neutral-blue-700'
          />
        }
      />
    </Form>
  );
};
