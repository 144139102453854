import { AlertIcon, Box, Flex, IconProps, Text } from '@betterleap/ui';
import { CsvUploader } from './CsvUploader';
import Section from './Section';

interface StepCardProps {
  stepNumber: number;
  title: string;
  description: string;
  icon: IconProps['name'];
}

const StepCard = ({ stepNumber, title, description, icon }: StepCardProps) => {
  return (
    <Box
      css={{
        p: 0,
        borderRadius: 24,
        '& + &': { mt: 24 },
        firstChild: {
          p: 1,
          background:
            'linear-gradient(50deg, #A5F8FD 5.91%, #8B5CF6 10.7%, #FF8A8A 14.36%, #6297EC 19.63%, #18D9E6 35.97%, #C8B7EF 58.57%, #EC4899 63.77%, #48ECD8 67.64%, #758CEC 78.29%, #8B5CF6 83.62%)',
          '& > div': {
            borderWidth: 0,
          },
        },
      }}
    >
      <Flex
        css={{
          gap: 18,
          p: 24,
          backgroundColor: '$purple-100',
          border: '1px solid $purple-500',
          borderRadius: 24,
        }}
      >
        <Box css={{ flex: 1 }}>
          <Text size='xs' css={{ color: '$text-tertiary' }}>
            STEP {stepNumber}
          </Text>
          <Text css={{ fontWeight: '$medium', my: 8 }}>{title}</Text>
          <Text size='sm' css={{ color: '$text-tertiary' }}>
            {description}
          </Text>
        </Box>
        <AlertIcon
          name={icon}
          size='lg'
          variant='purple'
          shape='rounded-square'
        />
      </Flex>
    </Box>
  );
};

const steps: StepCardProps[] = [
  {
    stepNumber: 1,
    title: 'Upload your CSV',
    description: 'Upload your list of candidates.',
    icon: 'upload',
  },
  {
    stepNumber: 2,
    title: 'Provide your email address',
    description: 'Share your email address.',
    icon: 'mail',
  },
  {
    stepNumber: 3,
    title: 'Get emails from your candidates',
    description: 'Get a list of candidate emails delivered to your inbox.',
    icon: 'sparkles',
  },
];

const CtaSection = (): JSX.Element => {
  return (
    <Section
      css={{
        px: 0,
      }}
    >
      <Flex
        css={{
          gap: 40,
          flexDirection: 'column',
          mediaLg: {
            gap: 120,
            flexDirection: 'row',
          },
        }}
      >
        <Box css={{ flex: 1, px: 24, mediaLg: { px: 0 } }}>
          <Text
            css={{
              fontSize: 38,
              lineHeight: '120%',
              mb: 20,
              mediaLg: { fontSize: 56, whiteSpace: 'nowrap' },
            }}
          >
            Unlock{' '}
            <Text as='span' inherit css={{ fontWeight: '$medium' }}>
              Free
              <br /> Verified Emails
              <br />
            </Text>{' '}
            of Your Candidates
          </Text>
          <Text
            size='xl'
            css={{
              color: '$gray-500',
              lineHeight: '170%',
              fontWeight: '$normal',
              mb: 24,
              mediaLg: {
                mb: 34,
                lineHeight: '28px',
              },
            }}
          >
            Upload LinkedIn links of your candidates and retrieve email
            addresses using our free tool.
          </Text>
          <Box css={{ p: 22 }}>
            <CsvUploader />
          </Box>
        </Box>
        <Box
          css={{
            backgroundColor: '$purple-100',
            flex: 1,
            p: 24,
            width: '100%',
            mediaLg: { borderRadius: 40, mx: 0, p: 64 },
          }}
        >
          {steps.map((step) => (
            <StepCard key={step.stepNumber} {...step} />
          ))}
        </Box>
      </Flex>
    </Section>
  );
};

export default CtaSection;
