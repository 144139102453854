import {
  SeparatorWithText,
  signupWithGoogle,
  signupWithMicrosoft,
  Spinner,
  Types,
} from '@betterleap/shared';
import {
  Box,
  Button,
  Flex,
  Form,
  Icon,
  BoundInput as Input,
  Text,
} from '@betterleap/ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import config from 'lib/config';
import { useSignup } from 'components/templates/SignIn/useSignup';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import useAnalytics from '../../../../hooks/analytics';
import googleIcon from '../../../../assets/images/google_icon.svg';
import microsoftIcon from '../../../../assets/images/microsoft.svg';
import logoPurple from '../../../../assets/images/betterleap_logo_purple.svg';

import TermLink from '../TermLink/TermLink';
import { EmailPasswordForm } from './EmailPasswordForm';

interface UserSignInProps {
  type: 'company' | 'recruiter' | null;
  onSignIn: (data: Record<string, unknown>) => void;
  loading?: boolean;
  confirmationText?: string;
  setIsLoading: (loading: boolean) => void;
  isSignup?: boolean;
}

const UserSignInWithEmailLink = ({
  type,
  onSignIn,
  loading,
  confirmationText,
  setIsLoading,
  isSignup,
}: UserSignInProps): JSX.Element => {
  const { width, height } = useWindowDimensions();
  const { control, handleSubmit } = useForm<{
    email: string;
    password: string;
  }>();

  const { track } = useAnalytics();

  const showSignup = !!type || isSignup || false;

  const trackEvent = showSignup
    ? Types.ANALYTICS_CLIENT_EVENT.SIGNUP_STARTED
    : Types.ANALYTICS_CLIENT_EVENT.LOGIN_STARTED;

  const handleGoogleClick = () => {
    track(trackEvent, { authProvider: 'google.com' });
    signupWithGoogle(
      setIsLoading,
      config.environment === 'development',
      ({ mappedUser }) => signup(mappedUser),
    );
  };

  const handleMicrosoftClick = () => {
    track(trackEvent, { authProvider: 'microsoft.com' });
    signupWithMicrosoft(
      setIsLoading,
      config.environment === 'development',
      ({ mappedUser }) => signup(mappedUser),
    );
  };

  const { signup } = useSignup();

  return (
    <>
      <Flex
        css={{
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box css={{ margin: height > 675 ? 48 : 24 }}>
          <Box as='img' src={logoPurple} alt='logo' css={{ width: 54 }} />
        </Box>
        <Text
          as='h1'
          css={{
            fontSize: 42,
            color: 'white',
            fontFamily: '$gilroy',
            lineHeight: 1.05,
            textAlign: 'center',
            marginBottom: '1.5rem',
          }}
        >
          Welcome to Betterleap
        </Text>
        <Text
          css={{
            color: 'white',
            fontSize: 14,
            lineHeight: 1.5,
            textAlign: 'center',
            marginBottom: '1.5rem',
          }}
        >
          Your all-in-one recruiting platform to source, engage and convert
          candidates
        </Text>

        <Box
          css={{
            width: '100%',
            paddingTop: height > 700 ? '2.25rem' : '0',
            paddingBottom: '1.25rem',
          }}
        >
          {!process.env.REACT_APP_MAGIC_LINK_SIGNUP_DISABLED && (
            <Box css={{ maxWidth: 440, margin: 'auto' }}>
              <Form
                /* eslint-disable @typescript-eslint/no-explicit-any */
                control={control as any}
                css={{ maxWidth: 360, margin: 'auto' }}
                onSubmit={handleSubmit(onSignIn)}
              >
                <Input
                  placeholder='Email Address'
                  type='email'
                  name='email'
                  id='email'
                  required
                />
                <Button full onClick={handleSubmit(onSignIn)}>
                  {loading ? <Spinner variant='white' /> : 'Sign In'}
                </Button>
              </Form>
              {confirmationText && (
                <Text
                  css={{
                    color: 'white',
                    paddingLeft: width > 768 ? '2rem' : '1rem',
                    paddingRight: width > 768 ? '2rem' : '1rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '.875rem',
                    lineHeight: '1.25rem',
                    textAlign: 'center',
                    marginBottom: width > 768 ? '1.5rem' : '1rem',
                    marginTop: width > 768 ? '1.5rem' : '1rem',
                  }}
                >
                  {confirmationText}
                </Text>
              )}
              <SeparatorWithText
                className='px-4 tablet:px-9'
                text='Or continue with'
              />
            </Box>
          )}
          <EmailPasswordForm
            control={control}
            handleSubmit={handleSubmit}
            isSignup={showSignup}
          />
          <Flex vertical css={{ gap: 24, alignItems: 'center' }}>
            <Button
              variant='ghost'
              onClick={handleGoogleClick}
              css={{
                borderRadius: 16,
                padding: '24px 24px',
                maxWidth: 265,
                width: '100%',
              }}
            >
              <Box
                as='img'
                css={{ maxWidth: 22, mr: 12 }}
                src={googleIcon}
                alt='google_icon'
              />
              &nbsp;
              {showSignup ? 'Sign up with Google' : 'Sign in with Google'}
            </Button>
            <Button
              variant='ghost'
              onClick={handleMicrosoftClick}
              css={{
                borderRadius: 16,
                padding: '24px 24px',
                maxWidth: 265,
                width: '100%',
              }}
            >
              <Box
                as='img'
                css={{ maxWidth: 22, mr: 12 }}
                src={microsoftIcon}
                alt='microsoft_icon'
              />
              &nbsp;
              {showSignup ? 'Sign up with Microsoft' : 'Sign in with Microsoft'}
            </Button>
          </Flex>
          <Flex css={{ justifyContent: 'center' }}>
            <Icon name='information-circle' size={14} css={{ fill: 'white' }} />
            <Text
              css={{
                fontSize: '.75rem',
                lineHeight: '1rem',
                textAlign: 'center',
                color: 'white',
                m: '1rem .25rem',
              }}
            >
              Please use your work email address to{' '}
              {showSignup ? 'sign up' : 'sign in'}.
            </Text>
          </Flex>
        </Box>
      </Flex>
      {showSignup && (
        <Text
          css={{
            bottom: height > 700 ? '2rem' : '.75rem',
            fontSize: '.75rem',
            textAlign: 'center',
            color: '$text-tertiary',
            position: 'absolute',
          }}
        >
          By clicking sign up, you agree to our{' '}
          <TermLink css={{ textDecoration: 'underline' }} /> and{' '}
          <a
            target='_blank'
            href='https://betterleap.com/privacy/'
            style={{ textDecoration: 'underline' }}
            rel='noreferrer'
          >
            Privacy Policy
          </a>
        </Text>
      )}
    </>
  );
};

export default UserSignInWithEmailLink;
