/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { all, AllEffect, call, CallEffect } from 'redux-saga/effects';
import apiSaga from './api/saga';
import appSaga from './app/saga';
import authSaga from './auth/saga';
import organizationSaga from './organization/saga';

/** TODO: apiSaga is undefined when running tests. i have no idea why. */
const sagas = [
  ...appSaga,
  ...(apiSaga ?? []),
  ...authSaga,
  ...organizationSaga,
];

function* root(): Generator<AllEffect<CallEffect<void>>, void, unknown> {
  yield all(sagas.map((saga) => call(saga)));
}

export { root };
