import { EnrichmentCertification } from '@betterleap/client';
import { Flex, Text } from '@betterleap/ui';
import { SourcingResultSection } from './SourcingResult.elements';

interface SectionProps {
  certifications: EnrichmentCertification[];
  markAsViewed: () => void;
}
interface EntryProps {
  entry: EnrichmentCertification;
}

export const SourcingResultCertifications = ({
  certifications,
  markAsViewed,
}: SectionProps) => {
  return (
    <SourcingResultSection
      title='Certifications'
      icon='academic-cap'
      elements={certifications}
      onExpanded={markAsViewed}
      constructElement={(e) => <CertificationsEntry entry={e} />}
    />
  );
};

const CertificationsEntry = ({ entry }: EntryProps) => (
  <Flex align='start' key={JSON.stringify(entry)} css={{ gap: 12 }}>
    {!!entry.name && (
      <Text css={{ fontSize: '$sm', maxWidth: 600 }}>{entry.name}</Text>
    )}
    {!!entry.organization && (
      <Text css={{ fontSize: '$sm', color: '$neutral-blue-700' }}>
        {entry.organization}
      </Text>
    )}
    {!!entry.endDate && (
      <>
        <Text css={{ color: '$neutral-blue-700' }}>•</Text>
        <Text css={{ fontSize: '$sm', color: '$neutral-blue-700' }}>
          {new Date(entry.endDate).getFullYear()}
        </Text>
      </>
    )}
  </Flex>
);
