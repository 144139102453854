import { ExtendedContactProject } from '@betterleap/client';
import { Types } from '@betterleap/shared';
import { IconButtonProps, showToast, Tooltip, useModal } from '@betterleap/ui';
import { Row } from 'react-table';
import {
  ConfirmModal,
  ConfirmModalProps,
} from 'components/modules/Modal/ConfirmModal/ConfirmModal';
import useAnalytics from 'hooks/analytics';
import { apiClient } from 'lib/apiClient';
import { useMutation, useQueryClient } from 'react-query';
import { useSendSequence } from '../Hooks/useSendSequence';
import { ProjectActionButton } from '../../ProjectActions';
import { useProjectContactsFilterParams } from '../../useProjectContactsFilterParams';

export interface SendFirstStepButtonProps
  extends Omit<IconButtonProps, 'label'> {
  selected?: 'all' | Row<Record<string, unknown>>[];
  projectId: string;
  sequenceId: string;
}

export const SendFirstStepButton = ({
  css,
  selected,
  projectId,
  sequenceId,
  ...props
}: SendFirstStepButtonProps) => {
  const { contactProjectQuery } = useProjectContactsFilterParams();
  const openConfirmModal = useModal<ConfirmModalProps>(ConfirmModal);
  const queryClient = useQueryClient();

  const { track } = useAnalytics();

  const elligibleSelected =
    selected === 'all'
      ? undefined
      : selected
          ?.filter((row) => {
            const contact = row.original as ExtendedContactProject;

            return (
              (!contact.currentStepSendAt &&
                contact.status === ExtendedContactProject.status.SCHEDULED &&
                contact.currentStepType ===
                  ExtendedContactProject.currentStepType.EMAIL) ||
              !contact.contactSequenceId
            );
          })
          ?.map((contact) => (contact.original as ExtendedContactProject).id);

  const sendFirstButtonEnabled =
    !!sequenceId && (selected === 'all' || elligibleSelected?.length);

  const sendFirstStep = useMutation(
    () =>
      apiClient.project.sendFirstStep({
        requestBody: {
          contactIds: selected === 'all' ? undefined : elligibleSelected,
          projectId,
          sequenceId,
        },
      }),
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['get_project_contacts', projectId]);

        showToast({
          title: 'Sequence Queued',
          description: `Successfully queued the sequence for ${
            result.data?.affected ?? 0
          } contacts.`,
          variant: 'success',
        });
      },
      onError: () => {
        showToast({
          title: 'Failed to send first step',
          description: `Something went wrong. Please try again.`,
          variant: 'danger',
        });
      },
    },
  );

  const sendSequence = useSendSequence({
    ...contactProjectQuery,
    projectId,
    contactIds:
      selected === 'all'
        ? undefined
        : selected?.map((c) => (c.original as ExtendedContactProject).id),
    showSuccessModal: false,
    onSuccess: () => {
      sendFirstStep.mutate();
    },
  });

  const handleSendFirstStep = async () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Send First Step',
      buttonLocation: `Project Details`,
    });

    const confirm = await openConfirmModal({
      icon: {
        name: 'paper-airplane',
        variant: 'success',
      },
      title: 'Start sequence now',
      description: `Ready to send the first step immediately to the selected contacts?`,
      confirmationText: 'Send',
      size: 'medium',
    });

    if (confirm) {
      sendSequence.sendSequence();
    }
  };

  return (
    <Tooltip content='Send first step now'>
      <ProjectActionButton
        onClick={handleSendFirstStep}
        disabled={!sendFirstButtonEnabled}
        css={{ pr: 14, ...css }}
        label={`Send first sequence step`}
        name='paper-airplane'
        {...props}
      />
    </Tooltip>
  );
};
