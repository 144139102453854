import {
  getFullName,
  sequenceScheduleToValue,
  showToast,
  Spinner,
  StepEditor,
  StepEditorProps,
  StepError,
  Types,
  useUpload,
  useWatchSubject,
  valueToSequenceSchedule,
} from '@betterleap/shared';
import {
  OverrideStepsDto,
  Sender,
  Step,
  StepAttachmentOverrideDto,
  StepOverrideDto,
} from '@betterleap/client';
import {
  BackButton,
  Box,
  Button,
  BoundCheckbox as Checkbox,
  Combobox,
  Flex,
  Form,
  Icon,
  BoundInput as Input,
  Label,
  Option,
  Text,
  useModal,
} from '@betterleap/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FieldError,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import routeNames from '../../../constants/routeNames';
import useFetch from '../../../hooks/fetch';
import usePrompt from '../../../hooks/useLeavePagePrompt';
import api from '../../../lib/api';
import { apiClient } from '../../../lib/apiClient';
import {
  UpdateSequence,
  UpdateSequenceSchema,
} from '../../../schemas/Sequence';
import { Tip } from '../../elements/Tip/Tip';
import {
  AlertModal,
  AlertModalProps,
} from '../../modules/Modal/AlertModal/AlertModal';
import { AddStepButtons } from './AddStepButtons';
import { CreateSequenceEmptyState } from './CreateSequenceEmptyState';
import { PersonalizeSequenceAlert } from './PersonalizeSequenceAlert';
import { SequenceScheduleSelector } from './SequenceScheduleSelector';
import { useGetSequenceWithOverrides } from './useGetSequenceWithOverrides';
import { useSendTestEmail } from './useSendTestEmail';

const resolver = zodResolver(UpdateSequenceSchema);

const EditSequence = (): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const contactSequenceId = searchParams.get('contactSequenceId') ?? '';

  const [, doUpload, ,] = useUpload(
    useFetch,
    `users/{userUid}/public/sequence`,
    'save_sequence_photo',
  );

  const openAlertModal = useModal<AlertModalProps>(AlertModal);

  const {
    data: sequenceResult = [],
    isLoading,
    refetch,
    error: getSequenceError,
  } = useGetSequenceWithOverrides({
    onSuccess: (sequenceWithOverrides) => {
      if (!senderId) {
        setSenderId(sequenceWithOverrides?.sender?.id);
      }

      if (formState.isDirty) {
        return;
      }

      if (sequenceWithOverrides) {
        reset({
          ...sequenceWithOverrides,
          sender: sequenceWithOverrides?.sender?.id,
          sequenceSchedule: sequenceScheduleToValue(
            sequenceWithOverrides?.sequenceSchedule,
          ),
          steps: sequenceWithOverrides.steps.map((step) => ({
            ...step,
            from: sequenceWithOverrides?.sender?.email,
          })),
        });
      }
    },
  });

  const { data: sendersData, isLoading: isSendersLoading } = useQuery(
    ['get_organization_senders'],
    () => api.fetch<{ data: Sender[] }>('get_organization_senders'),
  );

  const [sequence, contactSequence] = sequenceResult;
  const senders = sendersData?.data?.data || [];

  const [signature, setSignature] = useState<string>();
  const [senderId, setSenderId] = useState<string | undefined>(
    sequence?.sender?.id || senders[0]?.id,
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState,
    getValues,
  } = useForm<UpdateSequence>({
    defaultValues: {
      ...sequence,
      sender: senderId,
      sequenceSchedule: sequenceScheduleToValue(sequence?.sequenceSchedule),
    },
    resolver,
  });

  useEffect(() => {
    if (formState.errors.steps) {
      const firstIndexWithError = (
        formState.errors.steps as Array<FieldError>
      ).findIndex((err) => !!err);
      const stepElement = document.getElementById(
        `steps.${firstIndexWithError}`,
      );

      stepElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [formState.isSubmitting]);

  const sender = useMemo(
    () => senders.find((s: Sender) => s.id === senderId),
    [senderId, senders],
  );

  useEffect(() => {
    if (senders.length) {
      setSignature(sender?.signature);

      reset({
        ...getValues(),
      });
    }
  }, [sender]);

  const { fields, remove, append } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'steps', // unique name for your Field Array
  });

  const updateSequence = useMutation((data: UpdateSequence) =>
    api.fetch<{ data: Types.Sequence }>('update_sequence', {
      sequenceId: id,
      ...data,
      sequenceSchedule: valueToSequenceSchedule(data.sequenceSchedule),
      steps: data.steps.map((step) => ({
        ...step,
        sendAfterTime:
          step.sendAfterTime === undefined ? null : step.sendAfterTime,
      })),
    }),
  );

  const updateOverrides = useMutation((data: OverrideStepsDto) => {
    return apiClient.steps.overrideSteps({
      requestBody: {
        ...data,
      },
    });
  });

  useWatchSubject({ watch, trigger, setValue });
  const currentStepsValue = watch('steps');
  const sendTestEmail = useSendTestEmail();

  const handleFileUpload = async (file: File) => {
    if (file) {
      const result = await doUpload([file]);
      return result?.data?.[0]?.url as string;
    }
    return null;
  };

  const handleAddStepClick = (type: Step.type) => {
    const steps = getValues('steps');
    const lastStepIndex = steps?.length - 1;

    const emailSteps = steps.filter((step) => step.type === Step.type.EMAIL);
    const lastSubject = emailSteps[emailSteps.length - 1]?.subject ?? '';

    const waitBusinessDaysOnly = getValues(
      `steps.${lastStepIndex}.waitBusinessDaysOnly`,
    );

    // add new step with same subject and from as the last step in the sequence
    append({
      waitTimeCalendarDays: steps.length ? (undefined as unknown as number) : 0,
      waitBusinessDaysOnly: !!waitBusinessDaysOnly,
      subject: type === Step.type.EMAIL ? lastSubject : '',
      type,
      body: '',
      sendAfterTime: type === Step.type.EMAIL ? '8:30 AM' : undefined,
    });
  };

  const handleSubmitOverrides = useCallback(
    async (data: UpdateSequence) => {
      try {
        const stepOverrides: StepOverrideDto[] = [];
        let attachmentOverrides: StepAttachmentOverrideDto[] | undefined =
          data.steps.reduce<StepAttachmentOverrideDto[]>((acc, curr) => {
            return acc.concat(
              (curr.attachments ?? []).map((attachment) => ({
                stepId: curr.id as string,
                fileId: attachment.fileId,
              })),
            );
          }, []);

        // don't save attachment overrides if they haven't changed
        if (
          isEqual(
            attachmentOverrides.map((ao) => ao.fileId),
            contactSequence?.attachmentOverrides.map((ao) => ao.fileId),
          )
        ) {
          attachmentOverrides = undefined;
        }

        data.steps.forEach((step, index) => {
          if (formState.dirtyFields.steps?.[index]) {
            stepOverrides.push({
              stepId: step.id as string,
              body: step.body ?? '',
              subject: step.subject,
              sendAfterTime: step.sendAfterTime ?? undefined,
              waitTimeCalendarDays: step.waitTimeCalendarDays ?? undefined,
            });
          }
        });

        await updateOverrides.mutateAsync({
          contactSequenceId,
          attachmentOverrides,
          stepsOverrides: stepOverrides,
        });

        reset(getValues());

        refetch();
        await openAlertModal({
          title: 'Sequence Updated',
          description: `${getFullName(
            contactSequence?.contact,
          )} will now receive this updated sequence.`,
          confirmationText: 'Got it!',
          icon: {
            variant: 'success',
            name: 'check',
          },
        });

        navigate(
          routeNames.sequenceDetail({
            id: sequence?.id as string,
            contactSequenceId,
          }),
        );
      } catch (err) {
        showToast('Failed to update sequence. Please try again', {}, 'error');
      }
    },
    [formState.dirtyFields, contactSequence],
  );

  const handleSubmitSequence = async (data: UpdateSequence) => {
    try {
      const response = await updateSequence.mutateAsync({
        ...data,
        sender: senderId as string,
      });
      const newSequence = response.data.data;
      reset(getValues());
      refetch();
      await openAlertModal({
        title: 'Sequence Updated',
        description:
          'All in-progress contacts added to this sequence will receive the updated sequence.',
        confirmationText: 'Got it!',
        icon: {
          variant: 'success',
          name: 'check',
        },
      });

      navigate(routeNames.sequenceDetail({ id: newSequence.id }));
    } catch {
      showToast('Failed to update sequence. Please try again', {}, 'error');
    }
  };

  const hasCompletedStep = (stepId: string) => {
    return (
      !!contactSequence &&
      !!contactSequence.contactSequenceSteps?.find(
        (css) => css.stepId === stepId && !!css.completedAt,
      )
    );
  };

  const onSubmit: SubmitHandler<UpdateSequence> = async (data) => {
    if (contactSequenceId) {
      handleSubmitOverrides(data);
    } else {
      handleSubmitSequence(data);
    }
  };

  usePrompt(
    'Are you sure you want to leave this page? You have unsaved changes.',
    formState.isDirty && !formState.isSubmitting,
  );

  if (getSequenceError) {
    throw getSequenceError;
  }

  if (isLoading || isSendersLoading) {
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }

  if (!sequence) {
    return <Navigate to={routeNames.projects()} />;
  }

  return (
    <Box
      css={{
        p: 16,
        pb: 70,
        mediaLg: {
          p: 0,
          pb: 70,
        },
      }}
    >
      <BackButton
        route={sequence.name}
        onClick={() =>
          navigate(
            routeNames.sequenceDetail({ id: sequence.id, contactSequenceId }),
          )
        }
      />
      <Form
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        control={control as any}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit);
        }}
      >
        <Flex
          css={{
            mb: 24,
            flexDirection: 'column',
            alignItems: 'flex-start',
            mediaSm: {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
        >
          <Text
            as='h1'
            css={{
              fontSize: '$3xl',
              lineHeight: '$4xl',
              fontWeight: '$medium',
              color: '$neutral-blue-900',
              maxWidth: 436,
              mb: 16,
              mediaSm: { mb: 'unset' },
            }}
          >
            {sequence.name}
          </Text>
          <Flex css={{ gap: 8 }}>
            <Button
              dataCy='Edit cancle-button'
              variant='secondary'
              onClick={() => {
                const route = routeNames.sequenceDetail({
                  id: sequence.id,
                  contactSequenceId,
                });
                navigate(route);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!currentStepsValue.length}
              variant='primary'
              onClick={handleSubmit(onSubmit)}
              dataCy='Save Sequence-button'
            >
              {updateSequence.isLoading && <Spinner variant='white' />}Save
              Sequence
            </Button>
          </Flex>
        </Flex>
        {contactSequence && (
          <PersonalizeSequenceAlert
            sequenceId={id as string}
            contactSequence={contactSequence}
            title="You're editing a personalized sequence"
          />
        )}
        {!contactSequence && (
          <Flex
            justify='between'
            css={{
              mb: 16,
              backgroundColor: 'white',
              boxShadow: '$base',
              borderRadius: '$lg',
              padding: 20,
              columnGap: 24,
              alignItems: 'flex-start',
            }}
            wrap
          >
            <Box
              css={{
                flex: 1,
              }}
            >
              <Input
                dataCy='Sequence title-input'
                label='Sequence Title'
                placeholder='Frontend Engineer'
                name='name'
                id='sequence-name'
                required
              />
            </Box>
            <Flex
              vertical
              css={{
                flex: 1,
              }}
            >
              <Label
                id='sequence-sender-label'
                css={{ mb: 8, display: 'inline-block' }}
                htmlFor='sequence-sender'
              >
                From
              </Label>
              <Combobox
                aria-labelledby='sequence-sender-label'
                id='sequence-sender'
                placeholder='From'
                value={senderId}
                options={senders}
                leftIcon={<Icon name='mail' css={{ ml: -4, mr: 6 }} />}
                disabled={!senders.length}
                clearable={false}
                onChange={(keys, selected) => {
                  setSenderId(selected?.[0]?.id);
                }}
                css={{ height: 38 }}
              >
                {(senderOption) => (
                  <Option key={senderOption.id} css={{ p: 8, px: 16 }}>
                    <div>
                      <Text>{senderOption.email}</Text>
                    </div>
                  </Option>
                )}
              </Combobox>
            </Flex>
            <Flex vertical css={{ flex: 1 }}>
              <Label css={{ mb: 8 }}>Use Default Signature</Label>
              <Flex css={{ height: 36 }}>
                <Checkbox id='use-default-signature' name='useDefaultSignature'>
                  Include your signature in emails
                </Checkbox>
              </Flex>
            </Flex>
            <Flex css={{ flexBasis: '100%' }}>
              <SequenceScheduleSelector
                id='sequence-schedule'
                name='sequenceSchedule'
                label='Send On:'
              />
              <Flex css={{ ml: 32, mt: 28 }}>
                <Checkbox id='send-on-holidays' name='sendOnHolidays'>
                  Send on holidays
                </Checkbox>
                <Tip
                  css={{ marginLeft: 8 }}
                  content='Emails will send on US federal holidays.'
                />
              </Flex>
            </Flex>
          </Flex>
        )}
        {fields.length > 0 ? (
          fields.map((item, index) => (
            <Box
              id={`steps.${index}`}
              key={getValues().steps[index]?.id ?? item.id}
              css={{ mb: 40 }}
            >
              <Controller
                control={control}
                name={`steps.${index}`}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <StepEditor
                      error={error as StepEditorProps['error']}
                      stepNumber={index + 1}
                      onChange={onChange}
                      onRemove={() => remove(index)}
                      defaultValue={value}
                      sender={senderId}
                      removable={!contactSequence}
                      type={value.type}
                      readOnly={hasCompletedStep(value.id as string)}
                      onSendTestEmail={sendTestEmail}
                      onFileUpload={handleFileUpload}
                      signature={signature}
                      useDefaultSignature={getValues().useDefaultSignature}
                    />
                    <StepError error={error as StepEditorProps['error']} />
                  </>
                )}
              />
            </Box>
          ))
        ) : (
          <CreateSequenceEmptyState />
        )}
        {!contactSequenceId && (
          <AddStepButtons
            label={currentStepsValue.length ? 'Add step' : ''}
            onAddStep={handleAddStepClick}
          />
        )}
      </Form>
    </Box>
  );
};

export default EditSequence;
