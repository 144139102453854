import { Box } from '@betterleap/ui';
import { NavigationOptionsProps } from './NavigationOptions.types';
import NavigationOptionItem from './NavigationOptionItem';

const NavigationOptions: React.FC<NavigationOptionsProps> = ({
  optionRoutes,
}) => {
  return (
    <Box style={{ display: 'flex' }}>
      {optionRoutes
        .filter((route) => !route.hidden)
        .map((option) => (
          <NavigationOptionItem option={option} />
        ))}
    </Box>
  );
};

export default NavigationOptions;
