import { ContactSequence, Sequence, StepAttachment } from '@betterleap/client';
import { apiClient } from 'lib/apiClient';
import { groupBy } from 'lodash';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';

const applyOverrides = (
  sequence?: Sequence,
  contactSequence?: ContactSequence,
) => {
  if (!sequence) {
    return undefined;
  }

  const attachmentOverridesByStepId = groupBy(
    contactSequence?.attachmentOverrides ?? [],
    'stepId',
  );
  const stepsWithOverrides = (sequence?.steps ?? []).map((step) => {
    const override = contactSequence?.stepOverrides?.find(
      (so) => so.stepId === step.id,
    );

    let stepWithOverrides = { ...step };

    if (override) {
      stepWithOverrides = {
        ...stepWithOverrides,
        body: override.body,
        subject: override.subject,
        sendAfterTime: override.sendAfterTime,
        waitTimeCalendarDays: override.waitTimeCalendarDays ?? 0,
      };
    }

    const attachmentOverrides = attachmentOverridesByStepId[step.id];
    if (attachmentOverrides) {
      stepWithOverrides = {
        ...stepWithOverrides,
        attachments: attachmentOverrides.map(
          (ao) =>
            ({
              fileId: ao.fileId,
              file: ao.file,
              id: ao.id,
              stepId: ao.stepId,
            } as StepAttachment),
        ),
      };
    }

    return stepWithOverrides;
  });

  return {
    ...sequence,
    steps: stepsWithOverrides,
  } as Sequence;
};

interface UseGetSequenceWithOverridesProps {
  onSuccess?: (sequence: Sequence | undefined) => void;
}

export const useGetSequenceWithOverrides = ({
  onSuccess,
}: UseGetSequenceWithOverridesProps) => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const contactSequenceId = searchParams.get('contactSequenceId');

  const query = useQuery(
    ['get_sequence_with_overrides', id, contactSequenceId],
    () =>
      Promise.all([
        apiClient.sequence.findOneForUser({
          sequenceId: id as string,
          relations: [
            'steps',
            'sender',
            'sequenceSchedule',
            'steps.attachments',
            'steps.attachments.file',
          ],
        }),
        contactSequenceId
          ? apiClient.contactSequence.getCandidateSequenceById({
              id: contactSequenceId as string,
              relations: [
                'stepOverrides',
                'attachmentOverrides',
                'attachmentOverrides.file',
                'contact',
                'contactSequenceSteps',
              ],
            })
          : Promise.resolve({ data: undefined }),
      ]),
    {
      onSuccess: (result) => {
        const [sequenceResult, contactSequenceResult] = result;
        const sequence = sequenceResult.data;
        const contactSequence = contactSequenceResult.data;

        const sequenceWithOverrides = applyOverrides(sequence, contactSequence);

        onSuccess?.(sequenceWithOverrides);
      },
    },
  );

  const [sequenceResult, contactSequenceResult] = query.data ?? [];

  const sequence = sequenceResult?.data;
  const contactSequence = contactSequenceResult?.data;

  const sequenceWithOverrides = applyOverrides(sequence, contactSequence);

  return {
    ...query,
    data: [sequenceWithOverrides, contactSequence] as [
      Sequence | undefined,
      ContactSequence | undefined,
    ],
  };
};
