import capitalize from 'lodash/capitalize';

export interface ProjectSubjectOptions {
  singular?: boolean;
  cap?: boolean;
}

export const getProjectSubject =
  (isBDProject: boolean) =>
  (opts: ProjectSubjectOptions = {}) => {
    const { singular, cap } = opts;

    let subject = isBDProject ? 'prospects' : 'candidates';

    if (singular) {
      subject = subject.slice(0, subject.length - 1);
    }

    if (cap) {
      subject = capitalize(subject);
    }

    return subject;
  };
