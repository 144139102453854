import { Organization } from '@betterleap/client';
import { Box } from '@betterleap/ui';
import ashby from '../../../../assets/images/ashby-icon.png';
import bullhorn from '../../../../assets/images/bullhorn-icon.png';
import clockwork from '../../../../assets/images/clockwork-icon.svg';
import greenhouse from '../../../../assets/images/greenhouse-icon.svg';
import lever from '../../../../assets/images/lever-icon.svg';
import recruiterflow from '../../../../assets/images/recruiterflow-icon.png';
import taleo from '../../../../assets/images/taleo-icon.jpg';
import {
  ConnectIntegrationProps,
  IntegrationSetting,
} from './Integration.elements';

type IconMap = { [s: string]: { src: string; width: number } };
const iconMap: IconMap = {
  [Organization.atsSource.LEVER]: { src: lever, width: 20 },
  [Organization.atsSource.GREENHOUSE]: { src: greenhouse, width: 15 },
  [Organization.atsSource.ASHBY]: { src: ashby, width: 20 },
  [Organization.atsSource.RECRUITERFLOW]: { src: recruiterflow, width: 20 },
  [Organization.atsSource.CLOCKWORK]: { src: clockwork, width: 20 },
  [Organization.atsSource.BULLHORN]: { src: bullhorn, width: 20 },
  [Organization.atsSource.ORACLE_TALEO]: { src: taleo, width: 20 },
};

const ConnectAts = ({
  organization,
  isLoading,
  openMergeLink,
}: ConnectIntegrationProps) => {
  const icon = organization.atsSource ? iconMap[organization.atsSource] : null;
  return (
    <IntegrationSetting>
      <Box>
        <IntegrationSetting.Title>Connect ATS</IntegrationSetting.Title>
        <IntegrationSetting.Subtitle>
          This enables a two-way sync between your ATS and Betterleap
        </IntegrationSetting.Subtitle>
      </Box>
      {organization.accountToken && organization.atsSource ? (
        <IntegrationSetting.ConnectText
          integrationName={organization.atsSource}
          icon={icon}
        />
      ) : (
        <IntegrationSetting.ConnectButton
          buttonText='Connect ATS'
          isLoading={isLoading}
          onClick={openMergeLink}
        />
      )}
    </IntegrationSetting>
  );
};

export default ConnectAts;
