import { initializeApiClient, SegmentAnalytics } from '@betterleap/shared';
import '@betterleap/shared/dist/cjs/index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { apiClientConfig } from 'lib/apiClient';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hotjarWorkaround } from './hotjarWorkaround';

if (
  process.env.REACT_APP_ENABLE_MOCKS === 'true' &&
  process.env.NODE_ENV === 'development'
) {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://328916de86104a00aeb92724d62c566a@o1036501.ingest.sentry.io/6005561',
    integrations: [new Integrations.BrowserTracing()],
    environment: (process.env.REACT_APP_ENV || 'development') as string,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// analytics events should not fire for a user if they are masquerading as someone else
const sessionToken = window.sessionStorage.getItem('token');

if (!sessionToken && process.env.REACT_APP_SEGMENT_WRITE_KEY) {
  SegmentAnalytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY);
}

initializeApiClient(apiClientConfig);

hotjarWorkaround();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string,
  });

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
  );
})();
