import { Box, Flex, Text } from '@betterleap/ui';
import { useSelector } from 'react-redux';
import { selectCompany } from '../../../store/api/selectors';
import CompanyDetails from '../../modules/CompanyProfile/CompanyDetails/CompanyDetails';

const CompanyProfileTemplate = () => {
  const company = useSelector(selectCompany);

  return (
    <Box css={{ p: 32 }}>
      <Flex justify='between' align='center' css={{ mb: 24 }}>
        <Text
          as='h1'
          css={{
            fontSize: '$3xl',
            lineHeight: '$4xl',
            fontWeight: '$medium',
            color: '$neutral-blue-900',
          }}
        >
          Company Profile
        </Text>
      </Flex>
      <CompanyDetails company={company.data} />
    </Box>
  );
};

export default CompanyProfileTemplate;
