import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { filterState } from '../SourceCandidates.template';
import { VariableInclusionFilter } from './VariableInclusionFilter';

export const LicensedInStateFilter = () => {
  const filters = useAtomValue(filterState);

  const values = useMemo(() => {
    return filters.licensedStates ?? [];
  }, [filters]);

  return (
    <VariableInclusionFilter
      values={values}
      field='licensedStates'
      autocompleteField='regions'
      title='Licensed In'
      placeholder='Search by U.S. state'
      canExclude={false}
    />
  );
};
