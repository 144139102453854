import {
  BulkPauseOrResumeContactSequencesDto,
  ProjectWithStatsDto,
} from '@betterleap/client';
import { showToast } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { useMutation } from 'react-query';
import { Row } from 'react-table';
import {
  resolveSubject,
  selectContactIds,
} from '../../ProjectDetails.functions';
import { useProjectContactsFilterParams } from '../../useProjectContactsFilterParams';

interface Props {
  project: ProjectWithStatsDto;
  selectedContacts: Row<Record<string, unknown>>[] | 'all';
  onPauseOrResumeSequences: () => void;
}

export const usePauseOrResumeSequence = ({
  project,
  selectedContacts,
  onPauseOrResumeSequences,
}: Props) => {
  const { contactProjectQuery } = useProjectContactsFilterParams();

  const subjects = resolveSubject(project);

  const pauseOrResumeContactSequences = useMutation(
    (data: BulkPauseOrResumeContactSequencesDto) =>
      apiClient.contact.bulkPauseOrResumeContactSequences({
        requestBody: data,
      }),
    {
      onSuccess: (data, variables) => {
        const pausedOrResumed = variables.pause ? 'paused' : 'resumed';
        showToast({
          title: 'Success!',
          description: `Selected ${subjects} are ${pausedOrResumed}.`,
        });
        onPauseOrResumeSequences();
      },
      onError: (data, variables) => {
        const pauseOrResume = variables.pause ? 'pause' : 'resume';
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: `Failed to ${pauseOrResume} ${subjects}. Please try again.`,
        });
      },
    },
  );

  const pauseOrResumeSequence = (pause: boolean) => {
    pauseOrResumeContactSequences.mutate({
      ...contactProjectQuery,
      projectId: project.id,
      sequenceId: project.sequenceId,
      contactIds: selectContactIds(selectedContacts),
      pause,
    });
  };

  return { pauseOrResumeSequence };
};
