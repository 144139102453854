import { FileAttachment, useUploadFile } from '@betterleap/shared';
import { Button, Flex, Icon, Spinner } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

interface ContactAttachmentProps {
  contactId?: string;
  canEdit?: boolean;
}

const ContactAttachment = ({ contactId, canEdit }: ContactAttachmentProps) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { data: attachmentsResponse, refetch: refetchAttachments } = useQuery(
    ['attachments', contactId],
    () =>
      apiClient.attachment.getAttachments({ contactId: contactId as string }),
    {
      enabled: !!contactId,
    },
  );

  const createAttachment = useMutation(
    (data: { contactId: string; fileId: string }) =>
      apiClient.attachment.createAttachment({
        requestBody: data,
      }),
    {
      onSuccess: () => {
        setIsUploading(false);
        refetchAttachments();
      },
    },
  );

  const { uploadFile } = useUploadFile({
    path: 'private/resumes',
    onSuccess: (file) => {
      createAttachment.mutate({
        contactId: contactId as string,
        fileId: file.id,
      });
    },
  });

  const onUploadClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        setIsUploading(true);
        uploadFile(file);
      }
    };
    input.click();
  };

  if (!contactId) {
    return null;
  }

  const attachment = attachmentsResponse?.data?.[0];

  if (!attachment && !canEdit) {
    return null;
  }

  return (
    <Flex css={{ gap: 8 }}>
      <Icon size={16} color='$neutral-blue-700' name={'attachment'} />
      {attachment ? (
        <FileAttachment
          css={{
            maxWidth: 200,
          }}
          key={attachment.id}
          attachment={{ name: attachment.file?.name || '' }}
          onOpenClick={() => window.open(attachment.file?.gcsUrl, '_blank')}
          onDeleteClick={() =>
            apiClient.attachment.deleteAttachment({ id: attachment.id })
          }
          afterDelete={() => refetchAttachments()}
          canEdit={canEdit}
        />
      ) : (
        <Button
          onClick={onUploadClick}
          variant='link'
          css={{
            fontWeight: '$normal',
            fontSize: '$sm',
            color: '$blue-600',
          }}
        >
          Upload resume
        </Button>
      )}
      {isUploading && <Spinner css={{ height: 16, width: 16 }} />}
    </Flex>
  );
};

export default ContactAttachment;
