export function cleanLinkedInUrl(
  linkedinUrl?: string | null,
): string | undefined | null {
  if (!linkedinUrl) {
    return linkedinUrl;
  }
  let cleanLiUrlString = linkedinUrl;
  if (!cleanLiUrlString.startsWith('http')) {
    cleanLiUrlString = `https://${cleanLiUrlString}`;
  }
  const cleanLiUrl = new URL(cleanLiUrlString);
  cleanLiUrl.protocol = 'https:';
  cleanLiUrl.host = 'linkedin.com';

  const pathParts = cleanLiUrl.pathname.split('/').filter((p) => !!p);
  cleanLiUrl.pathname = `/${pathParts.slice(0, 2).join('/')}/`; // /in/<username>/

  cleanLiUrl.search = '';
  cleanLiUrl.hash = '';
  return cleanLiUrl.href;
}
