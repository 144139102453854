import { ApiError, Sender } from '@betterleap/client';
import { apiClient } from 'lib/apiClient';
import qs from 'qs';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import routeNames from '../../../constants/routeNames';

interface CreateSenderDto {
  code: string;
  provider: Sender.provider;
}

export enum oauthResult {
  SUCCESS = 'OAUTH_SUCCESS',
  FAILURE = 'OAUTH_FAILURE',
}

const SenderConnectCallbackTemplate = (): JSX.Element => {
  const createSender = useMutation(
    (data: CreateSenderDto) =>
      apiClient.sender.connectSender({
        requestBody: data,
      }),
    {
      onError: (error: ApiError) => {
        window.opener.postMessage({
          result: oauthResult.FAILURE,
          error: error?.body?.error?.data,
          e: error, // without this a failure occurs on local 🤯
        });
        window.close();
      },
      onSuccess: () => {
        window.opener.postMessage({
          result: oauthResult.SUCCESS,
        });
        window.close();
      },
    },
  );

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.opener) {
      const search = qs.parse(location.search.slice(1));

      if (search.error) {
        window.opener.postMessage({
          result: oauthResult.FAILURE,
        });
        window.close();
        return;
      }

      if (search.code) {
        createSender.mutate({
          code: search.code.toString(),
          provider: search.client_info
            ? Sender.provider.MICROSOFT
            : Sender.provider.GOOGLE,
        });
      }
    } else {
      navigate(routeNames.projects());
    }
  }, []);

  return (
    <div className='mobile:p-4 tablet:p-4 desktop:p-0'>
      <div className='relative'>Connecting email account...</div>
    </div>
  );
};

export default SenderConnectCallbackTemplate;
