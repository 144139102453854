import { Contact, ExtendedContactProject, Project } from '@betterleap/client';

export const resolveContactPrimaryEmail = (
  contact: Contact | ExtendedContactProject,
  enrichmentMode: Project.contactEmailEnrichmentMode,
): string | undefined => {
  if (enrichmentMode === Project.contactEmailEnrichmentMode.PERSONAL) {
    return contact.primaryEmail;
  } else {
    return contact.primaryBusinessEmail;
  }
};
