import { Flex, Text } from '@betterleap/ui';
import React from 'react';
import betterleapLogo from '../../../assets/images/betterleap-logo.svg';
import Header from '../../modules/Header/Header';
import styles from './CallbackLoadingTemplate.module.scss';

const CallbackLoadingTemplate = () => (
  <>
    <Header />
    <Flex
      vertical
      css={{ height: 600, justifyContent: 'center', alignItems: 'center' }}
    >
      <img
        src={betterleapLogo}
        alt='logo'
        width={60}
        height={60}
        className={styles.betterleap_spinner}
      />
      <Text as='h2' css={{ mt: 48, fontSize: '$2xl' }}>
        Please wait while we are logging you in...
      </Text>
    </Flex>
  </>
);

export default CallbackLoadingTemplate;
