import {
  AlertIcon,
  Box,
  Divider,
  Flex,
  FlexProps,
  IconButton,
  IconButtonProps,
  List,
  ListBox,
  ListBoxProps,
  ListInput,
  Menu,
  MenuArrow,
  MenuContent,
  MenuTrigger,
  Option,
  Spinner,
  Text,
  useControlledState,
} from '@betterleap/ui';
import merge from 'lodash/merge';
import { forwardRef, useState } from 'react';
import { CreateProjectAction } from './CreateProjectAction';

export const ProjectActionButton = forwardRef<
  HTMLButtonElement,
  IconButtonProps
>(({ css, onClick, ...props }, ref) => (
  <IconButton
    ref={ref}
    css={merge(
      {
        backgroundColor: '$neutral-blue-200',
        p: 12,
        fill: '$neutral-blue-800',
        hover: {
          backgroundColor: '$neutral-blue-400',
        },
      },
      css,
    )}
    size='sm'
    {...props}
    onClick={onClick}
  />
));

ProjectActionButton.displayName = 'ProjectActionButton';

export const ProjectActionBar = forwardRef<HTMLDivElement, FlexProps>(
  ({ css, ...props }, ref) => (
    <Flex
      ref={ref}
      justify='between'
      css={merge(
        {
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: 63,
          px: 16,
          py: 12,
          backgroundColor: '$background-component',
          borderTop: '1px solid $neutral-blue-300',
          zIndex: 2,
        },
        css,
      )}
      {...props}
    />
  ),
);

ProjectActionBar.displayName = 'ProjectActionBar';

export interface MoveToMenuProps extends Omit<ListBoxProps, 'children'> {
  loading?: boolean;
  onFilterChange?: (filter: string) => void;
  onCreateProject?: (name: string) => void;
  modal?: boolean;
  placeholder?: string;
  children?: React.ReactNode;
}

export const MoveToMenu = forwardRef<HTMLButtonElement, MoveToMenuProps>(
  (
    {
      value,
      defaultValue,
      onChange,
      options = [],
      title,
      children,
      loading,
      placeholder,
      emptyState,
      onFilterChange,
      onCreateProject,
      modal,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [stateValue, setStateValue] = useControlledState<
      (string | number)[] | undefined
    >(
      value,
      defaultValue,
      onChange as (v: (string | number)[] | undefined) => void,
    );

    const hasOptions = !!(options as []).length;
    const showCreateProject = !loading && !hasOptions && !!filter.trim();

    return (
      <Menu open={open} onDismiss={() => setOpen(false)} modal={modal}>
        <MenuTrigger
          asChild
          ref={ref}
          onClick={() => {
            setOpen(!open);
            setStateValue([]);
          }}
        >
          {children}
        </MenuTrigger>
        <MenuContent css={{ width: 320, pt: 0, pb: 0 }} align='start'>
          <MenuArrow offset={14} />
          <List
            value={stateValue}
            onChange={(keys) => {
              setStateValue(keys);
              setOpen(false);
            }}
            options={options}
          >
            <Box css={{ p: 16 }}>
              <Flex css={{ mb: 16, gap: 12 }}>
                <AlertIcon variant='purple' name='collection' />
                <Text css={{ fontWeight: 'medium' }}>{title}</Text>
              </Flex>
              <ListInput
                autoFocus
                placeholder={placeholder ?? 'Search'}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    setOpen(false);
                  }
                }}
                onChange={(f: string) => {
                  setFilter(f);
                  onFilterChange?.(f);
                }}
              />
            </Box>
            <Divider css={{ backgroundColor: '$neutral-blue-300' }} />
            {!!loading && (
              <Box css={{ ml: 18, mt: 18, minHeight: 35 }}>
                <Spinner variant='blue' />
              </Box>
            )}
            {!loading && !hasOptions && emptyState}
            <ListBox
              css={{
                p: 8,
                maxHeight: 240,
                display: !hasOptions ? 'none' : 'block',
              }}
              aria-label='project list'
            >
              {(option) => (
                <Option css={{ fontSize: '$sm' }}>{option.name}</Option>
              )}
            </ListBox>
            {!!onCreateProject && showCreateProject && (
              <CreateProjectAction
                projectName={filter}
                onClick={() => {
                  onCreateProject(filter);
                  setOpen(false);
                  setFilter('');
                }}
              />
            )}
          </List>
        </MenuContent>
      </Menu>
    );
  },
);

MoveToMenu.displayName = 'MoveToMenu';
