import { getProjectSubject } from '@betterleap/shared';
import { Box, Text } from '@betterleap/ui';
import noCandidatesFoundImage from '../../../../assets/images/no-candidates-found.svg';

export const NoCandidatesFoundGraphic = ({
  isBDProject,
}: {
  isBDProject: boolean;
}) => {
  const subject = getProjectSubject(isBDProject);

  return (
    <>
      <Box as='img' src={noCandidatesFoundImage} />
      <Text
        css={{
          fontSize: 18,
          fontWeight: 600,
          lineHeight: 2.5,
          color: '$neutral-blue-700',
          mt: -40,
        }}
      >
        No {subject()} found
      </Text>
      <Text css={{ fontSize: 14, color: '$neutral-blue-500' }}>
        There are no {subject()} in this tab
      </Text>
    </>
  );
};
