import React from 'react';
import OnboardingTemplate from '../components/templates/Onboarding/Onboarding.template';

const Onboarding = () => (
  <React.StrictMode>
    <OnboardingTemplate />
  </React.StrictMode>
);

export default Onboarding;
