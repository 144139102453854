import { Types } from '@betterleap/shared';
import { showToast, useModal } from '@betterleap/ui';
import { useMutation } from 'react-query';
import { DoNotContactModal } from 'components/modules/Modal/DoNotContactModal/DoNotContactModal';
import { apiClient } from 'lib/apiClient';
import useAnalytics from 'hooks/analytics';
import { AlertModal } from '../../modules/Modal/AlertModal/AlertModal';

interface Props {
  controller: 'doNotContactDomain' | 'restrictedDomain';
  onUpload: () => void;
  successToastTitle: string;
  successToastDescription: string;
  analyticsButtonName: string;
}

export const useUploadDnc = ({
  controller,
  onUpload,
  successToastTitle,
  successToastDescription,
  analyticsButtonName,
}: Props) => {
  const openUploadModal = useModal(DoNotContactModal);
  const openSuccessModal = useModal(AlertModal);

  const { track } = useAnalytics();

  const replaceDomains = useMutation(
    (requestBody: { domains: string[] }) =>
      apiClient[controller].replaceDomains({ requestBody }),
    {
      onSuccess: async () => {
        onUpload();
        await openSuccessModal({
          title: successToastTitle,
          description: successToastDescription,
          icon: {
            name: 'mail-open',
            variant: 'info',
          },
          confirmationText: 'Done',
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to upload file. Please try again.',
        });
      },
    },
  );

  const onUploadClick = async () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: analyticsButtonName,
      buttonLocation: 'Settings',
    });
    await openUploadModal({
      title: `Add Domains`,
      handleSubmit: (domains: string[]) =>
        replaceDomains.mutateAsync({ domains }),
    });
  };

  return onUploadClick;
};
