import { PromiseModalProps } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { AcknowledgmentText } from './AcknowledgmentText';
import { AcceptTermsModal } from './AcceptTermsModal';

export const PrivacyPolicyModal = ({ ...rest }: PromiseModalProps<unknown>) => {
  return (
    <AcceptTermsModal
      documentTitle='Privacy Policy'
      documentPath='/privacy-policy.html'
      acknowledgmentText={
        <AcknowledgmentText
          unlinkedText='I acknowledge and agree that my personal information will be used as described in the'
          linkedText='Privacy Policy'
          linkUrl='https://betterleap.com/privacy/'
        />
      }
      acceptButtonText='Accept Policy'
      acceptTerms={() => apiClient.user.acceptPrivacyPolicy()}
      {...rest}
    />
  );
};
