import { Box, Flex, Input, Label, TextArea } from '@betterleap/ui';
import { useAtom } from 'jotai';
import { FeatureFlagKey, useFeatureFlag } from 'hooks/useFeatureFlag';
import { SourceCandidatesMultiSelectFilter } from './SourceCandidatesMultiSelectFilter';
import { filterState, SearchMode } from './SourceCandidates.template';
import { applyTerm, parseTerm } from './SourceCandidates.functions';
import { ExcludeViewedCandidatesFilter } from './Filters/ExcludeViewedCandidatesFilter';
import {
  SourceCandidatesInputFilter,
  SourcingInputLabel,
} from './SourceCandidatesInputFilter';
import { GenderFilter } from './Filters/GenderFilter';
import { SourcingFilterGroup } from './SourcingFilterGroup';
import { SourcingFilterTitle } from './SourceCandidates.components';
import { HasPersonalEmailFilter } from './Filters/HasPersonalEmailFilter';
import { SkillsFilter } from './Filters/SkillsFilter';
import { LicensedInStateFilter } from './Filters/LicensedInStateFilter';

// https://docs.peopledatalabs.com/docs/company-sizes
const companySizeOptions = [
  '1-10',
  '11-50',
  '51-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5001-10000',
  '10001+',
].map((size) => ({ name: size, field: 'currentCompanySizes' }));

export interface SourceCandidatesFilterAreaProps {
  searchMode: SearchMode;
}

const SourceCandidatesFilterArea = (props: SourceCandidatesFilterAreaProps) => {
  const { searchMode } = props;
  const [filters, setFilters] = useAtom(filterState);
  const showHealthcareFilters = useFeatureFlag(
    FeatureFlagKey.HEALTHCARE_SOURCING_FILTERS,
  );

  return (
    <Box css={{ width: '100%' }}>
      <Flex vertical css={{ p: '1rem', gap: 12 }}>
        <ExcludeViewedCandidatesFilter searchMode={searchMode} />
        <HasPersonalEmailFilter />
      </Flex>
      <SourcingFilterGroup
        groupTitle='Locations'
        filterKeys={[
          'locations',
          'regions',
          'localities',
          'countries',
          'zipCodes',
        ]}
      >
        <SourceCandidatesMultiSelectFilter
          css={{ mb: 16 }}
          title='Location'
          field='locations'
          placeholder='Search by city, state, country'
          currentFilter={(filters.localities ?? [])
            .map((l) => ({ field: 'localities', ...parseTerm(l) }))
            .concat(
              (filters.locations ?? []).map((l) => ({
                field: 'locations',
                ...parseTerm(l),
              })),
            )
            .concat(
              (filters.regions ?? []).map((r) => ({
                field: 'regions',
                ...parseTerm(r),
              })),
            )
            .concat(
              (filters.countries ?? []).map((c) => ({
                field: 'countries',
                ...parseTerm(c),
              })),
            )}
          onFilterUpdated={(locations) => {
            setFilters({
              ...filters,
              localities: locations
                .filter((l) => l.field === 'localities')
                .map(applyTerm),
              locations: locations
                .filter((l) => l.field === 'locations')
                .map(applyTerm),
              regions: locations
                .filter((l) => l.field === 'regions')
                .map(applyTerm),
              countries: locations
                .filter((l) => l.field === 'countries')
                .map(applyTerm),
            });
          }}
        />
        <SourceCandidatesInputFilter
          title='Zip Code'
          field='zipCodes'
          placeholder='Search by zip code'
          aria-label='filter by zip code'
          currentFilter={(filters.zipCodes || []).map((zipCode) => ({
            field: 'zipCodes',
            ...parseTerm(zipCode),
          }))}
          onFilterUpdated={(zipCodes) => {
            setFilters({
              ...filters,
              zipCodes: zipCodes.map(applyTerm),
            });
          }}
        />
      </SourcingFilterGroup>
      <SourcingFilterGroup
        groupTitle='Job Titles'
        filterKeys={[
          'jobTitlesCurrent',
          'jobTitlesPast',
          'jobTitlesCurrentOrPast',
        ]}
      >
        <SourceCandidatesMultiSelectFilter
          css={{ mb: 16 }}
          title='Current Titles'
          field='jobTitlesCurrent'
          currentFilter={(filters.jobTitlesCurrent ?? []).map((jt) => ({
            field: 'jobTitlesCurrent',
            ...parseTerm(jt),
          }))}
          onFilterUpdated={(jobTitles) => {
            setFilters({
              ...filters,
              jobTitlesCurrent: jobTitles.map(applyTerm),
            });
          }}
        />
        <SourceCandidatesMultiSelectFilter
          css={{ mb: 16 }}
          title='Past Titles'
          field='jobTitlesPast'
          currentFilter={(filters.jobTitlesPast ?? []).map((jt) => ({
            field: 'jobTitlesPast',
            ...parseTerm(jt),
          }))}
          onFilterUpdated={(jobTitles) => {
            setFilters({
              ...filters,
              jobTitlesPast: jobTitles.map(applyTerm),
            });
          }}
        />
        <SourceCandidatesMultiSelectFilter
          css={{ mb: 16 }}
          title='Current or Past Titles'
          field='jobTitlesCurrentOrPast'
          currentFilter={(filters.jobTitlesCurrentOrPast ?? []).map((jt) => ({
            field: 'jobTitlesCurrentOrPast',
            ...parseTerm(jt),
          }))}
          onFilterUpdated={(jobTitles) => {
            setFilters({
              ...filters,
              jobTitlesCurrentOrPast: jobTitles.map(applyTerm),
            });
          }}
        />
      </SourcingFilterGroup>
      <SourcingFilterGroup
        groupTitle='Skills'
        filterKeys={['skills', 'languageNames']}
      >
        <SkillsFilter />
        <SourceCandidatesMultiSelectFilter
          css={{ mt: 16 }}
          title='Languages'
          field='languageNames'
          currentFilter={(filters.languageNames || []).map((language) => ({
            field: 'languageNames',
            ...parseTerm(language),
          }))}
          onFilterUpdated={(languages) => {
            setFilters({
              ...filters,
              languageNames: languages.map(applyTerm),
            });
          }}
        />
      </SourcingFilterGroup>
      <SourcingFilterGroup
        groupTitle='Experience'
        filterKeys={['yearsOfExperienceMax', 'yearsOfExperienceMin']}
      >
        <SourcingInputLabel
          id='filter-by-name'
          htmlFor='sourcing-years-of-experience-min'
        >
          Years of Experience
        </SourcingInputLabel>
        <Flex>
          <Label
            id='filter-years-of-experience-from'
            htmlFor='sourcing-years-of-experience-min'
            css={{ color: '$neutral-blue-600', fontSize: '$xs' }}
          >
            From
          </Label>
          <Input
            id='sourcing-years-of-experience-min'
            placeholder='Min'
            aria-label='filter minimum years of experience'
            value={filters.yearsOfExperienceMin ?? ''}
            type='number'
            onChange={(value) => {
              if (parseInt(value) < 0) return;
              setFilters({
                ...filters,
                yearsOfExperienceMin: value ? parseInt(value, 10) : undefined,
              });
            }}
            css={{ width: 64, ml: 8 }}
          />
          <Label css={{ color: '$neutral-blue-600', ml: 8, fontSize: '$xs' }}>
            to
          </Label>
          <Input
            placeholder='Max'
            aria-label='filter maximum years of experience'
            value={filters.yearsOfExperienceMax ?? ''}
            type='number'
            onChange={(value) => {
              if (parseInt(value) < 0) return;
              setFilters({
                ...filters,
                yearsOfExperienceMax: value ? parseInt(value, 10) : undefined,
              });
            }}
            css={{ width: 64, ml: 8 }}
          />
        </Flex>
      </SourcingFilterGroup>
      <SourcingFilterGroup
        groupTitle='Education'
        filterKeys={[
          'educationSchools',
          'educationDegrees',
          'educationMajors',
          'certifications',
        ]}
      >
        <>
          <SourceCandidatesMultiSelectFilter
            title='Schools'
            field='educationSchools'
            currentFilter={(filters.educationSchools ?? []).map((s) => ({
              field: 'educationSchools',
              ...parseTerm(s),
            }))}
            onFilterUpdated={(educationSchools) => {
              setFilters({
                ...filters,
                educationSchools: educationSchools.map(applyTerm),
              });
            }}
          />
          <SourceCandidatesMultiSelectFilter
            css={{ my: 16 }}
            title='Degrees'
            field='educationDegrees'
            currentFilter={(filters.educationDegrees ?? []).map((m) => ({
              field: 'educationDegrees',
              ...parseTerm(m),
            }))}
            onFilterUpdated={(educationDegrees) => {
              setFilters({
                ...filters,
                educationDegrees: educationDegrees.map(applyTerm),
              });
            }}
          />
          <SourceCandidatesMultiSelectFilter
            title='Majors'
            field='educationMajors'
            currentFilter={(filters.educationMajors ?? []).map((m) => ({
              field: 'educationMajors',
              ...parseTerm(m),
            }))}
            onFilterUpdated={(educationMajors) => {
              setFilters({
                ...filters,
                educationMajors: educationMajors.map(applyTerm),
              });
            }}
          />
          <SourceCandidatesMultiSelectFilter
            css={{ mt: 16 }}
            title='Certifications'
            field='certifications'
            currentFilter={(filters.certifications ?? []).map((s) => ({
              field: 'certifications',
              ...parseTerm(s),
            }))}
            onFilterUpdated={(certifications) => {
              setFilters({
                ...filters,
                certifications: certifications.map(applyTerm),
              });
            }}
          />
        </>
      </SourcingFilterGroup>
      <SourcingFilterGroup
        groupTitle='Companies'
        filterKeys={[
          'companyNamesCurrent',
          'companyNamesPast',
          'companyNamesCurrentOrPast',
          'currentCompanySizes',
          'currentOrPastCompanySizes',
          'industries',
        ]}
      >
        <>
          <SourceCandidatesMultiSelectFilter
            title='Current Companies'
            placeholder='Enter Company Name'
            field='companies'
            currentFilter={(filters.companyNamesCurrent || []).map(
              (companyName) => ({
                field: 'companies',
                ...parseTerm(companyName),
              }),
            )}
            onFilterUpdated={(companies) => {
              setFilters({
                ...filters,
                companyNamesCurrent: companies.map(applyTerm),
              });
            }}
          />
          <SourceCandidatesMultiSelectFilter
            css={{ my: 16 }}
            title='Past Companies'
            placeholder='Enter Company Name'
            field='companies'
            currentFilter={(filters.companyNamesPast || []).map(
              (companyName) => ({
                field: 'companies',
                ...parseTerm(companyName),
              }),
            )}
            onFilterUpdated={(companies) => {
              setFilters({
                ...filters,
                companyNamesPast: companies.map(applyTerm),
              });
            }}
          />
          <SourceCandidatesMultiSelectFilter
            css={{ my: 16 }}
            title='Current or Past Companies'
            placeholder='Enter Company Name'
            field='companies'
            currentFilter={(filters.companyNamesCurrentOrPast || []).map(
              (companyName) => ({
                field: 'companies',
                ...parseTerm(companyName),
              }),
            )}
            onFilterUpdated={(companies) => {
              setFilters({
                ...filters,
                companyNamesCurrentOrPast: companies.map(applyTerm),
              });
            }}
          />
          <SourceCandidatesMultiSelectFilter
            css={{ my: 16 }}
            placeholder='Enter Company Size'
            title='Current Company Sizes'
            field='currentCompanySizes'
            currentFilter={(filters.currentCompanySizes || []).map((size) => ({
              field: 'currentCompanySizes',
              ...parseTerm(size),
            }))}
            selectValues={companySizeOptions}
            onFilterUpdated={(sizes) => {
              setFilters({
                ...filters,
                currentCompanySizes: sizes.map(applyTerm),
              });
            }}
          />
          <SourceCandidatesMultiSelectFilter
            css={{ my: 16 }}
            placeholder='Enter Company Size'
            title='Current or Past Company Sizes'
            field='currentOrPastCompanySizes'
            currentFilter={(filters.currentOrPastCompanySizes || []).map(
              (size) => ({
                field: 'currentOrPastCompanySizes',
                ...parseTerm(size),
              }),
            )}
            selectValues={companySizeOptions}
            onFilterUpdated={(sizes) => {
              setFilters({
                ...filters,
                currentOrPastCompanySizes: sizes.map(applyTerm),
              });
            }}
          />
          <SourceCandidatesMultiSelectFilter
            title='Industries'
            field='industries'
            currentFilter={(filters.industries ?? []).map((s) => ({
              field: 'industries',
              ...parseTerm(s),
            }))}
            onFilterUpdated={(industries) => {
              setFilters({
                ...filters,
                industries: industries.map(applyTerm),
              });
            }}
          />
        </>
      </SourcingFilterGroup>
      {showHealthcareFilters && (
        <SourcingFilterGroup
          groupTitle='Healthcare'
          filterKeys={['licensedStates']}
        >
          <LicensedInStateFilter />
        </SourcingFilterGroup>
      )}
      <SourcingFilterGroup
        groupTitle='Other'
        filterKeys={['summaryKeywords', 'gender', 'name', 'boolean']}
      >
        <SourcingInputLabel
          id='filter-by-name'
          htmlFor='sourcing-name-filter'
          css={{ mb: 16 }}
        >
          Candidate Name
        </SourcingInputLabel>
        <Input
          css={{ mb: 16 }}
          id='sourcing-name-filter'
          placeholder='Enter Name'
          aria-label='search by name'
          value={filters.name ?? ''}
          onChange={(value) => {
            setFilters({
              ...filters,
              name: value ?? undefined,
            });
          }}
        />
        <SourceCandidatesInputFilter
          title='Keywords'
          field='summaryKeywords'
          placeholder='Enter Keywords'
          aria-label='filter by keywords'
          currentFilter={(filters.summaryKeywords || []).map((keyword) => ({
            field: 'summaryKeywords',
            ...parseTerm(keyword),
          }))}
          onFilterUpdated={(keywords) => {
            setFilters({
              ...filters,
              summaryKeywords: keywords.map(applyTerm),
            });
          }}
        />
        <GenderFilter css={{ mt: 16 }} />
        <Box css={{ mt: 16, width: '100%' }}>
          <SourcingFilterTitle title='Boolean' />
          <TextArea
            placeholder='Paste your boolean here...'
            value={filters.boolean ?? ''}
            onChange={(value) => {
              setFilters({
                ...filters,
                boolean: value || undefined,
              });
            }}
            css={{
              border: '1px solid $neutral-blue-400',
              borderRadius: '$2xl',
              p: '8px 16px',
            }}
          />
        </Box>
      </SourcingFilterGroup>
    </Box>
  );
};

export default SourceCandidatesFilterArea;
