import { Recruiter, RecruiterDto } from '@betterleap/client';
import {
  RecruiterProfileCard,
  RecruiterProfilePage,
} from './RecruiterProfile.elements';
import { RecruiterProfileAbout } from './RecruiterProfileAbout';
import { RecruiterProfileClients } from './Clients/RecruiterProfileClients';
import { RecruiterProfileHeader } from './RecruiterProfileHeader';
import { RecruiterProfileTestimonials } from './Testimonials/RecruiterProfileTestimonials';

interface RecruiterProfileProps {
  recruiter: Recruiter;
  onUpdate?: (dto: RecruiterDto) => void;
}

export const RecruiterProfile = (props: RecruiterProfileProps) => {
  const { recruiter, onUpdate } = props;
  const clients = recruiter.pastRecruiterClients || [];
  const testimonials = recruiter.recruiterTestimonals || [];
  return (
    <RecruiterProfilePage isViewOnly={!onUpdate}>
      <RecruiterProfileCard>
        <RecruiterProfileHeader recruiter={recruiter} onUpdate={onUpdate} />
        <RecruiterProfileAbout recruiter={recruiter} onUpdate={onUpdate} />
      </RecruiterProfileCard>
      <RecruiterProfileCard>
        <RecruiterProfileClients clients={clients} onUpdate={onUpdate} />
      </RecruiterProfileCard>
      <RecruiterProfileCard>
        <RecruiterProfileTestimonials
          testimonials={testimonials}
          onUpdate={onUpdate}
        />
      </RecruiterProfileCard>
    </RecruiterProfilePage>
  );
};
