import { Tooltip } from '@betterleap/ui';
import {
  ExtendedContactProject,
  ProjectWithStatsDto,
} from '@betterleap/client';
import { Row } from 'react-table';
import { ProjectActionButton } from '../../ProjectActions';
import { resolveSubject } from '../../ProjectDetails.functions';
import { usePauseOrResumeSequence } from '../Hooks/usePauseOrResumeSequence';

interface Props {
  project: ProjectWithStatsDto;
  selectedContacts: Row<Record<string, unknown>>[] | 'all';
  onSequencesPaused: () => void;
}

export const PauseSequenceButton = ({
  project,
  selectedContacts,
  onSequencesPaused,
}: Props) => {
  const { pauseOrResumeSequence } = usePauseOrResumeSequence({
    project,
    selectedContacts,
    onPauseOrResumeSequences: onSequencesPaused,
  });
  function canPauseSequences() {
    return (
      selectedContacts === 'all' ||
      selectedContacts.some((c) => {
        const contact = c.original as ExtendedContactProject;
        const isDeactivated = !!contact.contactSequenceDeactivatedReason;
        const isFinished = !!contact.contactSequenceFinishedReason;
        return !!contact.contactSequenceId && !isDeactivated && !isFinished;
      })
    );
  }
  return (
    <Tooltip content='Pause sequence'>
      <ProjectActionButton
        onClick={() => pauseOrResumeSequence(true)}
        disabled={!project.sequenceId || !canPauseSequences()}
        name='pause'
        label={`Pause sequence for ${resolveSubject(project)}.`}
      />
    </Tooltip>
  );
};
