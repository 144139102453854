import { Step } from '@betterleap/client';
import { RefinementEffect, z } from 'zod';
import { requiredString } from './helpers';

const timeRegExp = /((^(0?[1-9]|[1][0-2]):[0-5][0-9])\s(AM|PM)|\d\d:\d\d:\d\d)/;

const validateEmailStep = (
  step: Sequence['steps'][0],
  index: number,
  ctx: z.RefinementCtx,
) => {
  // If text is deleted from editor, body is not empty string, but equal to <div><br/></div>
  if (!step.body || step.body === '<div><br></div>') {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Body is required.',
      path: [index, 'body'],
    });
  }

  if (step.type === Step.type.EMAIL) {
    if (!step.subject) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Subject is required.',
        path: [index, 'subject'],
      });
    }

    if (index === 0 && !step.sendAfterTime) {
      return;
    }

    if (!step.sendAfterTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Send time is required.',
        path: [index, 'sendAfterTime'],
      });
    }

    if (!timeRegExp.test(step.sendAfterTime as string)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Send Time must be in format h:mm.',
        path: [index, 'sendAfterTime'],
      });
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validateSteps: RefinementEffect<any[]>['refinement'] = (steps, ctx) => {
  steps.forEach((step, index) => {
    validateEmailStep(step, index, ctx);
  });
};

export const SequenceSchema = z.object({
  name: requiredString('Name'),
  sender: z.string().optional(),
  sequenceSchedule: z.array(z.string()),
  useDefaultSignature: z.boolean().optional(),
  sendOnHolidays: z.boolean().optional(),
  steps: z
    .array(
      z.object({
        subject: z.string().optional(),
        body: z.string().min(1, { message: 'Body is required' }),
        sendAfterTime: z.string().optional().nullable(),
        type: z.nativeEnum(Step.type),
        attachments: z
          .array(
            z.object({
              fileId: z.string(),
            }),
          )
          .optional(),
        waitTimeCalendarDays: z
          .number({
            required_error: `Schedule Delay is required.`,
            invalid_type_error: 'Schedule Delay must be a number.',
          })
          .gte(0),
        waitBusinessDaysOnly: z.boolean().optional(),
      }),
    )
    .superRefine(validateSteps),
});

export const UpdateSequenceSchema = z.object({
  name: requiredString('Name'),
  sender: z.string().optional(),
  sequenceSchedule: z.array(z.string()),
  useDefaultSignature: z.boolean().optional(),
  sendOnHolidays: z.boolean().optional(),
  steps: z
    .array(
      z.object({
        id: z.string().optional(),
        subject: z.string().optional(),
        body: z.string().min(1, { message: 'Body is required' }),
        sendAfterTime: z.string().optional().nullable(),
        type: z.nativeEnum(Step.type),
        attachments: z
          .array(
            z.object({
              id: z.string().optional(),
              fileId: z.string(),
            }),
          )
          .optional(),
        waitTimeCalendarDays: z
          .number({
            required_error: `Schedule Delay is required.`,
            invalid_type_error: 'Schedule Delay must be a number',
          })
          .gte(0),
        waitBusinessDaysOnly: z.boolean().optional(),
      }),
    )
    .superRefine(validateSteps),
});

export type Sequence = z.infer<typeof SequenceSchema>;
export type UpdateSequence = z.infer<typeof UpdateSequenceSchema>;
