import { Types } from '@betterleap/shared';
import { CreateGroupDto } from '@betterleap/client';
import { Button, showToast, useModal } from '@betterleap/ui';
import EnterGroupNameModal, {
  EnterGroupNameProps,
  EnterGroupNameResult,
} from 'components/modules/Modal/EnterGroupNameModal/EnterGroupNameModal';
import useAnalytics from 'hooks/analytics';
import { apiClient } from 'lib/apiClient';
import { useMutation } from 'react-query';

interface CreateGroupButtonProps {
  onGroupCreated: () => void;
}

export const CreateGroupButton = ({
  onGroupCreated,
}: CreateGroupButtonProps) => {
  const { track } = useAnalytics();

  const createGroup = useMutation(
    (requestBody: CreateGroupDto) =>
      apiClient.group.createGroup({ requestBody }),
    {
      onSuccess: () => {
        onGroupCreated();
      },
    },
  );

  const handleCreateGroup = async (data?: EnterGroupNameResult) => {
    if (!data) {
      return;
    }
    try {
      await createGroup.mutateAsync(data);
      showToast({
        title: 'Group successfully created!',
        description: 'You can now add team members to this group.',
      });
    } catch (e) {
      showToast({
        variant: 'danger',
        title: 'Something went wrong!',
        description: 'Failed to create group. Please try again.',
      });
    }
  };

  const openCreateGroupModal = useModal<
    EnterGroupNameProps,
    EnterGroupNameResult
  >(EnterGroupNameModal);

  const onCreateGroupClick = async () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Create group',
      buttonLocation: 'Team',
    });
    const result = await openCreateGroupModal({
      title: 'Create Group',
      description: 'Groups help you manage and see stats for different teams.',
      confirmButtonText: 'Create',
    });
    handleCreateGroup(result);
  };

  return (
    <Button
      variant='secondary'
      css={{
        mb: 16,
        mt: 16,
        width: '100%',
        maxWidth: 300,
        mediaMd: { mb: 0, mt: 0, ml: 8, width: 'unset' },
      }}
      onClick={onCreateGroupClick}
    >
      Create group
    </Button>
  );
};
