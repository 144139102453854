import { Box, BoxProps, Text } from '@betterleap/ui';
import { ExcludeBadge } from './ExcludeBadge';
import {
  betterStartCase,
  filterItemsEqual,
  hideExclusionPrefix,
} from './SourceCandidates.functions';
import { FilterItem } from './SourceCandidates.types';

export interface SourceCandidatesMultiSelectFilterProps extends BoxProps {
  title?: string;
  field: string;
  currentFilter?: FilterItem[];
  onFilterUpdated: (newFilter: FilterItem[]) => void;
  selectValues?: FilterItem[];
  placeholder?: string;
  canExclude?: boolean;
}

export const SourcingFilterTitle = ({ title }: { title?: string }) => {
  if (!title) {
    return null;
  }
  return (
    <Text
      id={`filter-${title}`}
      size='sm'
      css={{ fontWeight: '$medium', mb: 8, color: '$text-light' }}
    >
      {title}
    </Text>
  );
};

interface FilterBadgesProps {
  items: FilterItem[];
  canExclude?: boolean;
  onFilterUpdated: (newFilter: FilterItem[]) => void;
}

export const SourcingFilterBadges = ({
  items,
  canExclude,
  onFilterUpdated,
}: FilterBadgesProps) => {
  return (
    <Box css={{ mb: 6 }}>
      {items.map((attribute) => (
        <ExcludeBadge
          key={attribute.name}
          variant='violet'
          canExclude={canExclude}
          isExcluded={attribute.excluded ?? false}
          onExclude={(excluded) => {
            const newFilterItems = items.map((v) => {
              if (filterItemsEqual(v, attribute)) {
                return {
                  name: v.name,
                  field: v.field,
                  excluded,
                };
              }
              return v;
            });
            onFilterUpdated(newFilterItems);
          }}
          onRemove={() => {
            onFilterUpdated(
              items.filter((v) => !filterItemsEqual(v, attribute)),
            );
          }}
          css={{ mr: 4, mt: 4, whiteSpace: 'balance' }}
        >
          {betterStartCase(hideExclusionPrefix(attribute.name))}
        </ExcludeBadge>
      ))}
    </Box>
  );
};
