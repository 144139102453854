import { useState } from 'react';
import { atom, useAtom } from 'jotai';
import { showToast } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { ContactPreviewSearchResult } from 'components/templates/Sourcing/SourceCandidates.template';
import { ProfileSummaryRequest } from '@betterleap/client';

export const contactSummariesState = atom(new Map());

function isEmpty(contact: Partial<ContactPreviewSearchResult>) {
  return Object.keys(contact).length === 1 && 'contactId' in contact;
}

export const useContactSummary = (
  contact: Partial<ContactPreviewSearchResult> | undefined,
) => {
  const shouldHideSummary = !contact || isEmpty(contact);

  const [loadingSummary, setLoadingSummary] = useState(false);
  const [summaryError, setSummaryError] = useState(shouldHideSummary);
  const [contactSummaries, setContactSummaries] = useAtom(
    contactSummariesState,
  );

  const fetchSummaryForUser = async () => {
    if (!contact) return;

    setLoadingSummary(true);
    setSummaryError(false);

    try {
      const { data } = await apiClient.personSearch.generateProfileSummary({
        requestBody: contact as ProfileSummaryRequest,
      });
      setContactSummaries(new Map(contactSummaries.set(contact?.id, data)));
    } catch {
      showToast({
        variant: 'danger',
        title: 'Something went wrong!',
        description:
          'Failed to generate the profile summary. Please try again later.',
      });
      setSummaryError(true);
    } finally {
      setLoadingSummary(false);
    }
  };

  return {
    loadingSummary,
    summaryError,
    contactSummaries,
    fetchSummaryForUser,
  };
};
