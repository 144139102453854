import { getFullName } from '@betterleap/shared';
import { Sender } from '@betterleap/client';
import {
  Badge,
  BadgeProps,
  IconButton,
  Menu,
  MenuArrow,
  MenuContent,
  MenuItem,
  MenuTrigger,
  Text,
} from '@betterleap/ui';

export const senderTableColumns = ({
  onReconnect,
  onRemove,
}: {
  onRemove: (sender: Sender) => void;
  onReconnect: (sender: Sender) => void;
}) => [
  {
    id: 'name',
    Header: 'NAME',
    accessor: (sender: Sender) => {
      return (
        <Text css={{ fontSize: '$sm', color: '$text-secondary' }}>
          {sender.name || getFullName(sender.user)}
        </Text>
      );
    },
    width: 250,
  },
  {
    id: 'email',
    Header: 'EMAIL',
    accessor: (sender: Sender) => {
      return (
        <Text css={{ fontSize: '$sm', color: '$text-secondary' }}>
          {sender.email}
        </Text>
      );
    },
    width: 250,
  },
  {
    id: 'status',
    Header: 'STATUS',
    accessor: (sender: Sender) => {
      let badgeProperties: { text: string; variant: BadgeProps['variant'] };

      switch (sender.status) {
        case Sender.status.ERROR:
          badgeProperties = {
            variant: 'danger',
            text: 'Disconnected',
          };

          break;
        case Sender.status.PAUSED_TEMPORARILY:
          badgeProperties = {
            variant: 'warning',
            text: 'Temporary Pause',
          };

          break;
        default:
          badgeProperties = {
            variant: 'success',
            text: 'Connected',
          };
      }

      return (
        <Badge variant={badgeProperties.variant} size='base'>
          {badgeProperties.text}
        </Badge>
      );
    },
    width: 250,
  },
  {
    id: 'actions',
    Header: '',
    accessor: (sender: Sender) => {
      const hasError = sender.status === Sender.status.ERROR;
      const canReconnect = hasError || !sender.userinfo;
      return (
        <Menu modal={false}>
          <MenuTrigger asChild>
            <IconButton
              label='open menu'
              name='dots-vertical'
              color='$neutral-blue-600'
              dataCy='Dot Edit Button'
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </MenuTrigger>
          <MenuContent align='end'>
            <MenuArrow offset={14} />
            {canReconnect && (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onReconnect(sender);
                }}
              >
                Reconnect
              </MenuItem>
            )}
            <MenuItem
              css={{
                color: '$text-danger',
              }}
              onClick={(e) => {
                e.stopPropagation();
                onRemove(sender);
              }}
            >
              Remove
            </MenuItem>
          </MenuContent>
        </Menu>
      );
    },
    css: {
      maxWidth: 60,
    },
    headerCss: {
      maxWidth: 60,
    },
    slot: 'custom',
    width: 60,
  },
];
