import {
  DEFAULT_SEQUENCE_SCHEDULE,
  Spinner,
  StepEditor,
  StepEditorProps,
  StepError,
  useUpload,
  useWatchSubject,
  valueToSequenceSchedule,
} from '@betterleap/shared';
import {
  CreateSequenceDto,
  Sender,
  Sequence as SequenceType,
  Step,
} from '@betterleap/client';
import {
  BackButton,
  Box,
  Button,
  BoundCheckbox as Checkbox,
  Combobox,
  Flex,
  Form,
  Icon,
  BoundInput as Input,
  Label,
  Option,
  showToast,
  Text,
  useModal,
} from '@betterleap/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import routeNames from '../../../constants/routeNames';
import useFetch from '../../../hooks/fetch';
import usePrompt from '../../../hooks/useLeavePagePrompt';
import api from '../../../lib/api';
import { apiClient } from '../../../lib/apiClient';
import { Sequence, SequenceSchema } from '../../../schemas/Sequence';
import { Tip } from '../../elements/Tip/Tip';
import {
  AlertModal,
  AlertModalProps,
} from '../../modules/Modal/AlertModal/AlertModal';
import { AddStepButtons } from './AddStepButtons';
import { CloneSequence } from './CloneSequence';
import { constructErrorToast } from './constructErrorToast';
import { CreateSequenceEmptyState } from './CreateSequenceEmptyState';
import { SequenceScheduleSelector } from './SequenceScheduleSelector';
import { useSendTestEmail } from './useSendTestEmail';

const resolver = zodResolver(SequenceSchema);

interface CreateSequenceResult {
  sequence: Sequence & { projectId: string };
}

const CreateSequence = (): JSX.Element => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { projectId } = qs.parse(search.slice(1));

  const openAlertModal = useModal<AlertModalProps>(AlertModal);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    getValues,
    formState,
    reset,
  } = useForm<Sequence>({
    defaultValues: {
      name: undefined,
      sender: undefined,
      useDefaultSignature: true,
      sendOnHolidays: false,
      sequenceSchedule: DEFAULT_SEQUENCE_SCHEDULE,
      steps: [],
    },
    resolver,
  });

  const { fields, append, remove, replace } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'steps', // unique name for your Field Array
  });
  const [signature, setSignature] = useState<string>();
  const [, doUpload, ,] = useUpload(
    useFetch,
    `users/{userUid}/public/sequence`,
    'save_sequence_photo',
  );
  const currentStepsValue = watch('steps');

  const createSequence = useMutation(
    (data: CreateSequenceDto) =>
      api.fetch<{ data: CreateSequenceResult }>('create_sequence', data),
    {
      onError: (axiosError: AxiosError) => {
        const { title, description } = constructErrorToast(axiosError);
        showToast({ title, description, variant: 'danger' });
      },
    },
  );

  const {
    data: senderResponse,
    isFetched: isSenderFetched,
    isLoading: isSenderLoading,
  } = useQuery(['get_sender'], () => apiClient.sender.getSender());

  const { data: project, isLoading: isProjectloading } = useQuery(
    ['get_project', projectId],
    () =>
      apiClient.project.findOneWithSequenceStats({
        projectId: projectId as string,
      }),
  );

  const projectName = project?.data.name;

  const [senderId, setSenderId] = useState<string | undefined>(undefined);
  const {
    isLoading: isSendersLoading,
    isFetched: isSendersFetched,
    data: sendersData,
    error: getSendersError,
  } = useQuery(['get_organization_senders'], () =>
    api.fetch<{ data: Sender[] }>('get_organization_senders'),
  );

  const usersSender = senderResponse?.data;
  const senders = sendersData?.data?.data || [];

  useEffect(() => {
    if (!senderId && isSenderFetched && isSendersFetched) {
      setSenderId(
        senders.find((s: Sender) => s.id === usersSender?.id)?.id ??
          senders[0]?.id,
      );
    }
  }, [senderResponse, sendersData]);

  const sender = useMemo(
    () => senders.find((s: Sender) => s.id === senderId),
    [senderId, senders],
  );

  useEffect(() => {
    if (senders.length) {
      setSignature(sender?.signature);
      reset({
        ...getValues(),
      });
    }
  }, [sender]);

  useWatchSubject({ watch, trigger, setValue });
  const sendTestEmail = useSendTestEmail();

  const handleFileUpload = async (file: File) => {
    if (file) {
      const result = await doUpload([file]);
      return result?.data?.[0]?.url as string;
    }
    return null;
  };

  const onSubmit: SubmitHandler<Sequence> = async (data) => {
    if (!projectId) {
      showToast({
        variant: 'danger',
        title: 'Sequence not created!',
        description: 'A sequence must have a corresponding project',
      });

      return;
    }
    const response = await createSequence.mutateAsync({
      ...data,
      sequenceSchedule: valueToSequenceSchedule(data.sequenceSchedule),
      sender: senderId as string,
      steps: data.steps.map((step) => ({
        ...step,
        subject: step.subject ?? '',
        body: step.body ?? '',
        sendAfterTime:
          step.sendAfterTime === undefined ? null : step.sendAfterTime,
      })),
      projectId: projectId as string,
    });

    const result = response.data.data;

    const newSequence = result.sequence;

    await openAlertModal({
      title: 'Sequence Created',
      description: (
        <>
          Add contacts to this sequence to initiate scheduled messaging. If a
          contact replies, they will no longer receive messages from the
          sequence.
        </>
      ),
      confirmationText: 'Got it!',
      icon: {
        variant: 'success',
        name: 'check',
      },
    });

    navigate(routeNames.project({ id: newSequence.projectId }));
  };

  const handleApplyCloneSequence = (sequenceToClone: SequenceType) => {
    replace(
      sequenceToClone.steps.map((step) => ({
        ...step,
        from: sequenceToClone.sender?.email,
      })),
    );
  };

  const handleResetSequence = () => {
    replace([
      {
        subject: '',
        body: '',
        type: Step.type.EMAIL,
        waitTimeCalendarDays: 0,
      },
    ]);
  };

  const handleAddStepClick = (type: Step.type) => {
    const steps = getValues('steps');
    const lastStepIndex = steps?.length - 1;

    const emailSteps = steps.filter((step) => step.type === Step.type.EMAIL);
    const lastSubject = emailSteps[emailSteps.length - 1]?.subject ?? '';

    const waitBusinessDaysOnly = getValues(
      `steps.${lastStepIndex}.waitBusinessDaysOnly`,
    );

    // add new step with same subject and from as the last step in the sequence
    append({
      waitTimeCalendarDays: steps.length ? (undefined as unknown as number) : 0,
      waitBusinessDaysOnly: !!waitBusinessDaysOnly,
      subject: type === Step.type.EMAIL ? lastSubject : '',
      type,
      body: '',
      sendAfterTime: type === Step.type.EMAIL ? '8:30 AM' : undefined,
    });
  };

  usePrompt(
    'Are you sure you want to leave this page? You have unsaved changes.',
    formState.isDirty &&
      !formState.isSubmitting &&
      !isEmpty(formState.touchedFields),
  );

  if (isSendersLoading || isProjectloading || isSenderLoading) {
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }

  if (getSendersError) {
    throw getSendersError;
  }

  return (
    <Box
      css={{
        p: 16,
        pb: 70,
        mediaLg: {
          p: 0,
          pb: 70,
        },
      }}
    >
      <BackButton route={projectName as string} onClick={() => navigate(-1)} />
      <Form
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        control={control as any}
        submitOnEnter={false}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex justify='between' align='center' css={{ mb: 24 }}>
          <Text
            as='h1'
            css={{
              fontSize: '$3xl',
              lineHeight: '$4xl',
              fontWeight: '$medium',
              color: '$neutral-blue-900',
            }}
          >
            Create Sequence
          </Text>
        </Flex>
        <Flex
          justify='between'
          css={{
            mb: 16,
            backgroundColor: 'white',
            boxShadow: '$base',
            borderRadius: '$lg',
            padding: 20,
            columnGap: 24,
            alignItems: 'flex-start',
          }}
          wrap
        >
          <Box
            css={{
              flex: '1',
            }}
          >
            <Input
              label='Sequence Title'
              placeholder='Frontend Engineer'
              name='name'
              id='sequence-name'
              required
            />
          </Box>
          <Flex
            vertical
            css={{
              flex: '1',
            }}
          >
            <Label
              id='sequence-sender-label'
              css={{ mb: 8, display: 'inline-block' }}
              htmlFor='sequence-sender'
            >
              From
            </Label>
            <Combobox
              aria-labelledby='sequence-sender-label'
              id='sequence-sender'
              placeholder='From'
              defaultValue={senderId}
              value={senderId}
              options={senders}
              leftIcon={<Icon name='mail' css={{ ml: -4, mr: 6 }} />}
              disabled={!senders.length}
              clearable={false}
              onChange={(keys, selected) => {
                setSenderId(selected?.[0]?.id);
              }}
              css={{ height: 38, flexGrow: 1 }}
            >
              {(senderOption) => (
                <Option key={senderOption.id} css={{ p: 8, px: 16 }}>
                  <div>
                    <Text>{senderOption.email}</Text>
                  </div>
                </Option>
              )}
            </Combobox>
          </Flex>
          <Flex vertical css={{ flex: '1' }}>
            <Label css={{ mb: 8 }}>Use Default Signature</Label>
            <Flex css={{ height: 36 }}>
              <Checkbox id='use-default-signature' name='useDefaultSignature'>
                Include your signature in emails
              </Checkbox>
            </Flex>
          </Flex>
          <Flex css={{ flexBasis: '100%' }}>
            <SequenceScheduleSelector
              id='sequence-schedule'
              name='sequenceSchedule'
              label='Send On:'
            />
            <Flex css={{ ml: 32, mt: 28 }}>
              <Checkbox id='send-on-holidays' name='sendOnHolidays'>
                Send on holidays
              </Checkbox>
              <Tip
                css={{ marginLeft: 8 }}
                content='Emails will send on US federal holidays.'
              />
            </Flex>
          </Flex>
        </Flex>
        <CloneSequence
          onApply={handleApplyCloneSequence}
          onReset={handleResetSequence}
        />
        {fields.length > 0 ? (
          fields.map((item, index) => (
            <Box key={item.id} css={{ mb: 40 }}>
              <Controller
                control={control}
                name={`steps.${index}`}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <StepEditor
                      dataCy='Step editor field'
                      error={error as StepEditorProps['error']}
                      stepNumber={index + 1}
                      onChange={onChange}
                      onBlur={onBlur}
                      type={value.type}
                      onRemove={() => remove(index)}
                      defaultValue={value}
                      removable={true}
                      sender={senderId}
                      onSendTestEmail={sendTestEmail}
                      onFileUpload={handleFileUpload}
                      signature={signature}
                      useDefaultSignature={getValues().useDefaultSignature}
                    />
                    <StepError error={error as StepEditorProps['error']} />
                  </>
                )}
              />
            </Box>
          ))
        ) : (
          <CreateSequenceEmptyState />
        )}
        <AddStepButtons
          label={currentStepsValue.length ? 'Add step' : ''}
          onAddStep={handleAddStepClick}
        />
        <Flex
          css={{
            py: 16,
            px: 32,
            backgroundColor: '$background-component',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            boxShadow: '$md',
            zIndex: '$10',
          }}
          justify='end'
        >
          <Button
            type='submit'
            disabled={createSequence.isLoading || !currentStepsValue.length}
          >
            Done
          </Button>
        </Flex>
      </Form>
    </Box>
  );
};

export default CreateSequence;
