import { getFullName, Spinner, Types } from '@betterleap/shared';
import { OnboardDto, Organization, User } from '@betterleap/client';
import {
  AlertIcon,
  Box,
  BoxProps,
  Button,
  Col,
  Flex,
  Form,
  Icon,
  BoundInput as Input,
  Label,
  RadioCard,
  RadioCardProps,
  BoundRadioGroup as RadioGroup,
  RadioIndicator,
  Row,
  showToast,
  Text,
  TextProps,
} from '@betterleap/ui';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useForm } from 'react-hook-form';
import routeNames from '../../../constants/routeNames';
import { apiClient } from '../../../lib/apiClient';
import useFetch from '../../../hooks/fetch';
import TOKEN from '../../../lib/token';
import onboardingImage from '../../../assets/images/onboarding-abstract.png';
import onboardingImageMobile from '../../../assets/images/onboarding-abstract-mobile.png';
import logoPurple from '../../../assets/images/betterleap_logo_purple.svg';
import { OnboardingGoal } from './OnboardingGoal';
import { OnboardingTools } from './OnboardingTools';

const OnBoardingHeader = (props: TextProps) => (
  <Text
    css={{
      fontSize: '$2xl',
      fontWeight: '$medium',
      color: '$purple-600',
      mb: 24,
    }}
    {...props}
  />
);

const OnboardingRadioIndicator = () => (
  <RadioIndicator
    css={{
      height: 24,
      width: 24,
      borderRadius: '$lg',
      selected: {
        background: '$purple-600',
      },
    }}
  >
    <Icon name='check' color='$background-component' size={16} />
  </RadioIndicator>
);

const OnBoardingHero = (props: BoxProps) => (
  <Box {...props}>
    <img src={logoPurple} alt='logo' aria-hidden />
    <Text
      size='4xl'
      css={{
        color: 'White',
        mb: 24,
        mt: 40,
        fontWeight: '$medium',
        '@lg': {
          mt: 105,
        },
      }}
    >
      Welcome to Betterleap!
    </Text>
    <Text css={{ color: 'White' }}>
      The all-in-one recruiting
      <br /> collaboration platform
    </Text>
  </Box>
);

const OnboardingRadioCard = (props: RadioCardProps) => (
  <RadioCard
    css={{
      px: 32,
      pt: 16,
      pb: 40,
      height: 200,
      borderColor: '$neutral-blue-400',
      backgroundColor: '$neutral-blue-100',
      borderRadius: '$5xl',
      focus: {
        boxShadow: '$focusPurple',
      },
      hover: {
        backgroundColor: '$neutral-blue-200',
        checked: {
          backgroundColor: '$purple-200',
        },
      },
      checked: {
        backgroundColor: '$purple-200',
        border: '1px solid $purple-200',
      },
    }}
    {...props}
  />
);

interface OnboardingForm {
  fullName: string;
  companyName: string;
  orgType: OnboardDto.orgType;
  goal: OnboardDto.goal;
  otherGoal?: string;
  tools: string;
}

const OnboardingTemplate = (): JSX.Element => {
  const [me, doFetchMe] = useFetch<User>('me');
  const navigate = useNavigate();
  const location = useLocation();

  const { control, handleSubmit, formState, setValue, watch } =
    useForm<OnboardingForm>({
      defaultValues: {
        companyName: '',
        fullName: '',
        orgType: OnboardDto.orgType.RECRUITER,
        otherGoal: '',
        tools: '',
      },
    });
  const currentGoal = watch('goal');

  const onboard = useMutation(
    (data: OnboardDto) =>
      apiClient.auth.onboard({
        requestBody: data,
      }),
    {
      onSuccess: () => {
        doFetchMe();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to create company. Please try again.',
        });
      },
    },
  );

  const token = TOKEN.get();
  const userNotAuthenticated = !token || (token && me.error);

  const shouldRedirectToSignin = userNotAuthenticated;
  const shouldRedirectToHome =
    token && me.data && !isEqual(me.data?.role, Types.ROLE.NONE);

  useEffect(() => {
    if (!formState.isDirty && me.data) {
      setValue(
        'fullName',
        getFullName({
          firstName: me.data.firstName,
          lastName: me.data.lastName,
        }),
      );
    }
  }, [me]);

  useEffect(() => {
    if (shouldRedirectToSignin) {
      navigate(routeNames.signin());
    }
  }, [shouldRedirectToSignin]);

  useEffect(() => {
    if (shouldRedirectToHome) {
      navigate(routeNames.home(), {
        state: { redirectUrl: location },
      });
    }
  }, [shouldRedirectToHome]);

  const onSubmit = (data: OnboardingForm) => {
    onboard.mutate({
      companyName: data.companyName,
      fullName: data.fullName,
      orgType: data.orgType,
      goal: data.goal,
      otherGoal: data.otherGoal,
      tools: data.tools,
    });
  };

  if (me.loading) {
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }

  return (
    <Box
      css={{
        display: 'grid',
        gridTemplateAreas: `
        "main"
      `,
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        height: '100vh',
        '@lg': {
          gridTemplateAreas: `
          "side main"
        `,
          gridTemplateColumns: 'minmax(275px, 1fr) 4fr',
        },
      }}
    >
      <OnBoardingHero
        css={{
          gridArea: 'side',
          backgroundImage: `url(${onboardingImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          p: 40,
          pr: 16,
          display: 'none',
          '@lg': {
            display: 'block',
          },
        }}
      />
      <Box
        css={{
          gridArea: 'main',
          px: 24,
          '@sm': {
            px: 80,
          },
          '@lg': {
            p: 64,
            pl: 120,
            pr: 64,
          },
        }}
      >
        <OnBoardingHero
          css={{
            backgroundImage: `url(${onboardingImageMobile})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            p: 40,
            pr: 16,
            mx: -24,
            mb: 40,
            display: 'block',
            minHeight: 293,
            '@sm': {
              mx: -80,
            },
            '@md': {
              minHeight: 350,
            },
            '@lg': {
              display: 'none',
            },
          }}
        />
        <Form
          css={{ maxWidth: 900 }}
          control={control}
          onSubmit={handleSubmit(onSubmit)}
        >
          <OnBoardingHeader>My details</OnBoardingHeader>
          <Row css={{ mb: 16 }}>
            <Col
              span={{
                '@initial': 12,
                '@lg': 6,
              }}
            >
              <Input
                id='fullName'
                name='fullName'
                placeholder='Full name'
                required
                label={
                  <Label css={{ color: '$neutral-blue-600', mb: 8 }}>
                    Your full name
                  </Label>
                }
                size='xl'
              />
            </Col>
            <Col
              span={{
                '@initial': 12,
                '@lg': 6,
              }}
            >
              <Input
                id='company'
                name='companyName'
                required
                placeholder='Company name'
                label={
                  <Label css={{ color: '$neutral-blue-600', mb: 8 }}>
                    Your company
                  </Label>
                }
                size='xl'
              />
            </Col>
          </Row>
          <OnBoardingHeader>I am part of a...</OnBoardingHeader>
          <RadioGroup css={{ mb: 32 }} id='role' name='orgType' required>
            <Row>
              <Col
                span={{
                  '@initial': 12,
                  '@lg': 4,
                }}
              >
                <OnboardingRadioCard value={Organization.orgType.RECRUITER}>
                  <Flex justify='end'>
                    <OnboardingRadioIndicator />
                  </Flex>
                  <Flex justify='center'>
                    <AlertIcon
                      variant='purple'
                      shape='rounded-square'
                      name='office-building'
                    />
                  </Flex>
                  <Text
                    size='lg'
                    align='center'
                    noBreak
                    css={{ color: '$purple-700', mt: 24, mb: 8 }}
                  >
                    Recruiting Firm
                  </Text>
                  <Text size='xs' align='center' css={{ color: '$purple-700' }}>
                    We source candidates and run outreach to help our clients
                    hire.
                  </Text>
                </OnboardingRadioCard>
              </Col>
              <Col
                span={{
                  '@initial': 12,
                  '@lg': 4,
                }}
              >
                <OnboardingRadioCard value={Organization.orgType.VC}>
                  <Flex justify='end'>
                    <OnboardingRadioIndicator />
                  </Flex>
                  <Flex justify='center'>
                    <AlertIcon
                      variant='purple'
                      shape='rounded-square'
                      name='badge-check'
                    />
                  </Flex>
                  <Text
                    size='lg'
                    align='center'
                    noBreak
                    css={{ color: '$purple-700', mt: 24, mb: 8 }}
                  >
                    VC Firm
                  </Text>
                  <Text size='xs' align='center' css={{ color: '$purple-700' }}>
                    We work with portfolio companies to help them fill open
                    positions.
                  </Text>
                </OnboardingRadioCard>
              </Col>
              <Col
                span={{
                  '@initial': 12,
                  '@lg': 4,
                }}
              >
                <OnboardingRadioCard value={Organization.orgType.COMPANY}>
                  <Flex justify='end'>
                    <OnboardingRadioIndicator />
                  </Flex>
                  <Flex justify='center'>
                    <AlertIcon
                      variant='purple'
                      shape='rounded-square'
                      name='star'
                    />
                  </Flex>
                  <Text
                    size='lg'
                    align='center'
                    css={{ color: '$purple-700', mt: 24, mb: 8 }}
                  >
                    Company
                  </Text>
                  <Text size='xs' align='center' css={{ color: '$purple-700' }}>
                    We’re hiring for several open positions.
                  </Text>
                </OnboardingRadioCard>
              </Col>
            </Row>
          </RadioGroup>
          <OnboardingGoal currentGoal={currentGoal} />
          <OnboardingTools />
          <Button
            size='xl'
            type='submit'
            variant='purple'
            css={{
              mt: 12,
              mb: 24,
              minWidth: '100%',
              '@lg': {
                minWidth: 148,
              },
            }}
          >
            Finish
          </Button>
        </Form>
      </Box>
    </Box>
  );
};

export default OnboardingTemplate;
