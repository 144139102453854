import React from 'react';
import { Box, Flex } from '@betterleap/ui';
import logo from '../../../assets/images/betterleap-logo-dark.svg';
import NavigationLink from '../../elements/Navigation/NavigationLink/NavigationLink';
import NavigationOption from '../../elements/Navigation/NavigationOptions/NavigationOptions';
import { NavbarProps } from './Navigation.types';

const Navigation = ({
  navbarRoutes,
  handleNavigate,
  currentLocation,
  optionRoutes,
}: NavbarProps) => {
  return (
    <Box
      css={{
        background: '$background',
        height: '86px',
        width: '100%',
        postion: 'sticky',
        overflowX: 'hidden',
        zIndex: 11,
        borderBottom: '1px solid #E8EAED',
        display: 'flex',
        padding: '0 32px',
        justifyContent: 'space-between',
      }}
    >
      <nav style={{ display: 'flex', alignItems: 'center' }}>
        <Box
          as='img'
          src={logo}
          css={{ height: 30, width: 30, marginRight: '28px' }}
          alt='logo'
          aria-hidden
        />
        <ul style={{ display: 'flex', height: '100%' }}>
          {navbarRoutes.map(
            (route) =>
              route.route && (
                <NavigationLink
                  id={route.id}
                  key={route.title}
                  title={route.title}
                  route={route.route}
                  beta={route.beta}
                  icon={route.icon}
                  indicator={route.indicator}
                  onClick={route.onClick}
                  hidden={route.hidden}
                  handleNavigate={handleNavigate}
                  currentLocation={currentLocation}
                />
              ),
          )}
        </ul>
      </nav>
      <Box>
        <Flex css={{ height: '100%' }}>
          <NavigationOption optionRoutes={optionRoutes} />
        </Flex>
      </Box>
    </Box>
  );
};

export default Navigation;
