export const FIREBASE_USER_TO_USER = {
  email: 'email',
  family_name: 'lastName',
  given_name: 'firstName',
  firebaseId: 'firebaseId',
  isNewUser: 'isNewUser',
};

export const mapObjectPartially = (
  object: Record<string, string | number | boolean>,
  mapper: Record<string, string>,
) => {
  const newObject: Record<string, string | number | boolean | undefined> = {};
  Object.keys(mapper).forEach((key) => {
    newObject[mapper[key] as string] = object[key];
  });
  return newObject;
};

export const mapObject = (
  object: Record<string, unknown>,
  mapper: Record<string, string>,
) => {
  const newObject: Record<string, unknown> = {};
  Object.keys(object).forEach((key) => {
    newObject[mapper[key] || key] = object[key];
  });
  return newObject;
};
