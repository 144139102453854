import SourceCandidates from 'components/templates/Sourcing/SourceCandidates.template';
import withPaywall from 'components/HOC/withPaywall';
import withSidebar from '../components/HOC/withSidebar';
import withContactDrawer from '../components/HOC/withContactDrawer';

const OrganizationSourceCandidates = () => <SourceCandidates />;

export default withContactDrawer(
  withSidebar(withPaywall(OrganizationSourceCandidates), true),
);
