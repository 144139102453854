import { Box, Divider, Flex, Text } from '@betterleap/ui';
import logo from '../../../assets/images/betterleap-logo-text.svg';
import facebook from '../../../assets/images/facebook.svg';
import twitter from '../../../assets/images/twitter.svg';
import linkedin from '../../../assets/images/linkedin-icon-blue.svg';
import Section from './Section';
import { LandingLink } from './LandingLink';

interface LinkGroupProps {
  title: string;
  links: {
    name: string;
    href: string;
  }[];
}

const LinkGroup = ({ title, links }: LinkGroupProps) => {
  return (
    <Box css={{ flex: 1, maxWidth: 'fit-content' }}>
      <Text
        css={{
          fontWeight: '$medium',
          mb: 16,
          textAlign: 'center',
          mediaLg: {
            textAlign: 'left',
          },
        }}
      >
        {title}
      </Text>
      {links.map((link) => (
        <LandingLink
          key={link.name}
          href={link.href}
          css={{
            color: '$neutral-blue-900',
            textAlign: 'center',
            '& + &': {
              mt: 16,
            },
            mediaLg: {
              textAlign: 'left',
            },
          }}
        >
          {link.name}
        </LandingLink>
      ))}
    </Box>
  );
};

const Socials = () => (
  <Flex
    css={{
      gap: 24,
      justifyContent: 'center',
      mediaLg: {
        justifyContent: 'flex-start',
      },
    }}
  >
    <LandingLink href='https://www.linkedin.com/company/betterleap/'>
      <img src={linkedin} />
    </LandingLink>
    <LandingLink href='https://twitter.com/betterleaphq'>
      <img src={twitter} />
    </LandingLink>
    <LandingLink href='https://www.facebook.com/betterleaphq/'>
      <img src={facebook} />
    </LandingLink>
  </Flex>
);

const links = [
  {
    name: 'Blog',
    href: 'https://betterleap.com/blog/',
  },
  {
    name: 'Product Updates',
    href: 'https://betterleap.launchnotes.io/',
  },
  {
    name: 'Pricing',
    href: 'https://betterleap.com/pricing/',
  },
];

const products = [
  {
    name: 'Chrome Extension',
    href: 'https://chrome.google.com/webstore/detail/betterleap/bimmbgomanhpkfodmiomjgfakleojpia',
  },
  {
    name: 'Outreach Automation',
    href: 'https://app.betterleap.com/signup',
  },
  {
    name: 'AI Assistant',
    href: 'https://app.betterleap.com/signup',
  },
];

const legal = [
  {
    name: 'Employer Terms',
    href: 'https://betterleap.com/employer-terms/',
  },
  {
    name: 'Recruiter Terms',
    href: 'https://betterleap.com/recruiter-terms/',
  },
  {
    name: 'Privacy Policy',
    href: 'https://betterleap.com/privacy/',
  },
];

const EmailFinderFooter = (): JSX.Element => {
  return (
    <Section
      css={{
        px: 24,
      }}
    >
      <Flex
        css={{
          gap: 40,
          flexDirection: 'column',
          justifyContent: 'center',
          mb: 36,
          mediaLg: {
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 16,
            gap: 80,
            flexDirection: 'row',
          },
        }}
      >
        <Flex
          vertical
          css={{
            flex: 1,
            maxWidth: 272,
            alignItems: 'center',
            mediaLg: {
              alignItems: 'flex-start',
            },
          }}
        >
          <img src={logo} />
          <Text
            size='sm'
            css={{
              color: '$neutral-blue-900',
              mt: 16,
              textAlign: 'center',
              mediaLg: {
                textAlign: 'left',
              },
            }}
          >
            An all-in-one recruiting platform to source, engage and convert
            candidates.
          </Text>
        </Flex>
        <LinkGroup title='Links' links={links} />
        <LinkGroup title='Products' links={products} />
        <LinkGroup title='Legal' links={legal} />
      </Flex>
      <Socials />
      <Divider
        css={{
          mt: 40,
          mb: 40,
          mediaLg: {
            mt: 48,
            mb: 24,
          },
        }}
      />
      <Flex
        css={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: 16,
          mediaLg: {
            flexDirection: 'row',
          },
        }}
      >
        <Text size='xs' css={{ color: '$gray-500' }}>
          Copyright @2023. All rights reserved
        </Text>
        <Text
          size='xs'
          css={{
            display: 'none',
            color: '$gray-500',
            mediaLg: {
              display: 'block',
            },
          }}
        >
          •
        </Text>
        <LandingLink
          href='https://betterleap.com/google-api-disclosure/'
          size='xs'
          css={{ color: '$gray-500' }}
        >
          Google API Disclosure
        </LandingLink>
      </Flex>
    </Section>
  );
};

export default EmailFinderFooter;
