import {
  AlertIcon,
  AlertIconProps,
  Box,
  BoxProps,
  FlexProps,
  Text,
  TextSkeleton,
} from '@betterleap/ui';
import { Card } from 'components/elements/Card/Card';
import { apiClient } from 'lib/apiClient';
import { Suspense } from 'react';
import { useQuery } from 'react-query';

export interface StatProps extends BoxProps {
  icon: AlertIconProps['name'];
  title: string;
}

export interface StatCardProps extends FlexProps {
  icon: AlertIconProps['name'];
  title: string;
}

export interface GetStatProps {
  entity:
    | 'ai-text-suggestion'
    | 'project'
    | 'sequence'
    | 'contact-project'
    | 'contact-reply'
    | 'contact-email-open'
    | 'contact-message'
    | 'sourcing-search';
  startTime: Date;
  endTime: Date;
}

export const GetStat = ({ entity, startTime, endTime }: GetStatProps) => {
  const { data: countResponse } = useQuery(
    ['get_stat', { entity, startTime, endTime }],
    async () => {
      return apiClient.reporting.count({
        entity,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      });
    },
    {
      suspense: true,
      keepPreviousData: true,
    },
  );

  return <>{(countResponse?.data ?? 0).toLocaleString()}</>;
};

export const Stat = ({ icon, title, children, ...rest }: StatProps) => {
  return (
    <Box {...rest}>
      <AlertIcon
        variant='info'
        size='lg'
        name={icon}
        shape='rounded-square'
        css={{ mb: 24 }}
      />
      <Box>
        <Suspense fallback={<TextSkeleton size='xl' css={{ width: 40 }} />}>
          <Text css={{ fontSize: '$xl' }}>{children}</Text>
        </Suspense>
        <Text css={{ fontSize: '$xs', color: '$neutral-blue-700' }}>
          {title}
        </Text>
      </Box>
    </Box>
  );
};

export const StatCard = ({ icon, title, children, ...rest }: StatCardProps) => {
  return (
    <Card {...rest}>
      <Stat icon={icon} title={title}>
        {children}
      </Stat>
    </Card>
  );
};
