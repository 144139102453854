import { Col, Flex, Icon, Row, Text } from '@betterleap/ui';
import { format, isValid, parse } from 'date-fns';
import { useRef } from 'react';
import {
  DelayStrategySelector,
  DelayStrategySelectorProps,
} from './DelayStrategySelector';
import { ScheduleTimeManagerProps } from './ScheduleTimeManager';
import { TimeInput } from './TimeInput';

export const FirstStepScheduleTimeManager = ({
  stepNumber,
  value,
  readOnly,
  onBlur,
  onChange,
  canEditDelay,
  canEditSendStrategy,
  error,
}: ScheduleTimeManagerProps) => {
  const lastSendAtTimeRef = useRef('8:30 AM');

  const handleTimeChange = (newValue: string) => {
    const parsedDate = parse(newValue ?? '', 'hh:mm a', new Date());

    if (isValid(parsedDate)) {
      lastSendAtTimeRef.current = newValue;
    }

    onChange({
      ...value,
      sendAfterTime: newValue,
    });
  };

  const handleSendStrategyChange: DelayStrategySelectorProps['onSendStrategyChange'] =
    (newValue) => {
      onChange({
        ...value,
        sendAfterTime:
          newValue === 'send-at' ? lastSendAtTimeRef.current : undefined,
      });
    };

  return (
    <Row>
      <Col
        span={{
          '@md': 6,
          '@lg': 6,
        }}
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: 16,
        }}
      >
        <DelayStrategySelector
          defaultSendStrategy={
            value?.sendAfterTime ? 'send-at' : 'send-immediately'
          }
          onSendStrategyChange={handleSendStrategyChange}
          canEditSendStrategy={canEditSendStrategy}
          stepNumber={stepNumber}
          readOnly={readOnly}
          canEditDelay={canEditDelay}
          sendAt={value?.sendAfterTime}
          delay={value?.waitTimeCalendarDays}
          businessDays={value?.waitBusinessDaysOnly}
          onBlur={onBlur}
        >
          <Flex>
            <TimeInput
              id={`delay-${stepNumber}`}
              error={error?.sendAfterTime?.message}
              value={value?.sendAfterTime ?? ''}
              onChange={handleTimeChange}
              onBlur={onBlur}
            />
          </Flex>
        </DelayStrategySelector>
      </Col>
      {!readOnly && canEditDelay && value?.sendAfterTime && (
        <Col
          span={{
            '@md': 6,
            '@lg': 6,
          }}
          css={{
            gap: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mediaLg: {
              justifyContent: 'flex-end',
            },
          }}
        >
          <Icon color='$neutral-blue-500' size={16} name='information-circle' />
          <Text css={{ color: '$neutral-blue-500', fontSize: '$sm' }}>
            Email will send at{' '}
            {/\d\d:\d\d:\d\d/.test(value?.sendAfterTime)
              ? format(
                  parse(value?.sendAfterTime, 'HH:mm:ss', new Date()),
                  'hh:mm a',
                )
              : value?.sendAfterTime}{' '}
            &#40;contact’s timezone&#41;
          </Text>
        </Col>
      )}
    </Row>
  );
};
