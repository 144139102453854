import { Types } from '@betterleap/shared';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { Action } from 'redux';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ApiEndpoint } from '../../constants/endpoints';
import routeNames from '../../constants/routeNames';
import api from '../../lib/api';
import TOKEN from '../../lib/token';
import { API, ApiAction, apiActions } from './actions';
import {
  selectCompany,
  selectOrganization,
  selectPublicToken,
} from './selectors';

export function* onApiLoad({ payload, type, meta, helpers }: ApiAction) {
  const actionType = type
    .replace(API.FETCH_START, '')
    .toLowerCase() as ApiEndpoint;
  try {
    const response: AxiosResponse<Types.ApiReducer<Types.ApiResponse>> =
      yield api.fetch(actionType, payload as Types.EndpointPayload | undefined);
    yield put(
      apiActions.fetchSuccess(
        actionType,
        response.data.data,
        {
          ...response.data.meta,
          debounce: {
            time: _.get(meta, 'debounce.time', 0) + 100,
            key: `${actionType}Success`,
          },
        },
        helpers ?? undefined,
      ),
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 401) {
      TOKEN.remove();
      localStorage.removeItem('activeStep');
      yield put(apiActions.remove());
      yield helpers?.navigate(routeNames.signin());
    }
    yield put(
      apiActions.fetchFailure(
        actionType,
        e.response?.data?.error,
        {
          debounce: {
            time: _.get(meta, 'debounce.time', 0) + 100,
            key: `${actionType}Failure`,
          },
        },
        helpers ?? undefined,
      ),
    );
  }
}

export function* watchApiLoad() {
  yield takeEvery(
    (action: Action) => /^FETCH_START_/.test(action.type),
    onApiLoad,
  );
}

export function* onRecruiterFetchSuccess({ payload }: ApiAction) {
  const localProfileCompleted: string = yield localStorage.getItem(
    'profileCompleted',
  );
  if (payload.profileStatus === 'notCompleted' && !localProfileCompleted) {
    localStorage.setItem('profileCompleted', 'not_completed');
  } else if (
    payload.profileStatus === 'completed' &&
    localProfileCompleted === 'not_completed'
  ) {
    localStorage.setItem('profileCompleted', 'just_completed');
    const recruiter: Types.Recruiter = payload;
    const specializations =
      recruiter.specializations.map((el) => el.name) || [];
    if (recruiter.otherSpecialization)
      specializations.push(recruiter.otherSpecialization);
  } else if (payload.profileStatus === 'completed' && !localProfileCompleted) {
    localStorage.setItem('profileCompleted', 'completed');
  }
}

export function* watchOnRecruiterFetchSuccess() {
  yield takeLatest('FETCH_SUCCESS_RECRUITER', onRecruiterFetchSuccess);
}

export function* onMeFetchSuccess({ payload, helpers }: ApiAction) {
  if (payload.invalidateToken) {
    TOKEN.remove();
    yield put(apiActions.fetch('me', undefined, {}, helpers));
    yield put(apiActions.remove());
  }
  if (payload.role === Types.ROLE.COMPANY_ADMIN) {
    yield put(apiActions.fetch('company', undefined, {}, helpers));
    yield put(apiActions.fetch('organization', undefined, {}, helpers));
  } else if (payload.role === Types.ROLE.RECRUITER) {
    yield put(apiActions.fetch('recruiter', undefined, {}, helpers));
    yield put(apiActions.fetch('organization', undefined, {}, helpers));
  }
}

export function* watchOnMeFetchSuccess() {
  yield takeLatest('FETCH_SUCCESS_ME', onMeFetchSuccess);
}

export function* onSyncSuccess({ payload, helpers }: ApiAction) {
  if (!payload) {
    const company: Types.ApiReducer<Types.Company> = yield select(
      selectCompany,
    );
    const organization: Types.ApiReducer<Types.Organization> = yield select(
      selectOrganization,
    );
    const publicTokenResponse: Types.ApiReducer<{ accountToken: string }> =
      yield select(selectPublicToken);

    yield new Promise((resolve) => setTimeout(resolve, 10000));
    yield put(
      apiActions.fetch(
        'get_sync_status',
        {
          companyId: company.data?.id,
          accountToken: !organization.data?.accountToken
            ? publicTokenResponse.data?.accountToken
            : organization.data?.accountToken,
        },
        {},
        helpers,
      ),
    );
  }
}

export function* watchOnSyncSuccess() {
  yield takeLatest('FETCH_SUCCESS_GET_SYNC_STATUS', onSyncSuccess);
}

export default [
  watchApiLoad,
  watchOnRecruiterFetchSuccess,
  watchOnMeFetchSuccess,
  watchOnSyncSuccess,
];
