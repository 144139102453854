import {
  Box,
  BoxProps,
  Input,
  InputProps,
  Label,
  LabelProps,
} from '@betterleap/ui';
import { useState } from 'react';
import { FilterItem } from './SourceCandidates.types';
import {
  SourcingFilterBadges,
  SourcingFilterTitle,
} from './SourceCandidates.components';

export interface SourceCandidatesInputFilterProps extends InputProps {
  title: string;
  field: string;
  currentFilter?: FilterItem[];
  onFilterUpdated: (newFilter: FilterItem[]) => void;
  values?: FilterItem[];
  placeholder?: string;
}

export const CarriageIcon = ({ css, ...props }: BoxProps) => (
  <Box
    as='span'
    css={{ color: '$text-placeholder', px: 6, ...css }}
    aria-hidden
    {...props}
  >
    ↵
  </Box>
);

export const SourcingInputLabel = ({ css, children, ...rest }: LabelProps) => {
  return (
    <Label
      id='filter-by-name'
      htmlFor='sourcing-name-filter'
      css={{
        fontWeight: '$medium',
        mb: 8,
        fontSize: '$sm',
        color: '$text-light',
        ...css,
      }}
      {...rest}
    >
      {children}
    </Label>
  );
};

export const SourceCandidatesInputFilter = (
  params: SourceCandidatesInputFilterProps,
) => {
  const {
    css,
    title,
    field,
    currentFilter,
    onFilterUpdated,
    placeholder,
    ...rest
  } = params;
  const [filterTerm, setFilterTerm] = useState<string>('');

  const placeholderText = placeholder || `Enter ${title}`;

  const myFilter = currentFilter || [];

  return (
    <Box css={{ width: '100%', ...css }}>
      <SourcingFilterTitle title={title} />
      <SourcingFilterBadges
        items={myFilter}
        onFilterUpdated={onFilterUpdated}
      />
      <Input
        placeholder={placeholderText}
        value={filterTerm}
        rightIcon={
          <Box
            as='span'
            css={{ color: '$text-placeholder', px: 6 }}
            aria-hidden
          >
            ↵
          </Box>
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (
              !myFilter.some(
                (v) => v.name.toLowerCase() === filterTerm.toLowerCase(),
              )
            ) {
              onFilterUpdated([
                ...myFilter,
                {
                  field,
                  name: filterTerm,
                  excluded: false,
                },
              ]);
            }

            setFilterTerm('');
          }
        }}
        onChange={(value) => {
          setFilterTerm(value);
        }}
        {...rest}
      />
    </Box>
  );
};
