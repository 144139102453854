/* eslint-disable import/no-cycle */
import {
  saveUserToken,
  SegmentAnalytics,
  showToast,
  signInWithEmail,
  Types,
} from '@betterleap/shared';
import { get } from 'lodash';
import { ReactText } from 'react';
import { Location, NavigateFunction } from 'react-router-dom';
import { ForkEffect, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { shouldEnableAnalytics } from '../../helper/analytics';
import routeNames from '../../constants/routeNames';
import TOKEN from '../../lib/token';
import { ApiAction, apiActions } from '../api/actions';
import { AUTH, authActions } from './actions';

function* identifyUserForAnalytics(user: Types.User, enableAnalytics: boolean) {
  if (user.isNewUser) {
    yield SegmentAnalytics.alias(enableAnalytics, user.id);
  }
  const traits = {
    name: [user?.firstName, user?.lastName].filter((x) => !!x).join(' '),
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
  };
  yield SegmentAnalytics.identify(enableAnalytics, user.id, traits);
}

export function* onSignupSuccess({ payload, helpers }: ApiAction) {
  const { user, redirectUrl } = payload;
  const isSignInAsAnotherUser = TOKEN.isSessionStorage();

  yield put(apiActions.fetch('me', undefined, {}, helpers));
  yield put(apiActions.fetch('organization', undefined, {}, helpers));

  const enableAnalytics = shouldEnableAnalytics(isSignInAsAnotherUser);
  yield identifyUserForAnalytics(user, enableAnalytics);

  const redirectTo = get(helpers?.location, 'state.redirectUrl') || redirectUrl;
  if (redirectTo) {
    helpers?.navigate(redirectTo);
    return;
  }

  const emailRedirectUrl = new URLSearchParams(window.location.search).get(
    'redirect-url',
  );

  if (emailRedirectUrl) {
    helpers?.navigate(emailRedirectUrl.replace(/\\/gi, '/'));
    return;
  }

  switch (get(user, 'role', Types.ROLE.NONE)) {
    case Types.ROLE.COMPANY_ADMIN:
      helpers?.navigate(routeNames.sourcing());
      break;
    case Types.ROLE.RECRUITER:
      helpers?.navigate(routeNames.sourcing());
      break;
    default:
      helpers?.navigate(routeNames.onboarding());
  }
}

export function* watchOnSigninSuccess(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest('FETCH_SUCCESS_SIGNIN', onSignupSuccess);
}
export function* onRecruiterSignupSuccess(action: ApiAction) {
  yield onSignupSuccess({
    ...action,
    payload: {
      ...action.payload,
      user: action.payload.recruiter.user,
    },
  });
}

export function* watchOnSignupSuccess(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest('FETCH_SUCCESS_SIGNUP', onSignupSuccess);
}

export function* watchOnRecruiterSignupSuccess(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest('FETCH_SUCCESS_RECRUITER_SIGNUP', onRecruiterSignupSuccess);
}

export function* onCreateRecruiterSuccess(action: ApiAction) {
  yield onSignupSuccess({
    ...action,
    payload: {
      ...action.payload,
      user: action.payload.recruiter.user,
    },
  });
}

export function* watchOnCreateRecruiterSuccess(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest('FETCH_SUCCESS_CREATE_RECRUITER', onCreateRecruiterSuccess);
}

export function* onSigninFailure(): Generator<ReactText> {
  yield showToast("User with this email doesn't exist", {}, 'error');
  TOKEN.remove();
}
export function* watchOnSigninFailure(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest('FETCH_FAILURE_SIGNIN', onSigninFailure);
}

export function* onSignupFailure({ payload }: ApiAction): Generator<ReactText> {
  yield showToast(get(payload, 'data.message'), {}, 'error');
}
export function* watchOnSignupFailure(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest('FETCH_FAILURE_SIGNUP', onSignupFailure);
}

/** On me failure */
export function* onMeFailure({ helpers }: ApiAction): Generator<void> {
  TOKEN.remove();
  localStorage.removeItem('activeStep');
  yield helpers?.navigate(routeNames.signin());
}
export function* watchOnMeFailure(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest('FETCH_FAILURE_ME', onMeFailure);
}

export function* watchEmailLinkConfirm(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(AUTH.CONFIRM_EMAIL_LINK, checkSignInWithEmailLink);
}

export function* checkSignInWithEmailLink(data: {
  payload: { token: string; email: string };
  type: string;
  helpers: { navigate: NavigateFunction; location: Location };
}) {
  yield put(authActions.setEmailLinkVerifyLoading(true));
  const { search } = window.location;

  const searchParams = new URLSearchParams(search);

  const result: Types.FirebaseAuthCredentials = yield signInWithEmail(
    data.payload.token,
    data.payload.email,
  );
  if (result) {
    yield saveUserToken();

    const signupOptions = {
      ...result,
      invitationId: searchParams.get('invitationId'),
      partner: searchParams.get('partner'),
      referrer: searchParams.get('referrer-link'),
    };

    yield put(apiActions.fetch('signup', signupOptions, {}, data.helpers));
    window.localStorage.removeItem('signupEmail');
  }
  yield put(authActions.setEmailLinkVerifyLoading(false));
}

export function* onLogout() {
  yield localStorage.removeItem('profileCompleted');
}

export function* watchOnLogout(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery('FETCH_FAILURE_ME', onLogout);
}

export default [
  watchOnSigninSuccess,
  watchOnSignupSuccess,
  watchOnSigninFailure,
  watchOnSignupFailure,
  watchOnMeFailure,
  watchEmailLinkConfirm,
  watchOnRecruiterSignupSuccess,
  watchOnCreateRecruiterSuccess,
  watchOnLogout,
];
