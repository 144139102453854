import { Contact } from '@betterleap/client';
import {
  Button,
  Icon,
  Menu,
  MenuArrow,
  MenuContent,
  MenuTrigger,
  Text,
} from '@betterleap/ui';
import { NewProjectTypeItem } from './NewProjectTypeMenuItem.template';

export interface NewProjectButtonProps {
  handleCreateProject: (
    mode: Contact.contactEmailEnrichmentMode,
  ) => Promise<void>;
}

export const NewProjectButton = (props: NewProjectButtonProps) => {
  const { handleCreateProject } = props;

  return (
    <Menu>
      <MenuTrigger asChild>
        <Button>
          New Project
          <Icon
            name='chevron-down'
            size={16}
            css={{ ml: 8 }}
            color='$neutral-blue-100'
          />
        </Button>
      </MenuTrigger>
      <MenuContent align='center'>
        <MenuArrow offset={14} />
        <Text css={{ fontWeight: '$medium', fontSize: '$base', m: 16 }}>
          Select Project Type
        </Text>
        <NewProjectTypeItem
          mode={Contact.contactEmailEnrichmentMode.PERSONAL}
          itemClicked={handleCreateProject}
        />
        <NewProjectTypeItem
          mode={Contact.contactEmailEnrichmentMode.PROFESSIONAL}
          itemClicked={handleCreateProject}
        />
      </MenuContent>
    </Menu>
  );
};
