import { SHARED_ENDPOINTS } from '@betterleap/shared';

// All endpoints must be in snake case (snake_case)
const ENDPOINTS = {
  ...SHARED_ENDPOINTS,
  // GLOBAL
  feature_config: {
    uri: '/feature-config',
    method: 'GET',
  },
  // AUTH
  signup: {
    uri: '/signup/user-signup',
    method: 'POST',
  },
  signin: {
    uri: '/auth/user-signin',
    method: 'POST',
  },
  email_signup: {
    uri: '/signup/email-user-signup',
    method: 'POST',
  },
  recruiter_email_signup: {
    uri: '/recruiter/email-user-signup',
    method: 'POST',
  },
  me: {
    uri: '/auth/me',
    method: 'GET',
  },
  // ONBOARDING
  create_company_admin: {
    uri: '/company-admin',
    method: 'POST',
  },
  create_company: {
    uri: '/company',
    method: 'POST',
  },
  company_admin: {
    uri: '/company-admin',
    method: 'GET',
  },
  create_recruiter: {
    uri: '/recruiter',
    method: 'POST',
  },
  company_complete_boarding: {
    uri: '/company/complete-onboarding',
    method: 'POST',
  },
  recruiter_complete_boarding: {
    uri: '/recruiter/complete-onboarding',
    method: 'POST',
  },

  // COMPANY INFO
  company: {
    uri: '/company',
    method: 'GET',
  },
  company_add_billing_info: {
    uri: '/company-billing-info',
    method: 'PUT',
  },
  company_get_billing_info: {
    uri: '/company-billing-info',
    method: 'GET',
  },
  company_admins: {
    uri: '/company-admin/all',
    method: 'GET',
  },
  invoices: {
    uri: '/company/invoice/all',
    method: 'GET',
  },
  // INVITE
  get_invitations: {
    uri: '/invitations',
    method: 'GET',
  },
  get_invitation: {
    uri: '/invitations/{id}',
    method: 'GET',
  },
  // RECRUITER INFO
  recruiter: {
    uri: '/recruiter',
    method: 'GET',
  },
  recruiter_by_id: {
    uri: '/recruiter/{id}',
    method: 'GET',
  },
  // RECRUITER ROLE
  get_sync_status: {
    uri: '/merge/{accountToken}/sync/ping',
    method: 'GET',
  },
  // SEQUENCING
  create_sender_oauth2complete: {
    uri: '/sender/oauth2complete',
    method: 'POST',
  },
  get_senders: {
    uri: '/sender/all',
    method: 'GET',
  },
  get_organization_senders: {
    uri: '/sender/my-organization',
    method: 'GET',
  },
  send_test_email: {
    uri: '/sender/send-test-email',
    method: 'POST',
  },
  get_sequence: {
    uri: '/sequences/{sequenceId}',
    method: 'GET',
  },
  get_sequence_with_steps: {
    uri: '/sequences/{sequenceId}?relations=steps,sender',
    method: 'GET',
  },
  all_recruiter_sequences: {
    uri: '/sequences',
    method: 'GET',
  },
  get_projects_with_stats: {
    uri: '/projects/stats',
    method: 'GET',
  },
  get_projects: {
    uri: '/projects',
    method: 'GET',
  },
  get_project: {
    uri: '/projects/{projectId}',
    method: 'GET',
  },
  get_project_with_stats: {
    uri: '/projects/{projectId}/stats',
    method: 'GET',
  },
  delete_project: {
    uri: '/projects/{projectId}',
    method: 'DELETE',
  },
  archive_sequence: {
    uri: '/sequences/{sequenceId}',
    method: 'DELETE',
  },
  pause_sequence: {
    uri: '/sequences/{sequenceId}',
    method: 'PATCH',
  },
  resume_sequence: {
    uri: '/sequences/{sequenceId}',
    method: 'PATCH',
  },
  update_sequence: {
    uri: '/sequences/{sequenceId}',
    method: 'PATCH',
  },
  create_many_contacts: {
    uri: '/contact/many',
    method: 'POST',
    isMultipart: true,
  },
  export_contacts_csv: {
    uri: '/contact/export/csv',
    method: 'GET',
  },
  create_sequence: {
    uri: '/sequences',
    method: 'POST',
  },
  create_project: {
    uri: '/projects',
    method: 'POST',
  },
  get_organization_members: {
    uri: '/user/organization-members',
    method: 'GET',
  },

  organization: {
    uri: '/organization',
    method: 'GET',
  },

  talk_to_us: {
    uri: '/slack/talk-to-us',
    method: 'POST',
  },

  // AI
  ai_database_agent: {
    uri: '/ai-database-agent',
    method: 'POST',
  },
  ai_database_agent_visualization: {
    uri: '/ai-database-agent/visualization',
    method: 'POST',
  },
  ai_database_agent_feedback: {
    uri: '/ai-database-agent-feedback',
    method: 'POST',
  },
};

export type ApiEndpoint = keyof typeof ENDPOINTS; // TODO: Endpoint defined in shared

export { ENDPOINTS };
