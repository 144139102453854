import { Types } from '@betterleap/shared';
import { useMutation } from 'react-query';
import { createLocalFile } from 'functions/createLocalFile';
import { apiClient } from 'lib/apiClient';
import { logToSegment } from 'functions/segmentLog';
import { ContactActivityType, ProjectContactStatus } from '@betterleap/client';

export const useExportContacts = () => {
  const exportAsCsv = useMutation(
    (request: {
      projectId: string;
      contactIds?: string[];
      activityTypes?: ContactActivityType[];
      statuses?: ProjectContactStatus[] | { and: ProjectContactStatus[][] };
    }) =>
      apiClient.contact.exportAsCsv({
        projectId: request.projectId,
        contactIds: request.contactIds,
        statuses: request.statuses
          ? JSON.stringify(request.statuses)
          : undefined,
        activityTypes: request.activityTypes,
      }),
    {
      onSuccess: (result) => {
        createFile(result.data);
      },
    },
  );

  const createFile = (csv: string) => {
    const date = new Date().toISOString().split('T')[0]?.replace(/-/g, '');
    createLocalFile(`contacts_${date}.csv`, csv);
  };

  const handleExportContactsClick = ({
    projectId,
    contactIds,
    statuses,
    activityTypes,
  }: {
    projectId: string;
    contactIds?: string[];
    statuses?: ProjectContactStatus[] | { and: ProjectContactStatus[][] };
    activityTypes?: ContactActivityType[];
  }) => {
    const properties = {
      action_taken: Types.BULK_ACTION.EXPORT,
      num_candidates_selected: contactIds?.length,
    };
    logToSegment(Types.SEGMENT_EVENTS.BULK_ACTION_ON_CANDIDATES, properties);

    exportAsCsv.mutate({
      projectId,
      contactIds,
      statuses,
      activityTypes,
    });
    return exportAsCsv;
  };

  return handleExportContactsClick;
};
