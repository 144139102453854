import {
  applyActionCode,
  signInWithEmailPassword,
  signUpWithEmailPassword,
  TOKEN,
} from '@betterleap/shared';
import { FirebaseUserSignupDto } from '@betterleap/client';
import { showToast, useModal } from '@betterleap/ui';
import useFetch from 'hooks/fetch';
import { EmailVerificationModal } from './EmailVerificationModal';
import { SignInRequest, SignUpRequest } from './SignIn.types';

type ToastValue = { title: string; description: string };

export const useEmailPasswordSignIn = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, signupUser] = useFetch('signup');

  const openEmailVerificationModal = useModal(EmailVerificationModal);

  const startSignUp = async (request: SignUpRequest) => {
    try {
      await trySignUp(request);
    } catch (e) {
      const errorMap = new Map<string, ToastValue>([
        [
          'auth/email-already-in-use',
          {
            title: 'Email Already Registered',
            description:
              'This email is associated with an account. Please sign in.',
          },
        ],
        [
          'auth/weak-password',
          {
            title: 'Weak Password',
            description: 'Your password should be at least 6 characters.',
          },
        ],
      ]);
      handleError(e, errorMap);
    }
  };

  const trySignUp = async (request: SignUpRequest) => {
    const { email, password } = request;
    const credential = await signUpWithEmailPassword(email, password);
    const accessToken = await credential.user.getIdToken();
    TOKEN.set(accessToken);
    openEmailVerificationModal();
  };

  const markEmailVerified = async (code: string) => {
    await applyActionCode(code);
  };

  const signIn = async (request: SignInRequest) => {
    try {
      await trySignIn(request);
    } catch (e) {
      const errorMap = new Map<string, ToastValue>([
        [
          'auth/invalid-credential',
          {
            title: 'Something Went Wrong',
            description:
              'Your password is incorrect, or the account doesn’t exist. Please try again.',
          },
        ],
      ]);
      handleError(e, errorMap);
    }
  };

  const trySignIn = async (request: SignInRequest) => {
    const { email, password } = request;
    const credential = await signInWithEmailPassword(email, password);
    const accessToken = await credential.user.getIdToken();
    TOKEN.set(accessToken);
    if (!credential.user.emailVerified) {
      openEmailVerificationModal();
    } else {
      signupUser({
        firebaseId: credential.user.uid,
        email: credential.user.email!,
        role: FirebaseUserSignupDto.role.NONE,
      });
    }
  };

  const handleError = (e: unknown, errorMap: Map<string, ToastValue>) => {
    const error = e as { code: string; message: string };
    let toast = errorMap.get(error.code);
    if (!toast) {
      // eslint-disable-next-line no-console
      console.error(error.code, error.message);
      toast = {
        title: 'An Error Occurred',
        description: 'Something went wrong. Please try again.',
      };
    }
    showToast({ ...toast, variant: 'danger' });
  };

  return { startSignUp, markEmailVerified, signIn };
};
