import { ProjectWithStatsDto } from '@betterleap/client';
import { Text } from '@betterleap/ui';
import { Row } from 'react-table';
import {
  countSelectedContacts,
  hasSelection,
  resolveSubject,
} from '../ProjectDetails.functions';

interface SelectionTextProps {
  project: ProjectWithStatsDto;
  totalCount: number;
  selectedContacts: Row<Record<string, unknown>>[] | 'all';
}

export const ActionSelectionText = (props: SelectionTextProps) => {
  return (
    <Text
      css={{
        fontSize: '$sm',
        color: '$neutral-blue-700',
        textAlign: 'right',
      }}
    >
      {constructSelectionText(props)}
    </Text>
  );
};

function constructSelectionText({
  project,
  totalCount,
  selectedContacts,
}: SelectionTextProps) {
  const selectedCount = countSelectedContacts(selectedContacts, totalCount);
  const singular = selectedContacts.length === 1;
  const contacts = resolveSubject(project, { singular });
  const contact = resolveSubject(project, { singular: true });

  let text: string;
  if (hasSelection(selectedContacts)) {
    text = `${selectedCount} ${contacts} selected`;
  } else if (!project.sequenceId) {
    text = `Select a ${contact} to take action`;
  } else {
    text = `Select ${contact} to add to a sequence`;
  }
  return text;
}
