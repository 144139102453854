import { Contact } from '@betterleap/client';
import { Flex, Icon, IconToken, MenuItem, Text } from '@betterleap/ui';

export interface NewProjectTypeMenuItemProps {
  mode: Contact.contactEmailEnrichmentMode;
  itemClicked: (mode: Contact.contactEmailEnrichmentMode) => Promise<void>;
}

export const NewProjectTypeItem = (props: NewProjectTypeMenuItemProps) => {
  const { mode, itemClicked } = props;

  let iconName: IconToken = 'briefcase';
  let title = 'Recruiting';
  let description = 'Source and reach out to candidates.';
  if (mode === Contact.contactEmailEnrichmentMode.PROFESSIONAL) {
    iconName = 'currency-dollar';
    title = 'Sales';
    description = 'Source and reach out to potential clients.';
  }

  return (
    <MenuItem css={{ p: 20 }} onClick={() => itemClicked(mode)}>
      <Flex css={{ gap: 18, alignItems: 'flex-start' }}>
        <Icon name={iconName} color='$neutral-blue-700' size={20} />
        <Flex vertical css={{ gap: 4 }}>
          <Text css={{ fontWeight: 'medium', fontSize: '$sm' }}>{title}</Text>
          <Text
            css={{
              fontWeight: 'medium',
              fontSize: '$xs',
              color: '$neutral-blue-600',
            }}
          >
            {description}
          </Text>
        </Flex>
      </Flex>
    </MenuItem>
  );
};
