import {
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from '@betterleap/ui';

interface BannerProps {
  isEnriching: boolean;
  selectedCount: number;
  totalCount: number;
  pageSize: number;
  onSelectAll: () => void;
  onClearSelection: () => void;
}

export const ProjectDetailsTableBanner = ({
  isEnriching,
  selectedCount,
  totalCount,
  pageSize,
  onSelectAll,
  onClearSelection,
}: BannerProps) => {
  const selectedContacts = <SelectedContacts count={selectedCount} />;
  const projectSelected = totalCount > pageSize && selectedCount === totalCount;
  const pageSelected = totalCount > pageSize && selectedCount === pageSize;
  const showBanner = isEnriching || projectSelected || pageSelected;
  return !showBanner ? null : (
    <BannerContainer>
      {isEnriching && <BannerContentLine>Enriching Contacts</BannerContentLine>}
      {pageSelected && (
        <BannerContentLine>
          All {selectedContacts} on this page are selected.{' '}
          <LinkButton onClick={onSelectAll}>
            Select all {totalCount} contacts in this project.
          </LinkButton>
        </BannerContentLine>
      )}
      {projectSelected && (
        <BannerContentLine>
          All {selectedContacts} in this project are selected.{' '}
          <LinkButton onClick={onClearSelection}>Clear selection.</LinkButton>
        </BannerContentLine>
      )}
    </BannerContainer>
  );
};

const BannerContainer = ({ children }: FlexProps) => (
  <Flex
    css={{
      p: 16,
      borderBottom: '1px solid',
      borderBottomColor: '$neutral-blue-200',
      flexDirection: 'column',
      gap: 8,
    }}
    justify='center'
  >
    {children}
  </Flex>
);

const BannerContentLine = ({ children }: TextProps) => (
  <Text
    css={{ fontSize: '$sm', color: '$neutral-blue-800', textAlign: 'center' }}
  >
    {children}
  </Text>
);

const SelectedContacts = ({ count }: { count: number }) => (
  <Text as='span' inline inherit css={{ fontWeight: '$semibold' }}>
    {count} contacts
  </Text>
);

const LinkButton = ({ children, onClick }: ButtonProps) => (
  <Button
    variant='link'
    css={{
      fontSize: '$sm',
      fontWeight: '$normal',
      color: '$purple-700',
      borderBottom: '1px solid $purple-700',
      hover: {
        borderBottom: '1px solid $purple-700',
      },
    }}
    onClick={onClick}
  >
    {children}
  </Button>
);
