import {
  Button,
  Flex,
  FlexProps,
  Icon,
  IconProps,
  Text,
  Tooltip,
} from '@betterleap/ui';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHighlightSearchTerms } from '../useHighlightWords';

interface SourcingResultSectionProps<T>
  extends ContentsProps<T>,
    SectionIconProps {}

/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
export const SourcingResultSection = <T extends unknown>({
  title,
  icon,
  css,
  ...rest
}: SourcingResultSectionProps<T>) => {
  return (
    <SourcingResultSectionContainer css={css}>
      <SourcingResultSectionIcon title={title} icon={icon} />
      <SourcingResultSectionContents {...rest} />
    </SourcingResultSectionContainer>
  );
};

export const SourcingResultSectionContainer = ({
  children,
  css,
}: FlexProps) => {
  return (
    <Flex css={{ mt: 16, gap: 12, width: '100%', minWidth: '700px', ...css }}>
      {children}
    </Flex>
  );
};

interface SectionIconProps extends FlexProps {
  icon: IconProps['name'];
}

export const SourcingResultSectionIcon = ({
  title,
  icon,
}: SectionIconProps) => {
  return (
    <Tooltip content={title}>
      <Flex
        css={{
          backgroundColor: '$neutral-blue-100',
          p: '6px 6px 6px 8px',
          borderRadius: 8,
          alignSelf: 'flex-start',
        }}
      >
        <Icon name={icon} color='$neutral-blue-700' size={18} />
      </Flex>
    </Tooltip>
  );
};

interface ContentsProps<T> extends FlexProps {
  elements: T[];
  elementKey?: string;
  center?: boolean;
  direction?: 'column' | 'row';
  collapsingType?: 'expand' | 'tooltip';
  collapsedDisplayMax?: number;
  displayRemainingElements?: (elements: T[]) => string;
  onExpanded?: () => void;
  constructElement: (element: T, index: number) => JSX.Element;
}

export const SourcingResultSectionContents = <T extends unknown>({
  elements,
  elementKey,
  direction = 'column',
  center = false,
  collapsingType: collapsingType = 'expand',
  collapsedDisplayMax = 2,
  displayRemainingElements,
  onExpanded,
  constructElement,
}: ContentsProps<T>) => {
  const [isExpanded, setExpanded] = useState(false);
  const displayedElements = isExpanded
    ? elements
    : elements.slice(0, collapsedDisplayMax);
  const toggleParams: { text: string; icon: 'chevron-up' | 'chevron-down' } = {
    text: isExpanded ? 'Minimize' : `Show all (${elements.length})`,
    icon: isExpanded ? 'chevron-up' : 'chevron-down',
  };
  const highlightTerms = useHighlightSearchTerms();

  useEffect(() => {
    if (isExpanded) {
      onExpanded && onExpanded();
      highlightTerms();
    }
  }, [isExpanded]);

  const showMoreElements: T[] = useMemo(() => {
    const elementsToSlice = elementKey
      ? elements.map((element) => get(element, elementKey, element))
      : elements;

    return elementsToSlice.slice(collapsedDisplayMax, elements.length);
  }, [collapsedDisplayMax, elements]);

  if (!displayRemainingElements) {
    displayRemainingElements = (e: T[]) => e.join(', ');
  }

  return (
    <Flex
      vertical
      css={{
        mt: center ? 5 : 0,
      }}
    >
      <Flex align='start' wrap css={{ gap: 4, flexDirection: direction }}>
        {displayedElements.map((element, index) =>
          constructElement(element, index),
        )}
        {collapsingType === 'tooltip' && !!showMoreElements.length && (
          <Tooltip
            css={{
              maxWidth: '400px',
              lineHeight: '20px',
            }}
            content={displayRemainingElements(showMoreElements)}
          >
            <Button
              variant='headless'
              css={{
                alignSelf: 'flex-start',
              }}
            >
              <Text css={{ fontSize: '$sm', color: '$blue-600' }}>
                + {showMoreElements.length} more
              </Text>
            </Button>
          </Tooltip>
        )}
      </Flex>
      {elements.length > collapsedDisplayMax && collapsingType === 'expand' && (
        <Button
          variant='headless'
          onClick={() => setExpanded(!isExpanded)}
          css={{ mt: 4 }}
        >
          <Text css={{ fontSize: '$sm', color: '$blue-600' }}>
            {toggleParams.text}
          </Text>
          <Icon
            name={toggleParams.icon}
            size={16}
            css={{ fill: '$blue-600', ml: 4 }}
          />
        </Button>
      )}
    </Flex>
  );
};
