import { Box, Flex, Text } from '@betterleap/ui';
import { sample } from 'lodash';
import { useMemo } from 'react';
import Lottie, { Options } from 'react-lottie';
import gradient from '../../../assets/lotties/gradient.json';

const sayings = [
  'Our AI is decoding the essence of your query now...',
  'Diving deep into your data for a comprehensive understanding...',
  'We’re connecting the dots and creating unique insights for you...',
  'Fine-tuning the results to ensure the highest level of precision...',
];

export const AssistantLoader = () => {
  const animationOptions = useMemo(() => {
    return {
      loop: true,
      autoplay: true,
      animationData: gradient,
    } as Options;
  }, []);

  const loadingSaying = useMemo(() => {
    return sample(sayings);
  }, []);

  return (
    <>
      <Flex
        justify='center'
        css={{
          position: 'absolute',
          top: '35%',
          left: 0,
          right: 0,
          width: '100%',
        }}
      >
        <Text
          css={{
            fontSize: 18,
            lineHeight: '150%',
            color: '$purple-500',
            maxWidth: '80%',
            '@lg': {
              fontSize: 22,
              lineHeight: '150%',
              maxWidth: '40%',
            },
          }}
        >
          {loadingSaying}
        </Text>
      </Flex>
      <Box
        css={{
          position: 'absolute',
          width: '100%',
          zIndex: 10,
          bottom: 0,
          left: 0,
          right: 0,
          '@lg': {
            bottom: -30,
          },
        }}
      >
        <Lottie options={animationOptions} />
      </Box>
    </>
  );
};
