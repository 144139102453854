import { ActionsUnion, createAction, IAction } from '../action';

enum ORGANIZATION {
  REFETCH_ORGANIZATION = 'REFETCH_ORGANIZATION',
}

const organizationActions = {
  refetchOrganization: (): IAction<ORGANIZATION> =>
    createAction(ORGANIZATION.REFETCH_ORGANIZATION),
};

export type OrganizationAction = ActionsUnion<typeof organizationActions>;
export { ORGANIZATION, organizationActions };
