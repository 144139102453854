import {
  Box,
  Button,
  Flex,
  Icon,
  IconProps,
  Spinner,
  Text,
} from '@betterleap/ui';

import addCandidatesImage from '../../../assets/images/add-candidates.svg';

interface ActionType {
  label: string;
  onClick: () => void;
  loading?: boolean;
}

interface ActionWithIconType extends ActionType {
  icon: IconProps['name'];
}

export interface IProjectEmptyProps {
  title: string;
  subtitle: string;
  primaryButton?: ActionType;
  secondaryButton?: ActionType;
  bottomButton?: ActionWithIconType;
}

export const ProjectEmptyState = ({
  title,
  subtitle,
  primaryButton,
  secondaryButton,
  bottomButton,
}: IProjectEmptyProps) => (
  <Flex
    justify='start'
    css={{
      flex: 1,
      height: '100%',
      paddingLeft: '100px',
      gap: '50px',
    }}
  >
    <Box as='img' src={addCandidatesImage} />
    <Flex
      vertical
      align='start'
      css={{
        gap: '7px',
      }}
    >
      <Text size='lg' css={{ fontWeight: 500 }}>
        {title}
      </Text>
      <Text size='sm' color='$natural-blue-700'>
        {subtitle}
      </Text>
      <Flex
        css={{
          gap: '10px',
          mt: '15px',
        }}
      >
        {primaryButton &&
          (primaryButton.loading ? (
            <Spinner />
          ) : (
            <Button onClick={primaryButton.onClick}>
              {primaryButton.label}
            </Button>
          ))}
        {secondaryButton &&
          (secondaryButton.loading ? (
            <Spinner />
          ) : (
            <Button onClick={secondaryButton.onClick} variant='secondary'>
              {secondaryButton.label}
            </Button>
          ))}
      </Flex>
      {bottomButton &&
        (bottomButton.loading ? (
          <Spinner />
        ) : (
          <Button
            css={{
              border: 'none',
              p: 0,
              display: 'flex',
              gap: '10px',
            }}
            variant='empty-primary'
            onClick={bottomButton.onClick}
          >
            {bottomButton.icon && (
              <Icon color='$primary' name={bottomButton.icon}></Icon>
            )}
            {bottomButton.label}
          </Button>
        ))}
    </Flex>
  </Flex>
);
