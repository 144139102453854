import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToContact = ({ isLoading }: { isLoading: boolean }) => {
  const { hash } = useLocation();
  const scrollToHashElement = () => {
    if (!isLoading && hash) {
      const elementId = hash.substring(1);
      if (!elementId) {
        return;
      }
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  useEffect(scrollToHashElement, [isLoading, hash]);
};
