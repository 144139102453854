import { Box, Button, showToast, Spinner } from '@betterleap/ui';
import {
  decode as decodeUrlSafeBase64,
  encodeURI as encodeUrlSafeBase64,
} from 'js-base64';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { apiClient } from 'lib/apiClient';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SourceCandidatesSearchParams } from '../Sourcing/SourceCandidates.types';
import {
  ContactProjectSearchMode,
  NlsSearchProjectInput,
} from './NlsSearchProjectInput';

export const nlsLoadingState = atom(false);

export function getNlsQueryFromSearchParams(
  searchParams: URLSearchParams,
): string | null {
  const nlsQuery = searchParams.get('nlsQuery');
  if (nlsQuery) {
    return decodeUrlSafeBase64(nlsQuery);
  }
  return searchParams.get('search') ?? null;
}

export function getNlsParamsFromSearchParams(
  searchParams: URLSearchParams,
): SourceCandidatesSearchParams | null {
  try {
    const nlsParams = searchParams.get('nlsParams');
    if (nlsParams) {
      return JSON.parse(decodeUrlSafeBase64(nlsParams));
    }
  } catch (e: unknown) {
    // Possible that the nlsParams is not a valid base64 string or not a valid JSON string
    // i.e. tampered URL so we should ignore it and return null
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return null;
}

export const NaturalLanguageFilter = () => {
  const setLoading = useSetAtom(nlsLoadingState);
  const isLoading = useAtomValue(nlsLoadingState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(
    getNlsQueryFromSearchParams(searchParams) || '',
  );
  const [mode, setMode] = useState<ContactProjectSearchMode | null | undefined>(
    searchParams.get('search') ? 'name' : undefined,
  );

  const hasQuery =
    !!searchParams.get('nlsQuery') || !!searchParams.get('search') || !!query;

  const setNlsQuery = (q: string) => {
    const encodedQuery = encodeUrlSafeBase64(q);
    searchParams.set('nlsQuery', encodedQuery);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const setNlsParams = (data: unknown) => {
    const encodedData = encodeUrlSafeBase64(JSON.stringify(data));
    searchParams.set('nlsParams', encodedData);
    setSearchParams(searchParams);
  };

  const setSqlQuery = (q: string) => {
    searchParams.set('search', q);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const clearParams = () => {
    searchParams.delete('search');
    searchParams.delete('nlsParams');
    searchParams.delete('nlsQuery');
    setSearchParams(searchParams);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (mode === 'profile') {
      try {
        setLoading(true);
        setNlsQuery(query);
        const { data } = await apiClient.personSearch.generateParams({
          query,
        });
        clearParams();
        setNlsParams(data);
      } catch (err: unknown) {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to execute search. Please try again.',
        });
      } finally {
        setLoading(false);
      }
    } else {
      clearParams();
      setSqlQuery(query);
    }
  };

  const handleModeChange = (
    newMode: ContactProjectSearchMode | null | undefined,
  ) => {
    setMode(newMode);
  };

  return (
    <Box
      css={{
        marginBottom: '16px',
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          gap: 10,
          marginLeft: 'auto',
          height: '100%',
        }}
      >
        <NlsSearchProjectInput
          value={query}
          mode={mode}
          onModeChange={(newMode) => {
            handleModeChange(newMode);
          }}
          onInput={(e) => {
            const target = e.target as HTMLInputElement;
            const value = target.value;
            setQuery(value);
          }}
          onClearClick={() => {
            clearParams();
            setQuery('');
            setMode(null);
          }}
          clearable={hasQuery}
        />
        <Button
          type='submit'
          variant='purple'
          style={{ width: 100, height: '100%', alignItems: 'center' }}
        >
          {isLoading ? (
            <Spinner css={{ height: 20, width: 20 }} variant='white' />
          ) : (
            'Search'
          )}
        </Button>
      </form>
    </Box>
  );
};
