import { RecruiterDto, RecruiterTestimonalsDto } from '@betterleap/client';
import { Box, Flex, useModal } from '@betterleap/ui';
import { v4 as uuid } from 'uuid';
import {
  AddButton,
  AddFirstButton,
  CardSection,
  SectionTitle,
  SectionTitleArea,
} from '../RecruiterProfile.elements';
import {
  AddTestimonialModal,
  AddTestimonialModalProps,
  AddTestimonialResult,
} from './AddTestimonialModal';
import { RecruiterProfileTestimonial } from './RecruiterProfileTestimonial';

interface Props {
  testimonials: RecruiterTestimonalsDto[];
  onUpdate?: (dto: RecruiterDto) => void;
}

export const RecruiterProfileTestimonials = (props: Props) => {
  const { testimonials, onUpdate } = props;
  const openModal = useModal<AddTestimonialModalProps, AddTestimonialResult>(
    AddTestimonialModal,
  );
  const onAddClick = async () => {
    const result = await openModal();
    if (!result) {
      return;
    }
    onUpdate?.({
      recruiterTestimonals: [
        ...testimonials,
        {
          id: uuid(),
          quotes: result.quote,
          companyName: result.companyName,
          isBetterleapClient: false,
        },
      ],
    });
  };
  const onEditClick = async (testimonial?: RecruiterTestimonalsDto) => {
    const result = await openModal({ testimonial });
    const recruiterTestimonals = [...testimonials];
    const index = recruiterTestimonals.findIndex(
      (c) => c.id === testimonial?.id,
    );
    if (!result || index < 0) {
      return;
    }
    if (result.delete) {
      recruiterTestimonals.splice(index, 1);
    } else {
      recruiterTestimonals[index] = {
        ...recruiterTestimonals[index],
        companyName: result.companyName,
        quotes: result.quote,
        isBetterleapClient: false,
      };
    }
    onUpdate?.({ recruiterTestimonals });
  };
  return (
    <CardSection>
      <SectionTitleArea>
        <SectionTitle title='Testimonials' icon='star' />
        {!!testimonials.length && !!onUpdate && (
          <AddButton text='Add testimonial' onClick={onAddClick} />
        )}
      </SectionTitleArea>
      {!testimonials.length && !!onUpdate ? (
        <AddFirstButton
          text='Add your first testimonial'
          onClick={onAddClick}
        />
      ) : (
        <Flex
          css={{
            width: '100%',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          {!testimonials.length ? (
            <Box css={{ alignSelf: 'flex-start' }}>
              This recruiter has not yet entered any testimonials.
            </Box>
          ) : (
            testimonials.map((testimonial) => (
              <RecruiterProfileTestimonial
                key={testimonial.id}
                testimonial={testimonial}
                onEditClick={onUpdate ? onEditClick : undefined}
              />
            ))
          )}
        </Flex>
      )}
    </CardSection>
  );
};
