import { Tooltip } from '@betterleap/ui';
import { useMe } from '@betterleap/shared';
import { ProjectContactStatus } from '@betterleap/client';
import { Row } from 'react-table';
import { ProjectActionButton } from '../../ProjectActions';
import {
  hasSelection,
  isFreeTrial,
  selectContactIds,
} from '../../ProjectDetails.functions';
import { useProjectContactsFilterParams } from '../../useProjectContactsFilterParams';
import { useExportContacts } from '../Hooks/useExportContacts';

export interface ExportAsCsvButtonProps {
  projectId: string;
  selectedContacts: Row<Record<string, unknown>>[] | 'all';
}

const noPersonalEmailFilter = ProjectContactStatus.NO_PERSONAL_EMAIL;

export const ExportAsCsvButton = ({
  projectId,
  selectedContacts,
}: ExportAsCsvButtonProps) => {
  const { contactProjectQuery, statuses } = useProjectContactsFilterParams();
  const exportAsCsv = useExportContacts();
  const me = useMe();

  const hasPermissions = me.ability.can('export', 'Contact');
  const featureEnabled = me.user?.organization?.features?.enabledExportEmails;
  const isExportableFilter = statuses.includes(noPersonalEmailFilter);
  const canExport = (hasPermissions && featureEnabled) || isExportableFilter;

  const isDisabled = !hasSelection(selectedContacts) || isFreeTrial(me.user);

  return !canExport ? null : (
    <Tooltip content='Export as CSV'>
      <ProjectActionButton
        disabled={isDisabled}
        name='download'
        label='Download contacts as CSV.'
        onClick={() =>
          exportAsCsv({
            ...contactProjectQuery,
            projectId,
            contactIds: selectContactIds(selectedContacts),
          })
        }
      />
    </Tooltip>
  );
};
