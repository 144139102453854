import { useLogout } from 'hooks/useLogout';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import betterleapLogo from '../../../assets/images/betterleap-logo.png';
import routeNames from '../../../constants/routeNames';
import { selectMe } from '../../../store/api/selectors';
import styles from './Header.module.scss';

const Header = () => {
  const me = useSelector(selectMe);
  const { logout } = useLogout();

  return (
    <div
      className={`w-full flex ${
        me?.data ? 'justify-between' : 'justify-center'
      } items-center  fixed left-0 right-0 top-0 z-10 ${styles.header_wrapper}`}
    >
      <Link to={routeNames.home()}>
        <img
          src={betterleapLogo}
          alt='logo'
          className={`cursor-pointer ${styles.logo}`}
        />
      </Link>
      {!!me?.data && (
        <p
          role='presentation'
          className='font-medium text-sm text-gray-400 cursor-pointer'
          onClick={logout}
        >
          Logout
        </p>
      )}
    </div>
  );
};

export default Header;
