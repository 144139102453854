import 'firebase/compat/storage';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { useMutation } from 'react-query';
import { CreateFileDto, FileEntity } from '@betterleap/client';

import { randomString } from '../functions/string.functions';
import { getUser } from '../helper';
import { firebase } from '../helper/firebase';
import { getApiClient } from '../lib';

interface UseUploadFileProps {
  path: string;
  basePath?: string;
  onSuccess?: (file: FileEntity) => void;
  onProgress?: (progress: number) => void;
}

interface UploadFileToFireBaseOptions {
  getDownloadURL?: boolean;
}

const useUploadFile = ({
  path,
  basePath,
  onSuccess,
  onProgress,
}: UseUploadFileProps) => {
  const apiClient = getApiClient();

  const uploadFileToFirebase = async (
    file: File,
    options: UploadFileToFireBaseOptions = { getDownloadURL: true },
  ) => {
    const user = await getUser();

    const resolvedBasePath = basePath ?? `users/${user?.uid}`;

    if (!user && resolvedBasePath.startsWith('users')) {
      throw new Error('User not found');
    }

    // warning: do not remove prefix because it is assumed to exist elsewhere!
    const filename = `${randomString()}-${file.name}`;
    const fullPath = [resolvedBasePath, path, filename]
      .filter((pathPart) => !!pathPart)
      .join('/');
    const storage = getStorage(firebase.app());
    const storageRef = ref(storage, fullPath);
    const task = uploadBytesResumable(storageRef, file);

    return new Promise<CreateFileDto>((resolve, reject) => {
      task.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress?.(progress);
        },
        (err) => {
          reject(err);
        },
        async () => {
          let downloadUrl;

          if (options?.getDownloadURL) {
            downloadUrl = await getDownloadURL(storageRef);
          }

          resolve({
            name: filename,
            gcsUrl: downloadUrl,
          });
        },
      );
    });
  };

  const saveFile = useMutation((requestBody: CreateFileDto) =>
    apiClient.file.saveFile({ requestBody }),
  );

  const uploadFile = async (file: File) => {
    const createFileRequest = await uploadFileToFirebase(file);
    const result = await saveFile.mutateAsync(createFileRequest);
    onSuccess?.(result.data);
    return result.data;
  };

  return { uploadFile, uploadFileToFirebase };
};

export default useUploadFile;
