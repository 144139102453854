import { emailRegex, excludedEmails } from '@betterleap/shared';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Form,
  BoundInput as Input,
  Modal,
  Text,
} from '@betterleap/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export interface InvitationRequest {
  firstName: string;
  lastName: string;
  email: string;
}

interface InviteData {
  firstName?: string;
  lastName?: string;
  email?: string;
  dataCy?: string;
}

export interface InviteExternalModalProps {
  title: string;
  message: string;
  excludePersonalEmails?: boolean;
  onDismiss: () => void;
  onSubmit: (data: InviteData) => void;
}

const InviteExternalModal = ({
  title,
  message,
  excludePersonalEmails = true,
  onDismiss,
  onSubmit,
}: InviteExternalModalProps) => {
  const { control, handleSubmit } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
  }>();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const handleInvite = async (data: InviteData) => {
    const re = new RegExp(`^((?!${excludedEmails.join('|')}).)*$`);
    if (data?.email && excludePersonalEmails && !re.test(data?.email)) {
      setErrorMessage('Please use your invitee’s work email address.');
      return;
    }

    try {
      await onSubmit({
        ...data,
        email: data?.email?.replace(/ /g, '').toLowerCase(),
      });
      onDismiss();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message === 'This user shares your domain.') {
        setErrorMessage(error.message);
      } else onDismiss();
    }
  };

  return (
    <Modal>
      <Flex vertical css={{ width: '100%', alignItems: 'center' }}>
        <AlertIcon variant='purple' name='mail' css={{ mb: 24 }} />
        <Text
          as='h2'
          css={{
            fontWeight: '$medium',
            fontSize: '$lg',
            mb: 8,
          }}
          dataCy='Share project modal'
        >
          {title}
        </Text>
        <Text
          as='p'
          css={{
            fontWeight: '$normal',
            fontSize: '$sm',
            color: '$text-lightest',
            textAlign: 'center',
          }}
        >
          {message}
        </Text>

        {errorMessage && (
          <Alert
            variant='danger'
            css={{
              padding: '16px 20px',
              width: '100%',
              mt: '20px',
            }}
          >
            <AlertDescription>
              {errorMessage === 'This user shares your domain.' ? (
                <Text
                  css={{
                    color: '#DC2626',
                    fontSize: '12px',
                    display: 'inline',
                  }}
                >
                  This user shares your domain. Invite them to join via the{' '}
                  <Text
                    onClick={() => {
                      navigate('/team');
                      onDismiss();
                    }}
                    css={{
                      all: 'inherit',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    team page.
                  </Text>
                </Text>
              ) : (
                <Text css={{ color: '#DC2626', fontSize: '12px' }}>
                  {errorMessage}
                </Text>
              )}
            </AlertDescription>
          </Alert>
        )}
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <Form control={control as any} onSubmit={handleSubmit(onSubmit)}>
          <Flex css={{ gap: 8, mt: 24 }}>
            <Input
              placeholder='First Name'
              type='text'
              required
              name='firstName'
              id='first-name'
              rules={{
                pattern: {
                  value: /[A-Za-z]/,
                  message: 'This field is required',
                },
              }}
              dataCy='First name'
            />
            <Input
              placeholder='Last Name'
              type='text'
              name='lastName'
              id='last-name'
              required
              rules={{
                pattern: {
                  value: /[A-Za-z]/,
                  message: 'This field is required.',
                },
              }}
              dataCy='Last name'
            />
          </Flex>
          <Input
            placeholder='Email Address'
            type='text'
            name='email'
            id='email'
            dataCy='mail input'
            rules={{
              pattern: {
                value: emailRegex,
                message: 'Invalid email',
              },
            }}
            required
          />
        </Form>
        <Flex css={{ gap: 8 }}>
          <Button
            variant='ghost'
            onClick={onDismiss}
            css={{ width: 146 }}
            dataCy='Cancel'
          >
            Cancel
          </Button>
          <Button
            css={{ width: 146 }}
            onClick={handleSubmit(handleInvite)}
            dataCy='Invite'
          >
            Invite
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
export default InviteExternalModal;
