import { ExtendedContactProject } from '@betterleap/client';
import { Box, Checkbox, CheckboxProps, Label } from '@betterleap/ui';
import React from 'react';

export const ActionMenuCheck = ({ css, ...props }: CheckboxProps) => (
  <Checkbox
    css={{
      p: 4,
      hover: {
        backgroundColor: '$background-muted',
      },
      ...css,
    }}
    {...props}
  />
);

export const InMailStatusSelector = ({
  contact,
  onChange,
}: {
  contact: ExtendedContactProject;
  onChange: CheckboxProps['onChange'];
}) => {
  const [value, setValue] = React.useState<string | undefined>(
    contact.externallyContactedStatus,
  );

  const handleChange = async (checked: boolean, newValue?: string) => {
    setValue(checked ? newValue : '');
    onChange?.(checked, newValue);
  };

  return (
    <Box
      css={{ p: 12, borderBottom: '1px solid $neutral-blue-300' }}
      onClick={(e) => e.stopPropagation()}
    >
      <Label
        css={{ fontSize: '$xs', color: '$text-secondary', pb: 8 }}
        htmlFor='inmail-status-select'
      >
        INMAIL STATUS
      </Label>
      <ActionMenuCheck
        onChange={handleChange}
        value='Contacted'
        checked={value === 'Contacted'}
      >
        InMailed
      </ActionMenuCheck>
      <ActionMenuCheck
        onChange={handleChange}
        value='Replied'
        checked={value === 'Replied'}
      >
        Replied
      </ActionMenuCheck>
    </Box>
  );
};
