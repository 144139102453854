import { QueueTask } from '@betterleap/client';
import {
  Flex,
  ProgressBar,
  ProgressBarProps,
  Text,
  Toast,
  ToastClose,
  ToastDescription,
  ToastIcon,
  ToastTitle,
} from '@betterleap/ui';
import { activeTaskState } from 'hooks/useGetTasks';
import { atom } from 'jotai';
import { useAtomValue, useSetAtom } from 'jotai/react';
import { apiClient } from 'lib/apiClient';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const csvTaskListState = atom((get) => {
  const dismissedTasks = get(dismissedTaskState);

  return get(activeTaskState)
    .filter(
      (task) => task.jobName === QueueTask.jobName.CONTACT_CSV_IMPORT_CONTACTS,
    )
    .filter((task) => !dismissedTasks.has(task.id));
});

const dismissedTaskState = atom(new Set<string>());

const isTaskActive = (taskStatus: QueueTask.status) => {
  return (
    taskStatus === QueueTask.status.ACTIVE ||
    taskStatus === QueueTask.status.WAITING
  );
};

const isTaskDone = (taskStatus: QueueTask.status) => {
  return (
    taskStatus === QueueTask.status.COMPLETED ||
    taskStatus === QueueTask.status.FAILED
  );
};

export const CsvTaskToast = ({
  task,
  title,
  onComplete,
}: {
  title: string;
  task: QueueTask;
  onComplete?: () => void;
}) => {
  const setDismissedTasks = useSetAtom(dismissedTaskState);
  const [progressQueryEnabled, setProgressQueryEnabled] = useState(
    isTaskActive(task.status),
  );

  const { data } = useQuery(
    ['task-status', task.jobId],
    async () => {
      const response = await apiClient.queueTask.getJob({
        queueName: 'contact-csv',
        jobId: task.jobId,
      });

      return response.data;
    },
    {
      refetchInterval: 1000,
      enabled: progressQueryEnabled,
      onSuccess: (response) => {
        if (isTaskDone(response.status)) {
          onComplete?.();
          setProgressQueryEnabled(false);
        }
      },
    },
  );

  const dismissTaskPermanently = useMutation(() => {
    return apiClient.queueTask.update({
      queueTaskId: task.id,
      requestBody: {
        dismissedAt: new Date().toISOString(),
      },
    });
  });

  const handleDismiss = (status: QueueTask.status) => {
    setDismissedTasks((prev) => new Set([...prev, task.id]));

    if (isTaskDone(status)) {
      dismissTaskPermanently.mutate();
    }
  };

  let variant;
  let description = 'Your CSV upload is processing...';
  const status = data?.status ?? task.status;
  let statusText = capitalize(status);
  switch (data?.status ?? task.status) {
    case QueueTask.status.ACTIVE:
      variant = 'info';
      statusText = 'In progress';
      break;
    case QueueTask.status.FAILED:
      variant = 'danger';
      description = `Your CSV upload failed. Please try again.`;
      break;
    case QueueTask.status.COMPLETED:
      variant = 'success';
      description = `Your CSV upload completed.`;
      break;
    default:
      variant = 'info';
  }

  let progress = data?.progress ?? 0;

  if (isTaskDone(status)) {
    progress = 100;
  }

  return (
    <Toast
      duration={Infinity}
      onOpenChange={(open) => {
        if (!open) {
          handleDismiss(status);
        }
      }}
    >
      <ToastIcon name='upload' variant={'info'} />
      <ToastTitle>{title}</ToastTitle>
      <ToastDescription>
        <Text css={{ mb: 16 }} inherit>
          {description}
        </Text>
        <Flex justify='between' css={{ pb: 8 }}>
          <Text
            size={'xs'}
            css={{ fontWeight: '$bold', color: '$text-secondary' }}
          >
            {statusText}
          </Text>
          <Text size='xs' css={{ color: '$text-secondary' }}>
            {progress}%
          </Text>
        </Flex>
        <ProgressBar
          variant={variant as ProgressBarProps['variant']}
          value={progress}
        />
      </ToastDescription>
      <ToastClose />
    </Toast>
  );
};

export const CsvTaskList = () => {
  const queryClient = useQueryClient();
  const taskList = useAtomValue(csvTaskListState);

  return (
    <>
      {taskList.map((task) => (
        <CsvTaskToast
          key={task.id}
          task={task}
          title='CSV Upload Status'
          onComplete={() => {
            const projectId = task.data.projectId;
            queryClient.invalidateQueries([
              'get_project_with_stats',
              projectId,
            ]);
            queryClient.invalidateQueries(['get_project_contacts', projectId]);
          }}
        />
      ))}
    </>
  );
};
