import {
  AlertIcon,
  AlertIconProps,
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalTitle,
  Text,
} from '@betterleap/ui';

declare interface SequenceConfirmationModalProps {
  title: string;
  notes: { id: number; name: string }[];
  description: string;
  icon: {
    variant: 'success' | 'danger' | 'info' | 'warning';
    name: AlertIconProps['name'];
  };
  confirmButtonVariant?: 'danger' | 'primary' | 'ghost';
  confirmButtonText?: string;
  onDismiss: () => void;
  onSubmit: () => void;
}

const SequenceConfirmationModal = ({
  title,
  notes,
  description,
  icon,
  confirmButtonVariant,
  confirmButtonText,
  onDismiss,
  onSubmit,
}: SequenceConfirmationModalProps) => (
  <>
    <Modal isDismissable={false}>
      <Flex css={{ alignItems: 'center' }} vertical>
        <Box css={{ py: 16 }}>
          <AlertIcon variant={icon.variant} name={icon.name} />
        </Box>
        <ModalTitle>{title}</ModalTitle>
        {notes.length > 0 && (
          <Box
            css={{
              backgroundColor: '$neutral-blue-50',
              borderRadius: '6px',
              padding: '16px',
            }}
          >
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '12px',
              }}
            >
              <Icon
                color='$neutral-blue-500'
                name='information-circle'
                size={16}
              />
              <Text
                size='sm'
                css={{
                  color: '$neutral-blue-600',
                  fontWeight: '500',
                  marginLeft: '14px',
                }}
              >
                Please note:
              </Text>
            </Box>
            {notes.map((note: { id: number; name: string }) => (
              <Box key={note.id} css={{ marginLeft: '38px', display: 'flex' }}>
                <Text
                  size='sm'
                  css={{ color: '$text-lightest', textAlign: 'center' }}
                >
                  ●
                </Text>
                <Text
                  size='sm'
                  css={{ color: '$text-lightest', marginLeft: '7px' }}
                >
                  {note.name}
                </Text>
              </Box>
            ))}
          </Box>
        )}
        <Text size='sm' css={{ color: '$text-lightest', textAlign: 'center' }}>
          {description}
        </Text>
        <Flex css={{ gap: 8, pt: 24, width: '100%' }}>
          <Button full variant='ghost' onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            full
            variant={confirmButtonVariant ?? 'primary'}
            onClick={onSubmit}
            dataCy='Confirm-Remove button'
          >
            {confirmButtonText}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  </>
);
export default SequenceConfirmationModal;
