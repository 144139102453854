import { apiClient } from 'lib/apiClient';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { addToViewingSession } from './contactViewingSession.functions';

const debounceTime = 1000;

export const useContactView = () => {
  const [viewedNow, setViewedNow] = useState<string[]>([]);
  const [viewed, setViewed] = useState<string[]>([]);

  const updateContactsViews = useMutation((contactsId: string[]) =>
    apiClient.contactView.upsert({
      requestBody: {
        contactsId,
      },
    }),
  );

  const { data } = useQuery(['view_contact'], () =>
    apiClient.contactView.get(),
  );

  const viewedContacts = useMemo(
    () => data?.data.map((viewedContact) => viewedContact.contactId),
    [data],
  );

  useEffect(() => {
    const debounced = _.debounce(() => {
      const newViews = viewedNow.filter((id) => !viewed.includes(id));
      newViews?.length && updateContactsViews.mutate(newViews);
      setViewed((prev) => [...prev, ...viewedNow]);
    }, debounceTime);

    debounced();

    return () => {
      debounced.cancel();
    };
  }, [viewedNow]);

  const updateViewedList = (contactIds: string | string[]) => {
    if (!Array.isArray(contactIds)) {
      contactIds = [contactIds];
    }
    contactIds.forEach((contactId) => {
      addToViewingSession(contactId);
      if (viewedContacts?.includes(contactId)) return;
      setViewedNow((prev) => {
        if (prev.includes(contactId) || !contactId) return prev;
        return [...prev, contactId];
      });
    });
  };
  return {
    onViewContact: updateViewedList,
    viewedContacts,
  };
};
