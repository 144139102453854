import { TagDto } from '@betterleap/client';
import { Badge, FlexProps, OverflowIndicator } from '@betterleap/ui';
import { OverflowList } from 'components/elements/OverflowList/OverflowList';

interface ProjectTagListProps extends FlexProps {
  max?: number;
  tags: TagDto[];
  onRemoveTag?: (tagId: string) => void;
}

export const ProjectTagList = ({
  tags,
  onRemoveTag,
  max = 3,
  ...rest
}: ProjectTagListProps) => (
  <OverflowList<TagDto>
    items={tags}
    max={max}
    indicatorContent={(items) => (
      <OverflowIndicator
        css={{
          fontSize: '$xs',
          py: 0,
          px: 4,
          mr: 8,
        }}
      >
        + {items.length}
      </OverflowIndicator>
    )}
    {...rest}
  >
    {(tag) => (
      <Badge
        css={{
          mr: 8,
        }}
        size='xs'
        variant='gray'
        shape='rectangle'
        onRemove={
          onRemoveTag
            ? () => {
                onRemoveTag(tag.id);
              }
            : undefined
        }
      >
        {tag.name}
      </Badge>
    )}
  </OverflowList>
);
