import { Box, BoxProps } from '@betterleap/ui';

export const HeaderMain = ({ css, ...props }: BoxProps) => (
  <Box
    css={{
      display: 'grid',
      gridTemplateAreas: `
        "header"
        "main"
      `,
      gridTemplateRows: 'auto 1fr',
      height: '100%',
      ...css,
    }}
    {...props}
  />
);

HeaderMain.Header = function Header({ css, ...props }: BoxProps) {
  return <Box css={{ gridArea: 'header', ...css }} {...props} />;
};

HeaderMain.Main = function Main({ css, ...props }: BoxProps) {
  return <Box css={{ gridArea: 'main', ...css }} {...props} />;
};
