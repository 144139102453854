import { LoadingArea } from '@betterleap/shared';
import { PersonSearchRequest, SavedSearch } from '@betterleap/client';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Divider,
  Flex,
  Icon,
  StyledIcon,
  Text,
  Tooltip,
  useIsOverflow,
} from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { ReactNode, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import merge from 'lodash/merge';
import { Link } from 'react-router-dom';

export interface RecentSavedSearchesProps extends BoxProps {
  onApplySearch: (params: PersonSearchRequest) => void;
}

export const ListItemButton = ({
  css,
  label,
  children,
  ...props
}: ButtonProps & { label: ReactNode }) => {
  const labelRef = useRef<HTMLElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useIsOverflow(
    labelRef,
    (overflow: boolean) => {
      setIsOverflow(overflow);
    },
    {
      type: 'horizontal',
    },
  );

  return (
    <Tooltip disabled={!isOverflow} content={label} css={{ maxWidth: 250 }}>
      <Button
        full
        variant='headless'
        css={merge(
          {
            focus: {
              boxShadow: 'none',
              [`& ${StyledIcon}`]: {
                boxShadow: '$focus',
              },
            },
            p: 16,
            gap: 16,
            fontSize: '$base',
            justifyContent: 'space-between',
          } as BoxProps['css'],
          css,
        )}
        {...props}
      >
        <Text ref={labelRef} inherit truncate flex>
          {label}
        </Text>
        {children}
      </Button>
    </Tooltip>
  );
};

export const RecentSavedSearches = ({
  onApplySearch,
  ...rest
}: RecentSavedSearchesProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: recentSavedSearchResponse, isLoading } = useQuery(
    ['recent_saved_searches'],
    () =>
      apiClient.savedSearch.get({
        pageSize: 6,
      }),
  );

  const savedSearches = recentSavedSearchResponse?.data ?? [];

  const handleApplySearchParams = (savedSearch: SavedSearch) => {
    const savedSearchParams = savedSearch.parameters;
    const newUrlParams = new URLSearchParams();

    for (const key in savedSearchParams) {
      newUrlParams.set(
        key,
        savedSearchParams[key as keyof PersonSearchRequest] as string,
      );
    }

    onApplySearch(savedSearchParams);
  };

  return (
    <Box {...rest}>
      <Collapsible onOpenChange={setIsOpen}>
        <CollapsibleTrigger asChild>
          <ListItemButton
            css={{
              fontWeight: '$medium',
            }}
            label='Saved Searches'
            variant='headless'
          >
            <Icon
              css={{
                open: { transform: 'rotate(180deg)' },
                borderRadius: '$lg',
              }}
              name='chevron-down'
              data-open={isOpen}
            />
          </ListItemButton>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <LoadingArea isLoading={isLoading}>
            {savedSearches.map((savedSearch: SavedSearch) => (
              <ListItemButton
                key={savedSearch.id}
                css={{
                  py: 8,
                  overflow: 'hidden',
                  fontSize: '$sm',
                  width: 312,
                  hover: {
                    backgroundColor: '$neutral-blue-200',
                  },
                }}
                label={savedSearch.name}
                onClick={() => handleApplySearchParams(savedSearch)}
              >
                <div>
                  <Icon
                    name='search'
                    size={30}
                    css={{
                      p: 8,
                      backgroundColor: '$neutral-blue-200',
                      borderRadius: '$lg',
                    }}
                  />
                </div>
              </ListItemButton>
            ))}
          </LoadingArea>
          <Flex css={{ pt: 8, px: 16, pb: 16 }}>
            <Link to={'/saved-searches'}>
              <Text variant={'link'} flex css={{ gap: 8, fontSize: '$xs' }}>
                View all saved searches{' '}
                <Icon inherit name='arrow-narrow-right' size={16} />
              </Text>
            </Link>
          </Flex>
        </CollapsibleContent>
      </Collapsible>
      <Divider />
    </Box>
  );
};
