import { v4 as uuid } from 'uuid';

const environment = (process.env.REACT_APP_ENV || 'development') as string;

const isWindows = navigator.platform.indexOf('Win') > -1;
const configs = {
  common: {
    isWindows,
    environment,
    run: uuid(), // Unique ID for the duration of this app run
    endpoints: {},
    keys: {},
  },
  development: {
    environment,
    endpoints: {
      api: 'http://localhost:4000/api',
    },
    url: 'http://localhost:3000',
    keys: {},
    test_user: localStorage.getItem('QA_TEST_USER_OVERRIDE'),
    calendly: 'https://calendly.com/youssef43/10min',
  },
  qa: {
    environment,
    endpoints: {
      api: 'https://qa.betterleap-api.com/api',
    },
    url: 'https://qa-app.betterleap.com',
    calendly: 'https://calendly.com/youssef43/10min',
    test_user: localStorage.getItem('QA_TEST_USER_OVERRIDE'),
    keys: {},
  },
  stage: {
    environment,
    endpoints: {
      api: 'https://stage.betterleap-api.com/api',
    },
    url: 'https://stage-app.betterleap.com',
    calendly: 'https://calendly.com/youssef43/10min',
    keys: {},
    test_user: localStorage.getItem('QA_TEST_USER_OVERRIDE'),
  },
  production: {
    endpoints: {
      api: 'https://prod.betterleap-api.com/api',
    },
    url: 'https://app.betterleap.com',
    calendly: 'https://calendly.com/betterleap-team/welcome-recruiters',
    keys: {},
  },
  test_cypress: {
    endpoints: {
      api: 'https://test-cypress.betterleap-api.com/api',
    },
    test_user: localStorage.getItem('QA_TEST_USER_OVERRIDE'),
  },
};

const config = {
  ...configs.common,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...configs[environment],
};

export default config;
