export function saveViewingSession(contactIds: string[]) {
  sessionStorage.setItem('viewingSession', contactIds.join(','));
}

export function restoreViewingSession() {
  const session = sessionStorage.getItem('viewingSession')?.split(',') || [];
  return session;
}

export function addToViewingSession(contactId: string) {
  const viewingSession = restoreViewingSession();
  if (viewingSession.includes(contactId)) return;
  saveViewingSession([...viewingSession, contactId]);
}

export function resetViewingSession() {
  sessionStorage.removeItem('viewingSession');
}
