import { AUTH, AuthAction } from './actions';

interface AppState {
  init: boolean;
  isVerifyEmailLinkLoading: boolean;
  isEmailLogin: boolean;
}

export const INITIAL_STATE: AppState = {
  init: false,
  isVerifyEmailLinkLoading: false,
  isEmailLogin: false,
};

const authReducer = (state = INITIAL_STATE, action: AuthAction): AppState => {
  switch (action.type) {
    case AUTH.SET_EMAIL_LINK_VERIFY_LOGIN:
      return {
        ...state,
        isEmailLogin: true,
        isVerifyEmailLinkLoading: action?.payload,
      };
    default:
      return state;
  }
};

export { authReducer };
