import routeNames from 'constants/routeNames';
import {
  ExtendedContactProject,
  ProjectWithStatsDto,
} from '@betterleap/client';
import { showToast, useModal } from '@betterleap/ui';
import { CandidateSubmitSuccessModal } from 'components/modules/Modal/SubmitCandidateModal/CandidateSubmitSuccessModal';
import {
  SubmitCandidatesModal,
  SubmitCandidatesModalProps,
  SubmitCandidateWithInviteForm,
} from 'components/modules/Modal/SubmitCandidateModal/SubmitCandidateModal';
import { useNavigate } from 'react-router-dom';

interface SubmitCandidateProps {
  project: ProjectWithStatsDto;
  onCandidateSubmitted: () => void;
}

export const useSubmitCandidate = ({
  project,
  onCandidateSubmitted,
}: SubmitCandidateProps) => {
  const navigate = useNavigate();
  const openSubmitCandidateModal = useModal<
    SubmitCandidatesModalProps,
    SubmitCandidateWithInviteForm
  >(SubmitCandidatesModal);
  const openSubmitSuccessModal = useModal(CandidateSubmitSuccessModal);
  const submitCandidate = async (contact: ExtendedContactProject) => {
    try {
      const submission = await openSubmitCandidateModal({
        isProjectShared: !!project?.sharedToOrganizationId,
        submitToAts: project?.linkedATS,
        contact,
      });

      if (submission) {
        onCandidateSubmitted();

        const viewCandidate = await openSubmitSuccessModal();

        if (viewCandidate) {
          navigate(routeNames.dashboard());
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      switch (err?.status) {
        case 409:
          showToast({
            variant: 'danger',
            title: 'Duplicate Submission',
            description:
              'This candidate has already been submitted to the client.',
          });
          break;
        default:
          showToast({
            variant: 'danger',
            title: 'Something went wrong!',
            description: 'Failed to submit candidate. Please try again.',
          });
      }
    }
  };

  return { submitCandidate };
};
