import { Organization } from '@betterleap/client';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectOrganization } from 'store/api/selectors';
import PortfolioPaywall from 'components/templates/PortfolioPaywallTemplate/PortfolioPaywall.template';
import withSidebar from '../components/HOC/withSidebar';
import PortfolioTemplate from '../components/templates/Portfolio/Portfolio.template';

const Portfolio = () => {
  const organization = useSelector(selectOrganization);

  return organization?.data?.tier === Organization.tier.PAYWALL ? (
    <PortfolioPaywall tier={organization.data.tier} />
  ) : (
    <PortfolioTemplate />
  );
};

export default withSidebar(Portfolio, true);
