import { Spinner } from '@betterleap/shared';
import {
  ApiError,
  ApiResponse,
  ContactPreview,
  CreateManyContactProjectsDto,
  Organization,
  SourceManyContactsDto,
} from '@betterleap/client';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  IconButton,
  showToast,
  Tab,
  TabContent,
  Tabs,
  TabsList,
  Text,
} from '@betterleap/ui';
import qs from 'qs';
import { useMutation, useQuery } from 'react-query';
import {
  createSearchParams,
  URLSearchParamsInit,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { atomWithReset, useHydrateAtoms, useResetAtom } from 'jotai/utils';
import { Row, TableInstance, UseRowSelectInstanceProps } from 'react-table';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { apiClient } from 'lib/apiClient';
import { useScrollTop } from 'hooks/useScrollTop';
import { HeaderMain } from 'components/elements/Layouts/HeaderMain';
import { formatNumber } from 'functions/formatNumber';
import {
  resetViewingSession,
  restoreViewingSession,
} from 'hooks/contactViewingSession.functions';
import { drawerCollectionState } from 'components/modules/Drawer/ContactDrawer/ContactDrawer';
import { SourceCandidatesSearchParams } from './SourceCandidates.types';
import SourceCandidatesFilterArea from './SourceCandidatesFilterArea.template';
import { SourceCandidatesResultsArea } from './SourceCandidatesResultsArea';
import { SourceCandidatesLayout } from './SourceCandidates.layout';
import { NaturalLanguageSearch, nlsInputState } from './NaturalLanguageSearch';
import { SaveSearchButton } from './SaveSearchButton';
import { RecentSavedSearches } from './RecentSavedSearches';
import { AddToProjectButton } from './AddToProjectButton';
import { SourceCandidatesEmptyState } from './SourceCandidatesEmptyState';
import { usePersonSearchQueryParams } from './useSourcingSearchParams';
import { useSetHighlights } from './useHighlightWords';

export enum SearchMode {
  MY_TALENT_POOL = 'My Talent Pool',
  WORLD_TALENT_POOL = 'World Talent Pool',
}

export interface ContactPreviewSearchResult extends ContactPreview {
  contactId?: string;
}

const defaultFilterState = {
  page: 1,
  pageSize: 30,
  locations: [],
  regions: [],
  skillsShould: [],
  skills: [],
  jobTitlesCurrent: [],
  jobTitlesPast: [],
  jobTitlesCurrentOrPast: [],
  gender: '',
  name: '',
  zipCodes: [],
  yearsOfExperienceMin: undefined,
  yearsOfExperienceMax: undefined,
  educationSchools: [],
  educationMajors: [],
  companyNamesCurrent: [],
  companyNamesPast: [],
  companyNamesCurrentOrPast: [],
  currentCompanySizes: [],
  currentOrPastCompanySizes: [],
  industries: [],
};

export const sourcingPageState = atom(1);
export const filterState =
  atomWithReset<SourceCandidatesSearchParams>(defaultFilterState);

const SourceCandidates = (): JSX.Element => {
  const { search } = useLocation();
  const { data: organization } = useQuery(['organization'], () =>
    apiClient.organization.getOrganization(),
  );
  const previousParamsRef = useRef<SourceCandidatesSearchParams>();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = qs.parse(search.slice(1));
  const setNlsInput = useSetAtom(nlsInputState);
  const [searchMode, setSearchMode] = useState(
    (queryParams?.searchMode as SearchMode) ?? SearchMode.WORLD_TALENT_POOL,
  );

  const [tableInstance, setTableInstance] =
    useState<
      Partial<
        TableInstance & UseRowSelectInstanceProps<Record<string, unknown>>
      >
    >();

  const [selectedRows, setSelectedRows] = useState<
    Row<Record<string, unknown>>[]
  >([]);

  const [noResultsFound, setNoResultsFound] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<
    string | undefined
  >(undefined);

  const [pageNumber, setPageNumber] = useAtom(sourcingPageState);

  const pageSize = parseInt((queryParams.pageSize as string) ?? '30', 10);
  const apiSearchParams = usePersonSearchQueryParams();

  /** Shared state */
  const [filters, setFilters] = useAtom(filterState);
  const setDrawerCollection = useSetAtom(drawerCollectionState);
  const resetFilters = useResetAtom(filterState);
  useHydrateAtoms([
    [filterState, apiSearchParams],
    [
      sourcingPageState,
      Number.parseInt((queryParams.page as string) ?? '1', 10),
    ],
  ]);

  const featureEnabledSourcing =
    organization?.data?.tier === Organization.tier.FREE_TRIAL ||
    organization?.data?.features?.enabledSourcing;

  const featureEnabledNaturalLanguageSourcing =
    organization?.data?.features?.enabledNaturalLanguageSourcing;

  /**
   * these params don't effect the intrinsic value of the filter so they are ignored
   * when calculating whether or not the filter has a value. (we don't allow searching when there is no value)
   */
  const searchParamsToOmit = useMemo(() => {
    const ignoreParams = [
      'pageSize',
      'page',
      'searchMode',
      'companySearchMode',
      'excludeViewed',
    ];

    return ignoreParams;
  }, [searchMode]);

  const canQuery = useMemo(() => {
    if (!featureEnabledSourcing) {
      return false;
    }

    if (isEqual(previousParamsRef.current, apiSearchParams)) {
      return false;
    }

    // look to make sure the filters aren't all empty
    return Object.values(omit(apiSearchParams, searchParamsToOmit)).some(
      (value) => {
        if (Array.isArray(value)) {
          return value.length;
        }

        return !!value;
      },
    );
  }, [featureEnabledSourcing, apiSearchParams, searchParamsToOmit]);

  const doesFilterHaveValues = useMemo(
    () =>
      Object.values(omit(filters, searchParamsToOmit)).some((value) => {
        if (Array.isArray(value)) {
          return value.length;
        }

        return !!value;
      }),
    [filters, searchParamsToOmit],
  );

  const doContactSearch = () =>
    apiClient.contactSearch.searchContacts({
      requestBody: apiSearchParams,
      page: pageNumber,
      pageSize,
    });

  const {
    data: contactSearchResponse,
    isLoading: isContactSearchLoading,
    isFetching: isFetchingContacts,
    refetch: refetchContactSearch,
    remove,
  } = useQuery(['search_contacts', queryParams], doContactSearch, {
    enabled: canQuery && searchMode === SearchMode.MY_TALENT_POOL,
    onError: () => {
      showToast({
        variant: 'danger',
        title: 'Something went wrong!',
        description: 'Failed to load results. Please try again.',
      });
    },
    onSuccess: (data) => {
      previousParamsRef.current = { ...apiSearchParams };
      if (data?.meta?.count === 0) {
        setNoResultsFound(true);
      } else {
        setNoResultsFound(false);
      }
    },
  });

  const searchPeople = useMutation(
    ['discover_candidates', queryParams],
    () =>
      apiClient.personSearch.searchPeople({
        requestBody: {
          ...apiSearchParams,
          viewingSessionContactIds: restoreViewingSession(),
        },
        page: pageNumber,
        pageSize,
      }),
    {
      onError: (error: ApiError) => {
        if (error.status === 404) {
          setNoResultsFound(true);
        } else {
          const title =
            error.status === 400
              ? 'Boolean syntax error'
              : 'Something went wrong!';
          const description =
            error.status === 400
              ? 'Please update your boolean search string and try again.'
              : 'Failed to load results. Please try again.';
          showToast({ variant: 'danger', title, description });
        }
        setSearchResults([]);
      },
      onSuccess: () => {
        previousParamsRef.current = { ...apiSearchParams };
        setNoResultsFound(false);
      },
    },
  );

  useSetHighlights({ filters, results: searchPeople.data?.data });

  const [searchResults, setSearchResults] = useState<
    Partial<ContactPreviewSearchResult>[]
  >([]);

  useEffect(() => {
    let totalCount: string | undefined;
    if (searchMode === SearchMode.MY_TALENT_POOL) {
      const results = (contactSearchResponse?.data ?? []).map(
        (contactSearch) => ({
          ...contactSearch,
          locationName: contactSearch.location,
          currentCompany: contactSearch.currentCompanyName,
          currentTitle: contactSearch.jobTitle,
        }),
      );

      setSearchResults(results);
      setDrawerCollection(results);
      totalCount = formatNumber(contactSearchResponse?.meta.count);
    } else if (!searchPeople.isLoading) {
      const results = searchPeople.data?.data?.searchResults?.results ?? [];
      setDrawerCollection(results);
      setSearchResults(results);
      totalCount = formatNumber(searchPeople.data?.meta.count);
    }
    if (totalCount) {
      setResultCount(totalCount);
    }
  }, [contactSearchResponse, searchPeople?.data, searchMode]);

  const sourceManyContacts = useMutation(
    (data: { requestBody: SourceManyContactsDto }) =>
      apiClient.contact.sourceManyContacts(data),
    {
      onSuccess: () => {
        const worldSearch = searchMode === SearchMode.WORLD_TALENT_POOL;
        if (worldSearch && !searchPeople.isLoading) {
          searchPeople.mutate();
        }
      },
    },
  );

  const createManyContactProjects = useMutation(
    (data: { requestBody: CreateManyContactProjectsDto }) =>
      apiClient.contactProject.createMany(data),
    {
      onSuccess: () => {
        const myPoolSearch = searchMode === SearchMode.MY_TALENT_POOL;
        if (myPoolSearch) {
          refetchContactSearch();
        }
      },
    },
  );

  const { ref, scrollTop } = useScrollTop();

  const handleFilterParamsChanged = (params: SourceCandidatesSearchParams) => {
    const newParams: URLSearchParamsInit = {
      ...Object.fromEntries(
        Object.entries(params).filter((entry) => !!entry[1]),
      ),
      searchMode,
    };

    setSearchParams(createSearchParams(newParams));
    tableInstance?.toggleAllRowsSelected?.(false);
    setSelectedRows([]);

    if (canQuery && searchMode === SearchMode.MY_TALENT_POOL) {
      refetchContactSearch();
    } else {
      setNoResultsFound(false);
    }
  };

  const handleSearch = (params: SourceCandidatesSearchParams) => {
    setPageNumber(1);
    resetViewingSession();
    setResultCount('');

    // Loop through params and if the key contains "Boolean" then trim the value to remove whitespace - Trimming these values here so we can make the textareas controlled inputs and the NLS search can update them.
    const trimmedParams = Object.fromEntries(
      Object.entries(params).map(([key, value]) => {
        if (key.includes('Boolean')) {
          return [key, value?.trim()];
        }
        return [key, value];
      }),
    );

    handleFilterParamsChanged({
      ...trimmedParams,
      page: 1,
    });
  };

  const handlePreviousPage = () => {
    if (searchMode === SearchMode.MY_TALENT_POOL) {
      setPageNumber(Math.max(pageNumber - 1, 1));
      scrollTop();
      handleFilterParamsChanged({
        ...apiSearchParams,
        page: Math.max(pageNumber - 1, 1),
      });

      return;
    }

    handleFilterParamsChanged({
      ...apiSearchParams,
      page: Math.max(pageNumber - 1, 0),
    });

    setPageNumber(Math.max(pageNumber - 1, 1));
    scrollTop();
    setResultCount('');
  };

  const handleNextPage = () => {
    if (searchMode === SearchMode.MY_TALENT_POOL) {
      setPageNumber(pageNumber + 1);
      scrollTop();
      handleFilterParamsChanged({
        ...apiSearchParams,
        page: pageNumber + 1,
      });

      return;
    }

    handleFilterParamsChanged({
      ...apiSearchParams,
      page: pageNumber + 1,
    });

    setPageNumber(pageNumber + 1);
    scrollTop();
    setResultCount('');
  };

  const onAddSelectedCandidatesToProject = (projectIds?: string[]) => {
    if (!projectIds?.length) {
      return;
    }
    const projectId = projectIds[0] as string;
    setSelectedProjectId(projectId);

    const selectedContacts = selectedRows.map(
      (row) => row.original as unknown as ContactPreviewSearchResult,
    );
    const mutationOptions = {
      onSuccess: async (
        response: ApiResponse<{ contactsAddedToProject: number }>,
      ) => {
        setSelectedProjectId('');
        showToast({
          variant: 'success',
          title: 'Success!',
          description: `${response.data.contactsAddedToProject} contacts added to project.`,
        });
      },
      onError: () => {
        setSelectedProjectId('');
        showToast({
          variant: 'danger',
          title: 'Adding contacts to project failed',
          description: 'Something went wrong. Please try again.',
        });
      },
    };
    if (searchMode === SearchMode.MY_TALENT_POOL) {
      createManyContactProjects.mutate(
        {
          requestBody: {
            contactIds: selectedContacts.map((sc) => sc.contactId as string),
            projectId,
            source: CreateManyContactProjectsDto.source.MY_TALENT_POOL,
          },
        },
        mutationOptions,
      );
    } else {
      sourceManyContacts.mutate(
        {
          requestBody: {
            contacts: selectedContacts,
            projectId,
          },
        },
        mutationOptions,
      );
    }
  };

  const count =
    searchMode === SearchMode.MY_TALENT_POOL
      ? contactSearchResponse?.meta.count
      : searchPeople.data?.meta.count;
  const [resultCount, setResultCount] = useState<string>('');
  const pageStart = (pageNumber - 1) * pageSize + 1;
  const pageEnd = pageNumber * pageSize;

  const canGoBack = pageNumber > 1;
  const canGoNext = (count || 0) > pageNumber * pageSize;

  const BackButton = () => (
    <IconButton
      size='sm'
      label='back'
      name='arrow-left'
      onClick={handlePreviousPage}
      variant='darkGray'
      disabled={!canGoBack}
    />
  );

  const NextButton = () => (
    <IconButton
      size='sm'
      label='next'
      name='arrow-right'
      onClick={handleNextPage}
      variant='darkGray'
      disabled={!canGoNext}
      css={{ ml: 8 }}
    />
  );

  const clearFilters = () => {
    resetFilters();
    setSearchResults([]);
    setNoResultsFound(false);
    setSearchParams(createSearchParams({}));
    remove();
    setNlsInput('');
    previousParamsRef.current = undefined;
    setResultCount('');
  };

  const isWorldTalentLoading = searchPeople.isLoading;
  const isMyTalentPoolLoading = isContactSearchLoading || isFetchingContacts;
  const isLoading = isWorldTalentLoading || isMyTalentPoolLoading;
  const shouldDisplaySpinner = !resultCount && isLoading;
  const isEmpty = searchResults?.length === 0 && !isFetchingContacts;

  useEffect(() => {
    const worldSearch = searchMode === SearchMode.WORLD_TALENT_POOL;

    if (canQuery && worldSearch && !searchPeople.isLoading) {
      searchPeople.mutate();
    }
  }, [search, canQuery]);

  return (
    <SourceCandidatesLayout
      featureEnabledNaturalLanguageSourcing={
        featureEnabledNaturalLanguageSourcing
      }
      css={{
        borderLeft: '2px solid $neutral-blue-300',
      }}
    >
      <SourceCandidatesLayout.SideBar
        css={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Tabs
          tabStyle='underline'
          defaultValue={apiSearchParams?.searchMode}
          css={{ height: '100%' }}
          onValueChange={(newValue) => {
            setSearchParams(
              createSearchParams({
                ...Object.fromEntries(searchParams),
                searchMode: newValue,
              }),
            );

            setSearchMode(newValue as SearchMode);
            setResultCount('');
          }}
        >
          <HeaderMain>
            <HeaderMain.Header>
              <TabsList css={{ justifyContent: 'center', pt: 16 }}>
                <Tab value={SearchMode.WORLD_TALENT_POOL}>
                  World Talent Pool
                </Tab>
                <Tab value={SearchMode.MY_TALENT_POOL}>Rediscovery</Tab>
              </TabsList>
              <Flex css={{ px: 16, pt: 24, pb: 8 }}>
                <SaveSearchButton
                  onSave={() => {
                    handleSearch(filters);
                  }}
                />
              </Flex>
              <RecentSavedSearches
                onApplySearch={(params) => {
                  handleSearch(params);
                  setFilters(params);
                }}
              />
            </HeaderMain.Header>
            <HeaderMain.Main css={{ overflow: 'auto', position: 'relative' }}>
              <TabContent
                css={{ minHeight: 'calc(100% - 73px)' }}
                value={SearchMode.WORLD_TALENT_POOL}
              >
                <SourceCandidatesFilterArea
                  searchMode={SearchMode.WORLD_TALENT_POOL}
                />
              </TabContent>
              <TabContent
                css={{ minHeight: 'calc(100% - 73px)' }}
                value={SearchMode.MY_TALENT_POOL}
              >
                <SourceCandidatesFilterArea
                  searchMode={SearchMode.MY_TALENT_POOL}
                />
              </TabContent>
              <Flex
                css={{
                  position: 'sticky',
                  bottom: 0,
                  p: '1rem',
                  borderTop: '1px solid $neutral-blue-300',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                  backgroundColor: '#F9FAFB',
                }}
              >
                <Button
                  size='xs'
                  variant='gray'
                  css={{
                    backgroundColor: '$neutral-blue-50',
                    borderColor: '$neutral-blue-50',
                    mb: 4,
                  }}
                  onClick={clearFilters}
                >
                  <Icon size={16} css={{ mr: 8 }} name='reset' />
                  Reset Filters
                </Button>
                <Button
                  disabled={!doesFilterHaveValues || !featureEnabledSourcing}
                  onClick={() => handleSearch(filters)}
                  css={{ ml: 10 }}
                >
                  Search
                </Button>
              </Flex>
            </HeaderMain.Main>
          </HeaderMain>
        </Tabs>
      </SourceCandidatesLayout.SideBar>
      {featureEnabledNaturalLanguageSourcing && (
        <NaturalLanguageSearch
          handleFilterParamsChanged={handleFilterParamsChanged}
        />
      )}
      <SourceCandidatesLayout.SubHeader
        id='sourcing-action-bar'
        css={{
          borderLeft: '1px solid $neutral-blue-300',
          borderBottom: '1px solid $neutral-blue-300',
          backgroundColor: '$background-component',
          display: 'flex',
          justifyContent: 'flex-end',
          px: 16,
          alignItems: 'center',
        }}
      >
        {shouldDisplaySpinner ? (
          <Box css={{ flexGrow: 1 }}>
            <Spinner variant='blue' />
          </Box>
        ) : (
          resultCount && (
            <>
              <Checkbox
                css={{ pr: 8 }}
                onChange={(checked) => {
                  tableInstance?.toggleAllRowsSelected?.(checked);
                }}
              />
              <Text css={{ flexGrow: 1, color: '$neutral-blue-700' }}>
                {resultCount} candidate{resultCount === '1' ? '' : 's'} found
              </Text>
            </>
          )
        )}
        <Flex
          css={{
            gap: 24,
          }}
        >
          <Text
            css={{
              fontSize: '$sm',
              color: '$neutral-blue-700',
              textAlign: 'right',
            }}
          >
            {selectedRows.length
              ? `${selectedRows.length} candidate${
                  selectedRows?.length > 1 ? 's' : ''
                } selected`
              : 'Select a candidate to take action'}
          </Text>
          <AddToProjectButton
            value={selectedProjectId}
            onChange={(ids) =>
              onAddSelectedCandidatesToProject(ids as string[])
            }
            disabled={!featureEnabledSourcing || !selectedRows.length}
          />
        </Flex>
        {resultCount && (
          <Text css={{ ml: 24, color: '$neutral-blue-700' }}>
            Results {pageStart}-{Math.min(pageEnd, count || pageEnd)}
          </Text>
        )}
        <Flex
          id='sourcing-paging-area'
          css={{ justifyContent: 'center', ml: 12 }}
        >
          <div>
            <BackButton />
            <NextButton />
          </div>
        </Flex>
      </SourceCandidatesLayout.SubHeader>
      <SourceCandidatesLayout.Main
        css={{
          backgroundColor: '$background-component',
          position: 'relative',
          overflow: 'hidden',
          borderLeft: '1px solid $neutral-blue-300',
          '&[data-is-empty="true"]': {
            mt: -56,
          },
        }}
        data-is-empty={!shouldDisplaySpinner && isEmpty}
      >
        <SourceCandidatesResultsArea
          ref={ref}
          featureEnabledSourcing={featureEnabledSourcing}
          isLoading={isFetchingContacts}
          searchResults={searchResults}
          searchMode={searchMode}
          selectedRows={selectedRows}
          onRowsSelected={setSelectedRows}
          onTableLoad={(table) => setTableInstance(table)}
          emptyState={
            <SourceCandidatesEmptyState
              noResultsFound={noResultsFound}
              searchMode={searchMode}
              onSuccess={handleFilterParamsChanged}
              text={
                searchMode === SearchMode.MY_TALENT_POOL
                  ? 'Search your talent pool by applying a filter'
                  : 'Search our global talent pool by applying a filter'
              }
            />
          }
        />
        <Flex
          css={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            px: 16,
            py: 12,
            backgroundColor: '$background-component',
            borderTop: '1px solid $neutral-blue-300',
            justifyContent: 'center',
            '&[data-is-empty="true"]': {
              display: 'none',
            },
          }}
          data-is-empty={isEmpty}
        >
          <Flex>
            <BackButton />
            <NextButton />
          </Flex>
        </Flex>
      </SourceCandidatesLayout.Main>
    </SourceCandidatesLayout>
  );
};

export default SourceCandidates;
