import { Flex, Text } from '@betterleap/ui';
import React from 'react';

interface PaywallProps extends React.ComponentProps<typeof Flex> {
  title: string;
  description: string;
  location: string;
}

const Paywall = ({ title, description, css }: PaywallProps) => {
  return (
    <Flex
      css={{
        p: 24,
        background: '$neutral-blue-100',
        borderRadius: '$xl',
        mt: 24,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 24,
        mediaMd: {
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
        },
        ...css,
      }}
    >
      <Flex vertical css={{ flex: 1 }}>
        <Text
          as='h1'
          css={{
            fontSize: '$3xl',
            color: '$primary',
            fontFamily: '$gilroy',
            maxWidth: 500,
            mb: 8,
          }}
        >
          {title}
        </Text>
        <Text css={{ fontSize: '$sm' }}>{description}</Text>
      </Flex>
    </Flex>
  );
};
export default Paywall;
