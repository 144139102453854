import {
  Flex,
  Form,
  IconButton,
  BoundInput as Input,
  RadioGroup,
  Spinner,
} from '@betterleap/ui';
import { useForm, useWatch } from 'react-hook-form';
import { ContactPhoneNumber } from '@betterleap/client';
import { phoneNumberRegex } from '../../../constants';
import ContactPhoneNumberItem from './ContactPhoneNumberItem.template';

export interface ContactPhoneNumberManageTemplateProps {
  contactPrimaryPhoneNumber: string | undefined;
  contactLinkedinUrl: string | undefined;
  isLoading: boolean;
  contactPhoneNumbers: ContactPhoneNumber[];
  handleAddPhoneNumber: (phoneNumber: string) => Promise<void>;
  handleRemovePhoneNumber: (
    contactPhoneNumber: ContactPhoneNumber,
  ) => Promise<void>;
  handleSetDefaultPhoneNumber: (phoneNumber: string) => Promise<void>;
  onPhoneNumberCopied?: () => void;
}

interface NewPhoneNumberForm {
  addNewPhoneNumberText: string;
}

const ContactPhoneNumberManageTemplate = (
  props: ContactPhoneNumberManageTemplateProps,
) => {
  const {
    contactPrimaryPhoneNumber,
    contactLinkedinUrl,
    isLoading,
    contactPhoneNumbers,
    handleAddPhoneNumber,
    handleRemovePhoneNumber,
    handleSetDefaultPhoneNumber,
    onPhoneNumberCopied,
  } = props;

  const { control, handleSubmit, setValue } = useForm<NewPhoneNumberForm>({
    defaultValues: { addNewPhoneNumberText: '' },
    reValidateMode: 'onSubmit',
  });

  const phoneNumberValue = useWatch({
    control,
    name: 'addNewPhoneNumberText',
  });

  const addNewPhoneNumber = (data: NewPhoneNumberForm) => {
    handleAddPhoneNumber(data.addNewPhoneNumberText);
    setValue('addNewPhoneNumberText', '');
  };

  return (
    <Flex
      vertical
      id='contact-phone-number-manager'
      css={{
        alignItems: 'flex-start',
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
    >
      {contactPhoneNumbers.length > 0 && (
        <Flex
          css={{
            width: '100%',
            border: '1px solid $neutral-blue-300',
            borderTopLeftRadius: '$xl',
            borderTopRightRadius: '$xl',
            borderBottom: 'none',
          }}
        >
          <form style={{ width: '100%' }}>
            <RadioGroup
              value={contactPrimaryPhoneNumber}
              onChange={(val) => {
                handleSetDefaultPhoneNumber(val);
              }}
            >
              {contactPhoneNumbers.map((contactPhoneNumber) => (
                <ContactPhoneNumberItem
                  key={contactPhoneNumber.id}
                  allowRemoval={Boolean(
                    contactLinkedinUrl || contactPhoneNumbers.length > 1,
                  )}
                  contactPhoneNumber={contactPhoneNumber}
                  handleRemovePhoneNumber={handleRemovePhoneNumber}
                  onPhoneNumberCopied={onPhoneNumberCopied}
                />
              ))}
            </RadioGroup>
          </form>
        </Flex>
      )}
      <Flex css={{ width: '100%' }}>
        <Form
          control={control}
          css={{ width: '100%' }}
          onSubmit={handleSubmit(addNewPhoneNumber)}
        >
          <Input
            aria-label='add-phone-number'
            css={{
              width: '100%',
            }}
            inputCss={{
              ...(contactPhoneNumbers.length
                ? {
                    borderTopRightRadius: '$none',
                    borderTopLeftRadius: '$none',
                    borderTop: 'none',
                    borderColor: '$neutral-blue-300',
                  }
                : {}),
            }}
            placeholder={
              contactPhoneNumbers.length
                ? 'Add another phone number'
                : 'Add phone number'
            }
            type='text'
            name='addNewPhoneNumberText'
            id='add-new-phone-number-text'
            rules={{
              pattern: {
                value: phoneNumberRegex,
                message: 'Invalid phone number',
              },
            }}
            rightIcon={
              isLoading ? (
                <Spinner />
              ) : (
                <IconButton
                  label='add'
                  name='arrow-right'
                  size='xs'
                  color={
                    phoneNumberRegex.test(phoneNumberValue)
                      ? '$blue-600'
                      : '$neutral-blue-500'
                  }
                  onClick={handleSubmit(addNewPhoneNumber)}
                />
              )
            }
            required
          />
        </Form>
      </Flex>
    </Flex>
  );
};

export default ContactPhoneNumberManageTemplate;
