import { Button, showToast, Spinner } from '@betterleap/ui';
import { useMe } from '@betterleap/shared';
import { Organization, ProjectWithStatsDto } from '@betterleap/client';
import { useQuery } from 'react-query';
import useConnectIntegration from 'hooks/useConnectIntegration';
import { apiClient } from 'lib/apiClient';
import { LinkRoleButton } from './LinkRoleButton';

interface ConnectAtsButtonProps {
  organization: Organization;
  project: ProjectWithStatsDto;
  onRoleLinked: () => void;
}

export const ConnectAtsButton = ({
  organization,
  project,
  onRoleLinked,
}: ConnectAtsButtonProps) => {
  const me = useMe();
  const { open, isLoading } = useConnectIntegration({
    location: 'Project details',
  });

  const { refetch: refetchSyncData } = useQuery(
    ['get_sync_status', organization.accountToken],
    () => apiClient.merge.checkSyncStatus(),
    {
      enabled: false,
      onSuccess: (response) => {
        if (!response.data.isSyncComplete) {
          setTimeout(() => refetchSyncData(), 10000);
        } else {
          showToast({
            variant: 'success',
            title: 'ATS Roles Synced',
            description:
              'You can now link roles in your ATS to Betterleap projects',
          });
        }
      },
    },
  );

  const { data: mergeRoles } = useQuery(
    ['get_merge_roles', project.id],
    () => apiClient.role.getMergeRoles({ pageSize: 1 }),
    {
      enabled: !!organization.accountToken,
      onSuccess: (roles) => {
        if (!roles?.data.length) {
          refetchSyncData();
        }
      },
    },
  );

  const roles = mergeRoles?.data || [];
  const isSynced = roles.length > 0;
  const isSyncing = roles.length === 0 && !!organization.accountToken;

  if (isSynced)
    return <LinkRoleButton project={project} onRoleLinked={onRoleLinked} />;

  if (me.ability.can('update', 'Organization', 'atsSource')) {
    return (
      <Button
        variant='secondary'
        onClick={() => open()}
        css={{ minWidth: 120 }}
        disabled={isLoading || isSyncing}
      >
        {isLoading ? <Spinner /> : isSyncing ? 'Syncing ATS' : 'Connect ATS'}
      </Button>
    );
  }

  return null;
};
