import { LoadingArea } from '@betterleap/shared';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  Text,
  TextSkeleton,
} from '@betterleap/ui';
import { useMemo, useState } from 'react';
import { SectionHeader } from './SectionHeader';

export interface SkillsSectionProps extends BoxProps {
  skills: string[];
  isLoading?: boolean;
}

const SkillsLoader = () => {
  const randomWidths = useMemo(() => {
    return new Array(15)
      .fill(undefined)
      .map(() => 50 + Math.floor(Math.random() * 50));
  }, []);

  return (
    <Flex wrap css={{ gap: 6 }}>
      {randomWidths.map((width, index) => (
        <TextSkeleton key={index} size='md' css={{ my: 2 }} style={{ width }} />
      ))}
    </Flex>
  );
};

const OverflowButton = ({
  isExpanded,
  children,
  css,
  ...props
}: ButtonProps & { isExpanded: boolean }) => {
  return (
    <Button
      variant='headless'
      css={{
        fontSize: '$sm',
        color: '$blue-600',
        display: 'inline-block',
        px: 2,
        hover: {
          textDecoration: 'underline',
        },
        ...css,
      }}
      {...props}
    >
      {isExpanded ? 'Less' : children}
    </Button>
  );
};

export const SkillsSection = ({ skills, isLoading }: SkillsSectionProps) => {
  const [showAll, setShowAll] = useState(false);
  const shownSkills = skills.slice(0, showAll ? skills.length : 20);
  const overflow = skills.slice(20);

  return (
    <Box css={{ p: 24 }}>
      <SectionHeader title='Skills' icon='lightning-bolt' css={{ mb: 16 }} />
      <Box css={{ pl: 40 }} className='highlight'>
        <LoadingArea
          isLoading={!skills.length && isLoading}
          loader={<SkillsLoader />}
        >
          <Text size='sm' css={{ color: '$text-secondary' }}>
            {shownSkills.join(' • ')}{' '}
            {overflow.length > 0 && (
              <OverflowButton
                isExpanded={showAll}
                onClick={() => setShowAll(!showAll)}
              >
                + {overflow.length} More
              </OverflowButton>
            )}
          </Text>
        </LoadingArea>
      </Box>
    </Box>
  );
};
