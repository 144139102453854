import { Flex, Input, Text } from '@betterleap/ui';
import { isValid, parse } from 'date-fns';
import { useRef } from 'react';
import { BaseStepEditorProps } from '../BaseStepEditor';
import { StepEditorValue } from '../StepEditorTypes';
import { BusinessDaySelect } from './BusinessDaySelect';
import { SendAtTip } from './SendAtTip';
import { TimeInput } from './TimeInput';

export interface DelaySelectorProps {
  stepNumber: number;
  value: StepEditorValue | undefined;
  onBlur: BaseStepEditorProps['onBlur'];
  onChange: (value: StepEditorValue) => void;
  canEditSendAfterTime?: boolean;
  prefix?: string;
  error?: BaseStepEditorProps['error'];
}

export const DelaySelector = ({
  stepNumber,
  value,
  onBlur,
  canEditSendAfterTime = true,
  prefix = 'Send at',
  onChange,
  error,
}: DelaySelectorProps) => {
  const lastSendAtTimeRef = useRef('8:30 AM');

  const handleDelayChange = (newValue: string) => {
    onChange({
      ...value,
      waitTimeCalendarDays: parseInt(newValue, 10),
    });
  };

  const handleTimeChange = (newValue: string) => {
    const parsedDate = parse(newValue ?? '', 'hh:mm a', new Date());

    if (isValid(parsedDate)) {
      lastSendAtTimeRef.current = newValue;
    }

    onChange({
      ...value,
      sendAfterTime: newValue,
    });
  };

  const handleWaitBusinessDayChange = (newValue: string) => {
    onChange({
      ...value,
      waitBusinessDaysOnly: newValue === 'true',
    });
  };

  return (
    <Flex css={{ gap: 8 }}>
      <Text
        noBreak
        css={{
          fontSize: '$sm',
          fontWeight: '$medium',
          color: '$text-light',
        }}
      >
        {prefix}
      </Text>
      {canEditSendAfterTime && (
        <TimeInput
          id={`delay-${stepNumber}`}
          error={error?.sendAfterTime?.message}
          value={value?.sendAfterTime ?? ''}
          onChange={handleTimeChange}
          onBlur={onBlur}
        />
      )}
      <Input
        aria-label='Enter Step Delay in days'
        id={`delay-${stepNumber}`}
        css={{
          '& input': {
            width: 35,
            '-webkit-appearance': 'none',
            margin: 0,
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
          },
        }}
        placeholder='5'
        min={1}
        type='number'
        error={error?.waitTimeCalendarDays?.message}
        onChange={handleDelayChange}
        value={value?.waitTimeCalendarDays}
        onBlur={onBlur}
        rightIcon={
          <BusinessDaySelect
            value={(!!value?.waitBusinessDaysOnly).toString()}
            onChange={handleWaitBusinessDayChange}
          />
        }
      />
      <Text
        noBreak
        css={{
          fontSize: '$sm',
          fontWeight: '$medium',
          color: '$text-light',
        }}
      >
        after previous step
      </Text>
      {value?.sendAfterTime && <SendAtTip css={{ ml: 8 }} />}
    </Flex>
  );
};
