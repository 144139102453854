import { ExtendedContactProject } from '@betterleap/client';
import {
  Box,
  CheckboxGroup,
  CheckboxMenuItem,
  Flex,
  Text,
  Tooltip,
} from '@betterleap/ui';
import { useProjectContactsFilterParams } from 'components/templates/Projects/useProjectContactsFilterParams';
import { format } from 'date-fns';
import { sortDateDescending } from 'functions/formatDateRange';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HeaderWithFilter } from './HeaderWithFilter';

export const OpensColumn = () => ({
  Header: () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { openedEmail } = useProjectContactsFilterParams();
    const [value, setValue] = useState<string[]>(
      openedEmail !== undefined
        ? [openedEmail === 'true' ? 'true' : 'false']
        : [],
    );

    const handleChange = (values: string[]) => {
      setValue(values);

      // remove param if there is nothing selected or both are selected which cancel each other out
      if (!values.length || values.length === 2) {
        searchParams.delete('opened');
        setSearchParams(searchParams);
      } else {
        searchParams.set('opened', values.includes('true') ? 'true' : 'false');
        setSearchParams(searchParams);
      }
    };

    return (
      <HeaderWithFilter header='opens' active={value.length > 0}>
        <CheckboxGroup onChange={handleChange} value={value}>
          <CheckboxMenuItem value='false'>Not opened</CheckboxMenuItem>
          <CheckboxMenuItem value='true'>Opened</CheckboxMenuItem>
        </CheckboxGroup>
      </HeaderWithFilter>
    );
  },
  id: 'email-opens',
  width: 75,
  headerCss: {
    maxWidth: 100,
  },
  css: {
    '& p': {
      color: '$neutral-blue-500',
    },
    maxWidth: 100,
  },
  accessor: (contact: ExtendedContactProject) => {
    if (!contact.emailOpenedAt?.length) {
      return renderOpenCount(contact.emailOpenedAt?.length);
    }
    return (
      <Tooltip content={renderOpenDates(contact.emailOpenedAt)}>
        {renderOpenCount(contact.emailOpenedAt.length)}
      </Tooltip>
    );
  },
  slot: 'custom',
});

function renderOpenCount(count: number | undefined) {
  return (
    <Flex css={{ height: 48, width: 48, placeItems: 'flex-start' }}>
      <Text
        noBreak
        css={{
          fontSize: '$sm',
          fontWeight: '$medium',
          width: '100%',
          pl: 8,
          alignSelf: 'center',
        }}
      >
        {count || '--'}
      </Text>
    </Flex>
  );
}

function renderOpenDates(emailOpenedAt?: string[]) {
  const dates = (emailOpenedAt ?? []).map((d) => new Date(d));
  const sorted = dates.sort((a, b) => sortDateDescending(a, b));
  return (
    <Box>
      {sorted.map(renderDate).slice(0, 10)}
      {sorted.length > 10 && (
        <Text css={{ color: 'white', fontSize: '$sm' }}>
          ... and {sorted.length - 10} more
        </Text>
      )}
    </Box>
  );
}

function renderDate(date: Date) {
  return (
    <Text css={{ color: 'white', fontSize: '$sm' }}>
      {format(date, 'E MMM d, h:mm a')}
    </Text>
  );
}
