import { EnrichmentEducation } from '@betterleap/client';
import { Flex, Text } from '@betterleap/ui';
import { SourcingResultSection } from './SourcingResult.elements';

interface SectionProps {
  education: EnrichmentEducation[];
  markAsViewed: () => void;
}
interface EntryProps {
  entry: EnrichmentEducation;
}

export const SourcingResultEducation = ({
  education,
  markAsViewed,
}: SectionProps) => (
  <SourcingResultSection
    title='Education'
    icon='library'
    elements={education}
    onExpanded={markAsViewed}
    constructElement={(e) => <EducationEntry entry={e} />}
  />
);

const EducationEntry = ({ entry }: EntryProps) => (
  <Flex align='start' key={JSON.stringify(entry)} css={{ gap: 12 }}>
    {!!entry.major && (
      <Text css={{ fontSize: '$sm', maxWidth: 600 }}>{entry.major}</Text>
    )}
    {!!entry.schoolName && (
      <Text css={{ fontSize: '$sm', color: '$neutral-blue-700' }}>
        {entry.schoolName}
      </Text>
    )}
    {!!entry.endDate && (
      <>
        <Text css={{ color: '$neutral-blue-700' }}>•</Text>
        <Text css={{ fontSize: '$sm', color: '$neutral-blue-700' }}>
          {new Date(entry.endDate).getFullYear()}
        </Text>
      </>
    )}
  </Flex>
);
