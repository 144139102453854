import { apiClient } from '../../../lib/apiClient';
import { ChartCard } from './ChartCard';

export const TalentPoolChartCard = () => {
  return (
    <ChartCard
      title='Talent Pool'
      icon='users'
      fetchMetrics={(r) => apiClient.contact.getContactMetrics(r)}
      fetchCount={() => apiClient.contact.countAll()}
    />
  );
};
