import { EnrichmentEducation } from '@betterleap/client';
import { Flex, Text } from '@betterleap/ui';
import ContactProfileEducationItem from './ContactProfileEducationItem.template';

export interface ContactProfileEducationProps {
  educationHistory: EnrichmentEducation[];
}

const ContactProfileEducation = (props: ContactProfileEducationProps) => {
  const { educationHistory } = props;

  return (
    <Flex vertical css={{ gap: 20, height: '100%', width: '100%' }}>
      <Text as='h3' css={{ fontWeight: '$medium', fontSize: '$lg' }}>
        {educationHistory.length ? 'Education' : 'No Education Found'}
      </Text>
      <Flex css={{ position: 'relative', height: '100%', width: '100%' }}>
        <Flex
          vertical
          css={{
            gap: 20,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflowY: 'auto',
          }}
        >
          {educationHistory.map((exp, i) => (
            <ContactProfileEducationItem
              key={`cpei_${i}`}
              educationData={exp}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ContactProfileEducation;
