import { Box, BoxProps, IconButton, Spinner } from '@betterleap/ui';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { CancelablePromise } from '@betterleap/client';

interface FileAttachmentProps extends BoxProps {
  attachment: { name: string };
  onDeleteClick: () => CancelablePromise<unknown>;
  onOpenClick: () => void;
  afterDelete: () => void;
  canEdit?: boolean;
}

export const FileAttachment = ({
  attachment,
  onDeleteClick,
  onOpenClick,
  afterDelete,
  css,
  canEdit,
  ...rest
}: FileAttachmentProps) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const deleteAttachment = useMutation(onDeleteClick, {
    onSuccess: () => {
      afterDelete();
      setIsDeleting(false);
    },
  });
  return (
    <Box
      css={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 4,
        backgroundColor: '$neutral-blue-200',
        borderRadius: 4,
        p: '2px 4px 2px 8px',
        fontSize: '$sm',
        color: '$neutral-blue-700',
        maxWidth: '100%',
        minHeight: 28,
        ...css,
      }}
      {...rest}
    >
      <Box
        css={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
        }}
        onClick={onOpenClick}
      >
        {attachment.name}
      </Box>
      {isDeleting ? (
        <Spinner css={{ height: 12 }} />
      ) : (
        canEdit !== false && (
          <IconButton
            label='Delete Attachment'
            name='trash'
            size='sm'
            color='$neutral-blue-700'
            css={{ cursor: 'pointer', height: 24, width: 24 }}
            onClick={() => {
              setIsDeleting(true);
              deleteAttachment.mutate();
            }}
          />
        )
      )}
    </Box>
  );
};
