export const setArrayUrlParam = (
  params: URLSearchParams,
  param: string,
  values: string[],
) => {
  params.delete(param);

  values.forEach((value) => {
    params.append(param, value);
  });

  return params;
};
