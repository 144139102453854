import Mark from 'mark.js';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { BetterleapPersonSearchResponse } from '@betterleap/client';
import { SourceCandidatesSearchParams } from './SourceCandidates.types';
import { getInclusions } from './SourceCandidates.functions';

export type Highlights = {
  jobTitles: string[];
  companyNames: string[];
  educationSchools: string[];
  educationMajors: string[];
  skills: string[];
  languageNames: string[];
  certifications: string[];
};

export const highlightsAtom = atom<Highlights>({
  jobTitles: [],
  companyNames: [],
  educationSchools: [],
  educationMajors: [],
  skills: [],
  languageNames: [],
  certifications: [],
});

interface SetHighlightProps {
  filters: SourceCandidatesSearchParams;
  results?: BetterleapPersonSearchResponse;
}

export const useSetHighlights = ({ filters, results }: SetHighlightProps) => {
  const setHighlights = useSetAtom(highlightsAtom);
  useEffect(() => {
    setHighlights(constructHighlights({ filters, results }));
  }, [filters, results]);
};

function constructHighlights({ filters, results }: SetHighlightProps) {
  const highlights: Highlights = {
    jobTitles: [
      ...(filters.jobTitlesCurrent || []),
      ...(filters.jobTitlesPast || []),
      ...(filters.jobTitlesCurrentOrPast || []),
      ...(results?.expandedjobTitlesCurrent || []),
      ...(results?.expandedJobTitlesPast || []),
      ...(results?.expandedJobTitlesCurrentOrPast || []),
      ...(filters.summaryKeywords || []),
    ],
    companyNames: [
      ...(filters.companyNamesCurrentOrPast || []),
      ...(filters.companyNamesCurrent || []),
      ...(filters.companyNamesPast || []),
    ],
    educationSchools: [
      ...(filters.educationSchools || []),
      ...(filters.summaryKeywords || []),
    ],
    educationMajors: [
      ...(filters.educationMajors || []),
      ...(filters.summaryKeywords || []),
    ],
    skills: [
      ...(filters.skillsShould || []),
      ...(filters.summaryKeywords || []),
      ...getInclusions(filters.skills || []),
    ],
    languageNames: filters.languageNames || [],
    certifications: filters.certifications || [],
  };
  return highlights;
}

export const useHighlightSearchTerms = () => {
  const [highlights] = useAtom(highlightsAtom);

  const highlightedValues = Object.values(highlights).flat();
  const highlightedTerms = highlightedValues.filter(Boolean);

  return () => {
    const markInstance = new Mark('.highlight');
    markInstance.unmark({
      done: function () {
        markInstance.mark(highlightedTerms, {
          separateWordSearch: false,
        });
      },
    });
  };
};
