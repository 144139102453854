import {
  sequenceScheduleToValue,
  Spinner,
  StepEditor,
} from '@betterleap/shared';
import {
  BackButton,
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  InputField,
  Label,
  Text,
} from '@betterleap/ui';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import SequenceStatistics from 'components/elements/SequenceStatistics/SequenceStatistics';
import routeNames from '../../../constants/routeNames';
import { apiClient } from '../../../lib/apiClient';
import { selectOrganization } from '../../../store/api/selectors';
import { Tip } from '../../elements/Tip/Tip';
import { ReadOnlyScheduleSelector } from '../Sequences/SequenceScheduleSelector';
import { PersonalizeSequenceAlert } from '../Sequences/PersonalizeSequenceAlert';
import { useGetSequenceWithOverrides } from '../Sequences/useGetSequenceWithOverrides';
import { usePauseSequence } from './usePauseSequence';
import { PausedSequenceBanner } from './PausedSequenceBanner';

const SequenceDetail = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const organization = useSelector(selectOrganization);
  const contactSequenceId = searchParams.get('contactSequenceId') ?? '';

  const { data: sequenceMetricResponse, refetch: refetchMetrics } = useQuery(
    ['get_sequence_metrics', id],
    () => apiClient.sequence.getSequenceMetrics({ sequenceId: id as string }),
  );

  const {
    data = [],
    isLoading,
    refetch: refetchSequence,
    error,
  } = useGetSequenceWithOverrides({});

  const [sequence, contactSequence] = data;

  const {
    data: senderResponse,
    isLoading: isSenderLoading,
    error: senderError,
  } = useQuery(
    ['get_sender', sequence?.sender?.id],
    () =>
      apiClient.sender.getSenderById({
        senderId: sequence?.sender?.id as string,
      }),
    { enabled: !!sequence?.sender?.id },
  );

  const { onToggleSequence } = usePauseSequence({
    sequence,
    onSuccess: () => {
      refetchSequence();
      refetchMetrics();
    },
  });

  if (isLoading || isSenderLoading) {
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }
  if (error ?? senderError) {
    throw error ?? senderError;
  }

  if (!sequence || !sequence.project) {
    return <Navigate to={routeNames.projects()} />;
  }

  const sender = senderResponse?.data;

  return (
    <Box
      css={{
        p: 16,
        pb: 70,
        mediaLg: {
          p: 0,
          pb: 70,
        },
      }}
    >
      <BackButton
        route={sequence.project.name}
        onClick={() => navigate(routeNames.project({ id: sequence.projectId }))}
      />
      <Flex justify='between' align='center' css={{ mb: 24 }}>
        <Text
          as='h1'
          css={{
            fontSize: '$3xl',
            lineHeight: '$4xl',
            fontWeight: '$medium',
            color: '$neutral-blue-900',
          }}
        >
          {sequence.name}
        </Text>
        <Flex css={{ gap: 8 }}>
          {organization.data?.id !== sequence.project.organizationId && (
            <Flex>
              <Icon name='eye' color='$blue-600' size={16} />
              <Text
                css={{ ml: 8, color: '$blue-600', fontSize: '$sm', mr: 16 }}
              >
                View Only
              </Text>
            </Flex>
          )}
          {organization.data?.id === sequence.project.organizationId && (
            <Button
              variant='secondary'
              onClick={onToggleSequence}
              disabled={!sequence.sender}
            >
              {sequence.enabled ? 'Pause' : 'Resume'} Sequence
            </Button>
          )}
          <Button
            dataCy='Edit sequence-button'
            disabled={
              organization.data?.id !== sequence.project.organizationId ||
              !sequence.sender
            }
            variant='primary'
            onClick={() => {
              if (id)
                navigate(
                  routeNames.editSequence({
                    id,
                    contactSequenceId: contactSequenceId,
                  }),
                );
            }}
          >
            Edit Sequence
          </Button>
        </Flex>
      </Flex>
      {contactSequence && (
        <PersonalizeSequenceAlert
          sequenceId={id as string}
          contactSequence={contactSequence}
          title="You're viewing a personalized sequence"
        />
      )}
      <PausedSequenceBanner
        sequence={sequence}
        senderName={sequenceMetricResponse?.data?.sender as string}
      />
      <SequenceStatistics sequenceStats={sequenceMetricResponse?.data} />
      <Flex
        justify='between'
        css={{
          mb: 16,
          backgroundColor: 'white',
          boxShadow: '$base',
          borderRadius: '$lg',
          padding: 20,
          columnGap: 24,
          alignItems: 'flex-start',
        }}
        wrap
      >
        <Box
          css={{
            flexBasis: '100%',
            mediaSm: { flexBasis: '40%' },
          }}
        >
          <InputField
            dataCy='Sequence title-field'
            label='Sequence Title'
            value={sequence.name}
            readOnly
            disabled
          />
        </Box>
        <Flex
          css={{
            flexGrow: 1,
          }}
        >
          <InputField
            dataCy='From-field'
            label='From'
            value={sequence?.sender?.email}
            leftIcon={<Icon name='mail' css={{ ml: 4, mr: 4 }} />}
            css={{ flexGrow: 1 }}
            readOnly
            disabled
          />
        </Flex>
        <Flex vertical css={{ mb: 12 }}>
          <Label css={{ mb: 8 }}>Use Default Signature</Label>
          <Flex css={{ height: 36 }}>
            <Checkbox checked={sequence.useDefaultSignature} disabled>
              Include your signature in emails
            </Checkbox>
          </Flex>
        </Flex>
        <Flex css={{ flexBasis: '100%' }}>
          <ReadOnlyScheduleSelector
            value={sequenceScheduleToValue(sequence.sequenceSchedule)}
            label='Send On:'
          />
          <Flex css={{ ml: 32, mt: 28 }}>
            <Checkbox checked={sequence.sendOnHolidays} disabled>
              Send on holidays
            </Checkbox>
            <Tip
              css={{ marginLeft: 8 }}
              content='Emails will send on US federal holidays.'
            />
          </Flex>
        </Flex>
      </Flex>
      {sequence.steps.map((step, index) => (
        <Box key={step.id + contactSequenceId} css={{ mb: 40 }}>
          <StepEditor
            readOnly
            stepNumber={index + 1}
            type={step.type}
            value={{
              ...step,
              from: sequence?.sender?.email,
            }}
            signature={sender?.signature}
            useDefaultSignature={sequence.useDefaultSignature}
          />
        </Box>
      ))}
    </Box>
  );
};

export default SequenceDetail;
