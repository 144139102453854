/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AlertIcon,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  TextArea,
} from '@betterleap/ui';
import React, { useState } from 'react';
import { FeedbackSentiment } from './AnalyticsCopilotTab';

interface FeedbackModalProps {
  sentiment: FeedbackSentiment;
  handleSubmitFeedback: (feedback: string) => void;
  onDismiss: () => any;
  onCancel: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  handleSubmitFeedback,
  onDismiss,
  onCancel,
  sentiment,
}) => {
  const [feedback, setFeedback] = useState<string>('');

  return (
    <Modal size='xxl' autoFocus={false} css={{ p: '32px 24px 0px 24px' }}>
      <ModalHeader>
        <Flex css={{ gap: 16, mb: 10 }}>
          <AlertIcon name='template' size='lg' shape='rounded-square' />
          <ModalTitle size='lg' css={{ fontWeight: '$medium' }}>
            Provide Feedback
          </ModalTitle>
        </Flex>
      </ModalHeader>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitFeedback(feedback);
          onDismiss();
        }}
      >
        <ModalBody css={{ padding: 4 }}>
          <TextArea
            autoFocus
            value={feedback}
            placeholder={
              sentiment === FeedbackSentiment.POSITIVE
                ? `We're glad you like the response, what did you like about it?`
                : `We're sorry to hear the response wasn't what you were looking for. This is an experimental tool and we're continually working to improve it. Please tell us more about what you didn't like about the repsonse.`
            }
            css={{
              border: '1px solid $neutral-blue-200',
              width: '100%',
              borderRadius: 16,
              padding: 16,
              paddingRight: 48,
              alignItems: 'start',
              resize: 'none',
              outlineColor: '$neutral-blue-200',
            }}
            onChange={(value: unknown) => setFeedback(value as string)}
          />
        </ModalBody>
        <ModalFooter>
          <Flex
            justify='end'
            css={{
              borderTop: '1px solid $neutral-blue-300',
              py: 16,
              mx: -24,
              px: 24,
              gap: 10,
            }}
          >
            <Button
              variant='gray'
              css={{ minWidth: 100 }}
              onClick={() => {
                onCancel();
                onDismiss();
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' css={{ minWidth: 100 }} type='submit'>
              Submit Feedback
            </Button>{' '}
          </Flex>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default FeedbackModal;
