import { CancelablePromise, CountDto, DataPoint } from '@betterleap/client';
import {
  AlertIcon,
  AreaChart,
  areaChartOptions,
  Box,
  Divider,
  Flex,
  Spinner,
  styleAreaChartData,
  Text,
} from '@betterleap/ui';
import { Card } from 'components/elements/Card/Card';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getMonthLabel } from './Reporting.functions';
import { TimeInterval } from './Reporting.types';

type MetricsRequest = { interval?: 'month' | 'quarter' | undefined };
type MetricsCallback = (r: MetricsRequest) => CancelablePromise<DataPoint[]>;

interface ChartCardProps {
  title: string;
  icon: 'save' | 'users' | 'paper-airplane';
  fetchMetrics: MetricsCallback;
  fetchCount: () => CancelablePromise<CountDto>;
}

export const ChartCard = ({
  title,
  icon,
  fetchMetrics,
  fetchCount,
}: ChartCardProps) => {
  const [interval] = useState<TimeInterval>(TimeInterval.MONTH);

  const { data: metricsData, isLoading: isMetricsLoading } = useQuery(
    [`${title}_metrics`, { interval }],
    () => fetchMetrics({ interval }),
  );
  const { data: countData, isLoading: isCountLoading } = useQuery(
    [`${title}_count`],
    () => fetchCount(),
  );

  const metrics = metricsData?.data ?? [];
  const count = countData?.data.count;

  return (
    <Card
      vertical
      css={{
        height: 350,
      }}
    >
      <Flex>
        <Text css={{ flex: 1, fontWeight: '$medium', fontSize: 18 }}>
          {title}
        </Text>
      </Flex>
      <Box css={{ position: 'relative', flex: 1, mb: 16 }}>
        {isMetricsLoading ? (
          <Flex justify='center' css={{ width: '100%', height: '100%' }}>
            <Spinner variant='blue' />
          </Flex>
        ) : (
          <AreaChart
            options={areaChartOptions}
            data={styleAreaChartData(
              metrics.map((datum) => ({
                label: getMonthLabel(datum.timestamp),
                value: datum.value,
              })),
            )}
          />
        )}
      </Box>
      <Divider css={{ backgroundColor: '$neutral-blue-200' }} />
      <Flex css={{ gap: 20, mt: 18 }}>
        <AlertIcon
          variant='info'
          size='lg'
          name={icon}
          shape='rounded-square'
        />
        <Box>
          <Text css={{ fontSize: '$xs', color: '$neutral-blue-700' }}>
            {title}
          </Text>
          {isCountLoading ? (
            <Spinner variant='blue' />
          ) : (
            <Text css={{ fontSize: '$xl' }}>{count?.toLocaleString()}</Text>
          )}
        </Box>
      </Flex>
    </Card>
  );
};
