import { TOKEN } from '@betterleap/shared';
import { useModal } from '@betterleap/ui';
import { PrivacyPolicyModal } from 'components/modules/Modal/PrivacyPolicyUpdatedModal/PrivacyPolicyModal';
import { TermsOfServiceModal } from 'components/modules/Modal/PrivacyPolicyUpdatedModal/TermsOfServiceModal';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectMe } from 'store/api/selectors';

interface Submittable {
  onSubmit: () => void;
}

export const useAcceptTerms = () => {
  const openTermsOfServiceModal = useModal<Submittable>(TermsOfServiceModal);
  const openPrivacyModal = useModal<Submittable>(PrivacyPolicyModal);

  const location = useLocation();
  const me = useSelector(selectMe);

  const acceptTerms = () => {
    const pagePath = location.pathname.split('/')[1];
    const isSignInAsAnotherUser = TOKEN.isSessionStorage();
    if (pagePath === 'signin' || isSignInAsAnotherUser) {
      return;
    }
    acceptTermsOfService();
  };

  function acceptTermsOfService() {
    if (me.data && !me.data.acceptedTermsOfServiceDate) {
      openTermsOfServiceModal({ onSubmit: acceptPrivacyPolicy });
    } else {
      acceptPrivacyPolicy();
    }
  }

  function acceptPrivacyPolicy() {
    if (me.data && !me.data.acceptedPrivacyPolicyDate) {
      openPrivacyModal();
    }
  }

  return { acceptTerms };
};
