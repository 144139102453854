import { useControlledState } from '@betterleap/ui';
import { BaseStepEditor, BaseStepEditorProps } from './BaseStepEditor';
import { StepEditorValue } from './StepEditorTypes';
import { ScheduleTimeManager } from './components/ScheduleTimeManager';

export const PhoneCallStepEditor = ({
  value,
  defaultValue,
  stepNumber,
  onChange,
  error,
  readOnly,
  canEditDelay = true,
  onBlur,
  ...props
}: BaseStepEditorProps) => {
  const [stateValue, setStateValue] = useControlledState(
    value,
    defaultValue,
    onChange,
  );

  const handleOnChange = (newValue: StepEditorValue) => {
    setStateValue({
      ...newValue,
    });
  };

  return (
    <BaseStepEditor
      value={stateValue}
      stepNumber={stepNumber}
      onBlur={onBlur}
      readOnly={readOnly}
      error={error}
      canUseVariables={false}
      headerComponent={
        <ScheduleTimeManager
          defaultSendStrategy='send-at'
          onChange={handleOnChange}
          onBlur={onBlur}
          readOnly={readOnly}
          canEditDelay={canEditDelay}
          canEditSendAfterTime={false}
          prefix='In'
          value={stateValue}
          error={error}
          stepNumber={stepNumber}
        />
      }
      onChange={handleOnChange}
      {...props}
    />
  );
};
