import { Box } from '@betterleap/ui';

const Line = () => (
  <Box
    css={{
      height: 1,
      width: '100%',
      mt: 24,
      mb: 24,
      backgroundColor: '$neutral-blue-200',
    }}
  />
);

export default Line;
