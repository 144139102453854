import { NotesColumn } from 'components/elements/CandidatesTable/NotesColumn';
import { OpensColumn } from 'components/elements/CandidatesTable/OpensColumn';
import { SequenceStatusColumn } from 'components/elements/CandidatesTable/SequenceStatusColumn';
import { MostRecentActivityColumn } from 'components/elements/CandidatesTable/MostRecentActivityColumn';
import { CompanyColumn } from 'components/elements/CandidatesTable/CompanyColumn';
import { NameColumn } from 'components/elements/CandidatesTable/NameColumn';
import {
  ApiResponse,
  ExtendedContactProject,
  ProjectWithStatsDto,
} from '@betterleap/client';
import { useMemo } from 'react';
import { Overlay } from 'components/elements/Overlay/Overlay';
import { Flex, Spinner } from '@betterleap/ui';
import {
  InteractiveTable,
  PartialTableInstance,
  TableColumn,
  Types,
} from '@betterleap/shared';
import useAnalytics from 'hooks/analytics';
import { resultIdAtom } from 'components/modules/Drawer/ContactDrawer/ContactDrawer';
import { useSetAtom } from 'jotai';
import { RowsSelectedEvent } from '../ProjectContacts.types';
import {
  countSelectedContacts,
  isEnriching,
  isSalesProject,
  isViewOnly,
  TableSelection,
  useMemoizedContacts,
} from '../ProjectDetails.functions';
import { useCohortFilter } from '../Hooks/useCohortFilter';
import { NoCandidatesFoundGraphic } from './NoCandidatesFoundGraphic';
import { ProjectDetailsTableBanner } from './ProjectDetailsTableBanner';
import { useScrollToContact } from './useScrollToContact';
import { useContactNotes } from './useContactNotes';
import { useContactActionMenu } from './useContactActionMenu';

const PAGE_SIZE = 100;

interface ProjectDetailsTableProps {
  project: ProjectWithStatsDto;
  projectContacts: ApiResponse<ExtendedContactProject[]> | undefined;
  isLoading: boolean;
  resetSelection: () => void;
  refreshData: () => void;
  refreshTable: () => void;
  scrollTop: () => void;
  setTableInstance: (table: PartialTableInstance) => void;
  selectedContacts: TableSelection;
  setSelectedContacts: (selectedContacts: TableSelection) => void;
}

export const ProjectDetailsTable = ({
  project,
  projectContacts,
  isLoading,
  resetSelection,
  refreshData,
  refreshTable,
  scrollTop,
  setTableInstance,
  selectedContacts,
  setSelectedContacts,
}: ProjectDetailsTableProps) => {
  const viewOnly = isViewOnly(project);

  const { cohort } = useCohortFilter();
  const contacts = useMemoizedContacts(projectContacts?.data);

  const { onRowClick, onRowsSelected } = useRowOperations(
    selectedContacts,
    setSelectedContacts,
  );

  useScrollToContact({ isLoading });

  const { addNote } = useContactNotes({
    projectId: project.id,
    onModalClosed: refreshData,
  });

  const contactActionMenu = useContactActionMenu({
    project,
    onInMailStatusUpdated: refreshData,
    onInterestedStatusUpdated: refreshData,
    onContactEdited: refreshData,
    onCandidateSubmitted: refreshData,
    onContactRemoved: refreshTable,
  });

  const contactTableColumns = useMemo(() => {
    const contactTableColumnsArray: TableColumn[] = [
      NameColumn({ viewOnly, project }),
      CompanyColumn(),
      MostRecentActivityColumn(),
      SequenceStatusColumn({ project }),
      OpensColumn(),
      NotesColumn({ handleAddNote: addNote }),
    ];
    if (!viewOnly) {
      contactTableColumnsArray.push(contactActionMenu);
    }
    return contactTableColumnsArray;
  }, [viewOnly, cohort, project.projectRoleAutoSync]);

  const contactsCount = projectContacts?.meta?.count ?? 0;
  const selectedCount = countSelectedContacts(selectedContacts, contactsCount);

  return (
    <InteractiveTable
      pageSize={PAGE_SIZE}
      onRowSelect={!viewOnly ? onRowsSelected : undefined}
      selectedRows={selectedContacts}
      tableData={contacts}
      columns={contactTableColumns}
      loading={isLoading}
      emptyState={<TableEmptyState project={project} />}
      overlayloader={<TableOverlayLoader />}
      onRowClick={viewOnly ? undefined : onRowClick}
      onLoad={setTableInstance}
      onPageChange={() => {
        scrollTop();
        resetSelection();
      }}
      count={contactsCount}
      tableBanner={
        <ProjectDetailsTableBanner
          isEnriching={isEnriching(projectContacts?.data)}
          selectedCount={selectedCount}
          totalCount={contactsCount}
          pageSize={PAGE_SIZE}
          onSelectAll={() => setSelectedContacts('all')}
          onClearSelection={resetSelection}
        />
      }
    />
  );
};

const useRowOperations = (
  currentSelection: TableSelection,
  selectRows: (rows: TableSelection) => void,
) => {
  const { track } = useAnalytics();
  const setResultId = useSetAtom(resultIdAtom);
  const onRowClick = (_commandPressed: boolean, data: unknown) => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Open Candidate Drawer',
      buttonLocation: `Project Details`,
    });
    setResultId((data as ExtendedContactProject).id);
  };
  const onRowsSelected = ({ selectedFlatRows }: RowsSelectedEvent) => {
    // otherwise refetch will change project- to page-level selection
    if (currentSelection !== 'all' || selectedFlatRows.length < PAGE_SIZE) {
      selectRows(selectedFlatRows);
    }
  };
  return { onRowClick, onRowsSelected };
};

const TableEmptyState = ({ project }: { project: ProjectWithStatsDto }) => (
  <Flex vertical css={{ alignItems: 'center', pb: 48 }}>
    <NoCandidatesFoundGraphic isBDProject={isSalesProject(project)} />
  </Flex>
);

const TableOverlayLoader = () => (
  <>
    <Overlay
      css={{
        backgroundColor: '$primary-700',
      }}
    />
    <Flex
      align='center'
      justify='center'
      css={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        maxHeight: 350,
      }}
    >
      <Spinner />
    </Flex>
  </>
);
