import { showToast } from '@betterleap/ui';
import { useMutation, useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { Contact, ContactPhoneNumber } from '@betterleap/client';
import { getApiClient } from '../../../lib';
import ContactPhoneNumberManagerTemplate from './ContactPhoneNumberManager.template';

export interface ContactPhoneNumberManagerProps {
  contactId: string;
  phoneNumberType?: ContactPhoneNumber.type;
  onContactUpdated: (contact?: Contact) => void;
  onPhoneNumberCopied?: () => void;
  contactPrimaryPhoneNumber: string | undefined;
  contactLinkedinUrl: string | undefined;
  pollingInterval?: number;
}

const ContactPhoneNumberManager = (props: ContactPhoneNumberManagerProps) => {
  const {
    contactId,
    contactPrimaryPhoneNumber,
    onContactUpdated,
    phoneNumberType,
    onPhoneNumberCopied,
    contactLinkedinUrl,
    pollingInterval,
  } = props;
  const [contactDefaultPhoneNumber, setContactDefaultPhoneNumber] = useState<
    string | undefined
  >(contactPrimaryPhoneNumber);
  const [refetchInterval, setRefetchInterval] = useState<number | false>(
    pollingInterval || false,
  );
  const apiClient = getApiClient();

  const {
    data: contactPhoneNumbersResponse,
    isLoading,
    refetch: refetchContactPhoneNumbers,
  } = useQuery(
    ['get_contact_phone_numbers', contactId, phoneNumberType],
    () =>
      apiClient.contactPhoneNumber.find({
        contactId: contactId as string,
        type: phoneNumberType,
      }),
    {
      onSuccess: (result) => {
        const defaultPhoneNumber = result?.data?.find(
          (contactPhoneNumber) =>
            contactPhoneNumber.phoneNumber === contactDefaultPhoneNumber,
        );

        if (!defaultPhoneNumber) {
          setContactDefaultPhoneNumber(undefined);
        }
        if (refetchInterval) {
          setTimeout(() => setRefetchInterval(false), refetchInterval * 5);
        }
      },
      refetchInterval: (data) => {
        if (!data?.data.length) {
          return refetchInterval;
        }
        return false;
      },
    },
  );

  useEffect(() => {
    setContactDefaultPhoneNumber(contactPrimaryPhoneNumber);
  }, [contactPrimaryPhoneNumber]);

  const contactPhoneNumbers = contactPhoneNumbersResponse?.data ?? [];

  const createContactPhoneNumber = useMutation(
    (data: { phoneNumber: string; contactId: string }) =>
      apiClient.contactPhoneNumber.create({
        requestBody: {
          ...data,
          type:
            phoneNumberType === ContactPhoneNumber.type.WORK
              ? ContactPhoneNumber.type.WORK
              : ContactPhoneNumber.type.PERSONAL,
        },
      }),
    {
      onSuccess: () => {
        refetchContactPhoneNumbers();
        onContactUpdated();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description:
            'Failed to add phone number. This may be caused by a duplicate phone number.',
        });
      },
    },
  );

  const handleAddPhoneNumber = async (phoneNumber: string) => {
    await createContactPhoneNumber.mutateAsync({
      phoneNumber,
      contactId,
    });

    if (!contactPhoneNumbers.length) {
      // if we are creating the fist phone number, set it as the default
      await handleSetDefaultPhoneNumber(phoneNumber);
    }
  };

  const removeContactPhoneNumber = useMutation(
    (data: { id: string }) => apiClient.contactPhoneNumber.delete(data),
    {
      onSuccess: () => {
        refetchContactPhoneNumbers();
        onContactUpdated();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description:
            'Failed to remove phone number. This may be caused by a duplicate phone number.',
        });
      },
    },
  );

  const handleRemovePhoneNumber = async (
    contactPhoneNumber: ContactPhoneNumber,
  ) => {
    await removeContactPhoneNumber.mutateAsync({ id: contactPhoneNumber.id });

    if (contactPhoneNumber.phoneNumber === contactDefaultPhoneNumber) {
      const otherContactPhoneNumbers = contactPhoneNumbers.filter(
        (ce) => ce.id !== contactPhoneNumber.id,
      );

      const newPrimaryPhoneNumber = otherContactPhoneNumbers[0];

      await updateContactPrimaryPhoneNumber.mutateAsync({
        contactId,
        ...(contactPhoneNumber?.type === ContactPhoneNumber.type.PERSONAL
          ? { phoneNumber: newPrimaryPhoneNumber?.phoneNumber ?? null }
          : {
              primaryBusinessPhoneNumber:
                newPrimaryPhoneNumber?.phoneNumber ?? null,
            }),
      });
    }
  };

  const updateContactPrimaryPhoneNumber = useMutation(
    (data: {
      contactId: string;
      phoneNumber?: string | null;
      primaryBusinessPhoneNumber?: string | null;
    }) =>
      apiClient.contact.updateContact({
        contactId: data.contactId,
        requestBody: {
          phoneNumber: data.phoneNumber,
          primaryBusinessPhoneNumber: data.primaryBusinessPhoneNumber,
          linkedinUrl: contactLinkedinUrl,
        },
      }),
    {
      onSuccess: (result) => {
        onContactUpdated(result.data);
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description:
            'Failed to update default phone number. Please try again.',
        });
      },
    },
  );

  const handleSetDefaultPhoneNumber = async (phoneNumber: string) => {
    setContactDefaultPhoneNumber(phoneNumber);
    await updateContactPrimaryPhoneNumber.mutateAsync({
      ...(phoneNumberType === ContactPhoneNumber.type.PERSONAL
        ? { phoneNumber }
        : { primaryBusinessPhoneNumber: phoneNumber }),
      contactId,
    });
  };

  return (
    <ContactPhoneNumberManagerTemplate
      contactPrimaryPhoneNumber={contactDefaultPhoneNumber}
      contactLinkedinUrl={contactLinkedinUrl}
      isLoading={
        isLoading ||
        createContactPhoneNumber.isLoading ||
        removeContactPhoneNumber.isLoading
      }
      contactPhoneNumbers={contactPhoneNumbers}
      handleAddPhoneNumber={handleAddPhoneNumber}
      handleRemovePhoneNumber={handleRemovePhoneNumber}
      handleSetDefaultPhoneNumber={handleSetDefaultPhoneNumber}
      onPhoneNumberCopied={onPhoneNumberCopied}
    />
  );
};

export default ContactPhoneNumberManager;
