import { cleanLinkedInUrl, Types } from '@betterleap/shared';
import { CreateManyContactProjectsDto } from '@betterleap/client';
import { Flex, IconButton } from '@betterleap/ui';
import { useEffect, useRef } from 'react';
import { AddToProjectButton } from 'components/templates/Sourcing/AddToProjectButton';
import { ContactPreviewSearchResult } from 'components/templates/Sourcing/SourceCandidates.template';
import { useAddContactsToProject } from 'components/templates/Sourcing/useAddContactToProject';
import useAnalytics from 'hooks/analytics';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useQueryClient } from 'react-query';
import { AddRemoveButtons } from 'components/elements/CandidatesTable/AddRemoveColumn';
import { useUpdateSuggestedContact } from 'components/templates/Projects/useUpdateSuggestedContact';
import {
  activeContactSelector,
  drawerCollectionState,
  lastContactIdSelector,
  nextContactIdSelector,
  resultIdAtom,
  sharedProjectIdState,
  sourcedContactIdState,
} from './ContactDrawer';

export const ContactDrawerHeader = () => {
  const addToProjectButtonRef = useRef<HTMLButtonElement>(null);
  const { track } = useAnalytics();
  const sharedProjectId = useAtomValue(sharedProjectIdState);
  const setResultId = useSetAtom(resultIdAtom);
  const [drawerCollection, setDrawerCollection] = useAtom(
    drawerCollectionState,
  );
  const canAddToProject = !sharedProjectId;
  const queryClient = useQueryClient();
  const contactPreview = useAtomValue(activeContactSelector);
  const nextContactId = useAtomValue(nextContactIdSelector);
  const prevContactId = useAtomValue(lastContactIdSelector);
  const setSourcedContactId = useSetAtom(sourcedContactIdState);

  const isProjectCopilot = !!contactPreview?.contactId && !!contactPreview?.id;

  const addToProject = useAddContactsToProject({
    mode: contactPreview?.contactId ? 'add' : 'source',
    source: CreateManyContactProjectsDto.source.MY_TALENT_POOL,
    onSuccess: (newContacts) => {
      const newContact = newContacts[0];

      if (newContact) {
        setSourcedContactId(newContact.id);
        setDrawerCollection(
          drawerCollection.map((result) => {
            if (
              cleanLinkedInUrl(result.linkedinUrl) === newContact.linkedinUrl
            ) {
              return {
                ...result,
                contactId: newContact.id,
              };
            }

            return result;
          }),
        );
      }

      queryClient.invalidateQueries(['contact_activity']);
      queryClient.invalidateQueries(['get_contact_projects']);
    },
  });

  const handleClose = () => {
    setResultId('');
  };

  const handleNext = () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Next Contact Button',
      buttonLocation: `Contact Drawer`,
    });
    nextContactId && setResultId(nextContactId);
  };

  const handlePrev = () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Previous Contact Button',
      buttonLocation: `Contact Drawer`,
    });
    prevContactId && setResultId(prevContactId);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        handleNext();
      }

      if (event.key === 'ArrowLeft') {
        handlePrev();
      }

      if (event.ctrlKey && event.key === 's') {
        const syntheticEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
        });

        addToProjectButtonRef?.current?.dispatchEvent(syntheticEvent);
      }

      if (event.ctrlKey && event.key === 'l') {
        if (contactPreview?.linkedinUrl) {
          window.open(contactPreview.linkedinUrl, '_blank');
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleNext, handleNext]);

  const handleAddToProject = (projectIds: string[] | undefined) => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Add To Project Button',
      buttonLocation: `Contact Drawer`,
    });

    const projectId = projectIds?.[0];

    setSourcedContactId(undefined);

    if (projectId && contactPreview) {
      addToProject(projectId, [contactPreview as ContactPreviewSearchResult]);
    }
  };

  const { addSuggestedContacts, removeSuggestedContacts } =
    useUpdateSuggestedContact({
      onSuccess: () => {
        queryClient.invalidateQueries('get_project_suggested_contacts');
        if (nextContactId) {
          setResultId(nextContactId);
        } else {
          handleClose();
        }
      },
    });

  return (
    <Flex justify='between'>
      <Flex css={{ gap: 8 }}>
        <IconButton
          label='close drawer'
          size='sm'
          variant='darkGray'
          name='x'
          css={{ mr: 8 }}
          onClick={handleClose}
        />
        <IconButton
          label='previous contact'
          name='arrow-left'
          size='sm'
          variant='secondary'
          disabled={!prevContactId}
          onClick={handlePrev}
        />
        <IconButton
          label='next contact'
          name='arrow-right'
          size='sm'
          variant='secondary'
          disabled={!nextContactId}
          onClick={handleNext}
        />
      </Flex>
      {!canAddToProject ? null : isProjectCopilot ? (
        <AddRemoveButtons
          contactId={contactPreview.contactId!}
          onAdd={(contactId) => {
            addSuggestedContacts([contactId]);
          }}
          onRemove={(contactId) => {
            removeSuggestedContacts([contactId]);
          }}
        />
      ) : (
        <AddToProjectButton
          ref={addToProjectButtonRef}
          size='sm'
          modal={false}
          onChange={(ids) => {
            handleAddToProject(ids);
          }}
        />
      )}
    </Flex>
  );
};
