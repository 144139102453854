import { Box } from '@betterleap/ui';
import avoidSpam from '../../../assets/images/email-finder/avoid-spam-value-prop.png';
import avoidSpamMobile from '../../../assets/images/email-finder/avoid-spam-value-prop-mobile.png';
import connect from '../../../assets/images/email-finder/connect-value-prop.png';
import connectMobile from '../../../assets/images/email-finder/connect-value-prop-mobile.png';
import emailVetting from '../../../assets/images/email-finder/email-vetting-value-prop.png';
import emailVettingMobile from '../../../assets/images/email-finder/email-vetting-value-prop-mobile.png';
import CtaCardSection from './CtaCardSection';
import CtaSection from './CtaSection';
import Footer from './Footer';
import LandingHeader from './LandingHeader';
import ReachMoreSection from './ReachMoreSection';
import SignUpSection from './SignUpSection';
import ValuePropSection from './ValuePropSection';

const EmailFinderTemplate = (): JSX.Element => (
  <Box>
    <LandingHeader />
    <CtaSection />
    <ReachMoreSection />
    <ValuePropSection
      id='value-props'
      title={
        <>
          Rigorous
          <br /> Email Vetting
        </>
      }
      description="Betterleap doesn't just find emails; we rigorously vet them. With multiple layers of bounce detection, we ensure that the email addresses you receive are the real deal."
      image={emailVetting}
      imageMobile={emailVettingMobile}
    />
    <ValuePropSection
      css={{
        backgroundColor: '$neutral-blue-100',
      }}
      title={
        <>
          Avoid the
          <br /> Spam Folder
        </>
      }
      description="There are 30+ common pitfalls that can send emails to the spam folder, but we've got you covered."
      reverse
      image={avoidSpam}
      imageMobile={avoidSpamMobile}
    />
    <ValuePropSection
      title='Connect Authentically'
      description='No more cold, impersonal outreach. With Betterleap, you can connect with any candidate as if you actually know them, forging more meaningful professional relationships.'
      image={connect}
      imageMobile={connectMobile}
    />
    <CtaCardSection />
    <SignUpSection />
    <Footer />
  </Box>
);

export default EmailFinderTemplate;
