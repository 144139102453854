import { Box, Checkbox, Flex } from '@betterleap/ui';
import { useAtom } from 'jotai';
import { filterState } from '../SourceCandidates.template';

export const HasPersonalEmailFilter = () => {
  const [filters, setFilters] = useAtom(filterState);
  return (
    <Flex vertical>
      <Box css={{ width: '100%' }}>
        <Checkbox
          id='has-personal-email'
          name='hasPersonalEmail'
          checked={filters.hasPersonalEmail ?? false}
          onChange={(value) => {
            setFilters({
              ...filters,
              hasPersonalEmail: value,
            });
          }}
        >
          Has personal email
        </Checkbox>
      </Box>
    </Flex>
  );
};
