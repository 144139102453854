import React from 'react';
import withHeader from '../components/HOC/withHeader';

const AdminNoAccess = () => (
  <>
    <div className='flex justify-center items-center text-2xl'>
      This email is already in use as an admin email.
    </div>
  </>
);

export default withHeader(AdminNoAccess);
