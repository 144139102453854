import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Icon,
  List,
  ListBox,
  ListInput,
  Modal,
  Option,
  Spinner,
  Text,
} from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

interface LinkRoleData {
  roleId?: string;
  autoSync: boolean;
}

interface LinkRoleModalProps {
  onDismiss: () => void;
  isProjectOwner: boolean;
  onSubmit: (data: LinkRoleData) => void;
}

const LinkRole = ({
  onDismiss,
  isProjectOwner,
  onSubmit,
}: LinkRoleModalProps) => {
  const [autoSync, setAutoSync] = useState(true);
  const [role, setRole] = useState<{
    key: string;
    label: JSX.Element;
  }>({
    key: '',
    label: <></>,
  });
  const handleOnRoleSelect = (e: { key: string; label: JSX.Element }) => {
    setRole(e);
  };
  const handleSubmitButton = () => {
    onSubmit({ roleId: role.key, autoSync });
  };

  const atsMessage = isProjectOwner
    ? 'This will sync your activity in Betterleap to your ATS, so that your ATS is always up-to-date.'
    : 'When candidates from this project are submitted, they will be added to your ATS to review and track.';

  const [filter, setFilter] = useState('');
  const { data: roles, isLoading } = useQuery(['get_merge_roles', filter], () =>
    apiClient.role.getMergeRoles({ pageSize: 100, search: filter }),
  );

  const options =
    roles?.data.map((el) => ({
      key: el.id,
      label: (
        <Flex css={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <Text css={{ fontSize: '$sm' }}>{el.title}</Text>
          <Text css={{ fontSize: '$xs', color: '$text-lightest' }}>
            {el.connectedAtsJobId}
          </Text>
        </Flex>
      ),
    })) || [];

  return (
    <Modal isDismissable={false} size='medium'>
      <Flex vertical css={{ width: '100%' }}>
        <Flex
          css={{
            width: '100%',
            p: '8px 8px 0px 8px',
            alignItems: 'flex-start',
          }}
        >
          <Box
            css={{
              justifyContent: 'center',
              width: '32px',
              height: '32px',
              backgroundColor: '$cyan-100',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              mr: '16px',
            }}
          >
            {' '}
            <Icon color='$cyan-600' name='briefcase' size={14} />
          </Box>
          <Flex vertical css={{ width: '100%' }}>
            <Text
              as='h6'
              css={{
                fontWeight: '$medium',
                fontSize: '$lg',
              }}
            >
              Link an ATS role
            </Text>

            <Text
              as='p'
              css={{
                fontWeight: '$normal',
                fontSize: '$sm',
                color: '$text-lightest',
              }}
            >
              {atsMessage}
            </Text>
          </Flex>
        </Flex>
        <Flex vertical css={{ width: '100%' }}>
          <List
            value={[role.key]}
            onChange={(keys) => {
              const option = options.find((o) => o.key === keys[0]);
              if (option) {
                handleOnRoleSelect(option);
              }
            }}
            options={options}
            shouldFilter={false}
          >
            <Box css={{ p: 16 }}>
              <ListInput
                autoFocus
                placeholder='Search for a role'
                onChange={(f) => setFilter(f)}
              />
            </Box>
            <Divider css={{ backgroundColor: '$neutral-blue-300' }} />
            <Box css={{ height: 230 }}>
              {isLoading && (
                <Flex css={{ p: 16, justifyContent: 'center' }}>
                  <Spinner />
                </Flex>
              )}
              {!isLoading && !(options as []).length && (
                <Text css={{ p: 16 }}>No roles available</Text>
              )}
              <ListBox css={{ p: 8, maxHeight: '100%' }} aria-label='role list'>
                {(option) => (
                  <Option css={{ fontSize: '$sm' }}>{option.label}</Option>
                )}
              </ListBox>
            </Box>
          </List>
          {isProjectOwner && (
            <Alert css={{ mt: 16 }}>
              <Checkbox
                checked={autoSync}
                onChange={setAutoSync}
                css={{ color: '$primary-600', fontWeight: '$medium' }}
              >
                Automatically sync candidates in this project with your ATS
              </Checkbox>
            </Alert>
          )}
        </Flex>
        <Flex
          css={{
            gap: 8,
            mt: '24px',
            justifyContent: 'center',
          }}
        >
          <Button
            variant='ghost'
            onClick={onDismiss}
            css={{
              width: 146,
              borderRadius: '12px',
              borderColor: '#F0F1F3',
              backgroundColor: '#F0F1F3',
              color: '#4F5762',
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!role.key}
            css={{ width: 146, borderRadius: '12px' }}
            onClick={handleSubmitButton}
          >
            Link Role
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
export default LinkRole;
