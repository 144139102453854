import routeNames from 'constants/routeNames';
import { getFullName } from '@betterleap/shared';
import {
  ExtendedContactProject,
  Project,
  ProjectWithStatsDto,
} from '@betterleap/client';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  CheckboxGroup,
  CheckboxMenuItem,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from '@betterleap/ui';
import { useState } from 'react';
import { useProjectContactsFilterParams } from 'components/templates/Projects/useProjectContactsFilterParams';
import { setArrayUrlParam } from 'helper/setArrayUrlParam';
import { HeaderWithFilter } from './HeaderWithFilter';
import { ContactProfileLinks } from './Elements/ContactProfileLinks';

interface Props {
  viewOnly?: boolean;
  project: ProjectWithStatsDto;
}

export const NameColumn = ({ viewOnly, project }: Props) => {
  function constructName(contact: ExtendedContactProject): JSX.Element {
    const name = getFullName(contact, '--');
    return <Text css={{ fontSize: '$sm', fontWeight: '$medium' }}>{name}</Text>;
  }

  return {
    id: 'name',
    Header: () => {
      const [searchParams, setSearchParams] = useSearchParams();
      const { statuses } = useProjectContactsFilterParams();
      const [statusesValue, setStatusValue] = useState<string[]>(
        statuses as string[],
      );

      const handleChange = (values: string[]) => {
        setStatusValue(values);
        setSearchParams(setArrayUrlParam(searchParams, 'status', values));
      };

      return (
        <HeaderWithFilter header='name' active={statusesValue.length > 0}>
          <CheckboxGroup onChange={handleChange} value={statusesValue}>
            <CheckboxMenuItem value='no personal email'>
              No Personal Email
            </CheckboxMenuItem>
          </CheckboxGroup>
        </HeaderWithFilter>
      );
    },
    accessor: (contact: ExtendedContactProject) => {
      const projectType = project.contactEmailEnrichmentMode;

      const emails = contact.emails.map((e) => e.email);
      const phone = getPhoneNumber(contact, projectType);

      const hasContactInfo = !!(emails.length || phone);
      const hasSocialMedia = !!(
        contact.linkedinUrl ||
        contact.twitterUrl ||
        contact.githubUrl ||
        contact.facebookUrl
      );
      const hasAtsSyncError =
        !contact.isSyncedWithAts && contact.syncWithAtsRoleError;
      const hasPendingSync =
        (contact.atsAutoSync || project?.projectRoleAutoSync) &&
        !contact.isSyncedWithAts;

      return (
        <>
          <Flex css={{ gap: 4 }} id={contact.id}>
            {constructName(contact)}
            {(contact.stepOverrideCount > 0 ||
              contact.attachmentOverrideCount > 0) && (
              <ViewPersonalizedSequenceButton contact={contact} />
            )}
            {contact.otherActiveSequenceCount > 0 && (
              <Tooltip
                content={`Contact active in ${
                  contact.otherActiveSequenceCount
                } other sequence${
                  contact.otherActiveSequenceCount > 1 ? 's' : ''
                }`}
              >
                <IconButton
                  label='Multiple active sequences'
                  name='exclamation'
                  size='xs'
                  color='$red-600'
                />
              </Tooltip>
            )}
            {contact.contactSequenceDeactivatedReason ===
              ExtendedContactProject.contactSequenceDeactivatedReason
                .PAUSED && (
              <Tooltip content='Sequence paused'>
                <Icon
                  name='pause'
                  size={16}
                  color='$neutral-blue-600'
                  css={{ stroke: '$neutral-blue-300' }}
                />
              </Tooltip>
            )}
            {contact.isInCompanyAts && !contact.candidateSubmittedAt && (
              <Tooltip content='Candidate already in company’s ATS'>
                <Icon
                  name='full-circle-check'
                  size={16}
                  color='$neutral-blue-600'
                  css={{ stroke: '$neutral-blue-300' }}
                />
              </Tooltip>
            )}
            {!viewOnly && hasPendingSync && (
              <Tooltip
                content={
                  hasAtsSyncError
                    ? 'Candidate was unable to sync to ATS'
                    : 'Candidate queued to sync to ATS'
                }
              >
                <Icon
                  name='history'
                  size={16}
                  color={hasAtsSyncError ? '$danger-700' : undefined}
                />
              </Tooltip>
            )}
            {!viewOnly && contact.isSyncedWithAts && (
              <Tooltip content='Candidate synced to your ATS'>
                <Icon color='$success-700' name='sync-complete' size={16} />
              </Tooltip>
            )}
            {!!contact.candidateSubmittedAt && (
              <Tooltip
                content={`Candidate submitted on ${moment(
                  contact.candidateSubmittedAt,
                ).format('ll')}`}
              >
                <Icon
                  name='full-circle-check'
                  size={16}
                  color='$blue-600'
                  stroke='White'
                  css={{ stroke: 'White' }}
                />
              </Tooltip>
            )}
          </Flex>
          {(hasContactInfo || hasSocialMedia) && (
            <Flex css={{ gap: 8, mt: 4 }}>
              {contactIcon('mail', 16, emails)}
              {contactIcon('phone', 14, phone ? [phone] : [])}
              {hasContactInfo && hasSocialMedia && (
                <Text css={{ color: '$neutral-blue-300' }}>|</Text>
              )}
              <ContactProfileLinks contact={contact} />
            </Flex>
          )}
        </>
      );
    },
    slot: 'custom',
  };
};

const ViewPersonalizedSequenceButton = ({
  contact,
}: {
  contact: ExtendedContactProject;
}) => {
  const navigate = useNavigate();
  const handlePersonalizeSequenceClick: React.MouseEventHandler<
    HTMLButtonElement
  > = (e) => {
    e.stopPropagation();
    if (contact.sequenceId && contact.contactSequenceId) {
      navigate(
        routeNames.sequenceDetail({
          id: contact.sequenceId,
          contactSequenceId: contact.contactSequenceId,
        }),
      );
    }
  };
  return (
    <Tooltip
      onClick={handlePersonalizeSequenceClick}
      content={`View personalized sequence`}
    >
      <IconButton
        label='View personalized sequence'
        name='flag'
        size='xs'
        color='$orange-600'
      />
    </Tooltip>
  );
};

type EnrichmentMode = Project.contactEmailEnrichmentMode;

function getPhoneNumber(c: ExtendedContactProject, m?: EnrichmentMode) {
  const isSales = m === Project.contactEmailEnrichmentMode.PROFESSIONAL;
  const phone = isSales ? c.primaryBusinessPhoneNumber : c.phoneNumber;
  return phone ?? c.phoneNumber;
}

function contactIcon(icon: 'mail' | 'phone', size: number, value: string[]) {
  return !value.length ? null : (
    <Tooltip content={formatTooltipList(value)}>
      <Icon
        name={icon}
        color='$neutral-blue-500'
        size={size}
        css={{
          cursor: 'pointer',
          hover: { fill: '$neutral-blue-700 !important' },
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </Tooltip>
  );
}

function formatTooltipList(value: string[]) {
  return (
    <Box>
      {value.map((v) => (
        <Text key={v} css={{ color: 'white', fontSize: '$sm' }}>
          {v}
        </Text>
      ))}
    </Box>
  );
}
