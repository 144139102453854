import { Icon, showToast, Toggle, Tooltip } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

interface AtsSyncToggleProps {
  projectRoleId: string;
  autoSync: boolean;
}

export const AtsSyncToggle = ({
  projectRoleId,
  autoSync,
  ...rest
}: AtsSyncToggleProps) => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const [isToggled, setIsToggled] = useState(autoSync);

  const updateProjectRole = useMutation(
    (toggle: boolean) =>
      apiClient.projectRole.update({
        id: projectRoleId,
        requestBody: {
          autoSync: toggle,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get_project_with_stats', id]);
        showToast({
          variant: 'success',
          title: 'Success!',
          description: `Successfully ${
            isToggled ? 'paused' : 'resumed'
          } auto sync.`,
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: `Failed to ${
            isToggled ? 'pause' : 'resume'
          } auto sync. Please try again.`,
        });
      },
    },
  );

  const handleChange = (pressed: boolean) => {
    setIsToggled(pressed);
    updateProjectRole.mutate(pressed);
  };

  return (
    <Tooltip
      content={isToggled ? 'Pause ATS Sync' : 'Resume ATS Sync'}
      {...rest}
    >
      <Toggle
        pressed={isToggled}
        onChange={handleChange}
        shape='square'
        css={{
          toggled: {
            borderColor: '$neutral-blue-200',
            backgroundColor: '$neutral-blue-200',
          },
          '& svg': {
            height: 20,
            minWidth: 20,
          },
        }}
      >
        {isToggled ? (
          <Icon color='$green-600' name='sync-complete' />
        ) : (
          <Icon color='$neutral-blue-500' name='sync-complete' />
        )}
      </Toggle>
    </Tooltip>
  );
};
