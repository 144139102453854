import { Flex, Input } from '@betterleap/ui';
import { useRef } from 'react';
import { useMutation } from 'react-query';
import { Contact } from '@betterleap/client';
import { getApiClient } from '../../../lib';

interface ContactEditorProps {
  contact: Partial<Contact>;
  onContactUpdated: (contact: Contact) => void;
  onUpdateError: (message: string) => void;
}

interface ContactEditorField {
  key: string;
  name: keyof Contact;
  placeholder: string;
  required?: boolean;
  ref?: React.RefObject<HTMLInputElement>;
  get?: (contact: Partial<Contact>) => string | undefined;
  set?: (value: string) => unknown;
}

const ContactEditor = (props: ContactEditorProps) => {
  const { contact, onContactUpdated, onUpdateError } = props;
  const apiClient = getApiClient();

  const fields: ContactEditorField[] = [
    {
      key: `firstName-${contact.id}`,
      name: 'firstName',
      placeholder: 'First Name',
      required: true,
      ref: useRef<HTMLInputElement>(null),
    },
    {
      key: `lastName-${contact.id}`,
      name: 'lastName',
      placeholder: 'Last Name',
      required: true,
      ref: useRef<HTMLInputElement>(null),
    },
    {
      key: `currentTitle-${contact.id}`,
      name: 'currentTitle',
      placeholder: 'Current Title',
    },
    {
      key: `currentCompany-${contact.id}`,
      name: 'currentCompany',
      placeholder: 'Current Company',
    },
    {
      key: `location-${contact.id}`,
      name: 'locations',
      placeholder: 'Location',
      get: (c: Partial<Contact>) => c.locations?.[0],
      set: (value: string) => (value ? [value] : []),
    },
  ];

  const updateContact = useMutation(
    (data: Partial<Contact>) =>
      apiClient.contact.updateContact({
        contactId: data.id as string,
        requestBody: data,
      }),
    {
      onSuccess: (result) => {
        onContactUpdated(result.data);
      },
      onError: () => {
        onUpdateError('Something went wrong. Please try again.');
      },
    },
  );

  const handleUpdate = (value: string, field: ContactEditorField) => {
    if (field.required && !value && field.ref?.current) {
      // eslint-disable-next-line no-param-reassign
      field.ref.current.value = contact[field.name];
      onUpdateError('This field is required.');
    } else {
      updateContact.mutate({
        id: contact.id,
        primaryEmail: contact.primaryEmail,
        linkedinUrl: contact.linkedinUrl,
        [field.name]: field.set?.(value) || value,
      });
    }
  };

  return (
    <Flex
      css={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: 14,
        color: '$neutral-blue-900',
        mb: 12,
      }}
    >
      {fields.map((field) => (
        <Input
          aria-label={`edit-${field.name}`}
          key={field.key}
          ref={field.ref}
          css={{
            width: '100%',
            paddingLeft: 2,
            border: '1px solid $neutral-blue-300',
            borderRadius: 0,
            borderBottomWidth: 0,
            firstChild: {
              borderTopLeftRadius: '$xl',
              borderTopRightRadius: '$xl',
            },
            lastChild: {
              borderBottomLeftRadius: '$xl',
              borderBottomRightRadius: '$xl',
              borderBottomWidth: 1,
            },
            focus: {
              zIndex: 10,
            },
          }}
          defaultValue={field.get?.(contact) || contact[field.name]}
          placeholder={field.placeholder}
          onBlur={(v) => handleUpdate(v.target.value, field)}
        />
      ))}
    </Flex>
  );
};

export default ContactEditor;
