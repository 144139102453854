import { ContactActivityDto } from '@betterleap/client';
import {
  Badge,
  CheckboxGroup,
  CheckboxMenuItem,
  Flex,
  Text,
} from '@betterleap/ui';
import { useProjectContactsFilterParams } from 'components/templates/Projects/useProjectContactsFilterParams';
import { formatDistance } from 'date-fns';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HeaderWithFilter } from './HeaderWithFilter';

interface Contact {
  mostRecentCommunicationActivity?: ContactActivityDto;
}

const MostRecentActivityColumn = () => ({
  id: 'most-recent-activity',
  Header: () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { activityTypes } = useProjectContactsFilterParams();
    const [value, setValue] = useState<string[]>(activityTypes as string[]);

    const handleChange = (values: string[]) => {
      setValue(values);

      searchParams.delete('activity');

      values.forEach((val) => {
        searchParams.append('activity', val);
      });

      setSearchParams(searchParams);
    };

    return (
      <HeaderWithFilter header='activity' active={value.length > 0}>
        <CheckboxGroup onChange={handleChange} value={value}>
          <CheckboxMenuItem value={ContactActivityDto.event.EMAIL_SENT}>
            Sent Email
          </CheckboxMenuItem>
          <CheckboxMenuItem value={ContactActivityDto.event.INMAIL_SENT}>
            InMailed
          </CheckboxMenuItem>
          <CheckboxMenuItem value={ContactActivityDto.event.EMAIL_REPLIED}>
            Email Replied
          </CheckboxMenuItem>
          <CheckboxMenuItem value={ContactActivityDto.event.INMAIL_REPLIED}>
            Inmail Replied
          </CheckboxMenuItem>
        </CheckboxGroup>
      </HeaderWithFilter>
    );
  },
  width: 90,
  accessor: (contact: Contact) => {
    const activity = contact.mostRecentCommunicationActivity;
    const badge = mapBadgeSettings(activity?.event);
    return !activity || !badge ? (
      <></>
    ) : (
      <Flex css={{ flexDirection: 'column', alignItems: 'flex-start', gap: 4 }}>
        <Badge
          variant={badge.variant as 'orange' | 'teal' | 'success' | 'info'}
          shape='rectangle'
        >
          {badge.text}
        </Badge>
        <Text css={{ fontSize: '$xs', color: '$neutral-blue-600', ml: 4 }}>
          {formatDistance(new Date(activity.createdAt), new Date(), {
            addSuffix: true,
          })}
        </Text>
      </Flex>
    );
  },
  slot: 'custom',
});

const mapBadgeSettings = (event?: ContactActivityDto.event) => {
  if (!event) {
    return null;
  }
  switch (event) {
    case ContactActivityDto.event.EMAIL_SENT:
      return { text: 'Sent email', variant: 'orange' };
    case ContactActivityDto.event.EMAIL_REPLIED:
      return { text: 'Replied', variant: 'info' };
    case ContactActivityDto.event.INMAIL_SENT:
      return { text: 'Sent InMail', variant: 'teal' };
    case ContactActivityDto.event.INMAIL_REPLIED:
      return { text: 'Replied on LI', variant: 'success' };
    default:
      return null;
  }
};

export { MostRecentActivityColumn };
