import {
  getFullName,
  SegmentAnalytics,
  Types,
  useMe,
} from '@betterleap/shared';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import mapValues from 'lodash/mapValues';
import TOKEN from '../lib/token';
import { markEventAsTest, shouldEnableAnalytics } from '../helper/analytics';
import {
  selectMe,
  selectOrganization,
  selectTestUser,
} from '../store/api/selectors';

export const useHotjarIdentify = () => {
  const identifiedRef = useRef(false);
  const me = useMe();

  useEffect(() => {
    if (!identifiedRef.current && me.user) {
      window?.hj?.('identify', me.user.id, {
        email: me.user.email,
        name: getFullName(me.user),
      });

      identifiedRef.current = true;
    }
  }, [me.user]);
};

const useAnalytics = () => {
  const me = useSelector(selectMe);
  const organization = useSelector(selectOrganization);
  const isTestUser = useSelector(selectTestUser);
  const isSignInAsAnotherUser = TOKEN.isSessionStorage();

  const track = useCallback(
    async (
      eventName: Types.ANALYTICS_CLIENT_EVENT,
      properties?: Record<string, unknown>,
    ) => {
      const shouldEnableTrack = shouldEnableAnalytics(isSignInAsAnotherUser);

      const resolvedProperties = mapValues(properties, (value) =>
        typeof value === 'function'
          ? value({ ...me.data, organization: organization.data })
          : value,
      );
      resolvedProperties.test = markEventAsTest(me.data?.email, isTestUser);

      await SegmentAnalytics.track(
        shouldEnableTrack,
        eventName,
        resolvedProperties,
      );
    },
    [me.data?.email, isTestUser, isSignInAsAnotherUser],
  );

  const identify = useCallback(
    async (id?: string) => {
      const shouldEnableTrack = shouldEnableAnalytics(isSignInAsAnotherUser);

      await SegmentAnalytics.identify(shouldEnableTrack, id);
    },
    [me.data?.email, isTestUser, isSignInAsAnotherUser],
  );

  const alias = useCallback(
    async (to: string, from?: string) => {
      const shouldEnableTrack = shouldEnableAnalytics(isSignInAsAnotherUser);

      await SegmentAnalytics.alias(shouldEnableTrack, to, from);
    },
    [me.data?.email, isTestUser, isSignInAsAnotherUser],
  );

  return {
    track,
    identify,
    alias,
  };
};

export default useAnalytics;
