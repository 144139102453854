import { Box, BoxProps } from '@betterleap/ui';

const TermLink = ({ css, ...props }: BoxProps): JSX.Element => (
  <Box
    as='a'
    css={css}
    href='https://betterleap.com/terms-of-service-for-employers-and-recruiters/'
    target='_blank'
    rel='noreferrer'
    {...props}
  >
    Terms
  </Box>
);

export default TermLink;
