import React from 'react';

interface ModalTitleProps {
  title: string;
}

const ModalTitle = ({ title }: ModalTitleProps) => (
  <p
    className='text-lg leading-6 font-medium text-gray-900 mb-2 text-center'
    data-cy={`modal_title-${title}`}
  >
    {title}
  </p>
);

export default ModalTitle;
