import { getProjectSubject, Spinner } from '@betterleap/shared';
import {
  CheckSequenceRequest,
  ExtendedContactProject,
  ProjectWithStatsDto,
} from '@betterleap/client';
import { Box, Button, showToast, Text, useModal } from '@betterleap/ui';
import { useMutation } from 'react-query';
import { Row } from 'react-table';
import {
  AlertModal,
  AlertModalProps,
} from 'components/modules/Modal/AlertModal/AlertModal';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { apiClient } from 'lib/apiClient';
import { CandidateImportNotices } from '../CandidateImportNotices';
import SendSequenceConfirmModal, {
  SendSequenceConfirmModalOnSubmitData,
  SendSequenceConfirmModalProps,
} from '../SendSequenceConfirmModal';
import { useProjectContactsFilterParams } from '../../useProjectContactsFilterParams';

interface SendSequenceButtonProps {
  project: ProjectWithStatsDto;
  selectedContacts: 'all' | Row<Record<string, unknown>>[];
  onSuccess: () => void;
}

export const SendSequenceButton = ({
  project,
  selectedContacts,
  onSuccess,
}: SendSequenceButtonProps) => {
  const { width } = useWindowDimensions();
  const { contactProjectQuery } = useProjectContactsFilterParams();
  const openSendSequenceConfirmModal = useModal<
    SendSequenceConfirmModalProps,
    SendSequenceConfirmModalOnSubmitData
  >(SendSequenceConfirmModal);
  const openAlertModal = useModal<AlertModalProps>(AlertModal);
  const subject = getProjectSubject(
    project?.contactEmailEnrichmentMode ===
      ProjectWithStatsDto.contactEmailEnrichmentMode.PROFESSIONAL,
  );

  const hasSequence = !!project?.sequenceId;
  const hasSelection = selectedContacts === 'all' || !!selectedContacts.length;
  const selectedContactIds =
    selectedContacts === 'all'
      ? undefined
      : selectedContacts.map((c) => (c.original as ExtendedContactProject).id);

  const startSequence = useMutation(
    (request: {
      contactIds?: string[];
      statuses?: CheckSequenceRequest['statuses'];
      skipContactsAlreadyActiveInSequence: boolean;
    }) =>
      apiClient.project.startSequence({
        requestBody: {
          ...contactProjectQuery,
          projectId: project.id,
          contactIds: request.contactIds,
          skipContactsAlreadyActiveInSequence:
            request.skipContactsAlreadyActiveInSequence,
        },
      }),
    {
      onSuccess: async (response) => {
        await openAlertModal({
          title: `Your ${subject()} have been added!`,
          description: (
            <>
              <Text css={{ fontWeight: '$bold' }} inherit inline>
                {response.data.contactsAddedToSequence} {subject()}
              </Text>{' '}
              will start receiving this sequence.
            </>
          ),
          icon: {
            name: 'mail-open',
            variant: 'info',
          },
          notices: CandidateImportNotices(response.data, hasSequence),
          confirmationText: 'Done',
        });
        onSuccess();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Starting Sequences Failed',
          description: 'Something went wrong. Please try again.',
        });
      },
    },
  );

  const checkStartSequence = useMutation(
    (request: {
      contactIds?: string[];
      statuses?: CheckSequenceRequest['statuses'];
    }) =>
      apiClient.project.checkStartSequenceRequest({
        requestBody: {
          ...contactProjectQuery,
          projectId: project.id,
          contactIds: request.contactIds,
        },
      }),
    {
      onSuccess: async (response) => {
        let skipContactsAlreadyActiveInSequence = true;
        if (response.data.contactsWithActiveSequencesCount > 0) {
          const confirmation = await openSendSequenceConfirmModal({
            responseData: response.data,
          });

          if (!confirmation) {
            return;
          }
          skipContactsAlreadyActiveInSequence =
            confirmation.skipContactsAlreadyActiveInSequence;
        }

        startSequence.mutate({
          ...contactProjectQuery,
          contactIds: selectedContactIds,
          skipContactsAlreadyActiveInSequence,
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Starting Sequences Failed',
          description: 'Something went wrong. Please try again.',
        });
      },
    },
  );

  const checkSequenceBeforeStart = () => {
    checkStartSequence.mutate({
      ...contactProjectQuery,
      contactIds: selectedContactIds,
    });
  };

  return (
    <Button
      disabled={!hasSelection}
      onClick={checkSequenceBeforeStart}
      css={{ minWidth: width < 500 ? 82 : 140 }}
    >
      {checkStartSequence.isLoading || startSequence.isLoading ? (
        <Box css={{ ml: '.75rem' }}>
          {/* spinner has mr .75rem */}
          <Spinner variant='blue' />
        </Box>
      ) : width < 500 ? (
        'Send'
      ) : (
        'Send Sequence'
      )}
    </Button>
  );
};
