import { useMe } from '@betterleap/shared';
import { ConnectIntegrationDto, Organization } from '@betterleap/client';
import { Box } from '@betterleap/ui';
import { useState } from 'react';
import Line from 'components/elements/Line/Line';
import useConnectIntegration from 'hooks/useConnectIntegration';
import ConnectCrm from './Connections/ConnectCrm';
import ConnectAts from './Connections/ConnectAts';
import { ConnectIntegrationProps } from './Connections/Integration.elements';

type IntegrationCategory = ConnectIntegrationDto.integrationCategory;
type CategoryMap = { [s: string]: (p: ConnectIntegrationProps) => JSX.Element };

interface IntegrationsSectionProps {
  organization: Organization;
}

const IntegrationsSection = ({ organization }: IntegrationsSectionProps) => {
  const me = useMe();
  const { open, isLoading } = useConnectIntegration({ location: 'Settings' });
  const [category, setCategory] = useState<IntegrationCategory | null>(null);
  const categories: CategoryMap = {
    [ConnectIntegrationDto.integrationCategory.ATS]: ConnectAts,
    [ConnectIntegrationDto.integrationCategory.CRM]: ConnectCrm,
  };

  if (organization.integrationsDisabled) {
    return null;
  }

  return (
    <>
      {me.ability.can('update', 'Organization', 'atsSource') &&
        Object.entries(categories).map((integrationCategory) => {
          const ConnectIntegration = integrationCategory[1];
          return (
            <Box key={integrationCategory[0]}>
              <ConnectIntegration
                organization={organization}
                isLoading={isLoading && category === integrationCategory[0]}
                openMergeLink={() => {
                  setCategory(integrationCategory[0] as IntegrationCategory);
                  open(integrationCategory[0] as IntegrationCategory);
                }}
              />
              <Line />
            </Box>
          );
        })}
    </>
  );
};

export default IntegrationsSection;
