import { getFullName, Types } from '@betterleap/shared';
import { RecruiterDto } from '@betterleap/client';
import { Flex, Spinner } from '@betterleap/ui';
import { useMutation, useQuery } from 'react-query';
import { apiClient } from 'lib/apiClient';
import { useEffect } from 'react';
import { logToSegment } from 'functions/segmentLog';
import { useSelector } from 'react-redux';
import { selectMe } from 'store/api/selectors';
import { RecruiterProfile } from './RecruiterProfile';

const RecruiterProfileTemplate = () => {
  const {
    data: recruiterResponse,
    isLoading,
    refetch,
  } = useQuery(['get_recruiter'], () =>
    apiClient.recruiter.getRecruiter({ xTestUser: undefined }),
  );

  const userData = useSelector(selectMe);

  useEffect(() => {
    const recruiterUserData = recruiterResponse?.data.user;
    if (recruiterUserData?.id === userData.data?.id) return;

    logToSegment(Types.SEGMENT_EVENTS.RECRUITER_PROFILE_VIEW, {
      recruiter_name: getFullName(recruiterUserData),
      recruiter_id: recruiterUserData?.id,
    });
  }, []);

  const updateRecruiter = useMutation(
    (requestBody: RecruiterDto) =>
      apiClient.recruiter.updateRecruiter({ requestBody }),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
  if (!recruiterResponse?.data || isLoading) {
    return (
      <Flex css={{ height: '100%', justifyContent: 'center' }}>
        <Spinner />
      </Flex>
    );
  }
  return (
    <RecruiterProfile
      recruiter={recruiterResponse.data}
      onUpdate={(dto) => updateRecruiter.mutate(dto)}
    />
  );
};

export default RecruiterProfileTemplate;
