import { Types } from '@betterleap/shared';
import { ProjectWithStatsDto } from '@betterleap/client';
import { Button, showToast, useModal } from '@betterleap/ui';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import useAnalytics from 'hooks/analytics';
import LinkRoleModal from 'components/modules/Modal/LinkRoleModal/LinkRoleModal';
import { apiClient } from 'lib/apiClient';
import { selectMe } from 'store/api/selectors';

interface LinkRoleButtonProps {
  project: ProjectWithStatsDto;
  onRoleLinked: () => void;
}

export const LinkRoleButton = ({
  project,
  onRoleLinked,
}: LinkRoleButtonProps) => {
  const me = useSelector(selectMe);
  const openLinkRoleModal = useModal<
    { isProjectOwner: boolean },
    { roleId: string; autoSync: boolean }
  >(LinkRoleModal);

  const { track } = useAnalytics();

  const linkRoleToProject = useMutation(
    (data: { roleId: string; autoSync: boolean }) =>
      apiClient.project.linkRole({
        id: project.id,
        requestBody: {
          roleId: data.roleId,
          autoSync: data.autoSync,
        },
      }),
    {
      onSuccess: () => {
        onRoleLinked();
        showToast({
          variant: 'success',
          title: 'Role Linked',
          description: 'Submitted candidates will now be added to your ATS.',
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Role Connecting Failed',
          description: 'Something went wrong. Please try again.',
        });
      },
    },
  );

  const handleLinkRole = async () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Link ATS Role',
      buttonLocation: 'Project Details',
    });
    const isProjectOwner = me.data?.organizationId === project?.organizationId;
    const result = await openLinkRoleModal({ isProjectOwner });

    if (result) {
      linkRoleToProject.mutate({
        roleId: result.roleId,
        autoSync: result.autoSync,
      });
    }
  };

  return (
    <Button variant='secondary' onClick={handleLinkRole}>
      Link Role
    </Button>
  );
};
