import { Flex, Spinner } from '@betterleap/ui';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import RecruiterToolPaywall from 'components/templates/RecruiterToolPaywall/RecruiterToolPaywall';
import { apiClient } from 'lib/apiClient';
import { selectOrganization } from 'store/api/selectors';
import withSidebar from '../components/HOC/withSidebar';
import ListProjects from '../components/templates/Projects/ListProjects.template';
import { isPaywallTier } from '../helper/paywall';

const OrganizationProjects = () => {
  const organization = useSelector(selectOrganization);

  const { data: sharedProjects, isLoading: sharedProjectLoading } = useQuery(
    ['project-share'],
    () =>
      apiClient.projectShare.getAllSharedProjectsWithOrganization({
        search: '',
      }),
  );

  if (!organization.data || sharedProjectLoading) {
    return (
      <Flex css={{ justifyContent: 'center', flexGrow: 1 }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }

  const showPaywall =
    isPaywallTier(organization.data.tier) && sharedProjects?.data?.length === 0;

  return showPaywall ? (
    <RecruiterToolPaywall tier={organization.data.tier} />
  ) : (
    <ListProjects />
  );
};

export default withSidebar(OrganizationProjects, true);
