import { useQuery, UseQueryOptions } from 'react-query';
import { defineUserAbility, FrontendAppAbility } from '@betterleap/authz';
import { ApiResponse, User } from '@betterleap/client';
import { getApiClient } from '../lib';

export const useMe = (
  options: UseQueryOptions<
    ApiResponse<User>,
    unknown,
    ApiResponse<User>,
    'me'
  > = {},
) => {
  const apiClient = getApiClient();
  const { data: result, ...rest } = useQuery(
    'me',
    () => apiClient.auth.me(),
    options,
  );

  const user = result?.data;
  const ability = defineUserAbility<User, FrontendAppAbility>(user);

  return {
    user,
    ability,
    ...rest,
  };
};
