import routeNames from 'constants/routeNames';
import { Flex, Text } from '@betterleap/ui';
import { Link } from 'react-router-dom';

export const ProjectNameColumn = () => ({
  Header: 'PROJECT',
  width: 170,
  headerCss: {
    maxWidth: 230,
  },
  css: {
    maxWidth: 230,
  },
  accessor: (cp: { projectName: string; projectId: string }) => (
    <Flex css={{ gap: 12 }}>
      <Link
        id={cp.projectId}
        to={routeNames.project({
          id: cp.projectId,
        })}
        className='cursor-pointer text-blue-500'
      >
        <Text css={{ fontSize: '$sm', fontWeight: '$medium' }}>
          {cp.projectName}
        </Text>
      </Link>
    </Flex>
  ),
  slot: 'custom',
});
