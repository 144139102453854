import {
  Badge,
  Button,
  ButtonProps,
  Flex,
  Icon,
  IconButton,
} from '@betterleap/ui';
import { FileEntity } from '@betterleap/client';

export interface StepAttachmentItemProps extends ButtonProps {
  file: FileEntity;
  readOnly?: boolean;
  onRemove?: () => void;
}

export const StepAttachmentItem = ({
  file,
  readOnly,
  onRemove,
}: StepAttachmentItemProps) => (
  <Button
    as='div'
    variant='ghost'
    css={{
      border: '1px solid $neutral-blue-200',
      display: 'flex',
      gap: 8,
      px: 8,
      borderRadius: 8,
      height: 34,
      hover: {
        backgroundColor: '$background-component',
      },
    }}
  >
    <Flex onClick={() => window.open(file.gcsUrl, '_blank')}>
      <Icon name='attachment' size={18} />
      <Badge variant='gray' shape='rectangle'>
        {file?.name?.split('-')?.slice(1)?.join('-')}
      </Badge>
    </Flex>
    {!readOnly && (
      <IconButton
        size='xs'
        type='button'
        label='remove attachment'
        name='trash'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onRemove?.();
        }}
        variant='danger'
      />
    )}
  </Button>
);
