/* eslint-disable @typescript-eslint/no-explicit-any */
import { Contact } from '@betterleap/client';
import {
  AlertIcon,
  Badge,
  BadgeProps,
  Button,
  Col,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PromiseModal,
  RadioCard,
  // RadioCardProps,
  RadioGroup,
  Row,
  Text,
} from '@betterleap/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Prompt, prompts } from './prompts';

export interface WorkflowModalProps {
  mode: Contact.contactEmailEnrichmentMode;
  projectName: string;
}

const RainbowBadge = ({ children, css, ...props }: BadgeProps) => (
  <Badge
    css={{
      borderRadius: 8,
      background:
        'linear-gradient(98.51deg, #46E1CF -0.77%, #5B9BFF 48.24%, #9C4FFF 100.95%)',
      p: 0.5,
      ...css,
    }}
    {...props}
  >
    <Badge
      css={{
        px: 12,
        py: 4,
        borderRadius: 8,
        background:
          'linear-gradient(97.16deg, rgba(233, 255, 250) 0%, rgba(224, 238, 255) 48.89%, rgba(236, 230, 255) 103.79%)',
      }}
    >
      <Text
        size='xs'
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          background:
            'linear-gradient(98.51deg, #46E1CF -0.77%, #5B9BFF 48.24%, #9C4FFF 100.95%)',
          '-webkit-background-clip': 'text',
          '-webkit-text-fill-color': 'transparent',
          backgroundClip: 'text',
          textFillColor: 'transparent',
        }}
      >
        <Icon name='star' color='$cyan-400' size={14} />
        {children}
      </Text>
    </Badge>
  </Badge>
);

const WorkflowRadio = ({
  prompt,
  css,
  onDismiss,
  ...props
}: any & { prompt: Prompt }) => {
  const navigate = useNavigate();

  return (
    <RadioCard
      css={{
        checked: {
          background: '$background-component',
        },
        hover: {
          backgroundColor: '$background-muted',
          checked: {
            background: '$background-component',
          },
        },
        disabled: {
          backgroundColor: '$background-component',
          cursor: 'default',
        },
        height: 160,
        display: 'flex',
        px: 24,
        py: 20,
        borderRadius: '$5xl',
        border: '1px solid $neutral-blue-300',
        flexDirection: 'column',
        ...css,
      }}
      disabled
      {...props}
      onClick={() => {
        let navigateTo;

        // If the prompt has a route property and we're not already on that route, we need to navigate
        if (prompt.route) {
          navigateTo = prompt.route;
        } else {
          // else fallback to general copilot
          navigateTo = `/copilot?prompt=${prompt.title}`;
        }

        // Note: Make sure we close the modal before the route change or the modal will not be unmounted.
        onDismiss();
        navigate(navigateTo);
      }}
    >
      <Flex css={{ gap: 8, mb: 16 }}>
        <AlertIcon
          name={prompt.icon}
          variant={prompt.variant}
          shape='rounded-square'
          size='sm'
        />
        <Text size='sm' css={{ fontWeight: '$medium' }}>
          {prompt.title}
        </Text>
      </Flex>
      <Text size='sm'>{prompt.description}</Text>
      {!prompt.prompt && !prompt.route && (
        <RainbowBadge css={{ mt: 16 }}>Coming Soon</RainbowBadge>
      )}
    </RadioCard>
  );
};

const WorkflowModal: PromiseModal<
  Record<string, string>,
  { prompt: string }
> = ({ onSubmit, onDismiss }) => (
  <Modal size='xxl' autoFocus={false} css={{ p: '32px 24px 0px 24px' }}>
    <ModalHeader>
      <Flex css={{ gap: 16, mb: 32 }}>
        <AlertIcon name='template' size='lg' shape='rounded-square' />
        <ModalTitle size='lg' css={{ fontWeight: '$medium' }}>
          Workflows Center
        </ModalTitle>
      </Flex>
    </ModalHeader>
    <ModalBody css={{ padding: 4 }}>
      <RadioGroup
        css={{ pb: 40 }}
        onChange={(value) => onSubmit({ prompt: value })}
      >
        <Row>
          {prompts.map((prompt) => (
            <Col
              key={prompt.prompt}
              span={{
                '@initial': 12,
                '@md': 4,
              }}
            >
              <WorkflowRadio
                value={prompt.prompt}
                prompt={prompt}
                disabled={!prompt.prompt && !prompt.route}
                onDismiss={onDismiss}
              />
            </Col>
          ))}
        </Row>
      </RadioGroup>
    </ModalBody>
    <ModalFooter>
      <Flex
        justify='end'
        css={{
          borderTop: '1px solid $neutral-blue-300',
          py: 16,
          mx: -24,
          px: 24,
        }}
      >
        <Button
          variant='gray'
          css={{ minWidth: 100 }}
          onClick={() => onDismiss()}
        >
          Cancel
        </Button>
      </Flex>
    </ModalFooter>
  </Modal>
);

export default WorkflowModal;
