import { Types } from '@betterleap/shared';
import { AxiosResponse } from 'axios';
import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import api from '../../lib/api';
import { apiActions } from '../../store/api/actions';

export function* onRefetchOrganizationSuccess() {
  const organizationResponse: AxiosResponse<
    Types.ApiReducer<Types.Organization>
  > = yield api.fetch<AxiosResponse<Types.ApiReducer<Types.Organization>>>(
    'organization',
  );
  yield put(
    apiActions.fetchSuccess('organization', organizationResponse.data.data),
  );
}

export function* watchOnRefetchOrganizationSuccess(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest('REFETCH_ORGANIZATION', onRefetchOrganizationSuccess);
}

export default [watchOnRefetchOrganizationSuccess];
