import { Text, Tooltip } from '@betterleap/ui';
import { ProjectWithStatsDto } from '@betterleap/client';
import { Row } from 'react-table';
import { MoveToMenu, ProjectActionButton } from '../../ProjectActions';
import { hasSelection } from '../../ProjectDetails.functions';
import { useProjectContactsFilterParams } from '../../useProjectContactsFilterParams';
import { useMoveContacts } from '../Hooks/useMoveContacts';

interface Props {
  project: ProjectWithStatsDto;
  totalContactCount: number;
  selectedContacts: Row<Record<string, unknown>>[] | 'all';
  onContactsCopied: () => void;
}

export const CopyToProjectButton = ({
  project,
  totalContactCount,
  selectedContacts,
  onContactsCopied,
}: Props) => {
  const { contactProjectQuery } = useProjectContactsFilterParams();
  const { onMoveContacts, setProjectSearchFilter, projectsLoading, options } =
    useMoveContacts({
      currentProject: project,
      selectedContacts,
      totalCount: totalContactCount,
      ...contactProjectQuery,
      onSuccess: onContactsCopied,
    });
  return (
    <MoveToMenu
      title='Copy to'
      options={options}
      onChange={(ids) => onMoveContacts(ids as string[], false)}
      loading={projectsLoading}
      onFilterChange={setProjectSearchFilter}
      emptyState={<Text css={{ p: 16 }}>No projects available</Text>}
    >
      <Tooltip content='Copy to'>
        <ProjectActionButton
          disabled={!hasSelection(selectedContacts)}
          name='duplicate'
          label='Copy Candidates to Project.'
        />
      </Tooltip>
    </MoveToMenu>
  );
};
