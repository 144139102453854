import React from 'react';
import {
  AlertIcon,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';
import { useSelector } from 'react-redux';
import { selectMe } from 'store/api/selectors';

export interface StartSourcingModalProps {
  onUploadCSV: () => void;
  onSourceWithBetterleap: () => void;
}

export const StartSourcingModal: PromiseModal<StartSourcingModalProps> = ({
  onSubmit,
  onUploadCSV,
  onSourceWithBetterleap,
}) => {
  const me = useSelector(selectMe);
  const hasAddedChromeExtension = me?.data?.hasAddedChromeExtension;

  const handleSourceWithChromeClick = () => {
    onSubmit();
    window.open(
      'https://chrome.google.com/webstore/detail/betterleap-sourcing/bimmbgomanhpkfodmiomjgfakleojpia',
    );
  };

  const handleSourceWithBetterleapClick = () => {
    onSubmit();
    onSourceWithBetterleap();
  };

  const handleUploadCSV = () => {
    onSubmit();
    onUploadCSV();
  };

  return (
    <Modal>
      <ModalHeader>
        <Flex css={{ py: 16 }} justify='center'>
          <AlertIcon size='lg' variant='info' name='search' />
        </Flex>
        <ModalTitle css={{ textAlign: 'center' }}>Add candidates</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Flex css={{ flexDirection: 'column' }} justify='center' align='center'>
          <Text
            size='sm'
            css={{ color: '$text-secondary', textAlign: 'center', mb: 24 }}
          >
            How would you like to source?
          </Text>
          <Button
            style={{ marginBottom: hasAddedChromeExtension ? 24 : 8 }}
            onClick={handleSourceWithBetterleapClick}
            css={{ width: 268 }}
          >
            Start sourcing in Betterleap
          </Button>
          {!hasAddedChromeExtension && (
            <Button
              variant='secondary'
              onClick={handleSourceWithChromeClick}
              css={{ width: 268, mb: 24 }}
            >
              Source with the Chrome Extension
            </Button>
          )}
          <Flex justify='center'>
            <Button
              variant='link'
              onClick={handleUploadCSV}
              css={{ mb: 16, gap: 8 }}
            >
              <Icon inherit name='upload' size={18} /> Upload a CSV
            </Button>
          </Flex>
        </Flex>
      </ModalBody>
    </Modal>
  );
};
