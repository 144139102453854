import { PastRecruiterClientDto } from '@betterleap/client';
import {
  Flex,
  Form,
  BoundInput as Input,
  Modal,
  PromiseModal,
} from '@betterleap/ui';
import { useForm } from 'react-hook-form';
import {
  FieldTitle,
  ModalButtons,
  ModalField,
  ModalHeader,
} from 'components/modules/Modal/Modal.elements';

export interface AddClientResult {
  name: string;
  delete?: boolean;
}

export interface AddClientModalProps {
  client?: PastRecruiterClientDto;
}

export const AddClientModal: PromiseModal<AddClientModalProps> = ({
  client,
  onDismiss,
  onSubmit,
}) => {
  const { control, formState, handleSubmit } = useForm<{
    name: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      name: client?.companyName ?? '',
    },
  });
  const onDelete = () => {
    onSubmit({ delete: true });
  };
  return (
    <Modal css={{ mediaLg: { p: 24 } }}>
      <Form control={control} onSubmit={handleSubmit(onSubmit)}>
        <Flex css={{ alignItems: 'center', gap: 12 }} vertical>
          <ModalHeader title='Add a client' icon='sparkles' />
          <ModalField>
            <FieldTitle
              title='Client details'
              icon='document-text'
              htmlFor='name'
            />
            <Input
              id='name'
              name='name'
              placeholder='Name'
              required
              css={{ width: '100%' }}
            />
          </ModalField>
          <ModalButtons
            formState={formState}
            submitText={!client ? 'Add client' : 'Done'}
            onCancel={!client ? onDismiss : undefined}
            onDelete={client ? onDelete : undefined}
          />
        </Flex>
      </Form>
    </Modal>
  );
};
