import { SequenceMetricsDto } from '@betterleap/client';
import { AlertIcon, Badge, Box, Flex, Text } from '@betterleap/ui';
import moment from 'moment';

interface SequenceStatisticsProps {
  sequenceStats?: SequenceMetricsDto;
}

interface SequenceStatusProps {
  status?: SequenceMetricsDto.status;
}
const SequenceStatus = ({ status }: SequenceStatusProps) => {
  switch (status) {
    case 'InActive':
      return <></>;
    case 'Active':
      return (
        <Badge size='sm' variant='success'>
          Active
        </Badge>
      );
    case 'Paused':
      return (
        <Badge size='sm' variant='danger'>
          Paused
        </Badge>
      );
    case 'New':
      return (
        <Badge size='sm' variant='warning'>
          New
        </Badge>
      );
    case 'Completed':
      return (
        <Badge size='sm' variant='info'>
          Completed
        </Badge>
      );
    default:
      return (
        <Badge size='sm' variant='warning'>
          New
        </Badge>
      );
  }
};

const SequenceStatistics = ({ sequenceStats }: SequenceStatisticsProps) =>
  !sequenceStats ? null : (
    <Box
      css={{
        background: 'white',
        borderRadius: '$xl',
        p: 24,
        mb: 24,
      }}
    >
      <Flex css={{ mb: 24, justifyContent: 'space-between' }}>
        <Flex css={{ gap: 16 }}>
          <Text css={{ fontWeight: '$medium' }}>Sequence Overview</Text>
          <SequenceStatus status={sequenceStats.status} />
        </Flex>
        <Flex
          css={{
            gap: 16,
            flexDirection: 'column',
            mediaMd: { flexDirection: 'row' },
          }}
        >
          <Text
            size='xs'
            noBreak
            css={{
              color: '$neutral-blue-600',
            }}
          >
            Created by {sequenceStats.creator}
          </Text>
          {sequenceStats?.createdAt && (
            <Text
              size='xs'
              noBreak
              css={{
                color: '$neutral-blue-600',
              }}
            >
              Created on {moment(sequenceStats?.createdAt).format('ll')}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex css={{ flexWrap: 'wrap', gap: 16 }}>
        <Flex
          css={{
            gap: 16,
            flex: 1,
            maxWidth: 300,
          }}
        >
          <AlertIcon
            shape='square'
            name='view-list'
            css={{ backgroundColor: '$cyan-200', fill: '$cyan-700' }}
          />
          <div>
            <Text size='2xl'>
              {Math.round(sequenceStats.inSequencePercent * 100)}%
            </Text>
            <Text
              size='xs'
              noBreak
              css={{
                color: '$neutral-blue-600',
              }}
            >
              {sequenceStats.inSequenceCount} in Sequence
            </Text>
          </div>
        </Flex>
        <Flex
          css={{
            gap: 16,
            flex: 1,
            maxWidth: 300,
          }}
        >
          <AlertIcon shape='square' name='mail' variant='purple' />
          <div>
            <Text size='2xl'>
              {Math.round(sequenceStats.messagedPercent * 100)}%
            </Text>
            <Text
              size='xs'
              noBreak
              css={{
                color: '$neutral-blue-600',
              }}
            >
              {sequenceStats.messagedCount} Messaged
            </Text>
          </div>
        </Flex>
        <Flex
          css={{
            gap: 16,
            flex: 1,
            maxWidth: 300,
          }}
        >
          <AlertIcon shape='square' name='mail-open' variant='warning' />
          <div>
            <Text size='2xl' data-testid='opened-count-percentage'>
              {Math.round(sequenceStats.openedPercent * 100)}%
            </Text>
            <Text
              size='xs'
              noBreak
              css={{
                color: '$neutral-blue-600',
              }}
              data-testid='opened-count'
            >
              {sequenceStats.openedCount} Opened
            </Text>
          </div>
        </Flex>
        <Flex
          css={{
            gap: 16,
            flex: 1,
            maxWidth: 300,
          }}
        >
          <AlertIcon shape='square' name='bell' variant='success' />
          <div>
            <Text size='2xl' data-testid='replied-count-percentage'>
              {Math.round(sequenceStats.repliedPercent * 100)}%
            </Text>
            <Text
              size='xs'
              noBreak
              css={{
                color: '$neutral-blue-600',
              }}
              data-testid='replied-count'
            >
              {sequenceStats.repliedCount} Replied
            </Text>
          </div>
        </Flex>
        <Flex
          css={{
            gap: 16,
            flex: 1,
            maxWidth: 300,
          }}
        >
          <AlertIcon shape='square' name='star' variant='orange' />
          <div>
            <Text size='2xl'>
              {Math.round(sequenceStats.interestedPercent * 100)}%
            </Text>
            <Text
              size='xs'
              noBreak
              css={{
                color: '$neutral-blue-600',
              }}
            >
              {sequenceStats.interestedCount} Interested
            </Text>
          </div>
        </Flex>
        <Flex
          css={{
            gap: 16,
            flex: 1,
            maxWidth: 300,
          }}
        >
          <AlertIcon
            shape='square'
            name='exclamation-circle'
            variant='danger'
          />
          <div>
            <Text size='2xl' data-testid='bounced-count-percentage'>
              {Math.round(sequenceStats.bouncedPercent * 100)}%
            </Text>
            <Text
              size='xs'
              noBreak
              css={{
                color: '$neutral-blue-600',
              }}
              data-testid='bounced-count'
            >
              {sequenceStats.bouncedCount} Bounced
            </Text>
          </div>
        </Flex>
      </Flex>
    </Box>
  );
export default SequenceStatistics;
