import { Types } from '@betterleap/shared';
import { Organization, Sender } from '@betterleap/client';
import { Box, showToast, useModal } from '@betterleap/ui';
import React, {
  cloneElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import * as Portal from '@radix-ui/react-portal';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiClient } from 'lib/apiClient';
import { useConnectEmail } from 'hooks/useConnectEmail';
import { logToSegment } from 'functions/segmentLog';
import routeNames from '../../../constants/routeNames';
import api from '../../../lib/api';
import { selectMe } from '../../../store/api/selectors';
import {
  AlertModal,
  AlertModalProps,
} from '../../modules/Modal/AlertModal/AlertModal';
import { oauthResult } from '../../templates/SenderConnect/SenderConnectCallback.template';
import { BANNER_MOUNT_ID, useBanner } from '../Banner/Banner';
import { FreeTrialBanner } from '../FreeTrialBanner/FreeTrialBanner';
import {
  EmailAccountBanner,
  emailAccountStates,
} from '../EmailAccountBanner/EmailAccountBanner';

export const EMAIL_SUCCESS_BANNER = 'EMAIL_SUCCESS_BANNER';
export const EMAIL_DISCONNECTED_BANNER = 'EMAIL_DISCONNECTED_BANNER';
export const FREE_TRIAL_BANNER = 'FREE_TRIAL_BANNER';

export const Banners = () => {
  const navigate = useNavigate();
  const mountBannerRef = useRef<HTMLElement>();
  const { openBanner, closeBanner, banner } = useBanner();
  const { pathname } = useLocation();
  const openConfirmationModal = useModal<AlertModalProps>(AlertModal);
  const me = useSelector(selectMe);
  const { data: organization } = useQuery(['organization'], () =>
    apiClient.organization.getOrganization(),
  );

  const { data: senderData, refetch } = useQuery(
    ['get_senders'],
    () => api.fetch<{ data: Sender[] }>('get_senders'),
    { enabled: !!me.data },
  );

  const senders = senderData?.data?.data ?? [];

  const handleConnectEmail = useConnectEmail();

  const callback = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (event: MessageEvent<any>) => {
      const { data } = event;
      if (data?.result === oauthResult.SUCCESS) {
        refetch().then(() => {
          logToSegment(Types.SEGMENT_EVENTS.EMAIL_PERMISSIONS_GRANTED);
          closeBanner();
          openBanner(
            EMAIL_SUCCESS_BANNER,
            <EmailAccountBanner
              emailAccountState={emailAccountStates.CONNECTED}
            />,
          );
        });

        window?.focus();

        if (window.location.pathname !== routeNames.settings()) {
          navigate(routeNames.projects());
        }

        showToast({
          variant: 'success',
          title: 'Email Successfully Connected',
          description: 'You can now create and send sequences',
        });
      } else if (data?.result === oauthResult.FAILURE) {
        const isPersonalEmail = data?.error?.name === 'SendingEmailNotAllowed';

        const result = await openConfirmationModal({
          title: 'Email Authentication Failed',
          icon: {
            variant: 'danger',
            name: 'x-circle',
          },
          description: isPersonalEmail
            ? 'Personal email accounts are not allowed. Please use a business email for your sending email.'
            : 'In order to send your email sequences, we need you to connect your email account to share permissions to allow us to send emails on your behalf.',
          confirmationText: 'Try Again',
        });

        if (result) {
          handleConnectEmail();
        }
      }
    },
    [me.data],
  );

  useEffect(() => {
    if (me.data) {
      window.addEventListener('message', callback, false);
    }

    return () => {
      window.removeEventListener('message', callback);
    };
  }, [me.data]);

  useEffect(() => {
    if (!organization?.data || !senderData?.data?.data) {
      return;
    }

    const showFreeTrialBanner = [
      Organization.tier.FREE_TRIAL,
      Organization.tier.PAYWALL,
    ].includes(organization.data.tier);

    if (showFreeTrialBanner) {
      openBanner(FREE_TRIAL_BANNER, <FreeTrialBanner />);
    }

    const disconnectedSender = senders.find(
      (sender) => sender.status === Sender.status.ERROR,
    );

    if (disconnectedSender) {
      openBanner(
        EMAIL_DISCONNECTED_BANNER,
        <EmailAccountBanner
          provider={disconnectedSender.provider}
          email={disconnectedSender.email}
          emailAccountState={emailAccountStates.DISCONNECTED}
        />,
      );
    } else if (organization.data.tier === Organization.tier.PAID) {
      closeBanner();
    }
  }, [senderData, pathname, organization?.data]);

  mountBannerRef.current =
    document.getElementById(BANNER_MOUNT_ID) ?? undefined;

  if (!mountBannerRef.current) {
    // eslint-disable-next-line no-console
    console.warn('No banner mounting element found.');
    return null;
  }

  return (
    <Portal.Root containerRef={mountBannerRef as RefObject<HTMLElement>}>
      <Box
        css={{
          transition: 'width .4s, left .4s',
          position: 'absolute',
          top: 86,
          right: 0,
          left: 0,
          zIndex: 10,
        }}
      >
        {banner && cloneElement(banner)}
      </Box>
    </Portal.Root>
  );
};
