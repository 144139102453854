import { Box, BoxProps } from '@betterleap/ui';

export interface SourceCandidatesLayoutProps extends BoxProps {
  featureEnabledNaturalLanguageSourcing: boolean | undefined;
}

export const SourceCandidatesLayout = ({
  css,
  featureEnabledNaturalLanguageSourcing,
  ...props
}: SourceCandidatesLayoutProps) => (
  <Box
    css={{
      display: 'grid',
      gridTemplateAreas: `
        "sidebar searchbar"
        "sidebar subHeader"
        "sidebar main"
      `,
      gridTemplateRows: featureEnabledNaturalLanguageSourcing
        ? '75px 56px 1fr'
        : '0px 56px 1fr',
      gridTemplateColumns: '310px 1fr',
      height: '100%',
      ...css,
    }}
    {...props}
  />
);

SourceCandidatesLayout.Header = function Header({ css, ...props }: BoxProps) {
  return <Box css={{ gridArea: 'header', ...css }} {...props} />;
};

SourceCandidatesLayout.SearchBar = function Header({
  css,
  ...props
}: BoxProps) {
  return <Box css={{ gridArea: 'searchbar', ...css }} {...props} />;
};

SourceCandidatesLayout.SubHeader = function Header({
  css,
  ...props
}: BoxProps) {
  return <Box css={{ gridArea: 'subHeader', ...css }} {...props} />;
};

SourceCandidatesLayout.Main = function Main({ css, ...props }: BoxProps) {
  return (
    <Box css={{ gridArea: 'main', overflow: 'auto', ...css }} {...props} />
  );
};

SourceCandidatesLayout.SideBar = function SideBar({ css, ...props }: BoxProps) {
  return (
    <Box css={{ gridArea: 'sidebar', overflow: 'auto', ...css }} {...props} />
  );
};
