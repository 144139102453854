import { Flex, Text } from '@betterleap/ui';

export const CompanyColumn = () => ({
  Header: 'COMPANY',
  width: 170,
  headerCss: {
    maxWidth: 230,
  },
  css: {
    maxWidth: 230,
  },
  accessor: (contact: { currentTitle?: string; currentCompany?: string }) => (
    <>
      <Flex css={{ gap: 12 }}>
        <Text
          css={{
            color: '$neutral-blue-700',
            fontSize: '$sm',
            lineHeight: '$2xl',
          }}
        >
          {contact.currentCompany ?? '--'}
        </Text>
      </Flex>
      <Text css={{ color: '$text-lightest', fontSize: '$xs' }}>
        {contact.currentTitle ? contact.currentTitle : ''}
      </Text>
    </>
  ),
  slot: 'custom',
});
