import { BoxProps, Icon, Select, SelectItem, Text } from '@betterleap/ui';
import { format, parse } from 'date-fns';
import React, { useState } from 'react';

export interface DelayStrategySelectorProps extends BoxProps {
  /**
   * The order of the step.
   */
  stepNumber: number;
  readOnly?: boolean;
  canEditDelay?: boolean;
  canEditSendStrategy?: boolean;
  defaultSendStrategy?: 'send-immediately' | 'send-at';
  onSendStrategyChange?: (value: 'send-immediately' | 'send-at') => void;
  sendAt?: string | null;
  delay?: number;
  businessDays?: boolean;
  onBlur?: () => void;
}

const ReadOnlyDelay = ({
  stepNumber,
  businessDays = false,
  delay = 0,
  sendAt,
}: Partial<DelayStrategySelectorProps>) => {
  const isFirstStep = stepNumber && stepNumber <= 1;
  const isImmediate = !sendAt;
  const formattedSendAt =
    sendAt && format(parse(sendAt, 'HH:mm:ss', new Date()), 'hh:mm a');

  if (isFirstStep && isImmediate) {
    return (
      <Text size='sm' css={{ color: '$text-lightest' }}>
        Scheduled for immediately after contact is sourced
      </Text>
    );
  }

  if (isFirstStep) {
    return (
      <Text size='sm' css={{ color: '$text-lightest' }}>
        Scheduled for
        {sendAt && (
          <>
            <Text
              inherit
              inline
              css={{ fontWeight: '$bold', marginInline: '4px' }}
            >
              {formattedSendAt}
            </Text>
            &#40;contact’s timezone&#41;
          </>
        )}
      </Text>
    );
  }

  return (
    <Text
      size='sm'
      css={{
        color: '$text-lightest',
      }}
    >
      Scheduled for
      {sendAt && (
        <>
          <Text
            inherit
            inline
            css={{ fontWeight: '$bold', marginInline: '4px' }}
          >
            {formattedSendAt}
          </Text>
          &#40;contact’s timezone&#41;
        </>
      )}
      <Text inherit inline css={{ fontWeight: '$bold', marginInline: '4px' }}>
        {delay} {businessDays && 'business '}days after previous step
      </Text>
    </Text>
  );
};

export const DelayStrategySelector = ({
  sendAt,
  delay,
  businessDays,
  stepNumber,
  defaultSendStrategy,
  onSendStrategyChange,
  readOnly,
  canEditDelay,
  canEditSendStrategy,
  onBlur,
  children,
}: DelayStrategySelectorProps) => {
  const isFirstStep = stepNumber <= 1;
  const [sendStrategy, setSendStrategy] = useState<string>(
    defaultSendStrategy ?? (isFirstStep ? 'send-immediately' : 'send-at'),
  );

  const handleChange = (value: 'send-immediately' | 'send-at') => {
    setSendStrategy(value);
    onSendStrategyChange?.(value);
  };

  if (readOnly || !canEditDelay) {
    return (
      <ReadOnlyDelay
        stepNumber={stepNumber}
        delay={delay}
        sendAt={sendAt}
        businessDays={businessDays}
      />
    );
  }

  return (
    <>
      {isFirstStep && canEditSendStrategy && (
        <Select
          css={{ width: 200 }}
          leftIcon={<Icon name='clock' css={{ ml: -8, mr: 4 }} />}
          onChange={handleChange}
          onBlur={onBlur}
          value={sendStrategy}
        >
          <SelectItem value='send-immediately'>Send immediately</SelectItem>
          <SelectItem value='send-at'>Send at</SelectItem>
        </Select>
      )}
      {sendStrategy === 'send-at' ? children : null}
    </>
  );
};
