import { Box, Flex, Text } from '@betterleap/ui';
import React from 'react';

interface InfoCardProps extends React.ComponentProps<typeof Flex> {
  title: string;
  description: string;
  backgroundColor?: string;
  image?: JSX.Element;
  marginTop?: number;
  maxHeight?: string;
}

const InfoCard = ({
  title,
  description,
  backgroundColor,
  image,
  marginTop,
  maxHeight,
  css,
}: InfoCardProps) => (
  <Flex
    align='center'
    vertical
    css={{
      ...css,
      position: 'relative',
    }}
  >
    <Box
      css={{
        height: '50%',
        maxHeight: maxHeight || 'none',
        background: backgroundColor || 'none',
        width: '100%',
        borderRadius: '$xl',
      }}
    >
      {image}
    </Box>
    <Text
      css={{
        mt: marginTop || 46,
        mb: 4,
        fontWeight: '$semibold',
        color: '$neutral-blue-700',
        textAlign: 'center',
        whiteSpace: 'pre',
      }}
    >
      {title}
    </Text>
    <Text
      css={{
        textAlign: 'center',
        fontSize: '$sm',
        color: '$neutral-blue-500',
        lineHeight: '$2xl',
      }}
    >
      {description}
    </Text>
  </Flex>
);

export default InfoCard;
