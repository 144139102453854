import { Flex, Text } from '@betterleap/ui';
import { SearchContactProjects } from '@betterleap/client';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import routeNames from '../../../../constants/routeNames';
import { SourcingResultSection } from './SourcingResult.elements';

interface SectionProps {
  projects: SearchContactProjects[];
}
interface EntryProps {
  entry: SearchContactProjects;
}

export const SourcingResultProjects = ({ projects }: SectionProps) => {
  const [containerWidth, setContainerWidth] = useState(0);
  useEffect(() => {
    const container = document.getElementById('search_result');

    setContainerWidth(container?.offsetWidth || 0);
  }, []);

  const collapsedDisplayMax = useMemo(() => {
    let counter = 0;
    let sum = 0;

    const maxContainerWidth = containerWidth * 2 - 200;

    for (let i = 0; i < projects.length; i++) {
      const newSum = (projects[i]?.name?.length || 0) * 5 + 20;

      if (newSum + sum >= maxContainerWidth) {
        return counter;
      } else {
        sum += newSum;
      }
      counter += 1;
    }

    return counter;
  }, [containerWidth]);

  return (
    <SourcingResultSection
      title='Projects'
      icon='collection'
      elements={projects}
      elementKey='name'
      constructElement={(e) => <ProjectEntry entry={e} />}
      direction='row'
      collapsingType='tooltip'
      collapsedDisplayMax={collapsedDisplayMax}
      css={{ maxWidth: '700px' }}
    />
  );
};

const ProjectEntry = ({ entry }: EntryProps) => {
  return (
    <Link to={routeNames.project({ id: entry.id })}>
      <Flex
        key={entry.name}
        css={{
          gap: 12,
          backgroundColor: '$purple-200',
          paddingLeft: '$8',
          paddingRight: '$8',
          borderRadius: '$base',
        }}
      >
        <Text css={{ fontSize: '$xs', color: '$purple-700' }}>
          {entry.name}
        </Text>
      </Flex>
    </Link>
  );
};
