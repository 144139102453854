import { Contact, ProjectWithStatsDto } from '@betterleap/client';
import { useModal } from '@betterleap/ui';
import EditContactModal, {
  EditContactModalProps,
} from 'components/modules/Modal/EditContactModal/EditContactModal';

export const useUpdateContact = ({
  enrichmentMode,
  onContactUpdated,
}: {
  enrichmentMode: ProjectWithStatsDto.contactEmailEnrichmentMode;
  onContactUpdated: () => void;
}) => {
  const openModal = useModal<EditContactModalProps>(EditContactModal);
  const updateContact = async (contact: Partial<Contact>) => {
    await openModal({ contact, enrichmentMode, onContactUpdated });
  };
  return { updateContact };
};
