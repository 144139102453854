import { Box, Button, Flex, Text } from '@betterleap/ui';
import { LoadingArea, useMe } from '@betterleap/shared';
import { useLogout } from 'hooks/useLogout';
import GetTheChromeExtension from '../../elements/GetTheChromeExtension/GetTheChromeExtension';
import EmailSenderSettings from './EmailSenderSettings';
import EmailSendingSettings from './EmailSendingSettings';
import IntegrationsSection from './IntegrationsSection';
import { OtherSettings } from './OtherSettings';

const SettingsTemplate = () => {
  const me = useMe();
  const { logout } = useLogout();

  if (!me.user || me.isLoading) {
    return <LoadingArea isLoading />;
  }

  return (
    <Box css={{ position: 'relative', padding: 32 }}>
      {!me.user.hasAddedChromeExtension && <GetTheChromeExtension />}
      <Text
        as='h1'
        css={{
          fontWeight: '$medium',
          fontSize: '$3xl',
          lineHeight: '$3xl',
          color: '$neutral-blue-900',
          mb: 32,
        }}
      >
        Settings
      </Text>
      <Box
        css={{
          width: '100%',
          background: 'White',
          px: 24,
          py: 38,
          borderRadius: '$lg',
          mb: 48,
        }}
      >
        <Text
          as='h2'
          css={{ fontWeight: '$semibold', fontSize: '$lg', lineHeight: '$3xl' }}
        >
          {me.user.firstName} {me.user.lastName}
        </Text>
        <Text
          css={{
            fontWeight: '$normal',
            fontSize: '$sm',
            color: '$neutral-blue-700',
            lineHeight: '$xl',
          }}
        >
          {me.user.email}
        </Text>
      </Box>
      <Text
        as='h2'
        css={{
          fontWeight: '$semibold',
          fontSize: '$lg',
          lineHeight: '$3xl',
          color: '$neutral-blue-900',
          mb: 24,
        }}
      >
        Connections
      </Text>
      {me.user.organization && (
        <IntegrationsSection organization={me.user.organization} />
      )}
      <EmailSenderSettings />
      <EmailSendingSettings />
      <OtherSettings user={me.user} />
      <Flex vertical>
        <Flex
          css={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            mediaMd: {
              mt: '5rem',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
        >
          <Text
            css={{
              color: '#6B7280',
              fontSize: '$sm',
              fontWeight: 400,
              mb: 16,
              mediaMd: { mb: 'unset' },
            }}
          >
            To delete your account, send an email request to
            support@betterleap.com with subject line &quot;Delete my
            account&quot;
          </Text>
          <Button
            onClick={logout}
            css={{ color: '$blue-600' }}
            variant='headless'
          >
            Logout
          </Button>
        </Flex>
        <Box
          css={{
            height: 1,
            width: '100%',
            mt: 24,
            mb: 24,
          }}
        />
      </Flex>
    </Box>
  );
};
export default SettingsTemplate;
