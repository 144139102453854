/* eslint-disable jsx-a11y/tabindex-no-positive */
import { forwardRef } from 'react';
import { Button, ButtonProps } from '@betterleap/ui';

export const SuggestionButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ css, ...rest }, ref) => (
    <Button
      css={{
        backgroundColor: '$neutral-blue-100',
        border: '1px solid $neutral-blue-200',
        fontSize: '$xs',
        borderRadius: '$2xl',
        whiteSpace: 'break-spaces',
        textAlign: 'start',
        color: '$neutral-blue-700',
        padding: '4px 8px',
        mb: 8,
        ...css,
      }}
      variant='headless'
      ref={ref}
      {...rest}
    />
  ),
);

SuggestionButton.displayName = 'SuggestionButton';
