import {
  Flex,
  FlexProps,
  IconButton,
  Menu,
  MenuArrow,
  MenuContent,
  MenuTrigger,
  Text,
} from '@betterleap/ui';

export interface HeaderWithFilterProps extends FlexProps {
  header: string;
  active: boolean;
}

export const HeaderWithFilter = ({
  header,
  active,
  children,
  css,
  ...rest
}: HeaderWithFilterProps) => {
  return (
    <Flex css={{ gap: 4, ...css }} {...rest}>
      <Text size='xs' css={{ color: '$gray-500', textTransform: 'uppercase' }}>
        {header}
      </Text>
      <Menu closeOnSelect={false}>
        <MenuTrigger asChild>
          <IconButton
            size='xs'
            label='open menu'
            name='filter'
            color={active ? '$blue-600' : '$gray-500'}
          />
        </MenuTrigger>
        <MenuContent align='end' css={{ minWidth: 200 }}>
          <MenuArrow offset={14} />
          {children}
        </MenuContent>
      </Menu>
    </Flex>
  );
};
