import { Box, Flex, Icon } from '@betterleap/ui';
import config from 'lib/config';
import logo from '../../../assets/images/betterleap-logo-black.svg';
import { LandingLink } from './LandingLink';

const LandingHeader = (): JSX.Element => {
  const scrollToValueProps = () => {
    const element = document.getElementById('value-props');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box css={{ px: 24, mediaLg: { px: 80 } }}>
      <Box
        css={{
          maxWidth: 1320,
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Flex justify='between' css={{ py: 16, mediaLg: { py: 24 } }}>
          <LandingLink href={`http://betterleap.com`}>
            <Box as='img' src={logo} />
          </LandingLink>
          <Flex css={{ gap: 32 }}>
            <LandingLink
              css={{ display: 'none', mediaLg: { display: 'block' } }}
              onClick={scrollToValueProps}
            >
              Features
            </LandingLink>
            <LandingLink
              css={{ display: 'none', mediaLg: { display: 'block' } }}
              href={`${config.url}/signin`}
            >
              Sign In
            </LandingLink>
            <LandingLink
              href={`${config.url}/signup`}
              css={{
                px: 24,
                py: 16,
                border: '1px solid $neutral-blue-1000',
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                borderRadius: 51,
                hover: {
                  textDecoration: 'none',
                },
              }}
            >
              Try For Free{' '}
              <Icon
                css={{ transform: 'rotate(45deg)', ml: 8 }}
                name='arrow-up'
                size={18}
              />
            </LandingLink>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default LandingHeader;
