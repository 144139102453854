import {
  ApiResponse,
  Contact,
  Organization,
  Project,
  ProjectWithStatsDto,
  ShareProjectDto,
  UpdateProjectShareDto,
  User,
} from '@betterleap/client';
import {
  getProjectSubject,
  ProjectTypeIcon,
  Types,
  useMe,
} from '@betterleap/shared';
import {
  BackButton,
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  showToast,
  Spinner,
  Tab,
  TabContent,
  Tabs,
  TabsList,
  Text,
  Tooltip,
  useModal,
} from '@betterleap/ui';
import { useBanner } from 'components/elements/Banner/Banner';
import { SearchInput } from 'components/elements/SearchInput/SearchInput';
import { formatNumber } from 'functions/formatNumber';
import useAnalytics from 'hooks/analytics';
import { useConnectEmail } from 'hooks/useConnectEmail';
import set from 'lodash/set';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useAtomValue } from 'jotai';
import routeNames from '../../../constants/routeNames';
import { useScrollTop } from '../../../hooks/useScrollTop';
import api from '../../../lib/api';
import { apiClient } from '../../../lib/apiClient';
import InviteExternalModal from '../../modules/Modal/InviteExternalModal/InviteExternalModal';
import { AddProjectTagMenu } from './AddProjectTagMenu';
import { AtsSyncToggle } from './AtsSyncToggle';
import { IntegrationButton } from './IntegrationButton';
import {
  getNlsParamsFromSearchParams,
  NaturalLanguageFilter,
  nlsLoadingState,
} from './NaturalLanguageFilter';
import { ProjectContacts } from './ProjectContacts';
import { ProjectLayout } from './ProjectLayout';
import { ProjectNameEditor } from './ProjectNameEditor';
import { ProjectTagList } from './ProjectTagList';
import { SourcingBanner } from './SourcingBanner';
import { AUTOSOURCING_STATUS, SourcingCopilotTab } from './SourcingCopilotTab';
import { useProjectDrawerCollection } from './useProjectDrawerCollection';
import { useProjectContactsFilterParams } from './useProjectContactsFilterParams';
import { AddCandidateByLinkedIn } from './AddCandidateByLinkedIn';
import { useAddContacts } from './Hooks/useAddContacts';

interface ProjectTemplateSearchParms {
  page?: string;
  pageSize?: string;
  'contact-project'?: string;
}

const ProjectTemplate = () => {
  const { search, state: locationState } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { contactProjectQuery } = useProjectContactsFilterParams();
  const { banner } = useBanner();
  const isNlsSearchLoading = useAtomValue(nlsLoadingState);

  const queryClient = useQueryClient();
  const queryParams: ProjectTemplateSearchParms = qs.parse(search.slice(1));
  const { data: organization, refetch: refetchOrganization } = useQuery(
    ['organization'],
    () => apiClient.organization.getOrganization(),
  );
  const navigate = useNavigate();
  const me = useMe();
  const { id } = useParams<{ id: string }>();
  const isRecruiter = me.user?.role === User.role.RECRUITER;
  const searchQuery = searchParams.get('search') ?? '';
  const { track } = useAnalytics();
  const [generatingMore, setGeneratingMore] = useState(false);

  const handleConnectEmail = useConnectEmail();

  const { data: sendersResponse, isLoading: isSendersLoading } = useQuery(
    ['get_senders'],
    () => api.fetch<{ data: Types.Sender[] }>('get_senders'),
  );

  const hasSenders = !!sendersResponse?.data?.data?.filter(
    (s) => s.status !== 'Error',
  ).length;

  const {
    data: projectResponse,
    isLoading: isProjectLoading,
    refetch: refetchStats,
  } = useQuery(
    ['get_project_with_stats', id],
    () =>
      api.fetch<{ data: ProjectWithStatsDto }>('get_project_with_stats', {
        projectId: id,
      }),
    {
      refetchInterval: 10000,
    },
  );

  const project = projectResponse?.data?.data;
  const hasSequence = !!project?.sequenceId;
  const viewOnly = project?.organizationId !== me.user?.organizationId;
  const viewOnlyChecklist = document.getElementById('checklist');
  const isBDProject =
    project?.contactEmailEnrichmentMode ===
    Project.contactEmailEnrichmentMode.PROFESSIONAL;
  const subject = getProjectSubject(isBDProject);
  const featureEnabledNaturalLanguageFilteringProjectContacts =
    organization?.data?.features
      ?.enabledNaturalLanguageFilteringProjectContacts ?? false;

  if (viewOnlyChecklist) {
    if (viewOnly) viewOnlyChecklist.style.bottom = '20px';
    else viewOnlyChecklist.style.bottom = '80px';
  }

  const { ref, scrollTop } = useScrollTop({
    parent: true,
  });

  const nlsParams = getNlsParamsFromSearchParams(searchParams);
  const hasNlsParams = nlsParams && Object.keys(nlsParams).length > 0;

  const {
    data: projectContacts,
    isLoading: isProjectContactsLoading,
    isFetching: isProjectContactsFetching,
    isPreviousData: isPreviousProjectContactsData,
    refetch: refetchContacts,
  } = useQuery(
    ['get_project_contacts', id, queryParams, nlsParams],
    () =>
      apiClient.contactSearch.searchProject({
        requestBody: {
          ...contactProjectQuery,
          statuses: JSON.stringify(contactProjectQuery.statuses),
          search: searchParams.get('search') ?? '',
          ...nlsParams,
        },
        page: Number.parseInt(queryParams.page ?? '1', 10),
        pageSize: Number.parseInt(queryParams.pageSize ?? '100', 10),
      }),
    {
      keepPreviousData: true,
      refetchInterval: hasNlsParams ? undefined : 10000,
    },
  );

  const {
    data: projectSuggestedContacts,
    isRefetching: isProjectSuggestedContactsRefetching,
    refetch: refetchProjectSuggestedContacts,
  } = useQuery(
    ['get_project_suggested_contacts', id],
    () =>
      apiClient.projectSuggestedContact.find({
        projectId: id as string,
        status: 'Active',
        pageSize: 50,
      }),
    {
      enabled: !isBDProject,
      onSuccess: (data) => {
        if (data?.data?.length) {
          setGeneratingMore(false);
        }
      },
    },
  );

  const isLoadingTable =
    isProjectContactsLoading ||
    isNlsSearchLoading ||
    (isPreviousProjectContactsData && isProjectContactsFetching);

  const refreshData = () => {
    refetchContacts();
    refetchStats();
  };

  const [activeTab, setActiveTab] = useState<string>(
    searchParams.get('tab') ?? 'contacts',
  );

  const { data: canUploadCsvData } = useQuery('can_upload_csv', () =>
    apiClient.contact.canUserUploadCsvContacts(),
  );

  // Gets project share by shared with org.
  const { data: sharedProjectResponse } = useQuery([id, queryParams], () =>
    apiClient.projectShare.getProjectSharedWithOrganization({
      projectId: id as string,
    }),
  );

  useProjectDrawerCollection({
    activeTab,
    projectContacts: projectContacts?.data,
    projectSuggestedContacts: projectSuggestedContacts?.data,
    sharedProjectId: sharedProjectResponse?.data?.projectId,
  });

  const recordSharedProjectView = useMutation(
    (args: { projectShareId: string; requestBody: UpdateProjectShareDto }) =>
      apiClient.projectShare.updateProjectShare(args),
  );

  useEffect(() => {
    if (recordSharedProjectView.isIdle && sharedProjectResponse?.data) {
      recordSharedProjectView.mutate({
        projectShareId: sharedProjectResponse?.data.id,
        requestBody: {
          viewedAt: new Date().toDateString(),
        },
      });
    }
  }, [sharedProjectResponse, recordSharedProjectView]);

  const onClick = async () => {
    if (!hasSenders && !hasSequence) {
      handleConnectEmail();
      return;
    }

    navigate(
      hasSequence
        ? routeNames.sequenceDetail({ id: project.sequenceId })
        : `${routeNames.createSequence({
            projectId: id as string,
          })}`,
    );
  };

  const handleSourcingCopilotClick = () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Sourcing Copilot Tab',
      buttonLocation: 'Project Details',
    });
  };

  const handleSearchInputFocus = () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Search',
      buttonLocation: 'Project Details',
    });
  };

  const recruiterSharedProjectSlack = useMutation(
    (data: { email: string; firstName: string; lastName: string }) =>
      apiClient.slack.recruiterSharedProject({
        requestBody: { ...data, projectName: project?.name as string },
      }),
  );

  const shareProject = useMutation(
    (data: ShareProjectDto) =>
      apiClient.project.shareProject({
        requestBody: { ...data, projectId: project?.id as string },
      }),
    {
      onSuccess: async () => {
        refetchStats();
      },
    },
  );

  const removeTag = useMutation(
    (data: { id: string }) =>
      apiClient.projectTag.removeTag({
        projectId: id as string,
        tagId: data.id,
      }),
    {
      onMutate: async (data: { id: string }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['get_project_with_stats', id]);

        // Snapshot the previous value
        const previousProjectResponse = queryClient.getQueryData<
          ApiResponse<{ data: ProjectWithStatsDto }>
        >(['get_project_with_stats', id]);

        // Optimistically update to the new value
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        queryClient.setQueryData(['get_project_with_stats', id], (old: any) => {
          if (!old) {
            return undefined;
          }

          const newProject: ApiResponse<{ data: ProjectWithStatsDto }> = {
            ...old,
          };
          set(
            newProject,
            'data.data.tags',
            newProject.data.data?.tags.filter((tag) => tag.id !== data.id),
          );

          return newProject;
        });

        // Return a context object with the snapshotted value
        return { previousProjectResponse };
      },
      onError: (err, data, context) => {
        queryClient.setQueryData(
          ['get_project_with_stats', id],
          context?.previousProjectResponse,
        );

        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to remove tag. Please try again.',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['get_project_with_stats', id]);
      },
    },
  );

  const handleRemoveTag = (tagId: string) => {
    removeTag.mutate({
      id: tagId,
    });
  };

  const handleShare = async (data: ShareProjectDto) => {
    if (data) {
      try {
        await shareProject.mutateAsync({
          ...data,
        });
        if (me.user?.role === User.role.RECRUITER)
          await recruiterSharedProjectSlack.mutateAsync({
            ...data,
          });
        showToast({
          title: 'Invite sent!',
          description: 'Your client should receive an invite shortly.',
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (
          error?.body.error.data?.message === 'This user shares your domain.' ||
          error.body.error.data.message ===
            'Sharing projects with other recruiting firms is not supported today.' ||
          error.status === 422
        ) {
          showToast({
            variant: 'danger',
            title: 'Something went wrong!',
            description: error.body.error.data.message,
          });

          return;
        }

        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to send invitation. Please try again.',
        });
      }
    }
  };

  const openShareProjectModal = useModal<
    {
      title: string;
      message: string;
      excludePersonalEmails: boolean;
      onSubmit: typeof handleShare;
    },
    ShareProjectDto
  >(InviteExternalModal);

  const handleShareProject = async () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Share Project',
      buttonLocation: 'project detail',
    });

    // prettier-ignore
    (await openShareProjectModal({
      title: 'Share project',
      message: 'Share your project with clients so they can add notes to candidates, view your sequence and conversion metrics.',
      excludePersonalEmails: false,
      onSubmit: handleShare,
    })) as ShareProjectDto;
  };

  const { addContacts: handleAddCandidatesClick } = useAddContacts({
    project,
    onContactsAdded: refetchStats,
  });

  // NOTE: We can edit this loader as we progress further
  if (isProjectLoading || isSendersLoading)
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );

  if (!project) {
    return <Navigate to={routeNames.projects()} />;
  }

  const sourcingCopilotTabName = `Recommended Matches${
    projectSuggestedContacts?.data?.length ? ' ' : ' ✨'
  }`;

  let autoSourcingState: AUTOSOURCING_STATUS = AUTOSOURCING_STATUS.INACTIVE;

  if (
    (!project.hasSuggestedSearch || generatingMore) &&
    project.contactsEnrichedForCopilot >= 10
  ) {
    autoSourcingState = AUTOSOURCING_STATUS.PENDING;
  }

  if (project.hasSuggestedSearch && !generatingMore) {
    autoSourcingState = AUTOSOURCING_STATUS.ACTIVE;
  }

  const contactsLabel =
    project.contactEmailEnrichmentMode ===
    Contact.contactEmailEnrichmentMode.PROFESSIONAL
      ? 'Prospects'
      : 'Candidates';

  const minHeight = banner ? 'calc(100vh - 52px)' : '100vh';

  return (
    <Box css={{ minHeight }} ref={ref as React.RefObject<HTMLDivElement>}>
      <ProjectLayout
        css={{
          minHeight,
          pl: 32,
          pt: 32,
          pb: 70,
        }}
      >
        <ProjectLayout.Header
          css={{
            pr: 32,
          }}
        >
          <BackButton
            route='Back to All Projects'
            onClick={() => {
              // if coming from the projects page, return to the same tab and page
              const state = locationState as { from: Location } | undefined;
              if (state?.from?.pathname === routeNames.projects()) {
                navigate(-1);
              } else {
                navigate(routeNames.projects());
              }
            }}
          />
          <Flex
            css={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              mediaSm: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              },
            }}
          >
            <Flex css={{ alignItems: 'flex-start' }}>
              <Flex
                css={{
                  fontSize: '$2xl',
                  lineHeight: '$3xl',
                  fontWeight: '$semibold',
                  color: '$neutral-blue-900',
                  maxWidth: 640,
                  mb: 16,
                  mr: 8,
                  mediaSm: { mb: 'unset' },
                }}
              >
                <ProjectNameEditor
                  projectId={id || ''}
                  projectPrevName={project.name}
                  viewOnly={viewOnly}
                  refetchStats={refetchStats}
                />
                {project.contactEmailEnrichmentMode ===
                  Contact.contactEmailEnrichmentMode.PROFESSIONAL && (
                  <Box
                    css={{
                      p: 4,
                      pb: 5,
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }}
                  >
                    <ProjectTypeIcon
                      mode={project.contactEmailEnrichmentMode}
                    />
                  </Box>
                )}
                {project.roleId && (
                  <Tooltip
                    content={
                      <Text
                        css={{ textAlign: 'center', color: '$text-inverse' }}
                        inherit
                      >
                        ATS: {project?.atsSource}
                        <br />
                        Role: {project?.roleTitle}
                        <br />
                        ATS Sync: {project.projectRoleAutoSync ? 'ON' : 'OFF'}
                      </Text>
                    }
                  >
                    <Icon
                      color={
                        project.projectRoleAutoSync
                          ? '$green-600'
                          : '$neutral-blue-600'
                      }
                      name='sync-complete'
                      css={{ display: 'inline-block', ml: 6 }}
                    />
                  </Tooltip>
                )}
              </Flex>
            </Flex>
            <Flex css={{ gap: 8 }}>
              {viewOnly && (
                <Flex css={{ gap: 8, mr: 14 }}>
                  <Icon name='eye' color='$primary' size={14} />
                  <Text css={{ color: '$blue-600', fontSize: '$sm' }}>
                    View only
                  </Text>
                </Flex>
              )}
              {me.user?.organization?.features
                ?.enabledAddToProjectByLinkedIn && <AddCandidateByLinkedIn />}
              {!featureEnabledNaturalLanguageFilteringProjectContacts && (
                <SearchInput
                  query={searchQuery}
                  defaultCollapsed
                  onFocus={handleSearchInputFocus}
                />
              )}
              {!viewOnly && isRecruiter && (
                <IconButton
                  size='base'
                  variant='darkGray'
                  onClick={handleShareProject}
                  name='share'
                  label='share project'
                />
              )}
              {!viewOnly && project.projectRoleId && (
                <AtsSyncToggle
                  projectRoleId={project.projectRoleId}
                  autoSync={project.projectRoleAutoSync ?? false}
                />
              )}
              {organization?.data && (
                <IntegrationButton
                  organization={organization?.data}
                  project={project}
                  onSomethingHappened={() => {
                    refetchOrganization();
                    refetchStats();
                  }}
                />
              )}
              {!viewOnly && (
                <Tooltip
                  content={
                    canUploadCsvData?.data.canUpload
                      ? 'Upload CSV'
                      : 'You have reached your monthly upload limit.'
                  }
                >
                  <Button
                    variant='secondary'
                    onClick={handleAddCandidatesClick}
                    dataCy='Add candidates-button'
                    css={{ height: 40 }}
                    disabled={!canUploadCsvData?.data.canUpload}
                  >
                    Add {subject({ cap: true })}
                  </Button>
                </Tooltip>
              )}
              {!viewOnly ? (
                <Button
                  variant='primary'
                  onClick={onClick}
                  dataCy='View Sequence-button'
                >
                  {hasSequence ? 'View Sequence' : 'Create Sequence'}
                </Button>
              ) : (
                hasSequence && (
                  <Button
                    variant='secondary'
                    onClick={onClick}
                    css={{ height: 40 }}
                  >
                    View Sequence
                  </Button>
                )
              )}
            </Flex>
          </Flex>
          <Flex
            css={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 12,
              mb: 40,
            }}
          >
            <Flex
              css={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 16,
              }}
            >
              {!sharedProjectResponse?.data && (
                <Flex
                  css={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    mediaSm: {
                      flexDirection: 'row',
                      alignItems: 'center',
                    },
                    gap: 8,
                  }}
                >
                  <Text
                    as='p'
                    css={{
                      fontSize: '$sm',
                      fontWeight: '$normal',
                      color: '#6B7280',
                    }}
                  >
                    Created by {project.creatorFirstName}{' '}
                    {project.creatorLastName}
                  </Text>
                  <Text
                    as='p'
                    css={{
                      fontSize: '$sm',
                      fontWeight: '$normal',
                      color: '#6B7280',
                      display: 'none',
                      mediaSm: {
                        display: 'block',
                      },
                    }}
                  >
                    |
                  </Text>
                  <Text
                    as='p'
                    css={{
                      fontSize: '$sm',
                      fontWeight: '$normal',
                      color: '#6B7280',
                    }}
                  >
                    Created on {moment(project?.projectCreatedAt).format('ll')}
                  </Text>
                </Flex>
              )}
            </Flex>
            {(project.roleId || sharedProjectResponse?.data?.user) && (
              <Flex css={{ gap: 16 }}>
                {(sharedProjectResponse?.data?.user?.firstName ||
                  sharedProjectResponse?.data?.user?.lastName) && (
                  <Flex css={{ gap: 4 }}>
                    <Text
                      as='p'
                      css={{
                        fontWeight: '$normal',
                        color: '#6B7280',
                      }}
                    >
                      Shared by:
                    </Text>
                    <Link
                      to={routeNames.companyRecruiterProfile({
                        id: sharedProjectResponse?.data?.user?.recruiter
                          ?.id as string,
                      })}
                      className='cursor-pointer text-blue-500'
                    >
                      {sharedProjectResponse?.data?.user?.firstName}{' '}
                      {sharedProjectResponse?.data?.user?.lastName}
                    </Link>
                  </Flex>
                )}
              </Flex>
            )}
            {!viewOnly && (
              <Flex>
                <ProjectTagList
                  tags={project.tags}
                  onRemoveTag={
                    !viewOnly
                      ? (tagId) => {
                          handleRemoveTag(tagId);
                        }
                      : undefined
                  }
                />
                <AddProjectTagMenu
                  value={project?.tags?.map((tag) => tag.id)}
                  onCreateTag={() => {
                    refetchStats();
                  }}
                  onChange={() => {
                    refetchStats();
                  }}
                />
              </Flex>
            )}
          </Flex>
          {autoSourcingState === AUTOSOURCING_STATUS.INACTIVE && !viewOnly && (
            <SourcingBanner
              style={{ marginBottom: hasSequence ? 24 : 40 }}
              enrichedCount={project.contactsEnrichedForCopilot}
              onUploadCSV={handleAddCandidatesClick}
            />
          )}
        </ProjectLayout.Header>
        <ProjectLayout.Main
          css={{
            pr: 32,
          }}
        >
          <Tabs
            css={{ height: '100%' }}
            value={activeTab}
            tabStyle='underline'
            onValueChange={(v) => {
              searchParams.set('tab', v);

              setSearchParams(searchParams);

              setActiveTab(v);
            }}
          >
            <TabsList css={{ mb: 20 }}>
              <Tab
                value='contacts'
                css={{ fontWeight: '$medium', fontSize: '$xl' }}
              >
                {contactsLabel}
                {!!project.contactsTotalCount && (
                  <Badge variant='violet' size='sm'>
                    {formatNumber(project.contactsTotalCount)}
                  </Badge>
                )}
              </Tab>
              {!isBDProject && !viewOnly && (
                <Tab
                  value='recommended-matches'
                  css={{ fontWeight: '$medium', fontSize: '$xl' }}
                  onClick={handleSourcingCopilotClick}
                >
                  {sourcingCopilotTabName}
                  {!!projectSuggestedContacts?.meta.count && (
                    <Badge variant='violet' size='sm'>
                      {projectSuggestedContacts?.meta.count}
                    </Badge>
                  )}
                </Tab>
              )}
            </TabsList>
            <TabContent value='contacts' css={{ flex: 1 }}>
              <Box
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                {featureEnabledNaturalLanguageFilteringProjectContacts && (
                  <NaturalLanguageFilter />
                )}
                <ProjectContacts
                  project={project}
                  projectContacts={projectContacts}
                  isInitiallyLoading={isProjectContactsLoading}
                  isLoadingTable={isLoadingTable}
                  refreshData={refreshData}
                  scrollTop={scrollTop}
                />
              </Box>
            </TabContent>

            <TabContent value='recommended-matches' css={{ flex: 1 }}>
              <Box
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <SourcingCopilotTab
                  isSourcingEnabled={
                    organization?.data?.features?.enabledSourcing ||
                    organization?.data.tier === Organization.tier.FREE_TRIAL
                  }
                  isAutoSourcing={autoSourcingState}
                  suggestedContacts={projectSuggestedContacts?.data}
                  isFetching={isProjectSuggestedContactsRefetching}
                  handleAddCandidatesClick={handleAddCandidatesClick}
                  onGenerateMore={() => setGeneratingMore(true)}
                  projectId={id as string}
                  onChange={() => {
                    refetchProjectSuggestedContacts();
                    refetchContacts();
                  }}
                />
              </Box>
            </TabContent>
          </Tabs>
        </ProjectLayout.Main>
      </ProjectLayout>
    </Box>
  );
};

export default ProjectTemplate;
