import { Box, Flex, FlexProps } from '@betterleap/ui';

const Section = ({ css, children, ...props }: FlexProps): JSX.Element => {
  return (
    <Flex
      css={{
        px: 24,
        py: 64,
        ...css,
        mediaLg: {
          px: 80,
          py: 80,
          ...css?.mediaLg,
        },
      }}
      {...props}
    >
      <Box
        css={{
          maxWidth: 1320,
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {children}
      </Box>
    </Flex>
  );
};

export default Section;
