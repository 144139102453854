/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Badge,
  Box,
  BoxProps,
  Button,
  Col,
  Divider,
  Editor,
  EDITOR_MODULES,
  EditorProps,
  Flex,
  Row,
  useControlledState,
} from '@betterleap/ui';
import { RefObject, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import ReactQuill, { Quill } from 'react-quill';
import { Step } from '@betterleap/client';
import { StepInput } from './components/StepInput';
import { StepTypeIndicator } from './components/StepTypeIndicator';
import { StepEditorValue } from './StepEditorTypes';

export interface BaseStepEditorProps
  extends Omit<BoxProps, 'defaultValue' | 'onChange' | 'value'> {
  /**
   * The order of the step.
   */
  stepNumber: number;
  value?: StepEditorValue;
  defaultValue?: StepEditorValue;
  onChange?: (value: StepEditorValue) => void;
  onRemove?: () => void;
  error?: Record<string, FieldError>;
  readOnly?: boolean;
  removable?: boolean;
  canEditDelay?: boolean;
  canEditSubject?: boolean;
  canUseVariables?: boolean;
  type: Step.type;
  canResizeImage?: boolean;
  canUploadImage?: boolean;
  onBlur?: () => void;
  onFileUpload?: (file: File) => Promise<string | null>;
  quillRef?: React.MutableRefObject<ReactQuill | undefined>;
  children?: React.ReactNode;
  modules?: EditorProps['modules'];
  headerComponent?: React.ReactNode;
}

const variables = [
  '{{dayOfWeek}}',
  '{{contact.firstName}}',
  '{{contact.lastName}}',
  '{{fullName}}',
  '{{contact.currentTitle}}',
  '{{contact.currentCompany}}',
  '{{contact.previousCompany}}',
  '{{contact.school}}',
];

export const BaseStepEditor = ({
  value,
  defaultValue,
  stepNumber,
  onChange,
  onRemove,
  error,
  readOnly,
  removable = true,
  onBlur,
  modules,
  onFileUpload,
  canEditSubject,
  canUploadImage,
  canResizeImage,
  canUseVariables = true,
  quillRef,
  children,
  headerComponent,
  type,
  ...props
}: BaseStepEditorProps) => {
  const defaultQuillRef = useRef<ReactQuill>();
  const editorRef = quillRef ?? defaultQuillRef;
  const subjectFocusRef = useRef(false);
  const subjectRef = useRef<HTMLInputElement>(null);

  const [stateValue, setStateValue] = useControlledState(
    value,
    defaultValue,
    onChange,
  );

  const handleEditorChange = (newValue: string) => {
    setStateValue({
      ...stateValue,
      body: newValue === '<p><br></p>' ? '' : newValue,
    });
  };

  const handleSubjectChange = (newValue: string) => {
    setStateValue({
      ...stateValue,
      subject: newValue,
    });
  };

  const handleVariableClick = (variable: string) => {
    // if focus is on subject add variable to subject
    if (subjectFocusRef.current) {
      const selectionStart = subjectRef.current?.selectionStart || 0;
      const selectionEnd = subjectRef.current?.selectionEnd || 0;

      subjectRef.current?.setRangeText(
        variable,
        selectionStart,
        selectionEnd,
        'end',
      );

      const subjectValue = stateValue?.subject ?? '';

      setStateValue({
        ...stateValue,
        subject:
          subjectValue.substring(0, selectionStart) +
          (subjectValue.length ? ` ${variable}` : variable) +
          subjectValue.substring(selectionEnd, subjectValue.length),
      });

      subjectRef.current?.focus();
      // else add to body editor
    } else {
      const quill = editorRef.current?.getEditor();
      const contentLength = quill?.getContents()?.length() ?? 0;
      const range = quill?.getSelection();

      // the position to insert text at: either where the cursor is (range) or the end of current editor contents (contentLength).
      const position = range ? range.index : contentLength - 1;

      // insert text. if there is no cursor position and there is content in the editor, add a space to the variable.
      quill?.insertText(
        position,
        !range && contentLength > 1 ? ` ${variable}` : variable,
      );
    }
  };

  const getEditorToolbar = () => {
    let toolbar;
    if (!readOnly) {
      toolbar = EDITOR_MODULES.toolbar;
    }

    if (!readOnly && canUploadImage) {
      toolbar = {
        ...EDITOR_MODULES.toolbar,
        container: [...EDITOR_MODULES.toolbar.container, ['link', 'image']],
      };
    } else if (!readOnly && !canUploadImage) {
      toolbar = {
        ...EDITOR_MODULES.toolbar,
        container: [...EDITOR_MODULES.toolbar.container, ['link']],
      };
    }

    return toolbar;
  };

  return (
    <Box
      css={{
        backgroundColor: 'white',
        boxShadow: '$base',
        borderRadius: '$lg',
        display: 'flex',
        flexDirection: 'column',
      }}
      {...props}
    >
      <Row
        css={{
          minHeight: 50,
          px: 12,
          py: 16,
          gridTemplateColumns: 'auto repeat(11, 1fr)',
          overflowX: 'auto',
          mediaLg: {
            py: 8,
          },
        }}
      >
        <Col
          span={{
            '@md': 12,
          }}
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            mediaLg: {
              gridColumn: 'auto',
            },
          }}
        >
          <Badge size='sm' variant='gray'>
            Step {stepNumber}
          </Badge>
          <StepTypeIndicator type={type} />
          <Divider
            css={{ mediaLg: { minHeight: 50 } }}
            orientation='vertical'
          />
        </Col>
        <Col
          span={{
            '@initial': 12,
            '@lg': 10,
          }}
        >
          {headerComponent}
        </Col>
        {removable && !readOnly && (
          <Col
            span={{
              '@initial': 12,
              '@lg': 1,
            }}
            css={{
              display: 'flex',
              justifyContent: 'flex-start',
              mediaLg: {
                justifyContent: 'flex-end',
              },
            }}
          >
            <Button
              variant='ghost'
              size='sm'
              onClick={() => {
                onRemove?.();
              }}
            >
              Remove Step
            </Button>
          </Col>
        )}
      </Row>
      {!readOnly && canUseVariables && (
        <Flex
          css={{
            px: 12,
            py: 20,
            gap: 6,
            overflow: 'auto',
            borderTop: '1px solid $border-light',
          }}
        >
          {variables.map((variable) => (
            <Button
              key={variable}
              size='xs'
              variant='ghost'
              onClick={() => handleVariableClick(variable)}
            >
              {variable}
            </Button>
          ))}
        </Flex>
      )}
      {canEditSubject && (
        <Flex vertical>
          <StepInput
            id={`subject-${stepNumber}`}
            ref={subjectRef}
            name='subject'
            label='Subject:'
            placeholder='Join our team!'
            onBlur={onBlur}
            error={error?.subject?.message}
            onChange={handleSubjectChange}
            onFocus={() => {
              subjectFocusRef.current = true;
            }}
            value={stateValue?.subject}
            readOnly={readOnly}
            css={{
              '& [data-readonly="true"]': {
                readOnly: {
                  color: '$text',
                },
              },
            }}
          />
        </Flex>
      )}
      <Editor
        dataCy='Input field sequence'
        ref={editorRef as RefObject<ReactQuill>}
        onChange={handleEditorChange}
        readOnly={readOnly}
        onBlur={onBlur}
        onFocus={() => {
          subjectFocusRef.current = false;
        }}
        css={{
          '& .ql-toolbar.ql-snow': {
            borderLeft: 'none',
            borderRight: 'none',
          },
          '& .ql-container.ql-snow': {
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: 'none',
            borderBottomLeftRadius: '$lg',
            borderBottomRightRadius: '$lg',
          },
          '&[aria-invalid=true] .ql-container.ql-snow': {
            border: '1px solid $border-danger',
            boxShadow: '$focus-invalid',
          },
          '& .ql-editor': {
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: 'none',
          },
        }}
        modules={{
          toolbar: getEditorToolbar(),
          imageResize:
            canResizeImage && !readOnly
              ? {
                  parchment: Quill.import('parchment'),
                  modules: ['Resize', 'DisplaySize'],
                }
              : undefined,
          ...modules,
        }}
        invalid={!!error?.body?.message}
        defaultValue={stateValue?.body ?? ''}
        onFileUpload={onFileUpload}
      />
      {children}
    </Box>
  );
};
