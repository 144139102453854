import { showToast, Text, Tooltip } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { useRef } from 'react';
import { useMutation } from 'react-query';

interface IProps {
  projectId: string;
  projectPrevName: string;
  viewOnly: boolean;
  refetchStats: () => void;
}

export const ProjectNameEditor = ({
  projectId,
  projectPrevName,
  viewOnly,
  refetchStats,
}: IProps) => {
  const projectNameRef = useRef<HTMLParagraphElement>(null);
  const updateProject = useMutation(
    (data: { projectName: string }) =>
      apiClient.project.update({
        id: projectId,
        requestBody: {
          name: data.projectName,
        },
      }),
    {
      onSuccess: () => {
        refetchStats();
        showToast({
          variant: 'success',
          title: 'Success!',
          description: 'Project name updated.',
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Project Update Failed',
          description: 'Something went wrong. Please try again.',
        });
      },
    },
  );

  const updateProjectName = (newProjectName: string) =>
    updateProject.mutate({
      projectName: newProjectName,
    });

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const target = e.target as HTMLInputElement;
      target.blur();
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.innerText;
    if (!value && projectNameRef.current) {
      projectNameRef.current.innerText = projectPrevName;
      return;
    }
    if (projectPrevName === value) {
      return;
    }
    updateProjectName(value);
  };

  return (
    <Tooltip content='Click to edit' sideOffset={-5} disabled={viewOnly}>
      <Text
        defaultValue={projectPrevName}
        contentEditable={!viewOnly}
        ref={projectNameRef}
        onKeyDown={onKeyPress}
        onBlur={onBlur}
        css={{
          borderRadius: '$md',
          cursor: 'pointer',
          padding: '0',
          lineHeight: '$4xl',
          fontWeight: '$semibold',
          color: '$neutral-blue-900',
          fontSize: '28px',
          flex: 1,
          minWidth: 150,
          focus: {
            padding: '0 4px',
            cursor: 'text',
          },
        }}
      >
        {projectPrevName}
      </Text>
    </Tooltip>
  );
};
