import { Text } from '@betterleap/ui';

interface AcknowledgmentTextProps {
  unlinkedText: string;
  linkedText: string;
  linkUrl: string;
}

export const AcknowledgmentText = ({
  unlinkedText,
  linkedText,
  linkUrl,
}: AcknowledgmentTextProps) => (
  <Text
    css={{
      fontWeight: '$normal',
      fontSize: '$sm',
      lineHeight: '$xl',
      color: '$text',
    }}
  >
    {unlinkedText}{' '}
    <a
      href={linkUrl}
      target='_blank'
      rel='noopener noreferrer'
      style={{ color: '#3A83F7', textDecoration: 'underline' }}
    >
      {linkedText}
    </a>
    .
  </Text>
);
