import { UpdateUserEmailSettingsDto, User } from '@betterleap/client';
import { Box, Checkbox, showToast, usePrevious } from '@betterleap/ui';
import { useMutation, useQueryClient } from 'react-query';
import { apiClient } from 'lib/apiClient';
import { useState } from 'react';
import { IntegrationSetting } from '../Connections/Integration.elements';
import { Settings } from '../Settings.elements';

export const ContactReplyEmailSetting = ({ user }: { user: User }) => {
  const queryClient = useQueryClient();
  const [enabled, setEnabled] = useState(user.optionEnabledContactReplyEmails);
  const previousValue = usePrevious(enabled);

  const updateUser = useMutation(
    (data: { requestBody: UpdateUserEmailSettingsDto }) =>
      apiClient.user.updateMe(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['me']);
        showToast({
          variant: 'success',
          title: `Successfully ${
            previousValue ? 'disabled' : 'enabled'
          } reply emails`,
          description: `You will ${
            previousValue ? 'no longer receive' : 'now receive'
          } emails when candidates reply`,
        });
      },
      onError: () => {
        if (previousValue) {
          setEnabled(previousValue);
        }

        showToast({
          variant: 'danger',
          title: `Failed to ${
            previousValue ? 'disable' : 'enable'
          } reply emails`,
          description: 'Please try again',
        });
      },
    },
  );

  return (
    <Settings.Section.Row>
      <Box>
        <IntegrationSetting.Title>
          Candidate reply email
        </IntegrationSetting.Title>
        <IntegrationSetting.Subtitle>
          This sends you an automated email with relevant links when a candidate
          replies
        </IntegrationSetting.Subtitle>
      </Box>
      <Checkbox
        defaultChecked={user.optionEnabledContactReplyEmails}
        onChange={(v) => {
          setEnabled(v);
          updateUser.mutate({
            requestBody: {
              optionEnabledContactReplyEmails: v,
            },
          });
        }}
      >
        Enable email
      </Checkbox>
    </Settings.Section.Row>
  );
};
