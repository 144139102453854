import { SegmentAnalytics, TOKEN, Types } from '@betterleap/shared';
import { EventProperties } from '@segment/analytics-next';
import { shouldEnableAnalytics } from 'helper/analytics';

const isSignInAsAnotherUser = TOKEN.isSessionStorage();
const shouldEnableTrack = shouldEnableAnalytics(isSignInAsAnotherUser);

export const logToSegment = (
  eventName: Types.SEGMENT_EVENTS | Types.ANALYTICS_CLIENT_EVENT,
  properties?: EventProperties | undefined,
) => SegmentAnalytics.track(shouldEnableTrack, eventName, properties);
