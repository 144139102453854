import { Recruiter, RecruiterDto } from '@betterleap/client';
import { Box, TextArea } from '@betterleap/ui';
import { useState } from 'react';
import { CardSection, SectionTitle } from './RecruiterProfile.elements';

interface AboutSectionProps {
  recruiter: Recruiter;
  onUpdate?: (dto: RecruiterDto) => void;
}

export const RecruiterProfileAbout = (props: AboutSectionProps) => {
  const { recruiter, onUpdate } = props;
  const [about, setAbout] = useState(recruiter.bio);
  return (
    <CardSection>
      <SectionTitle title='About' icon='sun' />
      {onUpdate ? (
        <TextArea
          placeholder='Make a great first impression. Add your intro here.'
          value={about}
          onChange={(value) => setAbout(value)}
          onBlur={() => {
            onUpdate?.({ bio: about });
          }}
          css={{
            border: '1px solid $neutral-blue-300',
            borderRadius: 16,
            p: 16,
          }}
        />
      ) : (
        <Box css={{ alignSelf: 'flex-start' }}>{recruiter.bio}</Box>
      )}
    </CardSection>
  );
};
