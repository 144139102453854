import { Types } from '@betterleap/shared';
import { FIREBASE_USER_TO_USER, mapObjectPartially } from 'helper/mapper';
import getReferrer from 'helper/sourceReferrer';
import useFetch from 'hooks/fetch';
import { getCookie } from 'lib/cookies';
import { useLocation } from 'react-router-dom';

export const useSignup = () => {
  const { search } = useLocation();
  const [signupData, doFetchSignup] = useFetch('signup');

  const signup = (user: Types.FirebaseAuthCredentials) => {
    const searchParams = new URLSearchParams(search);
    const invitationId = searchParams.get('invitationId');
    const source = searchParams.get('source') || getCookie('source');
    const referrer = source ? getReferrer(source) : document.referrer;
    const signupOptions = {
      ...mapObjectPartially(user, FIREBASE_USER_TO_USER),
      invitationId,
      partner: searchParams.get('partner'),
      referrer,
    };
    doFetchSignup(signupOptions);
    localStorage.removeItem('activeStep');
  };

  return { signup, signupData };
};
