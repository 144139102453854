import {
  Box,
  BoxProps,
  Combobox,
  ComboboxProps,
  Option,
  Select,
  SelectItem,
  Text,
} from '@betterleap/ui';
import { useMemo, useState } from 'react';
import { useAtom } from 'jotai/react';
import { SourcingFilterTitle } from '../SourceCandidates.components';
import {
  applyTerm,
  betterStartCase,
  filterItemsEqual,
  hideExclusionPrefix,
  parseTerm,
} from '../SourceCandidates.functions';
import {
  FilterItem,
  InclusionType,
  SourceCandidatesSearchParams,
} from '../SourceCandidates.types';
import { filterState } from '../SourceCandidates.template';
import { ExcludeBadge } from '../ExcludeBadge';
import {
  FriendlyFilterItem,
  useAutocompleteOptions,
} from '../SourceCandidatesMultiSelectFilter';

const getInclusionTitle = (type: InclusionType) => {
  switch (type) {
    case InclusionType.CANT_HAVE:
      return "Doesn't have";
    case InclusionType.MUST_HAVE:
      return 'Must have';
    default:
      return 'Can have';
  }
};

interface SelectionGroupProps extends Omit<BoxProps, 'selected'> {
  type: InclusionType;
  selected: FilterItem[];
  field: string;
  onRemove: (item: FilterItem) => void;
}

const SelectionGroup = ({
  type,
  selected,
  onRemove,
  ...rest
}: SelectionGroupProps) => {
  return (
    <Box {...rest}>
      <Text size='xs' css={{ color: '$text-light' }}>
        {getInclusionTitle(type)}
      </Text>
      {selected.map((item) => (
        <ExcludeBadge
          key={item.name}
          variant='violet'
          canExclude={false}
          data-type={item.type}
          css={{
            mr: 4,
            mt: 4,
            whiteSpace: 'balance',
            ['&[data-type="' + InclusionType.CANT_HAVE + '"]']: {
              backgroundColor: '$neutral-blue-200',
              color: '$neutral-blue-700',
              fill: '$neutral-blue-700',
            },
            ['&[data-type="' + InclusionType.CAN_HAVE + '"]']: {
              backgroundColor: '$violet-100',
              color: '$violet-800',
              fill: '$violet-800',
            },
            ['&[data-type="' + InclusionType.MUST_HAVE + '"]']: {
              backgroundColor: '$violet-300',
              color: '$violet-800',
              fill: '$violet-800',
            },
          }}
          onRemove={() => {
            onRemove(item);
          }}
        >
          {betterStartCase(hideExclusionPrefix(item.name))}
        </ExcludeBadge>
      ))}
    </Box>
  );
};

interface VariableInclusionFilterProps
  extends Omit<ComboboxProps, 'ref' | 'children'> {
  title?: string;
  field: keyof SourceCandidatesSearchParams;
  autocompleteField?: keyof SourceCandidatesSearchParams;
  values: string[];
  canExclude?: boolean;
}

export const VariableInclusionFilter = (
  params: VariableInclusionFilterProps,
) => {
  const {
    title,
    field,
    autocompleteField,
    values,
    canExclude,
    placeholder,
    css,
    ...rest
  } = params;
  const [filterType, setFilterType] = useState<InclusionType>(
    InclusionType.MUST_HAVE,
  );
  const [filters, setFilters] = useAtom(filterState);

  const selection = useMemo(() => {
    return values.map((val) => ({
      field,
      ...parseTerm(val),
    }));
  }, [values]);

  const selectionGroups = useMemo(() => {
    const selectionByGroup: Record<string, typeof selection> = {};

    const mustInclude = selection.filter(
      (val) => val.type === InclusionType.MUST_HAVE,
    );
    const shouldInclude = selection.filter(
      (val) => val.type === InclusionType.CAN_HAVE,
    );
    const excluded = selection.filter(
      (val) => val.type === InclusionType.CANT_HAVE,
    );

    if (mustInclude.length) {
      selectionByGroup[InclusionType.MUST_HAVE] = mustInclude;
    }

    if (shouldInclude.length) {
      selectionByGroup[InclusionType.CAN_HAVE] = shouldInclude;
    }

    if (excluded.length) {
      selectionByGroup[InclusionType.CANT_HAVE] = excluded;
    }

    return selectionByGroup;
  }, [selection]);

  const { options, isLoading, setSearchTerm } = useAutocompleteOptions({
    field: autocompleteField ?? field,
    selected: selection,
  });

  return (
    <Box css={{ width: '100%', ...css }}>
      <SourcingFilterTitle title={title} />
      <Box css={{ mb: 6 }}>
        {Object.keys(selectionGroups).map((key) => {
          const selected = selectionGroups[key] ?? [];
          return (
            <SelectionGroup
              key={key}
              css={{
                my: 12,
              }}
              type={key as InclusionType}
              selected={selected}
              field={field}
              onRemove={(item) => {
                setFilters({
                  ...filters,
                  [field]: selection
                    .filter((v) => !filterItemsEqual(v, item))
                    .map(applyTerm),
                });
              }}
            />
          );
        })}
      </Box>
      <Combobox
        aria-labelledby={`filter-${field}`}
        id={`filter-${field}`}
        placeholder={placeholder}
        options={options}
        loading={isLoading}
        value={[]}
        clearable={false}
        onChange={(keys, selected: FriendlyFilterItem[]) => {
          const newFilter = selection
            .filter((v) => !selected.some((s) => filterItemsEqual(v, s)))
            .concat(
              selected.map((value) => ({
                ...value,
                field,
                type: filterType,
                excluded: filterType === InclusionType.CANT_HAVE,
              })),
            );

          setFilters({
            ...filters,
            [field]: newFilter.map((filter) => applyTerm(filter)),
          });

          setSearchTerm('');
        }}
        rightIcon={
          <Select
            css={{
              width: 115,
              height: 40,
              mr: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              whiteSpace: 'nowrap',
            }}
            value={filterType}
            onChange={(newFilterType: InclusionType) => {
              setFilterType(newFilterType);
            }}
            size='xs'
            variant='headless'
          >
            <SelectItem size='sm' value={InclusionType.MUST_HAVE}>
              Must have
            </SelectItem>
            <SelectItem size='sm' value={InclusionType.CAN_HAVE}>
              Can have
            </SelectItem>
            {canExclude !== false && (
              <SelectItem size='sm' value={InclusionType.CANT_HAVE}>
                Doesn't have
              </SelectItem>
            )}
          </Select>
        }
        onFilterChange={setSearchTerm}
        css={{
          '& .combobox-toggle-open-button': {
            visibility: 'hidden',
            width: 0,
            p: 0,
            m: 0,
          },
        }}
        {...rest}
      >
        {(filterItem: FriendlyFilterItem) => (
          <Option key={filterItem.id} css={{ p: 8, px: 16 }}>
            <Text size='sm'>{filterItem.text}</Text>
          </Option>
        )}
      </Combobox>
    </Box>
  );
};
