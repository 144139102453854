import { SeparatorWithText, Types } from '@betterleap/shared';
import {
  Box,
  Button,
  Flex,
  Form,
  BoundInput as Input,
  Spinner,
} from '@betterleap/ui';
import {
  SignInRequest,
  SignUpRequest,
} from 'components/templates/SignIn/SignIn.types';
import { Control, UseFormHandleSubmit } from 'react-hook-form';
import config from 'lib/config';
import { useLocation, useNavigate } from 'react-router-dom';
import useAnalytics from 'hooks/analytics';
import { useEffect, useMemo, useState } from 'react';
import { useEmailPasswordSignIn } from 'components/templates/SignIn/useEmailPasswordSignIn';

type FormInput = { email: string; password: string };
interface EmailPasswordFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FormInput, any>;
  handleSubmit: UseFormHandleSubmit<FormInput>;
  isSignup: boolean;
}

const SignInSignUpToggle = ({ isSignup }: { isSignup: boolean }) => {
  const navigate = useNavigate();
  const altPath = `${isSignup ? 'signin' : 'signup'}?enableEmailSignUp=true`;
  return (
    <Flex css={{ mt: 24, alignSelf: 'center', fontSize: '$sm', gap: 8 }}>
      <Box css={{ color: 'white' }}>
        {isSignup ? 'Already have an account?' : "Don't have an account?"}
      </Box>
      <Box
        as='a'
        href={`${config.url}/${altPath}`}
        rel='noreferrer'
        css={{ color: '$blue-500', textDecoration: 'underline' }}
        onClick={(e) => {
          e.preventDefault();
          navigate(`/${altPath}`);
        }}
      >
        {isSignup ? 'Sign in' : 'Sign up'}
      </Box>
    </Flex>
  );
};

export const EmailPasswordForm = ({
  control,
  handleSubmit,
  isSignup,
}: EmailPasswordFormProps) => {
  const { startSignUp, markEmailVerified, signIn } = useEmailPasswordSignIn();
  const { track } = useAnalytics();
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [isLoading, setIsLoading] = useState(false);

  const onStartSignUp = async (request: SignUpRequest) => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Sign Up',
      buttonLocation: 'Sign Up Page',
    });
    setIsLoading(true);
    await startSignUp(request);
    setIsLoading(false);
  };
  const onSignIn = async (request: SignInRequest) => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Sign In',
      buttonLocation: 'Sign In Page',
    });
    setIsLoading(true);
    await signIn(request);
    setIsLoading(false);
  };
  const onSubmit = isSignup ? onStartSignUp : onSignIn;

  const code = searchParams.get('oobCode');
  useEffect(() => {
    if (code) {
      markEmailVerified(code);
    }
  }, [code]);

  if (searchParams.get('enableEmailSignUp') !== 'true') {
    return null;
  }

  return (
    <Box css={{ maxWidth: 440, margin: 'auto' }}>
      <Form
        control={control}
        css={{
          maxWidth: 360,
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          placeholder='Email Address'
          type='email'
          name='email'
          id='email'
          required
        />
        <Input
          placeholder='Password'
          type='password'
          name='password'
          id='password'
          required
        />
        <Button full type='submit'>
          {isLoading && <Spinner variant='white' />}
          {!isLoading && (isSignup ? 'Sign Up' : 'Sign In')}
        </Button>
        <SignInSignUpToggle isSignup={isSignup} />
      </Form>
      <SeparatorWithText className='px-4 tablet:px-9' text='Or continue with' />
    </Box>
  );
};
