import { useMemo } from 'react';
import { Button, Icon, showToast, useModal } from '@betterleap/ui';
import { PersonSearchRequest } from '@betterleap/client';
import { useMutation, useQueryClient } from 'react-query';
import { useAtomValue } from 'jotai';
import { apiClient } from 'lib/apiClient';
import SaveSearchModal, {
  SaveSearchModalProps,
} from 'components/modules/Modal/SaveSearchModal/SaveSearchModal';
import omit from 'lodash/omit';
import { filterState } from './SourceCandidates.template';

export const SaveSearchButton = ({ onSave }: { onSave?: () => void }) => {
  const queryClient = useQueryClient();
  const personSearchParams = useAtomValue(filterState);
  const openSaveSearchModal = useModal<never, SaveSearchModalProps>(
    SaveSearchModal,
  );

  const saveSearch = useMutation(
    (data: { name: string; parameters: PersonSearchRequest }) =>
      apiClient.savedSearch.create({
        requestBody: {
          ...data,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['recent_saved_searches']);
        onSave?.();
        showToast({
          variant: 'success',
          title: 'Success!',
          description: 'Successfully saved search.',
        });
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to save search. Please try again.',
        });
      },
    },
  );

  const paramsWithoutPagination = useMemo(
    () => omit(personSearchParams, ['page', 'pageSize']),
    [personSearchParams],
  );

  const hasParams = useMemo(() => {
    const paramsWithoutDefaults = omit(paramsWithoutPagination, [
      'companySearchMode',
      'searchMode',
      'excludeViewed',
    ]);

    return Object.values(paramsWithoutDefaults).some((value) => {
      return Array.isArray(value) ? value.length : value;
    });
  }, [paramsWithoutPagination]);

  const handleClick = async () => {
    const data = await openSaveSearchModal();

    if (data) {
      saveSearch.mutate({
        name: data.name,
        parameters: omit(personSearchParams, [
          'page',
          'pageSize',
          'excludeViewed',
        ]),
      });
    }
  };

  return (
    <Button
      size='sm'
      full
      css={{
        disabled: {
          backgroundColor: '$neutral-blue-200',
          fill: '$neutral-blue-500',
        },
      }}
      variant='gray'
      onClick={handleClick}
      disabled={!hasParams}
    >
      Save Search <Icon css={{ ml: 8 }} inherit name='bookmark' size={16} />
    </Button>
  );
};
