import { FC } from 'react';
import { Button, Icon } from '@betterleap/ui';
import { FeedbackSentiment } from './AnalyticsCopilotTab';

interface FeedbackButtonsProps {
  sentiment: FeedbackSentiment | null;
  result: string;
  feedbackProvided: boolean;
  handleOpenFeedbackModal: (sentiment: FeedbackSentiment) => void;
}

export const FeedbackButtons: FC<FeedbackButtonsProps> = ({
  sentiment,
  result,
  feedbackProvided,
  handleOpenFeedbackModal,
}) => (
  <>
    <Button
      size='xs'
      variant={
        sentiment && sentiment !== FeedbackSentiment.POSITIVE
          ? 'primary'
          : 'gray'
      }
      css={{ gap: 8 }}
      disabled={!result || feedbackProvided}
      onClick={() => handleOpenFeedbackModal(FeedbackSentiment.NEGATIVE)}
    >
      {/* NOTE: I'm not sure why and haven't investigated but the thumb icons appear to be the wrong way round */}
      <Icon name='thumb-up' inherit size={12} />
    </Button>
    <Button
      size='xs'
      variant={
        sentiment && sentiment === FeedbackSentiment.POSITIVE
          ? 'primary'
          : 'gray'
      }
      css={{ gap: 8 }}
      disabled={!result || feedbackProvided}
      onClick={() => handleOpenFeedbackModal(FeedbackSentiment.POSITIVE)}
    >
      <Icon name='thumb-down' inherit size={12} />
    </Button>
  </>
);
