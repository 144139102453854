import { AxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const constructErrorToast = (axiosError: AxiosError<any>) => {
  let response: { title: string; description: string };
  if (
    axiosError.response?.data?.error?.name?.includes('InvalidVariableError')
  ) {
    response = {
      title: 'Invalid variable names',
      description: 'Make sure the variables names used match what’s available',
    };
  } else if (axiosError.response?.data?.status === 403) {
    response = {
      title: 'Email Permissions Missing',
      description:
        'Please connect your email account to Betterleap in Settings',
    };
  } else {
    response = {
      title: 'Error',
      description:
        'Something went wrong. Please contact support@betterleap.com for assistance',
    };
  }
  return response;
};
