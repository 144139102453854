import { Flex, showToast, Text } from '@betterleap/ui';
import { useMutation } from 'react-query';
import { NoteManager, NoteManagerStyle } from '@betterleap/shared';
import { ContactNote } from '@betterleap/client';
import { apiClient } from 'lib/apiClient';

export interface ContactProfileNotesProps {
  myUserId: string;
  contactId: string;
  notes: ContactNote[];
  isNotesLoading: boolean;
  refetchContactNotes: () => void;
}

const ContactProfileNotes = (props: ContactProfileNotesProps) => {
  const { myUserId, contactId, notes, isNotesLoading, refetchContactNotes } =
    props;
  const hasNotes = notes.length > 0;

  const createContactNote = useMutation(
    (data: { note: string; contactId: string }) =>
      apiClient.contactNote.create({ requestBody: data }),
    {
      onSuccess: () => {
        refetchContactNotes();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to add note. Please try again.',
        });
      },
    },
  );
  const handleAddNote = async (note: string) => {
    await createContactNote.mutate({
      note,
      contactId,
    });
  };

  return (
    <Flex vertical css={{ gap: 20, height: '100%', width: '100%' }}>
      <Text as='h3' css={{ fontWeight: '$medium', fontSize: '$lg' }}>
        Notes
      </Text>
      <NoteManager
        myUserId={myUserId}
        style={NoteManagerStyle.CONTACT}
        showNotes={hasNotes}
        isNotesLoading={isNotesLoading}
        notes={notes}
        handleAddNote={handleAddNote}
      />
    </Flex>
  );
};

export default ContactProfileNotes;
