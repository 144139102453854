/* eslint-disable jsx-a11y/tabindex-no-positive */
import { forwardRef } from 'react';
import { Editor, EditorProps } from '@betterleap/ui';
import ReactQuill from 'react-quill';

export const CopilotEditor = forwardRef<ReactQuill, EditorProps>(
  ({ css, ...rest }, ref) => (
    <Editor
      ref={ref}
      modules={{
        toolbar: [],
      }}
      css={{
        '& #copilot-editor': {
          height: '100%',
        },
        '& .ql-container.ql-snow': {
          border: 'none',
          height: '100%',
          padding: 0,
          fontSize: '$base',
        },
        '& .ql-toolbar.ql-snow': {
          display: 'none',
        },
        '& .ql-editor': {
          minHeight: 0,
          height: '100%',
          padding: 0,
        },
        '& .ql-editor.ql-blank::before': {
          left: 0,
          color: '$text-tertiary',
          fontStyle: 'normal',
        },
        border: 'none',
        p: 0,
        pt: 8,
        pb: 8,
        height: '100%',
        color: '$text',
        focus: {
          boxShadow: 'none',
        },
        ...css,
      }}
      {...rest}
    />
  ),
);

CopilotEditor.displayName = 'CopilotEditor';
