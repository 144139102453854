import { useUploadFile } from '@betterleap/shared';
import { Sender, SetSignatureDto } from '@betterleap/client';
import {
  Box,
  Button,
  Editor,
  EDITOR_MODULES,
  Flex,
  showToast,
} from '@betterleap/ui';
import { useMutation, useQuery } from 'react-query';
import { RefObject, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Line from 'components/elements/Line/Line';
import { apiClient } from '../../../lib/apiClient';
import { IntegrationSetting } from './Connections/Integration.elements';

const SignatureSetting = () => {
  const editorRef = useRef<ReactQuill>();
  const { uploadFile } = useUploadFile({ path: 'public/signature' });
  const { data: senderResponse, refetch } = useQuery(['get_sender'], () =>
    apiClient.sender.getSender(),
  );
  const saveSignature = useMutation(
    (data: { requestBody: SetSignatureDto }) =>
      apiClient.sender.setSignature(data),
    {
      onSuccess: () => {
        showToast({
          title: 'Signature saved!',
          description:
            'This signature will be included at the end of every email in your sequences.',
        });
        refetch();
      },
    },
  );
  const sender = senderResponse?.data;
  const [signature, setSignature] = useState<string>(sender?.signature || '');
  const { width } = useWindowDimensions();
  if (!sender || sender.provider !== Sender.provider.MICROSOFT) {
    return <></>;
  }
  return (
    <Flex vertical>
      <Line />
      <IntegrationSetting
        css={{ mt: 8, mediaMd: { alignItems: 'flex-start' } }}
      >
        <Box>
          <Box css={{ mt: 16 }}>
            <IntegrationSetting.Title>Email Signature</IntegrationSetting.Title>
          </Box>
          <IntegrationSetting.Subtitle>
            This signature will be included at the end of every email in your
            sequences.
          </IntegrationSetting.Subtitle>
        </Box>
        <Box>
          <Editor
            defaultValue={sender.signature || ''}
            ref={editorRef as RefObject<ReactQuill>}
            css={{
              width: width * 0.4,
              minWidth: 432,
              backgroundColor: 'white',
              '& .ql-toolbar.ql-snow': {
                borderColor: '$neutral-blue-300',
              },
              '& .ql-container.ql-snow': {
                borderColor: '$neutral-blue-300',
              },
              '& .ql-toolbar': {
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
              },
              '& .ql-container': {
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
              },
              '& .ql-editor': {
                minHeight: 156,
                height: 'auto',
              },
              '& .ql-editor.ql-blank::before': {
                fontStyle: 'normal',
                color: '$neutral-blue-600',
              },
              '& .ql-formats button .ql-stroke': {
                stroke: '$neutral-blue-700',
              },
              '& .ql-formats button .ql-fill': {
                fill: '$neutral-blue-700',
              },
            }}
            placeholder='Add a signature to your sequences! Start typing or copy &  paste from another client.'
            modules={{
              toolbar: {
                ...EDITOR_MODULES.toolbar,
                container: [
                  ...EDITOR_MODULES.toolbar.container,
                  ['link', 'image'],
                ],
              },
              imageResize: {
                parchment: Quill.import('parchment'),
              },
            }}
            onFileUpload={async (file) => {
              const { gcsUrl } = await uploadFile(file);
              return gcsUrl;
            }}
            onChange={(content) => {
              setSignature(content);
            }}
          />
          <Button
            onClick={() => {
              saveSignature.mutate({
                requestBody: {
                  signature,
                },
              });
            }}
            css={{
              color: '$blue-600',
              float: 'right',
              margin: '24px 12px 0 12px',
              disabled: {
                color: '$blue-300',
              },
            }}
            variant='headless'
            disabled={signature === sender.signature}
          >
            Save
          </Button>
        </Box>
      </IntegrationSetting>
    </Flex>
  );
};

export default SignatureSetting;
