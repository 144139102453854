import { Box, Checkbox, Flex } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { useQuery } from 'react-query';
import { useAtom } from 'jotai';
import { filterState, SearchMode } from '../SourceCandidates.template';

export interface FilterProps {
  searchMode: SearchMode;
}

export const ExcludeViewedCandidatesFilter = ({ searchMode }: FilterProps) => {
  const { data: organization } = useQuery(['organization'], () =>
    apiClient.organization.getOrganization(),
  );
  const [filters, setFilters] = useAtom(filterState);
  const featureEnabled =
    organization?.data?.features?.enabledExcludeViewedFilter;
  if (!featureEnabled || searchMode === SearchMode.MY_TALENT_POOL) {
    return null;
  }
  return (
    <Flex vertical>
      <Box css={{ width: '100%' }}>
        <Checkbox
          id='exclude-viewed'
          name='excludeViewed'
          checked={filters.excludeViewed ?? false}
          onChange={(value) => {
            setFilters({
              ...filters,
              excludeViewed: value,
            });
          }}
        >
          Exclude viewed candidates
        </Checkbox>
      </Box>
    </Flex>
  );
};
