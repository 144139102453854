import { getFullName, useUploadFile } from '@betterleap/shared';
import { Recruiter, RecruiterDto } from '@betterleap/client';
import { Box, Flex, Spinner, Text } from '@betterleap/ui';
import { useState } from 'react';
import defImg from '../../../assets/images/default-profile-pic.png';
import { EditIcon } from './RecruiterProfile.elements';

interface ProfileHeaderProps {
  recruiter: Recruiter;
  onUpdate?: (dto: RecruiterDto) => void;
}
export const RecruiterProfileHeader = (props: ProfileHeaderProps) => {
  const { recruiter, onUpdate } = props;
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { uploadFile } = useUploadFile({
    path: 'public/profile-picture',
    onSuccess: (file) => {
      onUpdate?.({ profilePictureFile: file });
      setIsUploading(false);
    },
  });

  const onUploadClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.jpg, .jpeg, .png';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        setIsUploading(true);
        uploadFile(file);
      }
    };
    input.click();
  };
  return (
    <Flex css={{ width: '100%' }}>
      <Box css={{ position: 'relative' }}>
        {isUploading && (
          <Spinner
            css={{
              position: 'absolute',
              top: 24,
              left: 24,
              mediaLg: {
                top: 58,
                left: 58,
              },
            }}
          />
        )}
        <Box
          as='img'
          src={recruiter.profilePictureFile?.gcsUrl || defImg}
          alt='recruiter profile pic'
          css={{
            height: 72,
            width: 72,
            borderRadius: 16,
            objectFit: 'cover',
            mediaLg: {
              height: 140,
              width: 140,
              borderRadius: 32,
            },
          }}
        />
        {!!onUpdate && <EditIcon onClick={onUploadClick} />}
      </Box>
      <Box css={{ p: 24 }}>
        <Text
          css={{
            fontSize: 20,
            fontWeight: 500,
            mediaLg: {
              fontSize: '$3xl',
            },
          }}
        >
          {getFullName(recruiter.user)}
        </Text>
      </Box>
    </Flex>
  );
};
