/**
 * This is duplicated from the UtilsHelper in the API.
 * TODO: Provide a mechanism to share code between API and UIs.
 */
export function randomString(
  length = 10,
  possible = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
): string {
  const s = new Array(length);
  for (let i = 0; i < length; ++i) {
    s[i] = possible.charAt(randomInt(0, possible.length));
  }
  return s.join('');
}

function randomInt(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}
