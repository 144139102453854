import { Spinner, Types, useMe } from '@betterleap/shared';
import { InvitationsMetadataDto } from '@betterleap/client';
import {
  Badge,
  Box,
  Flex,
  Tab,
  TabContent,
  Tabs,
  TabsList,
  Text,
} from '@betterleap/ui';
import qs from 'qs';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { apiClient } from 'lib/apiClient';
import { CreateGroupButton } from './CreateGroupButton';
import { GroupsTable } from './GroupsTable';
import { MembersTable } from './MembersTable';
import { InviteMembersButton } from './InviteMembersButton';

export interface InviteMember {
  id?: string;
  name?: string;
  email?: string;
  status?: Types.INVITATION_STATUS;
  invitationId?: string;
  organizationId?: string;
  invitationType?: InvitationsMetadataDto.type;
  groupId: string;
  roles?: string[];
}

export interface Member {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  status?: string;
  invitationId?: string;
  organizationId?: string;
  invitationType?: InvitationsMetadataDto.type;
  roles?: string[];
  groupId: string;
}

export type GetOrganizationMembersResponse = {
  data: {
    data: Member[];
    totalSize: number;
  };
};

const OrganizationInviteTemplate = () => {
  const {
    data: organization,
    isLoading: organizationLoading,
    refetch: refetchOrganization,
  } = useQuery(['organization'], () =>
    apiClient.organization.getOrganization(),
  );
  const me = useMe();
  const location = useLocation();
  const querySearch = qs.parse(location.search.slice(1));

  const {
    data: invitees,
    isLoading: inviteesLoading,
    refetch,
  } = useQuery<GetOrganizationMembersResponse>(
    ['get_organization_members', querySearch],
    () =>
      apiClient.user.getOrgMembers({
        page: Number(`${querySearch.page}`),
        pageSize: Number(`${querySearch.pageSize}`),
      }),
  );

  const {
    data: groupsResponse,
    isLoading: isGroupsLoading,
    refetch: refetchGroups,
  } = useQuery(['get_groups'], () => apiClient.group.getGroups());

  const groups = groupsResponse?.data ?? [];

  const onTeamStatusChange = () => {
    refetch();
    refetchOrganization();
    refetchGroups();
  };

  const membersTable = (
    <MembersTable
      invitees={invitees}
      groups={groups}
      isLoading={inviteesLoading ?? isGroupsLoading}
      organizationCreatorId={organization?.data?.createdByUserId}
      onGroupChanged={onTeamStatusChange}
      onMemberDeactivated={onTeamStatusChange}
    />
  );
  const groupsTable = (
    <GroupsTable
      groupsResponse={groupsResponse}
      isLoading={isGroupsLoading}
      onGroupRename={refetchGroups}
    />
  );
  const tabs = [
    {
      key: 'members',
      label: 'Members',
      content: membersTable,
      count: invitees?.data.totalSize ?? 0,
    },
    {
      key: 'groups',
      label: 'Groups',
      content: groupsTable,
      count: groupsResponse?.meta.count ?? 0,
    },
  ];

  if (organizationLoading || inviteesLoading) {
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }

  return (
    <Box css={{ p: 32 }}>
      <Flex
        css={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          mb: 24,
          mediaMd: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        }}
      >
        <Text
          as='h1'
          css={{
            fontSize: '$3xl',
            lineHeight: '$4xl',
            fontWeight: '$medium',
            color: '$neutral-blue-900',
          }}
        >
          Your Team
        </Text>
        <Flex
          css={{
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            mediaMd: {
              flexDirection: 'row',
              justifyContent: 'unset',
              width: 'unset',
            },
          }}
        >
          {me.ability.can('create', 'Group') && (
            <CreateGroupButton onGroupCreated={refetchGroups} />
          )}
          {me.ability.can('create', 'Invitation') && (
            <InviteMembersButton onMemberInvited={refetch} />
          )}
        </Flex>
      </Flex>
      {me.ability.can('create', 'Group') ? (
        <Tabs css={{ flex: 1 }} defaultValue='members' tabStyle='underline'>
          <TabsList
            css={{
              justifyContent: 'space-between',
              overflow: 'visible',
            }}
          >
            <Flex>
              {tabs.map((tab) => (
                <Tab
                  key={tab.key}
                  value={tab.key}
                  css={{ fontWeight: '$medium', fontSize: '$xl' }}
                >
                  {tab.label}
                  {tab.count > 0 && (
                    <Badge variant='violet' size='sm'>
                      {tab.count}
                    </Badge>
                  )}
                </Tab>
              ))}
            </Flex>
          </TabsList>
          {tabs.map((tab) => (
            <TabContent key={tab.key} value={tab.key}>
              {tab.content}
            </TabContent>
          ))}
        </Tabs>
      ) : (
        <>
          <Text
            as='h3'
            css={{
              fontSize: '$lg',
              color: '$neutral-blue-900',
              fontWeight: '$medium',
            }}
          >
            Members
          </Text>
          {membersTable}
        </>
      )}
    </Box>
  );
};

export default OrganizationInviteTemplate;
