import { UpdateProjectSuggestedContactDto } from '@betterleap/client';
import { showToast } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { useMutation } from 'react-query';

export const useUpdateSuggestedContact = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const updateSuggestedContacts = useMutation(
    (data: UpdateProjectSuggestedContactDto) =>
      apiClient.projectSuggestedContact.update({
        requestBody: data,
      }),
    {
      onSuccess: () => {
        onSuccess?.();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to process action. Please try again.',
        });
      },
    },
  );

  const addSuggestedContacts = async (idList: string[]) => {
    const status = UpdateProjectSuggestedContactDto.status.ADDED;
    await updateSuggestedContacts.mutateAsync({ idList, status });
  };
  const removeSuggestedContacts = async (idList: string[]) => {
    const status = UpdateProjectSuggestedContactDto.status.REMOVED;
    await updateSuggestedContacts.mutateAsync({ idList, status });
  };

  return {
    addSuggestedContacts,
    removeSuggestedContacts,
    isLoading: updateSuggestedContacts.isLoading,
  };
};
