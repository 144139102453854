import { Badge, BadgeProps, IconButton, Tooltip } from '@betterleap/ui';
import { useState } from 'react';

interface Props extends BadgeProps {
  canExclude?: boolean;
  isExcluded?: boolean;
  onExclude?: (excluded: boolean) => void;
}

export const ExcludeBadge = ({
  children,
  canExclude,
  isExcluded,
  onExclude,
  onRemove,
  ...rest
}: Props) => {
  const [excluded, setExcluded] = useState<boolean>(isExcluded ?? false);

  const handleExclude = () => {
    onExclude?.(!excluded);
    setExcluded(!excluded);
  };

  return (
    <Badge
      {...rest}
      css={{
        ...(excluded && {
          backgroundColor: '$neutral-blue-200',
          color: '$neutral-blue-500',
          fill: '$neutral-blue-500',
        }),
        ...rest.css,
      }}
    >
      {children}
      {canExclude !== false && (
        <Tooltip content={excluded ? 'Include' : 'Exclude'}>
          <IconButton
            className='exclude-badge'
            label='exclude'
            name={excluded ? 'eye-off' : 'eye'}
            size='xs'
            shape='circle'
            onClick={handleExclude}
            css={{
              ml: 4,
              height: 20,
              fill: 'inherit',
              hover: {
                backgroundColor: 'transparent',
                fill: excluded ? '$neutral-blue-900' : '$purple-1000',
              },
              active: {
                backgroundColor: 'transparent',
              },
              focus: {
                boxShadow: 'none',
              },
            }}
          />
        </Tooltip>
      )}
      <Tooltip content='Remove'>
        <IconButton
          className='remove-badge'
          label='remove'
          name='x'
          size='xs'
          shape='circle'
          onClick={onRemove}
          css={{
            p: 0,
            fill: 'inherit',
            hover: {
              backgroundColor: 'transparent',
              fill: '$purple-1000',
            },
            active: {
              backgroundColor: 'transparent',
            },
          }}
        />
      </Tooltip>
    </Badge>
  );
};
