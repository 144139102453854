import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { User } from '@betterleap/client';
import { useLocation, useNavigate } from 'react-router-dom';
import withHeader from '../components/HOC/withHeader';
import routeNames from '../constants/routeNames';
import TOKEN from '../lib/token';
import { selectMe } from '../store/api/selectors';

const Home: React.FC = () => {
  const me = useSelector(selectMe);
  const location = useLocation();
  const navigate = useNavigate();
  const isAdmin = me.data?.role === User.role.ADMIN;
  const isCompany = me.data?.role === User.role.COMPANY_ADMIN;
  const isRecruiter = me.data?.role === User.role.RECRUITER;
  const token = TOKEN.get();
  useEffect(() => {
    localStorage.removeItem('activeStep');
  }, []);
  useEffect(() => {
    if (me.error || !token) {
      navigate(routeNames.signin(), { state: location.state });
    } else if (!!me.data && isCompany) {
      navigate(routeNames.dashboard());
    } else if (!!me.data && isRecruiter) {
      navigate(routeNames.dashboard());
    } else if (!!me.data && isAdmin) {
      navigate(routeNames.adminNoAccess());
    } else if (!!me.data && !isCompany && !isRecruiter && !isAdmin) {
      navigate(routeNames.onboarding());
    }
  }, [me.data]);
  return <></>;
};

export default withHeader(Home);
