import { Box, Button, Flex, Icon, Spinner, Text } from '@betterleap/ui';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useUploadFile } from '../../../hooks';
import { getApiClient } from '../../../lib';
import { FileAttachment } from './FileAttachment';

interface AttachmentManagerProps {
  contactId: string;
}

const AttachmentManager = ({ contactId }: AttachmentManagerProps) => {
  const apiClient = getApiClient();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { data: attachmentsResponse, refetch: refetchAttachments } = useQuery(
    ['attachments', contactId],
    () => apiClient.attachment.getAttachments({ contactId }),
  );
  const createAttachment = useMutation(
    (data: { contactId: string; fileId: string }) =>
      apiClient.attachment.createAttachment({
        requestBody: data,
      }),
    {
      onSuccess: () => {
        setIsUploading(false);
        refetchAttachments();
      },
    },
  );
  const { uploadFile } = useUploadFile({
    path: 'private/resumes',
    onSuccess: (file) => {
      createAttachment.mutate({ contactId, fileId: file.id });
    },
  });
  const onUploadClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        setIsUploading(true);
        uploadFile(file);
      }
    };
    input.click();
  };
  return (
    <Box css={{ mt: 4 }}>
      <Flex
        css={{
          border: '1px solid $neutral-blue-300',
          height: 38,
          borderRadius: '$2xl',
        }}
      >
        <Icon
          name='attachment'
          size={18}
          css={{ m: 8, fill: '$neutral-blue-800' }}
        />
        <Text
          css={{
            color: '$neutral-blue-600',
            fontSize: '$sm',
            flexGrow: 1,
            cursor: 'default',
          }}
        >
          Add resume
        </Text>
        <Button
          variant='secondary'
          onClick={onUploadClick}
          css={{ p: 0, height: 24, m: 8, borderRadius: 8 }}
        >
          {isUploading ? (
            <Spinner />
          ) : (
            <Icon name='upload' size={14} css={{ mx: 4, fill: '$blue-600' }} />
          )}
        </Button>
      </Flex>
      {!!attachmentsResponse?.data.length && (
        <Box css={{ flexGrow: 1, mt: 8 }}>
          {attachmentsResponse?.data.map((attachment) => (
            <FileAttachment
              key={attachment.id}
              attachment={{ name: attachment.file?.name || '' }}
              onOpenClick={() => window.open(attachment.file?.gcsUrl, '_blank')}
              onDeleteClick={() =>
                apiClient.attachment.deleteAttachment({ id: attachment.id })
              }
              afterDelete={() => refetchAttachments()}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AttachmentManager;
