import React from 'react';
import styles from './Separator.module.scss';

interface SeparatorProps {
  className?: string;
  vertical?: boolean;
}

const Separator = ({ className, vertical }: SeparatorProps) => (
  <div
    className={`${
      vertical ? styles.vertical_separator : `w-full ${styles.separator}`
    } ${className}`}
  />
);

export default Separator;
