import React from 'react';
import SenderConnectCallbackTemplate from '../components/templates/SenderConnect/SenderConnectCallback.template';

const SenderConnectCallback = () => (
  <React.StrictMode>
    <SenderConnectCallbackTemplate />
  </React.StrictMode>
);

export default SenderConnectCallback;
