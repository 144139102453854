import { Box, BoxProps } from '@betterleap/ui';
import { useMemo } from 'react';
import Lottie, { Options } from 'react-lottie';
import gradient from '../../../assets/lotties/gradient.json';

export const WaveLoader = ({ css, ...rest }: BoxProps) => {
  const animationOptions = useMemo(() => {
    return {
      loop: true,
      autoplay: true,
      animationData: gradient,
    } as Options;
  }, []);

  return (
    <Box
      css={{
        position: 'relative',
        width: '100%',
        height: 200,
        pointerEvents: 'none',
        overflow: 'hidden',
        ...css,
      }}
      {...rest}
    >
      <Box
        css={{
          position: 'absolute',
          width: '100%',
          zIndex: 10,
          bottom: 0,
          left: 0,
          right: 0,
          '@lg': {
            bottom: -50,
          },
        }}
      >
        <Lottie options={animationOptions} />
      </Box>
    </Box>
  );
};
