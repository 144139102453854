import {
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Divider,
  Icon,
} from '@betterleap/ui';
import { useAtomValue } from 'jotai/react';
import { useState } from 'react';
import { ListItemButton } from './RecentSavedSearches';
import { filterState } from './SourceCandidates.template';
import { SourceCandidatesSearchParams } from './SourceCandidates.types';

export interface SourcingFilterGroupProps extends BoxProps {
  groupTitle: string;
  filterKeys: (keyof SourceCandidatesSearchParams)[];
}

export interface SourcingFilterGroupBadge extends BadgeProps {
  filterKeys: (keyof SourceCandidatesSearchParams)[];
}

export const SourcingFilterGroupBadge = ({
  filterKeys = [],
  ...rest
}: SourcingFilterGroupBadge) => {
  const personSearchParams = useAtomValue(filterState);

  const filterCount = filterKeys.reduce((acc, key) => {
    const paramValue = personSearchParams[key];
    if (Array.isArray(paramValue)) {
      return acc + paramValue.length;
    } else {
      return acc + (paramValue ? 1 : 0);
    }
  }, 0);

  if (!filterCount) {
    return null;
  }

  return (
    <Badge css={{ ml: 8 }} variant={'purple'} size='xs' {...rest}>
      {filterCount}
    </Badge>
  );
};

export const SourcingFilterGroup = ({
  groupTitle,
  filterKeys,
  children,
  ...rest
}: SourcingFilterGroupProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box {...rest}>
      <Collapsible onOpenChange={setIsOpen} {...rest}>
        <Divider />
        <CollapsibleTrigger asChild>
          <ListItemButton
            css={{
              fontWeight: '$medium',
            }}
            label={
              <>
                {groupTitle}
                <SourcingFilterGroupBadge filterKeys={filterKeys} />
              </>
            }
            variant='headless'
          >
            <Icon
              css={{
                open: { transform: 'rotate(180deg)' },
                borderRadius: '$lg',
              }}
              name='chevron-down'
              data-open={isOpen}
            />
          </ListItemButton>
        </CollapsibleTrigger>
        <CollapsibleContent css={{ px: 16, pb: 16 }}>
          {children}
        </CollapsibleContent>
      </Collapsible>
    </Box>
  );
};
