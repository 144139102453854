/* eslint-disable @typescript-eslint/no-explicit-any */
import { Contact, Project } from '@betterleap/client';
import { ContactEditor } from '@betterleap/shared';
import {
  AlertIcon,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PromiseModal,
  showToast,
} from '@betterleap/ui';

export interface EditContactModalProps {
  contact: Partial<Contact>;
  onContactUpdated?: (contact?: Contact) => void;
  enrichmentMode?: Project.contactEmailEnrichmentMode;
}

const EditContactModal: PromiseModal<EditContactModalProps> = ({
  onSubmit,
  contact,
  onContactUpdated = () => {},
}) => {
  return (
    <Modal size='medium'>
      <ModalHeader>
        <Flex justify='center' css={{ py: 8 }}>
          <AlertIcon variant='info' name='identification' size='lg' />
        </Flex>
        <ModalTitle css={{ paddingBottom: 32 }} dataCy='Edit contact modal'>
          Edit Contact Details
        </ModalTitle>
      </ModalHeader>
      <ModalBody css={{ p: 4 }}>
        <ContactEditor
          contact={contact as Contact}
          onContactUpdated={onContactUpdated}
          onUpdateError={(err: string) => {
            showToast({
              title: 'Update failed.',
              description: err,
            });
          }}
        />
      </ModalBody>
      <ModalFooter css={{ justifyContent: 'center', display: 'flex', pt: 32 }}>
        <Button
          dataCy='Save button'
          css={{ width: 100 }}
          onClick={() => onSubmit()}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditContactModal;
