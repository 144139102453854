import { ContactPreview } from '@betterleap/client';
import { Flex, Text } from '@betterleap/ui';
import { ContactProfileLinks } from 'components/elements/CandidatesTable/Elements/ContactProfileLinks';
import { FeatureFlagKey, useFeatureFlag } from 'hooks/useFeatureFlag';
import { SourcingResultExperience } from './SourcingResultExperience';
import { SourcingResultEducation } from './SourcingResultEducation';
import { SourcingResultName } from './SourcingResultName';
import { SourcingResultSkills } from './SourcingResultSkills';
import { SourcingResultProjects } from './SourcingResultProjects';
import { SourcingResultLanguages } from './SourcingResultLanguages';
import { SourcingResultCertifications } from './SourcingResultCertifications';
import { SourcingResultLicensedStates } from './SourcingResultLicensedStates';

interface Props {
  center?: boolean;
  onViewed?: (value: string) => void;
  viewedContacts?: string[];
  enableFeedback?: boolean;
}

export const SourcingSearchResult = ({
  center,
  onViewed,
  viewedContacts,
  enableFeedback,
}: Props) => ({
  Header: 'RESULT',
  css: {
    position: 'relative',
  },
  accessor: (contact: ContactPreview & { contactId?: string }) => {
    const markAsViewed = () => onViewed && onViewed(contact.id);
    const showLicensedStates = useFeatureFlag(
      FeatureFlagKey.HEALTHCARE_SOURCING_FILTERS,
    );

    return (
      <Flex vertical css={{ gap: 4, width: '100%' }} id='search_result'>
        <SourcingResultName
          contact={contact}
          isViewed={!!viewedContacts?.includes(contact.id)}
          enableFeedback={enableFeedback}
        />
        <SourcingResultJob contact={contact} />
        <SourcingResultLocation contact={contact} />
        <ContactProfileLinks contact={contact} onLinkClicked={markAsViewed} />
        {!!contact.experience?.length && (
          <SourcingResultExperience
            experience={contact.experience}
            markAsViewed={markAsViewed}
          />
        )}
        {!!contact.education?.length && (
          <SourcingResultEducation
            markAsViewed={markAsViewed}
            education={contact.education}
          />
        )}
        {!!contact.certifications?.length && (
          <SourcingResultCertifications
            markAsViewed={markAsViewed}
            certifications={contact.certifications}
          />
        )}
        {!!contact.skills?.length && (
          <SourcingResultSkills skills={contact.skills} center={center} />
        )}
        {!!contact?.languages?.length && (
          <SourcingResultLanguages languages={contact?.languages} />
        )}
        {showLicensedStates && !!contact?.licensedStates?.length && (
          <SourcingResultLicensedStates
            licensedStates={contact?.licensedStates}
          />
        )}
        {!!contact?.contactProjects?.length && (
          <SourcingResultProjects projects={contact?.contactProjects} />
        )}
      </Flex>
    );
  },
  slot: 'custom',
});

const SourcingResultLocation = ({ contact }: { contact: ContactPreview }) => (
  <Text css={{ color: '$neutral-blue-700', fontSize: '$sm' }}>
    {contact.locationName}
  </Text>
);

interface JobProps {
  contact: ContactPreview;
}
const SourcingResultJob = ({ contact }: JobProps) => (
  <Flex css={{ gap: 12 }}>
    <Text css={{ fontSize: '$sm' }}>{contact.currentTitle}</Text>
    <Text css={{ color: '$neutral-blue-700', fontSize: '$sm' }}>
      {contact.currentCompany}
    </Text>
  </Flex>
);
