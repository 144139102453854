import React from 'react';
import { Text, TextProps } from '@betterleap/ui';
import { FieldError } from 'react-hook-form';

interface StepErrorProps extends TextProps {
  error?: Record<string, FieldError>;
}

export const StepError = ({ error = {} }: StepErrorProps) => {
  const errors = Object.values<FieldError>(error);
  const displayedError = errors?.[0]?.message;

  return (
    <Text css={{ pt: 4, color: '$danger-base' }} size='sm'>
      {displayedError}
    </Text>
  );
};
