import React from 'react';
import Container from '../elements/Container/Container';
import Wrapper from '../elements/Wrapper/Wrapper';
import Header from '../modules/Header/Header';

function withHeader<P extends JSX.IntrinsicAttributes>(
  WrappedComponent: React.ComponentType<P>,
) {
  return (props: P) => (
    <Wrapper>
      <>
        <Header />
        <Container>
          <WrappedComponent {...props} />
        </Container>
      </>
    </Wrapper>
  );
}

export default withHeader;
