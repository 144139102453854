import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { User } from '@betterleap/client';
import routeNames from '../../../constants/routeNames';
import TOKEN from '../../../lib/token';
import { selectMe } from '../../../store/api/selectors';

type CompanyRouteProps = { element: JSX.Element };

const CompanyRoute = ({ element }: CompanyRouteProps) => {
  const me = useSelector(selectMe);
  const location = useLocation();

  const isCompany =
    !!me.data && [User.role.COMPANY_ADMIN].includes(me.data?.role);
  const routeComponent = () => {
    if (!TOKEN.get() || !!me.error || (me.data && !isCompany)) {
      return <Navigate to={routeNames.home()} state={location.state} />;
    }
    if (isCompany) return element;
    return <></>;
  };

  return routeComponent();
};

export default CompanyRoute;
