import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import styles from './Modal.module.scss';

export interface ModalProps {
  isOpen?: boolean;
  handleClose?: () => void;
  modalClassname?: string;
  children?: React.ReactNode;
  containerClassname?: string;
  size?: 'small' | 'med' | 'large';
  _key?: string;
}

const Modal = ({
  isOpen,
  handleClose,
  modalClassname,
  children,
  containerClassname,
  size = 'small',
  _key,
}: ModalProps) => {
  const node = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (isOpen) body.style.overflow = 'hidden';
    else {
      body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const onClose = (e: MouseEvent) => {
    if (node.current && !node.current.contains(e.target as Node)) {
      if (handleClose) handleClose();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', onClose, false);
    return () => document.removeEventListener('mousedown', onClose, false);
  }, []);

  return isOpen ? (
    <div
      data-cy={`modal-${_key}`}
      key={_key}
      className={`flex justify-center items-center fixed top-0 right-0 h-screen p-4 ${styles.modal_container} ${containerClassname}`}
    >
      <div
        ref={node}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={classnames(
          `p-6 rounded-lg border border-solid flex flex-col tablet:flex-col relative  mt-2 justify-center items-center bg-white ${styles.modal} ${modalClassname}`,
          {
            'max-w-3xl': size === 'large',
            'max-w-xl': size === 'med',
          },
        )}
      >
        {children}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Modal;
