import {
  Contact,
  CreateManyContactProjectsDto,
  SourceManyContactsDto,
} from '@betterleap/client';
import { showToast } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { useMutation } from 'react-query';
import { ContactPreviewSearchResult } from './SourceCandidates.template';

interface useAddContactsToProjectProps {
  mode: 'add' | 'source';
  source: CreateManyContactProjectsDto.source;
  onSuccess?: (newContacts: Contact[]) => void;
  onError?: (err: unknown) => void;
}

export const useAddContactsToProject = ({
  mode,
  source,
  onSuccess,
  onError,
}: useAddContactsToProjectProps) => {
  const sourceManyContacts = useMutation(
    (data: { requestBody: SourceManyContactsDto }) =>
      apiClient.contact.sourceManyContacts(data),
    {
      onSuccess: (response) => {
        showToast({
          variant: 'success',
          title: 'Success!',
          description: `${response.data.contactsAddedToProject} contacts added to project.`,
        });

        onSuccess?.(response.data.updatedContacts);
      },
      onError: (err) => {
        showToast({
          variant: 'danger',
          title: 'Adding contacts to project failed',
          description: 'Something went wrong. Please try again.',
        });

        onError?.(err);
      },
    },
  );

  const createManyContactProjects = useMutation(
    (data: { requestBody: CreateManyContactProjectsDto }) =>
      apiClient.contactProject.createMany(data),
    {
      onSuccess: (response) => {
        onSuccess?.([]);

        showToast({
          variant: 'success',
          title: 'Success!',
          description: `${response.data.contactsAddedToProject} contacts added to project.`,
        });
      },
      onError: (err) => {
        onError?.(err);

        showToast({
          variant: 'danger',
          title: 'Adding contacts to project failed',
          description: 'Something went wrong. Please try again.',
        });
      },
    },
  );

  return (projectId: string, contacts: ContactPreviewSearchResult[]) => {
    if (mode === 'add') {
      createManyContactProjects.mutate({
        requestBody: {
          contactIds: contacts.map((contact) => contact.contactId as string),
          source,
          projectId,
        },
      });
    } else {
      sourceManyContacts.mutate({
        requestBody: {
          contacts,
          projectId,
        },
      });
    }
  };
};
