import { showToast, useModal } from '@betterleap/ui';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Types } from '@betterleap/shared';
import useAnalytics from '../../../hooks/analytics';
import api from '../../../lib/api';
import FeedbackModal from './FeedbackModal';
import { SamplePrompt } from './GeneralCopilotTab';
import { Copilot } from './Copilot';
import { FeedbackButtons } from './FeedbackButtons';
import { ChartData } from './Charts';
import { AssistantLoader } from './AssistantLoader';

const rolePrompts = [
  'How many candidates did my team source between February and today? ',
  'On which days of the week is my team performing the most sourcing activities, and is this activity evenly distributed or concentrated on specific days?',
  'What jobs have the highest conversion rate?',
  'What is the percentage distribution of sourced candidates based on their job titles?',
];

const followUpPrompts = [
  'What days and times do people open our emails the most?',
  'How are our weekly open rates trending over time?',
  'What are the commonly used keywords & phrases across messages that get the most opens?',
  'What is the typical length of messages that get the most replies?',
  'What are the common links shared in messages that get the most opens?',
];

export const samplePrompts: Record<string, SamplePrompt> = {
  'Email Engagement Analytics': {
    prompts: followUpPrompts,
    icon: 'paper-airplane',
    variant: 'purple',
  },
  'Sourcing Analytics': {
    prompts: rolePrompts,
    icon: 'emoji-happy',
    variant: 'success',
  },
};

export enum FeedbackSentiment {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

const defaultResultState = {
  question: '',
  output: '',
  data: '',
  dbResultId: '',
};

const AnalyticsCopilotTab = (): JSX.Element => {
  const { track } = useAnalytics();
  const [textPrompt, setTextPrompt] = useState('');
  const [query, setQuery] = useState('');
  const [result, setResult] = useState(defaultResultState);
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackProvided, setFeedBackProvided] = useState(false);

  const [feedbackSentiment, setFeedbackSentiment] =
    useState<null | FeedbackSentiment>(null);

  const [isLoadingVisualization, setIsLoadingVisualization] = useState(false);
  const [chartData, setChartData] = useState<null | ChartData>(null);

  const openFeedbackModal = useModal<{
    sentiment: FeedbackSentiment;
    handleSubmitFeedback: (feedback: string) => void;
    onCancel: () => void;
  }>(FeedbackModal);

  interface AgentResponse {
    data: LLMResponse;
  }

  interface LLMResponse {
    data: string;
    output: string;
    question: string;
    dbResultId: string;
  }

  interface VisResponse {
    data: string;
  }

  interface QuillOp {
    insert?: string | unknown;
    delete?: number;
    retain?: number;
    attributes?: Record<string, unknown>;
  }

  const getVisualizationData = useMutation(
    (llmResponse: LLMResponse) =>
      api.fetch<VisResponse>('ai_database_agent_visualization', {
        ...llmResponse,
      }),
    {
      onSuccess: ({ data }) => {
        setIsLoadingVisualization(false);
        setChartData(JSON.parse(data.data));
      },
      onError: () => {
        setIsLoadingVisualization(false);
        showToast({
          variant: 'danger',
          title: 'Error generating visualization!',
          description: 'Something went wrong, please try again.',
        });
      },
    },
  );

  type AgentFeedbackParams = {
    agentResponse: LLMResponse;
    feedback: string;
    sentiment: FeedbackSentiment;
    chartData: ChartData | null;
  };

  const submitAgentFeedback = useMutation(
    (params: AgentFeedbackParams) =>
      api.fetch('ai_database_agent_feedback', {
        ...params,
      }),
    {
      onSuccess: () => {
        setFeedBackProvided(true);
        showToast({
          variant: 'success',
          title: 'Feedback submitted!',
          description: 'Thank you for your feedback, we appreciate it!',
        });
      },
      onError: () => {
        setIsLoadingVisualization(false);
        showToast({
          variant: 'danger',
          title: 'Error submitting feedback!',
          description: 'Something went wrong, please try again.',
        });
      },
    },
  );

  const queryDatabaseAgent = useMutation(
    (agentQuery: string) =>
      api.fetch<AgentResponse>('ai_database_agent', { query: agentQuery }),
    {
      onSuccess: ({ data: agentResponse }) => {
        setIsLoading(false);
        setResult({ ...agentResponse.data });

        if (agentResponse.data.data) {
          setIsLoadingVisualization(true);
          getVisualizationData.mutate(agentResponse.data);
        }
      },
      onError: () => {
        setIsLoading(false);
        showToast({
          variant: 'danger',
          title: 'Oops something went wrong!',
          description:
            'Something went wrong with the analytics copilot, please try again!',
        });
      },
    },
  );

  const onSubmit = () => {
    if (!textPrompt) return;

    setFeedBackProvided(false);
    setFeedbackSentiment(null);
    setIsLoading(true);
    setResult(defaultResultState);
    setChartData(null);

    track(Types.ANALYTICS_CLIENT_EVENT.AI_DATABASE_ASSISTANT_PROMPT_SUBMIT, {
      promptText: textPrompt,
    });

    queryDatabaseAgent.mutate(query);
  };

  const handleOpenFeedbackModal = (sentiment: FeedbackSentiment) => {
    setFeedbackSentiment(sentiment);
    openFeedbackModal({
      sentiment,
      onCancel: () => {
        setFeedbackSentiment(null);
      },
      handleSubmitFeedback: (feedback) => {
        submitAgentFeedback.mutate({
          agentResponse: result,
          feedback,
          sentiment,
          chartData,
        });
      },
    });
  };

  const handleClear = () => {
    setTextPrompt('');
    setQuery('');
    setResult(defaultResultState);
    setChartData(null);
  };

  const feedbackButtons = (
    <FeedbackButtons
      sentiment={feedbackSentiment}
      result={result.output}
      feedbackProvided={feedbackProvided}
      handleOpenFeedbackModal={handleOpenFeedbackModal}
    />
  );

  return (
    <Copilot
      editorTitle='What would you like to know about your data?'
      editorPlaceholder='Ex: How many email opens did we get last week?'
      submitText='Submit'
      result={result.output}
      samplePrompts={samplePrompts}
      textPrompt={textPrompt}
      setTextPrompt={setTextPrompt}
      handleClear={handleClear}
      onSubmit={onSubmit}
      onEditorChange={(content, delta, source, editor) => {
        setTextPrompt(content);

        // Generating a plain text query from the editor content to save tokens
        const { ops } = editor.getContents();
        let plainText = '';
        if (!ops?.length) return;
        ops.forEach((op: QuillOp) => {
          if (typeof op.insert === 'string') {
            plainText += op.insert;
          }
        });
        setQuery(plainText);
      }}
      isLoading={isLoading}
      loadingComponent={<AssistantLoader />}
      feedbackButtons={feedbackButtons}
      isLoadingVisualization={isLoadingVisualization}
      chartData={chartData}
    />
  );
};

export default AnalyticsCopilotTab;
