import { Box, Flex, FlexProps, Icon, Text } from '@betterleap/ui';
import { ReactNode } from 'react';
import Section from './Section';

interface ValuePropSectionProps extends Omit<FlexProps, 'title'> {
  title: ReactNode;
  description: string;
  image: string;
  imageMobile: string;
  reverse?: boolean;
}

const ValuePropSection = ({
  title,
  description,
  image,
  imageMobile,
  reverse,
  css,
  ...rest
}: ValuePropSectionProps): JSX.Element => {
  return (
    <Section
      css={{
        px: 0,
        ...css,
      }}
      {...rest}
    >
      <Flex
        css={{
          gap: 30,
          flexDirection: 'column',
          mediaLg: {
            flexDirection: reverse ? 'row-reverse' : 'row',
            gap: 120,
          },
        }}
      >
        <Box css={{ flex: 1, px: 24, mediaLg: { px: 0 } }}>
          <Flex
            css={{
              alignItems: 'flex-start',
              mb: 32,
              gap: 8,
              mediaLg: { mb: 48 },
            }}
          >
            <div>
              <Icon
                name='arrow-right'
                color='$purple-400'
                css={{
                  transform: 'rotate(45deg)',
                  mt: 6,
                  height: 32,
                  width: 32,
                  mediaLg: {
                    mt: 8,
                    height: 40,
                    width: 40,
                  },
                }}
              />
            </div>
            <Text
              css={{
                lineHeight: '130%',
                fontSize: 32,
                mediaLg: { fontSize: 48 },
              }}
            >
              {title}
            </Text>
          </Flex>
          <Text
            css={{
              lineHeight: '170%',
              fontSize: 18,
              mediaLg: { fontSize: 20 },
            }}
          >
            {description}
          </Text>
        </Box>
        <Box
          css={{
            height: 400,
            width: '100%',
            mediaLg: { flex: 1.8, width: 'auto', height: 450 },
          }}
        >
          <Box
            css={{
              objectFit: 'fill',
              width: '100%',
              height: '100%',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              backgroundImage: `url(${imageMobile})`,
              mediaMd: {
                minWidth: 495,
                backgroundPosition: 'center',
                backgroundImage: `url(${image})`,
              },
            }}
          />
        </Box>
      </Flex>
    </Section>
  );
};

export default ValuePropSection;
