import { Flex, Text } from '@betterleap/ui';

export interface InitialsBoxProps {
  name?: string;
}

const InitialsBox = (props: InitialsBoxProps) => {
  const { name } = props;
  const initials = ((name || '').toUpperCase().match(/\b(\w)/g) || [])
    .slice(0, 2)
    .join('');

  return (
    <Flex
      css={{
        width: '100%',
        maxWidth: 24,
        height: 24,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Text
        as='p'
        css={{
          color: '$text',
          fontSize: '$xl',
          padding: 4,
          fontWeight: '$medium',
        }}
      >
        {initials}
      </Text>
    </Flex>
  );
};

export default InitialsBox;
