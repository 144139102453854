import { Types } from '@betterleap/shared';
import { CreateManyInvitationsResponseDto } from '@betterleap/client';
import { Button, showToast, useModal } from '@betterleap/ui';
import InviteMembersModal from 'components/modules/Modal/InviteMembersModal/InviteMembersModal';
import useAnalytics from 'hooks/analytics';
import { useDispatch } from 'react-redux';
import { organizationActions } from 'store/organization/actions';

interface InviteMembersButtonProps {
  onMemberInvited: () => void;
}

export const InviteMembersButton = ({
  onMemberInvited,
}: InviteMembersButtonProps) => {
  const { track } = useAnalytics();
  const dispatch = useDispatch();

  const openInviteModal = useModal<undefined, CreateManyInvitationsResponseDto>(
    InviteMembersModal,
  );

  const handleInviteUser = async () => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Invite members',
      buttonLocation: 'Team',
    });

    const result = await openInviteModal();

    if (result) {
      if (result.newInvitations.length) {
        showToast({
          title: 'Invites sent!',
          description: 'Your teammates will receive an email invite',
        });
      }

      if (result.failedInvitations.length) {
        showToast({
          variant: 'danger',
          title: 'Invites not sent!',
          description: `The following invites failed to send: ${result.failedInvitations
            .map((invite) => invite.inviteEmail)
            .join(', ')}.`,
        });
      }

      dispatch(organizationActions.refetchOrganization());

      onMemberInvited();
    }
  };

  return (
    <Button
      variant='primary'
      css={{
        width: '100%',
        maxWidth: 300,
        mediaMd: { ml: 8, width: 'unset' },
      }}
      onClick={handleInviteUser}
      dataCy='Invite members'
    >
      Invite members
    </Button>
  );
};
