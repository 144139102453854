import {
  Button,
  Modal,
  ModalDescription,
  ModalImage,
  ModalProps,
  ModalTitle,
} from '@betterleap/shared';
import confirmation from '@betterleap/shared/dist/assets/images/confirmation.svg';
import React from 'react';

export const KEY = 'submit-candidate-success-modal';

export type SubmitCandidateSuccessModalProps = {
  onClose?: () => void;
  onSubmitAnother?: () => void;
} & ModalProps;

const SubmitCandidateSuccessModal = ({
  onClose,
  onSubmitAnother,
  ...props
}: SubmitCandidateSuccessModalProps) => {
  return (
    <Modal _key={KEY} handleClose={onClose} size='med' {...props}>
      <ModalImage icon={confirmation} />
      <ModalTitle title='Candidate submitted!' />
      <ModalDescription description='We’ve notified the company about your candidate submission. You can monitor the candidate’s status in Candidates section.' />
      <div className='flex pt-8 w-full mobile:flex-wrap tablet:flex-nowrap'>
        <Button
          variant='ghost'
          buttonText='Back to dashboard'
          onClick={() => {
            onClose?.();
          }}
          className='w-full tablet:mr-2 mobile:mr-0 mobile:mb-2'
          loading={false}
        />
        <Button
          variant='normal'
          buttonText='Submit another candidate'
          onClick={() => {
            onSubmitAnother?.();
          }}
          className='w-full'
          loading={false}
        />
      </div>
    </Modal>
  );
};

export default SubmitCandidateSuccessModal;
