import { AlertIcon, Button, Flex, IconButton } from '@betterleap/ui';
import { useCopyToClipboard } from 'usehooks-ts';
import { ContactPhoneNumber } from '@betterleap/client';

export interface ContactPhoneNumberItemProps {
  allowRemoval: boolean;
  contactPhoneNumber: ContactPhoneNumber;
  handleRemovePhoneNumber: (
    contactPhoneNumber: ContactPhoneNumber,
  ) => Promise<void>;
  onPhoneNumberCopied?: () => void;
}

const ContactPhoneNumberItem = ({
  allowRemoval,
  contactPhoneNumber,
  handleRemovePhoneNumber,
  onPhoneNumberCopied,
}: ContactPhoneNumberItemProps) => {
  const [, copy] = useCopyToClipboard();

  return (
    <Flex
      css={{
        px: 8,
        py: 4,
        justifyContent: 'space-between',
        borderBottom: '1px solid $neutral-blue-300',
        gap: '8px',
      }}
    >
      <Flex css={{ width: '100%', overflow: 'hidden', gap: 8 }}>
        <AlertIcon
          variant='gray'
          name='phone'
          shape='rounded-square'
          css={{ flexShrink: 0 }}
        />
        <Button
          variant='link'
          css={{
            color: '$neutral-blue-700',
            fontWeight: 'normal',
            borderBottom: 'none',
            hover: {
              borderBottom: 'none',
              textDecoration: 'underline',
            },
          }}
          onClick={() => {
            copy(contactPhoneNumber.phoneNumber);
            onPhoneNumberCopied?.();
          }}
        >
          {contactPhoneNumber.phoneNumber}
        </Button>
      </Flex>
      <IconButton
        label='remove'
        name='minus'
        size='xs'
        color='$red-600'
        aria-hidden={!allowRemoval}
        css={{
          backgroundColor: '$red-100',
          ...(!allowRemoval && { hidden: { visibility: 'hidden' } }),
        }}
        onClick={(e) => {
          e.preventDefault();
          handleRemovePhoneNumber(contactPhoneNumber);
        }}
      />
    </Flex>
  );
};

export default ContactPhoneNumberItem;
