import { Box, Flex } from '@betterleap/ui';
import { Organization } from '@betterleap/client';
import { get } from 'lodash';
import graphicRed from '../../../assets/images/graphic-red.png';
import graphicYellow from '../../../assets/images/graphic-yellow.svg';
import graphicBlue from '../../../assets/images/graphic-blue.svg';
import InfoCard from '../../elements/InfoCard/InfoCard';
import Paywall from '../../elements/Paywall/Paywall';

interface PortfolioPaywallProps {
  tier: Organization.tier;
}

const paywallContent = {
  [Organization.tier.FREE_TRIAL_REQUESTED]: {
    title: 'Thank you for signing up!',
    description:
      'To provide you with a personalized experience, one of our account executives will reach out to schedule a demo.',
  },
  [Organization.tier.PAYWALL]: {
    title: `Create talent opportunities for your portfolio and 
    track success`,
    description: 'To get a demo, get in touch with our sales team.',
  },
};

const PortfolioPaywall = ({ tier }: PortfolioPaywallProps) => (
  <Box
    css={{
      width: '100%',
      margin: '0 auto',
      mediaMd: {
        width: '90%',
      },
      mediaLg: {
        width: '65%',
      },
    }}
  >
    <Flex align='center' vertical css={{ p: 24 }}>
      <Paywall
        title={get(paywallContent, `${tier}.title`)}
        description={get(paywallContent, `${tier}.description`)}
        location='Portfolio'
      />

      <Flex
        css={{
          gap: 74,
          mt: 60,
          flexWrap: 'wrap',
          justifyContent: 'center',
          p: 16,
        }}
      >
        <InfoCard
          title='Get Full Visibility'
          description='Get full visibility into your portfolio’s candidate pipeline and identify opportunities for improvement.'
          css={{ maxWidth: 300 }}
          image={
            <Box
              as='img'
              src={graphicYellow}
              css={{
                height: 182,
                marginTop: -5,
              }}
              alt='submit to portco'
            />
          }
        />
        <InfoCard
          title='Collaborate with Ease'
          description='Share projects with portfolio 
            companies and submit candidates 
            from your talent pool.'
          css={{ maxWidth: 257 }}
          image={
            <Box
              as='img'
              src={graphicRed}
              css={{ height: 178 }}
              alt='collaborate'
            />
          }
        />
        <InfoCard
          title='Track Success'
          description='Easily track how many candidates you submitted and how many were hired by portfolio companies.'
          css={{ maxWidth: 257 }}
          image={
            <Box
              as='img'
              src={graphicBlue}
              css={{ height: 178 }}
              alt='candida'
            />
          }
        />
      </Flex>
    </Flex>
  </Box>
);

export default PortfolioPaywall;
