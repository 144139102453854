import { QueueTask } from '@betterleap/client';
import { atom, useSetAtom } from 'jotai';
import { apiClient } from 'lib/apiClient';
import { useQuery } from 'react-query';

export const activeTaskState = atom<QueueTask[]>([]);

export const useGetTasks = (userId?: string) => {
  const setActiveTasks = useSetAtom(activeTaskState);
  const query = useQuery(
    ['queue-tasks', userId],
    async () => apiClient.queueTask.find({}),
    {
      enabled: !!userId,
      onSuccess: (response) => {
        const tasks = response.data;

        if (tasks.length) {
          setActiveTasks(tasks);
        }
      },
    },
  );

  return query;
};
