import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalDescription } from '@betterleap/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { authActions } from '../../../../store/auth/actions';
import styles from './VerifyEmailModal.module.scss';

interface IVerifyEmailModal {
  isOpen: boolean;
  modalClassname: string;
  token: string;
}
const VerifyEmailModal = ({
  isOpen,
  modalClassname,
  token,
}: IVerifyEmailModal) => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Modal containerClassname={modalClassname} isOpen={isOpen}>
      <ModalDescription description='Please confirm your email address' />
      <input
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        placeholder='Email'
        className={`bg-white rounded-md outline-none mobile:mt-4 text-sm leading-5 w-full ${styles.full_input_wrapper}`}
      />
      <Button
        variant='normal'
        buttonText='Confirm'
        onClick={() => {
          dispatch(
            authActions.confirmEmailLink(
              {
                token,
                email: email?.toLowerCase(),
              },
              { navigate, location },
            ),
          );
        }}
        className='w-full mt-5 tablet:mt-6'
      />
    </Modal>
  );
};

export default VerifyEmailModal;
