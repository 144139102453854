import { ExtendedContactProject } from '@betterleap/client';
import { useModal } from '@betterleap/ui';
import {
  ConfirmModal,
  ConfirmModalProps,
} from 'components/modules/Modal/ConfirmModal/ConfirmModal';
import { apiClient } from 'lib/apiClient';
import { useMutation } from 'react-query';

export const useSetInMailStatus = ({
  onStatusUpdated,
}: {
  onStatusUpdated: () => void;
}) => {
  const openConfirmModal = useModal<ConfirmModalProps>(ConfirmModal);

  const setExternallyContactedStatus = useMutation(
    ({ contactId, status }: { contactId: string; status: string }) =>
      apiClient.contact.setExternallyContactedStatus({ contactId, status }),
    {
      onSuccess: () => {
        onStatusUpdated();
      },
    },
  );

  const setInMailStatus = async (
    contact: ExtendedContactProject,
    checked: boolean,
    value?: string,
  ) => {
    if (contact.contactSequenceId && !checked && contact.isSequenceActive) {
      const confirmation = await openConfirmModal({
        icon: {
          variant: 'warning',
          name: 'exclamation-circle',
        },
        confirmationText: 'Resume',
        title:
          'Are you sure you want to update an InMail status for this contact?',
        description: 'This will resume a sequence for this contact.',
      });

      if (!confirmation) {
        return;
      }
    }

    setExternallyContactedStatus.mutate({
      contactId: contact.id,
      status: !checked ? (null as unknown as string) : (value as string),
    });
  };

  return { setInMailStatus };
};
