import { Box, BoxProps } from '@betterleap/ui';
import { merge } from 'lodash';

export const PanelLayout = ({ css, ...props }: BoxProps) => (
  <Box
    css={{
      display: 'grid',
      gridTemplateAreas: `
        "label"
        "main"
        "footer"
      `,
      gridTemplateRows: 'auto 1fr auto',
      height: '100%',
      ...css,
    }}
    {...props}
  />
);

PanelLayout.Label = function Label({ css, ...props }: BoxProps) {
  return <Box css={{ gridArea: 'label', ...css }} {...props} />;
};

PanelLayout.Main = function Main({ css, ...props }: BoxProps) {
  return (
    <Box css={{ gridArea: 'main', overflow: 'auto', ...css }} {...props} />
  );
};

PanelLayout.Vis = function Main({ css, ...props }: BoxProps) {
  return <Box css={{ ...css }} {...props} />;
};

PanelLayout.Footer = function Footer({ css, ...props }: BoxProps) {
  return <Box css={{ gridArea: 'footer', ...css }} {...props} />;
};

export const AssistantPage = ({ css, ...props }: BoxProps) => {
  const defaultCss = {
    display: 'grid',
    gridTemplateAreas: `
    "editor editor"
    "result result"
    `,
    columnGap: 16,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'minmax(200px, auto) 2.2fr',
    minHeight: '100%',
    '@lg': {
      gridTemplateAreas: `
      "editor editor sidebar"
      "result result sidebar"
      `,
      gridTemplateColumns: '1fr 1fr minmax(275px, 400px)',
      gridTemplateRows: 'minmax(200px, auto) 2.2fr',
    },
  };

  // merge the defaultCss with the overriding css passed as prop
  const mergedCss = merge({}, defaultCss, css);

  return <Box css={mergedCss} {...props} />;
};

AssistantPage.Editor = function Editor({ css, ...props }: BoxProps) {
  return (
    <Box
      css={{
        gridArea: 'editor',
        border: '1px solid $neutral-blue-300',
        borderBottomWidth: 0,
        backgroundColor: '$background-component',
        position: 'relative',
        borderTopLeftRadius: '$5xl',
        borderTopRightRadius: '$5xl',
        overflow: 'hidden',
        padding: '20px 24px 12px 24px',
        ...css,
      }}
      {...props}
    />
  );
};

AssistantPage.Result = function Result({ css, ...props }: BoxProps) {
  return (
    <Box
      css={{
        gridArea: 'result',
        border: '1px solid $neutral-blue-300',
        backgroundColor: '$background-component',
        position: 'relative',
        borderBottomLeftRadius: '$5xl',
        borderBottomRightRadius: '$5xl',
        padding: '20px 24px 12px 24px',
        overflow: 'hidden',
        ...css,
      }}
      {...props}
    />
  );
};

AssistantPage.Sidebar = function Sidebar({ css, ...props }: BoxProps) {
  return (
    <Box
      css={{
        gridArea: 'sidebar',
        display: 'none',
        border: '1px solid $neutral-blue-300',
        backgroundColor: '$background-component',
        borderRadius: '$5xl',
        px: 24,
        py: 20,
        '@lg': {
          display: 'block',
        },
        ...css,
      }}
      {...props}
    />
  );
};

export const RootLayout = ({ css, ...props }: BoxProps) => {
  const defaultCss = {
    display: 'grid',
    gridTemplateAreas: `
    "header header"
    "copilot copilot"
    `,
    columnGap: 16,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto 1fr',
    minHeight: '100%',
    px: 24,
    py: 20,
  };

  // merge the defaultCss with the overriding css passed as prop
  const mergedCss = merge({}, defaultCss, css);

  return <Box css={mergedCss} {...props} />;
};

RootLayout.Header = function Header({ css, ...props }: BoxProps) {
  return (
    <Box
      css={{
        gridArea: 'header',
        ...css,
      }}
      {...props}
    />
  );
};

RootLayout.Copilot = function Copilot({ css, ...props }: BoxProps) {
  return (
    <Box
      css={{
        gridArea: 'copilot',
        ...css,
      }}
      {...props}
    />
  );
};
