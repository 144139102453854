import { organizationColumns } from 'constants/organizationColumns';
import {
  getFullName,
  InteractiveTable,
  Types,
  useMe,
} from '@betterleap/shared';
import { GroupDto } from '@betterleap/client';
import { Button, showToast, useModal } from '@betterleap/ui';
import DeleteTeamMemberModal from 'components/modules/Modal/DeleteTeamMemberModal.tsx/DeleteTeamMemberModal';
import { apiClient } from 'lib/apiClient';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import useAnalytics from 'hooks/analytics';
import {
  GetOrganizationMembersResponse,
  InviteMember,
} from './OrganizationInviteTemplate';

interface MembersTableProps {
  invitees: GetOrganizationMembersResponse | undefined;
  groups: GroupDto[];
  isLoading: boolean;
  organizationCreatorId?: string | undefined;
  onGroupChanged: () => void;
  onMemberDeactivated: () => void;
}

export const MembersTable = ({
  invitees,
  groups,
  isLoading,
  organizationCreatorId,
  onGroupChanged,
  onMemberDeactivated,
}: MembersTableProps) => {
  const { track } = useAnalytics();
  const me = useMe();

  const members: InviteMember[] = useMemo(() => {
    const organizationMembers = (invitees?.data?.data || []).map((el) => ({
      id: el.id,
      email: el.email,
      name: getFullName(el),
      status: el.status
        ? Types.INVITATION_STATUS.PENDING
        : Types.INVITATION_STATUS.JOINED,
      invitationId: el.invitationId,
      invitationType: el.invitationType,
      organizationId: el.organizationId,
      groupId: el.groupId,
      roles: el.roles,
    }));

    return organizationMembers || [];
  }, [invitees?.data]);

  const openDeleteTeamMemberModal = useModal<InviteMember>(
    DeleteTeamMemberModal,
  );

  const deactivateMember = useMutation(
    (data: { id: string }) =>
      apiClient.deleteUser.deleteTeamMemberById({ id: data.id }),
    {
      onSuccess: () => {
        showToast({
          title: 'Deactivation Success',
          description: 'Member deleted sucessfully',
        });
        onMemberDeactivated();
      },
      onError: (error) => {
        const parsedData = JSON.parse(
          JSON.stringify((error as Record<string, unknown>).body),
        );
        if (parsedData?.error?.name === 'NotPermittedActionError') {
          showToast({
            variant: 'danger',
            title: 'Deactivation Failed',
            description:
              parsedData?.error?.data?.message ||
              'This user is not permitted to do this action',
          });
        } else if (parsedData?.error?.name === 'BadRequestError') {
          showToast({
            variant: 'danger',
            title: 'Deactivation Failed',
            description:
              'This user is the Creator of the Organization, and we don’t allow this user to do this.',
          });
        } else {
          showToast({
            variant: 'danger',
            title: 'Deactivation Failed',
            description: 'Something went wrong.',
          });
        }
      },
    },
  );

  const removeInvitation = useMutation(
    (data: { id: string }) =>
      apiClient.invitation.removeInvitation({ id: data.id }),
    {
      onSuccess: () => {
        showToast({
          title: 'Deactivation Success',
          description: 'Member deleted sucessfully',
        });
        onMemberDeactivated();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Deactivation Failed',
          description: 'Something went wrong.',
        });
      },
    },
  );

  const handleDeactivateMember = async (member: InviteMember) => {
    track(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName: 'Deactivate team member',
      buttonLocation: 'Team',
    });
    const result = await openDeleteTeamMemberModal(member);
    if (result) {
      const { id, invitationId } = member;
      if (id) {
        deactivateMember.mutate({ id });
      } else if (invitationId) {
        removeInvitation.mutate({ id: invitationId });
      } else {
        showToast({
          variant: 'danger',
          title: 'Deactivation Failed',
          description: 'Something went wrong.',
        });
      }
    }
  };

  const renderDeleteUserActionColumn = (member: InviteMember) => {
    const exclusions = [organizationCreatorId, me?.user?.id];
    if (
      exclusions.includes(member.id) ||
      !me.ability.can('update', 'User', 'roles')
    ) {
      return <></>;
    }

    return (
      <Button
        variant='danger-secondary'
        size='xs'
        onClick={() => handleDeactivateMember(member)}
      >
        Deactivate
      </Button>
    );
  };

  const tableColumns = [
    ...organizationColumns.columns(groups, () => {
      onGroupChanged();
    }),
    {
      id: 'delete-user-action',
      Header: '',
      width: 72,
      accessor: renderDeleteUserActionColumn,
      slot: 'custom',
    },
  ];

  return (
    <InteractiveTable
      tableData={members}
      columns={tableColumns}
      loading={isLoading}
      count={invitees?.data?.totalSize ?? 0}
    />
  );
};
