import { Organization } from '@betterleap/client';
import { BoxProps, Button, Flex, Icon, Spinner, Text } from '@betterleap/ui';

export interface ConnectIntegrationProps {
  organization: Organization;
  isLoading: boolean;
  openMergeLink: () => void;
}

export const IntegrationSetting = ({ children, css }: BoxProps) => (
  <Flex
    css={{
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      ...css,
      mediaMd: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        ...css?.mediaMd,
      },
    }}
  >
    {children}
  </Flex>
);

IntegrationSetting.Title = function Title({ children }: BoxProps) {
  return (
    <Text
      css={{
        fontSize: '$sm',
        lineHeight: '$lg',
        color: '$neutral-blue-900',
        fontWeight: '$medium',
        mb: 8,
      }}
    >
      {children}
    </Text>
  );
};

IntegrationSetting.Subtitle = function Subtitle({ children }: BoxProps) {
  return (
    <Text
      css={{
        fontSize: '$sm',
        lineHeight: '$lg',
        fontWeight: '$normal',
        color: '$neutral-blue-700',
      }}
    >
      {children}
    </Text>
  );
};

IntegrationSetting.ConnectText = function ConnectText({
  integrationName,
  icon,
}: {
  integrationName: string;
  icon: { src: string; width: number } | null | undefined;
}) {
  return (
    <Flex>
      {icon ? (
        <img width={icon.width} src={icon.src} alt='connected CRM icon' />
      ) : (
        <Icon name='briefcase' color='$neutral-blue-700' />
      )}
      <Text
        css={{
          fontSize: '$sm',
          lineHeight: '$xl',
          fontWeight: '$normal',
          color: '$neutral-blue-700',
          ml: 8,
        }}
      >
        Connected to {integrationName}
      </Text>
    </Flex>
  );
};

IntegrationSetting.ConnectButton = function ConnectButton({
  buttonText,
  isLoading,
  onClick,
}: {
  buttonText: string;
  isLoading: boolean;
  onClick: () => void;
}) {
  return (
    <Button
      onClick={() => {
        onClick();
      }}
      css={{
        color: '$primary-base',
        mt: 8,
        mediaMd: { mt: 'unset' },
        minWidth: 100,
      }}
      variant='headless'
      disabled={isLoading}
    >
      {isLoading ? <Spinner /> : buttonText}
    </Button>
  );
};
