import {
  AlertIcon,
  AlertIconProps,
  Flex,
  FlexProps,
  Text,
} from '@betterleap/ui';

interface SectionHeaderProps extends FlexProps {
  title: string;
  icon: AlertIconProps['name'];
  iconVariant?: AlertIconProps['variant'];
  textColor?: string;
}

export const SectionHeader = ({
  title,
  icon,
  iconVariant,
  css,
  textColor,
  ...rest
}: SectionHeaderProps) => {
  return (
    <Flex css={{ gap: 8, ...css }} {...rest}>
      <AlertIcon
        name={icon}
        variant={iconVariant || 'gray'}
        shape='rounded-square'
      />
      <Text size='lg' css={{ fontWeight: '$medium', color: textColor }}>
        {title}
      </Text>
    </Flex>
  );
};
