/* eslint-disable no-useless-escape */
export const urlRegex =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const linkedInRegex =
  /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/[a-zA-Z0-9-_À-ÿ]+(?:%[0-9A-Fa-f]{2})*[a-zA-Z0-9-_À-ÿ]*/gi;

export const phoneNumberRegex = /^[0-9\(\)\+\ \-\/]+$/;

// From: https://github.com/MaxEvan/pragmatic-email-regex/blob/master/src/index.ts
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailInviteRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const fullNameRegex = /^\s*(?:\S\s*){1,150}$/;

export const openingsRegex = /^([1-9]|10)$/;
