import { Icon, IconToken, Tooltip } from '@betterleap/ui';
import { Contact } from '@betterleap/client';

export interface ProjectTypeIconProps {
  mode: Contact.contactEmailEnrichmentMode;
}

const ProjectTypeIcon = (props: ProjectTypeIconProps) => {
  const { mode } = props;

  let iconName: IconToken = 'briefcase';
  let tooltipText = 'Recruiting project';
  if (mode === Contact.contactEmailEnrichmentMode.PROFESSIONAL) {
    iconName = 'currency-dollar';
    tooltipText = 'Sales project';
  }

  return (
    <Tooltip content={tooltipText}>
      <Icon name={iconName} color='$neutral-blue-700' size={20} />
    </Tooltip>
  );
};

export default ProjectTypeIcon;
