import { Location, NavigateFunction } from 'react-router-dom';
import { ActionsUnion, createAction } from '../action';

enum AUTH {
  CONFIRM_EMAIL_LINK = 'CONFIRM_EMAIL_LINK',
  SET_EMAIL_LINK_VERIFY_LOGIN = 'SET_EMAIL_LINK_VERIFY_LOGIN',
}

const authActions = {
  confirmEmailLink: (
    payload: { token: string; email: string },
    helpers: { navigate: NavigateFunction; location: Location },
  ) => createAction(AUTH.CONFIRM_EMAIL_LINK, payload, {}, helpers),
  setEmailLinkVerifyLoading: (data: boolean) =>
    createAction(AUTH.SET_EMAIL_LINK_VERIFY_LOGIN, data),
};

export type AuthAction = ActionsUnion<typeof authActions>;
export { AUTH, authActions };
