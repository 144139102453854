import uploadedIcon from '@betterleap/shared/dist/assets/images/uploaded-icon.svg';
import {
  Alert,
  AlertDescription,
  AlertHeading,
  AlertingIcon,
  Box,
  Button,
  Flex,
  Modal,
  PromiseModal,
  Spinner,
  Text,
} from '@betterleap/ui';
import { useState } from 'react';
import { UploadArea } from 'components/elements/UploadArea';
import CloseIcon from '../../../../assets/images/add-candidates-close.svg';
import InformationCircleIcon from '../../../../assets/images/information-circle.svg';
import styles from './CsvUploadModal.module.scss';

interface CsvUploadModalProps {
  title: string;
  columns: string[];
  requiredColumns: string[];
  columnHintBefore: string;
  columnHintAfter: string;
  parseFile: (file: File) => Promise<unknown[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: (results: any[]) => void;
}

const CsvUploadModal: PromiseModal<CsvUploadModalProps> = ({
  title,
  columns,
  requiredColumns,
  columnHintBefore,
  columnHintAfter,
  parseFile,
  onDismiss,
  onSubmit,
  onReject,
  handleSubmit,
}) => {
  const [file, setFile] = useState<File | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [parsedFile, setParsedFile] = useState<unknown[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState<string[]>([]);

  const handleFileUpload = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      setFile(acceptedFiles[0] as File);
      try {
        const rows = await parseFile(acceptedFiles[0] as File);
        if (rows.length === 0) {
          throw new Error('FieldsNotCorrect');
        }
        setParsedFile(rows);
        setErrorMessage('');
        setErrors([]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.message === 'FieldsNotCorrect')
          setErrorMessage('FieldsNotCorrect');
      }
    }
  };

  return (
    <Modal>
      <Flex vertical>
        <Flex css={{ width: '100%', justifyContent: 'space-between', mb: 32 }}>
          <Text as='h3' css={{ fontWeight: '$medium', fontSize: '$base' }}>
            {title}
          </Text>
          <Box css={{ cursor: 'pointer' }} onClick={onDismiss}>
            <img src={CloseIcon} alt='close_icon' />
          </Box>
        </Flex>
        {errorMessage === 'FieldsNotCorrect' && (
          <Alert css={{ mb: 16, alignItems: 'flex-start' }} variant='danger'>
            <AlertingIcon name='paper-clip' />
            <AlertHeading>
              <Text
                css={{
                  fontSize: '$sm',
                  fontWeight: '$medium',
                  mb: 4,
                }}
              >
                {file?.name}
              </Text>
              There were errors with your upload.
            </AlertHeading>
            <AlertDescription as='ul' css={{ listStyle: 'disc', pl: 14 }}>
              {requiredColumns.map((column) => (
                <Text key={column} as='li' inherit>
                  Missing column:{' '}
                  <Text inline inherit css={{ fontWeight: '$medium' }}>
                    {column}
                  </Text>
                </Text>
              ))}
            </AlertDescription>
          </Alert>
        )}
        {errorMessage === 'ContactCSVError' && (
          <Alert css={{ mb: 16, alignItems: 'flex-start' }} variant='danger'>
            <AlertingIcon name='paper-clip' />
            <AlertHeading>
              <Text
                css={{
                  fontSize: '$sm',
                  fontWeight: '$medium',
                  mb: 4,
                }}
              >
                {file?.name}
              </Text>
              There were {errors.length} errors in your CSV.
            </AlertHeading>
            <AlertDescription as='ul' css={{ listStyle: 'disc', pl: 14 }}>
              {errors.slice(0, 4).map((error, index) => (
                <Text key={index} as='li' inherit>
                  {error}
                </Text>
              ))}
              {errors.length > 5 && (
                <Text inherit css={{ ml: -14 }}>
                  ...And {errors.length - 5} more errors
                </Text>
              )}
            </AlertDescription>
          </Alert>
        )}
        {file && !errorMessage ? (
          <Flex
            className={styles.uploaded_area}
            align='center'
            justify='between'
            css={{ mt: 8, mediaMd: { mb: 24 } }}
          >
            <Flex>
              <img
                className={`mr-3 flex self-center ${styles.uploaded_area_img}`}
                src={uploadedIcon}
                alt='drag drop icon'
              />
              <Text
                as='p'
                css={{
                  maxWidth: 300,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {file?.name}
              </Text>
            </Flex>
            <Text
              css={{
                cursor: 'pointer',
                fontSize: '$sm',
                fontWeight: '$medium',
                color: '$blue-500',
              }}
              onClick={() => {
                setFile(null);
                setParsedFile([]);
              }}
              role='presentation'
            >
              Remove
            </Text>
          </Flex>
        ) : (
          <UploadArea
            id='uploadedFile'
            multiple={false}
            accept='.csv'
            description='CSV files only'
            onChange={handleFileUpload}
            inputProps={{ 'aria-describedby': 'error-file' }}
            maxFiles={1}
          />
        )}

        <Flex css={{ alignItems: 'flex-start', mt: 16 }}>
          <img
            src={InformationCircleIcon}
            alt='information_circle'
            className='mt-1 mr-2'
          />
          <Text
            css={{
              fontWeight: '$medium',
              fontSize: '$xs',
              color: '$text-light',
            }}
          >
            {columnHintBefore}
          </Text>
        </Flex>

        <Flex css={{ flexWrap: 'wrap', width: '100%', mt: 16, gap: 8 }}>
          {columns.map((column) => (
            <Box
              key={column}
              css={{
                p: '$2 $12',
                background: '$background-disabled-input',
                borderRadius: '$base',
              }}
            >
              <Text
                css={{ fontSize: '$xs', color: '#6B7280', textAlign: 'center' }}
              >
                {column}
              </Text>
            </Box>
          ))}
        </Flex>

        <Text
          css={{
            fontSize: '$xs',
            color: '$text-lightest',
            mt: 8,
          }}
        >
          {columnHintAfter}
        </Text>

        <Flex
          css={{ gap: 8, pt: 24, width: '100%', justifyContent: 'flex-end' }}
        >
          <Button variant='ghost' onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            css={{ minWidth: 82 }}
            disabled={!!(!file || errorMessage)}
            onClick={async () => {
              try {
                setLoading(true);
                const response = await handleSubmit(parsedFile);
                setLoading(false);
                onSubmit(response);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (err: any) {
                setLoading(false);
                const errorResponse = err.response?.data?.error?.data;
                if (errorResponse && errorResponse.name === 'ContactCSVError') {
                  setErrorMessage('ContactCSVError');
                  setErrors(errorResponse.error);
                } else {
                  onReject(err);
                }
              }
            }}
            dataCy='Upload Button'
          >
            {loading ? <Spinner variant='white' /> : 'Upload'}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
export default CsvUploadModal;
