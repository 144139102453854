import React from 'react';
import styles from './Container.module.scss';

interface ContainerProps {
  children: JSX.Element;
}

const Container = ({ children }: ContainerProps) => (
  <div className={`${styles.container} w-full`}>{children}</div>
);

export default Container;
