import { Box, BoxProps, Flex, IconButton, Spinner, Text } from '@betterleap/ui';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Types } from '@betterleap/shared';
import { User } from '@betterleap/client';
import { useLocation } from 'react-router-dom';
import { useConnectEmail } from 'hooks/useConnectEmail';
import useConnectIntegration from 'hooks/useConnectIntegration';
import { logToSegment } from 'functions/segmentLog';
import { selectMe, selectOrganization } from '../../../store/api/selectors';
import { OnboardingChecklistItem } from '../OnboardingChecklistItem/OnboardingChecklistItem';
import api from '../../../lib/api';

const whitelistedRoutePathList = ['/projects'];

export const OnboardingChecklist: React.FC<BoxProps> = ({ children, css }) => {
  const hideChecklist = localStorage.getItem('hideChecklist');
  const location = useLocation();
  const [openChecklist, setOpenChecklist] = useState(!hideChecklist);
  const [mergeSyncInitiated, setMergeSyncInitiated] = useState(false);

  const { open: triggerMergeLinkingFlow, isLoading } = useConnectIntegration({
    location: 'Onboarding Checklist',
    onSuccess: () => {
      setMergeSyncInitiated(true);
    },
  });

  const handleConnectEmail = useConnectEmail();

  const me = useSelector(selectMe);
  const organization = useSelector(selectOrganization);

  const { data: sendersResponse } = useQuery(['get_senders'], () =>
    api.fetch<{ data: Types.Sender[] }>('get_senders'),
  );

  const segmentOnboardingClick = (buttonName: string) =>
    logToSegment(Types.ANALYTICS_CLIENT_EVENT.BUTTON_CLICKED, {
      buttonName,
      buttonLocation: 'Onboarding Checklist',
    });

  const hasInitiatedMergeSync =
    mergeSyncInitiated || !!organization.data?.accountToken;
  const hasConnectedSender = !!sendersResponse?.data?.data?.find(
    (el) => el.userId === me.data?.id,
  );
  const hasAddedChromeExtension = me?.data?.hasAddedChromeExtension;

  const checked = useMemo(() => {
    if (organization?.data && sendersResponse?.data?.data && me.data)
      return (
        (hasInitiatedMergeSync ? 1 : 0) +
        (hasConnectedSender ? 1 : 0) +
        (hasAddedChromeExtension ? 1 : 0)
      );
    return null;
  }, [organization?.data, sendersResponse?.data?.data, me.data]);

  const completed = checked ? ((checked / 3) * 100).toFixed(0) : 0;

  if (!me.data || me.data?.role === User.role.NONE) return null;

  for (const brPrefix of whitelistedRoutePathList) {
    if (location.pathname !== brPrefix) {
      return null;
    }
  }

  return (
    <Flex
      id='checklist'
      vertical
      css={{
        alignItems: 'flex-end',
        zIndex: '$50',
        position: 'fixed',
        right: '20px',
        bottom: '20px',
        ...css,
      }}
    >
      <Box
        css={{
          width: '320px',
          height: '356px',
          backgroundColor: 'White',
          p: '36px 24px 0 24px',
          boxShadow: '$xl',
          borderRadius: '$lg',
          display:
            openChecklist && Number.isInteger(checked) && checked !== 3
              ? 'block'
              : 'none',
        }}
      >
        <IconButton
          size='sm'
          label='close'
          name='x'
          css={{
            position: 'absolute',
            right: '4px',
            top: '4px',
            background: 'none',
          }}
          onClick={() => {
            setOpenChecklist(false);
            localStorage.setItem('hideChecklist', 'true');
          }}
        />

        <Text
          css={{ fontWeight: '$medium', fontSize: '$2xl', lineHeight: '$3xl' }}
        >
          Get started with Betterleap
        </Text>
        <Text
          css={{
            color: '$neutral-blue-600',
            mt: '28px',
            fontSize: '10px',
          }}
        >
          {completed}% COMPLETE
          <Box css={{ position: 'relative', mb: '24px' }}>
            <Box
              css={{
                position: 'absolute',
                backgroundColor: '$neutral-blue-100',
                borderRadius: '$base',
                height: '8px',
                width: '256px',
              }}
            />
            <Box
              css={{
                position: 'absolute',
                zIndex: '110px',
                background:
                  'linear-gradient(90deg, #3A83F7 0.25%, #07B6D4 91.24%)',
                borderRadius: '$base',
                height: '8px',
                width: `calc( ${completed}% )`,
              }}
            />
          </Box>
          <OnboardingChecklistItem
            checked={hasInitiatedMergeSync}
            onClick={() => {
              triggerMergeLinkingFlow();
              segmentOnboardingClick('Connect ATS');
            }}
            label={
              isLoading ? <Spinner css={{ height: 20 }} /> : 'Connect your ATS'
            }
          />
          <OnboardingChecklistItem
            checked={hasConnectedSender}
            onClick={() => {
              handleConnectEmail();
              segmentOnboardingClick('Connect email');
            }}
            label='Connect your email'
          />
          <OnboardingChecklistItem
            checked={hasAddedChromeExtension}
            label='Download Chrome extension'
            onClick={() => {
              window.open(
                'https://chrome.google.com/webstore/detail/betterleap-sourcing/bimmbgomanhpkfodmiomjgfakleojpia',
              );
              segmentOnboardingClick('Download extension');
            }}
          />
        </Text>
      </Box>
      <IconButton
        css={{
          p: 16.5,
          mt: 8,
          fill: 'white',
          backgroundColor: '$blue-600',
          borderRadius: '$xl',
          hover: {
            backgroundColor: '$blue-700',
          },
          visibility:
            Number.isInteger(checked) && checked !== 3 ? 'visible' : 'hidden',
        }}
        onClick={() => {
          setOpenChecklist(true);
          localStorage.removeItem('hideChecklist');
        }}
        name='clipboard-list'
        label='Open onboarding checklist'
      />
      {children}
    </Flex>
  );
};
