import routeNames from 'constants/routeNames';
import { getFullName } from '@betterleap/shared';
import { ContactSequence } from '@betterleap/client';
import {
  Alert,
  AlertDescription,
  AlertHeading,
  AlertingIcon,
  AlertProps,
  Link,
} from '@betterleap/ui';
import { useNavigate } from 'react-router-dom';

interface PersonalizeSequenceAlertProps extends AlertProps {
  contactSequence: ContactSequence;
  title: string;
  sequenceId: string;
}

export const PersonalizeSequenceAlert = ({
  contactSequence,
  sequenceId,
  title,
  ...props
}: PersonalizeSequenceAlertProps) => {
  const navigate = useNavigate();

  return (
    <Alert
      css={{ border: '1px solid $blue-300', borderRadius: '$lg', mb: 16 }}
      {...props}
    >
      <AlertingIcon />
      <AlertHeading>{title}</AlertHeading>
      <AlertDescription>
        This is a personalized sequence for{' '}
        <Link
          css={{ fontWeight: '$bold', color: 'inherit' }}
          onClick={() =>
            navigate(
              routeNames.contactProfile({
                id: contactSequence.contact?.id as string,
              }),
            )
          }
        >
          {getFullName(contactSequence.contact)}
        </Link>
        . To view the original sequence click{' '}
        <Link
          css={{ fontWeight: '$bold', color: 'inherit' }}
          onClick={() =>
            navigate(routeNames.sequenceDetail({ id: sequenceId }))
          }
        >
          here
        </Link>
        .
      </AlertDescription>
    </Alert>
  );
};
