import { TagDto } from '@betterleap/client';
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  List,
  ListBox,
  ListBoxProps,
  ListInput,
  Menu,
  MenuContent,
  MenuItem,
  MenuTrigger,
  Option,
  showToast,
  Spinner,
  StyledBadge,
  Text,
  useControlledState,
} from '@betterleap/ui';
import { forwardRef, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { apiClient } from 'lib/apiClient';
import { ProjectTagList } from './ProjectTagList';

interface ProjectTagFilterMenuProps
  extends Omit<ListBoxProps, 'children' | 'onChange'> {
  selectedTags: TagDto[];
  onChange: (tags: TagDto[]) => void;
}

export const ProjectTagFilterMenu = forwardRef<
  HTMLButtonElement,
  ProjectTagFilterMenuProps
>(({ selectedTags, onChange }, ref) => {
  const { id } = useParams<{ id: string }>();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [stateValue, setStateValue] = useControlledState<TagDto[]>(
    selectedTags,
    undefined,
    onChange,
  );

  const {
    data: tagsResponse,
    isRefetching,
    refetch,
  } = useQuery(
    ['findProjectTags', { filter, id }],
    () =>
      apiClient.projectTag.findTags({
        search: filter,
        pageSize: 15,
      }),
    {
      keepPreviousData: true,
    },
  );

  const createTag = useMutation(
    (data: { name: string }) =>
      apiClient.projectTag.createTag({
        requestBody: {
          name: data.name,
        },
      }),
    {
      onSuccess: () => {
        refetch();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to create tag. Please try again.',
        });
      },
    },
  );

  const handleCreateTag = () => {
    setFilter('');
    createTag.mutate({
      name: filter,
    });
  };

  const tags = tagsResponse?.data?.tags ?? [];

  const notSelectedTags = useMemo(
    () => tags.filter((tag) => !stateValue?.find((t) => t.id === tag.id)),
    [tagsResponse],
  );

  return (
    <Menu
      open={open}
      closeOnSelect={false}
      modal={false}
      onDismiss={() => {
        setFilter('');
        setOpen(false);
      }}
    >
      <MenuTrigger
        asChild
        ref={ref}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Button
          variant='ghost'
          css={{
            gap: 8,
            justifyContent: 'flex-start',
            height: 40,
            minWidth: 320,
            border: '1px solid $neutral-blue-300',
            borderRadius: '$xl',
            fontSize: '$sm',
            color: '$text-placeholder',
            hover: {
              backgroundColor: '$background-component',
            },
          }}
        >
          <Icon color='$neutral-blue-700' name='filter' size={17} />
          {selectedTags.length ? (
            <ProjectTagList
              css={{ flex: 1 }}
              max={2}
              tags={selectedTags}
              onRemoveTag={(tagId) => {
                setStateValue(
                  (stateValue ?? []).filter((tag) => tag.id !== tagId),
                );
              }}
            />
          ) : (
            <Box as='span' css={{ pr: 4, pl: 2, flex: 1, textAlign: 'left' }}>
              Add Filters
            </Box>
          )}
          <Icon
            color='$neutral-blue-700'
            inherit
            name='chevron-down'
            size={17}
          />
        </Button>
      </MenuTrigger>
      <MenuContent
        style={{ paddingBottom: notSelectedTags.length ? 8 : 0 }}
        css={{ width: 320 }}
        align='end'
      >
        {!!selectedTags.length && (
          <Box css={{ pt: 6 }}>
            <Flex css={{ gap: 8, px: 16 }} wrap>
              {selectedTags.map((tag) => (
                <Badge
                  key={tag.id}
                  size='xs'
                  variant='gray'
                  shape='rectangle'
                  onRemove={() => {
                    setStateValue(
                      (stateValue ?? []).filter((t) => t.id !== tag.id),
                    );
                  }}
                >
                  {tag.name}
                </Badge>
              ))}
            </Flex>
            <MenuItem
              onClick={() => {
                setStateValue([]);
              }}
              css={{
                hover: {
                  textDecoration: 'underline',
                  backgroundColor: '$background-component',
                  color: '$primary-600',
                },
              }}
            >
              <Text variant='link'>Clear All</Text>
            </MenuItem>
            <Divider />
          </Box>
        )}
        <List
          async
          multi
          filterSelected
          value={stateValue?.map((tag) => tag.id)}
          onChange={(keys, newValue) => {
            setFilter('');
            setStateValue(selectedTags.concat(newValue));
          }}
          options={tags}
        >
          <Box css={{ px: 16, py: 12 }}>
            <ListInput
              leftIcon={<></>}
              rightIcon={isRefetching ? <Spinner /> : <></>}
              autoFocus
              placeholder='Search or create tags'
              value={filter}
              onChange={(f: string) => {
                setFilter(f);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && filter && !tags.length) {
                  handleCreateTag();
                }
              }}
            />
          </Box>
          <ListBox
            css={{ p: 0 }}
            aria-label='project tag list'
            autoFocus='first'
          >
            {(tag) => (
              <Option
                css={{
                  py: 4,
                  focus: {
                    backgroundColor: '$background-component',
                    [`&  ${StyledBadge}`]: {
                      backgroundColor: '$neutral-blue-400',
                    },
                  },
                }}
              >
                <Badge size='xs' variant='gray' shape='rectangle'>
                  {tag.name}
                </Badge>
              </Option>
            )}
          </ListBox>
          {tags.length === 0 && (
            <MenuItem
              disabled={!filter}
              onClick={handleCreateTag}
              css={{
                color: '$primary-600',
                fill: '$primary-600',
                borderTop: '1px solid $neutral-blue-300',
                justifyContent: 'space-between',
                disabled: {
                  color: '$text-disabled',
                  fill: '$text-disabled',
                },
              }}
            >
              <span>
                Create
                {filter && (
                  <Badge
                    size='xs'
                    variant='gray'
                    shape='rectangle'
                    css={{ ml: 8 }}
                  >
                    {filter}
                  </Badge>
                )}
              </span>
              <Icon inherit name='arrow-narrow-right' />
            </MenuItem>
          )}
        </List>
      </MenuContent>
    </Menu>
  );
});

ProjectTagFilterMenu.displayName = 'ProjectTagFilterMenu';
