import React from 'react';
import {
  AlertIcon,
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';

export interface MoveCandidatesModalProps {
  prompt: string;
  note?: JSX.Element;
}

export const MoveCandidatesModal: PromiseModal<MoveCandidatesModalProps> = ({
  onSubmit,
  onDismiss,
  prompt,
  note,
}) => (
  <Modal>
    <Flex css={{ alignItems: 'center', p: '0 8px 8px 8px' }} vertical>
      <Box css={{ py: 16 }}>
        <AlertIcon variant='info' name='collection' />
      </Box>
      <ModalTitle css={{ maxWidth: 275 }}>{prompt}</ModalTitle>
      {note && (
        <Flex
          vertical
          css={{
            backgroundColor: '$neutral-blue-50',
            p: 16,
            borderRadius: 6,
            mt: 16,
          }}
        >
          <Flex>
            <Icon
              name='information-circle'
              color='$neutral-blue-500'
              size={18}
            />
            <Text
              css={{
                m: '0 12px',
                fontWeight: 500,
                fontSize: 14,
                color: '$neutral-blue-700',
              }}
            >
              Please note:
            </Text>
          </Flex>
          <Text
            css={{
              display: 'list-item',
              fontSize: 14,
              mt: 8,
              ml: 48,
              fontWeight: 400,
              color: '$neutral-blue-600',
            }}
          >
            {note}
          </Text>
        </Flex>
      )}
      <Flex justify='center' css={{ gap: 12, pt: 24, width: '100%' }}>
        <Button onClick={onDismiss} variant='secondary' css={{ width: 120 }}>
          Cancel
        </Button>
        <Button onClick={onSubmit} css={{ width: 120 }}>
          Confirm
        </Button>
      </Flex>
    </Flex>
  </Modal>
);
