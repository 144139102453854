import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export function useIncrement(value: number, incrementInterval = 10) {
  const [currentValue, setCurrentValue] = useState(0);

  const increment = useCallback(
    debounce(() => {
      setCurrentValue((prev) => prev + 1);
    }, incrementInterval),
    [],
  );

  useEffect(() => {
    if (currentValue < value) {
      increment();
    }
  }, [value, currentValue]);

  return currentValue;
}
