/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createDebounce from 'redux-debounced';
import createSagaMiddleware from 'redux-saga';
import { apiReducer } from './api/reducer';
import { appReducer } from './app/reducer';
import { authReducer } from './auth/reducer';
import { modalReducer } from './modal/reducer';
import { root } from './sagas';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

export const sagaMiddleware = createSagaMiddleware();

export const rootReducer = combineReducers({
  app: appReducer,
  api: apiReducer,
  auth: authReducer,
  modal: modalReducer,
});

const store = createStore(
  rootReducer,
  // @ts-ignore
  composeEnhancers(applyMiddleware(sagaMiddleware, createDebounce())),
);

sagaMiddleware.run(root);

export type AppState = ReturnType<typeof rootReducer>;
export { store };
