import { Box, Flex } from '@betterleap/ui';
import candidateProfileImage from '../../../assets/images/sourcing-search-candidate-profile.svg';
import filtersImage from '../../../assets/images/sourcing-search-filters.svg';
import integrationsImage from '../../../assets/images/sourcing-search-integrations.svg';
import InfoCard from '../../elements/InfoCard/InfoCard';
import Paywall from '../../elements/Paywall/Paywall';

export const SourceCopilotPaywall = () => (
  <Flex
    align='center'
    css={{
      p: 24,
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      borderRadius: 12,
      border: '1px solid #D6D9DE',
    }}
  >
    <Paywall
      title='Recommended Matches: An effortless way to build a pipeline'
      description='Fuel your projects daily with top-quality candidates and maximize your efficiency.'
      location='Projects'
      css={{
        background: '$neutral-blue-200',
        flexDirection: 'column',
        backgroundColor: 'white',
        maxWidth: 350,
        mt: 0,
        mediaMd: {
          alignItems: 'flex-start',
        },
      }}
    />

    <Flex
      css={{
        gap: 48,
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexGrow: 1,
        p: 16,
      }}
    >
      <InfoCard
        title='Continuous talent flow'
        description='Get a steady influx of top candidates for your projects.'
        css={{ width: 255 }}
        image={
          <Box
            as='img'
            src={candidateProfileImage}
            alt='sourcing_profiles'
            css={{ width: 255 }}
          />
        }
      />
      <InfoCard
        title='Time savings'
        description='Streamline your recruiting process and reclaim valuable time.'
        css={{ width: 255 }}
        image={
          <Box
            as='img'
            src={filtersImage}
            alt='sourcing_filters'
            css={{ width: 255 }}
          />
        }
      />
      <InfoCard
        title='Tailored recommendations'
        description='Candidate suggestions are aligned with your project needs.'
        css={{ width: 255 }}
        image={
          <Box
            as='img'
            src={integrationsImage}
            alt='sourcing_integrations'
            css={{ width: 255 }}
          />
        }
      />
    </Flex>
  </Flex>
);
