import { Box, BoxProps } from '@betterleap/ui';

export const ContactDrawerLayout = ({ css, ...props }: BoxProps) => (
  <Box
    css={{
      display: 'grid',
      gridTemplateAreas: `
        "header header"
        "title title"
        "main aside"
      `,
      gridTemplateRows: '64px auto 1fr',
      gridTemplateColumns: '2fr 1fr',
      height: '100%',
      ...css,
    }}
    {...props}
  />
);

ContactDrawerLayout.Header = function Header({ css, ...props }: BoxProps) {
  return <Box css={{ gridArea: 'header', ...css }} {...props} />;
};

ContactDrawerLayout.Title = function Title({ css, ...props }: BoxProps) {
  return <Box css={{ gridArea: 'title', ...css }} {...props} />;
};

ContactDrawerLayout.Main = function Main({ css, ...props }: BoxProps) {
  return (
    <Box css={{ gridArea: 'main', overflow: 'auto', ...css }} {...props} />
  );
};

ContactDrawerLayout.Aside = function Aside({ css, ...props }: BoxProps) {
  return (
    <Box css={{ gridArea: 'aside', overflow: 'auto', ...css }} {...props} />
  );
};
