import React, { useMemo } from 'react';
import { Box, Text } from '@betterleap/ui';
import { ContactActivity, LoadingArea } from '@betterleap/shared';
import { Contact } from '@betterleap/client';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { groupBy } from 'lodash';
import { format, startOfDay } from 'date-fns';
import { apiClient } from 'lib/apiClient';
import { HeaderMain } from 'components/elements/Layouts/HeaderMain';

export const ContactActivityTemplate = ({ contact }: { contact: Contact }) => {
  const navigate = useNavigate();
  const contactId = contact.id;

  const { data: activityResult, isLoading } = useQuery(
    ['contact_activity', contactId],
    () =>
      apiClient.contactActivity.get({
        contactId,
        profileUrl: contact.linkedinUrl,
      }),
  );

  const activitiesByDay = useMemo(
    () =>
      groupBy(activityResult?.data ?? [], (activity) =>
        format(startOfDay(new Date(activity.createdAt)), 'MMM d, yyyy'),
      ),
    [activityResult],
  );

  const today = format(startOfDay(new Date()), 'MMM d, yyyy');

  return (
    <HeaderMain css={{ maxHeight: 'calc(100vh - 140px)' }}>
      <HeaderMain.Header>
        <Text size='lg' css={{ fontWeight: '$medium', mb: 20 }}>
          Recent Activity
        </Text>
      </HeaderMain.Header>
      <HeaderMain.Main
        css={{
          py: 40,
          px: 32,
          backgroundColor: '$background-component',
          borderRadius: '$5xl',
        }}
      >
        <LoadingArea isLoading={isLoading}>
          <Box css={{ paddingLeft: 90 }}>
            {Object.keys(activitiesByDay).map((day) => {
              const isToday = today === day;
              return (
                <>
                  {activitiesByDay[day]?.map((activity, index) => (
                    <ContactActivity
                      css={{
                        position: 'relative',
                        '&[data-last-of-day=true]': {
                          before: {
                            content: day,
                            display: 'block',
                            position: 'absolute',
                            textAlign: 'right',
                            color: '$text-secondary',
                            fontSize: '$sm',
                            width: 100,
                            left: -116,
                            top: -8,
                          },
                          '&[data-today=true]': {
                            before: {
                              content: 'Today',
                              display: 'block',
                              position: 'absolute',
                              textAlign: 'right',
                              color: '$primary-base',
                              fontWeight: '$medium',
                              fontSize: '$sm',
                              width: 100,
                              left: -116,
                              top: -8,
                            },
                          },
                        },
                      }}
                      key={activity.id}
                      isToday={isToday}
                      lastOfDay={index === 0}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      firstOfDay={index === activitiesByDay[day]!.length - 1}
                      activity={activity}
                      onLinkClick={(path) => navigate(`/${path}`)}
                    />
                  ))}
                </>
              );
            })}
          </Box>
        </LoadingArea>
      </HeaderMain.Main>
    </HeaderMain>
  );
};
