// eslint-disable-next-line import/no-duplicates
import { differenceInCalendarDays, formatDistance } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import locale from 'date-fns/locale/en-US';

interface FormatDistanceOptions {
  includeSeconds?: boolean;
  addSuffix?: boolean;
  locale?: Locale;
}

export const formatDistanceTerse = (
  date: Date,
  baseDate: Date,
  options?: FormatDistanceOptions,
) => {
  const unitMap: Record<string, string> = {
    lessThanXSeconds: '{{count}}s',
    xSeconds: '{{count}}s',
    halfAMinute: '30s',
    lessThanXMinutes: '{{count}}m',
    xMinutes: '{{count}}m',
    aboutXHours: '{{count}}h',
    xHours: '{{count}}h',
    xDays: '{{count}}d',
    aboutXWeeks: '{{count}}w',
    xWeeks: '{{count}}w',
    aboutXMonths: '{{count}}mo',
    xMonths: '{{count}}mo',
    aboutXYears: '{{count}}y',
    xYears: '{{count}}y',
    overXYears: '{{count}}y',
    almostXYears: '{{count}}y',
  };

  return formatDistance(date, baseDate, {
    ...options,
    locale: {
      ...locale,
      formatDistance: (
        token: string,
        count: string,
        opts: FormatDistanceOptions = {},
      ) => {
        // adjust for calendar days, e.g. Thu @ 4:30pm - Sat @ 8:30am = 2 days
        // worst case scenario is e.g. Thu @ 11:59pm - Sat @ 12:01am = 2 days
        let effectiveCount = count;
        if (token === 'xDays') {
          const diff = Math.abs(differenceInCalendarDays(date, baseDate));
          effectiveCount = diff.toString();
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const result = unitMap[token]!.replace('{{count}}', effectiveCount);

        if (opts.addSuffix) {
          return `${result} ago`;
        }

        return result;
      },
    },
  });
};
