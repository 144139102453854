import { Flex, Text } from '@betterleap/ui';
import config from 'lib/config';
import bannerMobile from '../../../assets/images/email-finder/abstract-banner-mobile.png';
import banner from '../../../assets/images/email-finder/abstract-banner.png';
import { LandingLink } from './LandingLink';
import Section from './Section';

const SignUpSection = (): JSX.Element => {
  return (
    <Section
      css={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: 295,
        backgroundImage: `url(${bannerMobile})`,
        mediaLg: {
          backgroundImage: `url(${banner})`,
        },
      }}
    >
      <Flex wrap css={{ justifyContent: 'space-between', flex: 1, gap: 40 }}>
        <Text
          css={{
            color: '$white',
            fontSize: 36,
            lineHeight: '40px',
            flex: 1,
            mediaLg: {
              fontSize: 48,
              lineHeight: '130%',
            },
          }}
        >
          Want to try
          <br /> Betterleap?
        </Text>
        <Flex css={{ gap: 24 }}>
          <LandingLink
            href={`${config.url}/signup`}
            flex
            css={{
              px: 17,
              py: 16,
              border: '1px solid $white',
              textAlign: 'center',
              color: '$white',
              width: 149,
              borderRadius: 51,
              display: 'block',
              cursor: 'pointer',
              hover: {
                textDecoration: 'none',
              },
              mediaLg: {
                fontSize: 18,
                width: 200,
              },
            }}
          >
            Sign Up
          </LandingLink>
          <Text
            css={{
              color: '$white',
              fontSize: '$xs',
              mediaLg: {
                fontSize: '$sm',
              },
            }}
          >
            No annual commitment.
          </Text>
        </Flex>
      </Flex>
    </Section>
  );
};

export default SignUpSection;
