import { ModalProps } from '@betterleap/shared';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../store/modal/actions';
import {
  getActiveModal,
  getActiveModalProps,
} from '../../../store/modal/selectors';
import SubmitCandidateSuccessModal, {
  KEY as SubmitCandidateSuccessModalKey,
} from './SubmitCandidateSuccessModal/SubmitCandidateSuccessModal';

export const modalRegistry = {
  [SubmitCandidateSuccessModalKey]: SubmitCandidateSuccessModal,
};

export const useModal = <T extends ModalProps>(
  modalKey: keyof typeof modalRegistry,
  modalProps: T,
) => {
  const dispatch = useDispatch();

  return {
    open: () => dispatch(modalActions.open(modalKey, modalProps)),
    close: () => dispatch(modalActions.close()),
  };
};

export const Modals = () => {
  const activeModal = useSelector(getActiveModal);
  const activeModalProps = useSelector(getActiveModalProps);

  return (
    <>
      {Object.entries(modalRegistry).map(([key, value]) => {
        const Modal = value;
        return (
          <Modal key={key} isOpen={activeModal === key} {...activeModalProps} />
        );
      })}
    </>
  );
};
