import { GroupDto } from '@betterleap/client';
import { Text } from '@betterleap/ui';
import { GroupSelect } from 'components/templates/OrganizationInviteTemplate/GroupSelect';
import { InviteMember } from 'components/templates/OrganizationInviteTemplate/OrganizationInviteTemplate';
import { RoleSelect } from 'components/templates/OrganizationInviteTemplate/RoleSelect';

export const organizationColumns = {
  columns: (groups: GroupDto[], onGroupChanged: () => void) => [
    {
      Header: 'NAME',
      id: 'name',
      accessor: (member: InviteMember) => (
        <Text css={{ fontSize: '$sm', color: '$neutral-blue-1000' }}>
          {member.name}
        </Text>
      ),
      slot: 'custom',
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      slot: 'tag',
    },
    {
      Header: 'ROLE',
      accessor: (member: InviteMember) => <RoleSelect member={member} />,
      slot: 'custom',
    },
    {
      Header: groups.length > 1 ? 'GROUP' : '',
      id: 'group',
      accessor: (member: InviteMember) => (
        <GroupSelect
          member={member}
          groups={groups}
          onGroupChanged={onGroupChanged}
        />
      ),
      slot: 'custom',
    },
  ],
};
