import { Toastify } from '@betterleap/shared';
import '@betterleap/shared/dist/cjs/index.css';
import { ThemeProvider } from '@betterleap/ui';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { BannerProvider } from './components/elements/Banner/Banner';
import { SidebarProvider } from './components/HOC/SidebarContext';
import AppRouter from './pages';
import { store } from './store';
import './styles/_main.scss';

TimeAgo.addDefaultLocale(en);

const queryClient = new QueryClient();

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY ||
    'pk_test_w8uy0u6APCdSJFjhve2i8STd007XNMpuGE',
);

const App: React.FC = () => (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Elements stripe={stripePromise}>
            <ThemeProvider>
              <SidebarProvider>
                <BannerProvider>
                  <Toastify />
                  <AppRouter />
                </BannerProvider>
              </SidebarProvider>
            </ThemeProvider>
          </Elements>
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

export default App;
