import {
  Box,
  Collapsible,
  CollapsibleContent,
  CollapsibleProps,
  CollapsibleTrigger,
  Flex,
  IconButton,
  Text,
  TextArea,
} from '@betterleap/ui';
import { useState } from 'react';
import { apiClient } from 'lib/apiClient';
import { useMutation, useQuery } from 'react-query';
import { Note, NoteManagerStyle, useMe } from '@betterleap/shared';
import { CollapisbleHeader } from './ContactProjects';

export interface ContactNotesProps extends CollapsibleProps {
  contactId?: string;
}

export const ContactNotes = ({ contactId }: ContactNotesProps) => {
  const me = useMe();
  const [noteValue, setNoteValue] = useState('');

  const { data: notesResponse, refetch } = useQuery(
    ['get_contact_notes', contactId],
    () =>
      apiClient.contactNote.getContactNotes({ contactId: contactId as string }),
    {
      enabled: !!contactId,
    },
  );

  const createContactNote = useMutation(
    (postData: { note: string; contactId: string }) =>
      apiClient.contactNote.create({ requestBody: postData }),
    {
      onSuccess: () => {
        setNoteValue('');
        refetch();
      },
    },
  );

  const handleAddNote = async () => {
    createContactNote.mutate({
      note: noteValue,
      contactId: contactId as string,
    });
  };

  const notes = notesResponse?.data ?? [];
  const hasNotes = contactId && notesResponse && notesResponse.data.length;

  return (
    <Collapsible defaultOpen={true}>
      <CollapsibleTrigger asChild>
        <CollapisbleHeader title='Notes' icon='pencil' />
      </CollapsibleTrigger>
      <CollapsibleContent css={{ overflow: 'auto', px: 24, pb: 40 }}>
        {hasNotes ? (
          notes.map((note) => (
            <Note
              item={note}
              viewingUserId={me?.user?.id ?? ''}
              noteStyle={NoteManagerStyle.CONTACT_PROJECT}
              noteTextSize='$sm'
            />
          ))
        ) : (
          <Flex justify='center' css={{ py: 30 }}>
            <Text size='sm' css={{ color: '$text-tertiary' }}>
              No contact notes yet.
            </Text>
          </Flex>
        )}
        {contactId && (
          <Box css={{ position: 'relative' }}>
            <TextArea
              value={noteValue}
              placeholder={`Add a note here`}
              css={{
                border: '1px solid $neutral-blue-200',
                width: '100%',
                height: 120,
                mt: 16,
                borderRadius: 16,
                padding: 16,
                paddingRight: 48,
                resize: 'none',
                outlineColor: '$neutral-blue-200',
              }}
              onChange={(value: unknown) => setNoteValue(value as string)}
            />
            <IconButton
              label={`Add note`}
              name='paper-airplane'
              disabled={!noteValue}
              size='sm'
              css={{
                position: 'absolute',
                bottom: 16,
                right: 10,
                borderRadius: 10,
                transform: 'rotate(90deg)',
                fill: 'white',
                backgroundColor: '$blue-600',
                hover: {
                  backgroundColor: '$blue-700',
                },
              }}
              onClick={handleAddNote}
            />
          </Box>
        )}
      </CollapsibleContent>
    </Collapsible>
  );
};
