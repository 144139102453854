import { showToast } from '@betterleap/ui';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { SendTestEmailDto } from '@betterleap/client';
import api from 'lib/api';
import { constructErrorToast } from './constructErrorToast';

export const useSendTestEmail = () => {
  const sendTestEmail = useMutation(
    (data: SendTestEmailDto) =>
      api.fetch<{ data: { recipient: string } }>('send_test_email', data),
    {
      onSuccess: (response) => {
        showToast({
          title: 'Test Email Sent!',
          description: `A test email has been sent to ${response.data.data.recipient}`,
          variant: 'success',
        });
      },
      onError: (axiosError: AxiosError) => {
        const { title, description } = constructErrorToast(axiosError);
        showToast({ title, description, variant: 'danger' });
      },
    },
  );

  const handleTestEmailClick = (data: SendTestEmailDto) => {
    sendTestEmail.mutate(data);
  };

  return handleTestEmailClick;
};
