import { Button, Flex, IconButton, Tooltip } from '@betterleap/ui';

export interface AddRemoveColumnArgs {
  onAdd: (contactId: string) => void;
  onRemove: (contactId: string) => void;
}

export const AddRemoveColumn = ({ onAdd, onRemove }: AddRemoveColumnArgs) => ({
  Header: 'ACTIONS',
  width: 150,
  headerCss: {
    maxWidth: 150,
  },
  css: {
    maxWidth: 150,
  },
  accessor: (contact: { id: string }) => (
    <AddRemoveButtons
      contactId={contact.id}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  ),
  slot: 'custom',
});

export const AddRemoveButtons = ({
  contactId,
  onAdd,
  onRemove,
}: {
  contactId: string;
  onAdd?: (contactId: string) => void;
  onRemove?: (contactId: string) => void;
}) => (
  <Flex css={{ gap: 8 }}>
    <Button size='sm' variant='secondary' onClick={() => onAdd?.(contactId)}>
      Add
    </Button>
    <Tooltip content='Pass'>
      <IconButton
        label='pass on candidate'
        size='sm'
        name='minus-circle'
        variant='danger'
        onClick={() => onRemove?.(contactId)}
      />
    </Tooltip>
  </Flex>
);
