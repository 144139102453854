import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, Flex, Text } from '@betterleap/ui';
import { LoadingArea } from '@betterleap/shared';
import { useState } from 'react';
import { apiClient } from 'lib/apiClient';

const ContactUnsubscribePage = (): JSX.Element => {
  const [statusText, setStatusText] = useState('');
  const { id, unsubscribeToken } = useParams<{
    id: string;
    unsubscribeToken: string;
  }>();

  const unsubscribeContact = useMutation(
    (data: { id: string; unsubscribeToken: string }) =>
      apiClient.contact.unsubscribeContact({
        id: data.id,
        unsubscribeToken: data.unsubscribeToken,
      }),
    {
      onSuccess: () => {
        setStatusText('You have successfully unsubscribed');
      },
      onError: () => {
        setStatusText('An error occurred, please try again later.');
      },
    },
  );

  if (!id || !unsubscribeToken) {
    return (
      <Text as='h2' css={{ mt: 48, fontSize: '$2xl', marginBottom: '1rem' }}>
        Invalid path params
      </Text>
    );
  }

  if (unsubscribeContact.isLoading) {
    return <LoadingArea isLoading />;
  }

  if (statusText) {
    return (
      <Text
        as='h2'
        css={{ fontSize: '$2xl', padding: 48, textAlign: 'center', mt: 200 }}
      >
        {statusText}
      </Text>
    );
  }

  return (
    <Flex
      vertical
      css={{ height: 600, justifyContent: 'center', alignItems: 'center' }}
    >
      <Text as='h2' css={{ mt: 48, fontSize: '$2xl', marginBottom: '1rem' }}>
        Unsubscribe from future emails
      </Text>
      <Button
        onClick={() => {
          unsubscribeContact.mutate({ id, unsubscribeToken });
        }}
        css={{
          color: '#3B82F6',
          mt: 8,
          fontStyle: 'normal',
          mediaMd: { mt: 'unset' },
          borderRadius: '14px',
        }}
        variant='empty-primary'
      >
        Unsubscribe
      </Button>
    </Flex>
  );
};

export default ContactUnsubscribePage;
