import { ProjectContactStatus } from '@betterleap/client';
import { useSearchParams } from 'react-router-dom';
import { setArrayUrlParam } from 'helper/setArrayUrlParam';
import { useProjectContactsFilterParams } from '../useProjectContactsFilterParams';

export const useCohortFilter = () => {
  const { statuses } = useProjectContactsFilterParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const cohort = searchParams.get('cohort') as ProjectContactStatus;
  const setCohort = (value: string) => {
    if (value) {
      searchParams.set('cohort', value);
      if (statuses.includes(ProjectContactStatus.NOT_IN_SEQUENCE)) {
        setArrayUrlParam(
          searchParams,
          'status',
          statuses.filter(
            (status) => status !== ProjectContactStatus.NOT_IN_SEQUENCE,
          ),
        );
      }
    } else {
      searchParams.delete('cohort');
    }
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };
  return { cohort, setCohort };
};
