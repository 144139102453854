import { Box, BoxProps } from '@betterleap/ui';
import ClearbitLogoImage from './ClearbitLogoImage';
import InitialsBox from './InitialsBox';

export interface ClearbitLogoProps extends BoxProps {
  name?: string;
  domain?: string;
}

const ClearbitLogo = (props: ClearbitLogoProps) => {
  const { name, domain, css, ...rest } = props;

  return (
    <Box
      css={{
        width: 48,
        height: 48,
        borderRadius: '$2xl',
        border: '1px solid $neutral-blue-300',
        ...css,
      }}
      {...rest}
    >
      <Box css={{ m: 12 }}>
        <ClearbitLogoImage
          domain={domain}
          requestedSize='48'
          renderSize={24}
          fallbackElm={<InitialsBox name={name} />}
        />
      </Box>
    </Box>
  );
};

export default ClearbitLogo;
