import { Button, Spinner } from '@betterleap/ui';
import { ConnectIntegrationDto } from '@betterleap/client';
import useConnectIntegration from 'hooks/useConnectIntegration';

export const ConnectCrmButton = ({ onSuccess }: { onSuccess: () => void }) => {
  const { open, isLoading } = useConnectIntegration({
    location: 'Project details',
    onSuccess,
  });

  return (
    <Button
      variant='secondary'
      onClick={() => open(ConnectIntegrationDto.integrationCategory.CRM)}
      css={{ minWidth: 120 }}
      disabled={isLoading}
    >
      {isLoading ? <Spinner /> : 'Connect CRM'}
    </Button>
  );
};
