import React from 'react';
import SigninTemplate from '../components/templates/SignIn/SignIn.template';

interface SignUpProps {
  verifyEmail?: boolean;
}

const SignUp = ({ verifyEmail }: SignUpProps) => (
  <React.StrictMode>
    <SigninTemplate verifyEmail={verifyEmail} isSignup />
  </React.StrictMode>
);

export default SignUp;
