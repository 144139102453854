import { LoadingArea, PartialTableInstance } from '@betterleap/shared';
import { Box, Tab, Tabs, TabsList } from '@betterleap/ui';
import { useState } from 'react';
import { ProjectContactsProps } from './ProjectContacts.types';
import { ProjectDetailsTable } from './Table/ProjectDetailsTable';
import { ProjectActionsBar } from './ActionsBar/ProjectActionsBar';
import { isViewOnly, TableSelection } from './ProjectDetails.functions';
import { useCohortFilter } from './Hooks/useCohortFilter';
import { EmptyProjectDetailsTable } from './EmptyProjectDetailsTable';

export const ProjectContacts = ({
  project,
  projectContacts,
  isInitiallyLoading,
  isLoadingTable,
  refreshData,
  scrollTop,
}: ProjectContactsProps) => {
  const viewOnly = isViewOnly(project);

  const [tableInstance, setTableInstance] = useState<PartialTableInstance>();
  const [selectedContacts, setSelectedContacts] = useState<TableSelection>([]);

  const { cohort, setCohort } = useCohortFilter();

  const resetSelection = () => {
    tableInstance?.toggleAllRowsSelected?.(false);
    setSelectedContacts([]);
  };
  const refreshTable = () => {
    resetSelection();
    refreshData();
  };
  const handleTabChange = (value: string) => {
    resetSelection();
    setCohort(value);
  };

  return (
    <>
      <Tabs
        defaultValue={cohort ?? ''}
        value={cohort ?? ''}
        onValueChange={handleTabChange}
      >
        <TabsList>
          <Tab value=''>All</Tab>
          <Tab value='uncontacted'>Uncontacted</Tab>
          <Tab value='contacted'>Contacted</Tab>
          <Tab value='replied'>Replied</Tab>
        </TabsList>
      </Tabs>
      <Box css={{ flex: 1 }}>
        <LoadingArea isLoading={isInitiallyLoading}>
          {!project.contactsTotalCount && !viewOnly ? (
            <EmptyProjectDetailsTable
              project={project}
              refreshData={refreshData}
            />
          ) : (
            <ProjectDetailsTable
              project={project}
              projectContacts={projectContacts}
              isLoading={isLoadingTable}
              resetSelection={resetSelection}
              refreshData={refreshData}
              refreshTable={refreshTable}
              scrollTop={scrollTop}
              setTableInstance={setTableInstance}
              selectedContacts={selectedContacts}
              setSelectedContacts={setSelectedContacts}
            />
          )}
        </LoadingArea>
      </Box>
      {!viewOnly && (
        <ProjectActionsBar
          project={project}
          selectedContacts={selectedContacts}
          projectContacts={projectContacts}
          onActionCompleted={refreshTable}
        />
      )}
    </>
  );
};
