import {
  AlertIcon,
  Button,
  Flex,
  Form,
  Input,
  Modal,
  PromiseModal,
  Text,
} from '@betterleap/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export interface EnterGroupNameProps {
  title: string;
  description?: string;
  value?: string;
  confirmButtonText: string;
}

export interface EnterGroupNameResult {
  name: string;
}

const EnterGroupNameModal: PromiseModal<
  EnterGroupNameProps,
  EnterGroupNameResult
> = ({ onDismiss, onSubmit, title, description, value, confirmButtonText }) => {
  const [groupName, setGroupName] = useState<string>(value || '');
  const { control, handleSubmit } = useForm<EnterGroupNameResult>({
    defaultValues: { name: value },
  });
  const submitName = () => {
    onSubmit({ name: groupName });
  };
  return (
    <Modal css={{ borderRadius: 16 }}>
      <Flex vertical css={{ alignItems: 'center' }}>
        <AlertIcon
          variant='info'
          name='user-group'
          size='lg'
          css={{ mt: 8, mb: 16, borderRadius: 12 }}
        />
        <Text as='h3' css={{ fontWeight: '$medium', fontSize: '$lg', mb: 8 }}>
          {title}
        </Text>
        {description && (
          <Text
            as='p'
            css={{
              color: '$gray-500',
              fontSize: '$sm',
              mb: 12,
              textAlign: 'center',
            }}
          >
            {description}
          </Text>
        )}
        <Flex justify='center' css={{ gap: 10, p: 20, width: '100%' }}>
          <Form
            control={control}
            onSubmit={handleSubmit(submitName)}
            css={{ width: '100%' }}
          >
            <Input
              id='group-name'
              name='name'
              type='text'
              placeholder='Group Name'
              defaultValue={value}
              required
              onChange={(v: string) => setGroupName(v)}
            />
          </Form>
        </Flex>
        <Flex justify='center' css={{ gap: 12, width: '100%', mt: 10, mb: 10 }}>
          <Button
            variant='gray'
            onClick={onDismiss}
            css={{ maxWidth: 146, width: '100%' }}
          >
            Cancel
          </Button>

          <Button
            onClick={handleSubmit(submitName)}
            css={{
              maxWidth: 146,
              width: '100%',
            }}
            disabled={!groupName}
          >
            {confirmButtonText}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
export default EnterGroupNameModal;
