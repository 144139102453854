import { AlertIconProps, IconProps } from '@betterleap/ui';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Types, useCreateCompletion } from '@betterleap/shared';
import config from 'lib/config';
import { selectMe } from 'store/api/selectors';
import useAnalytics from '../../../hooks/analytics';
import { Copilot } from './Copilot';
import { prompts } from './prompts';

const rolePrompts = [
  'Create a Product Designer job description for Airbnb',
  'Generate 10 interview questions for a UX researcher',
  'Create a recruiting pitch for Coinbase',
];

const followUpPrompts = [
  'Create a 3-step email sequence to a potential candidate. The 1st email explains the benefits of joining Stripe. The 2nd email explains Stripe’s mission to create a cashless world. The 3rd emails explains how entrepreneurial the engineering team is.',
  'Create an email to a candidate about an extended offer',
  'Create a candidate rejection email',
];

const generalPrompts = [
  'Generate a list of perks tech companies offer',
  'Create a careers page for Plaid  with sections our mission, open roles, our culture, and benefits',
];

export interface SamplePrompt {
  icon: IconProps['name'];
  variant: AlertIconProps['variant'];
  prompts: string[];
}

const samplePrompts: Record<string, SamplePrompt> = {
  General: {
    prompts: generalPrompts,
    icon: 'puzzle',
    variant: 'info',
  },
  'Starting a new search': {
    prompts: rolePrompts,
    icon: 'emoji-happy',
    variant: 'success',
  },
  'Following up': {
    prompts: followUpPrompts,
    icon: 'paper-airplane',
    variant: 'purple',
  },
};

const GeneralCopilotTab = (): JSX.Element => {
  const { track } = useAnalytics();
  const me = useSelector(selectMe);
  const [searchParams] = useSearchParams();
  const [textPrompt, setTextPrompt] = useState('');
  const [result, setResult] = useState({ requestId: '', text: '' });
  const [isLoading, setIsLoading] = useState(false);
  const userId = me?.data?.id;
  const controller = useRef(new AbortController());

  useEffect(() => {
    const selectedPrompt = searchParams.get('prompt');

    if (selectedPrompt)
      setTextPrompt(
        prompts.find((p) => p.title === selectedPrompt)?.prompt || '',
      );
  }, [searchParams]);

  const trimmedResult = useMemo(
    () => result.text.replace(/^(\n)+/, ''),
    [result],
  );

  const abortController = () => {
    controller.current.abort();
    controller.current = new AbortController();
  };

  const createCompletion = useCreateCompletion({
    userId,
    apiUrl: config.endpoints.api,
    setResult,
    setIsLoading,
    controller,
  });

  const onSubmit = () => {
    if (!textPrompt) return;

    track(Types.ANALYTICS_CLIENT_EVENT.AI_ASSISTANT_PROMPT_SUBMIT, {
      promptText: textPrompt,
    });
    createCompletion(textPrompt);
  };

  const handleClear = () => {
    abortController();
    setTextPrompt('');
    setResult({ requestId: '', text: '' });
  };

  return (
    <Copilot
      editorTitle='What would you like to create?'
      editorPlaceholder='Ex: Write a list of responsibilities for a product designer role'
      onEditorChange={(content: string) => setTextPrompt(content)}
      submitText='Create content '
      result={trimmedResult}
      samplePrompts={samplePrompts}
      textPrompt={textPrompt}
      setTextPrompt={setTextPrompt}
      handleClear={handleClear}
      onSubmit={onSubmit}
      isLoading={isLoading}
      showRewriteButton
    />
  );
};

export default GeneralCopilotTab;
