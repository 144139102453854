import { useMe } from '@betterleap/shared';
import { Box, Flex, FlexProps, Select, SelectItem } from '@betterleap/ui';
import { useAtom } from 'jotai';
import { filterState } from '../SourceCandidates.template';
import { SourcingInputLabel } from '../SourceCandidatesInputFilter';

export const GenderFilter = ({ ...rest }: FlexProps) => {
  const me = useMe();
  const [filters, setFilters] = useAtom(filterState);
  if (!me.user?.organization?.features?.enabledGenderFilter) {
    return null;
  }
  return (
    <Flex vertical {...rest}>
      <Box css={{ width: '100%' }}>
        <SourcingInputLabel id='filter-gender' htmlFor='sourcing-gender-select'>
          Gender
        </SourcingInputLabel>
        <Select
          id='sourcing-gender-select'
          size='sm'
          aria-labelledby='filter-gender'
          placeholder='Select Gender'
          value={filters.gender}
          css={{
            color: filters.gender ? '$text' : '$text-placeholder',
            height: 42,
          }}
          onChange={(value) => {
            setFilters({
              ...filters,
              gender: value,
            });
          }}
        >
          <SelectItem size='sm' value='female'>
            Female
          </SelectItem>
          <SelectItem size='sm' value='male'>
            Male
          </SelectItem>
        </Select>
      </Box>
    </Flex>
  );
};
