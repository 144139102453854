import { InvitationsMetadataDto } from '@betterleap/client';
import { useMutation } from 'react-query';
import { showToast, useModal } from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import InviteExternalModal, {
  InvitationRequest,
} from 'components/modules/Modal/InviteExternalModal/InviteExternalModal';

interface HookProps {
  onSuccess: () => void;
}

export const useInvitePortfolioCompany = ({ onSuccess }: HookProps) => {
  const inviteUser = useMutation(
    (data: InvitationRequest) =>
      apiClient.invitation.inviteUser({
        requestBody: {
          inviteEmail: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          metadata: {
            type: InvitationsMetadataDto.type.LINKED_ORG,
          },
        },
      }),
    {
      onSuccess: async () => {
        onSuccess();
      },
    },
  );

  const invitePortfolioCompany = async (data: InvitationRequest) => {
    if (!data) {
      return;
    }
    try {
      await inviteUser.mutateAsync(data);
      showToast({
        title: 'Invite sent!',
        description: 'Your portfolio company should receive an invite shortly.',
      });
    } catch (error) {
      showToast({
        variant: 'danger',
        title: 'Something went wrong!',
        description: 'Failed to send invitation. Please try again.',
      });
    }
  };

  const openInviteModal = useModal<
    {
      title: string;
      message: string;
      onSubmit: typeof invitePortfolioCompany;
    },
    InvitationRequest
  >(InviteExternalModal);

  const onInviteClick = async () => {
    // prettier-ignore
    await openInviteModal({
      title: 'Invite Company',
      message: 'Invite your portfolio companies to collaborate, and view project metrics all in one central space.',
      onSubmit: invitePortfolioCompany,
    });
  };

  return onInviteClick;
};
