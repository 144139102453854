import React from 'react';
import {
  IconButton,
  IconButtonProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@betterleap/ui';

export const SendAtTip = (props: Omit<IconButtonProps, 'label'>) => (
  <Popover>
    <PopoverTrigger>
      <IconButton
        {...props}
        label='more information'
        name='question-mark-circle'
        size='sm'
      />
    </PopoverTrigger>
    <PopoverContent css={{ backgroundColor: '$navy', maxWidth: 250 }}>
      <Text size='sm' css={{ color: 'white' }}>
        It can take up to 2 hours after the scheduled send time for a contact to
        receive this step.
      </Text>
    </PopoverContent>
  </Popover>
);
