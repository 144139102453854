import isEqual from 'lodash/isEqual';
import {
  CreateSequenceScheduleDto,
  SequenceSchedule,
} from '@betterleap/client';

export const DEFAULT_SEQUENCE_SCHEDULE = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
];

export const sequenceScheduleToValue = (schedule?: SequenceSchedule) => {
  if (!schedule) {
    return DEFAULT_SEQUENCE_SCHEDULE;
  }

  return Object.keys(schedule).reduce<string[]>((acc, key) => {
    if (schedule[key]) {
      return acc.concat([key]);
    }

    return acc;
  }, []);
};

export const valueToSequenceSchedule = (value: string[]) => {
  if (isEqual(value, DEFAULT_SEQUENCE_SCHEDULE)) {
    return undefined;
  }

  return value.reduce<CreateSequenceScheduleDto>(
    (acc, key) => {
      if (acc[key] !== undefined) {
        acc[key] = true;
      }

      return acc;
    },
    {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
  );
};
