import routeNames from 'constants/routeNames';
import { EmptyStateSVG, RecruiterChatView } from '@betterleap/chat-shared';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { apiClient } from 'lib/apiClient';
import logo from '../../../assets/images/betterleap_logo_purple.svg';

export default function OrganizationChatTemplate() {
  const { data: chatWorkspace, isLoading: workspaceLoading } = useQuery(
    ['org_chat_workspace'],
    () => apiClient.chatWorkspace.getOrgDefaultWorkspace(),
  );
  const navigate = useNavigate();

  if (
    !chatWorkspace?.data ||
    !chatWorkspace?.data.workspaces[0] ||
    workspaceLoading
  ) {
    return (
      <div className='h-[calc(100vh-86px)] flex items-center justify-center'>
        <img src={logo} className='h-10 w-10 mx-auto' />
      </div>
    );
  }

  return (
    <RecruiterChatView
      url={chatWorkspace.data.url}
      emptyState={
        <div className='grid place-items-center h-full'>
          <div className='text-center flex flex-col gap-2'>
            <EmptyStateSVG className='mx-auto' />
            <div className='text-lg font-bold relative -mt-14'>
              No messages yet
            </div>
            <div className='text-gray-500 max-w-lg'>
              Start a conversation with this candidate. For example, ask about
              their desired role, qualifications, and what motivates them.
            </div>
          </div>
        </div>
      }
      onChatClick={(chatUrl) => {
        navigate(chatUrl);
      }}
      onClickBack={() => {
        navigate(routeNames.chats());
      }}
    />
  );
}
