const generateRoute = (
  unsafeRoute: string,
  params: Record<string, string | null> = {},
) => {
  let route = unsafeRoute;
  const searchParams: Record<string, string> = {};

  Object.keys(params).forEach((key) => {
    if (!params[key]) route = route.replace(`/:${key}`, '');
    else if (route.includes(`:${key}`))
      route = route.replace(`:${key}`, params[key] as string);
    else if (params[key]) {
      searchParams[key] = params[key] as string;
    }
  });

  const searchParamsString = new URLSearchParams(searchParams).toString();
  if (searchParamsString) {
    route += `?${searchParamsString}`;
  }

  return route;
};
const routeNames = {
  home: (params?: Record<string, string>): string => generateRoute('/', params),
  dashboard: (params?: Record<string, string>): string =>
    generateRoute('/dashboard', params),
  copilot: (params?: Record<string, string>): string =>
    generateRoute('/copilot/*', params),
  analytics: (params?: Record<string, string>): string =>
    generateRoute('/copilot/analytics', params),
  signin: (params?: Record<string, string>): string =>
    generateRoute('/signin', params),
  signup: (params?: Record<string, string>): string =>
    generateRoute('/signup', params),
  onboarding: (params?: Record<string, string>): string =>
    generateRoute('/onboarding', params),
  adminNoAccess: (params?: Record<string, string>): string =>
    generateRoute('/no-access', params),
  team: (params?: Record<string, string>) => generateRoute('/team', params),
  createRecruiterProfile: (params?: Record<string, string>): string =>
    generateRoute('/create-recruiter-profile', params),
  profile: (params?: Record<string, string>): string =>
    generateRoute('/profile', params),
  recruiterProfile: (params?: Record<string, string>): string =>
    generateRoute('/recruiter-profile', params),
  companyRecruiterProfile: (params?: Record<string, string>): string =>
    generateRoute('/recruiter/:id', params),
  verifyEmailLink: (params?: Record<string, string>) =>
    generateRoute('/verify', params),
  jobs: (params?: Record<string, string>): string =>
    generateRoute('/jobs', params),
  senderConnectCallback: (params?: Record<string, string>): string =>
    generateRoute('/sender/oauth2callback', params),
  sourcing: (params?: Record<string, string>): string =>
    generateRoute('/sourcing', params),
  projects: (params?: Record<string, string>): string =>
    generateRoute('/projects', params),
  project: (params?: Record<string, string>): string =>
    generateRoute('/projects/:id', params),
  createSequence: (params?: Record<string, string>): string =>
    generateRoute('/sequences/new', params),
  editSequence: (params?: Record<string, string>): string =>
    generateRoute('/sequences/:id/edit', params),
  sequenceDetail: (params?: Record<string, string>): string =>
    generateRoute('/sequence/:id', params),
  contactProfile: (params?: Record<string, string>): string =>
    generateRoute('/contact/:id', params),
  callback: (params?: Record<string, string>): string =>
    generateRoute('/auth/callback', params),
  settings: (params?: Record<string, string>): string =>
    generateRoute('/settings', params),
  portfolio: (params?: Record<string, string>): string =>
    generateRoute('/portfolio', params),
  contactUnsubscribe: (params?: Record<string, string>): string =>
    generateRoute('/unsubscribe/:unsubscribeToken/contact/:id', params),
  emailFinder: (params?: Record<string, string>): string =>
    generateRoute('/enrich', params),
  savedSearches: (params?: Record<string, string>): string =>
    generateRoute('/saved-searches', params),
  chats: (params?: Record<string, string>): string =>
    generateRoute('/chat', params),
  chatThread: (params?: Record<string, string>): string =>
    generateRoute('/chat/:chatId', params),
};

export default routeNames;
