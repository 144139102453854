import { UpdateContactProjectDto } from '@betterleap/client';
import { showToast } from '@betterleap/ui';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { apiClient } from 'lib/apiClient';

interface ProjectStatusUpdaterProps {
  onContactProjectUpdated: () => void;
}

export const useProjectStatusUpdater = ({
  onContactProjectUpdated,
}: ProjectStatusUpdaterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateContactProject = useMutation(
    ({
      id: contactProjectId,
      ...requestBody
    }: { id: string } & UpdateContactProjectDto) =>
      apiClient.contactProject.update({
        contactProjectId,
        requestBody,
      }),
    {
      onSuccess: async () => {
        onContactProjectUpdated();
      },
    },
  );

  const contactProjectId = searchParams.get('contactProjectId');
  const status = searchParams.get('status');

  useEffect(() => {
    if (!contactProjectId || !status) {
      return;
    }
    const request: UpdateContactProjectDto = {};
    if (status === 'replied') {
      request.externallyContactedStatus = 'Replied';
    } else if (status === 'interested') {
      request.interested = true;
    } else if (status === 'not_interested') {
      request.interested = false;
    }
    updateContactProject.mutate({
      id: contactProjectId,
      ...request,
    });
    const friendlyStatus = status.replace(/_/g, ' ');
    showToast({
      title: 'Thank you!',
      description: `This contact was marked as ${friendlyStatus}`,
    });
    searchParams.delete('contactProjectId');
    searchParams.delete('status');
    setSearchParams(searchParams);
  }, []);
};
