import { Organization } from '@betterleap/client';
import { Box } from '@betterleap/ui';
import salesforce from '../../../../assets/images/salesforce-icon.svg';
import {
  ConnectIntegrationProps,
  IntegrationSetting,
} from './Integration.elements';

const ConnectCrm = ({
  organization,
  isLoading,
  openMergeLink,
}: ConnectIntegrationProps) => {
  const iconMap = {
    [Organization.crmSource.SALESFORCE]: { src: salesforce, width: 20 },
  };
  const icon = organization.crmSource ? iconMap[organization.crmSource] : null;
  return (
    <IntegrationSetting>
      <Box>
        <IntegrationSetting.Title>Connect CRM</IntegrationSetting.Title>
        <IntegrationSetting.Subtitle>
          This enables a sync between your CRM and Betterleap
        </IntegrationSetting.Subtitle>
      </Box>
      {organization.crmAccountToken && organization.crmSource ? (
        <IntegrationSetting.ConnectText
          integrationName={organization.crmSource}
          icon={icon}
        />
      ) : (
        <IntegrationSetting.ConnectButton
          buttonText='Connect CRM'
          isLoading={isLoading}
          onClick={openMergeLink}
        />
      )}
    </IntegrationSetting>
  );
};

export default ConnectCrm;
