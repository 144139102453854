import { ApiResponse, ProjectWithStatsDto, TagDto } from '@betterleap/client';
import {
  Badge,
  Box,
  Icon,
  List,
  ListBox,
  ListBoxProps,
  ListInput,
  Menu,
  MenuContent,
  MenuItem,
  MenuTrigger,
  Option,
  showToast,
  Spinner,
  StyledBadge,
  useControlledState,
} from '@betterleap/ui';
import { set } from 'lodash';
import { forwardRef, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { apiClient } from 'lib/apiClient';

interface AddProjectTagMenuProps extends Omit<ListBoxProps, 'children'> {
  onCreateTag?: (tag: TagDto) => void;
}

export const AddProjectTagMenu = forwardRef<
  HTMLButtonElement,
  AddProjectTagMenuProps
>(({ value, defaultValue, onChange, onCreateTag }, ref) => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [stateValue, setStateValue] = useControlledState<
    (string | number)[] | undefined
  >(
    value,
    defaultValue,
    onChange as (v: (string | number)[] | undefined) => void,
  );

  const { data: tagsResponse, isRefetching } = useQuery(
    ['findProjectTags', { filter, id }],
    () =>
      apiClient.projectTag.findTags({
        search: filter,
        pageSize: 15,
      }),
    {
      keepPreviousData: true,
    },
  );

  const onMutate = async (data: { name: string; id?: string }) => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries(['get_project_with_stats', id]);

    // Snapshot the previous value
    const previousProjectResponse = queryClient.getQueryData<
      ApiResponse<{ data: ProjectWithStatsDto }>
    >(['get_project_with_stats', id]);

    // Optimistically update to the new value
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryClient.setQueryData(['get_project_with_stats', id], (old: any) => {
      if (!old) {
        return undefined;
      }

      const newProject: ApiResponse<{ data: ProjectWithStatsDto }> = { ...old };
      set(newProject, 'data.data.tags', [
        {
          id: data.id ?? v4(),
          name: data.name,
        },
        ...(newProject.data.data?.tags || []),
      ]);

      return newProject;
    });

    // Return a context object with the snapshotted value
    return { previousProjectResponse };
  };

  const createTag = useMutation(
    (data: { name: string }) =>
      apiClient.projectTag.createTag({
        requestBody: {
          name: data.name,
          projectId: id as string,
        },
      }),
    {
      onMutate,
      onSuccess: (data) => {
        onCreateTag?.(data as unknown as TagDto);
      },
      onError: (err, data, context) => {
        queryClient.setQueryData(
          ['get_project_with_stats', id],
          context?.previousProjectResponse,
        );

        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to create tag. Please try again.',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['get_project_with_stats', id]);
      },
    },
  );

  const addTag = useMutation(
    (data: { name: string; id: string }) =>
      apiClient.projectTag.addTag({
        projectId: id as string,
        requestBody: {
          tagId: data.id,
        },
      }),
    {
      onMutate,
      onError: (err, data, context) => {
        queryClient.setQueryData(
          ['get_project_with_stats', id],
          context?.previousProjectResponse,
        );

        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to add tag. Please try again.',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['get_project_with_stats', id]);
      },
    },
  );

  const handleCreateTag = () => {
    createTag.mutate({
      name: filter,
    });

    setFilter('');
  };

  const tags = tagsResponse?.data?.tags ?? [];

  const tagsWithoutSelected = useMemo(
    () => tags.filter((tag) => !stateValue?.includes(tag.id)),
    [tagsResponse, value],
  );

  const hasExactSameTag = useMemo(
    () =>
      tagsWithoutSelected.some(
        (tag) => tag.name.toUpperCase() === filter.trim().toUpperCase(),
      ),
    [tagsWithoutSelected, filter],
  );

  return (
    <Menu
      open={open}
      modal={false}
      onDismiss={() => {
        setOpen(false);
        setFilter('');
      }}
    >
      <MenuTrigger
        asChild
        ref={ref}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Badge
          as='button'
          variant='gray'
          shape='rectangle'
          size='xs'
          css={{
            border: '1px dashed $neutral-blue-400',
            gap: 2,
            outline: 'none',
            focus: {
              boxShadow: '$focus',
            },
          }}
        >
          <Icon inherit name='plus' size={12} /> Add Tag
        </Badge>
      </MenuTrigger>
      <MenuContent
        style={{ paddingBottom: tagsWithoutSelected.length ? 8 : 0 }}
        css={{ width: 320 }}
        align='start'
      >
        <List
          async
          multi
          filterSelected
          value={stateValue}
          onChange={(keys, newValues) => {
            const newValue = newValues[0];
            setFilter('');

            if (newValue) {
              addTag.mutate({
                name: newValue.name,
                id: newValue.id,
              });
            }

            setStateValue(keys);
          }}
          options={tags}
        >
          <Box css={{ px: 16, py: 12 }}>
            <ListInput
              leftIcon={<></>}
              rightIcon={isRefetching ? <Spinner /> : <></>}
              autoFocus
              placeholder='Search or create tags'
              value={filter}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  filter &&
                  !tagsWithoutSelected.length
                ) {
                  handleCreateTag();
                }
              }}
              onChange={(f: string) => {
                setFilter(f);
              }}
            />
          </Box>
          <ListBox
            filterSelected
            multi
            css={{ p: 0 }}
            aria-label='project list'
            autoFocus='first'
          >
            {(tag) => (
              <Option
                css={{
                  py: 4,
                  focus: {
                    backgroundColor: '$background-component',
                    [`& ${StyledBadge}`]: {
                      backgroundColor: '$neutral-blue-400',
                    },
                  },
                }}
              >
                <Badge size='xs' variant='gray' shape='rectangle'>
                  {tag.name}
                </Badge>
              </Option>
            )}
          </ListBox>
          {filter.trim() && (
            <MenuItem
              disabled={!filter || hasExactSameTag}
              onClick={handleCreateTag}
              css={{
                color: '$primary-600',
                fill: '$primary-600',
                borderTop: '1px solid $neutral-blue-300',
                justifyContent: 'space-between',
                disabled: {
                  color: '$text-disabled',
                  fill: '$text-disabled',
                },
              }}
            >
              <span>
                Create
                {filter && (
                  <Badge
                    size='xs'
                    variant='gray'
                    shape='rectangle'
                    css={{ ml: 8 }}
                  >
                    {filter}
                  </Badge>
                )}
              </span>
              <Icon inherit name='arrow-narrow-right' />
            </MenuItem>
          )}
        </List>
      </MenuContent>
    </Menu>
  );
});

AddProjectTagMenu.displayName = 'AddProjectTagMenu';
