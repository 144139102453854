import { Box, Flex, FlexProps, Text } from '@betterleap/ui';

export const CreateSequenceEmptyState = ({ css, ...props }: FlexProps) => {
  return (
    <Flex
      justify='center'
      css={{
        py: 32,
        ...css,
      }}
      {...props}
    >
      <Box
        css={{
          maxWidth: '90%',
          mediaLg: {
            maxWidth: '50%',
          },
        }}
      >
        <Text
          align='center'
          css={{
            fontSize: '$2xl',
            fontWeight: '$medium',
            lineHeight: '32px',
            mb: 12,
          }}
        >
          No sequence steps... yet!
        </Text>
        <Text align='center' css={{ lineHeight: '24px' }}>
          Select a step type to get started. This will be the first contact your
          candidate receives in the sequence
        </Text>
      </Box>
    </Flex>
  );
};
