export function setCookie(name: string, value: string) {
  document.cookie = name + '=' + value + '; path=/';
}

export function getCookie(name: string) {
  const cookies = document.cookie.split(';');
  for (const c of cookies) {
    const cookie = c.trim();
    const index = cookie.indexOf(`${name}=`);
    if (index == 0) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return null;
}
