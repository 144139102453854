import { Avatar } from '@betterleap/shared';
import { RecruiterTestimonalsDto } from '@betterleap/client';
import { Box, Flex } from '@betterleap/ui';
import { EditIcon } from '../RecruiterProfile.elements';

interface RecruiterProfileTestimonialProps {
  testimonial: RecruiterTestimonalsDto;
  onEditClick?: (testimonial: RecruiterTestimonalsDto) => void;
}

export const RecruiterProfileTestimonial = ({
  testimonial,
  onEditClick,
}: RecruiterProfileTestimonialProps) => (
  <Box css={{ position: 'relative', width: '100%' }}>
    {!!onEditClick && <EditIcon onClick={() => onEditClick(testimonial)} />}
    <Flex
      css={{
        width: '100%',
        p: 24,
        border: '1px solid $neutral-blue-300',
        borderRadius: 32,
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 12,
      }}
    >
      <Box>{testimonial.quotes}</Box>
      <Flex>
        <Flex
          css={{
            height: 24,
            width: 24,
            borderRadius: 8,
            background: '$neutral-blue-200',
            justifyContent: 'center',
          }}
        >
          <Avatar
            image={testimonial.logo}
            name={testimonial.companyName}
            color='#3A83F7'
            size={16}
          />
        </Flex>
        <Box
          css={{
            fontSize: '$sm',
            color: '$neutral-blue-700',
            ml: 8,
          }}
        >
          {testimonial.companyName}
        </Box>
      </Flex>
    </Flex>
  </Box>
);
