import React from 'react';
import {
  Button,
  Flex,
  Icon,
  Tab,
  TabContent,
  Tabs,
  TabsList,
  useModal,
} from '@betterleap/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { toggles, useMe } from '@betterleap/shared';
import { Organization } from '@betterleap/client';
import { RootLayout } from './Layouts.template';
import WorkflowModal from './WorkflowModal';
import AnalyticsCopilotTab from './AnalyticsCopilotTab';
import GeneralCopilotTab from './GeneralCopilotTab';

export const CopilotTemplate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const me = useMe();

  const openWorkflowModal = useModal<undefined, { prompt: string }>(
    WorkflowModal,
  );

  const handleOpenWorkflowCenter = async () => {
    await openWorkflowModal();
  };

  const handleTabChange = (path: string) => {
    navigate(path);
  };

  return (
    <Tabs
      css={{ height: '100%' }}
      value={location.pathname}
      tabStyle='underline'
      onValueChange={handleTabChange}
    >
      <RootLayout>
        <RootLayout.Header>
          {me.user?.organization?.tier === Organization.tier.PAID &&
            toggles.isDemoFeatureEnabledByZone() && (
              <TabsList css={{ mb: 16, justifyContent: 'space-between' }}>
                <Flex>
                  <Tab value='/copilot' css={{ fontSize: '$lg' }}>
                    General
                  </Tab>
                  <Tab value='/copilot/analytics' css={{ fontSize: '$lg' }}>
                    Analytics
                  </Tab>
                </Flex>
                <Button
                  css={{
                    gap: 8,
                    '@lg': { display: 'none' },
                  }}
                  variant='secondary'
                  onClick={handleOpenWorkflowCenter}
                >
                  <Icon css={{ fill: 'inherit' }} size={16} name='template' />
                  Workflows Center
                </Button>
              </TabsList>
            )}
        </RootLayout.Header>
        <RootLayout.Copilot>
          <TabContent css={{ height: '100%' }} value='/copilot'>
            <GeneralCopilotTab />
          </TabContent>
          <TabContent css={{ height: '100%' }} value='/copilot/analytics'>
            <AnalyticsCopilotTab />
          </TabContent>
        </RootLayout.Copilot>
      </RootLayout>
    </Tabs>
  );
};

export default CopilotTemplate;
