import { ApiClient } from '@betterleap/client';
import { BetterleapHttpRequest } from '@betterleap/shared';
import config from './config';
import TOKEN from './token';

export const apiClientConfig = {
  BASE: config.endpoints.api.replace('/api', ''),
  TOKEN: async () => TOKEN.get() ?? '',
};

export const apiClient = new ApiClient(apiClientConfig, BetterleapHttpRequest);
