import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import uniq from 'lodash/uniq';
import { SourceCandidatesSearchParams } from './SourceCandidates.types';
import { SearchMode } from './SourceCandidates.template';

export const usePersonSearchQueryParams = () => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const apiSearchParams: SourceCandidatesSearchParams = useMemo(
    () => ({
      page: searchParams.get('page')
        ? Number.parseInt(searchParams.get('page') as string, 10)
        : undefined,
      pageSize: parseInt((searchParams.get('pageSize') as string) ?? '30', 10),
      localities: searchParams.getAll('localities'),
      locations: searchParams.getAll('locations'),
      regions: searchParams.getAll('regions'),
      countries: searchParams.getAll('countries'),
      skills: uniq([
        ...searchParams.getAll('skills'),
        ...searchParams.getAll('skillsShould'),
      ]),
      jobTitlesCurrent: searchParams.getAll('jobTitlesCurrent'),
      jobTitlesPast: searchParams.getAll('jobTitlesPast'),
      jobTitlesCurrentOrPast: searchParams.getAll('jobTitlesCurrentOrPast'),
      industries: searchParams.getAll('industries'),
      yearsOfExperienceMin: searchParams.get('yearsOfExperienceMin')
        ? Number.parseInt(
            searchParams.get('yearsOfExperienceMin') as string,
            10,
          )
        : undefined,
      yearsOfExperienceMax: searchParams.get('yearsOfExperienceMax')
        ? Number.parseInt(
            searchParams.get('yearsOfExperienceMax') as string,
            10,
          )
        : undefined,
      educationSchools: searchParams.getAll('educationSchools'),
      educationMajors: searchParams.getAll('educationMajors'),
      educationDegrees: searchParams.getAll('educationDegrees'),
      companyNamesCurrent: searchParams.getAll('companyNamesCurrent'),
      companyNamesPast: searchParams.getAll('companyNamesPast'),
      companyNamesCurrentOrPast: searchParams.getAll(
        'companyNamesCurrentOrPast',
      ),
      currentCompanySizes: searchParams.getAll('currentCompanySizes'),
      currentOrPastCompanySizes: searchParams.getAll(
        'currentOrPastCompanySizes',
      ),
      gender: searchParams.get('gender') ?? '',
      name: searchParams.get('name') ?? '',
      searchMode:
        (searchParams.get('searchMode') as SearchMode) ??
        SearchMode.WORLD_TALENT_POOL,
      jobTitlesBoolean: searchParams.get('jobTitlesBoolean') ?? undefined,
      skillsBoolean: searchParams.get('skillsBoolean') ?? undefined,
      industriesBoolean: searchParams.get('industriesBoolean') ?? undefined,
      educationSchoolsBoolean:
        searchParams.get('educationSchoolsBoolean') ?? undefined,
      educationMajorsBoolean:
        searchParams.get('educationMajorsBoolean') ?? undefined,
      companyNamesBoolean: searchParams.get('companyNamesBoolean') ?? undefined,
      summaryKeywords: searchParams.getAll('summaryKeywords'),
      excludeViewed:
        searchParams.get('excludeViewed') === 'true' ? true : undefined,
      languageNames: searchParams.getAll('languageNames'),
      summaryKeywordsBoolean:
        searchParams.get('summaryKeywordsBoolean') ?? undefined,
      hasPersonalEmail: searchParams.get('hasPersonalEmail') === 'true',
      zipCodes: searchParams.getAll('zipCodes'),
      boolean: searchParams.get('boolean') ?? undefined,
      certifications: searchParams.getAll('certifications'),
      licensedStates: searchParams.getAll('licensedStates'),
    }),
    [search],
  );

  return apiSearchParams;
};
