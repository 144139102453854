import {
  Avatar,
  Button,
  CompanyInfo,
  getCompanyLogo,
  Types,
} from '@betterleap/shared';
import React from 'react';
import styles from './CompanyDetails.module.scss';

interface CompanyDetailsProps {
  company?: Types.Company;
}

const CompanyDetails = ({ company }: CompanyDetailsProps) =>
  company ? (
    <div>
      <div className={`w-full bg-white ${styles.preview_role_container}`}>
        <div
          className={`flex justify-between items-center w-full ${styles.container_spacing}`}
        >
          <div className='flex items-center w-full'>
            <Avatar
              image={getCompanyLogo(company.companyUrl, company.companyName)}
              name={company?.companyName || ''}
              color='#3A83F7'
              size={57}
            />
            <span className='not-italic font-medium text-lg leading-6 text-gray-900 ml-6'>
              {(company?.companyName &&
                company?.companyName.replace('_DEMO', '')) ||
                ''}
            </span>
          </div>
          <Button
            buttonText='Website'
            variant='ghost'
            onClick={() => {
              const url = company?.companyUrl || '';

              window.open(
                url.startsWith('http') ? url.trim() : `https://${url.trim()}`,
              );
            }}
          />
        </div>
      </div>
      <CompanyInfo data={company} />
      <p className={`mt-10 ${styles.deleting_instruction}`}>
        To delete your account, send an email request to support@betterleap.com
        with subject line &quot;Delete my account&quot;
      </p>
    </div>
  ) : null;
export default CompanyDetails;
