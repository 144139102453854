import { InteractiveTable } from '@betterleap/shared';
import {
  ApiResponse,
  ProjectWithSharedDataDto,
  TagDto,
} from '@betterleap/client';
import {
  Badge,
  Box,
  Flex,
  Spinner,
  Tab,
  TabContent,
  Tabs,
  TabsList,
} from '@betterleap/ui';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  createSearchParams,
  URLSearchParamsInit,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { SortingRule } from 'react-table';
import { SearchInput } from 'components/elements/SearchInput/SearchInput';
import { apiClient } from 'lib/apiClient';
import { ProjectTagFilterMenu } from './ProjectTagFilterMenu';

type TableColumns = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Header: string | ((props: any) => React.ReactNode);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accessor: string | ((props: any) => React.ReactNode);
  slot?: string | undefined;
}[];

interface ProjectsTabsProps {
  myProjects: ApiResponse<ProjectWithSharedDataDto[]> | undefined;
  allProjects: ApiResponse<ProjectWithSharedDataDto[]> | undefined;
  isLoading: boolean;
  pageSize: number;
  columns: TableColumns;
  onRowClick: (commandPressed: boolean, data: unknown) => Promise<void>;
}

export const ProjectsKeys = { all: 'all', my: 'my', shared: 'shared' };

export const ProjectsTabs = ({
  myProjects,
  allProjects,
  isLoading,
  pageSize,
  columns,
  onRowClick,
}: ProjectsTabsProps) => {
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = qs.parse(search.slice(1));

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [hasMyProjects, setHasMyProjects] = useState(false);
  const [filterTags, setFilterTags] = useState<TagDto[]>();

  const allProjectsTab = {
    key: ProjectsKeys.all,
    label: 'All Projects',
    enabled: true,
    data: allProjects,
    isLoading,
  };
  const myProjectsTab = {
    key: ProjectsKeys.my,
    label: 'My Projects',
    enabled: hasMyProjects,
    data: myProjects,
    isLoading,
  };

  const tabs = [allProjectsTab, myProjectsTab];
  const defaultTab = (queryParams.currentTab as string) || allProjectsTab.key;
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const tagQuery =
    typeof queryParams.tags === 'string'
      ? [queryParams.tags]
      : queryParams.tags;

  useQuery(
    ['findProjectTags', { ids: tagQuery }],
    () =>
      apiClient.projectTag.findTags({
        ids: tagQuery as string[],
      }),
    {
      onSuccess: (response) => {
        if (response.data?.tags?.length && !filterTags) {
          setFilterTags(response.data?.tags);
        }
      },
      enabled: !!tagQuery?.length,
    },
  );

  const handleTagFilterChange = (tags: TagDto[]) => {
    searchParams.delete('tags');

    tags.forEach((tag) => {
      searchParams.append('tags', tag.id);
    });

    setSearchParams(searchParams);
    setFilterTags(tags);
  };

  const handleSort = (sort?: SortingRule<object>) => {
    const currentParams = Object.fromEntries(searchParams);

    if (!sort) {
      delete currentParams.sortBy;
      delete currentParams.sortOrder;
      setSearchParams(currentParams);
    } else if (sort) {
      setSearchParams(
        createSearchParams({
          ...currentParams,
          sortBy: sort.id,
          sortOrder: sort.desc ? 'DESC' : 'ASC',
        }),
      );
    }
  };

  useEffect(() => {
    if (isInitialLoading && !isLoading) {
      setIsInitialLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (myProjects?.data?.length) {
      setHasMyProjects(true);
    }
  }, [myProjects?.data]);

  useEffect(() => {
    if (currentTab && currentTab !== queryParams.currentTab) {
      const newParams: URLSearchParamsInit = {
        ...Object.fromEntries(searchParams.entries()),
        currentTab,
      };
      for (const tab of tabs) {
        newParams[`${tab.key}page`] = '1';
      }
      setSearchParams(newParams);
    }
  }, [currentTab]);

  useEffect(() => {
    if (queryParams.currentTab && queryParams.currentTab !== currentTab) {
      setCurrentTab(queryParams.currentTab as string);
    }
  }, [queryParams.currentTab]);

  if (isInitialLoading) {
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }

  return (
    <Box css={{ mb: 32 }}>
      <Tabs css={{ flex: 1 }} defaultValue={defaultTab} tabStyle='underline'>
        <TabsList
          css={{
            mb: 20,
            mt: 30,
            justifyContent: 'space-between',
            overflow: 'visible',
          }}
        >
          <Flex>
            {tabs.map(
              (tab) =>
                tab.enabled && (
                  <Tab
                    key={tab.key}
                    value={tab.key}
                    css={{ fontWeight: '$medium', fontSize: '$xl' }}
                    onClick={() => setCurrentTab(tab.key)}
                  >
                    {tab.label}
                    {!!tab.data?.meta?.count && (
                      <Badge variant='violet' size='sm'>
                        {tab.data?.meta?.count}
                      </Badge>
                    )}
                  </Tab>
                ),
            )}
          </Flex>
          <Flex css={{ py: 8, gap: 8 }}>
            <SearchInput
              query={queryParams.search as string}
              pageParamNames={Object.keys(ProjectsKeys).map((k) => `${k}page`)}
            />
            <ProjectTagFilterMenu
              selectedTags={filterTags ?? []}
              onChange={handleTagFilterChange}
            />
          </Flex>
        </TabsList>
        {tabs.map((tab) => (
          <TabContent key={tab.key} value={tab.key}>
            <InteractiveTable
              tableData={tab.data?.data || []}
              onRowClick={onRowClick}
              onSort={handleSort}
              columns={columns}
              count={tab.data?.meta?.count ?? 0}
              loading={tab.isLoading}
              paginationKey={tab.key}
              pageSize={pageSize}
            />
          </TabContent>
        ))}
      </Tabs>
    </Box>
  );
};
