import { excludedEmails, Spinner, Types, useUpload } from '@betterleap/shared';
import { ExtendedContactProject, FileEntity } from '@betterleap/client';
import {
  AlertIcon,
  Button,
  Col,
  Flex,
  Form,
  BoundInput as Input,
  Label,
  Modal,
  ModalBody,
  ModalClose,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PromiseModal,
  Row,
  Text,
  BoundTextArea as TextArea,
  useExpandingTextArea,
} from '@betterleap/ui';
import { useMutation, useQuery } from 'react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import get from 'lodash/get';
import { FileBar, UploadArea } from 'components/elements/UploadArea';
import { apiClient } from 'lib/apiClient';
import useFetch from 'hooks/fetch';
import { requiredString } from '../../../../schemas/helpers';

export interface SubmitCandidatesModalProps {
  isProjectShared: boolean;
  submitToAts?: boolean;
  contact: ExtendedContactProject;
}

export const SubmitCandidateSchema = z.object({
  notes: z.string(),
  fileIds: z.array(z.string()),
});

const SubmitCandidateWithInviteSchema = z.object({
  firstName: requiredString('First Name'),
  lastName: requiredString('Last Name'),
  email: requiredString('email').regex(
    new RegExp(`^((?!${excludedEmails.join('|')}).)*$`),
    'Please use a business email.',
  ),
  notes: z.string(),
  fileIds: z.array(z.string()),
});

export type SubmitCandidateForm = z.infer<typeof SubmitCandidateSchema>;
export type SubmitCandidateWithInviteForm = z.infer<
  typeof SubmitCandidateWithInviteSchema
>;

export const SubmitCandidatesModal: PromiseModal<
  SubmitCandidatesModalProps,
  SubmitCandidateForm | SubmitCandidateWithInviteForm
> = ({
  onSubmit,
  onDismiss,
  onReject,
  contact,
  isProjectShared,
  submitToAts,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const textAreaProps = useExpandingTextArea(textAreaRef, {
    maxHeight: 212,
    minHeight: 120,
  });
  const [uploads, setUploads] = useState<Types.File[]>([]);
  const [uploadData, doUpload, resetUploadData, createdFile] = useUpload(
    useFetch,
    `users/{userUid}/private/resumes/`,
    'save_files',
  );

  const validations = zodResolver(
    isProjectShared ? SubmitCandidateSchema : SubmitCandidateWithInviteSchema,
  );

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<SubmitCandidateWithInviteForm | SubmitCandidateForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      notes: '',
      fileIds: [],
    },
    resolver: validations,
  });

  useEffect(() => {
    if (createdFile.data) {
      setUploads([...uploads, ...createdFile.data]);
      setValue('fileIds', [
        ...uploads.map((file) => file.id),
        ...createdFile.data.map((file) => file.id),
      ]);
      resetUploadData();
    }
  }, [createdFile.data]);

  useQuery(
    ['attachments', contact.id],
    () =>
      apiClient.attachment.getAttachments({
        contactId: contact.id,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setUploads(data.data.map((a) => a.file as FileEntity));
        setValue(
          'fileIds',
          data.data.map((a) => a.file?.id as string),
        );
      },
    },
  );

  const submitCandidate = useMutation((data: SubmitCandidateWithInviteForm) =>
    apiClient.application.submitCandidate({
      requestBody: {
        contactProjectId: contact.contactProjectId,
        notes: data.notes,
        fileIds: data.fileIds,
        client: data.email
          ? {
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
            }
          : undefined,
      },
    }),
  );

  const submit: SubmitHandler<
    SubmitCandidateWithInviteForm | SubmitCandidateForm
  > = async (data) => {
    try {
      await submitCandidate.mutateAsync(data as SubmitCandidateWithInviteForm);
      onSubmit(data);
    } catch (err) {
      onReject(err);
    }
  };

  const handleFileUpload = (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      doUpload(acceptedFiles);
    }
  };

  const handleOnFileRemove = (fileId: string) => {
    const newUploads = uploads.filter((file) => file.id !== fileId);
    setValue(
      'fileIds',
      newUploads.map((file) => file.id),
    );
    setUploads(newUploads);
  };

  return (
    <Modal size='medium'>
      <ModalHeader>
        <Flex justify='center'>
          <AlertIcon
            shape='rounded-square'
            size='lg'
            css={{ mb: 8 }}
            variant='purple'
            name='user-add'
          />
        </Flex>
        <ModalTitle>
          Submit{' '}
          <Text
            as='span'
            inline
            inherit
            css={{ color: '$purple-700' }}
          >{`${contact.firstName} ${contact.lastName}`}</Text>
        </ModalTitle>
        <Text
          size='sm'
          css={{
            color: '$neutral-blue-600',
            textAlign: 'center',
            mb: 24,
            px: 48,
          }}
        >
          {submitToAts
            ? 'We will send an email notification to your client and add this candidate to their ATS.'
            : 'We will send an email notification to your client.'}
        </Text>
      </ModalHeader>
      <ModalClose />
      <ModalBody css={{ px: 4 }}>
        <Form control={control} onSubmit={handleSubmit(submit)}>
          {!isProjectShared && (
            <>
              <Label css={{ width: '100%', fontWeight: 'normal', mb: 8 }}>
                <Flex css={{ gap: 8 }}>
                  <AlertIcon
                    size='sm'
                    variant='info'
                    shape='rounded-square'
                    name='paper-airplane'
                  />
                  Add client details
                </Flex>
              </Label>
              <Row>
                <Col span='6'>
                  <Input
                    id='firstName'
                    name='firstName'
                    aria-label='first name'
                    autoFocus
                    placeholder='First name'
                  />
                </Col>
                <Col span='6'>
                  <Input
                    id='lastName'
                    name='lastName'
                    placeholder='Last name'
                    aria-label='last name'
                  />
                </Col>
              </Row>
              <Input
                css={{ mb: 4 }}
                id='email'
                name='email'
                placeholder='Email address'
                aria-label='email'
              />
            </>
          )}
          <Label css={{ width: '100%', fontWeight: 'normal', mb: 8 }}>
            <Flex css={{ gap: 8 }}>
              <AlertIcon
                size='sm'
                variant='purple'
                shape='rounded-square'
                name='document-add'
              />
              Add candidate notes
            </Flex>
          </Label>
          <TextArea
            ref={textAreaRef}
            id='notes'
            aria-label='candidate notes'
            name='notes'
            autoFocus={isProjectShared}
            placeholder='Add a note here'
            css={{ mb: 14 }}
            {...textAreaProps}
          />
          <Label css={{ width: '100%', fontWeight: 'normal', mb: 8 }}>
            <Flex css={{ gap: 8 }}>
              <AlertIcon
                size='sm'
                variant='success'
                shape='rounded-square'
                name='document'
              />
              Add files
            </Flex>
          </Label>
          <Controller
            data-cy='file-upload-area'
            name='fileIds'
            control={control}
            render={() => (
              <UploadArea
                id='resumeFiles'
                accept='.pdf'
                showIcon={false}
                description='PDF up to 10MB'
                loading={uploadData.loading}
                onChange={handleFileUpload}
                inputProps={{ 'aria-describedby': 'error-resume-files' }}
              />
            )}
          />
          {uploads.map((upload) => (
            <FileBar
              key={upload.id}
              css={{ fontSize: '$sm' }}
              onRemove={() => handleOnFileRemove(upload.id)}
              data-testid='resume-file'
            >
              <p>{upload.name}</p>
            </FileBar>
          ))}
          <p
            id='error-resume-files'
            className='p-1 text-xs text-red-400 h-6'
            role={errors.fileIds ? 'alert' : undefined}
          >
            {get(errors, 'files.message')}
          </p>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Flex justify='center' css={{ gap: 12, pt: 2, width: '100%' }}>
          <Button onClick={onDismiss} variant='secondary' css={{ width: 120 }}>
            Cancel
          </Button>
          <Button
            type='submit'
            onClick={handleSubmit(submit)}
            css={{
              width: 120,
              '& svg': {
                marginRight: 0,
              },
            }}
          >
            {submitCandidate.isLoading ? (
              <Spinner variant='white' />
            ) : (
              'Confirm'
            )}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
