import { OnboardDto } from '@betterleap/client';
import {
  BoundInput as Input,
  Label,
  BoundSelect as Select,
} from '@betterleap/ui';

interface Props {
  currentGoal: OnboardDto.goal | undefined;
}

export const OnboardingGoal = ({ currentGoal }: Props) => {
  const goals = {
    [OnboardDto.goal.REDUCE_COSTS]: 'Reduce costs',
    [OnboardDto.goal.INCREASE_EMAIL_COVERAGE]: 'Increase email coverage',
    [OnboardDto.goal.IMPROVE_EFFICIENCY]: 'Improve my team‘s efficiency',
    [OnboardDto.goal.AI]: 'Take advantage of AI technologies',
    [OnboardDto.goal.OTHER]: 'Other (please specify)',
  };
  const o = Object.entries(goals).map(([value, label]) => ({ value, label }));
  return (
    <>
      <Select
        id='goal'
        name='goal'
        required
        placeholder='Select one'
        size='lg'
        label={
          <Label css={{ mb: 24, fontSize: '$lg' }}>
            What‘s the main goal you hope to achieve with Betterleap?
          </Label>
        }
        options={o}
        css={{ maxWidth: 600 }}
      />
      {currentGoal === OnboardDto.goal.OTHER && (
        <Input
          id='otherGoal'
          name='otherGoal'
          placeholder='Please provide more details here'
          aria-label='Please provide more details here'
          size='xl'
          required
          css={{ maxWidth: 600 }}
        />
      )}
    </>
  );
};
