import { extensionUrl } from 'constants/externalUrls';
import { ProjectWithStatsDto } from '@betterleap/client';
import { useNavigate } from 'react-router-dom';
import { useAddContacts } from './Hooks/useAddContacts';
import { ProjectEmptyState } from './ProjectEmptyState';
import { resolveSubject } from './ProjectDetails.functions';

export const EmptyProjectDetailsTable = ({
  project,
  refreshData,
}: {
  project: ProjectWithStatsDto;
  refreshData: () => void;
}) => {
  const navigate = useNavigate();
  const { addContacts } = useAddContacts({
    project,
    onContactsAdded: refreshData,
  });
  return (
    <ProjectEmptyState
      title={`Add ${resolveSubject(project, { cap: true, singular: false })}`}
      subtitle='Choose from the below options to get started.'
      primaryButton={{
        label: 'Start Sourcing in Betterleap',
        onClick: () => navigate('/sourcing'),
      }}
      secondaryButton={{
        label: 'Use the Chrome Extension',
        onClick: () => window.open(extensionUrl),
      }}
      bottomButton={{
        label: 'Upload a CSV',
        icon: 'upload',
        onClick: addContacts,
      }}
    />
  );
};
