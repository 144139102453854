import { Spinner } from '@betterleap/shared';
import { Recruiter } from '@betterleap/client';
import { Flex } from '@betterleap/ui';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useFetch from '../../../hooks/fetch';
import { RecruiterProfile } from '../RecruiterProfile/RecruiterProfile';

const CompanyRecruiterProfileTemplate = () => {
  const location = useLocation();
  const id = location.pathname.split('/')[2];

  const [recruiter, doFetch] = useFetch<Recruiter>('recruiter_by_id');

  useEffect(() => {
    doFetch({ id });
  }, []);

  if (!recruiter.data || recruiter.loading)
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );

  return <RecruiterProfile recruiter={recruiter.data} />;
};

export default CompanyRecruiterProfileTemplate;
