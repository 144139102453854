import { useMe } from '@betterleap/shared';
import { Organization, ProjectWithStatsDto } from '@betterleap/client';
import { ConnectAtsButton } from './Merge/ConnectAtsButton';
import { ConnectCrmButton } from './Merge/ConnectCrmButton';

interface ProjectATSBannerProps {
  organization: Organization;
  project: ProjectWithStatsDto;
  onSomethingHappened: () => void;
}

export const IntegrationButton = ({
  organization,
  project,
  onSomethingHappened,
}: ProjectATSBannerProps) => {
  const me = useMe();
  const personalType = ProjectWithStatsDto.contactEmailEnrichmentMode.PERSONAL;
  const isPersonal = project.contactEmailEnrichmentMode === personalType;

  if (organization.integrationsDisabled) {
    return null;
  }

  if (isPersonal && !project.roleId) {
    return (
      <ConnectAtsButton
        organization={organization}
        project={project}
        onRoleLinked={onSomethingHappened}
      />
    );
  }

  if (
    !isPersonal &&
    !organization.crmAccountToken &&
    me.ability.can('update', 'Organization', 'crmSource')
  ) {
    return <ConnectCrmButton onSuccess={onSomethingHappened} />;
  }

  return null;
};
