import { useEffect } from 'react';
import qs from 'qs';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useModal } from '@betterleap/ui';
import CreateNoteModal from 'components/modules/Modal/CreateNoteModal/CreateNoteModal';
import { useMe } from '@betterleap/shared';
import { ProjectTemplateSearchParms } from '../ProjectContacts.types';

export const useContactNotes = ({
  projectId,
  onModalClosed,
}: {
  projectId: string;
  onModalClosed: () => void;
}) => {
  const me = useMe();

  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const queryParams: ProjectTemplateSearchParms = qs.parse(search.slice(1));

  const showCreateNoteModal = useModal<{
    projectId: string;
    contactProjectId: string;
    userId: string;
  }>(CreateNoteModal);

  const addNote = async (contactProjectId: string) => {
    await showCreateNoteModal({
      contactProjectId,
      projectId,
      userId: me.user?.id as string,
    });
    onModalClosed();
  };

  useEffect(() => {
    const showNotes = async () => {
      if (queryParams['contact-project']) {
        await showCreateNoteModal({
          contactProjectId: queryParams['contact-project'],
          projectId,
          userId: me.user?.id as string,
        });
        searchParams.delete('contact-project');
        setSearchParams(searchParams);
      }
    };
    showNotes();
  }, [queryParams['contact-project']]);

  return { addNote };
};
