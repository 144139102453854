import { EnrichmentJobExperience } from '@betterleap/client';
import { Flex, Text } from '@betterleap/ui';
import ContactProfileExperienceItem from './ContactProfileExperienceItem.template';

export interface ContactProfileExperienceProps {
  jobExperienceHistory: EnrichmentJobExperience[];
}

const ContactProfileExperience = (props: ContactProfileExperienceProps) => {
  const { jobExperienceHistory } = props;

  return (
    <Flex vertical css={{ gap: 20, height: '100%', width: '100%' }}>
      <Text as='h3' css={{ fontWeight: '$medium', fontSize: '$lg' }}>
        {jobExperienceHistory.length ? 'Experience' : 'No Experience Found'}
      </Text>
      <Flex css={{ position: 'relative', height: '100%', width: '100%' }}>
        <Flex
          vertical
          css={{
            gap: 20,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflowY: 'auto',
          }}
        >
          {jobExperienceHistory.map((exp, i) => (
            <ContactProfileExperienceItem
              key={`cpei_${i}`}
              experienceData={exp}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ContactProfileExperience;
