import { Box, Col, Flex, Row } from '@betterleap/ui';
import { Organization } from '@betterleap/client';
import { get } from 'lodash';
import candidateDetails from '../../../assets/images/candidate-details.svg';
import followUps from '../../../assets/images/follow-ups.svg';
import oneClickSourcing from '../../../assets/images/one-click-sourcing.svg';
import personalizedEmails from '../../../assets/images/personalized-emails-scale.svg';
import InfoCard from '../../elements/InfoCard/InfoCard';
import Paywall from '../../elements/Paywall/Paywall';

interface RecruiterToolPaywallProps {
  tier: Organization.tier;
}

const paywallContent = {
  [Organization.tier.FREE_TRIAL_REQUESTED]: {
    title: 'Thank you for signing up!',
    description: `To provide you with a personalized experience, one of our account executives will reach out to schedule a demo.`,
  },
  [Organization.tier.PAYWALL]: {
    title: 'A better way to engage and convert talent',
    description:
      'Use our sourcing & sequencing tools to 10x your top of funnel',
  },
};

const valueProps = [
  {
    title: `One-click\nSourcing`,
    description:
      'Source and enroll candidates into email sequences in just one click.',
    color: '#F8EED7',
    image: oneClickSourcing,
  },
  {
    title: `Personalized\nEmails at Scale`,
    description:
      'Develop relationships by using hyper-personalized email sequences.',
    color: '#FAE4E4',
    image: personalizedEmails,
  },
  {
    title: `Candidate\nDetails`,
    description:
      'Discover candidate contact information to personalize emails just for them.',
    color: '#D3E2FA',
    image: candidateDetails,
  },
  {
    title: `Automated\nFollow-Ups`,
    description:
      'Boost reply rates by staying top of mind with automated follow-ups.',
    color: '#EDE9FE',
    image: followUps,
  },
];

const RecruiterToolPaywall = ({ tier }: RecruiterToolPaywallProps) => (
  <Box
    css={{
      width: '100%',
      margin: '0 auto',
      mediaMd: {
        width: '90%',
      },
      mediaLg: {
        width: '65%',
      },
    }}
  >
    <Flex align='center' vertical css={{ p: 24 }}>
      <Paywall
        title={get(paywallContent, `${tier}.title`)}
        description={get(paywallContent, `${tier}.description`)}
        location='Recruiter Paywall'
      />

      <Row
        css={{
          gap: 30,
          rowGap: 60,
          mt: 80,
          p: 16,
          flexWrap: 'wrap',
          mediaLg: {
            flexWrap: 'nowrap',
          },
        }}
      >
        {valueProps.map((prop) => (
          <Col
            span={{
              '@initial': 12,
              '@md': 6,
              '@lg': 3,
            }}
            css={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <InfoCard
              title={prop.title}
              description={prop.description}
              backgroundColor={prop.color}
              css={{ maxWidth: 195, height: 280 }}
              image={
                <Box
                  as='img'
                  src={prop.image}
                  alt='one_click_sourcing'
                  css={{
                    height: 130,
                    position: 'relative',
                    right: '-20px',
                    top: '-20px',
                  }}
                />
              }
            />
          </Col>
        ))}
      </Row>
    </Flex>
  </Box>
);
export default RecruiterToolPaywall;
