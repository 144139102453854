import { Flex, FlexProps, Icon } from '@betterleap/ui';
import AmexLogo from '../../../assets/images/american-express.svg';
import VisaLogo from '../../../assets/images/visa.svg';
import DiscoverLogo from '../../../assets/images/discover.svg';
import MastercardLogo from '../../../assets/images/mastercard.svg';

export interface CreditCardIconProps extends FlexProps {
  brand: string;
}

export enum CREDIT_CARD_BRANDS {
  AMEX = 'amex',
  MASTERCARD = 'mastercard',
  DISCOVER = 'discover',
  VISA = 'visa',
}

export const CreditCardIcon = ({
  brand,
  css,
  ...rest
}: CreditCardIconProps) => {
  const renderCreditCardIcon = () => {
    switch (brand) {
      case CREDIT_CARD_BRANDS.AMEX:
        return (
          <img style={{ height: 16 }} src={AmexLogo} alt='amex card logo' />
        );
      case CREDIT_CARD_BRANDS.MASTERCARD:
        return <img src={MastercardLogo} alt='mastercard card logo' />;
      case CREDIT_CARD_BRANDS.DISCOVER:
        return <img src={DiscoverLogo} alt='discover card logo' />;
      case CREDIT_CARD_BRANDS.VISA:
        return <img src={VisaLogo} alt='visa credit card logo' />;
      default:
        return <Icon size={16} name='credit-card' />;
    }
  };

  return (
    <Flex
      justify='center'
      css={{
        height: 20,
        p: 2,
        width: 30,
        borderRadius: '$sm',
        overflow: 'hidden',
        backgroundColor: '$white',
        border: '1px solid #D9D9D9',
        ...css,
      }}
      {...rest}
    >
      {renderCreditCardIcon()}
    </Flex>
  );
};
