import {
  getFullName,
  getUser,
  showToast,
  Spinner,
  Types,
} from '@betterleap/shared';
import { Organization } from '@betterleap/client';
import { Flex } from '@betterleap/ui';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { logToSegment } from 'functions/segmentLog';
import { useAcceptTerms } from 'hooks/useAcceptTerms';
import { CsvTaskList } from 'components/templates/Projects/CsvTaskList';
import { ChatAppContextProvider } from '@betterleap/chat-shared';
import config from 'lib/config';
import { FeatureFlagKey, useFeatureFlag } from 'hooks/useFeatureFlag';
import { OnboardingChecklist } from '../components/elements/OnboardingChecklist/OnboardingChecklist';
import CompanyRoute from '../components/elements/ProtectedRoutes/CompanyRoute';
import OrganizationRoute from '../components/elements/ProtectedRoutes/OrganizationRoute';
import { Modals } from '../components/modules/Modal';
import routeNames from '../constants/routeNames';
import { useRupt } from '../hooks/rupt';
import { selectMe, selectOrganization } from '../store/api/selectors';
import { appActions } from '../store/app/actions';
import { selectAppInit } from '../store/app/selectors';
import { useHotjarIdentify } from '../hooks/analytics';
import { isPaywallTier } from '../helper/paywall';
import AdminNoAccess from './AdminNoAccess';
import Assistant from './Assistant';
import CallbackLoading from './CallbackLoading';
import CompanyRecruiterProfile from './CompanyRecruiterProfile';
import Home from './Home';
import OrganizationCreateSequence from './OrganizationCreateSequence';
import OrganizationEditSequence from './OrganizationEditSequence';
import OrganizationInvitePage from './OrganizationInvite';
import OrganizationProject from './OrganizationProject';
import OrganizationProjects from './OrganizationProjects';
import OrganizationSequenceDetail from './OrganizationSequenceDetail';
import Portfolio from './Portfolio';
import Profile from './Profile';
import SenderConnectCallback from './SenderConnectCallback';
import Settings from './Settings';
import SignIn from './SignInPage';
import SignUp from './SignUpPage';
import ContactUnsubscribePage from './ContactUnsubscribePage';
import ContactProfilePage from './ContactProfilePage';
import Onboarding from './OnboardingPage';
import OrganizationSourceCandidates from './OrganizationSourceCandidates';
import EmailFinder from './EmailFinder';
import Dashboard from './Dashboard';
import SavedSearches from './SavedSearches';
import OrganizationMessaging from './OrganizationMessaging';

const AppRouter = (): JSX.Element => {
  const dispatch = useDispatch();
  const isFirebaseInited = useSelector(selectAppInit);
  const organization = useSelector(selectOrganization);
  const ldClient = useLDClient();
  const me = useSelector(selectMe);
  const navigate = useNavigate();
  const location = useLocation();
  const { checkDevices } = useRupt();
  const hasCandidateMessaging = useFeatureFlag(
    FeatureFlagKey.CANDIDATE_MESSAGING,
  );

  useHotjarIdentify();

  useEffect(() => {
    dispatch(appActions.init({ navigate, location }));
    window.scrollTo(0, 0);
  }, [dispatch]);

  const pagePath = location.pathname.split('/')[1];

  useEffect(() => {
    if (pagePath === 'unsubscribe' || !pagePath) return;
    const properties = {
      page_name: pagePath,
      userId: me?.data?.id,
      email: me?.data?.email,
      organizationId: me?.data?.organizationId,
      name: getFullName(me?.data),
    };
    logToSegment(Types.SEGMENT_EVENTS.PAGE_VIEWED, properties);
  }, [pagePath]);

  const { acceptTerms } = useAcceptTerms();

  useEffect(() => {
    if (!me?.data) {
      return;
    } else {
      // indentify launch darkly
      ldClient?.identify({
        kind: 'multi',
        user: {
          key: me.data.id,
          name: getFullName(me.data),
          email: me.data.email,
          role: me.data.roles[0],
        },
        organization: {
          key: me.data.organizationId,
          name: me.data.organization?.name,
          domain: me.data.organization?.domain,
          tier: me.data.organization?.tier,
          orgType: me.data.organization?.orgType,
        },
        group: {
          key: me.data.groupId,
        },
      });

      checkDevices(me.data);
    }
    acceptTerms();
  }, [me]);

  if (organization.loading) {
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );
  }

  if (!isFirebaseInited) return <></>;

  return (
    <ChatAppContextProvider
      getUserToken={() => getUser().then((u) => u?.getIdToken() ?? null)}
      showGlobalError={(message) => showToast(message, undefined, 'error')}
      showToast={(message) => showToast(message, undefined, 'success')}
      initialState={{
        config: {
          apiBaseUrl: config.endpoints.api,
          apiNamespace: 'recruiter',
        },
        authState: 'signedIn',
        workspaces: [],
      }}
      enabled={hasCandidateMessaging}
    >
      {me.data &&
        organization.data &&
        !isPaywallTier(organization.data?.tier) && <OnboardingChecklist />}
      <Routes>
        <Route path={routeNames.home()} element={<Home />} />
        <Route
          path={routeNames.verifyEmailLink()}
          element={<SignIn verifyEmail />}
        />

        <Route path={routeNames.adminNoAccess()} element={<AdminNoAccess />} />
        <Route path={routeNames.copilot()} element={<Assistant />} />
        <Route path={routeNames.signin()} element={<SignIn />} />
        <Route path={routeNames.signup()} element={<SignUp />} />
        <Route path={routeNames.emailFinder()} element={<EmailFinder />} />
        <Route path={routeNames.onboarding()} element={<Onboarding />} />
        <Route
          path={routeNames.team()}
          element={<OrganizationRoute element={<OrganizationInvitePage />} />}
        />
        <Route
          path={routeNames.companyRecruiterProfile()}
          element={<CompanyRoute element={<CompanyRecruiterProfile />} />}
        />
        <Route
          path={routeNames.profile()}
          element={<OrganizationRoute element={<Profile />} />}
        />
        <Route
          path={routeNames.createRecruiterProfile()}
          element={<OrganizationRoute element={<Profile />} />}
        />
        <Route
          path={routeNames.settings()}
          element={<OrganizationRoute element={<Settings />} />}
        />
        <Route
          path={routeNames.dashboard()}
          element={<OrganizationRoute element={<Dashboard />} />}
        />
        <Route
          path={routeNames.portfolio()}
          element={<OrganizationRoute element={<Portfolio />} />}
        />
        <Route
          path={routeNames.sourcing()}
          element={
            <OrganizationRoute element={<OrganizationSourceCandidates />} />
          }
        />
        <Route
          path={routeNames.projects()}
          element={<OrganizationRoute element={<OrganizationProjects />} />}
        />
        <Route
          path={routeNames.project()}
          element={<OrganizationRoute element={<OrganizationProject />} />}
        />
        {(organization?.data?.tier === Organization.tier.PAID ||
          organization?.data?.tier === Organization.tier.FREE_TRIAL) && (
          <Route
            path={routeNames.editSequence()}
            element={
              <OrganizationRoute element={<OrganizationEditSequence />} />
            }
          />
        )}
        <Route
          path={routeNames.createSequence()}
          element={
            <OrganizationRoute element={<OrganizationCreateSequence />} />
          }
        />
        <Route
          path={routeNames.sequenceDetail()}
          element={
            <OrganizationRoute element={<OrganizationSequenceDetail />} />
          }
        />
        <Route
          path={routeNames.contactProfile()}
          element={<OrganizationRoute element={<ContactProfilePage />} />}
        />

        {organization.data && (
          <Route
            path={routeNames.senderConnectCallback()}
            element={<SenderConnectCallback />}
          />
        )}

        <Route
          path={routeNames.contactUnsubscribe()}
          element={<ContactUnsubscribePage />}
        />
        <Route
          path={routeNames.savedSearches()}
          element={<OrganizationRoute element={<SavedSearches />} />}
        />
        <Route
          path={routeNames.chats()}
          element={<OrganizationRoute element={<OrganizationMessaging />} />}
        />
        <Route
          path={routeNames.chatThread()}
          element={<OrganizationRoute element={<OrganizationMessaging />} />}
        />
        <Route path={routeNames.callback()} element={<CallbackLoading />} />
      </Routes>
      <Modals />
      <CsvTaskList />
    </ChatAppContextProvider>
  );
};

export default AppRouter;
