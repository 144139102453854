/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Flex,
  IconButton,
  Modal,
  PromiseModal,
  Text,
} from '@betterleap/ui';
import InfoCard from 'components/elements/InfoCard/InfoCard';
import automatedFollowUps from '../../../../assets/images/automated_follow_ups.png';
import completeTransparency from '../../../../assets/images/complete_transparency.png';
import oneClickSourcing from '../../../../assets/images/one_click_sourcing.png';

const PaywallPopupModal: PromiseModal<Record<string, string>> = ({
  onDismiss,
}) => {
  const handleClose = () => {
    onDismiss();
  };

  return (
    <Modal css={{ maxWidth: '947px', borderRadius: '16px' }}>
      <Flex justify='end' css={{ width: '100%' }}>
        <IconButton size='sm' label='close' name='x' onClick={handleClose} />
      </Flex>
      <Flex
        vertical
        css={{
          width: '100%',
          p: 40,
          alignItems: 'center',
        }}
      >
        <Text
          as='h1'
          css={{
            fontSize: '32px',
            color: '#3A83F7',
            fontFamily: '$gilroy',
            maxWidth: 500,
            textAlign: 'center',
          }}
        >
          A better way to engage and convert talent
        </Text>
        <Text as='h3' css={{ color: '$neutral-blue-500', fontSize: '$sm' }}>
          To create your first project, get in touch with our sales team
        </Text>

        <Flex
          css={{
            gap: 74,
            mt: 46,
            position: 'relative',
            overflowX: 'scroll',
            width: '100%',
            mediaMd: {
              justifyContent: 'center',
              position: 'unset',
              overflowX: 'unset',
              width: 'unset',
            },
          }}
        >
          <InfoCard
            title='One-click Sourcing'
            description='Source and enroll candidates into email sequences in just one click.'
            css={{ maxWidth: 195, height: 280 }}
            marginTop={24}
            maxHeight='50%'
            image={
              <Box
                as='img'
                src={oneClickSourcing}
                alt='one_click_sourcing'
                css={{
                  position: 'absolute',
                  maxWidth: '120%',
                  maxHeight: 'inherit',
                }}
              />
            }
          />
          <InfoCard
            title='Automated Follow-ups'
            description='Boost reply rates by staying top of mind with automated follow-ups.'
            css={{ maxWidth: 195, height: 280 }}
            marginTop={24}
            maxHeight='50%'
            image={
              <Box
                as='img'
                src={automatedFollowUps}
                alt='personalized_emails'
                css={{
                  position: 'absolute',
                  maxWidth: '120%',
                  maxHeight: 'inherit',
                }}
              />
            }
          />
          <InfoCard
            title='Complete Transparency'
            description='Have full visibility into your recruiting firms’ sourcing activity and sequences.'
            css={{ maxWidth: 195, height: 280 }}
            marginTop={24}
            maxHeight='50%'
            image={
              <Box
                as='img'
                src={completeTransparency}
                alt='candidate_details'
                css={{
                  position: 'absolute',
                  maxWidth: '120%',
                  maxHeight: 'inherit',
                }}
              />
            }
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

export default PaywallPopupModal;
