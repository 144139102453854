import { FileEntity, StepAttachment } from '@betterleap/client';
import { useState } from 'react';
import { useUploadFile } from '../../../hooks';
import { StepEditorValue } from './StepEditorTypes';

interface UseStepAttachmentProps {
  stateValue?: StepEditorValue;
  setStateValue: (v: StepEditorValue) => void;
  onAttachmentUpload?: (file: FileEntity) => void;
}

export const useStepAttachments = ({
  stateValue,
  setStateValue,
  onAttachmentUpload,
}: UseStepAttachmentProps) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const handleUploadSuccess = (file: FileEntity) => {
    onAttachmentUpload?.(file);

    setStateValue({
      ...stateValue,
      attachments: [
        ...(stateValue?.attachments ?? []),
        { fileId: file.id, file },
      ],
    });
    setIsUploading(false);
  };
  const { uploadFile } = useUploadFile({
    path: 'public/attachment',
    onSuccess: handleUploadSuccess,
  });
  const onInsertAttachmentClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf';
    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        setIsUploading(true);
        uploadFile(file);
      }
    };
    input.click();
  };
  const handleRemoveAttachment = (
    attachmentToRemove: Partial<StepAttachment>,
  ) => {
    setStateValue({
      ...stateValue,
      attachments: stateValue?.attachments?.filter(
        (attachment) => attachment.fileId !== attachmentToRemove.fileId,
      ),
    });
  };

  return { onInsertAttachmentClick, handleRemoveAttachment, isUploading };
};
