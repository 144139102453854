import {
  AlertIcon,
  AlertIconProps,
  Badge,
  BoxProps,
  Button,
  ButtonProps,
  Collapsible,
  CollapsibleContent,
  CollapsibleProps,
  CollapsibleTrigger,
  Divider,
  Flex,
  Icon,
  StyledIcon,
  Text,
  TextSkeleton,
} from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { Link } from 'react-router-dom';
import merge from 'lodash/merge';
import { useQuery } from 'react-query';
import { Suspense } from 'react';

export interface CollapsibleHeaderProps extends ButtonProps {
  title: string;
  icon: AlertIconProps['name'];
}

export const CollapisbleHeader = ({
  css,
  title,
  icon,
  ...props
}: CollapsibleHeaderProps) => {
  return (
    <Button
      full
      variant='headless'
      css={merge(
        {
          expanded: {
            [`& ${StyledIcon}.collapsible-chevron`]: {
              transform: 'rotate(180deg)',
            },
          },
          focus: {
            boxShadow: 'none',
            [`& ${StyledIcon}.collapsible-chevron`]: {
              boxShadow: '$focus',
            },
          },
          p: 24,
          gap: 16,
          fontSize: '$sm',
          justifyContent: 'space-between',
        } as BoxProps['css'],
        css,
      )}
      {...props}
    >
      <Flex css={{ gap: 8 }}>
        <AlertIcon name={icon} variant='gray' shape='rounded-square' />
        <Text size='lg' css={{ fontWeight: '$medium' }}>
          {title}
        </Text>
      </Flex>
      <Icon
        className='collapsible-chevron'
        css={{
          borderRadius: '$lg',
        }}
        name='chevron-down'
      />
    </Button>
  );
};

export const ProjectListLoader = () => {
  return (
    <Flex css={{ gap: 8, px: 24, py: 4 }}>
      <TextSkeleton size='lg' css={{ width: 40, borderRadius: '$sm' }} />
      <TextSkeleton size='lg' css={{ width: 50, borderRadius: '$sm' }} />
      <TextSkeleton size='lg' css={{ width: 40, borderRadius: '$sm' }} />
    </Flex>
  );
};

export const ProjectList = ({ contactId }: { contactId?: string }) => {
  const { data: contactProjectResponse } = useQuery(
    ['get_contact_projects', contactId],
    () => apiClient.contactSearch.searchProject({ requestBody: { contactId } }),
    {
      enabled: !!contactId,
      suspense: true,
    },
  );

  const projects = contactProjectResponse?.data ?? [];

  if ((contactProjectResponse && !projects.length) || !contactId) {
    return (
      <Flex justify='center' css={{ py: 30 }}>
        <Text size='sm' css={{ color: '$text-tertiary' }}>
          Contact not in any projects yet.
        </Text>
      </Flex>
    );
  }

  return (
    <Flex wrap css={{ gap: 8, p: 24, pt: 0 }}>
      {projects.map((project) => {
        return (
          <Link to={`/projects/${project.projectId}`}>
            <Badge
              css={{ cursor: 'pointer', overflow: 'hidden' }}
              key={project.id}
              variant='violet'
              shape='rectangle'
            >
              <Text inherit truncate size='xs'>
                {project.projectName}
              </Text>
            </Badge>
          </Link>
        );
      })}
    </Flex>
  );
};

export interface ContactProjectsProps extends CollapsibleProps {
  contactId?: string;
}

export const ContactProjects = ({ contactId }: ContactProjectsProps) => {
  return (
    <Collapsible defaultOpen={true}>
      <CollapsibleTrigger asChild>
        <CollapisbleHeader title='Projects' icon='collection' />
      </CollapsibleTrigger>
      <CollapsibleContent css={{ height: 100, overflow: 'auto' }}>
        <Suspense fallback={<ProjectListLoader />}>
          <ProjectList contactId={contactId} />
        </Suspense>
      </CollapsibleContent>
      <Divider />
    </Collapsible>
  );
};
