import { InteractiveTable } from '@betterleap/shared';
import { ApiResponse, GroupDto } from '@betterleap/client';
import { Text } from '@betterleap/ui';
import { RenameGroupButton } from './RenameGroupButton';

interface GroupsTableProps {
  groupsResponse: ApiResponse<GroupDto[]> | undefined;
  isLoading: boolean;
  onGroupRename: () => void;
}

export const GroupsTable = ({
  groupsResponse,
  isLoading,
  onGroupRename,
}: GroupsTableProps) => {
  const groupColumns = [
    {
      Header: 'GROUP',
      id: 'name',
      accessor: (group: GroupDto) => (
        <Text css={{ fontSize: '$sm', color: '$neutral-blue-1000' }}>
          {group.name}
        </Text>
      ),
      slot: 'custom',
    },
    {
      Header: 'MEMBERS',
      accessor: 'count',
      headerCss: { maxWidth: 120 },
      css: { maxWidth: 120 },
    },
    {
      id: 'rename-group-action',
      Header: '',
      headerCss: { maxWidth: 120 },
      css: { maxWidth: 120 },
      accessor: (group: GroupDto) => (
        <RenameGroupButton group={group} onGroupRenamed={onGroupRename} />
      ),
      slot: 'custom',
    },
  ];
  const groups = groupsResponse?.data ?? [];
  return (
    <InteractiveTable
      tableData={groups}
      columns={groupColumns}
      loading={isLoading}
      count={groupsResponse?.meta.count ?? 0}
    />
  );
};
