import { Box } from '@betterleap/ui';
import { useEffect, useState } from 'react';

declare interface DocumentMountPointProps {
  documentPath: string;
  onScrollToBottom: (isBottom: boolean) => void;
}

export const DocumentMountPoint = ({
  documentPath,
  onScrollToBottom,
}: DocumentMountPointProps) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch(documentPath)
      .then((response) => response.text())
      .then((data) => {
        setHtmlContent(data);
      });
  }, []);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const t = e.currentTarget;
    const isBottom = t.scrollHeight - t.scrollTop - t.clientHeight < 1;
    if (isBottom) {
      onScrollToBottom(isBottom);
    }
  };

  return (
    <Box
      dangerouslySetInnerHTML={{ __html: htmlContent }}
      css={{
        height: '30vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        px: 12,
        '&::-webkit-scrollbar': {
          width: 5,
        },
        '&::-webkit-scrollbar-track': {
          width: 5,
        },
        '&::-webkit-scrollbar-thumb': {
          background: '$blue-600',
          borderRadius: '$3xl',
          height: 87,
        },
      }}
      onScroll={handleScroll}
    />
  );
};
