import { MANUAL_STEPS } from '@betterleap/shared';
import {
  ExtendedContactProject,
  Project,
  ProjectWithStatsDto,
  Step,
} from '@betterleap/client';
import {
  Badge,
  Box,
  BoxProps,
  CheckboxGroup,
  CheckboxMenuItem,
  Flex,
  FlexProps,
  Icon,
  Text,
} from '@betterleap/ui';
import { formatDistanceTerse } from 'helper/formatDistanceTerse';
import { useSearchParams } from 'react-router-dom';
import { useProjectContactsFilterParams } from 'components/templates/Projects/useProjectContactsFilterParams';
import { useState } from 'react';
import { setArrayUrlParam } from 'helper/setArrayUrlParam';
import { HeaderWithFilter } from './HeaderWithFilter';
import { sendsAtMessage } from './Functions/Column.functions';

const Arrow = ({ css, ...props }: BoxProps) => (
  <Box
    css={{
      display: 'block',
      marginY: 'auto',
      width: 8,
      height: 8,
      borderTop: '2px solid $neutral-blue-400',
      borderLeft: '2px solid $neutral-blue-400',
      transform: 'rotate(135deg)',
      after: {
        content: '',
        display: 'block',
        position: 'absolute',
        width: 2,
        height: 27,
        backgroundColor: '$neutral-blue-400',
        transform: 'rotate(-45deg) translate(9px, 3px)',
        left: 0,
        top: 0,
      },
      ...css,
    }}
    {...props}
  />
);

const StepBadge = ({
  distance,
  children,
  paused,
  pausedReason,
  hasNext,
  css,
  ...props
}: BoxProps & {
  distance: string;
  paused?: boolean;
  hasNext?: boolean;
  pausedReason?: string;
}) => {
  if (paused) {
    return <PausedBadge pausedReason={pausedReason} />;
  }

  return (
    <Box css={{ width: 75, ...css }} {...props}>
      <Badge variant='gray' css={{ mb: 4, position: 'relative' }}>
        {children}
        {hasNext && (
          <Arrow
            css={{ position: 'absolute', right: -27, top: 8, bottom: 0 }}
          />
        )}
      </Badge>
      <Text size='xs' css={{ color: '$text-tertiary' }} noBreak>
        {distance}
      </Text>
    </Box>
  );
};

const FinishedBadge = ({ css, ...props }: FlexProps) => (
  <Flex
    css={{
      gap: 4,
      pb: 20,
      position: 'relative',
      before: {
        content: '',
        position: 'absolute',
        display: 'block',
        boxSizing: 'border-box',
        width: 45,
        height: 2,
        zIndex: 0,
        backgroundColor: '$neutral-blue-400',
        left: -43,
        top: 12,
      },
      ...css,
    }}
    {...props}
  >
    <Icon name='check-circle' color='$green-700' />
    <Text
      size='sm'
      css={{ color: '$text-tertiary', fontStyle: 'italic' }}
      noBreak
    >
      Finished
    </Text>
  </Flex>
);

const PausedBadge = ({
  css,
  pausedReason,
  ...props
}: FlexProps & { pausedReason?: string }) => (
  <Flex
    css={{
      gap: 4,
      pb: 20,
      position: 'relative',
      ...css,
    }}
    {...props}
  >
    <Icon name='pause' color='$warning-base' />
    <div>
      <Text size='sm' css={{ color: '$text-secondary' }} noBreak>
        Paused {}
      </Text>
      {pausedReason && (
        <Text size='xs' css={{ color: '$text-tertiary' }} noBreak>
          {pausedReason}
        </Text>
      )}
    </div>
  </Flex>
);

const verbMap: Record<Step.type, string> = {
  [Step.type.EMAIL]: 'emailed',
  [Step.type.X_DIRECT_MESSAGE]: "DM'd",
  [Step.type.INMAIL]: 'InMailed',
  [Step.type.PHONE_CALL]: 'called',
  [Step.type.SMS]: 'texted',
};

const getPausedReason = ({
  enrichmentMode,
  hasNoEmail,
  isMarkedReplied,
  hasNoSender,
}: {
  enrichmentMode: Project.contactEmailEnrichmentMode;
  hasNoEmail: boolean;
  isMarkedReplied: boolean;
  hasNoSender: boolean;
}) => {
  if (hasNoSender) {
    return 'Sending email removed';
  }

  if (hasNoEmail) {
    return enrichmentMode === Project.contactEmailEnrichmentMode.PERSONAL
      ? 'No personal email'
      : 'No business email';
  }

  if (isMarkedReplied) {
    return 'Replied via Inmail';
  }
};

export const SequenceStatusColumn = ({
  project,
}: {
  project: ProjectWithStatsDto;
}) => ({
  id: 'sequence-status',
  Header: () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { statuses } = useProjectContactsFilterParams();
    const [statusesValue, setStatusValue] = useState<string[]>(
      statuses as string[],
    );

    const cohort = searchParams.get('cohort');

    const handleChange = (values: string[]) => {
      setStatusValue(values);

      setSearchParams(setArrayUrlParam(searchParams, 'status', values));
    };

    return (
      <HeaderWithFilter
        header='sequence status'
        active={statusesValue.length > 0}
      >
        <CheckboxGroup onChange={handleChange} value={statusesValue}>
          {!cohort && (
            <CheckboxMenuItem value='not in sequence'>
              Not in sequence
            </CheckboxMenuItem>
          )}
          <CheckboxMenuItem value='scheduled'>Sends Soon</CheckboxMenuItem>
          <CheckboxMenuItem value='finished'>Finished</CheckboxMenuItem>
          <CheckboxMenuItem value='bounced'>Bounced</CheckboxMenuItem>
        </CheckboxGroup>
      </HeaderWithFilter>
    );
  },
  width: 130,
  accessor: (contact: ExtendedContactProject) => {
    const hasStatus =
      (contact.lastContactedAt || contact.nextContactAt) &&
      contact.status !== ExtendedContactProject.status.DO_NOT_CONTACT;

    if (!hasStatus) {
      return <Text css={{ color: '$neutral-blue-500' }}>--</Text>;
    }

    const stepType = contact.currentStepType as unknown as Step.type;
    const lastStepType = contact.lastStepType as unknown as Step.type;
    const replyType = ExtendedContactProject.externallyContactedStatus.REPLIED;
    const isMarkedReplied = contact.externallyContactedStatus === replyType;
    const hasNoEmail = !contact.selectedEmail && stepType === Step.type.EMAIL;
    const hasNoSender = !!(contact.sequenceId && !contact.senderId);

    const isPaused =
      !contact.isSequenceEnabled ||
      isMarkedReplied ||
      hasNoEmail ||
      hasNoSender ||
      contact.contactSequenceDeactivatedReason ===
        ExtendedContactProject.contactSequenceDeactivatedReason.PAUSED;

    const pausedReason = getPausedReason({
      enrichmentMode: project.contactEmailEnrichmentMode,
      hasNoEmail,
      isMarkedReplied,
      hasNoSender,
    });
    const isManualStep = MANUAL_STEPS.includes(stepType);
    return (
      <Flex css={{ gap: 24 }}>
        {contact.lastContactedAt ? (
          <StepBadge
            hasNext={!!contact.nextContactAt}
            distance={`${verbMap[lastStepType] ?? 'sent'} ${formatDistanceTerse(
              new Date(contact.lastContactedAt),
              new Date(),
              { addSuffix: true },
            )}`}
          >
            Step {contact.completedStepCount}
          </StepBadge>
        ) : null}
        {contact.nextContactAt ? (
          <StepBadge
            paused={isPaused}
            pausedReason={pausedReason}
            distance={sendsAtMessage(contact.nextContactAt, isManualStep)}
          >
            Step {contact.completedStepCount + 1}
          </StepBadge>
        ) : (
          <FinishedBadge />
        )}
      </Flex>
    );
  },
  slot: 'custom',
});
