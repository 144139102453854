import {
  Box,
  BoxProps,
  Col,
  Flex,
  Row,
  Select,
  SelectItem,
  Text,
} from '@betterleap/ui';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import { useState } from 'react';
import { GetStat, GetStatProps, StatCard, StatCardProps } from './StatCard';

const stats: (StatCardProps & { entity: GetStatProps['entity'] })[] = [
  {
    entity: 'sourcing-search',
    title: 'Searches completed',
    icon: 'search',
  },
  {
    entity: 'project',
    title: 'Projects created',
    icon: 'collection',
  },
  {
    entity: 'sequence',
    title: 'Sequences created',
    icon: 'view-list',
  },
  {
    entity: 'ai-text-suggestion',
    title: 'AI assistant prompts created',
    icon: 'sparkles',
  },
  {
    entity: 'contact-project',
    title: 'Contacts added to projects',
    icon: 'users',
  },
  {
    entity: 'contact-message',
    title: 'Contacts messaged',
    icon: 'mail',
  },
  {
    entity: 'contact-email-open',
    title: 'Contacts that opened emails',
    icon: 'mail-open',
  },
  {
    entity: 'contact-reply',
    title: 'Contacts that replied',
    icon: 'reply',
  },
];

export const MonthlyStats = (props: BoxProps) => {
  const lastSixMonths = new Array(6).fill(null).map((_, i) => {
    const date = subMonths(new Date(), i);

    return {
      label: format(date, 'MMMM yyyy'),
      startTime: startOfMonth(date),
      endTime: endOfMonth(date),
    };
  });

  const [selectedMonth, setSelectedMonth] = useState(lastSixMonths[0]);

  return (
    <Box {...props}>
      <Flex justify='between' css={{ mb: 16 }}>
        <Text variant='h6'>
          {selectedMonth?.label === format(new Date(), 'MMMM yyyy')
            ? 'Current Month'
            : selectedMonth?.label}
        </Text>
        <Select
          options={lastSixMonths}
          css={{ width: 200 }}
          onChange={(newValue) => {
            setSelectedMonth(
              lastSixMonths.find((month) => month.label === newValue),
            );
          }}
          value={selectedMonth?.label}
        >
          {({ label }: { label: string }) => (
            <SelectItem key={label} value={label}>
              {label}
            </SelectItem>
          )}
        </Select>
      </Flex>
      <Row>
        {stats.map((stat) => (
          <Col
            span={{
              '@md': 6,
              '@lg': 3,
            }}
          >
            <StatCard key={stat.entity} icon={stat.icon} title={stat.title}>
              <GetStat
                entity={stat.entity}
                startTime={selectedMonth!.startTime}
                endTime={selectedMonth!.endTime!}
              />
            </StatCard>
          </Col>
        ))}
      </Row>
    </Box>
  );
};
