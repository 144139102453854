import {
  AttachmentManager,
  ContactEditor,
  ContactEmailManager,
  ContactPhoneNumberManager,
} from '@betterleap/shared';
import { Contact, ContactEmail, ContactPhoneNumber } from '@betterleap/client';
import {
  BackButton,
  Box,
  Flex,
  Icon,
  showToast,
  Tab,
  TabContent,
  Tabs,
  TabsList,
  Text,
} from '@betterleap/ui';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { createFullName } from 'helper/createFullName';

export interface ContactProfileSummaryProps {
  contact: Contact;
  contactAvatarUrl: string | undefined;
  onContactUpdated: () => void;
  onUpdateError: (message: string) => void;
}

const ContactProfileSummary = (props: ContactProfileSummaryProps) => {
  const { contact, contactAvatarUrl, onContactUpdated, onUpdateError } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as {
    from: string;
  } | null;
  const queryParams = qs.parse(location.search.slice(1));

  const handleBackButton = () => {
    if (state?.from) {
      return navigate({
        pathname: state?.from,
        search: qs.stringify(queryParams.project),
      });
    }
    return navigate(-1);
  };

  return (
    <Flex>
      <Box css={{ width: '100%' }}>
        {location.key !== 'default' && (
          <BackButton route='Back' onClick={handleBackButton} />
        )}
        {contactAvatarUrl && (
          <Box
            as='img'
            css={{ mb: 12, width: 80, height: 80, borderRadius: 32 }}
            src={contactAvatarUrl}
            alt='contact_avatar'
          />
        )}
        <Text as='h1' css={{ fontWeight: '$medium', fontSize: '$2xl' }}>
          {createFullName(contact.firstName, contact.lastName)}
        </Text>
        <Flex css={{ mb: '2rem' }}>
          <Text css={{ fontSize: '$sm' }}>
            {contact.currentTitle && (
              <Text
                css={{
                  mr: 4,
                  color: '$gray-500',
                  fontSize: '$sm',
                  display: 'inline',
                }}
              >
                {contact.currentTitle}
              </Text>
            )}
            {contact.currentCompany ? `at ${contact.currentCompany}` : ''}
          </Text>
        </Flex>
        {contact.linkedinUrl && (
          <Flex css={{ mb: '1rem' }}>
            <Icon size={16} name='link' css={{ mr: '1rem' }} />
            <a href={contact.linkedinUrl} target='_blank' rel='noreferrer'>
              <Text css={{ color: '$blue-600', fontSize: '$sm' }}>
                LinkedIn
              </Text>
            </a>
          </Flex>
        )}
        <ContactEditor
          contact={contact}
          onContactUpdated={onContactUpdated}
          onUpdateError={onUpdateError}
        />
        <Tabs tabStyle='underline' defaultValue={ContactEmail.type.PERSONAL}>
          <TabsList css={{ mb: 16 }}>
            <Tab value={ContactEmail.type.PERSONAL}>Personal</Tab>
            <Tab value={ContactEmail.type.WORK}>Business</Tab>
          </TabsList>
          <TabContent value={ContactEmail.type.PERSONAL}>
            <ContactEmailManager
              contactId={contact.id}
              onContactUpdated={onContactUpdated}
              emailType={ContactEmail.type.PERSONAL}
              contactPrimaryEmail={contact.primaryEmail}
              contactLinkedinUrl={contact.linkedinUrl}
              onEmailCopied={() => {
                showToast({
                  title: 'Email copied!',
                  description: 'Email copied to your clipboard.',
                });
              }}
            />
            <ContactPhoneNumberManager
              contactId={contact.id}
              onContactUpdated={onContactUpdated}
              phoneNumberType={ContactEmail.type.PERSONAL}
              contactPrimaryPhoneNumber={contact.phoneNumber}
              contactLinkedinUrl={contact.linkedinUrl}
              onPhoneNumberCopied={() => {
                showToast({
                  title: 'Phone number copied!',
                  description: 'Phone number copied to your clipboard.',
                });
              }}
            />
          </TabContent>
          <TabContent value={ContactEmail.type.WORK}>
            <ContactEmailManager
              contactId={contact.id}
              onContactUpdated={onContactUpdated}
              emailType={ContactEmail.type.WORK}
              contactPrimaryEmail={contact.primaryBusinessEmail}
              contactLinkedinUrl={contact.linkedinUrl}
              onEmailCopied={() => {
                showToast({
                  title: 'Email copied!',
                  description: 'Email copied to your clipboard.',
                });
              }}
            />
            <ContactPhoneNumberManager
              contactId={contact.id}
              onContactUpdated={onContactUpdated}
              phoneNumberType={ContactPhoneNumber.type.WORK}
              contactPrimaryPhoneNumber={contact.primaryBusinessPhoneNumber}
              contactLinkedinUrl={contact.linkedinUrl}
              onPhoneNumberCopied={() => {
                showToast({
                  title: 'Phone number copied!',
                  description: 'Phone number copied to your clipboard.',
                });
              }}
            />
          </TabContent>
        </Tabs>
        <AttachmentManager contactId={contact.id} />
      </Box>
    </Flex>
  );
};

export default ContactProfileSummary;
