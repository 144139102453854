import { Step } from '@betterleap/client';
import { EmailStepEditor, EmailStepEditorProps } from './EmailStepEditor';
import { ManualTaskStepEditor } from './ManualTaskStepEditor';
import { PhoneCallStepEditor } from './PhoneCallStepEditor';

export type StepEditorProps = EmailStepEditorProps;

export const StepEditor = ({
  sender,
  type,
  onSendTestEmail,
  onAttachmentUpload,
  signature,
  useDefaultSignature,
  ...props
}: StepEditorProps) => {
  switch (type) {
    case Step.type.EMAIL:
      return (
        <EmailStepEditor
          type={type}
          sender={sender}
          onSendTestEmail={onSendTestEmail}
          signature={signature}
          useDefaultSignature={useDefaultSignature}
          onAttachmentUpload={onAttachmentUpload}
          {...props}
        />
      );
    case Step.type.PHONE_CALL:
      return <PhoneCallStepEditor type={type} {...props} />;
    default:
      return <ManualTaskStepEditor type={type} {...props} />;
  }
};

export * from './StepValidation';
