import React, { forwardRef } from 'react';
import { Flex, FlexProps } from '@betterleap/ui';

export const Card = forwardRef<HTMLDivElement, FlexProps>(
  ({ css, ...rest }, ref) => (
    <Flex
      ref={ref}
      css={{
        position: 'relative',
        border: '1px solid $neutral-blue-200',
        boxShadow: '$sm',
        borderRadius: '$3xl',
        p: 24,
        flex: 1,
        backgroundColor: 'white',
        ...css,
      }}
      {...rest}
    />
  ),
);

Card.displayName = 'Card';
