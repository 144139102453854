import { Sender } from '@betterleap/client';
import { useModal } from '@betterleap/ui';
import {
  ConnectEmailModal,
  ConnectEmailModalProps,
} from 'components/modules/Modal/ConnectEmailModal/ConnectEmailModal';
import config from 'lib/config';

export const useConnectEmail = () => {
  const openConnectEmailModal = useModal<
    ConnectEmailModalProps,
    Sender.provider
  >(ConnectEmailModal);
  // prettier-ignore
  const handleConnectEmailClick = (provider: Sender.provider) => {
    const url = `${config.endpoints.api}/sender/oauth2url/redirect?provider=${provider}`;
    const features = 'popup toolbar=no, menubar=no, width=400, height=700, top=200, left=500';
    const windowRef = window.open(url, '_blank', features);
    windowRef?.focus();
  };
  const handleConnectEmail = async (provider?: Sender.provider) => {
    const emailProvider = await openConnectEmailModal({ provider });
    if (emailProvider) {
      handleConnectEmailClick(emailProvider);
    }
  };
  return handleConnectEmail;
};
