import {
  EventSourceMessage,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { Dispatch, SetStateAction, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { TOKEN } from '../lib';

/* eslint-disable no-console */

interface CreateCompletionProps {
  userId: string | undefined;
  apiUrl: string;
  setResult: Dispatch<
    SetStateAction<{
      requestId: string;
      text: string;
    }>
  >;
  setIsLoading?: (value: boolean) => void;
  controller?: React.MutableRefObject<AbortController>;
}

const useCreateCompletion = ({
  userId,
  apiUrl,
  setResult,
  setIsLoading,
  controller: customController,
}: CreateCompletionProps) => {
  const defaultController = useRef(new AbortController());
  const controller = customController ?? defaultController;
  const abortController = () => {
    controller.current.abort();
    controller.current = new AbortController();
  };
  const receiveMessage = (msg: EventSourceMessage, requestId: string) => {
    console.debug('session message', msg);

    if (!msg.data) {
      return;
    }

    const responseContent = msg.data.replace(/\[NEWLINE\]/g, '\n');

    if (responseContent === 'close') {
      console.debug('request complete, closing connection');
      abortController();
    } else {
      console.debug('session writing to result', msg);

      setResult((prevResult) => {
        if (prevResult.requestId === requestId && responseContent) {
          return {
            ...prevResult,
            text: (prevResult.text + responseContent).trimStart(),
          };
        }
        console.debug(
          'Skipping setting text for message',
          prevResult.requestId,
          requestId,
          msg,
        );
        return prevResult;
      });
    }
  };

  const onSubmit = (textPrompt: string) => {
    const requestId = uuid();
    console.debug('session text prompt', textPrompt);
    if (textPrompt) {
      setResult({ requestId, text: '' });

      // clear out any previous connections.
      abortController();

      setIsLoading?.(true);
      fetchEventSource(
        `${apiUrl}/ai-text-suggestion?userId=${userId}&sessionId=${requestId}&prompt=${encodeURIComponent(
          textPrompt,
        )}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN.get()}`,
          },
          onmessage(msg) {
            receiveMessage(msg, requestId);
          },
          onclose: () => {
            console.debug('session closed');
          },
          onerror: (err) => {
            setIsLoading?.(false);
            console.debug('session err', err);
          },
          onopen: async (arg) => {
            setIsLoading?.(false);
            console.debug('session open', arg);
          },
          openWhenHidden: true,
          signal: controller.current.signal,
        },
      );
    }
  };

  return onSubmit;
};

export default useCreateCompletion;
