import { extensionUrl } from 'constants/externalUrls';
import { Box, Col, Flex, Link, Text } from '@betterleap/ui';
import sharedProjectImage from '../../../assets/images/shared-project-image.svg';

export const PromotionColumn = () => (
  <Col
    span={{
      '@initial': 12,
      '@lg': 6,
    }}
    css={{
      display: 'flex',
      alignItems: 'stretch',
      height: 'auto',
      flexDirection: 'column',
      p: '30px',
      '@lg': {
        height: '100vh',
        p: '80px',
      },
    }}
  >
    <Box
      css={{
        background: '$neutral-blue-50',
        flex: 1,
        p: '50px',
        borderRadius: '40px',
        border: '1px solid $neutral-blue-400',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        overflow: 'hidden',
      }}
    >
      <Box>
        <Text size='2xl' css={{ fontWeight: '500' }}>
          AI-powered recruiting platform
        </Text>
        <Text
          size='sm'
          css={{
            mt: '10px',
            color: '$text-secondary',
          }}
        >
          Monthly and annual plans available.
        </Text>

        <ul
          style={{
            listStyleType: 'disc',
            marginLeft: '25px',
            marginTop: '20px',
            fontSize: '14px',
          }}
        >
          <Link
            href={extensionUrl}
            target='_blank'
            css={{
              color: '$purple-700',
            }}
          >
            <li>Chrome extension</li>
          </Link>

          {benefitsList.map((item) => (
            <li
              style={{
                marginTop: '5px',
              }}
            >
              {item}
            </li>
          ))}
        </ul>
      </Box>
      <Flex css={{ width: '100%', flex: 1 }}>
        <img
          style={{
            marginTop: '20px',
            height: 'auto',
            maxHeight: '350px',
            width: '100%',
            objectFit: 'contain',
          }}
          src={sharedProjectImage}
          alt='shared_project'
        />
      </Flex>
    </Box>
  </Col>
);

const benefitsList = [
  'Email sequences',
  'Multi-channel sequences',
  'Contact info finding',
  'Sourcing from our database',
  'AI recommended matches',
  'Natural language search',
];
