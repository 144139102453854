import { Col, Row, Text } from '@betterleap/ui';
import { TeamStats } from './TeamStats';
import { OutreachStats } from './OutreachStats';
import { WelcomeTextHeader } from './WelcomeTextHeader';
import { OutreachChartCard } from './OutreachChartCard';
import { TalentPoolChartCard } from './TalentPoolChartCard';
import { MonthlyStats } from './MonthlyStats';

const ReportingTemplate = () => {
  return (
    <div className='mobile:p-0 tablet:p-4 desktop:p-8'>
      <WelcomeTextHeader />
      <MonthlyStats css={{ mb: 40 }} />
      <Text variant='h6' css={{ mb: 16 }}>
        All Time
      </Text>
      <Row css={{ gap: 20 }}>
        <Col
          span={{
            '@initial': 12,
            '@md': 6,
          }}
        >
          <OutreachChartCard />
        </Col>
        <Col
          span={{
            '@initial': 12,
            '@md': 6,
          }}
        >
          <TalentPoolChartCard />
        </Col>
      </Row>
      <TeamStats />
      <OutreachStats />
    </div>
  );
};

export default ReportingTemplate;
