import {
  ExtendedContactProject,
  ProjectSuggestedContactDto,
} from '@betterleap/client';
import {
  drawerCollectionState,
  sharedProjectIdState,
} from 'components/modules/Drawer/ContactDrawer/ContactDrawer';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

interface Props {
  activeTab: string;
  projectContacts?: ExtendedContactProject[];
  projectSuggestedContacts?: ProjectSuggestedContactDto[];
  sharedProjectId?: string;
}

export const useProjectDrawerCollection = ({
  activeTab,
  projectContacts,
  projectSuggestedContacts,
  sharedProjectId,
}: Props) => {
  const setDrawerCollection = useSetAtom(drawerCollectionState);
  const setSharedProjectId = useSetAtom(sharedProjectIdState);

  useEffect(() => {
    setSharedProjectId(sharedProjectId);
  }, [sharedProjectId]);

  useEffect(() => {
    if (activeTab === 'contacts' && projectContacts) {
      setDrawerCollection(
        projectContacts.map((contact) => ({
          contactId: contact.id,
          linkedinUrl: contact.linkedinUrl,
        })),
      );
    } else if (
      activeTab === 'recommended-matches' &&
      projectSuggestedContacts
    ) {
      setDrawerCollection(
        projectSuggestedContacts.map((contact) => ({
          contactId: contact.id,
          ...contact.rawData,
        })),
      );
    }
  }, [activeTab, projectContacts, projectSuggestedContacts]);
};
