import { apiClient } from 'lib/apiClient';
import { useQuery } from 'react-query';
import { createLocalFile } from 'functions/createLocalFile';
import { Box, Button, Flex, showToast } from '@betterleap/ui';
import { FileAttachment } from '@betterleap/shared';
import Line from 'components/elements/Line/Line';
import { Settings } from '../Settings.elements';
import { IntegrationSetting } from '../Connections/Integration.elements';
import { useUploadDnc } from '../useUploadDnc';

interface Props {
  controller: 'doNotContactDomain' | 'restrictedDomain';
  filename: string;
  settingTitle: string;
  settingSubtitle: string;
  successToastTitle: string;
  successToastDescription: string;
  removalToastTitle: string;
  analyticsButtonName: string;
}

export const DomainExclusionListSetting = ({
  controller,
  filename,
  settingTitle,
  settingSubtitle,
  successToastTitle,
  successToastDescription,
  removalToastTitle,
  analyticsButtonName,
}: Props) => {
  const { data: domainResponse, refetch: refetchDomains } = useQuery(
    [filename],
    () => apiClient[controller].getDomains(),
  );

  const uploadFile = useUploadDnc({
    controller,
    onUpload: refetchDomains,
    successToastTitle,
    successToastDescription,
    analyticsButtonName,
  });
  const downloadFile = () => {
    const domains = domainResponse?.data || [];
    const csv = `Company Domain\n${domains.join('\n')}`;
    createLocalFile(filename, csv);
  };
  const deleteFile = () => {
    refetchDomains();
    showToast({
      variant: 'success',
      title: removalToastTitle,
    });
  };

  return (
    <>
      <Settings.Section.Row>
        <Box>
          <IntegrationSetting.Title>{settingTitle}</IntegrationSetting.Title>
          <IntegrationSetting.Subtitle>
            {settingSubtitle}
          </IntegrationSetting.Subtitle>
          {!!domainResponse?.data.length && (
            <Flex css={{ mt: 12 }}>
              <FileAttachment
                attachment={{ name: filename }}
                onOpenClick={downloadFile}
                onDeleteClick={() =>
                  apiClient[controller].replaceDomains({
                    requestBody: { domains: [] },
                  })
                }
                afterDelete={deleteFile}
              />
            </Flex>
          )}
        </Box>
        <Button
          onClick={uploadFile}
          css={{ color: '$blue-600' }}
          variant='headless'
        >
          Upload List
        </Button>
      </Settings.Section.Row>
      <Line />
    </>
  );
};
