import { Types, useUploadFile } from '@betterleap/shared';
import {
  ApiError,
  EmailFinderSignupDto,
  FirebaseUserSignupDto,
} from '@betterleap/client';
import {
  Badge,
  Box,
  BoxProps,
  Button,
  Flex,
  Icon,
  IconButton,
  ProgressBar,
  showToast,
  Spinner,
  Text,
  useModal,
} from '@betterleap/ui';
import { UploadArea, UploadAreaProps } from 'components/elements/UploadArea';
import {
  SignupModal,
  SignupModalProps,
} from 'components/modules/Modal/SignupModal/SignupModal';
import { useIncrement } from 'hooks/useIncrement';
import { useEffect, useRef, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { apiClient } from 'lib/apiClient';
import { getCookie } from 'lib/cookies';
import useAnalytics from 'hooks/analytics';

const CSV_URL_KEY = 'csvFilename';

interface UploadBarProps extends BoxProps {
  progress: number;
  filename: string;
  onRemove: () => void;
}

interface FirebaseProfile {
  givenName?: string;
  surname?: string;
  family_name?: string;
  given_name?: string;
}

export const UploadBar = ({
  progress,
  filename,
  onRemove,
  css,
  ...rest
}: UploadBarProps) => {
  const incrementingProgress = useIncrement(progress, 5);
  const isUploading = incrementingProgress < 100;
  return (
    <Box
      css={{
        px: 18,
        py: 16,
        borderRadius: 20,
        boxShadow: '0px 0px 0px 22px #f5f3ff',
        border: '1px solid $neutral-blue-300',
        ...css,
      }}
      {...rest}
    >
      <Flex justify='between'>
        <Flex css={{ gap: 8 }}>
          {isUploading ? (
            <Text size='xs' css={{ color: '$text-secondary' }}>
              Uploading
            </Text>
          ) : (
            <Icon name='attachment' color='$neutral-blue-800' size={18} />
          )}
          <Badge variant='gray' size='xs' shape='rectangle'>
            {filename}
          </Badge>
        </Flex>
        <div>
          {isUploading ? (
            <Button
              variant='headless'
              size='xs'
              onClick={onRemove}
              css={{ color: '$danger-base', fontSize: '$xs', p: 4, gap: 4 }}
            >
              <Icon name='x-circle' color='$danger-base' size={14} /> Cancel
            </Button>
          ) : (
            <IconButton
              name='trash'
              size='xs'
              variant='danger'
              onClick={onRemove}
              label='Remove Upload'
            />
          )}
        </div>
      </Flex>
      {isUploading && (
        <ProgressBar
          css={{ mt: 18, '& > div': { transition: 'unset' } }}
          value={incrementingProgress}
        />
      )}
    </Box>
  );
};

export const CsvUploader = ({
  css,
  uploadBarCss,
  ...props
}: UploadAreaProps & { uploadBarCss?: BoxProps['css'] }) => {
  const hasFetchedRedirectResult = useRef(false);
  const { track } = useAnalytics();
  const [filename, setFilename] = useState<string>();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [firebaseFilename, setFirebaseFilename] = useState<
    string | undefined
  >();
  const [progress, setProgress] = useState<number>(0);
  const openSignupModal = useModal<SignupModalProps>(SignupModal);

  const signUp = useMutation(
    (data: EmailFinderSignupDto) =>
      apiClient.signup.emailFinderSignup({ requestBody: data }),
    {
      onSuccess: () => {
        setSuccess(true);
        showToast({
          variant: 'success',
          title: 'Success!',
          description: 'Your .CSV was uploaded successfully.',
        });
        handleRemoveUpload();
      },
      onError: (err: ApiError) => {
        handleRemoveUpload();

        if (
          err?.body?.error?.data?.message ===
          'Please use your work email to sign up.'
        ) {
          showToast({
            variant: 'danger',
            title: 'Sign up failed!',
            description: 'Please use your work email to sign up.',
          });
        } else {
          showToast({
            variant: 'danger',
            title: 'Sign up failed!',
            description: 'Something went wrong, please try again!',
          });
        }
      },
    },
  );

  useEffect(() => {
    const csvFilename = searchParams.get(CSV_URL_KEY);
    firebase
      .auth()
      .getRedirectResult()
      .then((result: firebase.auth.UserCredential) => {
        onAuthenticate(result, csvFilename);
      });
  }, [signUp]);

  const onAuthenticate = (
    result: firebase.auth.UserCredential,
    csvFilename?: string | null,
  ) => {
    if (csvFilename && !hasFetchedRedirectResult.current) {
      setIsLoading(true);
    }

    try {
      const { user, additionalUserInfo, credential } = result;

      if (user && csvFilename) {
        const isMicrosoft = credential?.signInMethod === 'microsoft.com';
        const profile = additionalUserInfo?.profile as FirebaseProfile;

        signUp.mutate({
          user: {
            firebaseId: user.uid,
            email: user.email as string,
            firstName: isMicrosoft ? profile?.givenName : profile?.given_name,
            lastName: isMicrosoft ? profile?.surname : profile?.family_name,
            role: FirebaseUserSignupDto.role.NONE,
            referrer:
              searchParams.get('source') ||
              getCookie('source') ||
              document.referrer,
          },
          filename: csvFilename,
        });
      }
    } catch (e) {
      setIsLoading(false);
      handleRemoveUpload();
      showToast({
        variant: 'danger',
        title: 'Sign up failed!',
        description: 'Something went wrong, please try again!',
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
      hasFetchedRedirectResult.current = true;
    }
  };

  const { uploadFileToFirebase } = useUploadFile({
    basePath: '',
    path: 'email-finder',
    onProgress: (prog) => {
      setProgress(prog);
    },
  });

  const handleFileUpload = async (acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0];
      setFilename(file.name);
      const result = await uploadFileToFirebase(file, {
        getDownloadURL: false,
      });

      track(Types.ANALYTICS_CLIENT_EVENT.EMAIL_FINDER_CSV_UPLOAD, {
        filename: result.name,
      });

      setFirebaseFilename(result.name);
    }
  };

  const handleRemoveUpload = () => {
    setFilename(undefined);
    setFirebaseFilename(undefined);
    searchParams.delete(CSV_URL_KEY);
    setSearchParams(searchParams);
    setProgress(0);
  };

  const handleSubmit = () => {
    openSignupModal({
      csvFilename: firebaseFilename,
      onAuthenticate,
    });
  };

  if (isLoading || signUp.isLoading) {
    return (
      <Flex justify='center'>
        <Spinner css={{ width: 36, height: 36 }} />
      </Flex>
    );
  }

  if (success) {
    return (
      <Text
        css={{
          fontWeight: '$medium',
          color: '$purple-600',
          fontSize: 20,
          lineHeight: '28px',
          ml: -20,
        }}
      >
        Thank you! Your .CSV was uploaded successfully and a member of our team
        will reach out shortly.
      </Text>
    );
  }

  if (filename) {
    return (
      <>
        <UploadBar
          css={uploadBarCss}
          progress={progress}
          filename={filename as string}
          onRemove={handleRemoveUpload}
        />
        {!!firebaseFilename && (
          <Flex
            css={{
              gap: 16,
              mt: 40,
              flexDirection: 'column',
              alignItems: 'flex-start',
              mx: -22,
              mediaLg: { flexDirection: 'row', alignItems: 'center', mx: 0 },
            }}
          >
            <Button
              onClick={handleSubmit}
              css={{
                height: 54,
                px: 16,
                py: 16,
                borderRadius: 1000,
                fontSize: 16,
                minWidth: 190,
                mediaLg: {
                  fontSize: 18,
                  minWidth: 215,
                },
              }}
            >
              Sign up to submit
            </Button>
            <Text
              css={{
                fontSize: '$xs',
                color: '$text-tertiary',
                whiteSpace: 'nowrap',
              }}
            >
              By signing up you agree to our
              <br />
              Terms of Service and Privacy Policy
            </Text>
          </Flex>
        )}
      </>
    );
  }

  return (
    <UploadArea
      css={{
        borderRadius: 20,
        boxShadow: '0px 0px 0px 22px #f5f3ff',
        ...css,
      }}
      multiple={false}
      maxSize={10000000}
      showIcon={false}
      onChange={handleFileUpload}
      maxFiles={1}
      accept='.csv'
      description='CSV files up to 10MB with a "linkedinUrl" column'
      {...props}
    />
  );
};
