import { PastRecruiterClientDto, RecruiterDto } from '@betterleap/client';
import { Box, Flex, useModal } from '@betterleap/ui';
import { v4 as uuid } from 'uuid';
import {
  AddButton,
  AddFirstButton,
  CardSection,
  SectionTitle,
  SectionTitleArea,
} from '../RecruiterProfile.elements';
import {
  AddClientModal,
  AddClientModalProps,
  AddClientResult,
} from './AddClientModal';
import { RecruiterProfileClient } from './RecruiterProfileClient';

interface ProfileClientsProps {
  clients: PastRecruiterClientDto[];
  onUpdate?: (dto: RecruiterDto) => void;
}

export const RecruiterProfileClients = (props: ProfileClientsProps) => {
  const { clients, onUpdate } = props;
  const openModal = useModal<AddClientModalProps, AddClientResult>(
    AddClientModal,
  );
  const onAddClick = async () => {
    const result = await openModal();
    if (!result) {
      return;
    }
    onUpdate?.({
      pastRecruiterClients: [
        ...clients,
        {
          id: uuid(),
          companyName: result.name,
          isBetterleapClient: false,
        },
      ],
    });
  };
  const onEditClick = async (client?: PastRecruiterClientDto) => {
    const result = await openModal({ client });
    const pastRecruiterClients = [...clients];
    const index = pastRecruiterClients.findIndex((c) => c.id === client?.id);
    if (!result || index < 0) {
      return;
    }
    if (result.delete) {
      pastRecruiterClients.splice(index, 1);
    } else {
      pastRecruiterClients[index] = {
        ...pastRecruiterClients[index],
        companyName: result.name,
        isBetterleapClient: false,
      };
    }
    onUpdate?.({ pastRecruiterClients });
  };
  return (
    <CardSection>
      <SectionTitleArea>
        <SectionTitle title='Clients' icon='sparkles' />
        {!!clients.length && !!onUpdate && (
          <AddButton text='Add client' onClick={onAddClick} />
        )}
      </SectionTitleArea>
      {!clients.length && onUpdate ? (
        <AddFirstButton text='Add your first client' onClick={onAddClick} />
      ) : (
        <Flex
          css={{
            width: '100%',
            flexDirection: 'row',
            gap: 16,
            flexWrap: 'wrap',
          }}
        >
          {!clients.length ? (
            <Box>This recruiter has not yet entered any clients.</Box>
          ) : (
            clients.map((client) => (
              <RecruiterProfileClient
                key={client.id}
                client={client}
                onEditClick={onUpdate ? onEditClick : undefined}
              />
            ))
          )}
        </Flex>
      )}
    </CardSection>
  );
};
