import {
  AtsProfileLink,
  getFullName,
  LoadingArea,
  useMe,
} from '@betterleap/shared';
import {
  ApiResponse,
  Contact,
  ContactPhoneNumber,
  ContactSearchResultDto,
  Project,
} from '@betterleap/client';
import {
  Box,
  BoxProps,
  Flex,
  Icon,
  IconButton,
  Label,
  Text,
  TextSkeleton,
  Tooltip,
  useModal,
} from '@betterleap/ui';
import { useAtomValue } from 'jotai';
import { apiClient } from 'lib/apiClient';
import { useMemo } from 'react';
import { QueryClient, QueryKey, useQuery, useQueryClient } from 'react-query';
import EditContactModal, {
  EditContactModalProps,
} from 'components/modules/Modal/EditContactModal/EditContactModal';
import qs from 'qs';
import EditContactPhonesModal from 'components/modules/Modal/EditContactModal/EditContactPhonesModal';
import EditContactEmailsModal from 'components/modules/Modal/EditContactModal/EditContactEmailsModal';
import { useLocation } from 'react-router-dom';
import { subject } from '@betterleap/authz';
import { sourcedContactIdState } from './ContactDrawer';
import ContactAttachment from './ContactAttachment';

interface ContactDetailsSectionProps extends BoxProps {
  contact: Partial<Contact>;
  project?: Project;
  isContactLoading: boolean;
}

export const usePollContact = (contact: Partial<Contact>) => {
  const sourcedContactId = useAtomValue(sourcedContactIdState);

  return () => {
    if (!contact.id || sourcedContactId !== contact.id) {
      return false;
    }

    return !contact?.primaryEmail && !contact?.primaryBusinessEmail
      ? 1000
      : false;
  };
};

const Socials = ({ contact }: { contact: Partial<Contact> }) => {
  return (
    <Flex css={{ gap: 16 }}>
      {contact.linkedinUrl && (
        <a href={contact.linkedinUrl} target='_blank'>
          <Icon name='linked-in' css={{ fill: '#0A66C2' }} />
        </a>
      )}
      {contact.githubUrl && (
        <a href={contact.githubUrl} target='_blank'>
          <Icon name='github' css={{ fill: '#000000' }} />
        </a>
      )}
      {contact.twitterUrl && (
        <a href={contact.twitterUrl} target='_blank'>
          <Icon name='x-logo' css={{ fill: '#000000' }} />
        </a>
      )}
      {contact.facebookUrl && (
        <a href={contact.facebookUrl} target='_blank'>
          <Icon name='facebook' css={{ fill: '#0866FF' }} />
        </a>
      )}
    </Flex>
  );
};

const Location = ({
  contact,
  isLoading,
}: {
  contact: Partial<Contact>;
  isLoading?: boolean;
}) => {
  const location = contact.locations?.[0]?.split(',')?.[0];

  if (isLoading) {
    return <TextSkeleton size='xs' css={{ width: 130 }} />;
  }

  if (!location) {
    return null;
  }

  return (
    <Flex css={{ gap: 8 }}>
      <Icon name='location-marker' color='$neutral-blue-700' size={16} />
      <Text size='sm' noBreak>
        {location}
      </Text>
    </Flex>
  );
};

const EmailOrPhone = ({
  primary,
  other,
  type,
  onEdit,
  canEdit,
}: {
  primary?: string;
  other?: string[];
  type: 'email' | 'phone';
  onEdit: () => void;
  canEdit?: boolean;
}) => {
  const label =
    type === 'email' ? 'Edit contact emails' : 'Edit contact phones';
  const id = type === 'email' ? 'edit-contact-emails' : 'edit-contact-phones';

  return (
    <Flex css={{ gap: 8 }}>
      <Tooltip content={label} disabled={!primary}>
        <IconButton
          id={id}
          onClick={onEdit}
          disabled={!canEdit}
          label={label}
          name={type === 'email' ? 'mail' : 'phone'}
          size='xs'
          css={{
            fill: '$blue-600',
            disabled: {
              fill: '$neutral-blue-700',
              backgroundColor: '$background-component',
            },
          }}
        />
      </Tooltip>
      {primary ? (
        <Text size='sm' noBreak>
          {primary}
        </Text>
      ) : (
        <Label
          htmlFor={id}
          css={{
            color: '$blue-600',
            hover: {
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          }}
        >
          Add {type === 'email' ? 'email' : 'phone'}
        </Label>
      )}
      {!!other?.length && (
        <Tooltip
          css={{ userSelect: 'text' }}
          content={
            <>
              {other.map((item) => (
                <Text inherit>{item}</Text>
              ))}
            </>
          }
        >
          <Text size='xs' css={{ color: '$blue-600', cursor: 'pointer' }}>
            + {other?.length}
          </Text>
        </Tooltip>
      )}
    </Flex>
  );
};

const Phones = ({
  contact,
  isContactLoading,
  enrichmentMode,
  onEdit,
  canEdit,
}: {
  contact: Partial<Contact>;
  isContactLoading: boolean;
  enrichmentMode: Project.contactEmailEnrichmentMode;
  onEdit: () => void;
  canEdit?: boolean;
}) => {
  const poll = usePollContact(contact);
  const { data: contactPhoneNumbersResponse, isLoading } = useQuery(
    ['get_contact_phone_numbers', contact?.id],
    () =>
      apiClient.contactPhoneNumber.find({
        contactId: contact?.id as string,
      }),
    {
      enabled: !!contact?.id,
      refetchInterval: poll,
    },
  );

  const phoneNumbers = contactPhoneNumbersResponse?.data ?? [];

  const primaryPersonalPhone = contact.phoneNumber;
  const primaryBusinessPhoneNumber = contact.primaryBusinessPhoneNumber;

  const contactPhones = useMemo(() => {
    return {
      primaryPhone: primaryPersonalPhone,
      primaryBusinessPhoneNumber,
      personalPhones: phoneNumbers
        .filter(
          (phone) =>
            phone.type === ContactPhoneNumber.type.PERSONAL &&
            phone.phoneNumber !== primaryPersonalPhone,
        )
        .map((phone) => phone.phoneNumber),
      businessPhones: phoneNumbers
        .filter(
          (phone) =>
            phone.type === ContactPhoneNumber.type.WORK &&
            phone.phoneNumber !== primaryBusinessPhoneNumber,
        )
        .map((phone) => phone.phoneNumber),
    };
  }, [
    contactPhoneNumbersResponse,
    primaryPersonalPhone,
    primaryBusinessPhoneNumber,
  ]);

  if (isContactLoading || isLoading) {
    return <TextSkeleton size='xs' css={{ width: 130 }} />;
  }

  if (!contact?.id) {
    return null;
  }

  const primaryPhone =
    enrichmentMode === Project.contactEmailEnrichmentMode.PERSONAL
      ? contactPhones.primaryPhone
      : contactPhones.primaryBusinessPhoneNumber;

  const otherPhones =
    enrichmentMode === Project.contactEmailEnrichmentMode.PERSONAL
      ? contactPhones.personalPhones
      : contactPhones.businessPhones;

  if (!primaryPhone && !canEdit) {
    return null;
  }

  return (
    <Flex css={{ gap: 16 }}>
      <EmailOrPhone
        onEdit={onEdit}
        canEdit={canEdit}
        primary={primaryPhone}
        other={otherPhones}
        type='phone'
      />
    </Flex>
  );
};

const Emails = ({
  contact,
  isContactLoading,
  enrichmentMode,
  onEdit,
  canEdit,
}: {
  contact: Partial<Contact>;
  isContactLoading: boolean;
  enrichmentMode: Project.contactEmailEnrichmentMode;
  onEdit: () => void;
  canEdit?: boolean;
}) => {
  const poll = usePollContact(contact);
  const { data: contactEmailsResponse, isLoading } = useQuery(
    ['get_contact_emails', contact?.id],
    () =>
      apiClient.contactEmail.getContactEmails({
        contactId: contact?.id as string,
      }),
    {
      enabled: !!contact.id,
      refetchInterval: poll,
    },
  );

  const emails = contactEmailsResponse?.data ?? [];

  const contactEmails = emails.sort((a, b) => {
    // sort by primary email first, then alphabetically
    if (enrichmentMode === Project.contactEmailEnrichmentMode.PROFESSIONAL) {
      if (contact.primaryBusinessEmail === a.email) return -1;
      if (contact.primaryBusinessEmail === b.email) return 1;
    } else {
      if (contact.primaryEmail === a.email) return -1;
      if (contact.primaryEmail === b.email) return 1;
    }
    return a.email.localeCompare(b.email);
  });

  if (isContactLoading || isLoading) {
    return <TextSkeleton size='xs' css={{ width: 165 }} />;
  }

  if (!contact?.id) {
    return null;
  }

  const primaryEmail = contactEmails[0]?.email;
  const otherEmails = contactEmails.slice(1).map((email) => email.email);

  if (!primaryEmail && !canEdit) {
    return null;
  }

  return (
    <Flex css={{ gap: 16 }}>
      <EmailOrPhone
        onEdit={onEdit}
        canEdit={canEdit}
        primary={primaryEmail}
        other={otherEmails}
        type='email'
      />
    </Flex>
  );
};

const updateSearchContactCache = (
  client: QueryClient,
  queryKey: QueryKey,
  updatedContact: Contact,
) => {
  client.setQueryData<ApiResponse<ContactSearchResultDto[]> | undefined>(
    queryKey,
    (oldData) => {
      if (!oldData) return;

      return {
        ...oldData,
        data: oldData.data.map((contact) => {
          if (contact.contactId === updatedContact.id) {
            return {
              ...contact,
              ...updatedContact,
              jobTitle: updatedContact.currentTitle ?? contact.jobTitle,
              currentCompanyName:
                updatedContact.currentCompany ?? contact.currentCompanyName,
              location: updatedContact.locations?.[0] ?? contact.location,
            };
          }

          return contact;
        }),
      };
    },
  );
};

export const ContactDetailsSection = ({
  contact,
  isContactLoading,
  project,
}: ContactDetailsSectionProps) => {
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const queryParams = qs.parse(search.slice(1));
  const me = useMe();

  const canUpdateContact = me.ability.can(
    'update',
    subject('Contact', contact),
  );

  const openEditContactModal =
    useModal<EditContactModalProps>(EditContactModal);
  const openEditContactPhonesModal = useModal<EditContactModalProps>(
    EditContactPhonesModal,
  );
  const openEditContactEmailsModal = useModal<EditContactModalProps>(
    EditContactEmailsModal,
  );

  const enrichmentMode =
    project?.contactEmailEnrichmentMode ??
    Project.contactEmailEnrichmentMode.PERSONAL;

  const handleContactUpdate = (updatedContact?: Contact) => {
    queryClient.invalidateQueries(['get_contact_emails', contact?.id]);
    queryClient.invalidateQueries(['get_contact_phone_numbers', contact?.id]);
    queryClient.invalidateQueries(['get_contact', contact?.id]);
    project?.id && queryClient.invalidateQueries(['get_project_contacts']);

    if (updatedContact) {
      updateSearchContactCache(
        queryClient,
        ['search_contacts', queryParams],
        updatedContact,
      );
    }
  };

  const handleEditContact = () => {
    openEditContactModal({
      contact,
      onContactUpdated: handleContactUpdate,
      enrichmentMode: project?.contactEmailEnrichmentMode,
    });
  };

  const handleEditContactPhones = () => {
    openEditContactPhonesModal({
      contact,
      onContactUpdated: handleContactUpdate,
      enrichmentMode: project?.contactEmailEnrichmentMode,
    });
  };

  const handleEditContactEmails = () => {
    openEditContactEmailsModal({
      contact,
      onContactUpdated: handleContactUpdate,
      enrichmentMode: project?.contactEmailEnrichmentMode,
    });
  };

  return (
    <Box>
      <LoadingArea
        isLoading={isContactLoading}
        loader={
          <Box>
            <TextSkeleton size='xl' css={{ width: 200, my: 6, height: 24 }} />
            <TextSkeleton size='lg' css={{ width: 300, my: 6 }} />
          </Box>
        }
      >
        <Text css={{ fontWeight: '$bold', lineHeight: '32px', fontSize: 24 }}>
          {getFullName(contact)}
          {contact.id && canUpdateContact && (
            <Tooltip content='Edit contact details'>
              <IconButton
                label='edit contact'
                name='pencil'
                size='sm'
                color='$text-tertiary'
                css={{ ml: 4 }}
                onClick={handleEditContact}
              />
            </Tooltip>
          )}
        </Text>
        <Text size='lg'>
          <Text as='span' inline inherit css={{ color: '$gray-500' }}>
            {contact.currentTitle}
          </Text>{' '}
          at {contact.currentCompany}
        </Text>
      </LoadingArea>
      <Flex css={{ columnGap: 48, rowGap: 12, pt: 22, minHeight: 54 }} wrap>
        <Emails
          contact={contact}
          isContactLoading={isContactLoading}
          onEdit={handleEditContactEmails}
          enrichmentMode={enrichmentMode}
          canEdit={canUpdateContact}
        />
        <Phones
          onEdit={handleEditContactPhones}
          contact={contact}
          isContactLoading={isContactLoading}
          enrichmentMode={enrichmentMode}
          canEdit={canUpdateContact}
        />
        <Location contact={contact} isLoading={isContactLoading} />
        <ContactAttachment contactId={contact?.id} canEdit={canUpdateContact} />
        <Socials contact={contact} />
        {contact?.linkedinUrl && (
          <AtsProfileLink css={{ p: 6 }} linkedInUrl={contact?.linkedinUrl} />
        )}
      </Flex>
    </Box>
  );
};
