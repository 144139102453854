import { useCallback, useRef } from 'react';

export const useScrollTop = ({ parent = false } = {}) => {
  const scrollableRef = useRef<HTMLElement>(null);
  const scrollTop = useCallback(() => {
    if (parent) {
      scrollableRef.current?.parentElement?.scrollTo(0, 0);
    } else {
      scrollableRef.current?.scrollTo(0, 0);
    }
  }, [scrollableRef.current]);
  return { ref: scrollableRef, scrollTop };
};
