import { ApiResponse, CreateProjectDto, Project } from '@betterleap/client';
import { showToast } from '@betterleap/ui';
import { useMutation, useQuery } from 'react-query';
import { apiClient } from 'lib/apiClient';

interface CreateProps {
  onSuccess?: (data: ApiResponse<Project>) => void;
}

export const useCreateProject = ({ onSuccess }: CreateProps) => {
  const createProject = useMutation(
    (requestBody: CreateProjectDto) =>
      apiClient.project.create({ requestBody }),
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to create project. Please try again.',
        });
      },
    },
  );
  return createProject;
};

interface GetProjectProps {
  key: string;
  q: qs.ParsedQs;
  pageSize: number;
  onlyMine?: boolean;
  enabled?: boolean;
}

export const useGetProjects = (props: GetProjectProps) => {
  const { key, q, onlyMine, enabled } = props;
  const { search, page, pageSize } = getRequest(key, q, props.pageSize);
  const request = { ...q, search, page, pageSize, onlyMine };
  return useQuery(
    [key, request],
    () => apiClient.project.findAllWithSequenceStats(request),
    { enabled },
  );
};

interface GetSharedProjectProps {
  key: string;
  q: qs.ParsedQs;
  pageSize: number;
}

export const useGetSharedProjects = (props: GetSharedProjectProps) => {
  const { key, q } = props;
  const { search, page, pageSize } = getRequest(key, q, props.pageSize);
  const request = { ...q, search, page, pageSize };
  return useQuery([key, request], () =>
    apiClient.projectShare.getAllSharedProjectsWithOrganization(request),
  );
};

function getRequest(key: string, q: qs.ParsedQs, defaultPageSize: number) {
  const page = q[`${key}page`]?.toString() || '1';
  const size = q[`${key}pageSize`]?.toString() || defaultPageSize.toString();
  return {
    search: q.search as string,
    page: parseInt(page, 10),
    pageSize: parseInt(size, 10),
  };
}
