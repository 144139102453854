import { Box, Flex, IconButton } from '@betterleap/ui';

interface NotesColumnProps {
  handleAddNote: (contactProjectId: string) => Promise<void>;
}

export const NotesColumn = ({ handleAddNote }: NotesColumnProps) => ({
  Header: 'COMMENTS',
  width: 95,
  headerCss: {
    maxWidth: 95,
  },
  css: {
    maxWidth: 95,
  },
  accessor: (contact: { contactProjectId: string; notes: [] }) => (
    <Flex>
      {contact.notes.length > 0 ? (
        <Flex>
          <IconButton
            label='view comments'
            name='document-text'
            size='sm'
            color='$violet-600'
            onClick={(e) => {
              e.stopPropagation();
              handleAddNote(contact.contactProjectId);
            }}
          />
          <Box
            css={{
              fontStyle: 'normal',
              fontSize: '$sm',
              color: '#656F7D',
              backgroundColor: '#F0F1F3',
              display: 'flex',
              alignItems: 'center',
              borderRadius: 10,
              maxHeight: 24,
              padding: 8,
              height: 'auto',
            }}
          >
            {contact.notes.length}
          </Box>
        </Flex>
      ) : (
        <IconButton
          label='add comment'
          name='plus'
          size='sm'
          color='$violet-600'
          onClick={(e) => {
            e.stopPropagation();
            handleAddNote(contact.contactProjectId);
          }}
        />
      )}
    </Flex>
  ),
  slot: 'custom',
});
