import {
  Flex,
  Form,
  IconButton,
  BoundInput as Input,
  RadioGroup,
  Spinner,
} from '@betterleap/ui';
import { useForm, useWatch } from 'react-hook-form';
import { ContactEmailDto } from '@betterleap/client';
import { emailRegex } from '../../../constants';
import ContactEmailItem from './ContactEmailItem.template';

export interface ContactEmailManagerTemplateProps {
  contactPrimaryEmail: string | undefined;
  contactLinkedinUrl: string | undefined;
  isLoading: boolean;
  contactEmails: ContactEmailDto[];
  handleAddEmail: (email: string) => Promise<void>;
  handleRemoveEmail: (contactEmail: ContactEmailDto) => Promise<void>;
  handleSetDefaultEmail: (email: string) => Promise<void>;
  onEmailCopied?: () => void;
}

interface NewEmailForm {
  addNewEmailText: string;
}

const ContactEmailManagerTemplate = (
  props: ContactEmailManagerTemplateProps,
) => {
  const {
    contactPrimaryEmail,
    contactLinkedinUrl,
    isLoading,
    contactEmails,
    handleAddEmail,
    handleRemoveEmail,
    handleSetDefaultEmail,
    onEmailCopied,
  } = props;
  const { control, handleSubmit, setValue } = useForm<NewEmailForm>({
    defaultValues: { addNewEmailText: '' },
    reValidateMode: 'onSubmit',
  });

  const emailValue = useWatch({
    control,
    name: 'addNewEmailText',
  });

  const addNewEmail = (data: NewEmailForm) => {
    handleAddEmail(data.addNewEmailText);
    setValue('addNewEmailText', '');
  };

  return (
    <Flex
      vertical
      id='contact-email-manager'
      css={{
        alignItems: 'flex-start',
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
    >
      {contactEmails.length > 0 && (
        <Flex
          css={{
            width: '100%',
            border: '1px solid $neutral-blue-300',
            borderTopLeftRadius: '$xl',
            borderTopRightRadius: '$xl',
            borderBottom: 'none',
          }}
        >
          <form style={{ width: '100%' }}>
            <RadioGroup
              value={contactPrimaryEmail}
              onChange={(val) => {
                handleSetDefaultEmail(val);
              }}
            >
              {contactEmails.map((ce) => (
                <ContactEmailItem
                  key={ce.id}
                  allowRemoval={Boolean(
                    contactLinkedinUrl || contactEmails.length > 1,
                  )}
                  contactEmail={ce}
                  handleRemoveEmail={handleRemoveEmail}
                  onEmailCopied={onEmailCopied}
                />
              ))}
            </RadioGroup>
          </form>
        </Flex>
      )}
      <Flex css={{ width: '100%' }}>
        <Form
          control={control}
          css={{ width: '100%' }}
          onSubmit={handleSubmit(addNewEmail)}
        >
          <Input
            aria-label='add-email'
            css={{
              width: '100%',
            }}
            inputCss={{
              ...(contactEmails.length
                ? {
                    borderTopRightRadius: '$none',
                    borderTopLeftRadius: '$none',
                    borderTop: 'none',
                    borderColor: '$neutral-blue-300',
                  }
                : {}),
            }}
            placeholder={
              contactEmails.length ? 'Add another email' : 'Add email'
            }
            type='text'
            name='addNewEmailText'
            id='add-new-email-text'
            rules={{
              pattern: {
                value: emailRegex,
                message: 'Invalid email',
              },
            }}
            rightIcon={
              isLoading ? (
                <Spinner />
              ) : (
                <IconButton
                  label='add'
                  name='arrow-right'
                  size='xs'
                  color={
                    emailRegex.test(emailValue)
                      ? '$blue-600'
                      : '$neutral-blue-500'
                  }
                  onClick={handleSubmit(addNewEmail)}
                />
              )
            }
            required
          />
        </Form>
      </Flex>
    </Flex>
  );
};

export default ContactEmailManagerTemplate;
